import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';

const pageKey = 'PENSIONER.ASSESSMENT.CERTIFY_STUDIES_FORM';
const clientCheckKey = `${pageKey}.STEPS.CLIENT_CHECK`;
const backgroundsKey = `${pageKey}.STEPS.BACKGROUNDS`;
const declarationKey = `${pageKey}.STEPS.DECLARATION`;
const attachmentsKey = `${pageKey}.STEPS.ATTACHMENTS`;
const paymentKey = `${pageKey}.STEPS.PAYMENT`;
const voucherKey = `${pageKey}.STEPS.VOUCHER`;
const successSubmissionKey = `${pageKey}.STEPS.VOUCHER.SUCCESS`;
const failedSubmissionKey = `${pageKey}.STEPS.VOUCHER.FAILED`;

const deceasedUserSection = `${backgroundsKey}.FORM.DECEASED_USER`;
const callingUserSection = `${backgroundsKey}.FORM.CALLING_USER`;

const unmarriedSectionKey = `${declarationKey}.UNMARRIED_SECTION`;
const declarationSectionKey = `${declarationKey}.DECLARATION_SECTION`;

const maritalStatusKey = `${callingUserSection}.FIELDS.MARITAL_STATUS.OPTIONS`;
const certificateAttachmentSectionKey = `${attachmentsKey}.CERTIFICATE_SECTION`;
const declarationAttachmentSectionKey = `${attachmentsKey}.DECLARATION_SECTION`;

const paymentMethodsKey = `${paymentKey}.PAYMENT_METHODS`;

const saveModal = 'MODALS.SAVED';
const toastKey = 'PENSIONER.ASSESSMENT.CERTIFY_STUDIES_FORM.TOAST';

export const CERTIFY_STUDIES_FORM_CONTENT = {
  title: `${pageKey}.TITLE`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`,
  },
  steps: {
    clientCheck: {
      label: `${clientCheckKey}.LABEL`
    },
    backgrounds: {
      label: `${backgroundsKey}.LABEL`,
      title: `${backgroundsKey}.TITLE`,
      buttons: {
        next: `${backgroundsKey}.BUTTONS.NEXT`,
        saveContinueLater: `${backgroundsKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
      form: {
        deceasedUser: {
          title: `${deceasedUserSection}.TITLE`,
          description: `${deceasedUserSection}.DESCRIPTION`,
          fields: {
            names: `${deceasedUserSection}.FIELDS.NAMES`,
            lastName: `${deceasedUserSection}.FIELDS.LAST_NAME`,
            rut: `${deceasedUserSection}.FIELDS.RUT`,
          },
        },
        callingUser: {
          title: `${callingUserSection}.TITLE`,
          description: `${callingUserSection}.DESCRIPTION`,
          fields: {
            names: `${callingUserSection}.FIELDS.NAMES`,
            lastName: `${callingUserSection}.FIELDS.LAST_NAME`,
            secondSurname: `${callingUserSection}.FIELDS.SECOND_SURNAME`,
            rut: `${callingUserSection}.FIELDS.RUT`,
            email: `${callingUserSection}.FIELDS.EMAIL`,
            cellphone: `${callingUserSection}.FIELDS.CELLPHONE`,
            maritalStatus: `${callingUserSection}.FIELDS.MARITAL_STATUS.LABEL`,
            gender: {
              label: `${callingUserSection}.FIELDS.GENDER.LABEL`,
              female: `${callingUserSection}.FIELDS.GENDER.FEMALE`,
              male: `${callingUserSection}.FIELDS.GENDER.MALE`,
            },
            deceasedDate: `${callingUserSection}.FIELDS.DECEASED_DATE`,
            birthdate: `${callingUserSection}.FIELDS.BIRTHDATE`,
            profession: `${callingUserSection}.FIELDS.PROFESION`,
            healthcare: `${callingUserSection}.FIELDS.HEALTHCARE`,
            address: `${callingUserSection}.FIELDS.ADDRESS`,
            region: `${callingUserSection}.FIELDS.REGION`,
            commune: `${callingUserSection}.FIELDS.COMMUNE`,
          },
        },
      },
    },
    declaration: {
      label: `${declarationKey}.LABEL`,
      disclaimer: `${declarationKey}.DISCLAIMER`,
      unmarriedSection: {
        title: `${unmarriedSectionKey}.TITLE`,
        unmarried: `${unmarriedSectionKey}.UNMARRIED`,
      },
      declarationSection: {
        title: `${declarationSectionKey}.TITLE`,
        label: `${declarationSectionKey}.LABEL`,
        options: {
          yes: `${declarationSectionKey}.OPTIONS.YES`,
          no: `${declarationSectionKey}.OPTIONS.NO`,
        }
      },
      buttons: {
        next: `${declarationKey}.BUTTONS.NEXT`,
        back: `${declarationKey}.BUTTONS.BACK`,
        saveContinueLater: `${backgroundsKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
    },
    attachments: {
      label: `${attachmentsKey}.LABEL`,
      certificateSection: {
        add: `${certificateAttachmentSectionKey}.ADD`,
        title: `${certificateAttachmentSectionKey}.TITLE`,
        secondTitle: `${certificateAttachmentSectionKey}.SECOND_TITLE`,
        disclaimer: `${certificateAttachmentSectionKey}.DISCLAIMER`,
      },
      declarationSection: {
        title: `${declarationAttachmentSectionKey}.TITLE`,
        secondTitle: `${declarationAttachmentSectionKey}.SECOND_TITLE`,
        disclaimer: `${declarationAttachmentSectionKey}.DISCLAIMER`,
        period: `${declarationAttachmentSectionKey}.PERIOD`,
        career: `${declarationAttachmentSectionKey}.CAREER`,
        establishment: `${declarationAttachmentSectionKey}.ESTABLISHMENT`,
        year: `${declarationAttachmentSectionKey}.YEAR`,
        declaration: `${declarationAttachmentSectionKey}.DECLARATION`,
        required: `${backgroundsKey}.FORM.ERRORS.REQUIRED`,
      },
      buttons: {
        next: `${declarationKey}.BUTTONS.NEXT`,
        back: `${declarationKey}.BUTTONS.BACK`,
        saveContinueLater: `${backgroundsKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
    },
    payment: {
      label: `${paymentKey}.LABEL`,
      change: `${paymentKey}.CHANGE`,
      changeOptions: {
        yes: `${paymentKey}.CHANGE_OPTIONS.YES`,
        no: `${paymentKey}.CHANGE_OPTIONS.NO`,
      },
      disclaimer: `${paymentKey}.DISCLAIMER`,
      accountDisclaimer: `${paymentKey}.VIRTUAL_ACCOUNT_DISCLAIMER`,
      paymentMethods: {
        disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
        rutDisclaimer: `${paymentMethodsKey}.RUT_DISCLAIMER`,
        accountDisclaimer: `${paymentMethodsKey}.VIRTUAL_ACCOUNT_DISCLAIMER`,
        formSection: {
          title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
          transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
          cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
          bank: {
            field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`,
          },
          account: {
            field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`,
          },
          other: {
            options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
          }
        },
        errors: {
          required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
          patternVirtualAccount: `${paymentMethodsKey}.ERRORS.VIRTUAL_ACCOUNT`,
        }
      },
      buttons: {
        next: `${paymentKey}.BUTTONS.NEXT`,
        back: `${paymentKey}.BUTTONS.BACK`,
      },
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
      success: {
        title: `${successSubmissionKey}.TITLE`,
        description: `${successSubmissionKey}.DESCRIPTION`,
        homeButton: `${successSubmissionKey}.BUTTON`,
        download: `${successSubmissionKey}.DOWNLOAD_PDF`,
      },
      failed: {
        title: `${failedSubmissionKey}.TITLE`,
        description: `${failedSubmissionKey}.DESCRIPTION`,
        firstBtnText: `${failedSubmissionKey}.BUTTON`,
        iconName: 'i-error',
      }
    },
  },
};

export const CERTIFY_STUDIES_MARITAL_STATUS_DESCRIPTION = {
  A: `${maritalStatusKey}.A`,
  S: `${maritalStatusKey}.S`,
  C: `${maritalStatusKey}.C`,
  V: `${maritalStatusKey}.V`,
  D: `${maritalStatusKey}.D`,
  P: `${maritalStatusKey}.P`,
};

export const CERTIFY_STUDIES_DEEASED_PERSON_BACKGROUNDS = [
  { key: 'names' },
  { key: 'lastName' },
  { key: 'rut' }
] as Array<PersonBackgroundsField>;

export const CERTIFY_STUDIES_CALLING_PERSON_BACKGROUNDS = [
  { key: 'names' },
  { key: 'lastName' },
  { key: 'secondSurname' },
  { key: 'rut' },
  { key: 'email' },
  { key: 'cellphone' },
  { key: 'maritalStatus' },
  { key: 'gender' },
  { key: 'birthdate' },
  { key: 'profession' },
  { key: 'healthcare' },
  { key: 'address' },
  { key: 'region' },
  { key: 'commune' },
] as Array<PersonBackgroundsField>;

export const CALLING_PERSON_FORM_KEY = 'callingPerson';
export const DECEASED_PERSON_FORM_KEY = 'deceasedPerson';
export type CERTIFY_STUDIES_FORM_TYPE = 'callingPerson' | 'deceasedPerson';

export const CERTIFY_STUDIES_ARRAY_PROPERTIES: Array<FormArrayItemProperties> = [
  { name: 'fileName', translated: false, style: ['bold'] },
  { name: 'year', translated: false },
  { name: 'period', translated: false },
];

const BASIC_DOCUMENT_REQUIRED_FILE = {
  order: 1,
  buttonLabel: `${certificateAttachmentSectionKey}.PERSONAL_DOCUMENT_TITLE`,
  required: true
};

export const PERSONAL_DOCUMENT_REQUIRED_FILE = {
  personalDocument: {
    ...BASIC_DOCUMENT_REQUIRED_FILE,
    fileIndex: 'personalDocument',
  }
};

export const PERSONAL_DECLARATION_REQUIRED_FILES = {
  personalDocument: {
    order: 1,
    buttonLabel: `${certificateAttachmentSectionKey}.PERSONAL_DOCUMENT_TITLE`,
    required: true,
    fileIndex: 'declarationPersonalDocument',
  },
  declarationDocument: {
    order: 2,
    buttonLabel: `${declarationAttachmentSectionKey}.DECLARATION_DOCUMENT_TITLE`,
    fileIndex: 'declarationDocument',
    required: true
  }
};

export const CERTIFY_STUDIES_PARTIAL_REQUEST_SAVED = {
  title: `${saveModal}.TITLE`,
  description: `${saveModal}.DESCRIPTION`,
  firstBtnText: `${saveModal}.BUTTON`,
  iconName: 'i-saved',
};

export const CERTIFY_STUDIES_REQUEST_FAILED_SUBMISSION = {
  title: `${failedSubmissionKey}.TITLE`,
  description: `${failedSubmissionKey}.DESCRIPTION`,
  firstBtnText: `${failedSubmissionKey}.BUTTON`,
  iconName: 'i-error',
};
