<div class="infographic-info" [ngClass]="typeClass">
  <div class="icon i-chart primary" [ngClass]="iconClass"></div>
  <p class="title" [ngClass]="font?.size" [innerHTML]="loading ? '' : infographic?.title | translate"></p>
  <ion-skeleton-text *ngIf="loading" class="skeleton-line small" animated></ion-skeleton-text>
  <p *ngIf="infographic?.description" class="description" [ngClass]="font?.size" [innerHTML]="loading ? '' : infographic?.description | translate"></p>
  <app-skeleton *ngIf="loading && infographic?.description" [linesNumber]="4" [paragraphNumber]="1"></app-skeleton>
  <div class="centered-container">
    <ion-button *ngIf="infographic?.showMoreButton" class="button secondary" [ngClass]="font?.size" (click)="emitClickButton()">
      <p class="i-more">{{ infographic?.showMoreButton }}</p>
    </ion-button>
  </div>
</div>
