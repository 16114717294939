<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <p class="title" [ngClass]="font?.size" [innerHTML]="information?.title | translate"></p>
  <p class="subtitle" [ngClass]="font?.size" [innerHTML]="information?.description | translate"></p>

  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ul class="ion-no-padding">
          <li class="ion-align-items-center ion-margin-bottom" *ngFor="let info of information?.importantInfo">
            <ion-icon color="danger" name="checkmark-circle-outline" size="large" class="ion-margin-end"></ion-icon>
            <p [innerHTML]="info | translate"></p>
          </li>
        </ul>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="authorization-container" *ngFor="let pdfFile of downloadable">
    <p [innerHTML]="pdfFile?.info | translate"></p>
    <ion-button (click)="getForm(pdfFile.downloadUrl, pdfFile.downloadFileName)" size="default">
      <ion-icon shape="round" slot="start" name="download-outline"></ion-icon>
      {{pdfFile.downloadButtonText | translate}}
    </ion-button>
  </div>
  <hr>
  <div class="button-container">
    <ion-button (click)="goTo()" expand="block" size="large" [ngClass]="font?.size">
      {{ information?.actionButton | translate }}
    </ion-button>
  </div>
  </div>