import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss'],
})
export class ModalGenericComponent extends ModalComponent {
  public title: string;
  public description: string;
  public firstBtnText: string;
  public secondBtnText: string;
  public primaryCallback: Function;
  public secondaryCallback: Function;
  public showSecondaryButton = false;
  public iconName: string[];
  public largeButton = false;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    const data = this.params.get('data');
    if (data) {
      Object.keys(data).forEach((key) => this[key] = data[key]);
      if (data.secondaryCallback) { this.showSecondaryButton = true; }
    }
  }

  public async primaryAction(): Promise<void> {
    await this.closeModal();
    this.primaryCallback();
  }

  public async secondaryAction(): Promise<void> {
    await this.closeModal();
    this.secondaryCallback();
  }
}
