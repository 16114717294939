import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { InfoText } from '@interfaces/info-text.interface';
import {
  OptionList, RadioButtonInputListElement, RadioButtonInputValues
} from '@interfaces/radio-button-input-list-element.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { CmsService } from '@services/cms/cms.service';
import { ErrorUtils } from '@utils/error.utils';

@Component({
  selector: 'app-radio-button-list-form',
  templateUrl: './radio-button-list-form.component.html',
  styleUrls: ['./radio-button-list-form.component.scss'],
})
export class RadioButtonListFormComponent implements OnChanges {
  @Input() public pageContent;
  @Input() public isFirstChild = true;
  @Input() public backgroundsValues: RadioButtonInputValues[];
  @Output() public formChanged: EventEmitter<any> = new EventEmitter();
  public radioButtonListForm: UntypedFormArray;
  public contentList: Array<RadioButtonInputListElement>;
  public optionsList: OptionList[] = [];
  public loadedData = false;
  public loaded = false;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private cmsService: CmsService,
    private loadingService: LoadingService,
    private errorUtils: ErrorUtils,
    private modalService: ModalService,
  ) { }

  public async ngOnChanges(): Promise<void> {
    await this.loadInstitutions();
    this.loadRadioButtonListForm();
  }

  public updateRequiredValidator(index: number): void {
    const codeControl = this.getControl(index, 'code');
    const acceptedControl = this.getControl(index, 'accepted');
    if (this.hasDetail(codeControl.value)) {
      const validator = [];
      if (acceptedControl.value) { validator.push(Validators.required); }
      const detailControl = this.radioButtonListForm.controls[index].get('detail');
      detailControl.reset();
      detailControl.setValidators(validator);
      detailControl.updateValueAndValidity();
    }
  }

  public getControl(index: number, key: string): AbstractControl {
    return this.radioButtonListForm.controls[index].get(key);
  }

  public getText(code: number): string {
    return this.getRadioButtonElement(code).text;
  }

  public getDefinition(code: number): Array<InfoText> {
    return this.getRadioButtonElement(code).definitions;
  }

  public getDetail(code: number): string {
    return this.getRadioButtonElement(code).detail;
  }

  public getList(code: number): string {
    return this.getRadioButtonElement(code).keyList;
  }

  public hasDefinition(code: number): boolean {
    return Boolean(this.getDefinition(code));
  }

  public hasDetail(code: number): boolean {
    return Boolean(this.getDetail(code));
  }

  public hasList(code: number): boolean {
    return Boolean(this.getList(code));
  }

  public getOptionList(code: number): Array<string> {
    const keyList = this.getList(code);
    const list = this.optionsList.find((option) => option.key === keyList);
    return list ? list.options : [];
  }

  private getRadioButtonElement(code: number): RadioButtonInputListElement {
    return this.contentList.find((element) => element.code === code);
  }

  private async loadInstitutions(): Promise<void> {
    this.loadingService.showLoading();
    await Promise.all([
      this.cmsService.loadHealthcareInstitutions().toPromise(),
      this.cmsService.loadPaymentInstitutions().toPromise()
    ]).then(([healthcareInstitutions, paymentInstitutions]) => {
      this.optionsList.push(
        { key: 'healthcareInstitutions', options: healthcareInstitutions },
        { key: 'paymentInstitutions', options: paymentInstitutions },
      );
      this.loadedData = true;
    }).catch((error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) }))
      .finally(() => this.loadingService.hideLoading());
  }

  private loadRadioButtonListForm(): void {
    this.radioButtonListForm = this.formBuilder.array([]);
    this.contentList = this.pageContent.options;
    this.contentList.forEach((element, index) => {
      const values = this.backgroundsValues ? this.backgroundsValues[index] : {} as RadioButtonInputValues;
      this.radioButtonListForm.push(this.formBuilder.group({
        code: [element.code],
        accepted: [values.selected, Validators.required],
        detail: [values.value],
        bigField: [element.bigField]
      }));
    });
    this.radioButtonListForm.valueChanges.subscribe(() => {
      this.formChanged.emit(this.radioButtonListForm);
    });

    this.loaded = true;
  }
}
