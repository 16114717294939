<div class="partial-content">
  <ion-button class="btn small secondary label medium save" [disabled]="form.invalid" (click)="confirm()">
    {{ pageContent?.buttons.saveContinueLater | translate }}
    <div class="icon-result save"></div>
  </ion-button>
</div>
<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="confirm()">
  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.declarationSection.title | translate }}
  </h3>
  <div class="form-section second">
    <mat-checkbox formControlName="declaration" color="primary" [ngClass]="font?.size">
      <div>
        {{ pageContent?.declarationSection.declaration | translate }}
        <strong>{{ selectedPensionModality | translate }}</strong>
      </div>
    </mat-checkbox>
  </div>
  <div class="form-section second">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.filesSection.title | translate }}</h3>
    <div class="information-container">
      <app-disclaimer-box [description]="pageContent?.filesSection.disclaimer" [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
    <app-attach-files *ngIf="requestFiles" [attachFiles]="requiredFiles" [preloadedFiles]="cleanFiles"
      (fileEmitter)="setFiles($event)">
    </app-attach-files>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>