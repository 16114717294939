export const TRANSFER_STATE_HEADER_ID = 1;
export const MULTIFUNDS_HEADER_ID = 2;
export const FINANCIAL_INFORMATION_HEADER_ID = 3;
export const PAYMENT_CALENDAR_HEADER_ID = 4;
export const PENSIONER_BENEFITS_HEADER_ID = 5;
export const SIMULATORS_HEADER_ID = 6;
export const CERTIFICATES_HEADER_ID = 7;
export const AFFILIATE_PAPERWORK_CENTER_HEADER_ID = 8;
export const PENSIONER_PAPERWORK_CENTER_HEADER_ID = 9;
export const EMPLOYER_PASSWORD_HEADER_ID = 10;
export const EMPLOYER_AGREED_DEPOSITS_HEADER_ID = 11;
export const EMPLOYER_PAYMENT_OPTIONS_HEADER_ID = 12;
export const EMPLOYER_EXCESS_PAYMENT_HEADER_ID = 13;
export const EMPLOYER_CURRENT_COMISSIONES_HEADER_ID = 14;
export const EMPLOYER_EMPLOYEE_TYPES_HEADER_ID = 15;
export const EMPLOYER_YOUNG_EMPLOYEE_SUBSIDY_HEADER_ID = 16;
export const EMPLOYER_PAYMENT_FORM_HEADER_ID = 17;
export const FOOTER_ADMINISTRATION_HEADER_ID = 18;
export const FOOTER_FINANTIAL_STATEMENTS_HEADER_ID = 19;
export const FOOTER_CORPORATE_GOVERNANCE_HEADER_ID = 20;
export const FOOTER_ESSENTIAL_FACTS_HEADER_ID = 21;
export const FOOTER_INFORMATION_OF_INTEREST_HEADER_ID = 22;
export const FOOTER_ANNUAL_MEMORY_HEADER_ID = 23;
export const FOOTER_OUR_COMPANY_HEADER_ID = 24;
export const FOOTER_POLICIES_HEADER_ID = 25;
export const FOOTER_SITEMAP_HEADER_ID = 26;
export const FOOTER_FAQS_HEADER_ID = 27;
