<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <form class="form-container" [formGroup]="form" (submit)="addPowerAttorney()">
    <h1 class="title primary" [ngClass]="font?.size">{{ pageContent?.modalTitle | translate }}</h1>
    <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.form.title | translate }}</h2>

    <div class="form-section second">
      <div class="information-container">
        <app-disclaimer-box
          [description]= "pageContent?.disclaimer"
          [typeDisclaimer]="'info'">
        </app-disclaimer-box>
      </div>
      <app-attach-files *ngIf="requestFiles"
        [attachFiles]="requiredFiles"
        [preloadedFiles]="powerAttorney ? filesLoaded : null"
        (fileEmitter)="setFiles($event)">
      </app-attach-files>
    </div>

    <div class="button-container">
      <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="form.invalid">
        {{ buttonText | translate }}
      </ion-button>
    </div>
  </form>
</div>
