import { Injectable } from '@angular/core';
import { CountryIso } from '@interfaces/country-dial-codes.interface';
import { getCountryDialCode, getCountryName, getCountryFlag } from 'country-flags-dial-code';
import * as isoCountries from 'i18n-iso-countries';

const COUNTRY_FLAGS_URL = 'https://flagcdn.com/';

@Injectable({
  providedIn: 'root'
})
export class CountryDialCodesService {

  private countries: CountryIso[];

  constructor() {}

  /**
   * This method returns a list of countries, each one with its ISO code, url of the flag and its dialing code.
   * @returns a promise of a list of 'CountryIso' objects. On error, the response is null
   */
  public async getCountries(): Promise<CountryIso[]> {
    try {
      isoCountries.registerLocale(await import('i18n-iso-countries/langs/es.json'));
      this.countries = this.sortCountriesList(isoCountries.getNames('es', {select: 'official'}));
      this.countries.forEach(country => {
        country.dialCode = this.getCountryPhoneCode(country.isoCode);
        country.isoName = this.getCountryIsoName(country.isoCode);
        country.flagSvgUrl = this.getCountrySvgFlag(country.isoCode);
        country.flagPngUrl = this.getCountryPngFlag(country.isoCode);
        country.flagBaseSvgUrl = this.getCountryBaseFlag(country.isoCode);
      });
      return this.countries;
    } catch {
      return null;
    }
  }

  /**
   * this method will bring up the dialing code of a country.
   * @param countryIsoCode the country's ISO code * reference (reference: https://es.wikipedia.org/wiki/ISO_3166-1)
   * @returns the dialing code of the country as a string
   */
  private getCountryPhoneCode(countryIsoCode: string): string {
    return getCountryDialCode(countryIsoCode);
  }

  /**
   * this method will bring up the name of a country.
   * @param countryIsoCode the country's ISO code * reference (reference: https://es.wikipedia.org/wiki/ISO_3166-1)
   * @returns the name of the country as a string
   */
  private getCountryIsoName(countryIsoCode: string): string {
    return getCountryName(countryIsoCode);
  }

  /**
   * this method will bring up the Base-Flag of a country.
   * @param countryIsoCode the country's ISO code * reference (reference: https://es.wikipedia.org/wiki/ISO_3166-1)
   * @returns the base flag of the country as a string (svg)
   */
  private getCountryBaseFlag(countryIsoCode: string): string {
    return getCountryFlag(countryIsoCode);
  }

  /**
   * this method will bring up the url of the country flag.
   * @param countryIsoCode the country's ISO code * reference (reference: https://es.wikipedia.org/wiki/ISO_3166-1)
   * @returns the url of the country flag as a string (png).
   */
  private getCountryPngFlag(countryIsoCode: string): string {
    return `${COUNTRY_FLAGS_URL}48x36/${countryIsoCode.toLocaleLowerCase()}.png`;
  }

  /**
   * this method will bring up the url of the country flag.
   * @param countryIsoCode the country's ISO code * reference (reference: https://es.wikipedia.org/wiki/ISO_3166-1)
   * @returns the url of the country flag as a string (svg).
   */
  private getCountrySvgFlag(countryIsoCode: string): string {
    return `${COUNTRY_FLAGS_URL}${countryIsoCode.toLocaleLowerCase()}.svg`;
  }

  /**
   * This method sorts a list of countries alphabetically
   * @param countries must be a list of countries, which must contain the 'name' field, within each object of the list.
   * @returns a list of sorted countries.
   */
  private sortCountriesList(countries: any): any[] {
    const tempCountries = Object.keys(countries).map((isoCode) => {
      return { isoCode, name: countries[isoCode] };
    });
    return tempCountries.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

}
