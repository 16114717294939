<div class="table-container">
  <div class="table-header">
    <div
      class="header-item-container"
      [ngClass]="{ 'sortable': column.sortable }"
      *ngFor="let column of columnsData; let i = index"
    >
      <div class="header-item" [ngClass]="font?.size" (click)="column.sortable && sortRows(i)">
        <span class="column-title">{{ column.label }}</span>
        <div *ngIf="column.sortable && currentSort === column.name" class="icon-sort-asc"></div>
        <div *ngIf="column.sortable && currentSort === '-' + column.name" class="icon-sort-desc"></div>
      </div>
    </div>
  </div>
  <div class="table-row" *ngFor="let row of shownRows" (click)="goToDetail(row)">
    <div class="row-item" *ngFor="let column of columnsData" [ngClass]="font?.size">
      <div class="column-title">{{ column.label }}</div>
      <div class="value" [ngClass]="calculateStatusClasses(column, row)" *ngIf="column.type !== 'date'">{{ row[column.name] }}</div>
      <div class="value" *ngIf="column.type === 'date'">{{ row[column.name] | date: column.format || 'dd-MM-yyyy' }}</div>
    </div>
  </div>
  <div class="pagination">
    <div class="description" [ngClass]="font?.size">Se muestran {{ sortedRows?.length || 0 }} resultados</div>
    <div class="actions-container">
      <div class="page-numbers-container">
        <div class="page-number" *ngFor="let page of shownPages" [ngClass]="pageNumberClasses(page)" (click)="setPage(page)">
          {{ page }}
        </div>
      </div>
      <div class="page-navigation-container">
        <div class="page-icon" [ngClass]="prevPageClass" (click)="prevPage()">
          <div class="icon-prev"></div>
        </div>
        <div class="page-icon" [ngClass]="nextPageClass" (click)="nextPage()">
          <div class="icon-next"></div>
        </div>
      </div>
    </div>
  </div>
</div>
