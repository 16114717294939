import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { MonthlyQuota } from '@interfaces/quota-values.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-summary-quota-values',
  templateUrl: './modal-summary-quota-values.component.html',
  styleUrls: ['./modal-summary-quota-values.component.scss'],
})
export class ModalSummaryQuotaValuesComponent extends ModalComponent {
  public monthlyQuotas: Array<MonthlyQuota>;
  public title: string;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.title = this.params.get('title');
    this.monthlyQuotas = this.params.get('values');
  }

}
