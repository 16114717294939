import { Image, LinkApp } from '@interfaces/components.interface';

const pageKey = 'GENERAL.FOOTER';

export const CERTIFICATE_DISCLAIMER = `${pageKey}.CERTIFICATE_DISCLAIMER`;
export const DOWNLOAD_APP = `${pageKey}.DOWNLOAD_APP`;
export const CERTIFICATES_FIRST: Array<Image> = [
  {
    url: 'assets/images/PLE.png',
    alt: 'Plain Language Europe',
  },
  {
    url: 'assets/images/fundacion_visibilia.png',
    alt: 'Fundación Visibilia',
  },
  {
    url: 'assets/images/bh_compliance.png',
    alt: 'BH Compliance',
  },
];

export const CERTIFICATES_SECOND: Array<Image> = [
  {
    url: 'assets/images/PRI_Sig_White.png',
    alt: 'PRI Principles for Responsible Investment',
  },
  {
    url: 'assets/images/Bureau-Veritas2x.png',
    alt: 'Certificación ISO',
  },
  {
    url: 'assets/images/sello-mayor.png',
    alt: 'Sello mayor',
  },
];

export const LINKS_APP: Array<LinkApp> = [
  {
    url: 'https://play.google.com/store/apps/details?id=cl.planvital.app',
    icon: 'icon-store i-google-play-white',
    iconBlack: 'icon-store i-google-play',
    title: 'Descargar App AFP PlanVital en Android'
  },
  {
    url: 'https://apps.apple.com/cl/app/afp-planvital/id1437275304',
    icon: 'icon-store i-app-store-white',
    iconBlack: 'icon-store i-app-store',
    title: 'Descargar App AFP PlanVital en iOs (iPhone)'
  },
  {
    url: 'https://appgallery.huawei.com/#/app/C103958565',
    icon: 'icon-store i-app-gallery-white',
    iconBlack: 'icon-store i-app-gallery',
    title: 'Descargar App AFP PlanVital Huawei'
  }
];
