import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { AssessmentModule } from '@modules/affiliate/assessment/assessment.module';
import { HomePageModule } from '@modules/affiliate/home/home.module';
import { InformationPageModule } from '@modules/affiliate/information/information.module';
import { SavingsModule } from '@modules/affiliate/savings/savings.module';
import { TransferModule } from '@modules/affiliate/transfer/transfer.module';
import { AffiliateRoutingModule } from './affiliate-routing.module';


@NgModule({
  imports: [
    AffiliateRoutingModule,
    RouterModule,
    ComponentsModule,
    HomePageModule,
    InformationPageModule,
    SavingsModule,
    TransferModule,
    AssessmentModule,
  ],
})
export class AffiliateModule { }
