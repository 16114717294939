<div class="modal modal-container">
    <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
      <ion-icon class="icon" name="close" color="primary"></ion-icon>
    </ion-button>  


    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" class="ion-text-center ion-margin-bottom">
            <ion-icon class="icon-header" name="warning-outline" color="primary"></ion-icon>
        </ion-col>
        <ion-col size="12">
            <p class="title ion-text-center" [ngClass]="font?.size" [innerHTML]="pageContent.modalRutValidation.title| translate"></p>
        </ion-col>

        <ion-col size="10">
            <p class="ion-text-center text-info" [ngClass]="font?.size" [innerHTML]="pageContent.modalRutValidation.textInfo1| translate"></p>
        </ion-col>
        <ion-col size-xl="7" size-lg="8" size-sm="12" size-md="10">
            <ion-button (click)="closeModal()" fill="outline"  expand="block" size="large" [ngClass]="font?.size">
                {{ pageContent.buttons.back | translate }}
              </ion-button>
        </ion-col>
      </ion-row>
      <hr>

      <ion-row class="ion-justify-content-center">
        <ion-col size="10">
            <p class="ion-text-center text-info" [ngClass]="font?.size" 
            [innerHTML]="pageContent.modalRutValidation.textInfo2 | translate"></p>
        </ion-col>

        <ion-col size-xl="7" size-lg="8" size-sm="12" size-md="10">
            <ion-button target="_blank" [href]="pageContent.modalRutValidation.transferUrl"  expand="block" size="large" [ngClass]="font?.size">
                {{ pageContent.modalRutValidation.btnSubs | translate }}
              </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  

   
  </div>