import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { Voucher } from '@interfaces/voucher.interface';
import { PrimaryOption } from '@interfaces/header.interface';

export const MIN_POWER_ATTORNEY_FILES = 1;
export const MAX_POWER_ATTORNEY_FILES = 10;



export const PENSIONER_PAGES: { [key: string]: PrimaryOption } = {
  pensionTypes: {
    name: 'Centro de trámites',
    url: 'pensionado/asesoria/centro-tramites',
  },
};

export const INHERITANCE_FIELDS_KEY = ['deathCertificate', 'kinshipCertificate', 'idFile'];

const pageKey = 'PENSIONER.INHERITANCE_REQUEST';
const inheritanceRequestKey = `${pageKey}.STEPS`;

const clientCheckKey = `${inheritanceRequestKey}.CLIENT_CHECK`;
const backgroundsKey = `${inheritanceRequestKey}.BACKGROUNDS`;
const reasonKey = `${inheritanceRequestKey}.REASONS`;
const attachmentsKey = `${inheritanceRequestKey}.ATTACHMENTS`;
const toastKey = `${pageKey}.TOAST`;

const voucherKey = `${pageKey}.STEPS.VOUCHER.SUCCESS`;
const failedSubmissionKey = `${pageKey}.STEPS.VOUCHER.FAILED`;

const modalPowerAttorney = `${pageKey}.MODAL`;

const savedModal = 'MODALS.SAVED';

export const INHERITANCE_REQUEST_REQUIRED_FILES = [
  {
    order: 1,
    buttonLabel: `${pageKey}.FILES.KINSHIP_CERTIFICATE`,
    fileIndex: 'kinshipCertificate',
    required: true
  },
  {
    order: 2,
    buttonLabel: `${pageKey}.FILES.IDENTIFICATION_FILE`,
    fileIndex: 'idFile',
    required: true
  },
  {
    order: 3,
    buttonLabel: `${pageKey}.FILES.DEATH_CERTIFICATE`,
    fileIndex: 'deathCertificate',
    required: false
  },
];

export const POWER_ATTORNEY_REQUIRED_FILES = {
  notarialPower: {
    order: 1,
    buttonLabel: `${modalPowerAttorney}.TITLE`,
    fileIndex: 'powerAttorney',
    required: true
  }
};

export const INHERITANCE_REQUEST_CONTENT = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  introduction: {
    title: `${pageKey}.INTRODUCTION.TITLE`,
    description: `${pageKey}.INTRODUCTION.DESCRIPTION`,
    disclaimer: `${pageKey}.INTRODUCTION.DISCLAIMER`,
    buttonAction: `${pageKey}.INTRODUCTION.BUTTON_ACTION`
  },
  folio: `${pageKey}.FOLIO`,
  requestProcess: `${pageKey}.REQUEST_PROCESS`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  steps: {
    clientCheck: {
      label: `${clientCheckKey}.LABEL`
    },
    backgrounds: {
      label: `${backgroundsKey}.LABEL`,
      buttons: {
        next: `${backgroundsKey}.BUTTONS.NEXT`,
        saveContinueLater: `${reasonKey}.BUTTONS.SAVE_CONTINUE_LATER`
      },
      form: {
        deceasedAffiliateSection: {
          title: `${backgroundsKey}.FORM.DECEASED_AFFILIATE_SECTION.TITLE`,
          description: `${backgroundsKey}.FORM.DECEASED_AFFILIATE_SECTION.DESCRIPTION`
        },
        applicantSection: {
          title: `${backgroundsKey}.FORM.APPLICANT_SECTION.TITLE`,
          description: `${backgroundsKey}.FORM.APPLICANT_SECTION.DESCRIPTION`
        }
      }
    },
    reasons: {
      label: `${reasonKey}.LABEL`,
      title: `${reasonKey}.TITLE`,
      buttons: {
        next: `${reasonKey}.BUTTONS.NEXT`,
        back: `${reasonKey}.BUTTONS.BACK`,
        saveContinueLater: `${reasonKey}.BUTTONS.SAVE_CONTINUE_LATER`
      },
      form: {
        question: `${reasonKey}.FORM.QUESTION`,
        options: {
          0: `${reasonKey}.FORM.OPTIONS.0`,
          1: `${reasonKey}.FORM.OPTIONS.1`,
          2: `${reasonKey}.FORM.OPTIONS.2`,
          3: `${reasonKey}.FORM.OPTIONS.3`,
          4: `${reasonKey}.FORM.OPTIONS.4`,
          5: `${reasonKey}.FORM.OPTIONS.5`
        }
      }
    },
    attachments: {
      label: `${attachmentsKey}.LABEL`,
      buttons: {
        next: `${attachmentsKey}.BUTTONS.NEXT`,
        back: `${attachmentsKey}.BUTTONS.BACK`
      },
      backupAttachment: {
        title: `${attachmentsKey}.BACKUP_ATTACHMENT.TITLE`,
        disclaimer: `${attachmentsKey}.BACKUP_ATTACHMENT.DISCLAIMER`
      },
      notarialAttachment: {
        title: `${attachmentsKey}.NOTARIAL_ATTACHMENT.TITLE`,
        buttonPowerAttorney: {
          add: `${attachmentsKey}.NOTARIAL_ATTACHMENT.BUTTON_POWER_ATTORNEY.ADD`,
          disclaimer: `${attachmentsKey}.NOTARIAL_ATTACHMENT.BUTTON_POWER_ATTORNEY.DISCLAIMER`
        }
      }
    },
    voucher: {
      label: `${voucherKey}.LABEL`
    }
  }
};

export const INHERITANCE_REQUEST_VOUCHER: Voucher = {
  title: `${voucherKey}.TITLE`,
  description: `${voucherKey}.DESCRIPTION`,
  homeButton: `${voucherKey}.BUTTON`,
  download: `${voucherKey}.DOWNLOAD_PDF`,
};

export const INHERITANCE_REQUEST_FAILED_SUBMISSION = {
  title: `${failedSubmissionKey}.TITLE`,
  description: `${failedSubmissionKey}.DESCRIPTION`,
  firstBtnText: `${failedSubmissionKey}.BUTTON`,
  iconName: 'i-error',
};

export const INHERITANCE_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const MODAL_POWER_ATTORNEY_CONTENT = {
  modalTitle: `${modalPowerAttorney }.MODAL_TITLE`,
  formTitle: `${modalPowerAttorney }.FORM_TITLE`,
  disclaimer: `${modalPowerAttorney}.DISCLAIMER`,
  form: {
    title: `${modalPowerAttorney}.FORM.TITLE`,
    typeDocument: `${modalPowerAttorney}.FORM.TYPE_DOCUMENTS`,
  },
  buttons: {
    updateDocuments: `${modalPowerAttorney}.BUTTONS.UPDATE`,
    addDocuments: `${modalPowerAttorney}.BUTTONS.ADD`,
  },
  errors: {
    required: `${modalPowerAttorney }.ERRORS.REQUIRED`,
  }
};

export const ARRAY_POWER_ATTORNEY: Array<FormArrayItemProperties> = [
  { name: 'typeDocument', translated: false, style: ['bold'] },
  { name: 'name', translated: true },
  { name: 'fileName', translated: false },
];
