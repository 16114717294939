import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import * as cmsConstants from '@constants/cms.constant';
import { CMS_REQUESTS } from '@constants/cms.constant';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import { Banner } from '@interfaces/banner.interface';
import { CalendarItems } from '@interfaces/calendar.interface';
import { AlertBanner, Article, GeneralInfo, GuiaVitalItem, Link } from '@interfaces/components.interface';
import { FaqsItem, FaqsSectionContent } from '@interfaces/faqs.interface';
import { Card } from '@interfaces/info-card.interface';
import { Infographic } from '@interfaces/infographic.interface';
import { Product } from '@interfaces/product.interface';
import { CmsUtils } from '@utils/cms.utils';
import { ErrorUtils } from '@utils/error.utils';

import * as mocks from './mocks/components.mock';
import { GET_HEADERS_MOCKUP } from './mocks/headers.mock';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {
  constructor(
    private cmsUtils: CmsUtils,
    private errorUtils: ErrorUtils,
    private http: HttpClientInterceptor,
  ) {
  }

  public getArticles(language = 'es'): Observable<Array<Article>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/articulos?fields=${cmsConstants.ARTICLE_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}&sort=${cmsConstants.CMS_SORT}`;
    return this.http.get(url, mocks.GET_ARTICLES_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapArticles(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getGuiaVitalItems(language = 'es'): Observable<Array<GuiaVitalItem>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/tu_guia_vital?fields=${cmsConstants.ARTICLE_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}&sort=${cmsConstants.CMS_SORT}`;
    return this.http.get(url, mocks.GET_ARTICLES_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapGuiaVitalItems(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getBanners(language = 'es'): Observable<Array<Banner>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/banners?fields=${cmsConstants.BANNER_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}&sort=${cmsConstants.CMS_SORT}`;
    return this.http.get(url, mocks.GET_BANNERS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapBanners(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getProducts(language = 'es'): Observable<Array<Product>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/productos?fields=${cmsConstants.PRODUCTS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_PRODUCTS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapProducts(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getLinks(language = 'es'): Observable<Array<Link>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/links?fields=${cmsConstants.LINKS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_LINKS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapLinks(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getInfographics(language = 'es'): Observable<Array<Infographic>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/infografias?fields=${cmsConstants.INFOGRAPHICS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_INFOGRAPHICS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapInfographics(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getHeaders(language = 'es'): Observable<Array<Banner>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/headers?fields=${cmsConstants.HEADERS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_HEADERS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapHeaders(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getAlertBanner(): Observable<AlertBanner> {
    const language = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/alerta_cinta?fields=${cmsConstants.ALERT_BANNER_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}&limit=1`;
    return this.http.get(url, mocks.GET_ALERT_BANNER_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapAlertBanner(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getGeneralInfo(): Observable<GeneralInfo> {
    const language = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/informacion_general?fields=${cmsConstants.GENERAL_INFO_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_GENERAL_INFO_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapGeneralInfo(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getPaymentCenters(language = 'es'): Observable<Array<Card<any>>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/centro_pagos?fields=${cmsConstants.PAYMENT_CENTER_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_PAYMENT_CENTERS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapPaymentCenters(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getCalendars(): Observable<CalendarItems> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/calendarios?fields=${cmsConstants.CALENDARS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_CALENDARS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapCalendarItems(response.data)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getAffiliateFaqs(): Observable<FaqsItem> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/preguntas_afiliado?fields=${cmsConstants.FAQS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_AFFILIATE_FAQS_MOCKUP)
      .pipe(
        map(response => response.data),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getPensionerFaqs(): Observable<FaqsItem> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/preguntas_pensionados?fields=${cmsConstants.FAQS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_PENSIONER_FAQS_MOCKUP)
      .pipe(
        map(response => response.data),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getEmployerFaqs(): Observable<FaqsItem> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/preguntas_empleador?fields=${cmsConstants.FAQS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_EMPLOYER_FAQS_MOCKUP)
      .pipe(
        map(response => response.data),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getCorporateGovernanceFaqs(): Observable<FaqsItem> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/preguntas_gobierno_corporativo?fields=${cmsConstants.FAQS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_CORPORATE_GOVERNANCE_FAQS_MOCKUP)
      .pipe(
        map(response => response.data),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getFaqsSections(language: string = 'es'): Observable<Array<FaqsSectionContent>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/secciones_preguntas?fields=${cmsConstants.FAQS_SECTIONS_FIELDS}&filter=${JSON.stringify(CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, mocks.GET_FAQS_SECTIONS_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapFaqsSections(response.data, language)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }
}
