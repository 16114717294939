import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mustMatch(controlName: string, matchingControlName: string, validation: boolean = true): ValidatorFn {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) { return; }

    matchingControl.setErrors(null);
    const controlValue = getControlValue(control.value);
    const matchingControlValue = getControlValue(matchingControl.value);

    if (matchingControlValue === '') { return; }

    const valuesMatch = controlValue === matchingControlValue;
    if ((validation && !valuesMatch) || (!validation && valuesMatch)) { matchingControl.setErrors({ mustMatch: true }); }
  };
}

function getControlValue(value: any): any {
  return isString(value) ? value.toLowerCase() : value;
}

function isString(value: any): boolean {
  return typeof (value) === 'string';
}
