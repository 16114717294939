// tslint:disable: ban-types

import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ATTACH_VALIDATORS_CONTENT, FILE_VALID_MIME_TYPES, filesActionType
} from '@constants/forms.constant';
import { TOOLTIP_CONFIG } from '@constants/tooltip-config.constant';
import { AttachFileReloadIndicator, AttachFiles } from '@interfaces/attach-files.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';
import { fileValidators } from '@validators/attach-file.validator';

@Component({
  selector: 'app-attach-files',
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.scss'],
})
export class AttachFilesComponent implements OnInit, OnChanges {
  @Input() public attachFiles: AttachFiles;
  @Input() public pageContent;
  @Input() public preloadedFiles?: any;
  @Output() public fileEmitter: EventEmitter<any> = new EventEmitter();
  private fileKeysActionIndicator: Array<AttachFileReloadIndicator> = [];

  @ViewChildren('fileUpload') fileUploads: QueryList<any>;

  public attachFormatContent = ATTACH_VALIDATORS_CONTENT.attachValidators;
  public form: UntypedFormGroup;
  public tooltipConfig = TOOLTIP_CONFIG;
  public validMimeTypes = FILE_VALID_MIME_TYPES;

  public get hiddenFilesNamesList(): boolean {
    return this.attachFiles.files.every(attachFile => this.fileNameControlValue(attachFile.fileIndex) === '');
  }

  constructor(
    public utils: Utils,
    public font: FontService,
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.utils.inputHasChanged(changes.attachFiles)) { return; }
    const oldFormValue = this.form ? this.form.value : null;
    this.createForm();
    if (!oldFormValue) { return; }
    changes.attachFiles.currentValue.files.forEach(({ fileIndex }) => {
      if (!Object.keys(oldFormValue).includes(fileIndex) || oldFormValue[fileIndex].fileName === '') { return; }
      this.form.controls[fileIndex].setValue(oldFormValue[fileIndex]);
    });
  }

  public ngOnInit(): void {
    this.createForm();
    if (!this.preloadedFiles) { return; }
    Object.keys(this.preloadedFiles).forEach(key => {
      if (!this.form.controls[key]) { return; }
      this.form.controls[key].setValue(this.preloadedFiles[key]);
    });
  }

  public createForm(): void {
    this.form = this.createFormGroup();
    this.form.setValidators(fileValidators(this.attachFiles.files.map(file => file.fileIndex)));
    this.form.updateValueAndValidity();
    this.form.valueChanges.subscribe(() => this.emitForm());
    this.emitForm();
  }

  public createFormGroup(): UntypedFormGroup {
    this.fileKeysActionIndicator = [];
    const group: any = {};
    this.attachFiles.files.forEach(attachFile => {
      const { required: isRequired, fileIndex } = attachFile;
      const required = isRequired ? Validators.required : [];
      group[fileIndex] = new UntypedFormControl('', required);
      this.fileKeysActionIndicator.push({ fileIndex, action: filesActionType.none });
    });
    return this.formBuilder.group(group);
  }

  public onFileSelect(event: any, fileIndex: string, fileDefault?: any): void {
    if (!fileDefault && (!event || !event.target.files.length)) { return; }
    const insertEvent = this.isInsertEvent(fileIndex);
    if (!insertEvent) { this.getFormFileControl(fileIndex).setValue(''); }
    const file = fileDefault ? fileDefault : event.target.files[0];
    this.fileKeysActionIndicator.find((fileKeyIndicator) => fileKeyIndicator.fileIndex === fileIndex).action =
      fileDefault ? filesActionType.none : insertEvent ? filesActionType.insert : filesActionType.update;
    this.getFormFileControl(fileIndex).setValue(file);
  }

  public deleteFile(fileIndex: string): void {
    this.getFormFileControl(fileIndex).setValue('');
    const file = this.fileUploads.find(fileUpload => fileUpload.nativeElement.id === fileIndex);
    file.nativeElement.value = null;
    this.fileKeysActionIndicator.find((fileKeyIndicator) => fileKeyIndicator.fileIndex === fileIndex).action = filesActionType.delete;
    this.fileUploads.filter(fileUpload => fileUpload.nativeElement.id !== fileIndex).forEach(fileUpload => {
      const controlValue = this.fileControlValue(fileUpload.nativeElement.id);
      if (controlValue) { this.onFileSelect(null, fileUpload.nativeElement.id, controlValue); }
    });
  }

  public getAttachValidatorClass(validator: string): string {
    const file = this.attachFiles.files.some(attachFile => Boolean(this.fileNameControlValue(attachFile.fileIndex)));
    if (!file) { return 'file-default'; }
    const validatorClass = this.attachFiles.files.some(attachFile => this.getFormFileControl(attachFile.fileIndex).hasError(validator));
    if (validatorClass) { return 'file-default file-invalid'; }
    return 'file-default file-valid';
  }

  public disabledFormFileField(fileIndex: string): boolean {
    const actualAttachFile = this.attachFiles.files.find(attachFile => attachFile.fileIndex === fileIndex);
    if (this.isValidControlValue(fileIndex)) { return true; }
    const previousAttachFileOrder = actualAttachFile.order - 1;
    const previousAttachFile = this.attachFiles.files.find(attachFile => attachFile.order === previousAttachFileOrder);
    const allPreviousRequired = this.attachFiles.files.every(
      (attachFile) => attachFile.order > previousAttachFileOrder ? true : attachFile.required
    );
    return previousAttachFile && allPreviousRequired && !this.isValidControlValue(previousAttachFile.fileIndex);
  }

  private isValidControlValue(fileIndex: string): boolean {
    return this.fileNameControlValue(fileIndex) !== '' && this.getFormFileControl(fileIndex).valid;
  }

  public getFormFileControl(fileIndex: string): AbstractControl {
    if (this.form) { return this.form.get(fileIndex); }
  }

  public isInsertEvent(fileIndex: string): boolean {
    return !this.getFormFileControl(fileIndex).value;
  }

  public fileNameControlValue(fileIndex: string): string {
    const file = this.fileControlValue(fileIndex);
    if (file !== '') { return file.name; }
    return '';
  }

  public fileControlValue(fileIndex: string): any {
    return this.getFormFileControl(fileIndex).value;
  }

  public getFormFileSize(fileIndex: string): string {
    const controlValue = this.fileControlValue(fileIndex);
    return controlValue ? `${Math.round(controlValue.size / 1000)}KB` : null;
  }

  private emitForm(): void {
    const keyList = [];
    const fileList = [];
    const actionList = [];
    Object.entries(this.form.value).forEach(([key, value]) => {
      if (value !== '' && this.form.controls[key].valid) {
        const fileIndicator = this.fileKeysActionIndicator.find((fileKeyIndicator) => fileKeyIndicator.fileIndex === key);
        keyList.push(key);
        fileList.push(value);
        actionList.push(fileIndicator.action);
      }
    });
    this.fileEmitter.emit({ keyList, fileList, actionList });
  }
}
