export const DEFAULT_MAP_ZOOM = 18;

export const PRIVATE_SITE_LOGIN_PATH = 'ingreso';

export const ONE_YEAR_IN_MILLISECONDS = 31536000000;

export const SCREEN_BREAKPOINTS = {
  TABLET: 1085,
  MEDIUM_MOBILE: 768,
  SMALL_MOBILE: 481,
};

export const FILE_TEST = {
  fileName: 'test.pdf',
  contentType: 'application/pdf',
  key: 'clientFiles',
  personRut: '19',
  base64File: ''
};
