import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VIEW_WITH_H1_TAG } from '@constants/info-banner.constant';
import { Banner, BannerStyle, BannerType } from '@interfaces/banner.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent implements OnInit {
  @Input() public banner: Banner;
  @Input() public type: BannerType;
  @Input() public style: BannerStyle;
  @Input() public loading = false;

  constructor(
    public font: FontService,
    private navService: NavigationService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.setHighlightedLine();
  }

  private setHighlightedLine() {
    if (!this.banner || this.banner.highlightedLine ) { return; }
    this.banner.highlightedLine = 'highlighted-second';
  }

  public goToUrl() {
    this.navService.goTo(this.banner.url);
  }

  public get styleClass() {
    return this.style || '';
  }

  public get iconClass() {
    return this.banner ? `i-${this.banner.icon}` : '';
  }

  public get infoBannerClass() {
    if (!this.banner) { return ''; }
    const { highlightedLine } = this.banner;
    return `${highlightedLine || ''} ${this.type || ''}`;
  }

  public get showWithH1Tag(): boolean {
    return VIEW_WITH_H1_TAG.includes(this.router.url);
  }

}
