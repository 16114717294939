import { Mockup } from '@interfaces/mockup.interface';

export const GET_ANNUAL_MEMORY_LINKS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              memoria_anual: [
                {
                  titulo: 'Memoria anual 2019',
                  url: 'Memoria-Anual/Memoria-2019.pdf'
                },
                {
                  titulo: 'Memoria anual 2018',
                  url: 'Memoria-Anual/Memoria-2018.pdf'
                },
                {
                  titulo: 'Memoria anual 2017',
                  url: 'Memoria-Anual/Memoria_2017.pdf'
                },
                {
                  titulo: 'Memoria anual 2016',
                  url: 'Memoria-Anual/Memoria-2016.pdf'
                },
                {
                  titulo: 'Memoria anual 2015',
                  url: 'Memoria-Anual/Memoria-2015.pdf'
                },
                {
                  titulo: 'Memoria anual 2014',
                  url: 'Memoria-Anual/Memoria-2014.pdf'
                },
                {
                  titulo: 'Memoria anual 2013',
                  url: 'Memoria-Anual/Memoria-2013.pdf'
                },
                {
                  titulo: 'Memoria anual 2012',
                  url: 'Memoria-Anual/Memoria-2012.pdf'
                },
                {
                  titulo: 'Memoria anual 2011',
                  url: 'Memoria-Anual/Memoria-2011.pdf'
                },
                {
                  titulo: 'Memoria anual 2010',
                  url: 'Memoria-Anual/Memoria-2010.pdf'
                },
                {
                  titulo: 'Memoria anual 2009',
                  url: 'Memoria-Anual/Memoria-2009.pdf'
                },
                {
                  titulo: 'Memoria anual 2008',
                  url: 'Memoria-Anual/Memoria-2008.pdf'
                }
              ]
            }
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
