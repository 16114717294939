import { Component } from '@angular/core';
import { ModalTransferPensionOptionComponent } from '@components/modal-transfer-pension-option/modal-transfer-pension-option.component';
import { AFFILIATE_TRANSFER_OPTIONS } from '@constants/modal-transfer-pension-option.constant';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';

@Component({
  selector: 'app-transfer-pension-option',
  templateUrl: './transfer-pension-option.component.html',
  styleUrls: ['./transfer-pension-option.component.scss'],
})

export class TransferPensionOptionComponent {
  public affiliateTransferOptions = AFFILIATE_TRANSFER_OPTIONS;

  constructor(
    public font: FontService,
    private modalService: ModalService,
  ) { }

  public openModal(): void {
    this.modalService.openModal(ModalTransferPensionOptionComponent, null);
  }
}
