import { Component, Input } from '@angular/core';

import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() public pathNames: Array<string>;

  constructor(
    public font: FontService,
  ) { }
}
