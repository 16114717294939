import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_FAMILY_ALLOWANCE_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        banner: {
          id: 21
        },
        asignacion_familiar_seccion: [
          {
            id: 1,
            texto_boton: 'Pago PreviRed',
            url_boton: 'https://www.previred.com/web/previred/',
            tabla: {
              'Hasta $336.055': '$13.155',
              'Entre $336.056 y hasta $490.844': '$8.073',
              'Entre $409.845 y hasta $765.550': '$2.551',
              'Desde $765.551': '$0'
            },
            asignacion_familiar: 1,
            idioma: 'es',
            titulo: '<div>\n<div>Estos son los montos de este beneficio, vigentes a marzo de 2020.</div>\n</div>',
            descripcion: '<div>\n<div><strong>El c&aacute;lculo considera el tramo de tu remuneraci&oacute;n.</strong></div>\n</div>'
          }
        ],
        topes_mensuales: [
          {
            id: 1,
            texto_boton: 'Ver en SPensiones.cl',
            url_boton: 'https://www.spensiones.cl/',
            tabla: {
              'Cotizaciones obligatoria de AFP, Salud y Ley de Accidentes del Trabajo': '80,2 UF',
              'Seguro de Cesantía': '120,3 UF'
            },
            asignacion_familiar: 1,
            idioma: 'es',
            titulo: '<div>\n<div>Topes mensuales</div>\n</div>',
            descripcion: '<div>\n<div><strong>La Superintendencia de Pensiones public&oacute; los nuevos topes imponibles, para el c&aacute;lculo de las Cotizaciones Obligatorias de AFP, Accidentes de Trabajo y Seguro de Cesant&iacute;a.</strong></div>\n</div>'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};
