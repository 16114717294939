<div class="step-container">
  <div class="title secondary" [ngClass]="font?.size">{{ content?.title | translate }}</div>
  <div class="dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="content?.description | translate"></div>

  <form class="form-container" [formGroup]="form" autocomplete="off">

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.names | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="name" [maxLength]="namesMaxLength" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('name')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('name')">{{ errors.patternLetters | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.lastNames | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="lastName" [maxLength]="namesMaxLength" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('lastName')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('lastName')">{{ errors.patternLetters | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.rut | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="12.345.678-9" [maxLength]="rutMaxLength" [formatRut]="rut" rutInput required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('rut')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="!requiredError('rut') && rut.hasError('validRut')">{{ errors.invalidRut | translate }}</mat-error>
      </mat-form-field>

      <div class="serial-number-container">
        <mat-form-field [ngClass]="font?.size">
          <mat-label>{{ labels.serialNumber | translate }}</mat-label>
          <input [ngClass]="font?.size" matInput type="text" formControlName="serialNumber" placeholder="519654784" [maxLength]="serialNumberMaxLength" required>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size">{{ errors.required | translate }}</mat-error>
        </mat-form-field>
        <div class="button i-disclaimer-info" (click)="openSerialNumberInfo()"></div>
      </div>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.region | translate }}</mat-label>
        <mat-select formControlName="region" required>
          <mat-option *ngFor="let region of regions" [value]="region.code">{{ region.description | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.commune | translate }}</mat-label>
        <mat-select formControlName="commune" required>
          <mat-option *ngFor="let commune of communes" [value]="commune.code">{{ commune.description | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.address | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="address" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('address')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('address')">{{ errors.patternAddress | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.phone | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax" formControlName="phone" placeholder="987654321" [maxLength]="phoneNumberLength" [minLength]="phoneNumberLength" required digitsOnly>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('phone')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="phone.hasError('min') || phone.hasError('max')">{{ errors.invalidPhone | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.email | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="email" formControlName="email" (blur)="validateEmailDomain()" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getEmailInputErrorMessage()">{{ getEmailInputErrorMessage() | translate }}</mat-error>
      </mat-form-field>
    </div>

    <ion-button class="button primary form-button" (click)="goNextStep()" [disabled]="form.invalid" >{{ buttons.next | translate }}</ion-button>
    <ion-button class="button secondary form-button" (click)="goPreviousStep()">{{ buttons.previous | translate }}</ion-button>
  </form>
</div>
