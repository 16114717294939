import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class VitaliaService {

    private enableVitaliaIcon = new BehaviorSubject<boolean>(true);

    public showVitaliaIcon(state: boolean): void {
        this.enableVitaliaIcon.next(state);
    }

    public vitaliaStatusObs(): Observable<any> {
        return this.enableVitaliaIcon.asObservable();
    }
}
