import { CONTACT_CENTER } from '@constants/plan-vital.constant';

const contactCenterKey = 'CONTACT_CENTER';

export const CALL_CENTER = {
    title: `${contactCenterKey}.TITLE`,
    callButton: `<p><strong>${CONTACT_CENTER.MASK}</strong></p>`,
    buttons: {
      click2Call: `${contactCenterKey}.BUTTONS.CLICK_2_CALL`,
      cobrowsing: `${contactCenterKey}.BUTTONS.COBROWSING`,
      send: `${contactCenterKey}.BUTTONS.SEND`,
      back: `${contactCenterKey}.BUTTONS.BACK`,
    },
    click2Call: {
      title: `${contactCenterKey}.CLICK_2_CALL.TITLE`,
      description: `${contactCenterKey}.CLICK_2_CALL.DESCRIPTION`,
      form: {
        name: `${contactCenterKey}.CLICK_2_CALL.FORM.NAME`,
        rut: `${contactCenterKey}.CLICK_2_CALL.FORM.RUT`,
        phone: `${contactCenterKey}.CLICK_2_CALL.FORM.PHONE`,
        reason: `${contactCenterKey}.CLICK_2_CALL.FORM.REASON`,
      },
      errors: {
        required: `${contactCenterKey}.CLICK_2_CALL.ERRORS.REQUIRED`,
        invalid: `${contactCenterKey}.CLICK_2_CALL.ERRORS.INVALID`
      },
      successMessage: `${contactCenterKey}.CLICK_2_CALL.SUCCESS`,
    },
    cobrowsing: {
      title: `${contactCenterKey}.COBROWSING.TITLE`,
      description: `${contactCenterKey}.COBROWSING.DESCRIPTION`,
      enterCodeInstruction: `${contactCenterKey}.COBROWSING.ENTER_CODE_INSTRUCTION`,
      screenshareButton: `${contactCenterKey}.COBROWSING.SCREENSHARE_BUTTON`,
      disabledScreenshareButton: `${contactCenterKey}.COBROWSING.DISABLED_SCREENSHARE_BUTTON`,
      stopScreenshareButton: `${contactCenterKey}.COBROWSING.STOP_SCREENSHARE_BUTTON`,
      recomendation: `${contactCenterKey}.COBROWSING.RECOMENDATION`,
      errors: {
        invalid: `${contactCenterKey}.COBROWSING.ERRORS.INVALID`
      },
    }
};
