import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SerialNumberModalComponent } from '@components/serial-number-modal/serial-number-modal.component';
import {
  NAMES_MAX_LENGTH,
  PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_MAX,
  PHONE_NUMBER_MIN,
  RUT_MAX_LENGTH,
  SERIAL_NUMBER_MAX_LENGTH,
} from '@constants/forms.constant';
import { ButtonsCaiForm, ErrorsCaiForm, LabelsCaiForm } from '@interfaces/cai.interface';
import { Region, Section, SelectOption } from '@interfaces/general.interface';
import { CAI_FORM as caiForm } from '@pages-content/cai.constant';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { FormUtils } from '@utils/form.utils';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-personal-info-step',
  templateUrl: './personal-info-step.component.html',
  styleUrls: ['./personal-info-step.component.scss'],
})
export class PersonalInfoStepComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public regions: Array<Region> = [];
  @Output() public next = new EventEmitter();
  @Output() public previous = new EventEmitter();

  public content: Section = caiForm.personalInfoStep;
  public labels: LabelsCaiForm = caiForm.labels;
  public errors: ErrorsCaiForm = caiForm.errors;
  public buttons: ButtonsCaiForm = caiForm.buttons;
  public namesMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public serialNumberMaxLength = SERIAL_NUMBER_MAX_LENGTH;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;

  public communes: Array<SelectOption> = [];

  public get rut() { return this.form.get('rut'); }
  public get region() { return this.form.get('region'); }
  public get commune() { return this.form.get('commune'); }
  public get phone() { return this.form.get('phone'); }
  public get email() { return this.form.get('email'); }


  constructor(
    public font: FontService,
    private formUtils: FormUtils,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.regionSubscription();
  }

  private regionSubscription(): void {
    this.commune.disable();
    this.region.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        () => { this.communes = this.formUtils.getCommunes(this.commune, this.region, this.regions); }
      );
  }

  public getEmailInputErrorMessage(): string {
    const requiredEmail = this.requiredError('email');
    const invalidEmail = this.patternError('email') || this.email.hasError('email');
    return requiredEmail ? this.errors.required : invalidEmail ? this.errors.invalidEmail : this.errors.invalidEmailDomain;
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }

  public openSerialNumberInfo(): void {
    this.modalService.openModal(SerialNumberModalComponent, null);
  }

  public async validateEmailDomain(): Promise<void> {
    return await this.formUtils.validateEmailDomain(this.email);
  }

  public goPreviousStep(): void {
    this.previous.emit();
  }

  public goNextStep(): void {
    this.next.emit();
  }

}
