
import { Validators } from '@angular/forms';
import { VALIDATORS } from '@constants/forms.constant';
import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { SelectOption } from '@interfaces/general.interface';
import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { RadioButtonInputListElement } from '@interfaces/radio-button-input-list-element.interface';
import { Voucher } from '@interfaces/voucher.interface';

const pageKey = 'AFFILIATE.SURVIVOR_PENSION_FORM';
const requestSurvivorPensionKey = `${pageKey}.STEPS`;
const savedModal = 'MODALS.SAVED';
const toastKey = `${pageKey}.TOAST`;

const clientCheckKey = `${requestSurvivorPensionKey}.CLIENT_CHECK`;
const deceasedClientBackgroundKey = `${pageKey}.STEPS.DECEASED_CLIENT_BACKGROUND`;
const survivorMaritalStatusKey = `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.MARITAL_STATUS.OPTIONS`;
const transferKey = `${pageKey}.STEPS.TRANSFER`;
const voucherKey = `${pageKey}.STEPS.VOUCHER.SUCCESS`;
const failedSubmissionKey = `${pageKey}.STEPS.VOUCHER.FAILED`;
const otherBackgroundsSection = `${deceasedClientBackgroundKey}.FORM.OTHER_BACKGROUNDS_SECTION.FIELDS.BACKGROUNDS`;
const callingUserSection = `${deceasedClientBackgroundKey}.FORM.CALLING_USER`;
const paymentStep = `${pageKey}.STEPS.PAYMENT`;
const paymentMethodsKey = `${paymentStep}.PAYMENT_METHODS`;
const beneficiariesKey = `${paymentStep}.BENEFICIARIES`;
const preliminaryKey = `${paymentStep}.PRELIMINARY`;
const accrualKey = `${paymentStep}.ACCRUAL`;

export type DeceasedClientBackgroundsKeys =
  'isWorkAccident' |
  'pensionSavings' |
  'agreed' |
  'anotherCountry' |
  'transferBalances' |
  'preliminary' |
  'normalAccrual' |
  'scheduledWithdrawal' |
  'annuity';

export const SURVIVOR_MARITAL_STATUS_DESCRIPTION = {
  A: `${survivorMaritalStatusKey}.A`,
  S: `${survivorMaritalStatusKey}.S`,
  C: `${survivorMaritalStatusKey}.C`,
  V: `${survivorMaritalStatusKey}.V`,
  D: `${survivorMaritalStatusKey}.D`,
  P: `${survivorMaritalStatusKey}.P`,
};

export const SURVIVOR_ACCRUAL_OPTIONS = [
  { code: 'normalAccrual', description: `${accrualKey}.OPTIONS.0`,
    definitions: [{ title: `FORM_DEFINITIONS.7.TITLE`, definition: `FORM_DEFINITIONS.7.TEXT`}], },
  { code: 'scheduledWithdrawal', description: `${accrualKey}.OPTIONS.1`,
    definitions: [{ title: `FORM_DEFINITIONS.8.TITLE`, definition: `FORM_DEFINITIONS.8.TEXT`}], },
  { code: 'annuity', description: `${accrualKey}.OPTIONS.2`,
    definitions: [{ title: `FORM_DEFINITIONS.9.TITLE`, definition: `FORM_DEFINITIONS.9.TEXT`}], },
] as Array<SelectOption>;

export const SURVIVOR_PENSION_FORM_CONTENT = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  introduction: {
    title: `${pageKey}.INTRODUCTION.TITLE`,
    description: `${pageKey}.INTRODUCTION.DESCRIPTION`,
    buttonAction: `${pageKey}.INTRODUCTION.BUTTON_ACTION`,
  },
  folio: `${pageKey}.FOLIO`,
  requestProcess: `${pageKey}.REQUEST_PROCESS`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`,
  },
  steps: {
    clientCheck: {
      label: `${clientCheckKey}.LABEL`,
    },
    deceasedClientBackground: {
      label: `${deceasedClientBackgroundKey}.LABEL`,
      title: `${deceasedClientBackgroundKey}.TITLE`,
      buttons: {
        next: `${deceasedClientBackgroundKey}.BUTTONS.NEXT`,
        saveContinueLater: `${transferKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
      form: {
        callingUser: {
          title: `${callingUserSection}.TITLE`,
          description: `${callingUserSection}.DESCRIPTION`,
          fields: {
            names: `${callingUserSection}.FIELDS.NAMES`,
            lastName: `${callingUserSection}.FIELDS.LAST_NAME`,
            cellphone: `${callingUserSection}.FIELDS.PHONE`,
            rut: `${callingUserSection}.FIELDS.RUT`,
            email: `${callingUserSection}.FIELDS.EMAIL`,
          },
          errors: {
            required: `${deceasedClientBackgroundKey}.FORM.ERRORS.REQUIRED`,
            patternLetters: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_LETTERS`,
            invalidEmail: `${deceasedClientBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          },
        },
        deceasedClientSection: {
          title: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.TITLE`,
          description: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.DESCRIPTION`,
          fields: {
            names: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.NAMES`,
            lastName: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.LAST_NAME`,
            secondSurname: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.SECOND_SURNAME`,
            rut: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.RUT`,
            maritalStatus: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.MARITAL_STATUS.LABEL`,
            gender: {
              label: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.GENDER.LABEL`,
              female: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.GENDER.FEMALE`,
              male: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.GENDER.MALE`,
            },
            deceasedDate: `${deceasedClientBackgroundKey}.FORM.DECEASED_CLIENT_SECTION.FIELDS.DECEASED_DATE`,
          },
        },
        backgroundsSection: {
          title: `${deceasedClientBackgroundKey}.FORM.OTHER_BACKGROUNDS_SECTION.TITLE`,
          fields: {
            workSituation: `${otherBackgroundsSection}.WORK_SITUATION.TITLE`,
            backgrounds: {
              content: {
                options: {
                  yes: `${otherBackgroundsSection}.AFFILIATE.SELECTION.YES`,
                  no: `${otherBackgroundsSection}.AFFILIATE.SELECTION.NO`,
                },
                errors: {
                  required: `${deceasedClientBackgroundKey}.FORM.ERRORS.REQUIRED`,
                },
              },
              options: [
                {
                  code: 0,
                  text: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.WORK_ACCIDENT.TEXT`
                },
                {
                  code: 1,
                  text: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.PENSION_SAVINGS.TEXT`,
                  detail: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.PENSION_SAVINGS.DETAIL`,
                  definitions: [{ title: `FORM_DEFINITIONS.23.TITLE`, definition: `FORM_DEFINITIONS.23.TEXT`}],
                  keyList: 'paymentInstitutions',
                  bigField: true
                },
                {
                  code: 2,
                  text: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.AGREED.TEXT`,
                  detail: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.AGREED.DETAIL`,
                  definitions: [{ title: `FORM_DEFINITIONS.22.TITLE`, definition: `FORM_DEFINITIONS.22.TEXT`}],
                  keyList: 'paymentInstitutions',
                  bigField: true
                },
                {
                  code: 3,
                  text: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.ANOTHER_COUNTRY.TEXT`,
                  detail: `${otherBackgroundsSection}.AFFILIATE.OPTIONS.ANOTHER_COUNTRY.DETAIL`,
                  bigField: true
                },
              ] as Array<RadioButtonInputListElement>,
            },
          },
        },
        errors: {
          required: `${deceasedClientBackgroundKey}.FORM.ERRORS.REQUIRED`,
          patternLetters: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_LETTERS`,
          patternNumbers: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_NUMBERS`,
          patternAddress: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_ADDRESS`,
          patternAccount: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_ACCOUNT`,
          patternRutAccount: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_RUT_ACCOUNT`,
          patternVirtualAccount: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_VIRTUAL_ACCOUNT`,
          patternMatch: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_MATCH`,
          invalidRut: `${deceasedClientBackgroundKey}.FORM.ERRORS.INVALID_RUT`,
          mustMatchRut: `${deceasedClientBackgroundKey}.FORM.ERRORS.MUST_MATCH_RUT`,
          invalidPhone: `${deceasedClientBackgroundKey}.FORM.ERRORS.INVALID_PHONE`,
          invalidEmail: `${deceasedClientBackgroundKey}.FORM.ERRORS.INVALID_EMAIL`,
          invalidEmailDomain: `${deceasedClientBackgroundKey}.FORM.ERRORS.INVALID_EMAIL_DOMAIN`,
        },
      },
    },
    transfer: {
      label: `${transferKey}.LABEL`,
      title: `${transferKey}.TITLE`,
      question: `${transferKey}.QUESTION`,
      options: {
        yes: `${transferKey}.OPTIONS.YES`,
        no: `${transferKey}.OPTIONS.NO`,
      },
      selectAccount: `${transferKey}.SELECT_ACCOUNT`,
      disclaimerTitle: `${transferKey}.DISCLAIMER_TITLE`,
      disclaimerList: {
        saving: `${transferKey}.DISCLAIMER_LIST.SAVING`,
        padlock: `${transferKey}.DISCLAIMER_LIST.PADLOCK`,
        profitability: `${transferKey}.DISCLAIMER_LIST.PROFITABILITY`,
        waitTime: `${transferKey}.DISCLAIMER_LIST.WAIT_TIME`,
      },
      declaration: `${transferKey}.DECLARATION`,
      buttons: {
        submit: `${transferKey}.BUTTONS.SUBMIT`,
        back: `${transferKey}.BUTTONS.BACK`,
        saveContinueLater: `${transferKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
      table: {
        headers: {
          account: `${transferKey}.TABLE.HEADERS.ACCOUNT`,
        }
      }
    },
    payment: {
      label: `${paymentStep}.LABEL`,
      beneficiaries: {
        title: `${beneficiariesKey}.TITLE`,
        description: `${beneficiariesKey}.DESCRIPTION`,
        definitions: [{ title: `FORM_DEFINITIONS.4.TITLE`, definition: `FORM_DEFINITIONS.4.TEXT`}],
        buttons: {
          add: `${beneficiariesKey}.BUTTONS.ADD_BENEFICIARY`,
        },
      },
      preliminary: {
        title: `${preliminaryKey}.TITLE`,
        label: `${preliminaryKey}.LABEL`,
        definitions: [{ title: `FORM_DEFINITIONS.5.TITLE`, definition: `FORM_DEFINITIONS.5.TEXT`}],
        options: {
          yes: `${preliminaryKey}.OPTIONS.0`,
          no: `${preliminaryKey}.OPTIONS.1`,
        }
      },
      accrual: {
        title: `${accrualKey}.TITLE`,
        label: `${accrualKey}.LABEL`,
        definitions: [{ title: `FORM_DEFINITIONS.6.TITLE`, definition: `FORM_DEFINITIONS.6.TEXT`}],
      },
      paymentMethods: {
        disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
        rutDisclaimer: `${paymentMethodsKey}.RUT_DISCLAIMER`,
        accountDisclaimer: `${paymentMethodsKey}.VIRTUAL_ACCOUNT_DISCLAIMER`,
        formSection: {
          title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
          transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
          cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
          bank: {
            field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`,
          },
          account: {
            field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`,
          },
        },
        errors: {
          required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
          patternVirtualAccount: `${deceasedClientBackgroundKey}.FORM.ERRORS.PATTERN_VIRTUAL_ACCOUNT`,
        }
      },
      buttons: {
        next: `${paymentStep}.BUTTONS.NEXT`,
        back: `${paymentStep}.BUTTONS.BACK`,
      },
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
    },
  },
};

export const SURVIVOR_PENSION_FORM_VOUCHER: Voucher = {
  title: `${voucherKey}.TITLE`,
  description: `${voucherKey}.DESCRIPTION`,
  homeButton: `${voucherKey}.BUTTON`,
  download: `${voucherKey}.DOWNLOAD_PDF`
};

export const CLIENT_OPTION_DESEASED_AFFILIATED_ID = [
  'isWorkAccident',
  'pensionSavings',
  'pensionSavingsText',
  'agreed',
  'agreedText',
  'anotherCountry',
  'anotherCountryText'
];

export const CLIENT_OPTION_DESEASED_PAYMENT_ID = [
  'preliminary',
  'normalAccrual',
  'scheduledWithdrawal',
  'annuity',
];

export const SURVIVOR_PENSION_FORM_FAILED_SUBMISSION = {
  title: `${failedSubmissionKey}.TITLE`,
  description: `${failedSubmissionKey}.DESCRIPTION`,
  firstBtnText: `${failedSubmissionKey}.BUTTON`,
  iconName: 'i-error',
};

export type deceasedBackgroundFormKeyTypes =
  'backgrounds' |
  'callingUser' |
  'deceasedUser' |
  'workSituation';

export const ARRAY_DECEASED_AFFILIATE_PROPERTIES: Array<FormArrayItemProperties> = [
  { name: 'fullName', translated: true, style: ['bold'] },
  { name: 'birthdate', translated: true },
  { name: 'email', translated: true },
  { name: 'cellphone', translated: true },
];

export const DECEASED_BACKGROUNDS_PERSON_CONTROLS = [
  { key: 'names', validators: VALIDATORS.NAME },
  { key: 'lastName', validators: VALIDATORS.NAME },
  { key: 'secondSurname', validators: VALIDATORS.OPTIONAL_NAME },
  { key: 'rut', validators: VALIDATORS.RUT },
  { key: 'deceasedDate', validators: Validators.required },
  { key: 'maritalStatus', validators: Validators.required },
  { key: 'gender', validators: Validators.required },
] as Array<PersonBackgroundsField>;

export const RESUME_BACKGROUNDS_PERSON_CONTROLS = [
  { key: 'workSituation', validators: Validators.required }
] as Array<PersonBackgroundsField>;

export const RESUME_PERSON_CONTROLS = [
  { key: 'names', validators: VALIDATORS.NAME },
  { key: 'lastName', validators: VALIDATORS.NAME },
  { key: 'cellphone', validators: VALIDATORS.PHONE },
  { key: 'rut', validators: VALIDATORS.RUT },
  { key: 'email', validators: VALIDATORS.EMAIL },
] as Array<PersonBackgroundsField>;

export const SURVIVOR_PENSION_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};
