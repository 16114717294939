import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  HttpClientInterceptor
} from '@interceptors/http-client-interceptor/http-client-interceptor';
import { CertificateValues, QuotationsCertificateValues } from '@interfaces/certificates.interface';
import {
  ValidateCertificate, ValidateCertificateParams
} from '@interfaces/validate-certificate.interface';
import {
  GET_CERTIFICATE_MOCKUP, VALIDATE_CERTIFICATE_MOCKUP
} from '@services/certificates/mocks/certificates.mock';
import { ErrorUtils } from '@utils/error.utils';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {
  private baseUrl = `${environment.proxyApiUrl}certificates`;
  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils
  ) { }

  public validateCertificate(data: ValidateCertificateParams): Observable<ValidateCertificate> {
    const url = `${this.baseUrl}/validate-certificate`;
    return this.http.get(url, VALIDATE_CERTIFICATE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getCertificate(type: string, values: CertificateValues): Observable<any> {
    const { rut, periodCode, productType, accountType } = values;
    const data = { periodCode, productType, accountType };
    return this.http.get(
      `${environment.certificatesUrl}certificates/${type}/public?rut=${rut}&format=email`, GET_CERTIFICATE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
  public getQuotationsCertificate(values: QuotationsCertificateValues): Observable<any> {
    const { rut, startDate, endDate, productType } = values;
    const re = /\./gi;
    return this.http.get(
      // tslint:disable-next-line: max-line-length
      `${environment.certificatesUrl}certificates/quotations/getPublic?rut=${rut.replace(re, '')}&startDate=${startDate}&endDate=${endDate}&productType=${productType}`, GET_CERTIFICATE_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
