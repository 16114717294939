import { Component, Input } from '@angular/core';
import { ModalAlertComponent } from '@components/modal-alert/modal-alert.component';
import {
  ModalFileDownloadComponent
} from '@components/modal-file-download/modal-file-download.component';
import {
  ModalInfoDownloadableFormatsComponent
} from '@components/modal-info-downloadable-formats/modal-info-downloadable-formats.component';
import {
  ModalInformationComponent
} from '@components/modal-information/modal-information.component';
import {
  ModalIntroductoryComponent
} from '@components/modal-introductory/modal-introductory.component';
import { ModalMandateComponent } from '@components/modal-mandate/modal-mandate.component';
import { ModalPrimaryComponent } from '@components/modal-primary/modal-primary.component';
import { PdfFile } from '@interfaces/general.interface';
import { Card, CardAlignment, CardPage, CardType } from '@interfaces/info-card.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent<ModalType> {

  @Input() public card: Card<ModalType>;
  @Input() public page: CardPage;
  @Input() public alignment: CardAlignment;
  @Input() public type: CardType;
  @Input() public loading = false;
  @Input() public showImages = true;

  public get isSimulator(): boolean { return this.page === 'simulator'; }
  public get styleModalButton(): string {
    return `button ${this.isSimulator ? 'primary' : 'secondary'}`;
  }

  public get infoCardClass(): string {
    return `${this.type || ''} ${this.alignment || ''}`;
  }

  public get styleClass(): string {
    const { style } = this.card;
    return style || '';
  }

  public get hasButtonModal(): boolean {
    return Boolean(this.card && !this.card.isRedirect && !this.card.customModal && this.card.modal && this.card.showMoreText);
  }

  public get hasCustomModal(): boolean {
    return Boolean(this.card && !this.card.isRedirect && this.card.modal && this.card.customModal);
  }


  public get hasShowMoreButtonUrl(): boolean {
    return Boolean(this.card && !this.card.isRedirect && this.card.showMoreUrl && this.card.showMoreText);
  }

  public get hasButtonSecondaryModal(): boolean {
    return Boolean(this.card && !this.card.isRedirect && this.card.secondaryModal && this.card.showMoreSecondaryText);
  }

  public get hasButtonUrl(): boolean {
    return Boolean(this.card && !this.card.isRedirect && this.card.requestText && this.card.requestUrl
      && !this.hasButtonModalIntroductory && !this.hasDownloadableFormats);
  }

  public get hasButtonRedirect(): boolean {
    return Boolean(this.card && this.card.isRedirect && this.card.redirectButton);
  }

  public get hasRedirectionButton(): boolean {
    return Boolean(this.card && !this.card.isRedirect && this.card.requestText && this.card.redirectionButtons);
  }

  public get hasMoreButtons(): boolean {
    return (this.hasButtonModal || this.hasShowMoreButtonUrl) &&
      (this.hasButtonUrl || this.hasButtonSecondaryModal || this.hasRedirectionButton);
  }

  public get hasButtonModalIntroductory(): boolean {
    return Boolean(this.card && !this.card.isRedirect && this.card.modal && this.card.modal.redirectButton);
  }

  public get hasDisabledOptionActive(): boolean {
    return Boolean(this.card.disabled);
  }

  public get hasDownloadableFormats(): boolean {
    return Boolean(this.card.downloadableFormats);
  }

  constructor(
    public font: FontService,
    private modalService: ModalService,
    public utils: Utils,
  ) { }

  public moreInformation(): Promise<void> {
    switch (this.page) {
      case 'paperwork-center':
        return this.openInformationModal();
      case 'saving':
        return this.openInformationModal();
      case 'multi-fund':
        return this.openMultiFundModal();
      case 'pensioner':
        return this.openModal();
    }
  }

  public customModal(): Promise<void> {
    return this.modalService.openModal(ModalMandateComponent, { information: this.card });
  }

  public async openModalIntroductory(url: string, modalIntro: any): Promise<void> {
    await this.modalService.openModal(ModalIntroductoryComponent, { url, modalIntro, showButton: true });
  }

  public async openSecondaryModal(): Promise<void> {
    const { title, description, pdfFile } = this.card.secondaryModal;
    await this.modalService.openModal(ModalFileDownloadComponent, { title, description, pdfFile });
  }

  public goToPrivateSite(redirectType: string): void {
    window.open(this.utils.getPrivateSiteRedirectUrl(redirectType), '_blank');
  }

  public goToAlimony(): void {
    window.open(this.utils.getAlimonySiteRedirectUrl(), '_blank');
  }


  public goTo(url: string): void {
    if (!url) { return; }
    window.open(url, '_blank');
  }

  public downloadFile(pdfFile: PdfFile): void {
    const { downloadUrl, downloadFileName } = pdfFile;
    this.utils.downloadFile(downloadUrl, downloadFileName);
  }

  private async openInformationModal(): Promise<void> {
    await this.modalService.openModal(ModalInformationComponent, { information: this.card.modal });
  }

  private async openMultiFundModal(): Promise<void> {
    const { title, description, data } = this.card.modal;
    const component = data ? ModalPrimaryComponent : ModalAlertComponent;
    const params = data ? { data: this.card.modal } : { title, description };
    await this.modalService.openModal(component, params);
  }

  private async openModal(): Promise<void> {
    const { title, description } = this.card.modal;
    await this.modalService.openModal(ModalAlertComponent, { title, description });
  }

  public async openModalDownloadableFormats() {
    await this.modalService.openModal(ModalInfoDownloadableFormatsComponent,
      { information: this.card.modal, downloadable: this.card.downloadableFormats, url: this.card.requestUrl });
  }

}
