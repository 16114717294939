<div class="header-mobile-container" [class.open-side-menu]="open">
  <ion-header class="header-mobile">
    <ion-toolbar class="toolbar">
      <ion-buttons class="buttons" slot="start">
        <ion-button class="button" [ngClass]="font?.size" (click)="openLoginModal()">
          <div class="icon i-account"></div>
        </ion-button>
      </ion-buttons>
      <ion-title class="toolbar-title">
        <div class="logo i-pv-white-logo" (click)="goTo(homeUrl)"></div>
      </ion-title>
      <ion-buttons class="buttons" slot="end">
        <ion-button class="button" [ngClass]="font?.size" (click)="openSideMenu()">Menú</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</div>

<ion-menu class="side-menu-container" side="end" content-id="main-content" (ionDidClose)="closeSideMenu()"
  (ionWillOpen)="setOpen(true)">
  <ion-content class="side-menu">
    <ion-list class="options primary" [hidden]="itemValid">
      <ion-button class="option button transparent" [ngClass]="font?.size" (click)="closeSideMenu(homeUrl)">
        <span class="title-button">Inicio</span>
      </ion-button>
      <ion-button class="option button transparent" [ngClass]="font?.size" *ngFor="let item of primaryOptions"
        (click)="setItem(item)">
        <span class="title-button">{{ item?.name | translate }}</span>
        <ion-icon class="navigation-icon" slot="end" name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-list>
    <ion-list class="options secondary" [hidden]="!itemValid">
      <ion-button class="back-button" [ngClass]="font?.size" (click)="setItem(null)">
        <ion-icon class="icon" slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
      <p class="title-item" [ngClass]="font?.size">{{ itemSelected?.name | translate }}</p>
      <ion-button
        class="option button transparent"
        *ngFor="let option of itemSelected?.options"
        href="{{option?.url}}"
        (click)="closeSideMenu(option?.url, $event)">
        <span class="title-button">{{ option?.name | translate }}</span>
      </ion-button>
    </ion-list>
    <div class="footer-options" [hidden]="itemValid">
      <ion-button class="option button transparent" [ngClass]="font?.size" [class.selected]="item?.url.includes(defaultModule)"
      *ngFor="let item of options" href="{{item.url}}" (click)="closeSideMenu(item.url)">
        <span class="title-button">{{ item.name | translate }}</span>
      </ion-button>
      <div class="accessibility-options">
        <ion-button class="option button transparent select i-glove-white" [ngClass]="font?.size"
          (click)="openLanguageOptions($event)">
          {{ languageSelected.name }}
          <ion-icon class="icon" name="chevron-down-outline"></ion-icon>
        </ion-button>
        <app-accessibility [isRedBackground]="true"></app-accessibility>
      </div>
    </div>
  </ion-content>
</ion-menu>