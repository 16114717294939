import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AccountFounds, FoundOptions } from '@interfaces/transfer-survivor-pension-beneficiaries.interface';
import { FontService } from '@providers/font/font.service';


@Component({
  selector: 'app-account-founds',
  templateUrl: './account-founds.component.html',
  styleUrls: ['./account-founds.component.scss'],
})
export class AccountFoundsComponent implements OnInit {
  public textInfo = 'AFFILIATE.TRANSFER_SURVIVOR_PENSION_BENEFICIARIES_FORM';
  public accountTextInfo = `${this.textInfo}.STEPS.ACCOUNTS`;
  public accountForm!: UntypedFormGroup;
  public accountFormValues: AccountFounds;
  public foundAccountSaved = false;
  @Input() foundOptions: {
    C: FoundOptions;
    D: FoundOptions;
    E: FoundOptions;
    NE: FoundOptions;
  };
  @Output() goBack: EventEmitter<void> = new EventEmitter();
  @Output() public formValues: EventEmitter<AccountFounds> = new EventEmitter();

  constructor(
    public formBuilder: UntypedFormBuilder,
    public font: FontService,
  ) {
  }

  ngOnInit(): void {
    this.accountForm = this.formBuilder.group({
      mandatoryContribution: this.formBuilder.group({
        firstOrigin: ['', Validators.required],
        firstDestiny: ['', Validators.required],
        secondOrigin: ['', Validators.required],
        secondDestiny: ['', Validators.required],
      }),
      voluntarySavings: this.formBuilder.group({
        firstOrigin: ['', Validators.required],
        firstDestiny: ['', Validators.required],
        secondOrigin: ['', Validators.required],
        secondDestiny: ['', Validators.required],
      }),
      agreedDeposit: this.formBuilder.group({
        firstOrigin: ['', Validators.required],
        firstDestiny: ['', Validators.required],
        secondOrigin: ['', Validators.required],
        secondDestiny: ['', Validators.required],
      }),
      voluntaryContribution: this.formBuilder.group({
        firstOrigin: ['', Validators.required],
        firstDestiny: ['', Validators.required],
        secondOrigin: ['', Validators.required],
        secondDestiny: ['', Validators.required],
      }),
      voluntaryAffiliate: this.formBuilder.group({
        firstOrigin: ['', Validators.required],
        firstDestiny: ['', Validators.required],
        secondOrigin: ['', Validators.required],
        secondDestiny: ['', Validators.required],
      }),
    });
  }

  public validateAccountsForm(): boolean {
    return this.accountForm.valid;
  }

  public saveAccountFounds() {
    this.accountFormValues = this.accountForm.value;
    this.foundAccountSaved = true;
  }

  public goBackSaveAccountFounds() {
    this.foundAccountSaved = false;
  }

  public sendForm() {
    this.formValues.emit(this.accountFormValues);
  }

  public goBackForm() {
    this.goBack.emit();
  }
}
