import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-inheritance-request-reasons',
  templateUrl: './inheritance-request-reasons.component.html',
  styleUrls: ['./inheritance-request-reasons.component.scss'],
})
export class InheritanceRequestReasonsComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: string;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public back = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();
  public maxDate = new Date();
  public form: UntypedFormGroup;
  public reasonOptions;
  public resumeReasonControls: Array<any>;

  public get isValidForm(): boolean { return this.form && this.form.valid; }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.createForm();
    this.reasonOptions = Object.entries(this.pageContent.form.options);
  }

  public sendForm(showModal = false ): void {
    if (!this.isValidForm) { return; }
    this.formData.emit(this.form.controls.reason.value);
    this.savedModal.emit(showModal);
  }

  public previousStep(): void {
    this.back.emit();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      reason: ['', Validators.required],
    });
    this.setFormValues();
  }

  private setFormValues(): void {
    const reason = {
      reason: this.formValues
    };
    this.form.patchValue(reason);
  }

}
