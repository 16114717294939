export const MONTHS_ES = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
export const MONTHS_EN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const MONTHS_HT = ['Jan', 'Fev', 'Mas', 'Avr', 'Me', 'Jen', 'Jiy', 'Out', 'Sept', 'Okt', 'Nov', 'Des'];

// tslint:disable:max-line-length
export const MONTHS_EXTENDED = [
  'GENERAL.MONTHS.JANUARY',
  'GENERAL.MONTHS.FEBRUARY',
  'GENERAL.MONTHS.MARCH',
  'GENERAL.MONTHS.APRIL',
  'GENERAL.MONTHS.MAY',
  'GENERAL.MONTHS.JUNE',
  'GENERAL.MONTHS.JULY',
  'GENERAL.MONTHS.AUGUST',
  'GENERAL.MONTHS.SEPTEMBER',
  'GENERAL.MONTHS.OCTOBER',
  'GENERAL.MONTHS.NOVEMBER',
  'GENERAL.MONTHS.DECEMBER'
];

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm' ;
