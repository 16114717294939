import * as _ from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpErrorCodes } from '@constants/http.constant';
import { environment } from '@environment';
import { Environment } from '@interfaces/environment.interface';
import { Mockup } from '@interfaces/mockup.interface';

@Injectable()
export class HttpClientInterceptor {
  private httpSuccessCode = 200;
  private headers = new HttpHeaders();
  private environment: Environment;

  constructor(private http: HttpClient) {
    this.environment = environment;
  }

  private mockedResponse(mockup: Mockup): Observable<any> {
    const responseStatus = (mockup.failures && (Math.random() > this.environment.mockedResponseSuccessRate)) ? 'failures' : 'success';
    const responseNumber = 0;
    const responseOptionsParams = {
      status: responseStatus === 'success' ? this.httpSuccessCode : httpErrorCodes.badRequest.code,
    };
    if (responseStatus === 'success') {
      responseOptionsParams['body'] = mockup[responseStatus][responseNumber].response;
    } else {
      responseOptionsParams['error'] = mockup[responseStatus][responseNumber].response;
    }
    const response = new HttpResponse(responseOptionsParams);
    const responseError = new HttpErrorResponse(responseOptionsParams);
    // tslint:disable-next-line deprecation
    return responseStatus === 'success' ? of(response.body).pipe(delay(this.environment.mockedResponseDelay)) : throwError(responseError);
  }

  private httpCall({ method, url, mockup, data, customHeaders }): Observable<any> {
    const headers = customHeaders ? customHeaders : this.headers;

    if (this.environment.mockHttpCalls) { return this.mockedResponse(_.cloneDeep(mockup)); }
    if ((method === 'get' || method === 'delete') && data) { return this.http[method]<any>(url, { headers, params: data }).pipe(take(1)); }

    return this.http[method]<any>(url, data, { headers }).pipe(take(1));
  }

  public delete(url: string, mockup: Mockup, data?: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data, customHeaders });
  }

  public get(url: string, mockup: Mockup, data?: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'get', url, mockup, data, customHeaders });
  }

  public post(url: string, data: any, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  public put(url: string, data: any, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }

  public patch(url: string, data: any, mockup: Mockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'patch', url, mockup, data, customHeaders });
  }
}

