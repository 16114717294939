import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { PensionPaymentPageModule } from '@modules/pensioner/pension-payment/pension-payment.module';
import { AssessmentPageModule } from './assessment/assessment.module';
import { PensionerRoutingModule } from './pensioner-routing.module';

@NgModule({
  imports: [
    PensionPaymentPageModule,
    PensionerRoutingModule,
    RouterModule,
    ComponentsModule,
    AssessmentPageModule,
  ],
})
export class PensionerModule { }
