import { Environment } from '@interfaces/environment.interface';

const BASE_URL = 'https://apiweb-dev.planvital.cl'; // 'http://35.186.243.77/';
export const environment: Environment = {
  name: 'develop',
  production: false,
  baseUrl: BASE_URL,
  clientsUrl: BASE_URL + '/clients/',
  proxyApiUrl: BASE_URL + '/public/',
  affiliateApplicationsUrl: BASE_URL + '/applications/',
  certificatesUrl: BASE_URL + '/certificates/',
  // cmsApiUrl: BASE_URL + '/v2/cms/',
  cmsApiUrl: 'https://apiweb-qa.planvital.cl/v2/cms/',
  withdrawalsUrl: BASE_URL + '/withdrawals/',
  recaptchaSiteKey: '6LdMsLcZAAAAAILUOD0ObyYhJHeWDjUeMSaPWFbC',
  googleMapsKey: 'AIzaSyDK_MHYxjJmdNh3TV5o3hN-K-viZxZzOmM',
  googleAnalyticsId: 'UA-171402309-2',
  firebaseConfig: {
    apiKey: 'AIzaSyAGbRArJYj704Prbq8iEtcAx88qBQFH_wk',
    authDomain: 'plan-vital-dev-qa.firebaseapp.com',
    databaseURL: 'https://plan-vital-dev-qa.firebaseio.com',
    projectId: 'plan-vital-dev-qa',
    storageBucket: 'plan-vital-dev-qa.appspot.com',
    messagingSenderId: '381542100011',
    appId: '1:381542100011:web:fce3ae69769217818cce6e',
    measurementId: 'G-2TW99JTRCX'
  },
  mockHttpCalls: false,
  mockedResponseDelay: 0,
  mockedResponseSuccessRate: 1,
  loginUrl: 'https://shckltn2.planvital.cl:444/NewLogin',
  privateSiteUrl: 'http://34.95.94.24/',
  fundsWithdrawalUrl: 'http://34.120.169.84/',
  investorProfileUrl: BASE_URL + '/clients/',
  publicFilesUrl: 'https://shckltn.planvital.cl/archivos/',
  paetUrl: 'http://35.244.214.2',
  transferUrl: 'http://35.244.180.109',
  botmakerChatbotVideoCall: 'https://go.botmaker.com/rest/webchat/p/F6ONOJNNN2/init.js',
  botmakerChatbotInternationalCall: 'https://go.botmaker.com/rest/webchat/p/GAZ3AQU2EY/init.js',
  urlAlimony: 'https://leypensiondealimentos-dev.planvital.cl/',
  urlPageInMaintenance: 'https://cus-web-ts-frt-cloud-maintenance-spa-dev-zkwhin7qca-uc.a.run.app',
  employersUrl: BASE_URL + '/employers/',
  urlLocalizatorBranchStores: 'https://staging-storeloc-planvital.partoo-store-locator.co/'
};
