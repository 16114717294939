const applicationFormProgressKey = 'APPLICATION_FORM_PROGRESS';
const disclaimerSectionKey = `${applicationFormProgressKey}.DISCLAIMER_SECTION`;
const validateUser = `${applicationFormProgressKey}.VALIDATE_USER`;
const validateUserOptionsKey = `${validateUser}.FORM.OPTIONS`;

export const APPLICATION_FORM_PROGRESS_PAGE_CONTENT = {
  title: `${applicationFormProgressKey}.TITLE`,
  description: `${applicationFormProgressKey}.DESCRIPTION`,
  subDescription: `${applicationFormProgressKey}.SUB_DESCRIPTION`,
  disclaimerSection: {
    question: `${disclaimerSectionKey}.QUESTION`,
    disclaimer: `${disclaimerSectionKey}.DISCLAIMER`,
  },
  validateUser: {
    question: `${validateUser}.QUESTION`,
    options: {
      yes: `${validateUser}.OPTIONS.YES`,
      not: `${validateUser}.OPTIONS.NOT`,
    },
    form: {
      rut: `${validateUser}.FORM.RUT`,
      search: `${validateUser}.FORM.SEARCH`,
      options: {
        folio: `${validateUserOptionsKey}.FOLIO`,
        phone: `${validateUserOptionsKey}.PHONE`,
        email: `${validateUserOptionsKey}.EMAIL`,
      }
    }
  },
  buttons: {
    submit: `${applicationFormProgressKey}.BUTTONS.SUBMIT`,
    back: `${applicationFormProgressKey}.BUTTONS.BACK`,
    goForm: `${applicationFormProgressKey}.BUTTONS.GO_FORM`,
    continue: `${applicationFormProgressKey}.BUTTONS.CONTINUE`,
  },
  errors: {
    required: `${applicationFormProgressKey}.ERRORS.REQUIRED`,
    invalidRut: `${applicationFormProgressKey}.ERRORS.REQUIRED`,
    invalidCellPhone: `${applicationFormProgressKey}.ERRORS.REQUIRED`,
    invalidEmail: `${applicationFormProgressKey}.ERRORS.REQUIRED`,
  }
};
