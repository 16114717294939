<div class="modal modal-get-certificate">
  <div class="modal-header">
    <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
      <ion-icon class="icon" name="close"></ion-icon>
    </ion-button>
    <div class="title-container">
      <div class="title-icon i-certificates-red"></div>
      <div class="title center-title" [ngClass]="font?.size" [innerHTML]="title | translate"></div>
      <div class="description" [ngClass]="font?.size" [innerHTML]="textContent?.description | translate"></div>
    </div>
  </div>

  <form class="get-certificate-form" [formGroup]="certificateForm">
    <div class="item-form">
      <div class="options">
        <app-drop-down-period *ngIf="includeControl('periodCode')" (selectedPeriodCode)="selectedPeriod($event)"
          [fieldsInput]="this.fields" [type]="this.type"></app-drop-down-period>

        <mat-form-field [ngClass]="font?.size" *ngIf="includeControl('productType')">
          <mat-label class="label">{{ textContent?.productTypeLabel | translate }}</mat-label>
          <mat-select [formControl]="productType" [ngClass]="font?.size">
            <mat-option [ngClass]="font?.size" *ngFor="let productTypeOption of productTypeOptions"
              [value]="productTypeOption.id">
              {{ productTypeOption.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field [ngClass]="font?.size" *ngIf="includeControl('accountType')">
          <mat-label class="label">{{ textContent?.accountTypeLabel | translate }}</mat-label>
          <mat-select [formControl]="accountType" [ngClass]="font?.size">
            <mat-option [ngClass]="font?.size" *ngFor="let accountTypeOption of accountTypeOptions"
              [value]="accountTypeOption.id">
              {{ accountTypeOption.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="options" *ngIf="customPeriodSelected">
        <mat-form-field [ngClass]="font?.size">
          <input disabled [ngClass]="font?.size" matInput [min]="minFromDate" [max]="todayCalendar"
            [matDatepicker]="fromDate" [placeholder]="textContent?.fromDateLabel | translate" formControlName="fromDate"
            disabled="true">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate startView="multi-year" disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field [ngClass]="font?.size">
          <input disabled [ngClass]="font?.size" matInput [min]="minToDate" [max]="todayCalendar"
            [matDatepicker]="toDate" [placeholder]="textContent?.toDateLabel | translate" formControlName="toDate"
            disabled="true">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate startView="multi-year" disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="item-form">
        <ion-item class="rut-box">
          <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ textContent?.rutLabel | translate }}
          </ion-label>
          <ion-input [formControl]="rut" type="text" maxLength="12" [formatRut]="rut" required></ion-input>
        </ion-item>
      </div>
    </div>
  </form>

  <div class="centered-container">
    <ion-button class="button action primary" [ngClass]="font?.size" [disabled]="!validForm()"
      (click)="getCertificate()">{{ textContent?.getCertificateButtonText | translate }}</ion-button>
  </div>
</div>