<div class="partial-content">
  <ion-button class="btn small secondary label medium save" [disabled]="form.invalid" (click)="sendForm(true)">
      {{ pageContent?.buttons.saveContinueLater | translate }}
      <div class="icon-result save"></div>
  </ion-button>
</div>
<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="sendForm()">
  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.unmarriedSection.title | translate }}</h3>
  <div class="form-section">
    <mat-checkbox formControlName="unmarried" color="primary" [ngClass]="font?.size">
      <div [innerHTML]=" pageContent?.unmarriedSection.unmarried | translate "></div>
    </mat-checkbox>
  </div>
  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.declarationSection.title | translate }}
  </h3>
  <div class="form-section second">
    <mat-radio-group class="option-container" formControlName="certificate">
      <p class="option-label" [ngClass]="font?.size">{{ pageContent?.declarationSection.label | translate }}</p>
      <mat-radio-button value="true" class="option" [class.selected]="isChecked('certificate', 'true')"
        [ngClass]="font?.size">
        {{ pageContent?.declarationSection.options.yes | translate }}
      </mat-radio-button>
      <mat-radio-button value="false" class="option" [class.selected]="isChecked('certificate', 'false')"
        [ngClass]="font?.size">
        {{ pageContent?.declarationSection.options.no | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="form-section second">
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.disclaimer"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
