import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@components/modal/modal.component';
import {
  STUDIES_CERTIFICATE_CONTENT, STUDIES_CERTIFICATE_PERIODS, STUDIES_CERTIFICATE_REQUIRED_FILES,
  STUDIES_CERTIFICATE_YEARS
} from '@constants/modal-studies-certificate.constant';
import { AttachFiles } from '@interfaces/attach-files.interface';
import { CertifyStudiesCertificate } from '@interfaces/certify-studies.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';

@Component({
  selector: 'app-modal-studies-certificate',
  templateUrl: './modal-studies-certificate.component.html',
  styleUrls: ['./modal-studies-certificate.component.scss'],
})
export class ModalStudiesCertificateComponent extends ModalComponent implements OnInit {
  public pageContent = STUDIES_CERTIFICATE_CONTENT;
  public studiesCertificate: CertifyStudiesCertificate;
  public periods = STUDIES_CERTIFICATE_PERIODS;
  public form: UntypedFormGroup;
  public loadData = false;
  public maxDate = new Date();
  public requiredFiles: AttachFiles;
  public years = STUDIES_CERTIFICATE_YEARS;

  public get buttonText(): string {
    return this.studiesCertificate ? this.pageContent.buttons.updateCertificate : this.pageContent.buttons.addCertificate;
  }
  public get attachedFile(): AbstractControl { return this.form.get('attachedFile'); }
  public get requestFiles(): boolean { return Boolean(this.requiredFiles) && this.requiredFiles.files.length > 0; }
  public get filesLoaded(): any {
    const filesObject = {};
    filesObject['certificate'] = this.studiesCertificate.attachedFile;
    return filesObject;
  }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.studiesCertificate = this.params.get('item');
    this.loadFiles();

    this.loadData = true;
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public getControl(control: string): AbstractControl {
    return this.form.controls[control];
  }

  public setDate(dateName: string): void {
    this.formUtils.setDateSelected(this.form, dateName);
  }

  public async addStudiesCertificate(): Promise<void> {
    if (this.form.invalid) { return; }
    const {
      year,
      period,
      fileName,
      attachedFile,
    } = this.form.value;
    const certifyStudiesCertificate: CertifyStudiesCertificate = {
      year,
      period,
      fileName,
      attachedFile,
    };
    await this.closeModal(certifyStudiesCertificate);
  }

  public setFiles(data: any): void {
    this.attachedFile.setValue(data.fileList[0]);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      year: [this.getFamilyBurdenAttribute('year'), Validators.required],
      period: [this.getFamilyBurdenAttribute('period'), Validators.required],
      fileName: [STUDIES_CERTIFICATE_REQUIRED_FILES.certificate.buttonLabel, Validators.required],
      attachedFile: [this.getFamilyBurdenAttribute('attachedFile'), Validators.required],
    });
  }

  private getFamilyBurdenAttribute(attribute: string): string {
    if (!this.studiesCertificate) { return ''; }
    return this.studiesCertificate[attribute];
  }

  private loadFiles(): void {
    this.requiredFiles = {
      files: Object.entries(STUDIES_CERTIFICATE_REQUIRED_FILES).map(entry => entry[1])
    };
  }
}
