import 'firebase/auth';
import 'firebase/remote-config';

import * as firebase from 'firebase/app';

import { Injectable } from '@angular/core';
import { FIREBASE_REMOTE_CONFIG, REMOTE_CONFIG_TIMES, SHUTDOWN_TRANSACTIONS_DEFAULT } from '@constants/firebase.constants';
import { environment } from '@environment';
import { ShutdownTransactions } from '@interfaces/firebase.interface';
import { FirebaseService } from '@services/firebase/firebase.abstract';

@Injectable()
export class FirebaseServiceImplemented implements FirebaseService {
  private remoteConfig: firebase.remoteConfig.RemoteConfig;

  public initializeFirebase(): void {
    if (this.isInitialized()) { return; }
    firebase.initializeApp(environment.firebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  }

  public async initializeRemoteConfig(): Promise<void> {
    if (this.remoteConfig) { return; }
    this.remoteConfig = firebase.remoteConfig();
    this.remoteConfig.settings = FIREBASE_REMOTE_CONFIG;
    await this.initializeDefaultConfig();
  }

  public async initializeDefaultConfig(): Promise<void> {
    this.remoteConfig.defaultConfig = ({
      ShutdownTransactionsPublicSite: JSON.stringify({}),
    });
    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: 5 * REMOTE_CONFIG_TIMES.MINUTE,
      fetchTimeoutMillis: REMOTE_CONFIG_TIMES.MINUTE,
    };

    await this.remoteConfig.fetchAndActivate()
      .then(() => {
        this.setShutdownConfig();
      })
      .catch((error) => console.error('Error fetching the remote config', error));
  }

  public isInitialized(): boolean {
    return Boolean(firebase.apps.length);
  }

  public async getRemoteConfigShutdownValues(): Promise<ShutdownTransactions> {
    await this.initializeRemoteConfig();
    const shutdownConfig: ShutdownTransactions = JSON.parse(this.remoteConfig.getValue('ShutdownTransactionsPublicSite').asString());
    return shutdownConfig;
  }

  private setShutdownConfig(): void {
    const shutdownConfig = JSON.parse(this.remoteConfig.getValue('ShutdownTransactionsPublicSite').asString());

    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledCertificates = shutdownConfig.disabledCertificates;
    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledPaperworkCenterAffiliate = shutdownConfig.disabledPaperworkCenterAffiliate;
    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledPaperworkCenterPensioned = shutdownConfig.disabledPaperworkCenterPensioned;
    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledPaperworkFollowUpAffiliate = shutdownConfig.disabledPaperworkFollowUpAffiliate;
    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledPaperworkFollowUpPensioned = shutdownConfig.disabledPaperworkFollowUpPensioned;
    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledPensionTypePensioned = shutdownConfig.disabledPensionTypePensioned;
    SHUTDOWN_TRANSACTIONS_DEFAULT.disabledSurvivorPensionPensioned = shutdownConfig.disabledSurvivorPensionPensioned;
  }
}
