<form [formGroup]="form" class="files-form" autocomplete="off" *ngIf="form">
  <div class="content">
    <p *ngIf="attachFiles.description" class="description" [ngClass]="font?.size">{{ attachFiles.description | translate
      }}</p>
    <div class="files-fields">
      <div class="file-input" *ngFor="let attachFile of attachFiles.files">
        <label for="{{attachFile.fileIndex}}" class="btn small secondary label"
          [class.disabled]="disabledFormFileField(attachFile.fileIndex)" [ngClass]="font?.size">
          <span [ngClass]="font?.size">{{ attachFile.buttonLabel | translate }}</span>
          <span class="icon document"></span>
        </label>
        <input #fileUpload id="{{attachFile.fileIndex}}" class="btn small secondary" type="file"
          [disabled]="disabledFormFileField(attachFile.fileIndex)" (change)="onFileSelect($event, attachFile.fileIndex)"
          accept="{{ validMimeTypes }}">
        <div class="optional-file" *ngIf="attachFile.required === false">
          <span class="optional-title" [ngClass]="font?.size">{{ attachFormatContent.optionalTitle | translate }}</span>
          <span class="icon i-info-blue" [matTooltip]="attachFormatContent.optionalTooltip | translate"
            [matTooltipPosition]="tooltipConfig.position" [matTooltipHideDelay]="tooltipConfig.delay"
            [attr.aria-label]="attachFormatContent.optionalTooltip | translate" [matTooltipClass]="font?.size"></span>
        </div>
      </div>
    </div>

    <div class="files-names" [hidden]="hiddenFilesNamesList || fileNameControlValue(attachFile.fileIndex) === '' "
      *ngFor="let attachFile of attachFiles.files">
      <div class="file-field" [class.error]="getFormFileControl(attachFile.fileIndex).invalid">
        <div class="file-texts" [ngClass]="font?.size">
          <p class="file-name">{{ fileNameControlValue(attachFile.fileIndex) }}</p>
          <p class="file-size">(<span class="file-size-digit">{{ getFormFileSize(attachFile.fileIndex) }}</span>)</p>
        </div>
        <button (click)="deleteFile(attachFile.fileIndex)" class="icon cross cross-gray"></button>
      </div>
    </div>
    <div class="attach-validators" [ngClass]="font?.size">
      <ul>
        <li [ngClass]="getAttachValidatorClass('nameExtension')"><span class="icon check"></span>{{
          attachFormatContent.attachFormat | translate }}</li>
        <li [ngClass]="getAttachValidatorClass('nameWithoutExtension')"><span class="icon check"></span>{{
          attachFormatContent?.attachExample | translate }}</li>
        <li [ngClass]="getAttachValidatorClass('maxFileSize')"><span class="icon check"></span>{{
          attachFormatContent?.attachMaxSize | translate }}</li>
        <li [ngClass]="getAttachValidatorClass('attachDifferentFiles')"><span class="icon check"></span>{{
          attachFormatContent?.attachDifferentFiles | translate }}</li>
      </ul>
    </div>
  </div>
</form>