<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="transfer-options-description-container">
    <div
      class="title primary"
      [ngClass]="font?.size"
      [innerHTML]="affiliateTransferOptions?.title | translate">
    </div>
  </div>
  <div class="button-container">
    <ion-button
      *ngFor="let option of affiliateTransferOptions.options"
      class="button transparent"
      [class.large]="largeButton"
      [ngClass]="font?.size"
      [innerHTML]="option?.name | translate"
      (click)="goTo(option?.url)">
    </ion-button>
  </div>
</div>
