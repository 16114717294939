<div class="comment-section" [class.starred-section]="comment.starred" *ngIf="comment">
  <div class="comment-container starred-mobile" *ngIf="comment.starred">
    <span class="starred-text" [ngClass]="font?.size">{{ pageContent?.comments.document_request | translate }}</span><div class="i-flag"></div>
  </div>
  <div class="comment-container" [class.starred-container]="comment.starred">
    <div class="avatar-container">
      <app-avatar [initials]="comment.authorName" size="medium" [bordered]="true" [colorSeed]="comment.authorName"></app-avatar>
    </div>
    <div class="info-container">
      <div class="userdata-container">
        <div class="head-section">
          <div class="name"[ngClass]="font?.size">{{ comment.authorName }}</div>
          <div class="date" [ngClass]="font?.size">{{ getFormattedDate(comment.createdAt) }}</div>
        </div>
        <div class="starred" *ngIf="comment.starred">
          <span class="starred-text"[ngClass]="font?.size">{{ pageContent?.comments.document_request | translate }}</span><div class="i-flag"></div>
        </div>
      </div>
      <div class="message" [ngClass]="font?.size">
        {{ getMessage() }}
        <span class="outstanding" (click)="showFullComment(true)" *ngIf="isLargeComment && collapsed">{{ pageContent?.comments.more | translate }}</span>
        <span class="outstanding" (click)="showFullComment(false)" *ngIf="isLargeComment && !collapsed">{{ pageContent?.comments.less | translate }}</span>
      </div>
    </div>
  </div>
</div>
