<form class="form-container" [formGroup]="form">
  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.label | translate }}</h3>
  <div class="form-section">
    <div *ngFor="let disclaimer of disclaimers" class="disclaimer-container">
      <p class="disclaimer" [ngClass]="[font?.size, disclaimer.icon]" [innerHTML]="disclaimer.text | translate"></p>
      <app-info-text-popup class="tooltip-icon" *ngIf="disclaimer.definitions" [definitions]="disclaimer.definitions" iconName="i-tooltip-blue">
        <div class="i-tooltip-blue"></div>
      </app-info-text-popup>
    </div>
    <div class="form-section second">
      <mat-checkbox formControlName="declaration" color="primary" [ngClass]="font?.size">
        {{ pageContent?.declaration | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div class="button-container">
    <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="form.invalid" (click)="confirm()">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>

</form>
