import * as _ from 'lodash';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators
} from '@angular/forms';
import { NAMES_MAX_LENGTH, RUT_MAX_LENGTH, VALIDATORS } from '@constants/forms.constant';
import {
  CLIENT_OPTION_DESEASED_AFFILIATED_ID, DECEASED_BACKGROUNDS_PERSON_CONTROLS,
  deceasedBackgroundFormKeyTypes, DeceasedClientBackgroundsKeys, RESUME_BACKGROUNDS_PERSON_CONTROLS,
  RESUME_PERSON_CONTROLS
} from '@constants/pages-content/survivor-pension-form.constants';
import { FieldsValidators, PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import {
  OptionList, RadioButtonInputValues
} from '@interfaces/radio-button-input-list-element.interface';
import {
  Backgrounds, CallingUser, DeceasedAffiliateBackgrounds, DeceasedAffiliateParams
} from '@interfaces/survivor-pension-form.interface';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';

@Component({
  selector: 'app-deceased-background-form',
  templateUrl: './deceased-background-form.component.html',
  styleUrls: ['./deceased-background-form.component.scss'],
})
export class DeceasedBackgroundFormComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: DeceasedAffiliateParams;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();
  public namesMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public maxDate = new Date();
  public form: UntypedFormGroup;
  public optionsList: OptionList[] = [];
  public resumeDeceasedPersonControls: Array<PersonBackgroundsField>;
  public resumeBackgroundPersonControl: Array<PersonBackgroundsField>;
  public resumePersonControls: Array<PersonBackgroundsField>;
  public deceasedFieldsValidators = { rut: { value: '' } } as FieldsValidators;
  public backgroundsValues: RadioButtonInputValues[];
  private clientOptionDeseadedAffiliateId = CLIENT_OPTION_DESEASED_AFFILIATED_ID;

  public get rut(): AbstractControl { return this.getControl('rut'); }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private utils: Utils,
    private formUtils: FormUtils,
    ) { }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public ngOnInit(): void {
    this.createForm();
  }

  public sendForm(showModal = false): void {
    if (this.form.invalid) { return; }

    const {
      name, lastName, secondSurname, rut, maritalStatusId, gender, deceasedDate, clientSituation,
      callingUserNames, callingUserLastname, callingUserCellphone, callingUserRut, callingUserEmail,
    } = this.form.value;
    const clientOptions = this.backgrounds;

    const callingUser = {
      name: callingUserNames,
      lastName: callingUserLastname,
      cellphone: callingUserCellphone,
      rut: this.utils.rutClean(callingUserRut),
      email: callingUserEmail,
    } as CallingUser;

    const deceasedAffiliateBackgrounds = {
      name,
      lastName,
      secondSurname,
      rut: this.utils.rutClean(rut),
      maritalStatusId,
      gender,
      deceasedDate,
      clientSituation,
    } as DeceasedAffiliateBackgrounds;

    const formValues = {
      callingUser,
      deceasedAffiliateBackgrounds,
      clientOptions,
    } as DeceasedAffiliateParams;
    this.savedModal.emit(showModal);
    this.formData.emit(formValues);
  }

  public validateForm(form: UntypedFormArray | UntypedFormGroup, keyForm: deceasedBackgroundFormKeyTypes): void {
    if (keyForm === 'backgrounds') {
      this.validateBackgroundsFormArray(form as UntypedFormArray);
    } else if (keyForm === 'callingUser') {
      this.validateCallingUserFormGroup(form as UntypedFormGroup);
    } else if (keyForm === 'deceasedUser') {
      this.validateDeceasedUserFormGroup(form as UntypedFormGroup);
    } else if (keyForm === 'workSituation') {
      this.validateWorkSituationUserFormGroup(form as UntypedFormGroup);
    }
  }

  private validateBackgroundsFormArray(form: UntypedFormArray): void {
    const pensionSavings = form.value[1].accepted;
    const agreed = form.value[2].accepted;
    const anotherCountry = form.value[3].accepted;
    this.form.patchValue({
      isWorkAccident: form.value[0].accepted,
      pensionSavings,
      pensionSavingsText: form.value[1].detail,
      agreed,
      agreedText: form.value[2].detail,
      anotherCountry,
      anotherCountryText: form.value[3].detail,
    });
    pensionSavings ?
      this.clearAndSetValidator('pensionSavingsText', Validators.required)
      : this.clearAndSetValidator('pensionSavingsText');
    agreed ?
      this.clearAndSetValidator('agreedText', Validators.required)
      : this.clearAndSetValidator('agreedText');
    anotherCountry ?
      this.clearAndSetValidator('anotherCountryText', Validators.required)
      : this.clearAndSetValidator('anotherCountryText');
  }

  private validateCallingUserFormGroup(form: UntypedFormGroup): void {
    const { names, lastName, cellphone, rut, email } = form.value;
    this.form.patchValue({
      callingUserNames: names,
      callingUserLastname: lastName,
      callingUserCellphone: cellphone,
      callingUserRut: rut,
      callingUserEmail: email,
    });
    this.deceasedFieldsValidators = {
      rut: {
        value: rut
      }
    };
  }

  private validateDeceasedUserFormGroup(form: UntypedFormGroup): void {
    const { names, lastName, secondSurname, rut, deceasedDate, maritalStatus, gender } = form.value;
    this.form.patchValue({
      name: names,
      lastName,
      secondSurname,
      gender,
      rut,
      maritalStatusId: maritalStatus,
      deceasedDate,
    });
  }

  private validateWorkSituationUserFormGroup(form: UntypedFormGroup): void {
    this.form.patchValue({
      clientSituation: form.get('workSituation').value
    });
  }

  private get backgrounds(): Array<Backgrounds> {
    const backgrounds: Array<Backgrounds> = [];

    Object.entries(this.form.value).forEach(([key, value]) => {
      if (typeof value !== 'boolean' || !value) { return; }

      const description = this.form.value[`${key}Text`];
      const background = { id: key as DeceasedClientBackgroundsKeys } as Backgrounds;

      if (description) { background['description'] = description; }

      backgrounds.push(background);
    });

    return backgrounds;
  }

  private createForm(): void {
    const values = this.getFormValues();
    const group = {
      name: [values.name, VALIDATORS.NAME],
      lastName: [values.lastName, VALIDATORS.NAME],
      secondSurname: [values.secondSurname, VALIDATORS.OPTIONAL_NAME],
      rut: [values.rut, VALIDATORS.RUT],
      maritalStatusId: [values.maritalStatusId, Validators.required],
      gender: [values.gender, Validators.required],
      deceasedDate: [values.deceasedDate, Validators.required],
      clientSituation: [values.clientSituation, Validators.required],
      isWorkAccident: [values.isWorkAccident, Validators.required],
      pensionSavings: [values.pensionSavings, Validators.required],
      pensionSavingsText: [values.pensionSavingsText],
      agreed: [values.agreed, Validators.required],
      agreedText: [values.agreedText],
      anotherCountry: [values.anotherCountry, Validators.required],
      anotherCountryText: [values.anotherCountryText],
      callingUserNames: [values.callingUserNames, VALIDATORS.NAME],
      callingUserLastname: [values.callingUserLastname, VALIDATORS.NAME],
      callingUserCellphone: [values.callingUserCellphone, VALIDATORS.PHONE],
      callingUserRut: [values.callingUserRut, VALIDATORS.RUT],
      callingUserEmail: [values.callingUserEmail, VALIDATORS.EMAIL],
    };
    this.setControlsValues();

    this.form = this.formBuilder.group(
      group,
      { validator: [ mustMatch('callingUserRut', 'rut', false) ] }
    );
  }

  private getFormValues(): any {
    if (!this.formValues) { return {}; }
    const {
      name: callingUserNames, lastName: callingUserLastname, cellphone: callingUserCellphone, rut: callingUserRut, email
    } = this.formValues.callingUser;
    const {
      name, lastName, secondSurname, rut, maritalStatusId, gender, deceasedDate, clientSituation,
    } = this.formValues.deceasedAffiliateBackgrounds;
    const backgrounds =  this.formatFormBackgrounds()
      .reduce((backgroundAccumulator, formBackground) => ({ ...backgroundAccumulator, ...formBackground }), {});

    return {
      name,
      lastName,
      secondSurname,
      rut,
      maritalStatusId,
      gender,
      deceasedDate,
      clientSituation,
      ...backgrounds,
      callingUserNames,
      callingUserLastname,
      callingUserCellphone,
      callingUserRut,
      callingUserEmail: email,
    };
  }

  private setControlsValues(): void {
    this.setCallingUserControls();
    this.setResumeDeceasedPersonControls(this.formValues ? this.formValues.deceasedAffiliateBackgrounds : null);
    this.setResumeBackgroundsPersonControls(this.formValues ? this.formValues.deceasedAffiliateBackgrounds : null);
    this.setBackgroundsControlsValues();
  }

  private setCallingUserControls(): void {
    this.resumePersonControls = _.cloneDeep(RESUME_PERSON_CONTROLS);
    if (!this.formValues || !this.formValues.callingUser) { return ; }
    const { name, lastName, cellphone, rut, email } = this.formValues.callingUser;
    this.resumePersonControls.find((control: PersonBackgroundsField) => control.key === 'names').value = name;
    this.resumePersonControls.find((control: PersonBackgroundsField) => control.key === 'lastName').value = lastName;
    this.resumePersonControls.find((control: PersonBackgroundsField) => control.key === 'cellphone').value = cellphone;
    this.resumePersonControls.find((control: PersonBackgroundsField) => control.key === 'rut').value = rut;
    this.resumePersonControls.find((control: PersonBackgroundsField) => control.key === 'email').value = email;
  }

  private setResumeDeceasedPersonControls(value: DeceasedAffiliateBackgrounds): void {
    this.resumeDeceasedPersonControls = this.formUtils.getDefaultControlsValues(
      value, DECEASED_BACKGROUNDS_PERSON_CONTROLS);
  }

  private setResumeBackgroundsPersonControls(value: DeceasedAffiliateBackgrounds): void {
    this.resumeBackgroundPersonControl = this.formUtils.getDefaultControlsValues(
      value, RESUME_BACKGROUNDS_PERSON_CONTROLS);
  }

  private formatFormBackgrounds(): any {
    if (!this.formValues || !this.formValues.clientOptions) { return []; }
    const clientOptions = this.formValues.clientOptions;
    return this.clientOptionsFiltered
        .map((id) => {
          const background = clientOptions.find((clientOption) => clientOption.id === id);
          const hasText = this.clientOptionDeseadedAffiliateId.includes(`${id}Text`);
          return background ?
            this.getFormBackground(id, true, hasText, background.description) :
            this.getFormBackground(id, false, hasText);
        });
  }

  private setBackgroundsControlsValues(): void {
    if (!this.formValues) { return ; }
    this.backgroundsValues = this.formUtils.getBackgroundsControlsValues(this.formValues.clientOptions, this.clientOptionsFiltered);
  }

  private getFormBackground(id: string, idValue: boolean, hasText: boolean, textValue = ''): any {
    const baseFormBackground = { [id]: idValue, };
    const valueReturn = hasText ?
      { ...baseFormBackground, [`${id}Text`]: textValue } : baseFormBackground;
    return valueReturn;
  }

  private clearAndSetValidator(control: string, validator?: ValidatorFn): void {
    if (!this.form) { return ; }
    this.form.get(control).clearValidators();
    if (validator) { this.form.get(control).setValidators(validator); }
    this.form.get(control).updateValueAndValidity();
  }

  private get clientOptionsFiltered(): string[] {
    return this.clientOptionDeseadedAffiliateId.filter((id => !this.utils.isTextBackground(id)));
  }
}
