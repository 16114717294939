import { Mockup } from '@interfaces/mockup.interface';
import { FooterColumn } from '@interfaces/footer.interface';

export const GET_FOOTER_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        links_columna_1: [
          {
            links_id: 2
          }
        ],
        links_columna_2: [
          {
            links_id: 1
          },
          {
            links_id: 2
          }
        ],
        links_columna_3: [
          {
            links_id: 1
          }
        ],
        titulos: [{
          titulo_1: 'Información Útil',
          titulo_2: 'Sitios de Interés',
          titulo_3: null,
          languages_code: 'es'
        }]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FOOTER_MOCKUP: Array<FooterColumn> = [
  {
    title: 'Información Útil',
    links: [
      {
        id: 2,
        title: 'Asociación AFP Chile',
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: 'Sitios de Interés',
    links: [
      {
        id: 1,
        title: 'Mapa del Sitio',
        url: 'url',
        externo: false
      },
      {
        id: 2,
        title: 'Asociación AFP Chile',
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: null,
    links: [
      {
        id: 1,
        title: 'Mapa del Sitio',
        url: 'url',
        externo: false
      }
    ]
  }
];

export const GET_FOOTER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        links_columna_1: [
          {
            links_id: 2
          }
        ],
        links_columna_2: [
          {
            links_id: 1
          },
          {
            links_id: 2
          }
        ],
        links_columna_3: [
          {
            links_id: 1
          }
        ],
        titulos: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FOOTER_NULL_MOCKUP: Array<FooterColumn> = [
  {
    title: null,
    links: [
      {
        id: 2,
        title: null,
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: null,
    links: [
      {
        id: 1,
        title: null,
        url: 'url',
        externo: false
      },
      {
        id: 2,
        title: null,
        url: 'pens',
        externo: false
      }
    ]
  },
  {
    title: null,
    links: [
      {
        id: 1,
        title: null,
        url: 'url',
        externo: false
      }
    ]
  }
];
