import { Component, Input } from '@angular/core';
import { PopoverInformationComponent } from '@components/popover-information/popover-information.component';
import { Step } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';
import { PopoverService } from '@providers/popover/popover.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
  @Input() steps: Array<Step>;
  constructor(
    public font: FontService,
    private popoverService: PopoverService,
  ) { }

  public openDescription(information: string, event) {
    this.popoverService.presentPopover(PopoverInformationComponent, { information }, event);
  }
}
