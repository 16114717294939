import { Component, Input } from '@angular/core';

import { DATE_FORMAT } from '@constants/months.constant';
import {
  PARTIAL_FORMS_DETAIL_CONTENT
} from '@constants/pages-content/partial-form-detail.constants';
import { UserComment } from '@interfaces/comment.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

const MAX_LENGTH = 50;
const SPAN_LENGTH = 10;

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input() public comment: UserComment;
  public collapsed = true;
  public pageContent = PARTIAL_FORMS_DETAIL_CONTENT;

  public get isLargeComment(): boolean { return this.comment.message.length >= MAX_LENGTH + SPAN_LENGTH; }

  constructor(
    public utils: Utils,
    public font: FontService
  ) { }

  public showFullComment(showFull: boolean): void {
    this.collapsed = !showFull;
  }

  public getFormattedDate(date: Date): string {
    return this.utils.getFormattedDate(date, DATE_FORMAT);
  }

  public getMessage(): string {
    return this.isLargeComment && this.collapsed ? `${this.comment.message.substring(0, MAX_LENGTH)}...` : this.comment.message;
  }
}
