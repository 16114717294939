import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import isValid from 'date-fns/isValid';

export function ValidateDate(control: AbstractControl) {
  if (!isValid(control.value)) {
    return { validDate: true };
  }
  return null;
}

export function EndDateValidator(start: string, end: string): ValidatorFn {
  return (group: UntypedFormGroup): ValidationErrors => {
    const startDateInput = group.controls[start];
    const endDateInput = group.controls[end];
    if (endDateInput.value >= startDateInput.value) {
      endDateInput.setErrors(null);
    } else {
      endDateInput.setErrors({ endSmallerThanStart: true });
    }
    return;
  };
}
