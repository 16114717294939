import { Component, Input } from '@angular/core';
import { Login } from '@interfaces/home.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @Input() public loginData: Login;
  constructor(
    public font: FontService,
  ) { }

}
