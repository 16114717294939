import { TRANSFER_TITLES } from './titles.constant';
import { environment } from '@environment';

interface TransferOption {
  name: string;
  url: string;
}

interface TransferOptions {
  title: string;
  options?: Array<TransferOption>;
}

export const AFFILIATE_TRANSFER_OPTIONS: TransferOptions = {
  title: TRANSFER_TITLES.name,
  options: [
    {
      name: TRANSFER_TITLES.content.wantTransfer,
      url: environment.transferUrl,
    },
    {
      name: TRANSFER_TITLES.content.shortWhy,
      url: 'afiliado/traspaso/por-que-cambiarme',
    },
    {
      name: TRANSFER_TITLES.content.state,
      url: 'afiliado/traspaso/estado',
    }
  ]
};
