<ion-card class="info-card" [ngClass]="[styleClass, infoCardClass]">
  <img *ngIf="card?.image && showImages" class="info-card-img" src="{{ card?.image.url }}" alt="{{ card?.image.alt }}">
  <div class="info-card-content">
    <div class="text-container">
      <h2 *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="card?.title | translate"></h2>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line title" animated></ion-skeleton-text>
      <p *ngIf="!loading && card?.description" class="description dynamic-text secondary card"
        [ngClass]="[font?.size, styleClass]"
        [innerHTML]="card?.disabled ? card?.disabledText : card?.description | translate"></p>
      <ion-skeleton-text *ngIf="loading && card?.description" class="skeleton-line title" animated></ion-skeleton-text>
    </div>

    <div class="button-container" [class.more-buttons]="hasMoreButtons">
      <ion-button *ngIf="hasCustomModal" [disabled]="loading || hasDisabledOptionActive"
        [ngClass]="[styleModalButton, font?.size]" (click)="customModal()">
        <span [class.hidden]="loading">{{ card?.showMoreText | translate }}</span>
      </ion-button>
      <ion-button *ngIf="hasButtonModal" [disabled]="loading || hasDisabledOptionActive"
        [ngClass]="[styleModalButton, font?.size]" (click)="moreInformation()">
        <span [class.hidden]="loading">{{ card?.showMoreText | translate }}</span>
      </ion-button>
      <ion-button *ngIf="hasButtonRedirect" [disabled]="loading || hasDisabledOptionActive"
        [ngClass]="[styleModalButton, font?.size]" (click)="goToPrivateSite(card?.redirectButton?.redirectURL)">
        <span [class.hidden]="loading">{{ card?.redirectButton?.textButton | translate }}</span>
      </ion-button>
      <ion-button *ngIf="hasShowMoreButtonUrl" [disabled]="loading || hasDisabledOptionActive"
        [ngClass]="[styleModalButton, font?.size]" (click)="goTo(card?.showMoreUrl)">
        <span [class.hidden]="loading">{{ card?.showMoreText | translate }}</span>
      </ion-button>
      <ion-button *ngIf="hasButtonModal" class="button transparent" [ngClass]="font?.size" (click)="moreInformation()">
        <ion-icon class="icon" name="add"></ion-icon>
      </ion-button>
      <ion-button *ngIf="hasButtonModalIntroductory" class="button primary"
        [disabled]="loading || hasDisabledOptionActive" [ngClass]="font?.size"
        (click)="openModalIntroductory(card.requestUrl, card.modal)">
        <span [class.hidden]="loading">{{ card?.requestText | translate }}</span>
      </ion-button>
      <ion-button *ngIf="hasButtonUrl || hasCustomModal" class="button primary"
        [disabled]="loading || hasDisabledOptionActive" [ngClass]="font?.size" (click)="goTo(card?.requestUrl)">
        <span [class.hidden]="loading">{{ card?.requestText | translate }}</span>
      </ion-button>
      <ion-button *ngFor="let redirectionButton of card?.redirectionButtons" class="button primary"
        [disabled]="loading || hasDisabledOptionActive" [ngClass]="font?.size"
        (click)="goToPrivateSite(redirectionButton.redirectType)">
        <span [class.hidden]="loading">{{ redirectionButton.requestText | translate }}</span>
      </ion-button>
      <ion-button *ngFor="let redirectionButton of card?.redirectionButtonsAlimony" class="button primary"
        [disabled]="loading || hasDisabledOptionActive" [ngClass]="font?.size" (click)="goToAlimony()">
        <span [class.hidden]="loading">{{ redirectionButton.requestText | translate }}</span>
      </ion-button>
      <ion-button *ngIf="hasButtonSecondaryModal" class="button primary" [disabled]="loading || hasDisabledOptionActive"
        [ngClass]="font?.size" (click)="openSecondaryModal()">
        <span [class.hidden]="loading">{{ card?.showMoreSecondaryText | translate }}</span>
      </ion-button>
      <ion-button *ngFor="let pdfFile of card?.pdfFiles" class="button primary"
        [disabled]="loading || hasDisabledOptionActive" [ngClass]="font?.size" (click)="downloadFile(pdfFile)">
        <span [class.hidden]="loading">{{ pdfFile?.downloadButtonText | translate }}</span>
      </ion-button>
      <ion-button class="button primary" *ngIf="hasDownloadableFormats" [disabled]="loading || hasDisabledOptionActive"
        [ngClass]="font?.size" (click)="openModalDownloadableFormats()">
        <span [class.hidden]="loading">{{ card?.requestText | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-card>