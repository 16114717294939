import { PrimaryOption } from '@interfaces/header.interface';
import { ProductProperty } from '@interfaces/product.interface';
import { SAVINGS_TITLES } from './titles.constant';

export const MAIN_PRODUCTS: { [key: string]: ProductProperty } = {
  mandatorySavings: {
    style: 'default',
    name: SAVINGS_TITLES.content.mandatorySavings,
    url: 'afiliado/ahorros/obligatorio',
  },
  voluntarySavings: {
    style: 'default',
    name: SAVINGS_TITLES.content.voluntarySavings,
    url: 'afiliado/ahorros/previsional-voluntario',
  },
  secondaryAccount: {
    style: 'default',
    name: SAVINGS_TITLES.content.secondaryAccount,
    url: 'afiliado/ahorros/cuenta-2',
  }
};

export const SAVINGS_TABS: Array<PrimaryOption> = [
  {
    name: MAIN_PRODUCTS.mandatorySavings.name,
    url: MAIN_PRODUCTS.mandatorySavings.url,
  },
  {
    name: MAIN_PRODUCTS.voluntarySavings.name,
    url: MAIN_PRODUCTS.voluntarySavings.url,
  },
  {
    name: MAIN_PRODUCTS.secondaryAccount.name,
    url: MAIN_PRODUCTS.secondaryAccount.url,
  },
];
