import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-modal-introductory',
  templateUrl: './modal-introductory.component.html',
  styleUrls: ['./modal-introductory.component.scss']
})
export class ModalIntroductoryComponent extends ModalComponent {
  public modal: any;
  public title: string;
  public description: string;
  public disclaimer: string;
  public urlRedirect: string;
  public buttonText: string;
  public largeButton = false;
  public showButton: boolean;

  constructor(
    public font: FontService,
    protected modalCtrl: ModalController,
    private navService: NavigationService,
    protected params: NavParams
  ) {
    super(params, modalCtrl);
    this.urlRedirect = this.params.get('url');
    this.modal = this.params.get('modalIntro');
    this.showButton = this.params.get('showButton');
    this.getInnerParams();
  }

  public getInnerParams(): void {
    this.title = this.modal.title;
    this.description = this.modal.description;
    this.disclaimer = this.modal.disclaimer;
    this.buttonText = this.modal.redirectButton.requestText;
  }

  public async goTo(url: string): Promise<void> {
    if (!url) {
      return;
    }

    await this.closeModal();
    this.navService.goTo(url);
  }
}
