import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import { CaiFormSubmit } from '@interfaces/cai.interface';
import { ContactReason, ContactState } from '@interfaces/contact.interface';
import { RegisterBankAccountParams, RegisterBankAccountResponse } from '@interfaces/register-bank-account.interface';
import { CONTACT_MOCKUP, GET_REASONS_MOCKUP, SUBMIT_CAI_FORM } from '@services/contact/mocks/contact.mock';
import { ErrorUtils } from '@utils/error.utils';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { REGISTER_BANK_ACCOUNT_MOCKUP } from './mocks/contact.mock';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private baseUrl = `${environment.proxyApiUrl}contact`;
  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
  ) { }

  public getReasons(): Observable<Array<ContactReason>> {
    const url = `${this.baseUrl}/reasons`;
    return this.http.get(url, GET_REASONS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public contact(data: FormData): Observable<ContactState> {
    const url = `${this.baseUrl}`;
    return this.http.post(url, data, CONTACT_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public registerBankAccount(params: RegisterBankAccountParams): Observable<boolean> {
    const url = `${this.baseUrl}/register-bank-account`;
    return this.http.post(url, params, REGISTER_BANK_ACCOUNT_MOCKUP)
      .pipe(
        map((response: RegisterBankAccountResponse) => response.emailSent),
        catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public submitCaiForm(params: CaiFormSubmit): Observable<any> {
    const url = `${this.baseUrl}/send-cai-form`;
    return this.http.post(url, params, SUBMIT_CAI_FORM)
      .pipe(
        catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
