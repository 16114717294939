import { ModuleSection, SectionContent } from '@interfaces/titles.interface';

export const HOME = 'Home';
export const LOGIN_TITLES: SectionContent = {
  name: 'LOGIN.TITLE',
};
export const LOGIN_MODAL_KEY = 'LOGIN.LOGIN_MODAL';

const affiliateKey = 'AFFILIATE';
export const AFFILIATED_HOME_TITLES: SectionContent = {
  name: `${affiliateKey}.HOME.TITLE`,
};
const assessmentKey = 'ASSESSMENT';
export const ASSESSMENT_TITLES: SectionContent = {
  name: `${affiliateKey}.${assessmentKey}.NAME`,
  content: {
    certificates: `${affiliateKey}.${assessmentKey}.CERTIFICATES.NAME`,
    financial: `${affiliateKey}.${assessmentKey}.FINANCIAL_INFORMATION.NAME`,
    multifunds: `${affiliateKey}.${assessmentKey}.MULTI_FUNDS.NAME`,
    simulators: `${affiliateKey}.${assessmentKey}.SIMULATORS.NAME`,
    futurePensioner: `${affiliateKey}.${assessmentKey}.FUTURE_PENSIONER.NAME`,
    paperwork: `${affiliateKey}.${assessmentKey}.PAPERWORK_CENTER.NAME`,
    vitalGuide: `${affiliateKey}.${assessmentKey}.VITAL_GUIDE.NAME`,
    followUp: `${affiliateKey}.${assessmentKey}.FOLLOW_UP.NAME`
  }
};
const simulatorKey = 'SIMULATORS';
export const SIMULATOR_TITLES: SectionContent = {
  name: `${affiliateKey}.${assessmentKey}.SIMULATORS.NAME`,
  content: {
    investorProfile: `${affiliateKey}.${assessmentKey}.${simulatorKey}.INVESTOR_PROFILE.NAME`,
  }
};
const savingsKey = 'SAVE_WITH_US';
export const SAVINGS_TITLES: SectionContent = {
  name: `${affiliateKey}.${savingsKey}.NAME`,
  content: {
    mandatorySavings: `${affiliateKey}.${savingsKey}.MANDATORY_SAVINGS.NAME`,
    voluntarySavings: `${affiliateKey}.${savingsKey}.VOLUNTARY_SAVINGS.NAME`,
    secondaryAccount: `${affiliateKey}.${savingsKey}.SECONDARY_ACCOUNT.NAME`,
  },
};
const transferKey = 'TRANSFER';
export const TRANSFER_TITLES: SectionContent = {
  name: `${affiliateKey}.${transferKey}.NAME`,
  content: {
    wantTransfer: `${affiliateKey}.${transferKey}.WANT.NAME`,
    state: `${affiliateKey}.${transferKey}.STATE.NAME`,
    fullWhy: `${affiliateKey}.${transferKey}.WHY.NAME`,
    shortWhy: `${affiliateKey}.${transferKey}.WHY.SHORT`,
  },
};
export const AFFILIATE_MODULE: { [key: string]: ModuleSection; } = {
  affiliate: {
    name: `${affiliateKey}.NAME`,
    sections: {
      login: LOGIN_TITLES,
      home: AFFILIATED_HOME_TITLES,
      assessment: ASSESSMENT_TITLES,
      savings: SAVINGS_TITLES,
      transfer: TRANSFER_TITLES,
    }
  }
};
const corporationKey = 'CORPORATION';
const aboutUsKey = 'ABOUT_US';
export const ABOUT_US_TITLES: SectionContent = {
  name: `${corporationKey}.${aboutUsKey}.NAME`,
  content: {
    ourCompany: `${corporationKey}.${aboutUsKey}.OUR_COMPANY.NAME`,
    administration: `${corporationKey}.${aboutUsKey}.ADMINISTRATION.NAME`,
  }
};
const relevantInfoKey = 'RELEVANT_INFO';
export const RELEVANT_INFO_TITLES: SectionContent = {
  name: `${corporationKey}.${relevantInfoKey}.NAME`,
  content: {
    essentialFacts: `${corporationKey}.${relevantInfoKey}.ESSENTIAL_FACTS.NAME`,
    informationOfInterest: `${corporationKey}.${relevantInfoKey}.INFORMATION_OF_INTEREST.NAME`,
    annualMemory: `${corporationKey}.${relevantInfoKey}.ANNUAL_MEMORY.NAME`,
    financialStatements: `${corporationKey}.${relevantInfoKey}.FINANCIAL_STATEMENTS.NAME`,
    sitemap: `${corporationKey}.${relevantInfoKey}.SITEMAP.NAME`,
  },
};
const policiesKey = 'POLICIES';
export const POLICIES_TITLES: SectionContent = {
  name: `${corporationKey}.${policiesKey}.NAME`,
  content: {
    afp: `${corporationKey}.${policiesKey}.AFP.NAME`,
    government: `${corporationKey}.${policiesKey}.GOVERNMENT.NAME`,
  },
};
export const CORPORATION_MODULE: { [key: string]: ModuleSection; } = {
  corporation: {
    name: `${corporationKey}.NAME`,
    sections: {
      aboutUs: ABOUT_US_TITLES,
      relevantInformation: RELEVANT_INFO_TITLES,
      policies: POLICIES_TITLES,
    }
  }
};
const pensionerKey = 'PENSIONER';
const pensionerAssessmentKey = 'ASSESSMENT';
export const PENSIONER_ASSESSMENT_TITLES: SectionContent = {
  name: `${pensionerKey}.${pensionerAssessmentKey}.NAME`,
  content: {
    paperwork: `${pensionerKey}.${pensionerAssessmentKey}.PAPERWORK.NAME`,
  }
};
const benefitsKey = 'BENEFITS';
export const BENEFITS_TITLES: SectionContent = {
  name: `${pensionerKey}.${benefitsKey}.NAME`,
  content: {
    additional: `${pensionerKey}.${benefitsKey}.ADDITIONAL.NAME`,
    solidarity: `${pensionerKey}.${benefitsKey}.SOLIDARITY.NAME`,
  },
};
const pensionPaymentKey = 'PENSION_PAYMENT';
export const PENSIONER_PAYMENT_TITLES: SectionContent = {
  name: `${pensionerKey}.${pensionPaymentKey}.NAME`,
  content: {
    calendar: `${pensionerKey}.${pensionPaymentKey}.CALENDARS.NAME`,
    register: `${pensionerKey}.${pensionPaymentKey}.REGISTER.NAME`,
    pensionTypes: `${pensionerKey}.${pensionPaymentKey}.PENSION_TYPES.NAME`,
    pensionModes: `${pensionerKey}.${pensionPaymentKey}.PENSION_MODES.NAME`,
    pensionOffers: `${pensionerKey}.${pensionPaymentKey}.PENSION_OFFERS.NAME`,
  },
};
export const PENSIONER_MODULE: { [key: string]: ModuleSection; } = {
  pensioner: {
    name: `${pensionerKey}.NAME`,
    sections: {
      assessment: PENSIONER_ASSESSMENT_TITLES,
      benefits: BENEFITS_TITLES,
      pensionPayment: PENSIONER_PAYMENT_TITLES,
    }
  }
};
const employerKey = 'EMPLOYER';
const paymentKey = 'PAYMENT';
export const EMPLOYER_PAYMENT_TITLES: SectionContent = {
  name: `${employerKey}.${paymentKey}.NAME`,
  content: {
    paymentSheets: `${employerKey}.${paymentKey}.SHEETS.NAME`,
    currentFees: `${employerKey}.${paymentKey}.FEES.NAME`,
  }
};
const servicesKey = 'SERVICES';
export const EMPLOYER_SERVICES_TITLES: SectionContent = {
  name: `${employerKey}.${servicesKey}.NAME`,
  content: {
    passwordCertificates: `${employerKey}.${servicesKey}.PASSWORD.NAME`,
    agreedDeposits: `${employerKey}.${servicesKey}.DEPOSITS.NAME`,
    validateCertificate: `${employerKey}.${servicesKey}.CERTIFICATE.NAME`,
    paymentOptions: `${employerKey}.${servicesKey}.PAYMENT_OPTIONS.NAME`,
    excessPayment: `${employerKey}.${servicesKey}.EXCESS_PAYMENT.NAME`,
  },
};
const infoKey = 'INFO';
export const EMPLOYER_INFO_TITLES: SectionContent = {
  name: `${employerKey}.${infoKey}.NAME`,
  content: {
    familyAssignment: `${employerKey}.${infoKey}.FAMILY.NAME`,
    employeeTypes: `${employerKey}.${infoKey}.EMPLOYEE_TYPES.NAME`,
    youngEmployeeSubsidy: `${employerKey}.${infoKey}.YOUNG_EMPLOYEE.NAME`,
    contributionsPaymentNov: `${employerKey}.${infoKey}.CONTRIBUTIONS-PAYMENT-NOV.NAME`,
  },
};
export const EMPLOYER_MODULE: { [key: string]: ModuleSection; } = {
  employer: {
    name: `${employerKey}.NAME`,
    sections: {
      home: EMPLOYER_PAYMENT_TITLES,
      servicesProducts: EMPLOYER_SERVICES_TITLES,
      employerInformation: EMPLOYER_INFO_TITLES,
    }
  }
};
const otherKey = 'OTHER';
export const OTHER_TITLES: SectionContent = {
  name: `${otherKey}.NAME`,
  content: {
    opinion: `${otherKey}.OPINION`,
    faqs: `${otherKey}.FAQS`,
    branches: `${otherKey}.BRANCHES`,
    externalReview: `${otherKey}.REVIEW`,
  }
};
export const OTHERS_MODULE: { [key: string]: ModuleSection; } = {
  others: {
    name: `${otherKey}.MODULE_NAME`,
    sections: {
      home: OTHER_TITLES,
    }
  }
};
