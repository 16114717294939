import { Injectable } from '@angular/core';
import { GENESYS_ORGANIZATION_GUID, MYPURECLOUD_ENVIRONMENT } from '@constants/pages-content/contact.constant';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import { Click2CallRequest } from '@interfaces/genesys.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { CLICK_2_CALL_MOCK, CODE_AUTHORIZATION_MOCK, CONTACT_CENTER_AVAILABLE_MOCK } from './mocks/genesys.mock';

@Injectable({
  providedIn: 'root'
})
export class GenesysService {
  private genesysUrl = `${environment.investorProfileUrl}genesys`;
  private sdk;
  private activeSession;
  public isSessionActive = new BehaviorSubject<boolean>(false);
  public isScreenshareAvailable = true;

  constructor(
    private http: HttpClientInterceptor,
  ) {
    try {
      this.sdk = new (window as any).GenesysCloudWebrtcSdk({
        organizationId: GENESYS_ORGANIZATION_GUID,
        environment: MYPURECLOUD_ENVIRONMENT,
        logLevel: 'log',
        allowedSessionTypes: ['screenShare'],
        optOutOfTelemetry: true,
      });

      this.listenToGenesysEvents();
    } catch (err) {
      this.isScreenshareAvailable = false;
    }
  }

  public isContactCenterAvailable(): Observable<boolean> {
    return this.http.get(`${this.genesysUrl}/is-contact-center-available`, CONTACT_CENTER_AVAILABLE_MOCK);
  }

  public sendClick2CallRequest(callbackRequest: Click2CallRequest): Observable<any> {
    return this.http.post(`${this.genesysUrl}/click2call`, callbackRequest, CLICK_2_CALL_MOCK);
  }

  public authorizeScreenshareSession(addCommunicationCode: string): Observable<any> {
    return this.http.post(`${this.genesysUrl}/authorize-screenshare`, { addCommunicationCode }, CODE_AUTHORIZATION_MOCK);
  }

  public async initializeScreenshare(securityCode: string): Promise<void> {
    const authorizeSessionResponse = await this.authorizeScreenshareSession(securityCode).toPromise();
    const customerData = this.generateCustomerData(authorizeSessionResponse);
    await this.sdk.initialize(customerData);
    await this.sdk.startScreenShare();
  }

  public async stopScreenshare(): Promise<void> {
    await this.sdk.endSession({ sessionId: this.activeSession.id });
  }

  private listenToGenesysEvents(): void {
    this.sdk.on('sessionStarted', (session: any) => {
      this.activeSession = session;
      this.isSessionActive.next(true);
    });

    this.sdk.on('sessionEnded', (session: any) => {
      if (session.id === this.activeSession.id) { this.isSessionActive.next(false); }
    });
  }

  private generateCustomerData(authorizeSessionResponse): any {
    const { conversation, sourceCommunicationId, jwt } = authorizeSessionResponse;
    return {
      conversation: {
        id: conversation.id,
      },
      sourceCommunicationId,
      jwt,
    };
  }
}
