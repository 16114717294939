<div class="content-container general-container">
  <div class="title-container">
    <h1 class="title" [ngClass]="font?.size">{{ pensionMode?.title | translate }}</h1>
    <p class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="pensionMode?.description | translate"></p>
  </div>
</div>

<div class="pension-mode-container general-container">
  <app-icon-card [isButtonsPrimary]="false" class="pension-mode" [card]="card" *ngFor="let card of pensionMode?.modeCards"></app-icon-card>
</div>

<app-disclaimer-commision></app-disclaimer-commision>
