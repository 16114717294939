import { Mockup } from '@interfaces/mockup.interface';
import { Banner } from '@interfaces/banner.interface';
import { environment } from '@environment';

// tslint:disable:max-line-length
export const GET_HEADERS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          imagen: {
            filename_disk: `16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
          },
          imagen_mobile: {
            filename_disk: `16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
          },
          icono: 'handshake',
          traducciones: [{
            id: 1,
            titulo: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
            languages_code: 'es',
            header: 1,
            alt_imagen: 'alt',
            title_image: '',
          }]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const HEADERS_MOCKUP: Array<Banner> = [
  {
    id: 1,
    image: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: '',
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: '',
    },
    icon: 'handshake',
    firstLine: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
  }
];

export const GET_HEADERS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          imagen: null,
          imagen_mobile: null,
          icono: 'handshake',
          traducciones: []
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const HEADERS_NULL_MOCKUP: Array<Banner> = [
  {
    id: 1,
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'handshake',
    firstLine: null,
  }
];
