import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalInformationComponent } from '@components/modal-information/modal-information.component';
import { SlidesComponent } from '@components/slides/slides.component';
import { Infographic } from '@interfaces/infographic.interface';
import { ModalService } from '@providers/modal/modal.service';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-slides-infographic',
  templateUrl: './slides-infographic.component.html',
  styleUrls: ['./slides-infographic.component.scss'],
})
export class SlidesInfographicComponent extends SlidesComponent implements OnInit {
  @ViewChild('swiperRef', { static: true }) public swiperRef: ElementRef<SwiperContainer>;
  @Input() public infographics: Array<Infographic>;
  @Input() public type: 'simple' | 'extended';
  @Input() public product: 'primary' | 'secondary' | 'tertiary';
  @Input() public loading = false;

  constructor(
    private modalService: ModalService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.slideOpts = {
      slidesPerView: 3,
      loop: false,
      pagination: { el: '.swiper-pagination', clickable: true },
      centeredSlides: true,
      breakpoints: {
        320: {
          centeredSlides: true,
          slidesPerView: 1,
          initialSlide: 0,
        },
        480: {
          centeredSlides: false,
          slidesPerView: 2,
          initialSlide: 0,
        },
        720: {
          centeredSlides: false,
          slidesPerView: 2,
          initialSlide: 0,
        },
        1024: {
          centeredSlides: false,
          slidesPerView: 3,
          initialSlide: 1,
        },
        1440: {
          centeredSlides: false,
          slidesPerView: 3,
          initialSlide: 1
        },
      }
    };
    Object.assign(this.swiperRef.nativeElement, this.slideOpts);
  }

  public get typeClass() { return this.type || ''; }

  public openModalInformation(infographic: Infographic) {
    this.modalService.openModal(ModalInformationComponent, { information: infographic.modal });
  }

  public navigate(direction: 'previous' | 'next'): void {
    direction === 'next' ? this.swiperRef.nativeElement.swiper.slideNext() : this.swiperRef.nativeElement.swiper.slidePrev();
  }
}
