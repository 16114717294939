import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { affiliatePaths } from '@constants/routes.constant';

const routes: Routes = [
  { path: 'asesoria', redirectTo: 'asesoria/informacion-financiera', pathMatch: 'full' },
  {
    path: 'asesoria',
    children: [
      {
        path: affiliatePaths.assessmentPath.futurePensionerPath,
        loadChildren: () => import('./future-pensioner/future-pensioner.module').then(m => m.FuturePensionerPageModule),
      },
      {
        path: affiliatePaths.assessmentPath.multiFundsPath,
        loadChildren: () => import('./multi-funds/multi-funds.module').then(m => m.MultiFundsPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.financialInformationPath,
        loadChildren: () => import('./financial-information/financial-information.module').then(m => m.FinancialInformationPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.simulatorsPath,
        loadChildren: () => import('./simulators/simulators.module').then(m => m.SimulatorsPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.certificatesPath,
        loadChildren: () => import('./certificates/certificates.module').then(m => m.CertificatesPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.paperworkCenterPath,
        loadChildren: () => import('./paperwork-center/paperwork-center.module').then(m => m.PaperworkCenterPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.survivorPensionPath,
        loadChildren: () => import('./survivor-pension/survivor-pension.module').then(m => m.SurvivorPensionPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.followUpPath,
        loadChildren: () => import('./paperwork-follow-up/paperwork-follow-up.module').then(m => m.PaperworkFollowUpPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.requestDetailPath,
        loadChildren: () => import('./form-status-detail/form-status-detail.module').then(m => m.FormStatusDetailPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.employerDiscount,
        loadChildren: () => import('./employer-discount/employer-discount.module').then(m => m.EmployerDiscountPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.regulatoryDocuments,
        loadChildren: () => import('./regulatory-documents/regulatory-documents.module').then(m => m.RegulatoryDocumentsPageModule)
      },
      {
        path: affiliatePaths.assessmentPath.transferSurvivorPensionBeneficiaries,
        loadChildren: () => import('./transfer-survivor-pension-beneficiaries/transfer-survivor-pension-beneficiaries.module')
          .then(m => m.TransferSurvivorPensionBeneficiariesPageModule)
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssessmentRoutingModule { }
