<ion-app>
  <app-loading *ngIf="showLoading"></app-loading>
  <div class="container-header-alert">
    <app-alert-banner *ngIf="showAlert" (hideAlert)="setShowAlert($event)" [data]="alertBanner"
      [loading]="loading"></app-alert-banner>
    <app-header [showAlert]="showAlert"></app-header>
  </div>
  <ion-router-outlet id="main-content" class="pages" [class.alert-banner]="showAlert"></ion-router-outlet>
  <app-help-button (menuStatus)="menuStatus($event)"></app-help-button>
  <div *ngIf="vitaliaOptionsOpened" class="black-screen"></div>
</ion-app>