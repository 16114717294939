<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="confirm()" *ngIf="form">
  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h3>
    
    <app-form-array-list
      [pageContent]="pageContent?.buttonFamilyBurden"
      [iconClass]="'pension'"
      [itemProperties]="burdenProperties"
      [modalType]="modalType"
      [modalData]="modalData"
      (edited)="setFamilyBurdens($event)">
    </app-form-array-list>
    <div class="information-container">
      <app-disclaimer-box
        [description]= "disclaimerMessage"
        [typeDisclaimer]="'informative'">
      </app-disclaimer-box>
    </div>
  </div>
  <div class="form-section">
    <mat-checkbox formControlName="declaration" color="primary" [ngClass]="font?.size">
      {{ pageContent?.personalConsent | translate }}
    </mat-checkbox>
  </div>

  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.titleSecondary | translate }}</h3>
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.disclaimerSecondary"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
    <app-attach-files
      [attachFiles]="requiredFiles"
      (fileEmitter)="setFiles($event)">
    </app-attach-files>
  </div>
 
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
