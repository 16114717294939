import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MORE_INFO } from '@constants/pages-content/general.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss'],
})
export class AlertToastComponent {
  @Input() public pageContent;
  @Input() public severity: string;
  @Output() public hideAlert = new EventEmitter<boolean>();
  public buttonText = MORE_INFO;

  constructor(
    public font: FontService,
  ) { }

  public hideToast() {
    this.hideAlert.emit(true);
  }
}
