import { Injectable } from '@angular/core';
import { LANGUAGE_KEY } from '@constants/translate.constant';
import { Item } from '@interfaces/general.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class LanguageProvider {
  public language = new Subject<string>();
  public selectedLanguage: string;

  constructor(
    public translate: TranslateService,
  ) { }

  public setLanguage(language: Item): void {
    if (this.selectedLanguage === language.id) { return; }
    localStorage.setItem(LANGUAGE_KEY, language.id);
    this.selectedLanguage = language.id;
    this.language.next(language.id);
    this.translate.use(language.id);
  }

}
