import { Injectable } from '@angular/core';
import { FUNDS_NAMES } from '@constants/quota-values.constant';
import { QuotaValue, QuotaValueFund } from '@interfaces/quota-values.interface';
import { Utils } from '@utils/utils';

@Injectable({
  providedIn: 'root'
})
export class ChartistUtils {
  public funds = FUNDS_NAMES;

  constructor(
    private utils: Utils,
  ) { }

  public getSeriesQuotaValueFunds(quotas: Array<QuotaValueFund>): Array<Array<number>> {
    const series = [[], [], [], [], []];
    quotas.forEach((quota) => {
      this.funds.forEach((fund, index) => series[index].push(quota[`fund${fund}`]));
    });
    return series;
  }

  public getTypeChart(values: Array<any>): 'x-large' | 'large' | 'medium' | 'small' | 'x-small' {
    const length = values.length;
    return length > 180 ? 'x-large' : length > 90 && length <= 180 ? 'large' : length > 45 && length <= 90 ? 'medium' :
      length > 15 && length <= 45 ? 'small' : 'x-small';
  }

  public getLabelsQuotaValues(quotas: Array<QuotaValue>): Array<string> {
    const labels = [];
    quotas.forEach((quota) => labels.push(this.getLabelByType('none', new Date(quota.date))));
    return labels;
  }

  private getLabelByType(type: 'x-large' | 'large' | 'medium' | 'small' | 'x-small' | 'none', date: Date): string {
    const month = Utils.getMonthTranslate(date.getMonth().toString());
    const isHalfMonth = this.utils.isHalfDayOfMonth(date);
    const isEndMonth = this.utils.isLastDayOfMonth(date);
    const isMonday = this.utils.isDayNumberOfWeek(date, 1);
    const isThursday = this.utils.isDayNumberOfWeek(date, 4);
    const formattedDate = `${this.utils.getFormattedDate(date)}`;
    switch (type) {
      case 'x-large':
        return isHalfMonth ? `${ month } ${ date.getFullYear() }` : '';
      case 'large':
        return isHalfMonth || isEndMonth ? formattedDate : '';
      case 'medium':
        return isMonday ? formattedDate : '';
      case 'small':
        return isMonday || isThursday ? formattedDate : '';
      case 'x-small':
      default:
        return formattedDate;
    }

  }
}
