<div class="stepper-header">
  <ng-container *ngFor="let label of steps; let index = index">
    <div class="steps-union-line" [hidden]="isFirst(index)" [style.marginLeft.%]="unionLinesMargin"
         [style.marginRight.%]="unionLinesMargin" [class.done]="isBeforeCurrent(index) || isCurrent(index)"
         [class.pending]="isNextToCurrent(index)"></div>
    <div class="step" [ngClass]="{ 'final': isLast(index), 'current': isCurrent(index), 'done': isBeforeCurrent(index) }">
      <div class="icon-container">
        <div class="icon" (click)="onStepClicked(index)" [class.clickable]="canGoToStep(index)"></div>
      </div>
      <div class="description label" [ngClass]="font?.size">{{ label | translate }}</div>
    </div>
  </ng-container>
</div>

<div class="mobile-step">
  <h3 class="title-secondary spacing step">{{ currentIndex + 1 }}.</h3>
  <h3 class="title-secondary spacing label">{{ currentLabel | translate }}</h3>
</div>
