import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_FINANCIAL_STATEMENTS_LINKS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              titulos: {
                titulo_administradora: 'Estados Financieros Trimestrales de la Administradora.',
                titulo_fondos: 'Estados Financieros Trimestrales de los Fondos de Pensiones Tipos A, B, C, D y E.'
              },
              estados_financieros: [
                {
                  titulo: '30 de septiembre 2020',
                  subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 30 de septiembre 2020 y sus notas.',
                  administradora: [
                    {
                      titulo: '30 de Septiembre 2020',
                      url: 'Estados-Financieros/30-sept-2020/Administradora/Estados_Financieros_AFPPlanvital_Septiembre2020.pdf'
                    }
                  ],
                  fondo: [
                    {
                      titulo: '30 de Septiembre 2020',
                      url: 'Estados-Financieros/30-sept-2020/Fondos/EEFF Fondos de Pensiones Septiembre 2020.pdf'
                    }
                  ]
                },
                {
                  titulo: '30 de junio 2020',
                  subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 30 de junio 2020 y sus notas.',
                  administradora: [
                    {
                      titulo: '30 de Junio 2020',
                      url: 'Estados-Financieros/30-jun-2020/Administradora/Estados_Financieros_AFPPlanvital_Junio2020.pdf'
                    }
                  ],
                  fondo: [
                    {
                      titulo: '30 de Junio 2020',
                      url: 'Estados-Financieros/30-jun-2020/Fondos/DefinitivoEE FF Fondos de Pensiones Junio 2020.pdf'
                    }
                  ]
                },
                {
                  titulo: '31 de marzo 2020',
                  subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 31 de marzo 2020 y sus notas.',
                  administradora: [
                    {
                      titulo: 'Identificación',
                      url: 'Estados-Financieros/31-mar-2020/Administradora/01-Identificacion.pdf'
                    },
                    {
                      titulo: 'Ficha Estadística',
                      url: 'Estados-Financieros/31-mar-2020/Administradora/02-Ficha-Estadistica.pdf'
                    },
                    {
                      titulo: 'Notas a los Estados Financieros',
                      url: 'Estados-Financieros/31-mar-2020/Administradora/03-Notas-Estados-Financieros.pdf'
                    },
                    {
                      titulo: 'Hechos Relevantes',
                      url: 'Estados-Financieros/31-mar-2020/Administradora/04-Hechos-Relevantes.pdf'
                    },
                    {
                      titulo: 'Análisis Razonado',
                      url: 'Estados-Financieros/31-mar-2020/Administradora/05-Analisis-Razonado.pdf'
                    },
                    {
                      titulo: 'Declaración de Responsabilidad',
                      url: 'Estados-Financieros/31-mar-2020/Administradora/06-Declaracion-de-Responsabilidad.pdf'
                    }
                  ],
                  fondo: [
                    {
                      titulo: '31 de Marzo 2020',
                      url: 'Estados-Financieros/31-mar-2020/Fondos/EEFF Fondos de Pensiones 31 Marzo 2020.pdf'
                    }
                  ]
                },
                {
                  titulo: '31 de diciembre 2019',
                  subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 31 de diciembre 2019 y sus notas.',
                  administradora: [
                    {
                      titulo: 'Identificación',
                      url: 'Estados-Financieros/31-dic-2019/Administradora/01-Identificacion.pdf'
                    },
                    {
                      titulo: 'Ficha Estadística',
                      url: 'Estados-Financieros/31-dic-2019/Administradora/02-Ficha-Estadistica.pdf'
                    },
                    {
                      titulo: 'Notas a los Estados Financieros',
                      url: 'Estados-Financieros/31-dic-2019/Administradora/03-Notas-Estados-Financieros.pdf'
                    },
                    {
                      titulo: 'Hechos Relevantes',
                      url: 'Estados-Financieros/31-dic-2019/Administradora/04-Hechos-Relevantes.pdf'
                    },
                    {
                      titulo: 'Análisis Razonado',
                      url: 'Estados-Financieros/31-dic-2019/Administradora/05-Analisis-Razonado.pdf'
                    },
                    {
                      titulo: 'Declaración de Responsabilidad',
                      url: 'Estados-Financieros/31-dic-2019/Administradora/06-Declaracion-de-Responsabilidad.pdf'
                    }
                  ],
                  fondo: [
                    {
                      titulo: '31 de Diciembre 2019',
                      url: 'Estados-Financieros/31-dic-2019/Fondos/Estados Financieros Fondos de Pensiones Diciembre 2019.pdf'
                    }
                  ]
                },
                {
                  titulo: '30 de septiembre 2019',
                  subtitulo: 'Estados Financieros de AFP PlanVital S.A. al 30 de septiembre 2019 y sus notas.',
                  administradora: [
                    {
                      titulo: 'Identificación',
                      url: 'Estados-Financieros/30-sept-2019/Administradora/01-Identificacion.pdf'
                    },
                    {
                      titulo: 'Ficha Estadística',
                      url: 'Estados-Financieros/30-sept-2019/Administradora/02-Ficha-Estadistica.pdf'
                    },
                    {
                      titulo: 'Notas a los Estados Financieros',
                      url: 'Estados-Financieros/30-sept-2019/Administradora/03-Notas-Estados-Financieros.pdf'
                    },
                    {
                      titulo: 'Hechos Relevantes',
                      url: 'Estados-Financieros/30-sept-2019/Administradora/04-Hechos-Relevantes.pdf'
                    },
                    {
                      titulo: 'Análisis Razonado',
                      url: 'Estados-Financieros/30-sept-2019/Administradora/05-Analisis-Razonado.pdf'
                    },
                    {
                      titulo: 'Declaración de Responsabilidad',
                      url: 'Estados-Financieros/30-sept-2019/Administradora/06-Declaracion-de-Responsabilidad.pdf'
                    }
                  ],
                  fondo: [
                    {
                      titulo: 'Identificación',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/01-Identificacion.pdf'
                    },
                    {
                      titulo: 'Balance general',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/02-Balance-General.pdf'
                    },
                    {
                      titulo: 'Estados de variación patrimonial',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/03-Estados-de-Variacion-Patrimonial.pdf'
                    },
                    {
                      titulo: 'Notas a los Estados Financieros',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/04-Notas-Estados-Financieros.pdf'
                    },
                    {
                      titulo: 'Cuadro de la rentabilidad de cartera',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/05-Cuadro-de-la-Rentabilidad-de-la-Cartera.pdf'
                    },
                    {
                      titulo: 'Valores cuotas y cuentas',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/06-Valores-Cuotas-Cuentas.pdf'
                    },
                    {
                      titulo: 'Recaudación',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/08-Recaudacion.pdf'
                    },
                    {
                      titulo: 'Declaraciones y no pago',
                      url: 'Estados-Financieros/30-sept-2019/Fondos/09-Declaraciones-y-no-pago.pdf'
                    }
                  ]
                },
              ]
            }
          }
        ],
      },
      public: true,
    }
  }],
  failures: [{
    response: {}
  }]
};
