import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@components/modal/modal.component';
import { CALL_CENTER } from '@constants/pages-content/call-center.constant';
import { GenesysService } from '@services/genesys/genesys.service';

@Component({
  selector: 'app-modal-stop-screenshare',
  templateUrl: './modal-stop-screenshare.component.html',
  styleUrls: ['./modal-stop-screenshare.component.scss'],
})
export class ModalStopScreenshareComponent {
  public buttonLabel = CALL_CENTER.cobrowsing.stopScreenshareButton;

  constructor(
    private genesysService: GenesysService,
    public dialogRef: MatDialogRef<ModalComponent>,
  ) {
    this.genesysService.isSessionActive.subscribe(response => {
      if (response === false) { this.closeModal(); }
    });
  }

  public async stopSharingScreen(): Promise<void> {
    try {
      await this.genesysService.stopScreenshare();
    } catch (err) {
      console.error('Error', err);
    }
  }

  private closeModal(): void {
    this.dialogRef.close();
  }

}
