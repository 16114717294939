<form class="form-container" [formGroup]="radioButtonListForm" *ngIf="loadedData">
  <div class="title secondary" [ngClass]="font?.size">{{ pageContent?.content.title | translate }}</div>
  <p class="subtitle dynamic-text secondary" [ngClass]="font?.size">{{ pageContent?.content.description | translate }}</p>

  <div class="radio-header" [class.fixed-header]="isFirstChild">
    <div class="radio-label-container">
      <p class="description radio-label" [ngClass]="font?.size">{{ pageContent?.content.options.yes | translate }}</p>
      <p class="description radio-label" [ngClass]="font?.size">{{ pageContent?.content.options.no | translate }}</p>
    </div>
  </div>
  <div *ngFor="let radioButtonElement of radioButtonListForm.controls; let index = index">
    <div [formGroup]="radioButtonElement">
      <div class="radio-container">
        <mat-radio-group class="radio-option" formControlName="accepted" (change)="updateRequiredValidator(index)">
          <mat-radio-button [value]="true" [ngClass]="font?.size"></mat-radio-button>
          <mat-radio-button [value]="false" [ngClass]="font?.size"></mat-radio-button>
        </mat-radio-group>
        <div class="radio-select">
          <div class="description-container">
            <p class="description" [ngClass]="font?.size" [innerHtml]="getText(getControl(index, 'code').value) | translate"></p>
            <app-info-text-popup class="tooltip-icon" *ngIf="hasDefinition(getControl(index, 'code').value)" [definitions]="getDefinition(getControl(index, 'code').value)" iconName="i-tooltip-blue">
              <div class="i-tooltip-blue"></div>
            </app-info-text-popup>
          </div>
          <ng-container *ngIf="hasDetail(getControl(index, 'code').value)">
            <ng-container *ngIf="hasList(getControl(index, 'code').value)">
              <mat-form-field [ngClass]="font?.size" [class.big]="getControl(index, 'bigField').value" [hidden]="!getControl(index, 'accepted').value">
                <mat-label>{{ getDetail(getControl(index, 'code').value) | translate }}</mat-label>
                <mat-select formControlName="detail" [ngClass]="font?.size">
                  <mat-option [ngClass]="font?.size" *ngFor="let option of getOptionList(getControl(index, 'code').value)" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error [ngClass]="font?.size">{{ pageContent?.content.errors.required | translate }}</mat-error>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!hasList(getControl(index, 'code').value)">
              <mat-form-field class="detail-option" [class.big]="getControl(index, 'bigField').value" [ngClass]="font?.size" *ngIf="hasDetail(getControl(index, 'code').value)"
                [hidden]="!getControl(index, 'accepted').value">
                <mat-label>{{ getDetail(getControl(index, 'code').value) | translate }}</mat-label>
                <input [ngClass]="font?.size" matInput type="text" formControlName="detail">
                <span matSuffix class="i-validator"></span>
                <mat-error [ngClass]="font?.size">{{ pageContent?.content.errors.required | translate }}</mat-error>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
