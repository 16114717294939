import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-slider-navigation',
  templateUrl: './slider-navigation.component.html',
  styleUrls: ['./slider-navigation.component.scss'],
})
export class SliderNavigationComponent {
  @Output() private direction = new EventEmitter<'previous' | 'next'>();
  @Input() public color: 'default' | 'gray' = 'default';

  constructor(
    public font: FontService,
  ) { }

  public clickButton(direction: 'previous' | 'next') {
    this.direction.emit(direction);
  }
}
