export const ROUTES_WITH_IP_VALIDATION = [
  {
    route: 'contact',
    method: 'POST',
  },
  {
    route: 'certificates/validate-certificate',
    method: 'GET',
  },
];
