import { from, Observable } from 'rxjs';

import {
  HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SEND_NOEMAIL_NOTIFICATION_MESSAGE } from '@constants/noemail-notification.constants';
import { NotificationService } from '@services/notification/notification.service';

@Injectable()
export class NoEmailNotificationInterceptorProvider implements HttpInterceptor {
  constructor(
    public http: HttpClient,
    public notificationService: NotificationService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(next.handle(request).toPromise()
      .then(((response) => {
        let message: string;
        if (Object.keys(SEND_NOEMAIL_NOTIFICATION_MESSAGE).includes(request.url)) {
          message = SEND_NOEMAIL_NOTIFICATION_MESSAGE[request.url];
        }
        if (message) { this.notificationService.sendNoEmailNotification(message); }

        return response;
      })));
  }
}
