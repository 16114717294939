import { Component, Input } from '@angular/core';

import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() public message: string;
  @Input() public type: string;

  constructor(public font: FontService) { }

}
