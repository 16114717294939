import { Certificates, ValidateCertificate } from '@interfaces/certificates.interface';

const pageKey = 'AFFILIATE.ASSESSMENT.CERTIFICATES';
const validatePageKey = `${pageKey}.VALIDATE`;
const getPageKey = `${pageKey}.GET`;

export const CERTIFICATES_CONTENT: Certificates = {
  header: {
    id: 1,
    image: {
      alt: 'Validación de certificados',
      url: 'assets/images/banner-certificados-online.webp',
    },
    image_mobile: {
      alt: 'Validación de certificados',
      url: 'assets/images/banner-certificados-online.webp',
    },
    icon: 'accept',
    firstLine: `${pageKey}.TITLE`,
  }
};


export const VALIDATE_CERTIFICATE_CONTENT: ValidateCertificate = {
  mainTitle: `${validatePageKey}.MAIN_TITLE`,
  title: `${validatePageKey}.TITLE`,
  description: `${validatePageKey}.DESCRIPTION`,
  validateBox: {
    title: `${validatePageKey}.BOX.TITLE`,
    rut: `${validatePageKey}.BOX.RUT`,
    certificateId: `${validatePageKey}.BOX.ID`,
    button: `${validatePageKey}.BOX.BUTTON`,
    image: {
      url: 'assets/images/AFP_Planvital_folio_certificado_validador.png',
      alt: 'Número de folio en la parte superior derecha del certificado',
    },
  },
};

export const GET_CERTIFICATE_CONTENT: any = {
  mainTitle: `${getPageKey}.MAIN_TITLE`,
  title: `${getPageKey}.TITLE`,
  description: `${getPageKey}.DESCRIPTION`,
  categories: {
    general: {
      name: `${getPageKey}.CATEGORIES.GENERAL.NAME`,
      description: `${getPageKey}.CATEGORIES.GENERAL.DESCRIPTION`,
    },
    transactional: {
      name: `${getPageKey}.CATEGORIES.TRANSACTIONAL.NAME`,
      description: `${getPageKey}.CATEGORIES.TRANSACTIONAL.DESCRIPTION`,
    },
  },
  types: {
    general: {
      affiliate: {
        name: `${getPageKey}.TYPES.GENERAL.AFFILIATE.NAME`,
        description: `${getPageKey}.TYPES.GENERAL.AFFILIATE.DESCRIPTION`,
      },
      contingencyRecords: {
        name: `${getPageKey}.TYPES.GENERAL.CONTINGENCY_RECORDS.NAME`,
        description: `${getPageKey}.TYPES.GENERAL.CONTINGENCY_RECORDS.DESCRIPTION`,
      },
      residenceRecords: {
        name: `${getPageKey}.TYPES.GENERAL.RESIDENCE_RECORDS.NAME`,
        description: `${getPageKey}.TYPES.GENERAL.RESIDENCE_RECORDS.DESCRIPTION`,
      },
      vacations: {
        name: `${getPageKey}.TYPES.GENERAL.VACATIONS.NAME`,
        description: `${getPageKey}.TYPES.GENERAL.VACATIONS.DESCRIPTION`,
      }
    },
    transactional: {
      remunerations: {
        name: `${getPageKey}.TYPES.TRANSACTIONAL.REMUNERATIONS.NAME`,
        description: `${getPageKey}.TYPES.TRANSACTIONAL.REMUNERATIONS.DESCRIPTION`,
      },
      quotations: {
        name: `${getPageKey}.TYPES.TRANSACTIONAL.QUOTATIONS.NAME`,
        description: `${getPageKey}.TYPES.TRANSACTIONAL.QUOTATIONS.DESCRIPTION`,
      },
    },
  },
  serviceResponse: {
    success: {
      title: `${getPageKey}.SERVICE_RESPONSE.SUCCESS.TITLE`,
      description: `${getPageKey}.SERVICE_RESPONSE.SUCCESS.DESCRIPTION`,
      iconName: 'i-check',
      firstBtnText: `${getPageKey}.SERVICE_RESPONSE.SUCCESS.FIRST_BUTTON_TEXT`,
    },
    error: {
      title: `${getPageKey}.SERVICE_RESPONSE.ERROR.TITLE`,
      description: `${getPageKey}.SERVICE_RESPONSE.ERROR.DESCRIPTION`,
      iconName: 'i-error',
      firstBtnText: `${getPageKey}.SERVICE_RESPONSE.ERROR.FIRST_BUTTON_TEXT`,
      secondBtnText: `${getPageKey}.SERVICE_RESPONSE.ERROR.SECOND_BUTTON_TEXT`,
      notFound : {
        title: `${getPageKey}.SERVICE_RESPONSE.ERROR.NOT_FOUND.TITLE`,
        description: `${getPageKey}.SERVICE_RESPONSE.ERROR.NOT_FOUND.DESCRIPTION`,
      }
    }
  }
};
