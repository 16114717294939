<div class="table-container">
  <div class="row header">
    <p class="column" [ngClass]="font?.size">{{ data?.header.age }}</p>
    <p class="column" [ngClass]="font?.size">{{ data?.header.fund }}</p>
  </div>
  <div class="items" *ngFor="let item of data?.items">
    <div class="row">
      <p class="column" [ngClass]="font?.size" [innerHtml]="item.age"></p>
      <p class="column" [ngClass]="font?.size">{{ item.fund }}</p>
    </div>
  </div>
</div>
