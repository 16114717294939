import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import {
  PARTIAL_FORMS_DETAIL_CONTENT
} from '@constants/pages-content/partial-form-detail.constants';
import { CommentRequest, UserComment } from '@interfaces/comment.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { FormsService } from '@services/forms/forms.service';
import { ErrorUtils } from '@utils/error.utils';
import { slideInOut } from 'src/app/animations/slideInOut.annimation';

@Component({
  selector: 'app-card-comments',
  templateUrl: './card-comments.component.html',
  styleUrls: ['./card-comments.component.scss'],
  animations: [
    slideInOut
  ]
})
export class CardCommentsComponent implements OnInit, OnDestroy {
  @Input() public id: number;
  @Input() public contactName: string;
  @Input() public disabled: boolean;
  public pageContent = PARTIAL_FORMS_DETAIL_CONTENT;
  public modals = PARTIAL_FORMS_DETAIL_CONTENT.modals;
  public comments: Array<UserComment>;
  public isCommenting = false;
  public form: UntypedFormGroup;
  public showButtons = 'out';
  public externalStarredComment: UserComment;
  public downSort = true;
  private comment$: Subscription;

  private get comment(): AbstractControl { return this.form.get('comment'); }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
    private partialFormService: FormsService,
    private modalService: ModalService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.loadingService.showLoading();
    await this.getComments();
    this.form = this.formBuilder.group({
      comment: [''],
      profitExecutive: [false],
    });
    this.comment$ = this.comment.valueChanges.subscribe(() => {
      this.isCommenting = this.comment.value !== '';
      this.showButtons = this.isCommenting ? 'in' : 'out';
    });
    this.loadingService.hideLoading();
  }

  public ngOnDestroy(): void {
    if (this.comment$) { this.comment$.unsubscribe(); }
  }

  public discardComment(): void {
    this.comment.setValue('');
    this.showButtons = 'out';
  }

  public addComment(): void {
    if (this.comment.value === '') { return; }
    this.loadingService.showLoading();
    this.partialFormService.saveComment(this.generateRequestComment())
      .then(() => this.getComments())
      .catch((error) => this.handleError(error))
      .finally(() => {
        this.discardComment();
        this.loadingService.hideLoading();
      });
  }

  public changeSort(): void {
    this.downSort = !this.downSort;
    if (this.comments.length) { this.comments.reverse(); }
  }

  private generateRequestComment(): CommentRequest {
    return {
      comment: {
        message: this.comment.value,
        starred: false,
        internal: false,
        requestInformationId: String(this.id),
      }
    };
  }

  private async getComments(): Promise<boolean> {
    return this.partialFormService.getComments(this.id)
      .then((response) => {
        this.comments = this.mapCommentsResponse(response);
        this.externalStarredComment = response ? this.formatComment(response.find((comment) => comment.starred)) : null;
        return true;
      })
      .catch((error) => {
        this.handleError(error);
        return false;
      });
  }

  private mapCommentsResponse(response: Array<UserComment>): Array<UserComment> {
    if (!response || !response.length) { return []; }
    return response.filter((comment) => !comment.starred).map((executiveComment) =>
      this.formatComment(executiveComment)
    );
  }

  private formatComment(executiveComment: UserComment): UserComment {
    if (!executiveComment) { return null; }
    const { createdAt, updatedAt } = executiveComment;
    return {
      ...executiveComment,
      createdAt: new Date(createdAt),
      updatedAt: updatedAt ? new Date(updatedAt) : null,
    };
  }

  private handleError(error): void {
    const { title, subtitle } = this.modals.modal_alert_generic_error;
    const description = this.errorUtils.handleServiceError(error) || subtitle;
    const data = { title, description };
    this.modalService.openAlert({ title: data.title, description: data.description });
  }
}
