const partialFormGenericModalKey = 'PARTIAL_FORM_GENERIC_MODAL';
const contactInformationErrorKey = `${partialFormGenericModalKey}.CONTACT_INFORMATION_ERROR`;
const existingInsertErrorKey = `${partialFormGenericModalKey}.EXISTING_INSERT_ERROR`;

export const DEFAULT_MODAL_DATA = {
  contactInformationError: {
    firstBtnText: `${contactInformationErrorKey}.FIRST_BTN`,
    primaryCallback: () => { },
    iconName: 'i-error',
  },
  existingInsertError: {
    firstBtnText: `${existingInsertErrorKey}.FIRST_BTN`,
    secondBtnText: `${existingInsertErrorKey}.SECOND_BTN`,
    secondaryCallback: () => { },
    showSecondaryButton: true,
    iconName: 'i-info-red',
  }
};
