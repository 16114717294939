import { Component, OnInit, Input } from '@angular/core';
import { ComponentsService } from '@services/cms/components.service';
import { GenesysService } from '@services/genesys/genesys.service';

@Component({
  selector: 'app-assistance-button',
  templateUrl: './assistance-button.component.html',
  styleUrls: ['./assistance-button.component.scss'],
})
export class AssistanceButtonComponent implements OnInit {
  @Input() public procedureType;
  public inTime: boolean;
  public isContactCenterAvailable = true;
  public isWithinContactCenterHours = true;

  public get showAssistanceCallBtn(): boolean { return this.isContactCenterAvailable && this.isWithinContactCenterHours; }
  constructor(
    private componentsService: ComponentsService,
    private genesysService: GenesysService
  ) {
  }

  ngOnInit(): void {
    this.checkContactCenterAvailable();
    this.getCallCenterHours();
   }

  private checkContactCenterAvailable(): void {
    this.componentsService.getGeneralInfo().subscribe( response => {
      this.isContactCenterAvailable = response.callCenterAvailable;
    });
  }

  private getCallCenterHours(): void {
    this.genesysService.isContactCenterAvailable().subscribe( response => {
      this.isWithinContactCenterHours = response;
    });
  }

}
