
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import {
  PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, REQUEST_FAILED_SUBMISSION,
  TYPE_SEARCH_OPTIONS
} from '@constants/forms.constant';
import {
  APPLICATION_FORM_PROGRESS_PAGE_CONTENT
} from '@constants/pages-content/application-form-progress.constant';
import { EMAIL_PATTERN, NUMERIC_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import {
  applicationsAffiliateStatePath, applicationsPensionerStatePath
} from '@constants/routes.constant';
import { FormDataRequest, PartialRequestLoadResponse } from '@interfaces/forms.interface';
import { Item } from '@interfaces/general.interface';
import { GenericModalData } from '@interfaces/modal.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { FormsService } from '@services/forms/forms.service';
import { ErrorUtils } from '@utils/error.utils';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { ValidateRut } from '@validators/rut.validator';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-application-form-progress',
  templateUrl: './application-form-progress.component.html',
  styleUrls: ['./application-form-progress.component.scss'],
})
export class ApplicationFormProgressComponent implements OnInit {
  @Input() public isAffiliate = false;
  @Input() public returnApplicantRut: boolean;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public solicitantRutRequired: EventEmitter<any> = new EventEmitter();
  public pageContent = APPLICATION_FORM_PROGRESS_PAGE_CONTENT;
  public form: UntypedFormGroup;
  public typeSearchOptions: Array<Item> = TYPE_SEARCH_OPTIONS;
  public onlyNumbersPattern = NUMERIC_PATTERN;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public showForm = false;
  private formType: string;


  public get rut(): AbstractControl { return this.form.get('rut'); }
  public get phone(): AbstractControl { return this.form.get('phone'); }
  public get email(): AbstractControl { return this.form.get('email'); }
  public get typeSearchOption(): AbstractControl { return this.form.get('typeSearchOption'); }
  public get phoneOptionSelected(): boolean { return this.typeSearchOption.value === '2'; }
  public get emailOptionSelected(): boolean { return this.typeSearchOption.value === '3'; }
  public get isVoluntaryDiscountRoute(): boolean { return this.router.url.includes('descuento-empleador'); }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private formUtils: FormUtils,
    private router: Router,
    private route: ActivatedRoute,
    private formsService: FormsService,
    private errorUtils: ErrorUtils,
    private utils: Utils,
  ) { }

  public ngOnInit(): void {
    this.formType = this.formUtils.getFormType(this.router.url);
    this.createForm();
    this.changeTypeSearchOption();
    this.typeSearchOptionSubscription();
    this.route.queryParams.subscribe((params: Params) => {
      const { rut, phone, type } = params;
      if (!rut || !phone || !type) { return; }
      this.showForm = true;
      this.rut.setValue(rut);
      this.formType = type;
      this.phone.setValue(phone);
      this.getRequest();
    });
  }

  private createForm(): void {
    const phoneValidators = [
      Validators.minLength(this.phoneNumberLength),
      Validators.maxLength(this.phoneNumberLength),
      Validators.min(this.phoneNumberMin),
      Validators.max(this.phoneNumberMax),
      Validators.pattern(PHONE_PATTERN),
    ];

    this.form = this.formBuilder.group({
      typeSearchOption: ['2'],
      rut: ['', [Validators.required, ValidateRut]],
      phone: ['', phoneValidators],
      email: ['', [Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    });
  }

  public getRequest(): void {
    if (!this.showForm) { this.formData.emit(null); }
    if (this.form.invalid) { return; }
    this.loadingService.showLoading();
    const { rut, phone, email } = this.form.value;
    const formDataRequest: FormDataRequest = {
      type: this.formType,
      solicitantRut: this.utils.rutClean(rut),
      phone,
      email,
    };

    this.formsService.getFormData(formDataRequest)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response: PartialRequestLoadResponse) => {
          if (this.returnApplicantRut) {
            this.solicitantRutRequired.emit(rut);
          }
          this.formData.emit(response);
        },
        (error) => this.mapError(error)
      );
  }

  public setShowForm(value: boolean): void {
    this.showForm = value;
  }

  private mapError(error): void {
    const route = this.isAffiliate ? applicationsAffiliateStatePath : applicationsPensionerStatePath;
    const mappedError = this.utils.mapDatastoreMessageError(error, () => this.router.navigate([route]));
    if (mappedError) { this.datastoreHandleError(mappedError); } else { this.handleError(error); }
  }

  private handleError(error): void {
    const { title, firstBtnText, iconName } = REQUEST_FAILED_SUBMISSION;
    const description = this.errorUtils.handleServiceError(error);
    const primaryCallback = () => { };
    const data = { title, firstBtnText, description, iconName, primaryCallback };
    this.modalService.openModal(ModalGenericComponent, { data });
  }

  private datastoreHandleError(data: GenericModalData): void {
    this.modalService.openModal(ModalGenericComponent, { data });
  }

  private typeSearchOptionSubscription(): void {
    this.typeSearchOption.valueChanges.subscribe(() => this.changeTypeSearchOption());
  }

  private changeTypeSearchOption(): void {
    this.formUtils.disableFields(this.form, ['phone', 'email']);
    if (this.phoneOptionSelected) {
      this.formUtils.enableFields(this.form, ['phone']);
    } else {
      this.formUtils.enableFields(this.form, ['email']);
    }
  }
}
