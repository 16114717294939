import { Injectable } from '@angular/core';
import { EMPLOYER_CURRENT_COMISSIONES_HEADER_ID, FOOTER_ADMINISTRATION_HEADER_ID, FOOTER_ESSENTIAL_FACTS_HEADER_ID, FOOTER_FINANTIAL_STATEMENTS_HEADER_ID, FOOTER_INFORMATION_OF_INTEREST_HEADER_ID } from '@constants/banners.constant';

import { CURRENT_COMMISSIONS_FIELDS_ARRAY } from '@constants/cms.constant';
import { FUNDS_WITHDRAWAL_CONTENT } from '@constants/pages-content/funds-withdrawal.constant';
import { FUTURE_PENSIONER_CONTENT } from '@constants/pages-content/future-pensioner.constant';
import { MULTI_FUNDS_CONTENT } from '@constants/pages-content/multi-funds.constant';
import { LANGUAGE_KEY } from '@constants/translate.constant';
import { environment } from '@environment';
import { AdministrationWorkers, WorkerContent } from '@interfaces/administration.interface';
import {
  Banner,
  BannerTranslation,
  HeaderItem,
  HeaderTranslation,
} from '@interfaces/banner.interface';
import { CalendarItemCms, CalendarItems } from '@interfaces/calendar.interface';
import {
  AfpPolicies,
  AnnualMemory,
  AdministrationWorkers as CmsAdministrationWorkers,
  Link as CmsLink,
  CorporateGovernance,
  EssentialFacts,
  FinancialStatements,
  InformationOfInterest,
  LinkCollection,
  Workers
} from '@interfaces/cms-corporate.interface';
import {
  AlertBannerItem,
  AlertBannerTranslation,
  ArticleItem,
  GeneralInfoItem,
  ImageItem,
  LinkItem,
} from '@interfaces/cms.interface';
import { AlertBanner, Article, GeneralInfo, GuiaVitalItem, Link } from '@interfaces/components.interface';
import { CurrentCommissionsContent } from '@interfaces/current-commissions.interface';
import {
  CurrentCommissionsValuesItem,
  EmployerHome,
  FamilyAllowance,
  FamilyAllowanceItem,
  FamilyAllowanceTable
} from '@interfaces/employer.interface';
import { FaqsContent, FaqsItem, FaqsSectionContent, SectionsItem } from '@interfaces/faqs.interface';
import {
  EconomicIndicatorsInfo,
  EconomicIndicatorsItem,
  FinancialInformation,
  FinancialInformationItem,
  InvestmentPortfolioGraphics,
  InvestmentPortfolioInfo,
  InvestmentPortfolioItems,
} from '@interfaces/financial-information.interface';
import { FooterColumn, FooterItem } from '@interfaces/footer.interface';
import { FundsWithdrawal, FundsWithdrawalItem } from '@interfaces/fund-withdrawal';
import { FuturePensioner, FuturePensionerItem } from '@interfaces/future-pensioner.interface';
import { DownloadContentItems, DownloadContent, DownloadSection, Section, UrlSection, UrlSectionContent, DownloadSectionContent } from '@interfaces/general.interface';
import { AffiliateHomeItem, EmployerHomeItem, Home } from '@interfaces/home.interface';
import { Card, Modal } from '@interfaces/info-card.interface';
import { Infographic, InfographicItem } from '@interfaces/infographic.interface';
import { MortuaryQuotaRelationshipsItem } from '@interfaces/mortuary-quota-form.interface';
import {
  MultiFunds,
  MultiFundsItem,
  ProfitabilityTableItem,
  ProfitabilityTableItemJson
} from '@interfaces/multi-funds.interface';
import { CalendarCms } from '@interfaces/pension-payment.interface';
import { Product, ProductItem } from '@interfaces/product.interface';
import { QuotaValuesBanner, QuotaValuesBannerItem } from '@interfaces/quota-values-banner.interface';
import * as Saving from '@interfaces/savings.interface';
import { SuperintendenceBanner, SuperintendenceBannerItem } from '@interfaces/superintendence-banner.interface';
import { TransferState, TransferStateItem } from '@interfaces/transfer-state.interface';
import { TransferItem, WhyTransfer } from '@interfaces/why-transfer.interface';
import { MANDATORY_SAVINGS_CONTENT } from '@pages-content/mandatory-savings.constant';
import { SECONDARY_ACCOUNT_CONTENT } from '@pages-content/secondary-account.constant';
import { VOLUNTARY_SAVINGS_CONTENT } from '@pages-content/voluntary-savings.constant';

@Injectable()
export class CmsUtils {

  public mapHome(
    homeItem: AffiliateHomeItem, articles: Array<Article>, banners: Array<Banner>, products: Array<Product>,
    guiaVitalItems: Array<GuiaVitalItem>, language: string): Home {
    const selectedBanners = homeItem.banners.map(banner => banner.banners_id);
    const login = homeItem.login.find(item => item.languages_code === language);
    const mainActions = homeItem.acciones_principales.find(item => item.languages_code === language);
    const selectedProducts = homeItem.productos.map(product => product.productos_id);
    const productsContainer = homeItem.contenedor_productos.find(item => item.languages_code === language);
    const selectedArticles = homeItem.articulos.map(article => article.articulos_id);
    const articlesContainer = homeItem.contenedor_articulos.find(item => item.languages_code === language);
    const selectedGuiaVital = homeItem.tuguiavital.map(item => item.tu_guia_vital_id);
    const guiaVitalContainer = homeItem.contenedor_tuguiavital.find(item => item.languages_code === language);
    const selectedMoreArticles = homeItem.mas_articulos.map(article => article.articulos_id);
    const fundsContainer = homeItem.valores_cuota.find(item => item.languages_code === language);

    return {
      id: homeItem.id,
      banners: banners.filter(banner => selectedBanners.includes(banner.id)),
      productsContainer: {
        title: productsContainer ? productsContainer.titulo : null,
        subtitle: productsContainer ? productsContainer.subtitulo : null,
        products: products.filter(product => selectedProducts.includes(product.id)),
      },
      login: {
        title: login ? login.titulo : null,
        rut: login ? login.rut : null,
        password: login ? login.clave : null,
        loginButton: login ? login.boton_ingresar : null,
        forgotPasswordMessage: login ? login.recuperar_clave : null,
      },
      mainActions: {
        leftIcon: homeItem.icono_accion_izquierda,
        leftAction: mainActions ? mainActions.mensaje_izquierda : null,
        leftActionButton: mainActions ? mainActions.boton_izquierda : null,
        rightIcon: homeItem.icono_accion_derecha,
        rightAction: mainActions ? mainActions.mensaje_derecha : null,
        rightActionButton: mainActions ? mainActions.boton_derecha : null,
      },
      articlesContainer: {
        title: articlesContainer ? articlesContainer.titulo : null,
        subtitle: articlesContainer ? articlesContainer.subtitulo : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        moreInfoText: articlesContainer ? articlesContainer.mas_informacion : null,
        disclaimer: articlesContainer ? articlesContainer.texto_inferior : null,
        disclaimerButton: articlesContainer ? articlesContainer.boton : null,
        disclaimerUrl: homeItem.url_boton_articulos,
      },
      moreArticlesContainer: {
        title: null,
        subtitle: null,
        articles: articles.filter(article => selectedMoreArticles.includes(article.id)),
        moreInfoText: articlesContainer ? articlesContainer.mas_informacion : null,
      },
      fundsContainer: {
        title: fundsContainer ? fundsContainer.titulo : null,
        subtitle: fundsContainer ? fundsContainer.texto_fecha : null,
        fundName: fundsContainer ? fundsContainer.fondo : null,
        chartTitle: fundsContainer ? fundsContainer.titulo_grafico : null,
        disclaimer: fundsContainer ? fundsContainer.texto_inferior : null,
        disclaimerButton: fundsContainer ? fundsContainer.boton : null,
        disclaimerUrl: homeItem.url_boton_valores_cuota,
      },
      guiaVitalContainer: {
        title: guiaVitalContainer ? guiaVitalContainer.titulo : null,
        subtitle: guiaVitalContainer ? guiaVitalContainer.subtitulo : null,
        articles: guiaVitalItems.filter(item => selectedGuiaVital.includes(item.id)),
        moreInfoText: guiaVitalContainer ? guiaVitalContainer.mas_informacion : null,
      }
    };
  }

  public mapEmployerHome(homeItem: EmployerHomeItem, articles: Array<Article>, banners: Array<Banner>, language: string): EmployerHome {
    const selectedBanners = homeItem.banners.map(banner => banner.banners_id);
    const selectedArticles = homeItem.articulos.map(article => article.articulos_id);
    const articlesContainer = homeItem.contenedor_articulos.find(item => item.languages_code === language);
    return {
      banners: banners.filter(banner => selectedBanners.includes(banner.id)),
      articlesSection: {
        title: articlesContainer ? articlesContainer.titulo : null,
        subtitle: articlesContainer ? articlesContainer.subtitulo : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        moreInfoText: articlesContainer ? articlesContainer.mas_informacion : null,
      }
    };
  }

  public mapFamilyAllowance(familyAllowanceItem: FamilyAllowanceItem, banners: Array<Banner>, language: string): FamilyAllowance {
    const selectedBanner = familyAllowanceItem.banner;
    const familyAllowanceContainer = familyAllowanceItem.asignacion_familiar_seccion.find(item => item.languages_code === language);
    const monthlyCapsContainer = familyAllowanceItem.topes_mensuales.find(item => item.languages_code === language);
    return {
      banner: banners.find(banner => selectedBanner === banner.id),
      familyAllowanceSection: {
        title: familyAllowanceContainer ? familyAllowanceContainer.titulo : null,
        description: familyAllowanceContainer ? familyAllowanceContainer.descripcion : null,
        buttonLabel: familyAllowanceContainer ? familyAllowanceContainer.texto_boton : null,
        buttonUrl: familyAllowanceContainer ? familyAllowanceContainer.url_boton : null,
        table: familyAllowanceContainer ? this.tableFormat(familyAllowanceContainer.tabla) : null
      },
      monthlyCaps: {
        title: monthlyCapsContainer ? monthlyCapsContainer.titulo : null,
        description: monthlyCapsContainer ? monthlyCapsContainer.descripcion : null,
        buttonLabel: monthlyCapsContainer ? monthlyCapsContainer.texto_boton : null,
        buttonUrl: monthlyCapsContainer ? monthlyCapsContainer.url_boton : null,
        table: monthlyCapsContainer ? this.tableFormat(monthlyCapsContainer.tabla) : null
      }
    };
  }

  public mapCurrentCommissionsValues(currentCommissions: CurrentCommissionsValuesItem, headers: Array<Banner>): CurrentCommissionsContent {
    const header = this.mapHeaderImages(headers, EMPLOYER_CURRENT_COMISSIONES_HEADER_ID);
    if (!currentCommissions) {
      return {
        currentValues: [],
        header,
      };
    }
    return {
      currentValues: CURRENT_COMMISSIONS_FIELDS_ARRAY.map((value) => {
        return currentCommissions[value];
      }),
      header,
    };
  }

  public mapFinancialStatements(financialStatementsItem: FinancialStatements, headers: Array<Banner>, language = 'es'): DownloadContentItems {
    const header = headers.find(item => item.id === FOOTER_FINANTIAL_STATEMENTS_HEADER_ID);
    if (!financialStatementsItem) { return { downloadContent: [], header }; }
    const traducciones = financialStatementsItem.traducciones.find(item => item.languages_code === language);
    const financialStatementsContent = traducciones.contenido;
    const downloadContent: Array<DownloadContent> = [];
    const administrationTitle = financialStatementsContent.titulos.titulo_administradora;
    const fundTitle = financialStatementsContent.titulos.titulo_fondos;
    const financialStatements = financialStatementsContent.estados_financieros;

    financialStatements.forEach((statement) => {
      const downloadSectionList: Array<DownloadSection> = [];
      let urlSectionList = this.parseToDownloadSection(statement.administradora).urlSectionList;
      downloadSectionList.push({
        title: `<p><strong>${statement.subtitulo}</strong></p><p>${administrationTitle}</p>`,
        urlSectionList
      });
      urlSectionList = this.parseToDownloadSection(statement.fondo).urlSectionList;
      downloadSectionList.push({ title: `<p>${fundTitle}</p>`, urlSectionList });
      downloadContent.push({ title: statement.titulo, downloadSectionList });
    });
    return { downloadContent, header };
  }

  public mapEssentialFacts(essentialFactsItem: EssentialFacts, headers: Array<Banner>, language = 'es'): DownloadContentItems {
    const header = headers.find(item => item.id === FOOTER_ESSENTIAL_FACTS_HEADER_ID);
    if (!essentialFactsItem) { return { downloadContent: [], header}; }
    const traducciones = essentialFactsItem.traducciones.find(item => item.languages_code === language);
    const essentialFactsContent = traducciones.contenido;
    const essentialFacts = essentialFactsContent.hechos_esenciales;
    return { downloadContent: this.parseToDownloadContent(essentialFacts), header };
  }

  public mapInformationOfInterest(informationOfInterestItem: InformationOfInterest, headers: Array<Banner>, language = 'es'):
  DownloadContentItems {
    const header = headers.find(item => item.id === FOOTER_INFORMATION_OF_INTEREST_HEADER_ID);
    if (!informationOfInterestItem) { return { downloadContent: [], header}; }
    const traducciones = informationOfInterestItem.traducciones.find(item => item.languages_code === language);
    const informationOfInterestContent = traducciones.contenido;
    const informationOfInterest = informationOfInterestContent.informacion_de_interes;
    return { downloadContent: this.parseToDownloadContent(informationOfInterest), header };
  }

  public mapCorporateGovernance(corporateGovernanceItem: CorporateGovernance, language = 'es'): {
    topics: Array<DownloadSection>; certificate: DownloadSection;
  } {
    if (!corporateGovernanceItem) { return; }
    const traducciones = corporateGovernanceItem.traducciones.find(item => item.languages_code === language);
    const corporateGovernanceContent = traducciones.contenido;
    const corporateGovernance = corporateGovernanceContent.gobierno_corporativo;
    const certificate = corporateGovernanceContent.certificado;
    return {
      topics: this.parseToDownloadSectionArray(corporateGovernance),
      certificate: this.parseToDownloadSection(certificate.items, certificate.titulo)
    };
  }

  public mapAnnualMemoryValues(annualMemoryItem: AnnualMemory, header: Banner, language = 'es'): UrlSectionContent {
    if (!annualMemoryItem) { return { urlSection: [], header}; }
    const traducciones = annualMemoryItem.traducciones.find(item => item.languages_code === language);
    const annualMemoryContent = traducciones.contenido;
    const annualMemory = annualMemoryContent.memoria_anual;
    return { urlSection: this.parseToDownloadSection(annualMemory).urlSectionList, header };
  }

  public mapAfpPoliciesValues(afpPoliciesItem: AfpPolicies, header: Banner, language = 'es'): DownloadSectionContent {
    if (!afpPoliciesItem) { return { downloadSection: [], header}; }
    const traducciones = afpPoliciesItem.traducciones.find(item => item.languages_code === language);
    const afpPoliciesContent = traducciones.contenido;
    const afpPolicies = afpPoliciesContent.politicas_afp;
    return { downloadSection: this.parseToDownloadSectionArray(afpPolicies), header };
  }

  public mapFaqs(faqs: FaqsItem, faqsSections: Array<FaqsSectionContent>, language: string): FaqsContent {
    const selectedFaqsSections = faqs.secciones_preguntas.map(seccion => seccion.secciones_preguntas_id);
    const traducciones = faqs.traducciones.find(item => item.languages_code === language);
    return {
      title: traducciones ? traducciones.titulo : null,
      sections: faqsSections.filter(section => selectedFaqsSections.includes(section.id as number))
    };
  }

  public mapFaqsSections(faqsSections: Array<SectionsItem>, language: string): Array<FaqsSectionContent> {
    return faqsSections.map(section => {
      const traducciones = section.traducciones.find(item => item.languages_code === language);
      return {
        id: section.id,
        name: traducciones ? traducciones.nombre : null,
        faqs: traducciones ? this.getFaqsFromSection(traducciones.preguntas) : null
      };
    });
  }

  private getFaqsFromSection(preguntas: { [key: string]: any; }): Array<Section> {
    if (preguntas.data) {
      const result = preguntas.data.reduce((acc, item) => {
        const tempKey = Object.keys(item)[0];
        acc[tempKey] = item[tempKey];
        return acc;
      }, {});
      preguntas = result;
    }
    return Object.keys(preguntas).map((key) => {
      return { title: key, description: preguntas[key] };
    });
  }

  public mapWhyTransfer(
    transferItem: TransferItem, articles: Array<Article>, infographics: Array<Infographic>, language: string): WhyTransfer {
    const selectedArticles = transferItem.articulos.map(article => article.articulos_id);
    const selectedInfographics = transferItem.carrusel.map(item => item.infografias_id);
    const traducciones = transferItem.traducciones.find(item => item.languages_code === language);
    return {
      id: transferItem.id,
      header: {
        image: {
          url: transferItem.imagen_header ? this.getImageUrl(transferItem.imagen_header.filename_disk) : null,
          alt: traducciones ? traducciones.alt_imagen_header : null,
          title: traducciones ? traducciones.title_image : null,
        },
        image_mobile: {
          url: transferItem.imagen_header_mobile ? this.getImageUrl(transferItem.imagen_header_mobile.filename_disk) : null,
          alt: traducciones ? traducciones.alt_imagen_header : null,
          title: traducciones ? traducciones.title_image : null,
        },
        icon: transferItem.icono_header,
        firstLine: traducciones ? traducciones.header : null,
      },
      mainAction: {
        disclaimer: traducciones ? traducciones.texto_traspaso_header : null,
        disclaimerButton: traducciones ? traducciones.boton_traspaso : null,
        disclaimerUrl: transferItem.link_traspaso.url,
      },
      carouselTitle: traducciones ? traducciones.titulo_carrusel : null,
      carousel: infographics.filter(item => selectedInfographics.includes(item.id)),
      promoteApp: {
        body: traducciones ? traducciones.promover_aplicacion_movil : null,
        titleStores: traducciones ? traducciones.titulo_tiendas_aplicacion_movil : null
      },
      instructions: {
        body: traducciones ? traducciones.traspaso : null,
        disclaimer: traducciones ? traducciones.ir_a_traspaso : null,
        disclaimerButton: traducciones ? traducciones.boton_traspaso : null,
        disclaimerUrl: transferItem.link_traspaso.url,
      },
      articlesSection: {
        title: traducciones ? traducciones.titulo_articulos : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        disclaimer: traducciones ? traducciones.disclaimer_articulos : null,
        disclaimerButton: traducciones ? traducciones.boton_articulos : null,
        disclaimerUrl: transferItem.link_seccion_articulos.url,
      }
    };
  }

  public mapTransferState(transferItem: TransferStateItem, articles: Array<Article>, headers: Array<Banner>, language: string
  ): TransferState {
    const selectedArticles = transferItem.articulos.map(article => article.articulo_id);
    const header = headers.find(item => item.id === transferItem.header.id);
    const traducciones = transferItem.traducciones.find(item => item.languages_code === language);
    return {
      id: transferItem.id,
      header: {
        image: {
          url: header.image.url,
          alt: header.image.alt,
          title: null
        },
        image_mobile: {
          url: header.image_mobile.url,
          alt: header.image.alt,
          title: header.image.title
        },
        icon: header.icon,
        firstLine: header.firstLine,
      },
      statusInformation: {
        title: traducciones ? traducciones.titulo : null,
        subtitle: traducciones ? traducciones.subtitulo : null,
        body: traducciones ? traducciones.body : null,
      },
      transferStatusBox: {
        title: traducciones ? traducciones.titulo_formulario : null,
        rut: traducciones ? traducciones.rut : null,
        date: traducciones ? traducciones.fecha : null,
        button: traducciones ? traducciones.boton_formulario : null,
      },
      transferBanner: {
        text: traducciones ? traducciones.traspaso_texto : null,
        button: traducciones ? traducciones.traspaso_boton : null,
      },
      articlesSection: {
        title: traducciones ? traducciones.articulos_titulo : null,
        articles: articles.filter(article => selectedArticles.includes(article.id)),
        disclaimer: traducciones ? traducciones.articulos_texto : null,
        disclaimerButton: traducciones ? traducciones.articulos_boton : null,
        disclaimerUrl: transferItem.link_seccion_articulos ? transferItem.link_seccion_articulos.url : null,
      }
    };
  }

  public mapFooter(footerItem: FooterItem, links: Array<Link>, language: string): Array<FooterColumn> {
    const columnsLinks: Array<string> = Object.keys(footerItem).filter((element) => element.includes('links'));
    return columnsLinks.map((id, index) => this.createColumn(footerItem, links, language, id, index));
  }

  public mapSuperintendenceBanner(bannerItem: SuperintendenceBannerItem, language: string): SuperintendenceBanner {
    const traducciones = bannerItem.traducciones.find(traduccion => traduccion.languages_code === language);
    return {
      text: traducciones ? traducciones.texto : null,
      button: traducciones ? traducciones.texto_boton : null,
      url: bannerItem.link_superintendencia.url
    };
  }

  public mapQuotaValuesBanner(quotaValuesItem: QuotaValuesBannerItem, language: string): QuotaValuesBanner {
    const traducciones = quotaValuesItem.traducciones.find(traduccion => traduccion.languages_code === language);
    return {
      quotaValue: traducciones ? traducciones.valores_cuota : null,
      fund: traducciones ? traducciones.fondo : null,
    };
  }

  public mapArticles(articleItems: Array<ArticleItem>, language: string): Array<Article> {
    return articleItems.map(articleItem => {
      const articleTranslation = articleItem.traducciones.find(item => item.languages_code === language);
      const { id, identificador, imagen, url } = articleItem;
      return {
        id,
        url,
        identifier: identificador,
        image: {
          url: imagen ? this.getImageUrl(imagen.filename_disk) : null,
          alt: articleTranslation ? articleTranslation.alt_imagen : null,
          title: articleTranslation ? articleTranslation.title_image : null,
        },
        title: articleTranslation ? articleTranslation.titulo : null,
      };
    });
  }

  public mapGuiaVitalItems(articleItems: Array<ArticleItem>, language: string): Array<GuiaVitalItem> {
    const articles: Array<GuiaVitalItem> = [];
    articleItems.forEach(articleItem => {
      const articleTranslation = articleItem.traducciones.find(item => item.languages_code === language);
      const { id, identificador, imagen, url } = articleItem;
      const article: GuiaVitalItem = {
        id,
        url,
        identifier: identificador,
        image: {
          url: imagen.filename_disk ? this.getImageUrl(imagen.filename_disk) : null,
          alt: articleTranslation ? articleTranslation.alt_imagen : null,
          title: articleTranslation ? articleTranslation.title_image : null,
        },
        title: articleTranslation ? articleTranslation.titulo : null,
      };
      articles.push(article);
    });
    return articles;
  }

  public mapBanners(bannerItems: Array<HeaderItem<BannerTranslation>>, language: string): Array<Banner> {
    const banners: Array<Banner> = [];
    bannerItems.forEach(bannerItem => {
      const bannerTranslation = bannerItem.traducciones.find(item => item.languages_code === language);
      const banner: Banner = {
        id: bannerItem.id,
        image: {
          url: bannerItem.imagen ? this.getImageUrl(bannerItem.imagen.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        image_mobile: {
          url: bannerItem.imagen_mobile ? this.getImageUrl(bannerItem.imagen_mobile.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        } ,                    
        icon: bannerItem.icono,
        firstLine: bannerTranslation ? bannerTranslation.primera_linea : null,
        secondLine: bannerTranslation ? bannerTranslation.segunda_linea : null,
        button: bannerTranslation ? bannerTranslation.boton : null,
        url: bannerItem.url,
      };
      banners.push(banner);
    });
    return banners;
  }

  public mapPaymentCenters(paymentCenterItems: Array<HeaderItem<BannerTranslation>>, language: string): Array<Card<any>> {
    return paymentCenterItems.map(paymentCenterItem => {
      const paymentCenterTranslation = paymentCenterItem.traducciones.find(item => item.languages_code === language);
      return {
        image: {
          url: paymentCenterItem.imagen ? this.getImageUrl(paymentCenterItem.imagen.filename_disk) : null,
          alt: paymentCenterTranslation ? paymentCenterTranslation.alt_imagen : null,
        },
        title: paymentCenterTranslation ? paymentCenterTranslation.primera_linea : null,
        requestText: paymentCenterTranslation ? paymentCenterTranslation.boton : null,
        requestUrl: paymentCenterItem.url,
      } as Card<any>;
    });
  }

  public mapCalendarItems(calendarItems: Array<CalendarItemCms>): CalendarItems {
    const calendars: CalendarItems = { servipagCalendar: [], bankCalendar: [], enrollmentCalendar: [] };
    calendarItems.forEach(calendarItem => {
      const sortedDates = Object.entries(calendarItem.fechas)
        .sort(([a], [b]) => this.getMonthIndex(a) - this.getMonthIndex(b))
        .map(([, value]) => value);

      calendars[calendarItem.titulo] = sortedDates;
    });
    return calendars;
  }

  private getMonthIndex(month: string): number {
    const monthOrder = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october',
      'november', 'december'];
    return monthOrder.indexOf(month.toLowerCase());
  }

  public mapCalendarCms(paymentCenters: Array<Card<any>>, calendars: CalendarItems): CalendarCms {
    return { calendars, paymentCenters };
  }

  public mapLinks(linkItems: Array<LinkItem>, language: string): Array<Link> {
    const links: Array<Link> = [];
    linkItems.forEach(linkItem => {
      const linkTranslation = linkItem.traducciones.find(item => item.languages_code === language);
      const link: Link = {
        id: linkItem.id,
        title: linkTranslation ? linkTranslation.titulo : null,
        url: linkItem.url,
        externo: linkItem.url.startsWith('http'),
      };
      links.push(link);
    });
    return links;
  }

  public mapInfographics(infographicItems: Array<InfographicItem>, language: string): Array<Infographic> {
    const infographics: Array<Infographic> = [];
    infographicItems.forEach(infographicItem => {
      const infographicTranslation = infographicItem.traducciones.find(item => item.languages_code === language);
      const infographic: Infographic = {
        id: infographicItem.id,
        icon: infographicItem.icono,
        title: infographicTranslation ? infographicTranslation.titulo : null,
        description: infographicTranslation ? infographicTranslation.descripcion : null,
        showMoreButton: infographicTranslation ? infographicTranslation.mostrar_mas : null,
        modal: {
          title: infographicTranslation ? infographicTranslation.titulo_modal : null,
          description: infographicTranslation ? infographicTranslation.descripcion_modal : null,
          image: {
            url: infographicItem.imagen_modal ? this.getImageUrl(infographicItem.imagen_modal.filename_disk) : null,
            alt: infographicTranslation ? infographicTranslation.alt_imagen_modal : null,
            title: null,
          },
          disclaimer: infographicTranslation ? infographicTranslation.texto_inferior_modal : null,
          disclaimerButton: infographicTranslation ? infographicTranslation.boton_modal : null,
          disclaimerUrl: infographicItem.url_modal,
        }
      };
      infographics.push(infographic);
    });
    return infographics;
  }

  public mapProducts(productsItems: Array<ProductItem>, language: string): Array<Product> {
    const products: Array<Product> = [];
    productsItems.forEach((productsItem) => {
      const productTranslation = productsItem.traducciones.find(item => item.languages_code === language);
      const product: Product = {
        id: productsItem.id,
        identifier: productsItem.identificador,
        icon: productsItem.icono,
        title: productTranslation ? productTranslation.titulo : null,
        description: productTranslation ? productTranslation.descripcion : null,
        productUrl: productsItem.url_producto,
        textButton: productTranslation ? productTranslation.texto_boton : null,
      };
      products.push(product);
    });
    return products;
  }

  public mapHeaders(headerItems: Array<HeaderItem<HeaderTranslation>>, language: string): Array<Banner> {
    const banners: Array<Banner> = [];
    headerItems.forEach(bannerItem => {
      const bannerTranslation = bannerItem.traducciones.find(item => item.languages_code === language);
      const banner: Banner = {
        id: bannerItem.id,
        image: {
          url: bannerItem.imagen ? this.getImageUrl(bannerItem.imagen.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        image_mobile: {
          url: bannerItem.imagen_mobile ? this.getImageUrl(bannerItem.imagen_mobile.filename_disk) : null,
          alt: bannerTranslation ? bannerTranslation.alt_imagen : null,
          title: bannerTranslation ? bannerTranslation.title_image : null,
        },
        icon: bannerItem.icono,
        firstLine: bannerTranslation ? bannerTranslation.titulo : null,
      };
      banners.push(banner);
    });
    return banners;
  }

  public mapMandatorySavings(mandatorySavingsItem: Saving.MandatorySavingsItem, infographics: Array<Infographic>)
    : Saving.MandatorySavings {
    const language = this.getLanguage();
    const mandatorySavings: Saving.MandatorySavings = MANDATORY_SAVINGS_CONTENT;
    const selectedInfographics = mandatorySavingsItem.carrusel.map(item => item.infografias_id);
    mandatorySavings.header.image = {
      url: mandatorySavingsItem.imagen_banner ? this.getImageUrl(mandatorySavingsItem.imagen_banner.filename_disk) : null,
      alt: mandatorySavingsItem ? mandatorySavingsItem.alt_image : null,
      title: mandatorySavingsItem ? mandatorySavingsItem.title_image : null,
    };
    mandatorySavings.header.image_mobile = {
      url: mandatorySavingsItem.imagen_banner_mobile ? this.getImageUrl(mandatorySavingsItem.imagen_banner_mobile.filename_disk) : null,
      alt: mandatorySavingsItem ? mandatorySavingsItem.alt_image : null,
      title: mandatorySavingsItem ? mandatorySavingsItem.title_image : null
    };
    mandatorySavings.carousel.benefits = infographics.filter(item => selectedInfographics.includes(item.id));
    mandatorySavings.adviceSection.body = mandatorySavingsItem.traducciones.find(item => item.languages_code === language).consejos;
    return mandatorySavings;
  }

  public mapVoluntarySavings(
    voluntarySavingsItem: Saving.VoluntarySavingsItem, infographics: Array<Infographic>): Saving.VoluntarySavings {
    const language = this.getLanguage();
    const voluntarySavings: Saving.VoluntarySavings = VOLUNTARY_SAVINGS_CONTENT;
    const selectedInfographics = voluntarySavingsItem.carrusel.map(item => item.infografias_id);
    voluntarySavings.header.image = {
      url: voluntarySavingsItem.imagen_banner ? this.getImageUrl(voluntarySavingsItem.imagen_banner.filename_disk) : null,
      alt: voluntarySavingsItem ? voluntarySavingsItem.alt_image : null,
      title: voluntarySavingsItem ? voluntarySavingsItem.title_image : null,
    };
    voluntarySavings.header.image_mobile = {
      url: voluntarySavingsItem.imagen_banner_mobile ? this.getImageUrl(voluntarySavingsItem.imagen_banner_mobile.filename_disk) : null,
      alt: voluntarySavingsItem ? voluntarySavingsItem.alt_image : null,
      title: voluntarySavingsItem ? voluntarySavingsItem.title_image : null
    };
    voluntarySavings.carousel.benefits = infographics.filter(item => selectedInfographics.includes(item.id));
    voluntarySavings.questionsSection.body = voluntarySavingsItem.apv_preguntas_traducciones
      .find(item => item.languages_code === language).preguntas;
    return voluntarySavings;
  }

  public mapSecondaryAccount(
    secondaryAccountItem: Saving.SecondaryAccountItem, infographics: Array<Infographic>): Saving.SecondaryAccount {
    const lang = this.getLanguage();
    const secondaryAccount: Saving.SecondaryAccount = SECONDARY_ACCOUNT_CONTENT;
    const selectedInfographics = secondaryAccountItem.carrusel.map(item => item.infografias_id);
    secondaryAccount.header.image = {
      url: secondaryAccountItem.imagen_banner ? this.getImageUrl(secondaryAccountItem.imagen_banner.filename_disk) : null,
      alt: secondaryAccountItem ? secondaryAccountItem.alt_image : null,
      title: secondaryAccountItem ? secondaryAccountItem.title_image : null,
    };
    secondaryAccount.header.image_mobile = {
      url: secondaryAccountItem.imagen_banner_mobile ? this.getImageUrl(secondaryAccountItem.imagen_banner_mobile.filename_disk) : null,
      alt: secondaryAccountItem ? secondaryAccountItem.alt_image : null,
      title: secondaryAccountItem ? secondaryAccountItem.title_image : null,
    };
    secondaryAccount.carousel.benefits = infographics.filter(item => selectedInfographics.includes(item.id));
    const translations = secondaryAccountItem.traducciones.find(item => item.languages_code === lang);
    secondaryAccount.withdrawalsSection.body = translations.dudas;
    secondaryAccount.restrictionsSection.body = translations.diferencias;
    return secondaryAccount;
  }

  public mapFinancialInformation(financialInformationItem: FinancialInformationItem, headers: Array<Banner>): FinancialInformation {
    const language = this.getLanguage();
    const header = headers.find(item => item.id === financialInformationItem.header.id);
    const {
      titulo: title,
      cuerpo: description,
      titulo_tabla: titleTable,
      tabla: table,
      fecha_actualizacion: informationUpdateDate
    } = financialInformationItem.traducciones.find(item => item.languages_code === language);
    return {
      header,
      profitabilityVariation: { title, description, titleTable, table, informationUpdateDate },
    };
  }

  public mapInvestmentPortfolio(investmentPortfolioItems: InvestmentPortfolioItems): InvestmentPortfolioInfo {
    const {
      fecha_actualizacion: informationUpdateDate,
      titulo: title,
      contenido: description,
      titulo_grafico_zona: chartZoneDescription,
      titulo_grafico_cartera: chartDistributionDescription

    } = investmentPortfolioItems.traducciones.find(item => item.languages_code === this.getLanguage());
    const charts = investmentPortfolioItems.graficos.map( (graphic: InvestmentPortfolioGraphics) => {
      return {
        alt_distribution: graphic.cartera_inversiones_graficos_id.alt_cartera,
        alt_zone: graphic.cartera_inversiones_graficos_id.alt_zona,
        distribution_chart: this.getImageUrl(graphic.cartera_inversiones_graficos_id.grafico_cartera.filename_disk),
        zone_chart: this.getImageUrl(graphic.cartera_inversiones_graficos_id.grafico_zona.filename_disk),
        fund_title: graphic.cartera_inversiones_graficos_id.titulo_fondo,
      };
    });
    return { informationUpdateDate, title, description, chartZoneDescription, chartDistributionDescription, charts };
  }

  public mapEconomicIndicators(economicIndicatorItems: EconomicIndicatorsItem): EconomicIndicatorsInfo {
    const { id, filename_download: fileName } = economicIndicatorItems.reporte;
    const reportFileUrl = this.getPDFUrl(id);
    const {
      titulo: title,
      texto_boton: textButton,

    } = economicIndicatorItems.traducciones.find(item => item.languages_code === this.getLanguage());
    return { reportFileUrl, fileName, title, textButton };
  }

  public mapMultiFunds(multiFundsItem: MultiFundsItem, headers: Array<Banner>, language: string): MultiFunds {
    const multiFunds: MultiFunds = MULTI_FUNDS_CONTENT;
    multiFunds.header = headers.find(item => item.id === multiFundsItem.header.id);
    const { rentabilidad = null } = multiFundsItem.traducciones.find(item => item.languages_code === language) || {};
    if (!rentabilidad) { return multiFunds; }
    const profitabilityCard = multiFunds.moreInfoCards.find(item => item.modal && item.modal.type === 'profitability');
    profitabilityCard.modal.data.header = this.assignProfitabilityItem(rentabilidad.titulos);
    profitabilityCard.modal.data.items = rentabilidad.items.map(item => this.assignProfitabilityItem(item));
    return multiFunds;
  }

  public mapFuturePensioner(futurePensionerItem: FuturePensionerItem, banners: Array<Banner>, headers: Array<Banner>): FuturePensioner {
    const futurePensioner: FuturePensioner = FUTURE_PENSIONER_CONTENT;
    const selectedBanners = futurePensionerItem.banners.map(banner => banner.banners_id);
    futurePensioner.banners = banners.filter(banner => selectedBanners.includes(banner.id));
    const calendarHeader = headers.find(header => header.id === 4);
    futurePensioner.header = {
      id: calendarHeader.id,
      image: {
        url: calendarHeader.image.url,
        alt: calendarHeader.image.alt,
        title: calendarHeader.image.title
      },
      image_mobile: {
        url: calendarHeader.image_mobile.url,
        alt: calendarHeader.image.alt,
        title: calendarHeader.image.title
      },
      icon: calendarHeader.icon,
      firstLine: calendarHeader.firstLine,
    };
    return futurePensioner;
  }

  public mapHeaderImages(headers: Array<Banner>, headerId: number): Banner {
    const headerItem = headers.find(header => header.id === headerId);
    const banner: Banner = {
      id: headerItem.id,
      image: {
        url: headerItem.image.url,
        alt: headerItem.image.alt,
        title: headerItem.image.title
      },
      image_mobile: {
        url: headerItem.image_mobile.url,
        alt: headerItem.image.alt,
        title: headerItem.image.title
      },
      icon: headerItem.icon,
      firstLine: headerItem.firstLine
    };
    return banner;
  }

  public mapAlertBanner(alertBannerItems: Array<AlertBannerItem>, language: string): AlertBanner {
    if (!alertBannerItems.length) { return { title: '', message: '', active: false }; }
    return this.getBannerDetail(alertBannerItems[0], language);
  }

  public mapGeneralInfo(generalInfoItem: GeneralInfoItem, language: string): GeneralInfo {
    const { contact_center_habilitado = false } = generalInfoItem;
    const { horarios_call_center = null } = generalInfoItem.traducciones.find(item => item.languages_code === language) || {};
    const {
      titulo = null,
      bajada = null,
      boton = null
    } = generalInfoItem.flotante.find(item => item.languages_code === language) || {};
    return {
      callCenterAvailable: contact_center_habilitado,
      callCenterHours: horarios_call_center,
      loginModalActive: generalInfoItem.modal_login,
      floatingButton: {
        show: generalInfoItem.mostrar_flotante,
        title: titulo,
        description: bajada,
        button: {
          name: boton,
          url: generalInfoItem.flotante_url,
        },
      },
    };
  }

  public mapFundsWithdrawal(fundWithdrawalItem: FundsWithdrawalItem, language: string): FundsWithdrawal {
    const fundWithdrawal = FUNDS_WITHDRAWAL_CONTENT;
    fundWithdrawal.description = fundWithdrawalItem.contenido;
    return fundWithdrawal;
  }

  public mapAdministrationWorkers(
    administrationWorkersItem: CmsAdministrationWorkers, headers: Array<Banner>, language = 'es'): AdministrationWorkers {
    const traducciones = administrationWorkersItem.traducciones.find(item => item.languages_code === language);
    const workersContent = traducciones.contenido;
    const directoryContent = this.parseWorkers(workersContent.directorio);
    const executiveContent = this.parseWorkers(workersContent.ejecutivos);
    const headerContent = headers.find(header => header.id === FOOTER_ADMINISTRATION_HEADER_ID);
    return { directoryContent, executiveContent, headerContent };
  }

  public mapMortuaryQuotaRelationships(mortuaryQuotaRelationshipsItem: MortuaryQuotaRelationshipsItem, language = 'es') {
    const traducciones = mortuaryQuotaRelationshipsItem.traducciones.find(item => item.languages_code === language);
    return traducciones.cuota_mortuoria.contenido;
  }

  public getLanguage() {
    if (localStorage.getItem(LANGUAGE_KEY) !== null) { return localStorage.getItem(LANGUAGE_KEY); }
    return 'es';
  }

  private createColumn(footerItem: FooterItem, links: Array<Link>, language: string, id: string, index: number) {
    const titles = footerItem.titulos.find(titulo => titulo.languages_code === language);
    return {
      title: titles ? titles[`titulo_${index + 1}`] : null,
      links: this.getLinksColumn(footerItem, id, links),
    };
  }

  private getLinksColumn(footerItem: FooterItem, name: string, links: Array<Link>): Array<Link> {
    const linksIds: Array<number> = footerItem[name].map(link => link.links_id);
    return links.filter(link => linksIds.includes(link.id));
  }

  private assignProfitabilityItem(item: ProfitabilityTableItemJson): ProfitabilityTableItem {
    const {
      fondo: fund,
      mes: month,
      semestre: semester,
      añoPasado: lastYear,
      ultimosTresAños: lastThreeYears,
      ultimosAños: lastYears,
      historico: historic
    } = item;
    return { fund, month, semester, lastYear, lastThreeYears, lastYears, historic };
  }

  private getBannerDetail(alertBannerItem: AlertBannerItem, language: string) {
    const bannerInformation = alertBannerItem.traducciones.find(traduccion => traduccion.languages_code === language);
    const { activo: active } = alertBannerItem;
    const { titulo: title, mensaje: message } = bannerInformation;
    const modal = alertBannerItem.modal ? this.getModalDetail(bannerInformation, alertBannerItem.imagen_modal) : null;
    return { title, message, active, modal } as AlertBanner;
  }

  private getModalDetail(alertBannerTranslation: AlertBannerTranslation, imageItem: ImageItem) {
    const { modal_titulo: title, modal_descripcion: description, alt_imagen: alt } = alertBannerTranslation;
    const filename = imageItem ? imageItem.filename_disk : '';
    const image = { url: this.getImageUrl(filename), alt };
    return { title, description, image } as Modal<any>;
  }

  private getImageUrl(id: string): string {
    const url = id.length ? `${environment.cmsApiUrl}assets/${id}` : null;
    return url;
  }

  private getPDFUrl(id: string): string {
    const url = id.length ? `${environment.cmsApiUrl}assets/${id}` : null;
    return url;
  }

  private tableFormat(jsonTable: { [key: string]: any; }): Array<FamilyAllowanceTable> {

    if (jsonTable.data) {
      const result = jsonTable.data.reduce((acc, item) => {
        const tempKey = Object.keys(item)[0];
        acc[tempKey] = item[tempKey];
        return acc;
      }, {});
      jsonTable = result;
    }
    return Object.keys(jsonTable).map((key) => {
      return { title: key, value: jsonTable[key] };
    });
  }

  private parseToDownloadSection(links: Array<CmsLink>, title?: string): DownloadSection {
    const urlSectionList: Array<UrlSection> = [];
    links.forEach((item) => {
      const url = `${environment.publicFilesUrl}${item.url}`;
      urlSectionList.push({ title: item.titulo, date: item.fecha, url });
    });
    return { title, urlSectionList };
  }

  private parseToDownloadContent(collection: Array<LinkCollection>): Array<DownloadContent> {
    const downloadContent: Array<DownloadContent> = [];
    collection.forEach((linksItem) => {
      const downloadSection = this.parseToDownloadSection(linksItem.items);
      const downloadSectionList: Array<DownloadSection> = [downloadSection];
      downloadContent.push({ title: linksItem.titulo, year: linksItem.año, downloadSectionList });
    });
    return downloadContent;
  }

  private parseToDownloadSectionArray(collection: Array<LinkCollection>): Array<DownloadSection> {
    const downloadSectionList: Array<DownloadSection> = [];
    collection.forEach((linksItem) => {
      const downloadSection = this.parseToDownloadSection(linksItem.items, linksItem.titulo);
      downloadSectionList.push(downloadSection);
    });
    return downloadSectionList;
  }

  private parseWorkers(cmsWorkers: Array<Workers>): Array<WorkerContent> {
    const workers: Array<WorkerContent> = cmsWorkers.map((worker) => ({
      name: worker.nombre,
      position: worker.cargo,
      portrait: {
        url: worker.url && `${environment.publicFilesUrl}${worker.url}`,
        alt: worker.nombre,
        title: null,
      }
    }));
    return workers;
  }
}
