import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnData } from '@interfaces/table.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss']
})
export class PaginatedTableComponent implements OnInit, OnChanges {
  @Input() public inputData: any[] = [];
  @Input() public rut: string;
  @Input() public columnsData: ColumnData[] = [];
  @Input() public pageContent: any;
  @Input() public defaultSort: string;
  @Input() public detailRoute: string;
  public currentPage = 1;
  public numberOfPages = 1;
  public sortedRows = [];
  public shownPages = [];
  public currentSort = '';
  public initialized = false;

  constructor(
    public font: FontService,
    public router: Router,
    ) {}

  private refreshPagination(): void {
    this.shownPages = [];
    this.numberOfPages = Math.ceil(this.sortedRows.length / 10);
    const maxPage = this.numberOfPages;
    let startRange = this.currentPage - 2;
    let endRange = this.currentPage + 2;
    if (startRange < 1) {
      endRange = endRange + (1 - startRange);
    }
    if (endRange > maxPage) {
      startRange = startRange - (endRange - maxPage);
    }
    startRange = startRange < 1 ? 1 : startRange;
    endRange = endRange > maxPage ? maxPage : endRange;
    for (let i = startRange; i <= endRange; i++) {
      this.shownPages.push(i);
    }
  }

  public setPage(page): void {
    this.currentPage = page;
    this.refreshPagination();
  }

  public nextPage(): void { this.setPage(this.currentPage + 1); }

  public prevPage(): void { this.setPage(this.currentPage - 1); }

  private sortFnByNumber(columnName: string): (a, b) => number {
    return (a, b): number => (a[columnName] - b[columnName]);
  }

  private sortFnByDate(columnName: string): (a, b) => number {
    return (a, b) => new Date(a[columnName]).getTime() - new Date(b[columnName]).getTime();
  }

  private sortFnByString(columnName: string): (a, b) => number {
    return (a, b) => (a[columnName] > b[columnName] ? 1 : -1);
  }

  public sortRows(columnIndex: number = 0, ascendant: boolean = null): void {
    if (!this.columnsData[columnIndex]) { return; }
    const column = this.columnsData[columnIndex];
    const { name: columnName, type: columnType } = column;
    const ascendantOrder = ascendant !== null ? ascendant : this.currentSort !== `${columnName}`;
    switch (columnType) {
      case 'number':
        this.sortedRows.sort(this.sortFnByNumber(columnName));
        break;
      case 'date':
        this.sortedRows.sort(this.sortFnByDate(columnName));
        break;
      case 'string':
        this.sortedRows.sort(this.sortFnByString(columnName));
        break;
      default:
        return;
    }
    if (!ascendantOrder) { this.sortedRows.reverse(); }
    this.currentSort = `${ascendantOrder ? '' : '-'}${columnName}`;
    this.setPage(1);
  }

  private refreshSort(order: string = ''): void {
    if (!order) { return; }
    const ascendantSort = order[0] !== '-';
    const columnName = ascendantSort ? order : order.slice(1);
    const indexToSort = this.columnsData.findIndex(column => column.name === columnName);
    this.sortRows(indexToSort >= 0 ? indexToSort : 0, ascendantSort);
  }

  private initTable(): void {
    this.refreshSort(this.defaultSort);
    this.refreshPagination();
  }

  private refreshTable(): void {
    this.refreshSort(this.currentSort);
    this.refreshPagination();
  }

  public ngOnInit(): void {
    this.sortedRows = [...this.inputData];
    this.initTable();
    this.initialized = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) { return; }
    const inputChanges = changes && changes.inputData && changes.inputData.currentValue;
    if (inputChanges) {
      this.sortedRows = [...inputChanges];
      this.refreshTable();
    }
  }

  public goToDetail(row: any): void {
      const { id, rut, type } = row;
      this.router.navigate([this.detailRoute], { queryParams: { id, type, rut }});
  }

  public calculateStatusClasses(column: any, row: any): string[] {
    return column.name === 'simplyLanguageStatus' && row.colorClass ? ['status', row.colorClass, this.font.size] : [''];
  }

  public get shownRows(): any[] {return this.sortedRows.slice((this.currentPage - 1) * 10, this.currentPage * 10); }
  public pageNumberClasses(page: number): string[] { return [this.currentPage === page ? 'active' : '' , this.font.size]; }
  public get prevPageClass(): string { return this.currentPage > 1 ? '' : 'disabled'; }
  public get nextPageClass(): string { return this.currentPage < (this.sortedRows.length / 10) ? '' : 'disabled'; }

}
