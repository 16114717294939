import { Component } from '@angular/core';
import { HeaderComponent } from '@components/header/header.component';
import { LOGIN_OPTION } from '@constants/header.constant';
import { HeaderOption } from '@interfaces/header.interface';
import { MenuController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { PopoverService } from '@providers/popover/popover.service';
import { RouterService } from '@providers/router/router.service';
import { ComponentsService } from '@services/cms/components.service';
import { ModalLoginComponent } from '@components/modal-login/modal-login.component';
import { Utils } from '@utils/utils';
import { LanguageProvider } from '@providers/language/language.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent extends HeaderComponent {
  public open = false;
  public itemSelected: HeaderOption;
  public get itemValid() { return this.itemSelected && this.itemSelected.options.length > 0; }

  constructor(
    public font: FontService,
    private menuCtrl: MenuController,
    protected componentsService: ComponentsService,
    protected navService: NavigationService,
    protected modalService: ModalService,
    protected routerService: RouterService,
    protected popoverService: PopoverService,
    public language: LanguageProvider,
    public util: Utils,
  ) {
    super(font, componentsService, navService, modalService, routerService, popoverService, language, util);
  }

  public setOpen(open: boolean) {
    this.open = open;
  }

  public async openSideMenu() {
    await this.menuCtrl.open();
  }

  public async closeSideMenu(url?: string, event: any = { preventDefault: () => {}}) {
    event.preventDefault();
    await this.menuCtrl.close();
    this.setOpen(false);
    this.itemSelected = null;
    if (!url) { return; }
    this.goTo(url);
  }

  public setItem(item: HeaderOption) {
    if (item && item.isModal && this.showModalLogin) {
      this.openLoginModal();
      return this.closeSideMenu();
    }
    if (item && item.url) { return this.closeSideMenu(item.url); }
    this.itemSelected = item;
  }

  public openLoginModal(): void {
    if (!this.showModalLogin) { return this.goTo(LOGIN_OPTION.url); }
    this.modalService.openModal(
      ModalLoginComponent,
      { newUrl: LOGIN_OPTION.url, actualUrl: LOGIN_OPTION.actualUrl },
      'modal-default modal-login'
    );
  }
}
