import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AssessmentRoutingModule } from './assessment-routing.module';
import { MultiFundsPageModule } from './multi-funds/multi-funds.module';

@NgModule({
  imports: [
    RouterModule,
    AssessmentRoutingModule,
    MultiFundsPageModule,
  ],
})
export class AssessmentModule { }
