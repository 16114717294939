import { Mockup } from '@interfaces/mockup.interface';
import { GetTransferState } from '@interfaces/transfer-state.interface';

export const GET_TRANSFER_STATE_MOCKUP: Mockup = {
  success: [{
    response: {
      requestDate: new Date(2020, 2, 3),
      state: 'Rechazada',
      rejectionCode: '100',
      rejectionMessage: 'Solicitud con datos erroneos'
    } as GetTransferState
  }],
  failures: [{
    response: {
      error: {
        error: 40001
      }
    }
  }]
};
