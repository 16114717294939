import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { VALIDATE_CERTIFICATE_MODAL } from '@constants/certificate.constant';
import { ValidateCertificate } from '@interfaces/validate-certificate.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-modal-validate-certificate',
  templateUrl: './modal-validate-certificate.component.html',
  styleUrls: ['./modal-validate-certificate.component.scss'],
})

export class ModalValidateCertificateComponent extends ModalComponent {
  public validate: ValidateCertificate;
  public certificateId: string;
  public content = VALIDATE_CERTIFICATE_MODAL.CONTENT;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private utils: Utils,
  ) {
    super(params, modalCtrl);
    this.validate = this.params.get('data');
    this.certificateId = this.params.get('certificateId');
  }

  public get isValid(): boolean { return this.validate.valid; }
  public get resolution() { return VALIDATE_CERTIFICATE_MODAL[this.isValid ? 'VALID' : 'INVALID']; }

  public downloadCertificate(): void {
    this.utils.downloadPdf(this.validate.data, this.certificateId);
  }
}
