<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="sendForm()">
  <div class="form-section">
    <mat-radio-group class="option-container" formControlName="change">
      <p class="option-label" [ngClass]="font?.size">{{ pageContent?.change | translate }}</p>
      <mat-radio-button value="true" class="option" [class.selected]="isChecked('change', 'true')" [ngClass]="font?.size">
        {{ pageContent?.changeOptions.yes | translate }}
      </mat-radio-button>
      <mat-radio-button value="false" class="option" [class.selected]="isChecked('change', 'no')" [ngClass]="font?.size">
        {{ pageContent?.changeOptions.no | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="form-section second">
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.disclaimer "
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
  </div>
  <div class="form-section second" *ngIf="changePaymentMethod">
    <app-payment-method-form *ngIf="loadedPaymentMethods"
      [pageContent]="pageContent?.paymentMethods"
      [allowOtherMethods]="true"
      [removeValeVista]="true"
      [paymentData]="paymentData"
      [paymentMethods]="paymentMethods"
      [userRut]="userRut"
      (confirmForm)="validateForm($event)">
    </app-payment-method-form>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
