import { Component, Input } from '@angular/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-disclaimer-info',
  templateUrl: './disclaimer-info.component.html',
  styleUrls: ['./disclaimer-info.component.scss'],
})
export class DisclaimerInfoComponent {
  @Input() public title: string;
  @Input() public description: string;
constructor(
  public font: FontService
) { }
}
