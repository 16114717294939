import { finalize } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  ModalBeneficiaryComponent
} from '@components/modal-beneficiary/modal-beneficiary.component';
import {
  ARRAY_CHARGING_FEES_PROPERTIES, CHARGING_FEES_ADVISER_CONTROLS,
  CHARGING_FEES_AFFILIATE_CONTROLS,
  CHARGING_FEES_PENSION_BACKGROUNDS
} from '@constants/pages-content/charging-fees.constants';
import { survivalPensionTypeKey } from '@constants/pension-backgrounds.constant';
import { Beneficiary } from '@interfaces/beneficiary.interface';
import {
  ChargingFeesAdviserBackgrounds, ChargingFeesBackgrounds
} from '@interfaces/charging-fees.interface';
import { DefaultResponseItem } from '@interfaces/general.interface';
import { FieldsValidators, PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { ErrorUtils } from '@utils/error.utils';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-charging-fees-backgrounds',
  templateUrl: './charging-fees-backgrounds.component.html',
  styleUrls: ['../charging-fees.page.scss'],
})
export class ChargingFeesBackgroundsComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public formValues;
  @Output() public formData: EventEmitter<ChargingFeesBackgrounds> = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();
  public beneficiaries: Array<Beneficiary> = [];
  public chargingFeesProperties = ARRAY_CHARGING_FEES_PROPERTIES;
  public modalType = ModalBeneficiaryComponent;
  public affiliatePersonControls;
  public affiliateForm: UntypedFormGroup;
  public pensionForm: UntypedFormGroup;
  public adviserForm: UntypedFormGroup;
  public adviserPersonControls;
  public adviserUserFieldsValidators = { rut: { value: '' } } as FieldsValidators;
  public pensionControlsName = CHARGING_FEES_PENSION_BACKGROUNDS;
  public pensionsControlData;
  private relationships: Array<DefaultResponseItem>;

  public get isFormValid(): boolean {
    return this.affiliateForm && this.pensionForm && this.adviserForm &&
      this.affiliateForm.valid && this.pensionForm.valid && this.adviserForm.valid;
  }
  public get modalData(): any {
    return {
      relationships: this.relationships,
      removeContactInfo: false,
      includeAttachmentFile: true,
      beneficiaries: this.formValues ? this.formValues.beneficiaries : null
    };
  }
  public get showBeneficiariesSection(): boolean {
    return this.pensionForm ? this.pensionForm.controls.pensionType.value === survivalPensionTypeKey : false;
  }

  constructor(
    private modalService: ModalService,
    private parametersService: ParametersService,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
    private utils: Utils,
    private formUtils: FormUtils,
    public font: FontService,
  ) { }


  public ngOnInit(): void {
    this.loadData();
    this.setFormValues();
    this.pensionsControlData = this.formValues
      ? this.pensionsControlData = {
        pensionType: this.formValues.pensionType,
        pensionModality: this.formValues.pensionModality
      }
      : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formValues) {
      const mappedBeneficiaries = this.formValues.beneficiaries.map(beneficiary => {
        const { fullName, ...rest } = beneficiary;
        const testName = fullName.split(' ');
        const secondSurname = testName[2] !== '' ? testName[2] : '';
        return {
          fullName,
          secondSurname,
          ...rest
        };
      });
      this.beneficiaries = mappedBeneficiaries;
    }
  }

  public validateForm(form: UntypedFormGroup, formKey: 'affiliate' | 'adviser') {
    if (formKey === 'affiliate') { this.validateAffiliateForm(form); }
    if (formKey === 'adviser') { this.validateAdviserForm(form); }
  }

  private validateAffiliateForm(form: UntypedFormGroup) {
    this.adviserUserFieldsValidators = {
      rut: { value: this.affiliateForm.controls.rut.value }
    };

    this.affiliateForm.patchValue({
      ...form.value,
    });
  }

  private validateAdviserForm(form: UntypedFormGroup) {
    this.adviserForm.patchValue({
      ...form.value
    });
  }

  public setAffiliateForm(form: UntypedFormGroup): void {
    this.affiliateForm = form;
    this.adviserUserFieldsValidators = {
      rut: { value: this.affiliateForm.controls.rut.value }
    };
  }

  public setPensionParams(form: UntypedFormGroup): void {
    this.pensionForm = form;
  }

  public setAdviserForm(form: UntypedFormGroup): void {
    this.adviserForm = form;
  }

  public setBeneficiaries(beneficiaries: Array<Beneficiary>): void {
    this.beneficiaries = beneficiaries;
  }

  public sendForm(showModal = false): void {
    if (!this.isFormValid) { return; }
    const { names, rut, selectionDate } = this.affiliateForm.value;
    const { pensionModality, pensionType } = this.pensionForm.value;
    const chargingFeesBackgrounds = {
      affiliateBackgrounds: {
        name: names,
        rut: this.utils.rutClean(rut),
        selectionDate
      },
      adviserBackgrounds: this.getAdviserBackgrounds(),
      pensionModality,
      pensionType,
      beneficiaries: this.beneficiaries
    } as ChargingFeesBackgrounds;
    this.savedModal.emit(showModal);
    this.formData.emit(chargingFeesBackgrounds);
  }

  private getAdviserBackgrounds(): ChargingFeesAdviserBackgrounds {
    const { names, rut, commissionAmount, address, commune, cellphone, email, region } = this.adviserForm.value;
    return {
      name: names,
      rut: this.utils.rutClean(rut),
      commissionAmount,
      address,
      regionId: region,
      communeId: commune,
      cellphone,
      email
    };
  }

  private setFormValues() {
    const affiliateBackground = this.formValues ? this.formValues.affiliateBackgrounds : null;
    const adviserBackground = this.formValues ? this.formValues.adviserBackgrounds : null;

    if (adviserBackground !== null) {
      adviserBackground.commune = this.formValues.adviserBackgrounds.communeId;
      adviserBackground.region = this.formValues.adviserBackgrounds.regionId;
    }

    this.setAffiliateBackgroundControls(affiliateBackground);
    this.setAdviserBackgroundControls(adviserBackground);
  }

  private setAffiliateBackgroundControls(affiliate) {
    const callback = (formGroup: UntypedFormGroup) => this.affiliateForm = formGroup;
    this.affiliatePersonControls =
      this.setPersonBackgroundsParams(affiliate, false, callback, CHARGING_FEES_AFFILIATE_CONTROLS);
  }

  private setAdviserBackgroundControls(adviser) {
    const callback = (formGroup: UntypedFormGroup) => this.adviserForm = formGroup;
    this.adviserPersonControls =
      this.setPersonBackgroundsParams(adviser, false, callback, CHARGING_FEES_ADVISER_CONTROLS);
  }

  private setPersonBackgroundsParams(
    values: any,
    defaultRelationship: boolean,
    callback: Function,
    defaultControls: any,
  ): Array<PersonBackgroundsField> {
    const controls = this.formUtils.getDefaultControlsValues(values, defaultControls, defaultRelationship);
    const group = {};
    controls.forEach((control) => {
      group[control.key] = new UntypedFormControl(control.value, control.validators);
    });
    callback(new UntypedFormGroup(group));
    return controls;
  }

  private loadData(): void {
    this.loadingService.showLoading();
    this.parametersService.getRelationships()
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => this.relationships = response,
        (error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) })
      );
  }
}
