import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  COMPENSATION_BOX_CODE,
  COOPEUCH_CODE,
  RUT_ACCOUNT_ID,
  STATE_BANK_CODE,
  VISTA_ACCOUNT_ID
} from '@constants/forms.constant';
import { NUMBER_PATTERN } from '@constants/regex.constant';
import { ButtonsCaiForm, ErrorsCaiForm, LabelsCaiForm, PaymentMethodStep } from '@interfaces/cai.interface';
import { PaymentMethods } from '@interfaces/general.interface';
import { CAI_FORM as caiForm } from '@pages-content/cai.constant';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-payment-method-step',
  templateUrl: './payment-method-step.component.html',
  styleUrls: ['./payment-method-step.component.scss'],
})
export class PaymentMethodStepComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public paymentMethods: PaymentMethods;
  @Input() public rut: string;
  @Output() public next = new EventEmitter();
  @Output() public previous = new EventEmitter();

  public content: PaymentMethodStep = caiForm.paymentMethodStep;
  public labels: LabelsCaiForm = caiForm.labels;
  public errors: ErrorsCaiForm = caiForm.errors;
  public buttons: ButtonsCaiForm = caiForm.buttons;

  public accountNumberMaxLength: number = ACCOUNT_NUMBER_MAX_LENGTH;
  public accountNumberPattern: RegExp = NUMBER_PATTERN;

  public get bankCode(): AbstractControl { return this.form.get('bankCode'); }
  public get accountType(): AbstractControl { return this.form.get('accountType'); }
  public get accountNumber(): AbstractControl { return this.form.get('accountNumber'); }
  public get repeatAccountNumber(): AbstractControl { return this.form.get('repeatAccountNumber'); }
  public get authorize(): AbstractControl { return this.form.get('authorize'); }
  public get personalConsent(): AbstractControl { return this.form.get('personalConsent'); }

  constructor(
    public font: FontService,
    private formUtils: FormUtils,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.bankCodesSubscription();
    this.rutAccountTypeSubscription();
  }

  public isRutAccountNumber(): boolean {
    const accountNumber = this.accountNumber.value;
    const bankCode = this.bankCode.value;
    const accountType = this.accountType.value;
    if (!accountNumber || !bankCode || !accountType) { return false; }
    return bankCode === STATE_BANK_CODE && accountType !== RUT_ACCOUNT_ID && accountNumber.startsWith(this.rut);
  }

  private bankCodesSubscription() {
    this.bankCode.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((code: string) => {
        this.resetFields(['accountType', 'accountNumber', 'repeatAccountNumber']);
        if (code === COMPENSATION_BOX_CODE || code === COOPEUCH_CODE) {
          this.accountType.setValue(VISTA_ACCOUNT_ID);
          this.accountType.disable();
        } else {
          this.accountType.setValue(null);
          this.accountType.enable();
        }
      });
  }

   private rutAccountTypeSubscription() {
    this.accountType.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe((accountType: number) => {
        if (accountType === RUT_ACCOUNT_ID && this.rut) {
          this.accountNumber.setValue(this.rutAccountNumber);
          this.accountNumber.disable();
        } else {
          this.accountNumber.setValue(null);
          this.repeatAccountNumber.setValue(null);
          this.accountNumber.enable();
        }
      });
  }

  private resetFields(fieldNames: Array<string>) {
    fieldNames.forEach((field) => {
      const control = this.form.get(field);
      control.reset();
    });
  }

  private get rutAccountNumber(): string {
    if (!this.rut) { return; }
    const rutUnformatted = this.utils.rutClean(this.rut);
    return rutUnformatted.substring(0, rutUnformatted.length - 1);
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }

  public mustMatchError(formControlName: string): boolean {
    return this.form.controls[formControlName].errors && this.form.controls[formControlName].errors.mustMatch;
  }

  public showAccountTypeOption(id: number): boolean {
    return id !== RUT_ACCOUNT_ID || this.bankCode.value === STATE_BANK_CODE;
  }

  public goPreviousStep() {
    this.previous.emit();
  }

  public goNextStep() {
    this.next.emit();
  }

}
