import { Component, Input } from '@angular/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-fund-quota-value',
  templateUrl: './fund-quota-value.component.html',
  styleUrls: ['./fund-quota-value.component.scss'],
})
export class FundQuotaValueComponent {
  @Input() public fund: string;
  @Input() public value: number;
  @Input() public delta: number;
  @Input() public fundLabel: string;
  @Input() public simplified = false;
  @Input() public loading = false;

  public get fundValue() { return this.value ? this.value : 0; }
  public get deltaIcon() { return this.delta > 0 ? 'i-chart-up' : 'i-chart-down'; }
  public get deltaClass() { return this.delta > 0 ? 'delta-positive' : 'delta-negative'; }

  constructor(
    public font: FontService,
  ) { }


}
