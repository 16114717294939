<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="confirm()" *ngIf="loadedPage">
  <div class="form-section">
    <div class="form-title">
      <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.beneficiaries.title | translate }}</h3>
      <app-info-text-popup class="tooltip-icon" [definitions]="pageContent?.beneficiaries.definitions" iconName="i-tooltip-blue">
        <div class="i-tooltip-blue"></div>
      </app-info-text-popup>
    </div>
    <app-form-array-list
      [pageContent]="pageContent?.beneficiaries.buttons"
      [iconClass]="'pension'"
      [itemProperties]="deceasedAffiliateProperties"
      [modalType]="modalType"
      [modalData]="modalData"
      [items]="beneficiaries"
      (edited)="setBeneficiaries($event)">
    </app-form-array-list>
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.beneficiaries.description | translate"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
  </div>
  <div class="form-section">
    <div class="form-title">
      <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.preliminary.title | translate }}</h3>
      <app-info-text-popup class="tooltip-icon" [definitions]="pageContent?.preliminary.definitions" iconName="i-tooltip-blue">
        <div class="i-tooltip-blue"></div>
      </app-info-text-popup>
    </div>
    <mat-radio-group class="option-container" formControlName="preliminary">
      <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.preliminary.label| translate | titlecase }}</p>
      <div class="options">
        <mat-radio-button value="1" class="option" [class.selected]="isChecked('preliminary', '1')" [ngClass]="font?.size">
          {{ pageContent?.preliminary.options.yes | translate }}
        </mat-radio-button>
        <mat-radio-button value="0" class="option" [class.selected]="isChecked('preliminary', '0')" [ngClass]="font?.size">
          {{ pageContent?.preliminary.options.no | translate }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="form-section">
    <div class="form-title">
      <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.accrual.title | translate }}</h3>
      <app-info-text-popup class="tooltip-icon" [definitions]="pageContent?.accrual.definitions" iconName="i-tooltip-blue">
        <div class="i-tooltip-blue"></div>
      </app-info-text-popup>
    </div>
    <div class="check-group">
      <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.accrual.label | translate | titlecase }}</p>
      <mat-radio-group class="options custom-border" formControlName="accrual">
        <div class="option" [class.selected]="isChecked('accrual', accrualOption.code)"
          *ngFor="let accrualOption of accrualOptions">
          <div class="left-radio-button">
            <mat-radio-button [value]="accrualOption.code"></mat-radio-button>
          </div>
          <div class="right-radio-button text-label" [ngClass]="font?.size">{{ accrualOption.description | translate }}</div>
          <app-info-text-popup class="tooltip-icon" [definitions]="accrualOption.definitions" iconName="i-tooltip-blue">
            <div class="i-tooltip-blue"></div>
          </app-info-text-popup>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-section">
    <app-payment-method-form
      [pageContent]="pageContent?.paymentMethods"
      [paymentData]="paymentData"
      [paymentMethods]="paymentMethods"
      [userRut]="userRut"
      [formValues]="paymentMethodValues"
      (confirmForm)="validateForm($event, 'paymentMethod')">
    </app-payment-method-form>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
      <div class="icon-result next"></div>
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      <div class="icon-result back"></div>
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
