import { Mockup } from '@interfaces/mockup.interface';
import { SuperintendenceBanner } from '@interfaces/superintendence-banner.interface';

export const GET_SUPERINTENDECE_BANNER_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        link_superintendencia: {
          url: 'https://es.research.net/r/spensiones',
          identificador: 'Mapa del sitio'
        },
        traducciones: [
          {
            texto: 'Entrega tu opinión a la SUPERINTENDENCIA DE PENSIONES sobre la calidad de servicio de tu AFP, ',
            texto_boton: 'comienza aquí.',
            languages_code: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const SUPERINTENDECE_BANNER_MOCKUP: SuperintendenceBanner = {
  text: 'Entrega tu opinión a la SUPERINTENDENCIA DE PENSIONES sobre la calidad de servicio de tu AFP, ',
  button: 'comienza aquí.',
  url: 'https://es.research.net/r/spensiones'
};

export const GET_SUPERINTENDECE_BANNER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        link_superintendencia: {
          url: 'url',
          identificador: 'Mapa del sitio'
        },
        traducciones: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const SUPERINTENDECE_BANNER_NULL_MOCKUP: SuperintendenceBanner = {
  text: null,
  button: null,
  url: 'url'
};
