import { EconomicIndicatorsInformation, FinancialInformation, InvestmentPortfolioInfo } from '@interfaces/financial-information.interface';
import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_FINANCIAL_INFORMATION_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        header: {
          id: 1,
          pagina: 'Información financiera',
          imagen: 19,
          imagen_mobile: 19,
          icono: 'analysis'
        },
        traducciones: [
          {
            languages_code: 'es',
            id: 1,
            titulo: 'Rentabilidad Agosto 2020',
            cuerpo: '<p>En agosto todos los fondos administrados por PlanVital obtuvieron rentabilidades positivas.<br />Estos resultados se explican principalmente por los retornos positivos de las Inversiones en Renta Variable Extranjera para los Fondos A, B y C, debido a un mejor &aacute;nimo en los mercados burs&aacute;tiles tras la reapertura en algunas de las grandes econom&iacute;as globales. Tambi&eacute;n hubo retornos positivos de las Inversiones en Renta Fija Nacional para los Fondos D y E.<br />&nbsp;<br />En el siguiente cuadro te mostramos como aportaron las inversiones de los distintos tipos de instrumentos, en la rentabilidad de los fondos en agosto.&nbsp;</p>',
            titulo_tabla: 'Contribución a la rentabilidad nominal del mes de agosto',
            tabla: {
              'Tipo de Instrumento': {
                A: 'Fondo A',
                B: 'Fondo B',
                C: 'Fondo C',
                D: 'Fondo D',
                E: 'Fondo E'
              },
              'Renta Variable Extranjera': {
                A: '5.0%',
                B: '3.8%',
                C: '2.3%',
                D: '1.0%',
                E: '0.3%'
              },
              'Renta Variable Nacional': {
                A: '-0.2%',
                B: '-0.2%',
                C: '-0.1%',
                D: '-0.1%',
                E: '0.0%'
              },
              'Renta Fija Exranjera': {
                A: '0.9%',
                B: '1.0%',
                C: '0.0%',
                D: '0.7%',
                E: '0.3%'
              },
              'Renta Fija Nacional': {
                A: '0.0%',
                B: '0.3%',
                C: '1.1%',
                D: '1.9%',
                E: '2.0%'
              },
              'Activos Alternativos': {
                A: '0.2%',
                B: '0.1%',
                C: '0.1%',
                D: '0.1%',
                E: '0.0%'
              },
              'Derivados y otros': {
                A: '-0.6%',
                B: '-0.7%',
                C: '-0.5%',
                D: '-0.5%',
                E: '-0.2%'
              },
              Total: {
                A: '5.31%',
                B: '4.29%',
                C: '3.84%',
                D: '3.11%',
                E: '2.33%'
              }
            },
            fecha_actualizacion: 'Actualizado al 17 de marzo del 2021'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const FINANCIAL_INFORMATION_MOCKUP: FinancialInformation = {
  header: {
    id: 1,
    image: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: ''
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/16d3caad-dd9a-4a4b-93da-f17fc546c41a.png`,
      alt: 'alt',
      title: ''
    },
    icon: 'handshake',
    firstLine: '<p>Estado de tu&nbsp;<strong>traspaso a AFP PlanVital</strong></p>',
  },
  profitabilityVariation: {
    title: 'Rentabilidad Agosto 2020',
    description: '<p>En agosto todos los fondos administrados por PlanVital obtuvieron rentabilidades positivas.<br />Estos resultados se explican principalmente por los retornos positivos de las Inversiones en Renta Variable Extranjera para los Fondos A, B y C, debido a un mejor &aacute;nimo en los mercados burs&aacute;tiles tras la reapertura en algunas de las grandes econom&iacute;as globales. Tambi&eacute;n hubo retornos positivos de las Inversiones en Renta Fija Nacional para los Fondos D y E.<br />&nbsp;<br />En el siguiente cuadro te mostramos como aportaron las inversiones de los distintos tipos de instrumentos, en la rentabilidad de los fondos en agosto.&nbsp;</p>',
    titleTable: 'Contribución a la rentabilidad nominal del mes de agosto',
    table: {
      'Tipo de Instrumento': {
        A: 'Fondo A',
        B: 'Fondo B',
        C: 'Fondo C',
        D: 'Fondo D',
        E: 'Fondo E'
      },
      'Renta Variable Extranjera': {
        A: '5.0%',
        B: '3.8%',
        C: '2.3%',
        D: '1.0%',
        E: '0.3%'
      },
      'Renta Variable Nacional': {
        A: '-0.2%',
        B: '-0.2%',
        C: '-0.1%',
        D: '-0.1%',
        E: '0.0%'
      },
      'Renta Fija Exranjera': {
        A: '0.9%',
        B: '1.0%',
        C: '0.0%',
        D: '0.7%',
        E: '0.3%'
      },
      'Renta Fija Nacional': {
        A: '0.0%',
        B: '0.3%',
        C: '1.1%',
        D: '1.9%',
        E: '2.0%'
      },
      'Activos Alternativos': {
        A: '0.2%',
        B: '0.1%',
        C: '0.1%',
        D: '0.1%',
        E: '0.0%'
      },
      'Derivados y otros': {
        A: '-0.6%',
        B: '-0.7%',
        C: '-0.5%',
        D: '-0.5%',
        E: '-0.2%'
      },
      Total: {
        A: '5.31%',
        B: '4.29%',
        C: '3.84%',
        D: '3.11%',
        E: '2.33%'
      }
    },
    informationUpdateDate: 'Actualizado al 17 de marzo del 2021'
  }
};

export const GET_ECONOMIC_INDICATORS_INFORMATION_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: {
          data: {
            full_url: `${environment.cmsApiUrl}97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
            url: '/uploads/sitio-publico/originals/97466f76-6ac0-4587-82cb-0d0a4441285c.png',
            asset_url: '/sitio-publico/assets/avj74bkhv9k4oswc',
            embed: null
          }
        }
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ECONOMIC_INDICATORS_INFORMATION_MOCKUP: EconomicIndicatorsInformation = {
  title: 'Indicadores Económicos',
  downloadButtonText: 'Descargar Reporte',
  downloadFileName: 'indicadores_economicos.pdf',
  downloadUrl: `${environment.cmsApiUrl}7f71070a-843b-4d9c-9879-31c4a535a117.pdf`,
};

export const GET_ECONOMIC_INDICATORS_INFORMATION_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: null,
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ECONOMIC_INDICATORS_INFORMATION_NULL_MOCKUP: EconomicIndicatorsInformation = {
  title: 'Indicadores Económicos',
  downloadButtonText: 'Descargar Reporte',
  downloadFileName: 'indicadores_economicos.pdf',
  downloadUrl: `${environment.cmsApiUrl}7f71070a-843b-4d9c-9879-31c4a535a117.pdf`,
};

export const GET_INVESTMENT_PORTFOLIO_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            fecha_actualizacion: 'Actualizado al 16 de marzo del 2021',
            languages_code: 'es',
            titulo: 'Cartera de Fondos de Inversiones',
            contenido: 'Contenido',
            titulo_grafico_zona: 'Zona',
            titulo_grafico_cartera: 'Cartera'
          }],
          graficos: [{
            cartera_inversiones_graficos_id: {
              alt_cartera: 'Fondo A Distribución',
              alt_zona: 'Fondo A Zona Geográfica',
              grafico_cartera: {
                filename_disk: 'fondoA_distribucion.jpg'
              },
              grafico_zona: {
                filename_disk: 'fondoA_geografica.jpg'
              },
              titulo_fondo: 'Fondo A'
            }
          }]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const INVESTMENT_PORTFOLIO_MOCKUP: InvestmentPortfolioInfo = {
  informationUpdateDate: 'Actualizado al 16 de marzo del 2021',
  title: 'Cartera de Fondos de Inversiones',
  description: 'Contenido',
  chartZoneDescription: 'Zona',
  chartDistributionDescription: 'Cartera',
  charts: [{
    alt_distribution: 'Fondo A Distribución',
    alt_zone: 'Fondo A Zona Geográfica',
    distribution_chart: `${environment.cmsApiUrl}assets/fondoA_distribucion.jpg`,
    zone_chart: `${environment.cmsApiUrl}assets/fondoA_geografica.jpg`,
    fund_title: 'Fondo A'
  }]
};
