import { Mockup } from '@interfaces/mockup.interface';


export const GET_GENERAL_PARAMETERS_MOCK: Mockup = {
    success: [{
        response: {
            data: [
                {
                    id: 1,
                    name: 'sueldo_minimo',
                    value: 460000
                },
                {
                    id: 2,
                    name: 'porcentaje_descuento_rim',
                    value: 10
                },
                {
                    id: 3,
                    name: 'renta_imponible',
                    value: 400000
                }
            ],
            public: true
        }
    }],
    failures: []
};
