import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_PAYMENT_INSTITUTIONS_MOCK: Mockup = {
  success: [{
    response: {
      data: {
        instituciones: {
          contenido: [
            'CONSORCIO S.A ADMINISTRADORA GENERAL DE FONDOS',
            'BANCO ESTADO S.A. ADMINISTRADORA GENERAL DE FONDOS',
            'BICE VIDA COMPAÑIA DE SEGUROS S.A',
            'ADMINISTRADORA GENERAL DE FONDOS SECURITY S.A',
            'BTG PACTUAL ADMINISTRADORA GENERAL DE FONDOS S.A.',
            'BICE INVERSIONES ADMINISTRADORA GENERAL DE FONDOS',
            'ESTADO',
            'CORPBANCA',
            'BANCO ITAU',
            'BTG PACTUAL CHILE S A COMPANIA DE SEGUROS DE VIDA',
            'CONSORCIO NACIONAL',
            'BICE',
            'EUROAMERICA ADMINISTRADORA',
            'Seguros Vida Security Prevision S.A.',
            'COMPAÑIA DE SEGUROS CONFUTURO S.A.',
            'CORREDORES DE BOLSA SURA S.A.',
            'CRUZ DEL SUR S.A. CORREDORA DE BOLSA',
            'BBVA',
            'Metlife Chile Seguros de Vida S.A.',
            'CHILENA CONSOLIDADA SEG.GEN.',
            'CHILENA CONSOLIDADA SEG.VIDA',
            'CIA. SEG.VIDA EUROAMERICA',
            'PENTA VIDA',
            'BCI SEGUROS DE VIDA S.A.',
            'LARRAIN VIAL S.A C, BOLSA',
            'CUPRUM INV.NAC.',
            'BANCHILE ADM. GENERALES DE FONDOS',
            'OHIO',
            'ZURICH ADMINISTRADORA GENERAL DE FONDOS S.A',
            'BANCO BICE',
            'AFP CUPRUM',
            'AFP HABITAT',
            'AFP PROVIDA',
            'AFP CAPITAL S.A.',
            'AFP MODELO',
            'AFP UNO',
            'PRINCIPAL SEG. VIDA CHILE S.A.',
            'AFM SECURITY S.A.',
            'AGF EUROAMERICA',
            'PRINCIPAL ADMINISTRADORA GENERAL DE FONDOS S.A',
            'CORPVIDA',
            'IM TRUST SA ADMINISTRADORA GENERAL DE FONDOS',
            'SANTANDER ASSET MANAGEMENT SA ADM GRL DE FONDOS',
            'Itau Chile Administradora General de Fondos S.A.',
            'BCI Asset Management Adm. General de Fondos S.A.',
            'CCAF LOS ANDES NUEVO 07/10/2013',
            'Banco Estado (Nuevo 07/10/2013)',
            'SCOTIA ADMINISTRADORA GENERAL DE FONDOS CHILE S.A.',
            'CCAF LOS ANDES NUEVO 07/10/2013',
            'Banco Estado (Nuevo 07/10/2013)',
            'SCOTIA ADMINISTRADORA GENERAL DE FONDOS CHILE S.A.'
          ]
        }
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};
