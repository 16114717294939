import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  PENSION_BACKGROUNDS_DEFAULT_CONTROLS, PENSION_MODALITY, PENSION_TYPES,
  PensionBackgroundsDefaultType
} from '@constants/pension-backgrounds.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-pension-backgrounds',
  templateUrl: './pension-backgrounds.component.html',
  styleUrls: ['./pension-backgrounds.component.scss'],
})
export class PensionBackgroundsComponent implements OnInit {
  @Input() public pageContent;
  @Input() public controlsName: Array<PensionBackgroundsDefaultType> = [];
  @Input() public data;
  @Output() public formChanged: EventEmitter<any> = new EventEmitter();
  public form: UntypedFormGroup;
  public pensionTypesOptions = Object.entries(PENSION_TYPES);
  public pensionModalityOptions = Object.entries(PENSION_MODALITY);
  private controls = PENSION_BACKGROUNDS_DEFAULT_CONTROLS;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.createForm();
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  private createForm(): void {
    const group = {};
    this.controlsName.forEach((controlName) => group[controlName] = this.controls[controlName]);

    this.form = this.formBuilder.group(group);

    if (this.data) {
      this.form.patchValue({
        pensionType: this.data.pensionType,
        pensionModality: this.data.pensionModality,
      });
      this.form.markAsTouched();
      this.formChanged.emit(this.form); // IMPORTANT: this emit update the value in the pensionform in the backgrounds
    }

    this.form.valueChanges.subscribe(() => {
      this.formChanged.emit(this.form);
    });
  }

  private getControl(key: string): AbstractControl { return this.form.get(key); }
}
