import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AssessmentPageRoutingModule } from './assessment-routing.module';

@NgModule({
  imports: [
    FormsModule,
    AssessmentPageRoutingModule,
  ],
})
export class AssessmentPageModule {}
