<div class="box">
  <p *ngIf="!loading" class="title tertiary" [ngClass]="font?.size">{{ boxData?.title }}</p>
  <app-skeleton *ngIf="loading" [linesNumber]="3" [paragraphNumber]="1"></app-skeleton>
  <form [formGroup]="requestForm">
    <ion-item class="question-box">
      <ion-label *ngIf="!loading" class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.rut }}</ion-label>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line xsmall" animated></ion-skeleton-text>
      <ion-input formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut"></ion-input>
    </ion-item>
    <ion-item class="question-box">
      <ion-label *ngIf="!loading" class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.date }}</ion-label>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line xsmall" animated></ion-skeleton-text>
      <div class="icon-input">
        <ion-input formControlName="dateInput" placeholder="2020-03-20" readonly></ion-input>
        <input class="data-input" (dateChange)="dateSelected()" matInput [matDatepicker]="myDatepicker" formControlName="date">
        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #myDatepicker></mat-datepicker>
      </div>
    </ion-item>
    <div class="disclaimer" [ngClass]="font?.size" [innerHtml]="recaptchaContent | translate"></div>
    <div class="centered-container">
      <ion-button class="button primary"[ngClass]="font?.size" (click)="getTransferState()" [disabled]="requestForm.invalid || loading">
        <span [class.hidden]="loading">{{ boxData?.button }}</span>
      </ion-button>
    </div>
  </form>
</div>
