import { CONTACT_CENTER } from '@constants/plan-vital.constant';

const contactCenterKey = 'CONTACT_CENTER_V2';

export const CALL_CENTER_V2 = {
    title: `${contactCenterKey}.TITLE`,
    callButton: `<p><strong>${CONTACT_CENTER.MASK}</strong></p>`,
    buttons: {
      send: `${contactCenterKey}.BUTTONS.SEND`,
      close: `${contactCenterKey}.BUTTONS.CLOSE`,
      continue: `${contactCenterKey}.BUTTONS.CONTINUE`,
    },
    click2Call: {
      title: `${contactCenterKey}.CLICK_2_CALL.TITLE`,
      description: `${contactCenterKey}.CLICK_2_CALL.DESCRIPTION`,
      schedule: `${contactCenterKey}.CLICK_2_CALL.SCHEDULE`,
      form: {
        name: `${contactCenterKey}.CLICK_2_CALL.FORM.NAME`,
        rut: `${contactCenterKey}.CLICK_2_CALL.FORM.RUT`,
        countryCode: `${contactCenterKey}.CLICK_2_CALL.FORM.COUNTRY_CODE`,
        phone: `${contactCenterKey}.CLICK_2_CALL.FORM.PHONE`,
        reason: `${contactCenterKey}.CLICK_2_CALL.FORM.REASON`,
        videoCallMessage: `${contactCenterKey}.CLICK_2_CALL.FORM.VIDEOCALL_MESSAGE`,
        phoneCallInternationalMessage: `${contactCenterKey}.CLICK_2_CALL.FORM.PHONECALL_INT_MESSAGE`,
      },
      errors: {
        required: `${contactCenterKey}.CLICK_2_CALL.ERRORS.REQUIRED`,
        invalid: `${contactCenterKey}.CLICK_2_CALL.ERRORS.INVALID`,
        failService: `${contactCenterKey}.CLICK_2_CALL.ERRORS.FAIL_SERVICE`
      },
      success: {
        title: `${contactCenterKey}.CLICK_2_CALL.SUCCESS.TITLE`,
        message: `${contactCenterKey}.CLICK_2_CALL.SUCCESS.MESSAGE`,
      }
    }
};

export const ERROR_INPUT_TEXT = {
  errors: {
    required: `${contactCenterKey}.CLICK_2_CALL.ERRORS.REQUIRED`,
    patternLetters: `${contactCenterKey}.CLICK_2_CALL.ERRORS.PATTERN_LETTERS`,
    invalidRut: `${contactCenterKey}.CLICK_2_CALL.ERRORS.INVALID_RUT`,
    invalidPhone: `${contactCenterKey}.CLICK_2_CALL.ERRORS.INVALID_PHONE`,
    patternNumbers: `${contactCenterKey}.CLICK_2_CALL.ERRORS.PATTERN_NUMBERS`
  }
};

