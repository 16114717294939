import { Observable, of, Subject } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ASSETS_PATH, DICTIONARY_KEY, LANGUAGE_KEY, STATIC_PATH
} from '@constants/translate.constant';
import { environment } from '@environment';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  private useLocal = true;
  private errorState = false;
  private waiter = new Subject<object>();
  private requestedLang: string;
  private staticTranslationsPath = STATIC_PATH;
  private assetsTranslationsPath = ASSETS_PATH;
  private languageJsonFiles = {};

  constructor(private readonly httpClient: HttpClient) { }

  public getTranslation(lang: string): Observable<object> {
    if (this.errorState || this.useLocal) { return this.translationFile(lang); }
    if (this.requestedLang === lang) { return this.waiter.asObservable(); }

    const { dictionary, language } = this.getDictionaryAndLanguage();
    // tslint:disable-next-line:deprecation
    if (dictionary && language === lang && this.isProduction()) { return of(this.parseDictionary(dictionary)); }

    this.setTranslation(lang);
    return this.waiter.asObservable();
  }

  private translationFile(lang: string): Observable<object> {
    if (!this.languageJsonFiles[lang]) {
      this.languageJsonFiles[lang] = this.httpClient.get(`${this.assetsTranslationsPath}${lang}.json`).pipe(shareReplay(1));
    }
    return this.languageJsonFiles[lang];
  }

  private getDictionaryAndLanguage(): any {
    const dictionary = sessionStorage.getItem(DICTIONARY_KEY);
    const language = sessionStorage.getItem(LANGUAGE_KEY);
    return { dictionary, language };
  }

  private isProduction(): boolean {
    return environment.name === 'production';
  }

  // TO-DO: secure implementation
  private parseDictionary(dictionary: string): any {
    return JSON.parse(dictionary);
  }

  private setTranslation(lang: string): void {
    const path = `${this.staticTranslationsPath}${lang}.json`;
    this.requestedLang = lang;

    this.httpClient.get(path).pipe(
      catchError(() => {
        this.errorState = true;
        return this.translationFile(lang);
      })
    ).subscribe(result => {
      this.setDictionaryAndLanguage(lang, result);
      this.waiter.next(result);
    });
  }

  private setDictionaryAndLanguage(lang: string, result: any): void {
    sessionStorage.setItem(DICTIONARY_KEY, JSON.stringify(result));
    sessionStorage.setItem(LANGUAGE_KEY, lang);
  }
}
