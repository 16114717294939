import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NAMES_MAX_LENGTH, RUT_MAX_LENGTH } from '@constants/forms.constant';
import { DEFAULT_CONTROLS } from '@constants/funeral-services-background.constant';
import {
  FUNERAL_SERVICES_FIELD, FUNERARY_ID
} from '@constants/pages-content/mortuary-quota-request.constants';
import { DefaultResponseItem } from '@interfaces/general.interface';
import {
  FuneralServicesBackgrounds, FuneralServicesParams
} from '@interfaces/mortuary-quota-form.interface';
import { FieldsValidators, PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { CmsService } from '@services/cms/cms.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';
import { FormUtils } from '@utils/form.utils';

@Component({
  selector: 'app-funeral-services-background',
  templateUrl: './funeral-services-background.component.html',
  styleUrls: ['./funeral-services-background.component.scss'],
})
export class FuneralServicesBackgroundComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public deceasedPersonRut;
  @Input() public formValues;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();
  @Output() public back = new EventEmitter();
  public validatedComponent: boolean;
  public namesMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public maxDate = new Date();
  public funeralServicesBackground = FUNERAL_SERVICES_FIELD;
  public resumeAffiliateControls: Array<PersonBackgroundsField>;
  public quotaPaymentControls: Array<PersonBackgroundsField>;
  public relationships: DefaultResponseItem[];
  public trimedRelationships: DefaultResponseItem[];
  public relationshipsToTrim = [FUNERARY_ID];
  public fieldsValidators = {
    rut: {
      value: ''
    }
  } as FieldsValidators;

  private funeralServiceBackgroundForm: UntypedFormGroup;
  private quotaPaymentBackgroundForm: UntypedFormGroup;

  public get validFuneralServiceBackgroundForm(): boolean {
    return this.funeralServiceBackgroundForm &&
      this.funeralServiceBackgroundForm.valid &&
      this.funeralServiceBackgroundForm.get('rut').value !== this.formattedDeceasedPersonRut;
  }
  public get validQuotaPaymentBackgroundForm(): boolean {
    return this.quotaPaymentBackgroundForm &&
      this.quotaPaymentBackgroundForm.valid &&
      this.quotaPaymentBackgroundForm.get('rut').value !== this.formattedDeceasedPersonRut;
  }
  public get validForm(): boolean {
    return this.validFuneralServiceBackgroundForm && this.validQuotaPaymentBackgroundForm;
  }
  public get formattedDeceasedPersonRut(): string {
    return this.utils.rutFormat(this.deceasedPersonRut);
  }

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private modalService: ModalService,
    private errorUtils: ErrorUtils,
    private utils: Utils,
    private languageProvider: LanguageProvider,
    private loadingService: LoadingService,
    private formUtils: FormUtils,
  ) { }

  public ngOnInit(): void {
    if (this.deceasedPersonRut) {
      this.setFieldsValidators(this.utils.rutFormat(this.deceasedPersonRut));
    }
    this.loadRelationships();
    this.languageSubscription();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.deceasedPersonRut) {
      this.setFieldsValidators(this.utils.rutFormat(changes.deceasedPersonRut.currentValue));
    }
  }

  public previousStep(): void {
    this.back.emit();
  }

  public sendForm(showModal = false): void {
    if (!this.validForm) { return; }

    const serviceContractor = this.parseServiceContractorParams(this.funeralServiceBackgroundForm.value);

    const applicantBackground = this.parseApplicantBackgroundParams(this.quotaPaymentBackgroundForm.value);

    const funeralServicesBackgrounds = {
      serviceContractor,
      applicantBackground
    } as FuneralServicesParams;
    this.savedModal.emit(showModal);
    this.formData.emit(funeralServicesBackgrounds);
  }

  public setFuneralServiceBackgroundForm(form: UntypedFormGroup) {
    this.funeralServiceBackgroundForm.patchValue({
      ...form.value
    });
  }

  public setQuotaPaymentBackgroundForm(form: UntypedFormGroup) {
    this.quotaPaymentBackgroundForm.patchValue({
      ...form.value
    });
  }

  private setFormValues(): void {
    const serviceContractor = this.formValues ? this.formValues.serviceContractor as FuneralServicesBackgrounds : null;
    const applicantBackground = this.formValues ? this.formValues.applicantBackground as FuneralServicesBackgrounds : null;
    this.setResumeAffiliateControls(serviceContractor);
    this.setApplicantBackgroundControls(applicantBackground);
  }

  private setResumeAffiliateControls(serviceContractor: FuneralServicesBackgrounds): void {
    const callback = (formGroup: UntypedFormGroup) => this.funeralServiceBackgroundForm = formGroup;
    this.resumeAffiliateControls = this.setPersonBackgroundsParams(serviceContractor, true, callback);
  }

  private setApplicantBackgroundControls(applicantBackground: FuneralServicesBackgrounds): void {
    const callback = (formGroup: UntypedFormGroup) => this.quotaPaymentBackgroundForm = formGroup;
    this.quotaPaymentControls = this.setPersonBackgroundsParams(applicantBackground, false, callback);
  }

  private setPersonBackgroundsParams(
    values: FuneralServicesBackgrounds,
    defaultRelationship: boolean,
    callback: Function
  ): Array<PersonBackgroundsField> {
    const controls = this.formUtils.getDefaultControlsValues(values, DEFAULT_CONTROLS, defaultRelationship, this.relationships);
    const group = {};
    controls.forEach((control) => {
      group[control.key] = new UntypedFormControl(control.value, control.validators);
    });
    callback(new UntypedFormGroup(group));
    return controls;
  }

  private loadRelationships(): void {
    this.loadingService.showLoading();
    this.cmsService.loadMortuaryQuotaRelationships().toPromise()
      .then(
        (response) => {
          this.relationships = response;
          this.trimRelationships();
          this.setFormValues();
          this.validatedComponent = true;
        },
        (error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) })
      ).finally(() => this.loadingService.hideLoading());
  }

  private trimRelationships() {
    this.trimedRelationships = this.relationships.filter(relationship => !this.relationshipsToTrim.includes(relationship.id));
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => { this.loadRelationships();
    });
  }

  private parseServiceContractorParams(formValue: any): FuneralServicesBackgrounds {
    const {
      names: name, lastName, secondSurname, rut, relationship: relationshipId, gender, cellphone, email
    } = formValue;

    return {
      name, lastName, secondSurname,
      rut: this.utils.rutClean(rut),
      relationshipId, gender,
      cellphone, email,
    } as FuneralServicesBackgrounds;
  }

  private parseApplicantBackgroundParams(formValue: any): FuneralServicesBackgrounds {
    const {
      names: name, lastName, secondSurname, rut, relationship: relationshipId, gender, cellphone, email
    } = formValue;

    return {
      name, lastName, secondSurname,
      rut: this.utils.rutClean(rut),
      relationshipId: this.relationships.find(relationship => relationship.id === relationshipId).description,
      gender,
      cellphone, email
    } as FuneralServicesBackgrounds;
  }

  private setFieldsValidators(rut: string): void {
    this.fieldsValidators = {
      rut: {
        value: rut,
      }
    } as FieldsValidators;
  }
}
