<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="icon-result" [ngClass]="iconName"></div>
  <p class="title" [ngClass]="font?.size" [innerHTML]="title | translate"></p>
  <p class="subtitle" style="font-weight: 600;" [ngClass]="font?.size" [innerHTML]="subTitle | translate"></p>
  <p class="subtitle" [ngClass]="font?.size" [innerHTML]="disclaimer | translate"></p>
  <div class="subtitle" *ngIf="selectedValue === selectedOptions.UNIQUE_BENEFICIARY" [ngClass]="font?.size">
    <p>1.- {{textInfo + ".APPLICANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>2.- {{textInfo + ".BENEFICIARY_CERTIFICATE" | translate}}</p>
    <p>3.- {{textInfo + ".CAUSANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>4.- {{textInfo + ".CERTIFICATE_OF_PENSION_HISTORY_CAUSANT" | translate}}</p>
  </div>

  <div class="subtitle" *ngIf="selectedValue === selectedOptions.MANDATARY_GROUP_BENEFICIARY" [ngClass]="font?.size">
    <p>1.- {{textInfo + ".APPLICANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>2.- {{textInfo + ".MANDATE_DOCUMENT" | translate}}</p>
    <p>3.- {{textInfo + ".CAUSANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>4.- {{textInfo + ".CERTIFICATE_OF_PENSION_HISTORY_CAUSANT" | translate}}</p>
  </div>

  <div class="subtitle" *ngIf="selectedValue === selectedOptions.TUTOR" [ngClass]="font?.size">
    <p>1.- {{textInfo + ".APPLICANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>2.- {{textInfo + ".TUTOR_RESOLUTION" | translate}}</p>
    <p>3.- {{textInfo + ".CAUSANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>4.- {{textInfo + ".CERTIFICATE_OF_PENSION_HISTORY_CAUSANT" | translate}}</p>
  </div>

  <div class="subtitle" *ngIf="selectedValue === selectedOptions.TUTOR_MENTAL_DISABILITY" [ngClass]="font?.size">
    <p>1.- {{textInfo + ".APPLICANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>2.- {{textInfo + ".TUTOR_RESOLUTION" | translate}}</p>
    <p>3.- {{textInfo + ".CAUSANT_PERSONAL_DOCUMENT" | translate}}</p>
    <p>4.- {{textInfo + ".CERTIFICATE_OF_PENSION_HISTORY_CAUSANT" | translate}}</p>
  </div>
  <div class="button-container">
    <ion-button class="button primary" [class.large]="largeButton" [ngClass]="font?.size"
      [innerHTML]="firstBtnText | translate" (click)="primaryAction()"></ion-button>
    <ion-button class="button secondary" [class.large]="largeButton" *ngIf="showSecondaryButton" [ngClass]="font?.size"
      [innerHTML]="secondBtnText | translate" (click)="secondaryAction()"></ion-button>
  </div>
</div>