import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  ModalDefinitionsComponent
} from '@components/modal-definitions/modal-definitions.component';
import { SCREEN_BREAKPOINTS } from '@constants/general.constant';
import { TOOLTIP_CONFIG } from '@constants/tooltip-config.constant';
import { InfoText } from '@interfaces/info-text.interface';
import { ModalInfoText } from '@interfaces/modal.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';

@Component({
  selector: 'app-info-text-popup',
  templateUrl: './info-text-popup.component.html',
  styleUrls: ['./info-text-popup.component.scss'],
})
export class InfoTextPopupComponent implements OnInit {
  @Input() public definitions: Array<InfoText>;
  @Input() public iconName?: string;
  public innerWidth: number;
  public tooltipText = '';
  public tooltipConfig = TOOLTIP_CONFIG;

  public get isDesktop(): boolean { return this.innerWidth > SCREEN_BREAKPOINTS.TABLET; }

  constructor(
    public font: FontService,
    private modalProvider: ModalService,
    private translateSvc: TranslateService
  ) { }

  public ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.tooltipText = this.definitions
      .map(info => this.translateSvc.instant(info.definition))
      .join(' ');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  public openModal(): void {
    const modalData: ModalInfoText = {
      iconName: this.iconName,
      definitions: this.definitions
    };
    this.modalProvider.openModalCustomWithClass(ModalDefinitionsComponent, modalData, 'modal-definition');
  }
}
