<div class="step-container">
  <div class="title secondary" [ngClass]="font?.size">{{ content?.title | translate }}</div>
  <div class="dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="content?.description | translate"></div>
  <form class="form-container" [formGroup]="form" autocomplete="off">
    <div class="fields-group">

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerRut | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="employerRut" placeholder="12.345.678-9" [maxLength]="rutMaxLength" [formatRut]="rut" rutInput required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('employerRut')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="!requiredError('employerRut') && rut.hasError('validRut')">{{ errors.invalidRut | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerName | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="employerName" [maxLength]="namesMaxLength" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('employerName')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('employerName')">{{ errors.patternLetters | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerRegion | translate }}</mat-label>
        <mat-select formControlName="employerRegion" required>
          <mat-option *ngFor="let region of regions" [value]="region.code">{{ region.description | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerCommune | translate }}</mat-label>
        <mat-select formControlName="employerCommune" required>
          <mat-option *ngFor="let commune of communes" [value]="commune.code">{{ commune.description | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerAddress | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="employerAddress" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('employerAddress')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('employerAddress')">{{ errors.patternAddress | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerEmail | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="email" formControlName="employerEmail" (blur)="validateEmailDomain()" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getEmailInputErrorMessage()">{{ getEmailInputErrorMessage() | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ labels.employerPhone | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax" formControlName="employerPhone" placeholder="987654321" [maxLength]="phoneNumberLength" [minLength]="phoneNumberLength" required digitsOnly>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('employerPhone')">{{ errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="phone.hasError('min') || phone.hasError('max')">{{ errors.invalidPhone | translate }}</mat-error>
      </mat-form-field>


      <mat-form-field *ngIf="hasReasonDate" class="calendar-input" [ngClass]="font?.size">
        <mat-label>{{ labels.startReasonDate | translate }}</mat-label>
        <input (dateChange)="dateSelected('startReasonDate')"
               matInput [matDatepicker]="startReasonDatepicker"
               formControlName="startReasonDateInput"
               [max]="maxReasonDate">
        <mat-datepicker-toggle [for]="startReasonDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startReasonDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="hasReasonDate" class="calendar-input" [ngClass]="font?.size">
        <mat-label>{{ labels.endReasonDate | translate }}</mat-label>
        <input (dateChange)="dateSelected('endReasonDate')"
               matInput [matDatepicker]="endReasonDatepicker"
               formControlName="endReasonDateInput"
               [min]="startReasonDateInput?.value"
               [max]="maxDate">
        <mat-datepicker-toggle [for]="endReasonDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endReasonDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="hasStartWorkDate" class="calendar-input" [ngClass]="font?.size">
        <mat-label>{{ labels.startWorkDate | translate }}</mat-label>
        <input (dateChange)="dateSelected('startWorkDate')"
               matInput [matDatepicker]="startWorkDatepicker"
               formControlName="startWorkDateInput"
               [max]="maxDate">
        <mat-datepicker-toggle [for]="startWorkDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startWorkDatepicker></mat-datepicker>
      </mat-form-field>

    </div>
    <ion-button class="button primary form-button" (click)="goNextStep()" [disabled]="form.invalid" >{{ buttons.next | translate }}</ion-button>
    <ion-button class="button secondary form-button" (click)="goPreviousStep()">{{ buttons.previous | translate }}</ion-button>
  </form>

</div>
