import { PensionPayment } from '@interfaces/pension-payment.interface';

const pageKey = 'PENSIONER.PENSION_PAYMENT';

export const PENSION_PAYMENT_CONTENT: PensionPayment = {
  header: {
    id: 1,
    image: {
      alt: 'Fecha Pago de Pensión',
      url: 'assets/images/banner-calendario-pago-pension.webp',
    },
    image_mobile: {
      alt: 'Fecha Pago de Pensión',
      url: 'assets/images/banner-calendario-pago-pension.webp',
    },
    icon: 'calendar-check',
    firstLine: `${pageKey}.FIRST_LINE`,
  },
};
