<div class="fund-help-popover">
  <div class="limits-container">
    <div class="limit">
      <h2 class="percentage" [ngClass]="font?.size">{{ fund?.maxLimit }}</h2>
      <p class="description" [ngClass]="font?.size">Límite Máximo Permitido</p>
    </div>
    <div class="limit">
      <h2 class="percentage" [ngClass]="font?.size">{{ fund?.minLimit }}</h2>
      <p class="description" [ngClass]="font?.size">Límite Mínimo Obligatorio</p>
    </div>
  </div>
</div>
