<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="sendForm()" *ngIf="validStep">
  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h3>
    <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.description | translate }}</p>
    <app-person-backgrounds
      [pageContent]="formContent"
      [controlsName]="resumeAffiliateControls"
      (formChanged)="validateForm($event)">
    </app-person-backgrounds>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
      <div class="icon-result next"></div>
    </ion-button>
  </div>
</form>
