import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_ESSENTIAL_FACTS_LINKS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              hechos_esenciales: [
                {
                  año: '2020',
                  titulo: 'Periodo 2020',
                  items: [
                    {
                      fecha: '2020/11/12',
                      titulo: '2020/11/12 | Cambios en la administración.',
                      url: 'hechos-esenciales/2020/Noviembre/CMF-Renuncia-JSL.pdf'
                    },
                    {
                      fecha: '2020/08/20',
                      titulo: '2020/08/20 | Cambios en la administración.',
                      url: 'hechos-esenciales/2020/Agosto/Carta 20CMF 20Informa 20Cambios 20en 20la 20Administracio CC 81n 2020 2008 202020 20(firmada).pdf'
                    },
                    {
                      fecha: '2020/04/30',
                      titulo: '2020/04/30 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2020/Abril/30042020-Formulario-Pago-de-Dividendos-Circular-N-660-SVS-Complem-Rect.pdf'
                    },
                    {
                      fecha: '2020/04/30',
                      titulo: '2020/04/30 | Acuerdos Juntas de Accionistas.',
                      url: 'hechos-esenciales/2020/Abril/30042020-Carta-CMF-Informa 20Acuerdos.pdf'
                    },
                    {
                      fecha: '2020/04/13',
                      titulo: '2020/04/13 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2020/Abril/13042020-CARTA-a-CMF-Remite-citacio CC 81n-(2020).pdf'
                    }
                  ]
                },
                {
                  año: '2019',
                  titulo: 'Periodo 2019',
                  items: [
                    {
                      fecha: '2019/05/03',
                      titulo: '2019/05/03 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2019/Mayo/03052019-Carta-portadora.pdf'
                    },
                    {
                      fecha: '2019/04/30',
                      titulo: '2019/04/30 | Acuerdos Juntas de Accionistas.',
                      url: 'hechos-esenciales/2019/Abril/30042019-Carta-a-CMF-Informa-Acuerdos-Juntas-30-04-2019.pdf'
                    },
                    {
                      fecha: '2019/04/25',
                      titulo: '2019/04/25 | Cambios en el representante legal y cambios en la administración.',
                      url: 'hechos-esenciales/2019/Abril/25042019-Cambios-representante-legal-y-administracion.pdf'
                    },
                    {
                      fecha: '2019/04/12',
                      titulo: '2019/04/12 | Cambios en la Administración y Junta de Accionitas.',
                      url: 'hechos-esenciales/2019/Abril/12042019-Carta-CMF(2019).pdf'
                    }
                  ]
                },
                {
                  año: '2018',
                  titulo: 'Periodo 2018',
                  items: [
                    {
                      fecha: '2018/05/02',
                      titulo: '2018/05/02 | Elección de Directorio.',
                      url: 'hechos-esenciales/2018/Mayo/02052018-Eleccio CC 81n-de-Directorio.pdf'
                    },
                    {
                      fecha: '2018/04/30',
                      titulo: '2018/04/30 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2018/Abril/30042018-Reparto-de-utilidades.pdf'
                    },
                    {
                      fecha: '2018/04/30',
                      titulo: '2018/04/30 | Acuerdos Juntas de Accionistas.',
                      url: 'hechos-esenciales/2018/Abril/30042018-Acuerdos-Juntas-de-Accionistas.pdf'
                    },
                    {
                      fecha: '2018/04/12',
                      titulo: '2018/04/12 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2018/Abril/12042018-Junta-de-accionistas.pdf'
                    }
                  ]
                },
                {
                  año: '2017',
                  titulo: 'Periodo 2017',
                  items: [
                    {
                      fecha: '2017/11/28',
                      titulo: '2017/11/28 | Cambios en la administración.',
                      url: 'hechos-esenciales/2017/Noviembre/28112017-Cambios-en-la-Adminsitracio CC 81n.pdf'
                    },
                    {
                      fecha: '2017/11/10',
                      titulo: '2017/11/10 | Cambios en la administración.',
                      url: 'hechos-esenciales/2017/Noviembre/10112017-Carta-SVS-Renuncia.pdf'
                    },
                    {
                      fecha: '2017/10/26',
                      titulo: '2017/10/26 | Transacciones con personas relacionadas o con interés.',
                      url: 'hechos-esenciales/2017/Octubre/26102017-SP-Mutuo.pdf'
                    },
                    {
                      fecha: '2017/07/07',
                      titulo: '2017/07/07 | Cambios en la administración.',
                      url: 'hechos-esenciales/2017/Julio/07072017-Cambios-en-la-administracio CC 81n.pdf'
                    },
                    {
                      fecha: '2017/05/31',
                      titulo: '2017/05/31 | Acuerdos Juntas de Accionistas.',
                      url: 'hechos-esenciales/2017/Mayo/31052017-Acuerdo-Junta-de-Accionistas.pdf'
                    },
                    {
                      fecha: '2017/05/31',
                      titulo: '2017/05/31 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2017/Mayo/31052017-Aviso-Dividendo.pdf'
                    },
                    {
                      fecha: '2017/05/12',
                      titulo: '2017/05/12 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2017/Mayo/12052017-Junta-de-Accionistas.pdf'
                    },
                    {
                      fecha: '2017/04/27',
                      titulo: '2017/04/27 | Acuerdos Juntas de Accionistas.',
                      url: 'hechos-esenciales/2017/Abril/27042017-Carta-SVS-Informa-Acuerdos-Juntas.pdf'
                    },
                    {
                      fecha: '2017/04/11',
                      titulo: '2017/04/11 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2017/Abril/11042017-Junta-de-accionistas.pdf'
                    }
                  ]
                },
                {
                  año: '2016',
                  titulo: 'Periodo 2016',
                  items: [
                    {
                      fecha: '2016/04/28',
                      titulo: '2016/04/28 | Acuerdos Juntas de Accionistas.',
                      url: 'hechos-esenciales/2016/Abril/28042016-SVS-Informa-Acuerdos-JOA.pdf'
                    },
                    {
                      fecha: '2016/04/12',
                      titulo: '2016/04/12 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2016/Abril/12042016-SVS-Remite-Citacion.pdf'
                    }
                  ]
                },
                {
                  año: '2015',
                  titulo: 'Periodo 2015',
                  items: [
                    {
                      fecha: '2015/12/07',
                      titulo: '2015/12/07 | Cambios en la administración.',
                      url: 'hechos-esenciales/2015/Diciembre/07122015-Cambios-administracion.pdf'
                    },
                    {
                      fecha: '2015/05/04',
                      titulo: '2015/05/04 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2015/Mayo/04052015-Reparto-utilidades.pdf'
                    },
                    {
                      fecha: '2015/04/30',
                      titulo: '2015/04/30 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2015/Abril/30042015-Junta-Accionistas.pdf'
                    },
                    {
                      fecha: '2015/04/14',
                      titulo: '2015/04/14 | Junta de Accionistas.',
                      url: 'hechos-esenciales/2015/Abril/14042015-Junta-Accionistas.pdf'
                    }
                  ]
                },
                {
                  año: '2014',
                  titulo: 'Periodo 2014',
                  items: [
                    {
                      fecha: '2014/12/15',
                      titulo: '2014/12/15 | Inicio período de Opción Preferente.',
                      url: 'hechos-esenciales/2014/Diciembre/15122014-Aviso.pdf'
                    },
                    {
                      fecha: '2014/12/02',
                      titulo: '2014/12/02 | Aviso Accionistas.',
                      url: 'hechos-esenciales/2014/Diciembre/02122014-Aviso-Accionistas.pdf'
                    },
                    {
                      fecha: '2014/12/02',
                      titulo: '2014/12/02 | Comunica hecho esencial.',
                      url: 'hechos-esenciales/2014/Diciembre/02122014-Comunica-hecho-esencial.pdf'
                    },
                    {
                      fecha: '2014/05/13',
                      titulo: '2014/05/13 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2014/Mayo/13052014-Reparto-de-utilidades.pdf'
                    }
                  ]
                },
                {
                  año: '2013',
                  titulo: 'Periodo 2013',
                  items: [
                    {
                      fecha: '2013/07/25',
                      titulo: '2013/07/25 | Reparto de dividendo definitivo eventual.',
                      url: 'hechos-esenciales/2013/Julio/25072013-Reparto-de-dividendo-definitivo-eventual.pdf'
                    },
                    {
                      fecha: '2013/05/28',
                      titulo: '2013/05/28 | Otros.',
                      url: 'hechos-esenciales/2013/Mayo/28052013-Otros.pdf'
                    },
                    {
                      fecha: '2013/05/13',
                      titulo: '2013/05/13 | Junta ordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2013/Mayo/13052013-Junta-ordinaria-accionistas.pdf'
                    },
                    {
                      fecha: '2013/05/03',
                      titulo: '2013/05/03 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2013/Mayo/03052013-Reparto-de-utilidades.pdf'
                    },
                    {
                      fecha: '2013/04/30',
                      titulo: '2013/04/30 | Cambios en la administración, reparto de utilidades (pago de dividendos), Junta ordinaria de accionistas, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2013/Abril/30042013-Cambios-en-la-administracion.pdf'
                    },
                    {
                      fecha: '2013/04/15',
                      titulo: '2013/04/15 | Junta ordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2013/Abril/15042013-Junta-ordinaria-de-accionistas.pdf'
                    },
                    {
                      fecha: '2013/04/15',
                      titulo: '2013/04/15 | Cambios en la administración.',
                      url: 'hechos-esenciales/2013/Abril/15042013-Cambios-en-la-administracion.pdf'
                    }
                  ]
                },
                {
                  año: '2012',
                  titulo: 'Periodo 2012',
                  items: [
                    {
                      fecha: '2012/11/30',
                      titulo: '2012/11/30 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2012/Noviembre/30112012-Reparto-de-utilidades.pdf'
                    },
                    {
                      fecha: '2012/05/02',
                      titulo: '2012/05/02 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2012/Mayo/02052012-Reparto-de-utilidades.pdf'
                    },
                    {
                      fecha: '2012/04/13',
                      titulo: '2012/04/13 | Junta extraordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2012/Abril/13042012-Junta-extraordinaria-de-accionistas.pdf'
                    },
                    {
                      fecha: '2012/02/02',
                      titulo: '2012/02/02 | Junta extraordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2012/Febrero/02022012-Junta-extraordinaria-de-accionistas.pdf'
                    },
                    {
                      fecha: '2012/01/30',
                      titulo: '2012/01/30 | Junta extraordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2012/Enero/30012012-Junta-extraordinaria-de-accionistas.pdf'
                    }
                  ]
                },
                {
                  año: '2011',
                  titulo: 'Periodo 2011',
                  items: [
                    {
                      fecha: '2011/12/01',
                      titulo: '2011/12/01 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2011/Diciembre/01122011-Reparto-de-utilidades.pdf'
                    },
                    {
                      fecha: '2011/12/01',
                      titulo: '2011/12/01 | Cambios en la administración.',
                      url: 'hechos-esenciales/2011/Diciembre/01122011-Cambios-en-la-administracion.pdf'
                    },
                    {
                      fecha: '2011/07/12',
                      titulo: '2011/07/12 | Junta extraordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2011/Julio/12072011-Junta-extraordinaria-de-accionistas.pdf'
                    },
                    {
                      fecha: '2011/05/06',
                      titulo: '2011/05/06 | Junta ordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2011/Mayo/06052011-Junta-ordinaria-de-accionistas.pdf'
                    },
                    {
                      fecha: '2011/04/28',
                      titulo: '2011/04/28 | Reparto de utilidades (pago de dividendos).',
                      url: 'hechos-esenciales/2011/Abril/28042011-Reparto-de-utilidades.pdf'
                    },
                    {
                      fecha: '2011/04/28',
                      titulo: '2011/04/28 | Junta ordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2011/Abril/28042011-Junta-ordinaria-de-accionistas.pdf'
                    },
                    {
                      fecha: '2011/04/12',
                      titulo: '2011/04/12 | Junta ordinaria de accionistas, citaciones, acuerdos y proposiciones.',
                      url: 'hechos-esenciales/2011/Abril/12042011-Junta-ordinaria-de-accionistas.pdf'
                    }
                  ]
                }
              ]
            }
          }
        ]
      },
    }
  }],
  failures: [{
    response: {}
  }]
};
