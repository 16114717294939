import { Component, OnInit } from '@angular/core';
import { SUPERINTENDENCE_FORM_URL } from '@constants/static-link.constant';
import { SuperintendenceBanner } from '@interfaces/superintendence-banner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-superintendence',
  templateUrl: './superintendence.component.html',
  styleUrls: ['./superintendence.component.scss'],
})
export class SuperintendenceComponent implements OnInit {
  public data: SuperintendenceBanner;
  public loading = false;

  constructor(
    public font: FontService,
    private cmsService: CmsService,
    private navService: NavigationService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit() {
    this.getSuperintendenceBanner();
    this.languageSubscription();
  }

  private getSuperintendenceBanner() {
    this.loading = true;
    this.cmsService.loadSuperintendenceBanner().toPromise()
      .then((response) => this.data = response)
      .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => { this.getSuperintendenceBanner();
    });
  }

  public goToForm() {
    this.navService.goTo(SUPERINTENDENCE_FORM_URL);
  }
}
