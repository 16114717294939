<div class="step-container">
  <div class="title secondary" [ngClass]="font?.size">{{ content?.title | translate }}</div>
  <div class="dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="content?.description | translate"></div>
  <div class="reason" *ngFor="let reason of content?.reasons" (click)="setReason(reason?.id)">
    <div class="icon primary" [ngClass]="reason?.icon"></div>
    <div class="reason-detail">
      <div class="reason-title" [ngClass]="font?.size">{{ reason?.name | translate }}</div>
      <div class="reason-description" [ngClass]="font?.size">{{ reason?.description | translate }}</div>
    </div>
    <div class="icon secondary i-arrow-right"></div>
  </div>
  <div class="i-alert disclaimer" [ngClass]="font?.size" [innerHTML]="content?.disclaimer | translate"></div>
  <ion-button class="button secondary form-button" (click)="goBack()">{{ buttons.previous | translate }}</ion-button>
</div>
