import { Component, Input, OnInit } from '@angular/core';
import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import { CHANNEL_LABELS, PENSION_FORM_TYPE } from '@constants/forms.constant';
import {
  PAPERWORK_FOLLOW_UP_PAGE_CONTENT, STATUS_COLOR_AND_SIMPLY_LANGUAGE, TABLE_COLUMNS
} from '@constants/pages-content/paperwork-follow-up.constant';
import { ASSESSMENT_TITLES } from '@constants/titles.constant';
import { FollowUp } from '@interfaces/follow-up.interface';
import { PartialFormStatusResponse } from '@interfaces/forms.interface';
import { AssessmentPage } from '@modules/affiliate/assessment/assessment.page';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { FormsService } from '@services/forms/forms.service';
import { ErrorUtils } from '@utils/error.utils';

@Component({
  selector: 'app-paperwork-follow-up',
  templateUrl: './paperwork-follow-up.component.html',
  styleUrls: ['./paperwork-follow-up.component.scss']
})
export class PaperworkFollowUpComponent extends AssessmentPage implements OnInit {
  @Input() public detailRoute: string;
  public pageTitle = ASSESSMENT_TITLES.content.followUp;
  public pageContent = PAPERWORK_FOLLOW_UP_PAGE_CONTENT;
  public loading = false;
  public followUp: FollowUp;
  public paperworkData: any[] = [];
  public columnsData = TABLE_COLUMNS;

  constructor(
    private formService: FormsService,
    private translateService: TranslateService,
    public font: FontService,
    public languageProvider: LanguageProvider,
    private modalService: ModalService,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
  ) {
    super();
    this.breadcrumb.push(this.pageTitle);
  }

  public ngOnInit(): void {
    this.translateTableHeaders();
  }

  public async searchPaperworkByParams(event: any): Promise<void> {
    try {
      this.loadingService.showLoading();
      const { rut, phone, email } = event;
      const statusResponse = await this.formService.loadPaperwork({ rut, phone, email }).toPromise();
      this.paperworkData = this.mapStateToSimpleLanguageAndColorClass(statusResponse.data);
      this.paperworkData = this.paperworkData.map((data) => ({ ...data, rut }));
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loadingService.hideLoading();
    }
  }

  private mapStateToSimpleLanguageAndColorClass(rows: PartialFormStatusResponse[]): any[] {
    const { status, formType } = this.pageContent.simplyLanguage;
    return rows.map(row => {
      const { simplyLanguageStatus, colorClass } = STATUS_COLOR_AND_SIMPLY_LANGUAGE.find(item => item.status === row.status);
      const translateType = row.type;
      const initialOrigin = row.origin;
      if (Object.keys(CHANNEL_LABELS).includes(initialOrigin) && Object.keys(PENSION_FORM_TYPE).includes(translateType)) {
        const translatedOrigin = CHANNEL_LABELS[initialOrigin];
        const type = PENSION_FORM_TYPE[translateType];
        return {
          ...row,
          formType: formType && this.translateService.instant(formType[row.type]),
          simplyLanguageStatus: status && this.translateService.instant(status[simplyLanguageStatus]),
          colorClass,
          type,
          origin: translatedOrigin
        };
      }
    });
  }



  private translateTableHeaders(): void {
    const { tableHeaders } = this.pageContent;
    this.columnsData = TABLE_COLUMNS.map((column: any) => {
      const { label } = column;
      return {
        ...column,
        label: this.translateService.instant(tableHeaders[label])
      };
    });
  }

  private handleError(error): void {
    const { title, defaultDescription, firstBtnText } = PAPERWORK_FOLLOW_UP_PAGE_CONTENT.modalError;
    const description = this.errorUtils.handleServiceError(error) || defaultDescription;
    const primaryCallback = () => { };
    const data = { title, description, firstBtnText, primaryCallback };
    this.modalService.openModal(ModalGenericComponent, { data });
  }
}
