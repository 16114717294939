import {
  ANTICIPATED_PENSION_REDIRECT_TYPE, DISABILITY_PENSION_REDIRECT_TYPE, ELDERLY_PENSION_REDIRECT_TYPE
} from '@constants/redirect.constant';
import { environment } from '@environment';
import { PensionTypes } from '@interfaces/future-pensioner.interface';

import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.ASSESSMENT.FUTURE_PENSIONER.TYPES';
const SURVIVOR_PENSION_ROUTE = 'afiliado/asesoria/pension-sobrevivencia';
const TERMINAL_ILLNESS_ROUTE = 'afiliado/asesoria/centro-tramites/enfermos-terminales/inicio';

export const PENSION_TYPES_CONTENT: PensionTypes = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  goTo: `${pageKey}.GO_TO_PROCEDURES`,
  moreInfoCards: [
    {
      image: {
        url: 'assets/images/01card-pension-vejez.webp',
        alt: 'Pensión de Vejez'
      },
      title: `${pageKey}.CARDS.ELDERLY.TITLE`,
      description: `${pageKey}.CARDS.ELDERLY.DESCRIPTION`,
      showMoreText: MORE_INFO,
      redirectionButtons: [
        {
          requestText: `${pageKey}.CARDS.ELDERLY.REQUEST_TEXT`,
          redirectType: ELDERLY_PENSION_REDIRECT_TYPE,
        }
      ],
      modal: {
        title: `${pageKey}.CARDS.ELDERLY.TITLE`,
        description: `${pageKey}.CARDS.ELDERLY.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        alt: 'Pensión Anticipada',
        url: 'assets/images/02card-pension-anticipada.webp',
      },
      title: `${pageKey}.CARDS.EARLY.TITLE`,
      description: `${pageKey}.CARDS.EARLY.DESCRIPTION`,
      showMoreText: MORE_INFO,
      redirectionButtons: [
        {
          requestText: `${pageKey}.CARDS.EARLY.REQUEST_TEXT`,
          redirectType: ANTICIPATED_PENSION_REDIRECT_TYPE,
        }
      ],
      modal: {
        title: `${pageKey}.CARDS.EARLY.TITLE`,
        description: `${pageKey}.CARDS.EARLY.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/03card-pension-invalidez.webp',
        alt: 'Pensión por Invalidez'
      },
      title: `${pageKey}.CARDS.DISABILITY.TITLE`,
      description: `${pageKey}.CARDS.DISABILITY.DESCRIPTION`,
      showMoreText: MORE_INFO,
      redirectionButtons: [
        {
          requestText: `${pageKey}.CARDS.DISABILITY.REQUEST_TEXT`,
          redirectType: DISABILITY_PENSION_REDIRECT_TYPE,
        }
      ],
      modal: {
        title: `${pageKey}.CARDS.DISABILITY.TITLE`,
        description: `${pageKey}.CARDS.DISABILITY.MODAL_DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/04card-pension-terminal.webp',
        alt: 'Pensión Anticipada por Enfermedad Terminal'
      },
      title: `${pageKey}.CARDS.TERMINAL_ILLNESS.TITLE`,
      description: `${pageKey}.CARDS.TERMINAL_ILLNESS.DESCRIPTION`,
      showMoreText: MORE_INFO,
      showMoreUrl: TERMINAL_ILLNESS_ROUTE,
      requestText: `${pageKey}.REQUEST_TEXT`,
      requestUrl: environment.paetUrl,
      showMoreSecondaryText: `${pageKey}.REQUEST_TEXT`,
    },
    {
      image: {
        url: 'assets/images/05card-pension-sobrevivencia.webp',
        alt: 'Pensión por Sobrevivencia'
      },
      title: `${pageKey}.CARDS.SURVIVAL.TITLE`,
      description: `${pageKey}.CARDS.SURVIVAL.DESCRIPTION`,
      showMoreText: MORE_INFO,
      modal: {
        title: `${pageKey}.CARDS.SURVIVAL.TITLE`,
        description: `${pageKey}.CARDS.SURVIVAL.MODAL_DESCRIPTION`,
      },
      requestText: `${pageKey}.REQUEST_TEXT`,
      requestUrl: SURVIVOR_PENSION_ROUTE,
    }
  ]
};
