<div class="title-container extended" *ngIf="!simplified">
  <h2 *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="content?.title"></h2>
  <h3 *ngIf="loading">
    <ion-skeleton-text class="skeleton-line" animated></ion-skeleton-text>
  </h3>
  <p *ngIf="!loading" class="subtitle" [ngClass]="font?.size" [innerHTML]="content?.subtitle + ' ' + translatedCurrentDate()"></p>
  <p *ngIf="loading">
    <ion-skeleton-text class="skeleton-line" animated></ion-skeleton-text>
  </p>
</div>
<div class="title-container banner" *ngIf="simplified">
  <p *ngIf="!loading" class="title" [ngClass]="font?.size">{{ content?.quotaValue }}</p>
  <p *ngIf="loading">
    <ion-skeleton-text class="skeleton-line xsmall" animated></ion-skeleton-text>
  </p>
  <p *ngIf="!loading" class="subtitle" [ngClass]="font?.size">{{ translatedCurrentDate() }}</p>
  <p *ngIf="loading">
    <ion-skeleton-text class="skeleton-line xsmall" animated></ion-skeleton-text>
  </p>
</div>
<div class="quota-values" [class.simplified]="simplified">
  <app-fund-quota-value
    class="quota" [class.simplified]="simplified"
    *ngFor="let fund of funds"
    (click)="showDetail(fund, $event)"
    [fund]="fund"
    [fundLabel]="fundLabel"
    [value]="currentQuotaValue['fund' + fund]"
    [delta]="delta['fund' + fund]"
    [simplified]="simplified"
    [loading]="loading">
  </app-fund-quota-value>
</div>
