<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="call-center-container">
    <div class="icon" *ngIf="!successfulClick2Call" [ngClass]="isCobrowsingSelected ? 'i-screen-share' : 'i-phone-red'">
    </div>
    <div class="call-center-menu" *ngIf="noOptionSelected">

      <p class="title" [ngClass]="font?.size" [innerHTML]="pageContent?.title | translate"></p>
      <p class="disclaimer dynamic-text tertiary" [ngClass]="font?.size" [innerHTML]="callCenterHours"></p>

      <div class="menu-buttons">
        <ion-button class="button back primary" [ngClass]="font?.size" href="tel:{{ contactCenterNumber }}"
          [innerHTML]="pageContent?.callButton"></ion-button>
        <ion-button class="button back primary" [ngClass]="font?.size" (click)="selectClick2Call()"
          [disabled]="!enableContactCenterButtons" *ngIf="isClick2CallActive">{{ pageContent?.buttons.click2Call |
          translate }}</ion-button>
        <ion-button *ngIf="isScreenshareAvailable" class="button back primary" [ngClass]="font?.size"
          (click)="selectCobrowsing()" [disabled]="!enableContactCenterButtons">{{ pageContent?.buttons.cobrowsing |
          translate }}</ion-button>
      </div>
    </div>

    <ng-container *ngIf="isClick2CallSelected">
      <ng-container *ngIf="!successfulClick2Call">
        <div class="text-container">
          <p class="title" [ngClass]="font?.size">{{ click2CallContent?.title | translate }}</p>
          <p class="subtitle" [ngClass]="font?.size">{{ click2CallContent?.description | translate }}</p>
        </div>

        <div class="form-container">
          <form [formGroup]="click2callForm">
            <div class="row-container">
              <ion-item class="question-box">
                <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ click2CallContent?.form.phone |
                  translate }}(*)</ion-label>
                <ion-input formControlName="phone" placeholder="988887777"></ion-input>
              </ion-item>
            </div>
            <div class="row-container two-column">
              <ion-item class="question-box">
                <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ click2CallContent?.form.rut |
                  translate }}(*)</ion-label>
                <ion-input formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut"></ion-input>
              </ion-item>
              <ion-item class="question-box">
                <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ click2CallContent?.form.name |
                  translate }}(*)</ion-label>
                <ion-input formControlName="name" placeholder="Juan Perez"></ion-input>
              </ion-item>
            </div>
            <div class="row-container">
              <ion-item class="question-box">
                <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ click2CallContent?.form.reason |
                  translate }}(*)</ion-label>
                <ion-input formControlName="reason"></ion-input>
              </ion-item>
            </div>
          </form>
        </div>
        <ion-button class="button back primary" [disabled]="click2callForm.invalid" [ngClass]="font?.size"
          (click)="submitClick2CallForm()">{{ pageContent?.buttons.send | translate }}</ion-button>
      </ng-container>
      <ng-container *ngIf="successfulClick2Call">
        <div class="icon i-check"></div>
        <p class="title success-message" [ngClass]="font?.size"
          [innerHTML]="click2CallContent?.successMessage | translate"></p>
      </ng-container>
    </ng-container>

    <div class="cobrowsing-container" *ngIf="isCobrowsingSelected">
      <p class="title" [ngClass]="font?.size" [innerHTML]="pageContent?.cobrowsing.title | translate"></p>
      <p class="disclaimer" [ngClass]="font?.size">{{ pageContent?.cobrowsing.description | translate }}</p>
      <p class="disclaimer enter-code-instruction" [ngClass]="font?.size">
        {{ pageContent?.cobrowsing.enterCodeInstruction | translate }}
      </p>
      <form [formGroup]="screenshareForm">
        <div class="row-container">
          <ion-item class="question-box">
            <ion-input [maxlength]="screenshareCodeMaxLength" class="security-code-input"
              formControlName="securityCode"></ion-input>
          </ion-item>
        </div>
        <p class="input-error" *ngIf="securityCode?.hasError('invalid')" [ngClass]="font?.size">
          {{ pageContent?.cobrowsing.errors.invalid | translate }}
        </p>
      </form>
      <ion-button class="button back primary" [disabled]="isShareScreenButtonDisabled" [ngClass]="font?.size"
        (click)="startScreenshare()">
        {{ shareScreenButtonText | translate }}
      </ion-button>
      <p class="disclaimer bottom" [ngClass]="font?.size">{{ pageContent?.cobrowsing.recomendation | translate }}</p>
    </div>
  </div>
</div>