import * as _ from 'lodash';

import { GENERAL_BACKGROUND_FIELDS_CONTENT } from '@constants/person-backgrounds.constant';

const pageKey = 'AFFILIATE.STATE_GUARANTEE_FORM';

const backgroundKey = `${pageKey}.STEPS.BACKGROUND`;
const applicantBackgroundKey = `${backgroundKey}.APPLICANT`;
const declarationSectionKey = `${backgroundKey}.DECLARATION_SECTION`;
const deceasedBackgroundKey = `${backgroundKey}.DECEASED`;

const declarationsKey = `${pageKey}.STEPS.DECLARATIONS`;

const conditionsKey = `${pageKey}.STEPS.CONDITIONS`;
const conditionsOptionsKey = `${pageKey}.STEPS.CONDITIONS.OPTIONS`;

const voucherKey = `${pageKey}.STEPS.VOUCHER`;

const successSubmissionKey = `${pageKey}.STEPS.VOUCHER.SUCCESS`;
const failedSubmissionKey = `${pageKey}.STEPS.VOUCHER.FAILED`;

export type StateGuaranteeBackgroundOptionKeys =
  'oldSystemPension' |
  'oldSystemPensionOthers' |
  'maxIncome' |
  'maxIncomeOthers' |
  'otherCountryQuotations' |
  'singleSons';

export const BACKGROUND_KEYS: StateGuaranteeBackgroundOptionKeys[] = [
  'oldSystemPension', 'oldSystemPensionOthers', 'maxIncome', 'maxIncomeOthers', 'otherCountryQuotations', 'singleSons'
];

export const INVERTED_DECLARATIONS: StateGuaranteeBackgroundOptionKeys[] = _.cloneDeep(BACKGROUND_KEYS).splice(0, 4);

export const STATE_GUARANTEE_FORM_CONTENT = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  introduction: {
    title: `${pageKey}.INTRODUCTION.TITLE`,
    description: `${pageKey}.INTRODUCTION.DESCRIPTION`,
    buttonAction: `${pageKey}.INTRODUCTION.BUTTON_ACTION`
  },
  steps: {
    background: {
      label: `${backgroundKey}.LABEL`,
      survivor: {
        label: `${backgroundKey}.SURVIVOR.LABEL`,
        yes: `${backgroundKey}.SURVIVOR.YES`,
        no: `${backgroundKey}.SURVIVOR.NO`,
        definitions: [{ title: `FORM_DEFINITIONS.4.TITLE`, definition: `FORM_DEFINITIONS.4.TEXT` }]
      },
      applicant: {
        title: `${applicantBackgroundKey}.TITLE`,
        ...GENERAL_BACKGROUND_FIELDS_CONTENT
      },
      declarationSection: {
        title: `${declarationSectionKey}.TITLE`,
        disclaimer: `${declarationSectionKey}.DISCLAIMER`,
      },
      deceased: {
        title: `${deceasedBackgroundKey}.TITLE`,
        ...GENERAL_BACKGROUND_FIELDS_CONTENT
      },
      buttons: {
        next: `${backgroundKey}.BUTTONS.NEXT`,
        back: `${backgroundKey}.BUTTONS.BACK`,
      }
    },
    declarations: {
      label: `${declarationsKey}.LABEL`,
      buttons: {
        next: `${declarationsKey}.BUTTONS.NEXT`,
        back: `${declarationsKey}.BUTTONS.BACK`
      },
      pensions: {
        title: `${declarationsKey}.PENSIONS.TITLE`,
        description: `${declarationsKey}.PENSIONS.DESCRIPTION`,
        myPension: `${declarationsKey}.PENSIONS.MINE`,
        offspringsPension: `${declarationsKey}.PENSIONS.OFFSPRINGS`,
        representedPension: `${declarationsKey}.PENSIONS.REPRESENTED`,
      },
      backgrounds: {
        content: {
          title: `${declarationsKey}.BACKGROUNDS.TITLE`,
          description: `${declarationsKey}.BACKGROUNDS.DESCRIPTION`,
          options: {
            yes: `${declarationsKey}.BACKGROUNDS.YES`,
            no: `${declarationsKey}.BACKGROUNDS.NO`
          },
          errors: {
            required: `${declarationsKey}.BACKGROUNDS.ERRORS.REQUIRED`
          }
        },
        options: [
          {
            code: 0,
            text: `${declarationsKey}.BACKGROUNDS.OPTIONS.OLD_PENSION.TEXT`
          },
          {
            code: 1,
            text: `${declarationsKey}.BACKGROUNDS.OPTIONS.OLD_PENSION_OTHERS.TEXT`
          },
          {
            code: 2,
            text: `${declarationsKey}.BACKGROUNDS.OPTIONS.MAX_INCOME.TEXT`,
            definitions: [{ title: `FORM_DEFINITIONS.17.TITLE`, definition: `FORM_DEFINITIONS.17.TEXT`}]
          },
          {
            code: 3,
            text: `${declarationsKey}.BACKGROUNDS.OPTIONS.MAX_INCOME_OTHERS.TEXT`,
            definitions: [{ title: `FORM_DEFINITIONS.17.TITLE`, definition: `FORM_DEFINITIONS.17.TEXT`}]
          },
          {
            code: 4,
            text: `${declarationsKey}.BACKGROUNDS.OPTIONS.ANOTHER_COUNTRY.TEXT`,
            detail: `${declarationsKey}.BACKGROUNDS.OPTIONS.ANOTHER_COUNTRY.DETAIL`
          },
          {
            code: 5,
            text: `${declarationsKey}.BACKGROUNDS.OPTIONS.SINGLE_OFFSPRINGS.TEXT`,
          }
        ]
      },
      beneficiaries: {
        title: `${declarationsKey}.BENEFICIARIES.TITLE`,
        add: `${declarationsKey}.BENEFICIARIES.ADD`,
        disclaimer: `${declarationsKey}.BENEFICIARIES.DISCLAIMER`
      }
    },
    conditions: {
      label: `${conditionsKey}.LABEL`,
      declaration: `${conditionsKey}.DECLARATION`,
      options: {
        one: `${conditionsOptionsKey}.ONE`,
        two: `${conditionsOptionsKey}.TWO`,
        three: `${conditionsOptionsKey}.THREE`,
        four: `${conditionsOptionsKey}.FOUR`,
        five: `${conditionsOptionsKey}.FIVE`,
        six: `${conditionsOptionsKey}.SIX`,
      },
      buttons: {
        next: `${conditionsKey}.BUTTONS.NEXT`,
        back: `${conditionsKey}.BUTTONS.BACK`
      },
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
      success: {
        title: `${successSubmissionKey}.TITLE`,
        description: `${successSubmissionKey}.DESCRIPTION`,
        homeButton: `${successSubmissionKey}.BUTTON`,
        download: `${successSubmissionKey}.DOWNLOAD_PDF`,
      },
      failed: {
        title: `${failedSubmissionKey}.TITLE`,
        description: `${failedSubmissionKey}.DESCRIPTION`,
        firstBtnText: `${failedSubmissionKey}.BUTTON`,
        iconName: 'i-error',
      }
    },
  }
};

export const PERSONAL_DOCUMENT_REQUIRED_FILE = {
  personalDocument: {
    order: 1,
    buttonLabel: `${backgroundKey}.PERSONAL_DOCUMENT_TITLE`,
    required: true,
    fileIndex: 'personalDocument',
  }
};

const DISCLAIMER_TEXTS = STATE_GUARANTEE_FORM_CONTENT.steps.conditions.options;
export const DISCLAIMERS = [
  { icon: 'cash', text: DISCLAIMER_TEXTS.one,
    definitions: [{ title: `FORM_DEFINITIONS.17.TITLE`, definition: `FORM_DEFINITIONS.17.TEXT`}] },
  { icon: 'mail', text: DISCLAIMER_TEXTS.two },
  { icon: 'alert', text: DISCLAIMER_TEXTS.three },
  { icon: 'pension', text: DISCLAIMER_TEXTS.four },
  { icon: 'percentage', text: DISCLAIMER_TEXTS.five },
  { icon: 'profile', text: DISCLAIMER_TEXTS.six },
];
