export const MORE_INFO = 'GENERAL.MORE_INFO';
export const GO_BACK = 'GENERAL.GO_BACK';
export const DOWNLOAD_FORM = 'GENERAL.DOWNLOAD_FORM';
export const RUT = 'FORM_FIELDS.RUT';
export const PHONE_NUMBER = 'FORM_FIELDS.PHONE_NUMBER';
export const EMAIL = 'FORM_FIELDS.EMAIL';
export const RECAPTCHA_DISCLAIMER = 'GENERAL.RECAPTCHA_DISCLAIMER';
export const YES = 'GENERAL.YES';
export const NO = 'GENERAL.NO';
export const SEND = 'GENERAL.SEND';
export const FUND = 'GENERAL.FUND';
export const CONTINUE = 'GENERAL.CONTINUE';
export const SEND_REQUEST = 'GENERAL.SEND_REQUEST';
