<app-card [title]="pageContent.requestContent.attachment.title">
  <div class="card-container" [class.disabled-component]="disabled">
    <div class="files-container" *ngIf="existAttachedFiles">
      <div class="files-names" *ngFor="let attachFile of attachedFiles; let i = index">
        <div class="file-texts">
          <p class="file-name" [ngClass]="font?.size">{{ attachFile.file.name }}</p>
          <p class="file-size">(<span class="file-size-digit" [ngClass]="font?.size">{{ getFileSize(attachFile.file.size) }}</span>)</p>
        </div>
        <div class="actions-container" *ngIf="attachFile.isEditable">
          <button (click)="downloadFile(attachFile.file)" class="icon i-download-2"></button>
          <button (click)="deleteFileModal(i)" class="icon i-close"></button>
        </div>
      </div>
    </div>
    
    <div class="upload-file-option">
      <input type="file" class="file-upload" (change)="attachFile($event)" #fileUpload>
      <button class="btn small secondary with-icon" (click)="fileUpload.click()" [ngClass]="font?.size">{{ pageContent?.requestContent.attachment.button | translate }}<em class="icon i-document-2"></em></button>
    </div>

    <div class="rules">
      <div class="rule" [ngClass]="getStatusClass('name')">
        <div class="icon"[ngClass]="getStatusIcon('name')" ></div>
        <div class="text" [ngClass]="font?.size">{{ pageContent?.requestContent.attachment.rules.name | translate }}</div>
      </div>
      <div class="rule"[ngClass]="getStatusClass('format')" >
      <div class="icon" [ngClass]="getStatusIcon('format')"></div>
        <div class="text" [ngClass]="font?.size">{{ pageContent?.requestContent.attachment.rules.format | translate }}</div>
      </div>
      <div class="rule" [ngClass]="getStatusClass('size')">
        <div class="icon" [ngClass]="getStatusIcon('size')"></div>
        <div class="text" [ngClass]="font?.size">{{ pageContent?.requestContent.attachment.rules.size | translate }}</div>
      </div>
    </div>
  </div>
</app-card>
