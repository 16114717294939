import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { VideoCallService } from '@services/video-call/video-call.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private navCtrl: NavController,
    private videoCall: VideoCallService,
  ) { }

  public externalUrl(url: string) {
    return url.includes('http');
  }

  public goTo(url: string) {
    window.scroll(0, 0);
    if (!url) { return; }
    if (this.externalUrl(url)) { return this.windowOpen(url, '_self'); }
    this.navCtrl.navigateForward(url);
  }

  public windowOpen(externalUrl: string, type = '_blank') {
    if (type === '_self') {
      this.videoCall.endVideocall();
    }
    return window.open(externalUrl, type);
  }
}
