import { Component, Input } from '@angular/core';

import { Article } from '@interfaces/components.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-card-article',
  templateUrl: './card-article.component.html',
  styleUrls: ['./card-article.component.scss'],
})
export class CardArticleComponent {
  @Input() public article: Article;
  @Input() public moreInfoText: string;
  @Input() public type: 'primary' | 'secondary' | 'secondary-banner';
  @Input() public loading = false;
  @Input() public section: 'info' | 'guiaVital';

  public get articleTypeClass() { return this.type ? `article-${this.type}` : ''; }

  constructor(
    public font: FontService,
    private navService: NavigationService,
  ) { }

  public openPage() {
    this.navService.goTo(this.article.url);
  }

  public openNewTabPage() {
    this.navService.windowOpen(this.article.url);
  }
}
