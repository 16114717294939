import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'ahorros', redirectTo: 'ahorros/obligatorio', pathMatch: 'full' },
  {
    path: 'ahorros',
    children: [
      {
        path: 'obligatorio',
        loadChildren: () => import('./mandatory-savings/mandatory-savings.module').then(m => m.MandatorySavingsPageModule)
      },
      {
        path: 'previsional-voluntario',
        loadChildren: () => import('./voluntary-savings/voluntary-savings.module').then(m => m.VoluntarySavingsPageModule)
      },
      {
        path: 'cuenta-2',
        loadChildren: () => import('./secondary-account/secondary-account.module').then(m => m.SecondaryAccountPageModule)
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class SavingsRoutingModule { }
