import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { GO_BACK } from '@constants/pages-content/general.constant';
import { Card } from '@interfaces/info-card.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-modal-mandate',
  templateUrl: './modal-mandate.component.html',
  styleUrls: ['./modal-mandate.component.scss'],
})
export class ModalMandateComponent extends ModalComponent {
  public information: Card<any>;
  public backButton = GO_BACK;
  modalInformation: string;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private utils: Utils,
    private navService: NavigationService

  ) {
    super(params, modalCtrl);
    this.information = this.params.get('information');
    this.modalInformation = 'AFFILIATE.ASSESSMENT.PAPERWORK_CENTER.MORE_INFO_CARD.DOWNLOAD_MANDATE';
  }

  public downloadMandate(mandato: string): void {
    const baseUrl = 'https://storage.googleapis.com/pv-site-public-files-dev/tramite-pension/';
    let downloadUrl: string;

    switch (mandato) {
      case 'notarial':
        downloadUrl = baseUrl + 'mandato-cobro-pension.pdf';
        break;
      case 'hospitalizado':
        downloadUrl = baseUrl + 'mandato-cobro-pension-afiliado-hospitalizado.pdf';
        break;
      case 'privado':
        downloadUrl = baseUrl + 'mandato-cobro-pension-privados-libertad.pdf';
        break;
    }

    if (downloadUrl) {
      this.utils.downloadFile(downloadUrl, mandato + '-Pdf');
    }
  }

  goToMandate(): void {
    this.navService.goTo(this.information.requestUrl);
    this.closeModal();
  }
}
