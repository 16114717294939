import { Certificate, PublicCertificate } from '@interfaces/certificates.interface';
import { GET_CERTIFICATE_CONTENT } from './pages-content/certificates.constant';

export const VALIDATE_CERTIFICATE_MODAL = {
  VALID: {
    title: `Tu certificado es válido, puedes revisarlo haciendo click en descargar`,
    subtitle: `<p>El certificado se enviará a tu correo registrado<br>en tus datos de AFP PlanVital.</p>`
  },
  INVALID: {
    title: `Tu certificado no es válido`,
    subtitle: ``,
  },
  CONTENT: {
    goBack: 'Volver',
    download: 'Descargar certificado',
    sendEmail: 'Enviar por e-mail',
  }
};

export type certificateFieldType = 'rut' | 'accountType' | 'periodCode' | 'productType';

export const GENERAL_CERTIFICATES: Array<Certificate> = [{
  name: GET_CERTIFICATE_CONTENT.types.general.affiliate.name,
  type: 'affiliate',
  description: GET_CERTIFICATE_CONTENT.types.general.affiliate.description,
  fields: ['rut'],
},
{
  name: GET_CERTIFICATE_CONTENT.types.general.contingencyRecords.name,
  type: 'contingency_records',
  description: GET_CERTIFICATE_CONTENT.types.general.contingencyRecords.description,
  fields: ['rut', 'accountType'],
},
{
  name: GET_CERTIFICATE_CONTENT.types.general.residenceRecords.name,
  type: 'residence_records',
  description: GET_CERTIFICATE_CONTENT.types.general.residenceRecords.description,
  fields: ['rut'],
},
{
  name: GET_CERTIFICATE_CONTENT.types.general.vacations.name,
  type: 'vacations',
  description: GET_CERTIFICATE_CONTENT.types.general.vacations.description,
  fields: ['rut'],
}];

export const TRANSACTIONAL_CERTIFICATES: Array<Certificate> = [{
  name: GET_CERTIFICATE_CONTENT.types.transactional.quotations.name,
  type: 'quotations',
  description: GET_CERTIFICATE_CONTENT.types.transactional.quotations.description,
  fields: ['rut', 'periodCode', 'productType'],
}, {
  name: GET_CERTIFICATE_CONTENT.types.transactional.remunerations.name,
  type: 'remunerations',
  description: GET_CERTIFICATE_CONTENT.types.transactional.remunerations.description,
  fields: ['rut', 'periodCode'],
}];

export const ALL_CERTIFICATES: Array<PublicCertificate> = [{
  name: GET_CERTIFICATE_CONTENT.categories.general.name,
  description: GET_CERTIFICATE_CONTENT.categories.general.description,
  icon: 'i-general-certificate',
  certificatesList: GENERAL_CERTIFICATES,
},
{
  name: GET_CERTIFICATE_CONTENT.categories.transactional.name,
  description: GET_CERTIFICATE_CONTENT.categories.transactional.description,
  icon: 'i-transactional-certificate',
  certificatesList: TRANSACTIONAL_CERTIFICATES,
}];
