import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SlidesComponent } from '@components/slides/slides.component';
import { MAIN_PRODUCTS } from '@constants/savings.constant';
import { IconCard } from '@interfaces/icon-card.interface';
import { Product, ProductProperty } from '@interfaces/product.interface';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-slides-products',
  templateUrl: './slides-products.component.html',
  styleUrls: ['./slides-products.component.scss'],
})
export class SlidesProductsComponent extends SlidesComponent implements OnInit, OnChanges {
  @ViewChild('swiperRef', { static: true }) public swiperRef: ElementRef<SwiperContainer>;
  @Input() public products: Array<Product>;
  @Input() public loading = false;
  public mainProducts = MAIN_PRODUCTS;

  public cards: Array<IconCard> = [];

  constructor() {
    super();
  }
  ngOnInit(): void {
    this.slideOpts = {
      pagination: { el: '.swiper-pagination', clickable: true },
      slidesPerView: 3, initialSlide: 0, loop: false, centeredSlides: false,
      breakpoints: {
        1024: { centeredSlides: false, slidesPerView: 3, initialSlide: 0 },
        720: { centeredSlides: false, slidesPerView: 2.3, initialSlide: 0 },
        480: { centeredSlides: false, slidesPerView: 2, initialSlide: 0 },
        320: { centeredSlides: true, slidesPerView: 1, initialSlide: 0 },
      }
    };
    Object.assign(this.swiperRef.nativeElement, this.slideOpts);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.products && changes.products.currentValue) {
      this.products.forEach((product) => {
        const properties = this.getProperties(product.identifier);
        const { title, description, icon, textButton } = product;
        const { url, style } = properties;
        const card: IconCard = { url, style, description, title, icon, textButton };
        this.cards.push(card);
      });
    }
  }

  private getProperties(identifier): ProductProperty {
    switch (identifier) {
      case 'Ahorro obligatorio':
        return this.mainProducts.mandatorySavings;
      case 'APV':
        return this.mainProducts.voluntarySavings;
      case 'Cuenta 2':
        return this.mainProducts.secondaryAccount;
    }
  }
}
