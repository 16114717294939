import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  HttpClientInterceptor
} from '@interceptors/http-client-interceptor/http-client-interceptor';
import { DefaultResponseItem, PaymentMethods, Region } from '@interfaces/general.interface';
import {
  PAYMENT_METHODS_AND_BANKS_MOCKUP
} from '@services/parameters/mocks/payment-methods-banks.mock';
import { REGIONS_MOCKUP } from '@services/parameters/mocks/regions.mock';
import { ErrorUtils } from '@utils/error.utils';

import { BANKS_MOCKUP } from './mocks/banks.mock';
import { VALIDATE_EMAIL_DOMAIN_MOCK } from './mocks/email-domain-validation.mock';
import { PAYMENT_METHODS_MOCKUP } from './mocks/payment-methods.mock';
import { RELATIONSHIPS_MOCKUP } from './mocks/relationships.mock';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  private baseUrl = `${environment.withdrawalsUrl}parameters/`;

  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
  ) { }

  public getPaymentMethodsAndBanks(): Observable<PaymentMethods> {
    const url = `${this.baseUrl}payment-methods`;
    return this.http.get(url, PAYMENT_METHODS_AND_BANKS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getPaymentMethods(functionality?: 'pensionary-forms'): Observable<Array<DefaultResponseItem>> {
    const url = `${environment.proxyApiUrl}payment-methods`;
    const query = functionality ? { functionality } : {};
    return this.http.get(url, PAYMENT_METHODS_MOCKUP, query)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getBanks(): Observable<Array<DefaultResponseItem>> {
    const url = `${environment.proxyApiUrl}banks`;
    return this.http.get(url, BANKS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getRegions(): Observable<Array<Region>> {
    const url = `${this.baseUrl}public-regions`;
    return this.http.get(url, REGIONS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getRelationships(): Observable<Array<DefaultResponseItem>> {
    const url = `${environment.proxyApiUrl}relationships`;
    return this.http.get(url, RELATIONSHIPS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public validateEmailDomain(email: string): Observable<boolean> {
    const url = `${this.baseUrl}validate-email/?email=${email}`;
    return this.http.get(url, VALIDATE_EMAIL_DOMAIN_MOCK)
      // tslint:disable-next-line deprecation
      .pipe(catchError(() => of(true)));
  }

  public validationRut(rut: string): Observable<string> {
    const url = `${environment.clientsUrl}affiliates/${rut}/validate`;
    return this.http.get(`${url}`, null)
      .pipe(map(response => response));
  }

}
