<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="download-app-container">
    <div class="store-icons-container">
      <a href="https://play.google.com/store/apps/details?id=cl.planvital.app" rel="noopener noreferrer" target=_blank><div class="icon-store i-google-play-black-{{ lang }}"></div></a>
      <a href="https://apps.apple.com/cl/app/afp-planvital/id1437275304" rel="noopener noreferrer" target=_blank><div class="icon-store i-app-store-black-es"></div></a>
      <a href="https://appgallery.huawei.com/#/app/C103958565" rel="noopener noreferrer" target=_blank><div class="icon-store i-app-gallery-black-{{ lang }}"></div></a>
    </div>
  </div>
</div>
