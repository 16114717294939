import { Component } from '@angular/core';
import { PopoverComponent } from '@components/popover/popover.component';
import { PrimaryOption } from '@interfaces/header.interface';
import { NavParams, PopoverController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-header-popover',
  templateUrl: './popover-header-menu.component.html',
  styleUrls: ['./popover-header-menu.component.scss'],
})
export class PopoverHeaderMenuComponent extends PopoverComponent {
  public options: Array<PrimaryOption>;
  constructor(
    public font: FontService,
    private params: NavParams,
    private navService: NavigationService,
    protected popoverCtrl: PopoverController,
  ) {
    super(popoverCtrl);
    this.options = this.params.get('options');
  }

  public goToPage(event: any, url: string) {
    event.preventDefault();
    if (!url) { return; }
    this.dismissPopover();
    this.navService.goTo(url);
  }

}
