<mat-accordion>
  <mat-expansion-panel class="item" *ngFor="let item of list">
    <mat-expansion-panel-header class="header">
      <mat-panel-title *ngIf="!loading" class="title" [ngClass]="font?.size">{{ item.title | translate }}</mat-panel-title>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line" animated></ion-skeleton-text>
    </mat-expansion-panel-header>
    <div *ngIf="!loading" class="description dynamic-text tertiary" [ngClass]="font?.size" [innerHTML]="item?.description | translate"></div>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line" animated></ion-skeleton-text>
    <div class="image-container" *ngIf="item?.image">
      <img class="img" src="{{ item?.image.url }}" alt="{{ item?.image.alt }}">
    </div>
  </mat-expansion-panel>
</mat-accordion>
