import { ClientUserType, EmployerUserType } from '@interfaces/segment.interface';

const pageKey = 'PAYMENT_METHODS';

export const OTHER_PAYMENT_METHODS_IDS = ['40', '41', '4'];
export const OTHER_PAYMENT_METHODS_IDS_EMPLOYER = ['4'];

export const DEFAULT_STATE_BANK_CODE = '012';
export const DEFAULT_VISTA_ACCOUNT_CODE = '8';

export const CLIENT_USER_TYPE: ClientUserType = 'client';
export const EMPLOYER_USER_TYPE: EmployerUserType = 'employer';

export const STATEMENT_PENSIONARY_DECLARATIONS_HEADLINES = [
  {
    id: '1',
    description: `${pageKey}.FORM_SECTION.HEADLINES.AFFILIATE`
  },
  {
    id: '2',
    description: `${pageKey}.FORM_SECTION.HEADLINES.MANDATARY`
  },
];
