import { Component, Input } from '@angular/core';
import { ArticlesSection } from '@interfaces/components.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-secondary-articles',
  templateUrl: './secondary-articles.component.html',
  styleUrls: ['./secondary-articles.component.scss'],
})
export class SecondaryArticlesComponent {
  @Input() articlesSection: ArticlesSection;
  @Input() public loading = false;

  constructor(
    public font: FontService,
  ) { }

}
