<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <div class="icon-result"></div>
  <p class="title" [ngClass]="font?.size" [innerHTML]="title | translate"></p>
  <p class="subtitle" [ngClass]="font?.size" [innerHTML]="description | translate"></p>
  <div class="disclaimer-container" *ngIf="disclaimer">
    <p class="disclaimer" [innerHTML]="disclaimer | translate"></p>
  </div>
  <div class="button-container" *ngIf="showButton">
    <ion-button class="button primary introductory" [class.large]="largeButton" [ngClass]="font?.size"
      (click)="goTo(urlRedirect)">
      {{ buttonText | translate }}
    </ion-button>
  </div>
</div>