const pageKey = 'OMNICHANNEL_STATUS';
const requestCategories = 'REQUEST_CATEGORY';

export const STATUS_REQUEST_KEY = {
  partial: 'P',
  enter: 'I',
  inProgress: 'E',
  rejected: 'R',
  accepted: 'A',
  inProcess: 'T',
  expired: 'C',
  pendingDocuments: 'D',
  cancelled: 'N',
};

export const STATUS_REQUEST = {
  [STATUS_REQUEST_KEY.partial]: `${pageKey}.PARTIAL`,
  [STATUS_REQUEST_KEY.enter]: `${pageKey}.ENTER`,
  [STATUS_REQUEST_KEY.inProgress]: `${pageKey}.IN_PROGRESS`,
  [STATUS_REQUEST_KEY.rejected]: `${pageKey}.REJECTED`,
  [STATUS_REQUEST_KEY.accepted]: `${pageKey}.ACCEPTED`,
  [STATUS_REQUEST_KEY.inProcess]: `${pageKey}.IN_PROCESS`,
  [STATUS_REQUEST_KEY.expired]: `${pageKey}.EXPIRED`,
  [STATUS_REQUEST_KEY.pendingDocuments]: `${pageKey}.PENDING_DOCUMENTS`,
  [STATUS_REQUEST_KEY.cancelled]: `${pageKey}.CANCELLED`
};

export const STATUS_HISTORY_REQUEST = {
  [STATUS_REQUEST_KEY.partial]: {
    status: `${pageKey}.PARTIAL`, definitions: [{ title: 'STATUS_DEFINITIONS.P.TITLE', definition: 'STATUS_DEFINITIONS.P.TEXT' }]
  },
  [STATUS_REQUEST_KEY.enter]: {
    status: `${pageKey}.ENTER`, definitions: [{ title: 'STATUS_DEFINITIONS.I.TITLE', definition: 'STATUS_DEFINITIONS.I.TEXT' }]
  },
  [STATUS_REQUEST_KEY.inProgress]: {
    status: `${pageKey}.IN_PROGRESS`, definitions: [{ title: 'STATUS_DEFINITIONS.E.TITLE', definition: 'STATUS_DEFINITIONS.E.TEXT' }]
  },
  [STATUS_REQUEST_KEY.rejected]: {
    status: `${pageKey}.REJECTED`, definitions: [{ title: 'STATUS_DEFINITIONS.R.TITLE', definition: 'STATUS_DEFINITIONS.R.TEXT' }]
  },
  [STATUS_REQUEST_KEY.accepted]: {
    status: `${pageKey}.ACCEPTED`, definitions: [{ title: 'STATUS_DEFINITIONS.A.TITLE', definition: 'STATUS_DEFINITIONS.A.TEXT' }]
  },
  [STATUS_REQUEST_KEY.inProcess]: {
    status: `${pageKey}.IN_PROCESS`, definitions: [{ title: 'STATUS_DEFINITIONS.T.TITLE', definition: 'STATUS_DEFINITIONS.T.TEXT' }]
  },
  [STATUS_REQUEST_KEY.expired]: {
    status: `${pageKey}.EXPIRED`, definitions: [{ title: 'STATUS_DEFINITIONS.C.TITLE', definition: 'STATUS_DEFINITIONS.C.TEXT' }]
  },
  [STATUS_REQUEST_KEY.cancelled]: {
    status: `${pageKey}.CANCELLED`, definitions: [{ title: 'STATUS_DEFINITIONS.N.TITLE', definition: 'STATUS_DEFINITIONS.N.TEXT' }]
  }
};

export const CHILD_STATUS = {
  PENDING: 'P',
  FINISHED: 'T'
};

const SUBSTATUS_OBJECT = () => {
  const childSubstatus = {};
  for (let statusKey = 1; statusKey <= 26; statusKey++) {
    childSubstatus[statusKey] = {
      status: `STATUS_DEFINITIONS.SUBSTATUS.${statusKey}.TITLE`,
      definitions: [{
        title: `STATUS_DEFINITIONS.SUBSTATUS.${statusKey}.TITLE`,
        definition: `STATUS_DEFINITIONS.SUBSTATUS.${statusKey}.TEXT`
      }]
    };
  }
  return childSubstatus;
};

export const CHILD_HISTORY_REQUEST = {
  D: {
    status: `${pageKey}.PENDING_DOCUMENTS`,
    definitions: [{ title: 'STATUS_DEFINITIONS.D.TITLE', definition: 'STATUS_DEFINITIONS.D.TEXT' }]
  },
  ...SUBSTATUS_OBJECT()
};

export const STATUS_COLOR_CLASS = {
  [STATUS_REQUEST_KEY.partial]: 'partial',
  [STATUS_REQUEST_KEY.enter]: 'enter',
  [STATUS_REQUEST_KEY.inProgress]: 'in-progress',
  [STATUS_REQUEST_KEY.rejected]: 'reject',
  [STATUS_REQUEST_KEY.accepted]: 'accept',
  [STATUS_REQUEST_KEY.inProcess]: 'in-process',
  [STATUS_REQUEST_KEY.expired]: 'reject',
  [STATUS_REQUEST_KEY.pendingDocuments]: 'pending-documents',
  [STATUS_REQUEST_KEY.cancelled]: 'canceled'
};

const request = 'REQUESTS';
const pensionKey = `${request}.CATEGORIES.PENSION_PROCEDURES`;
const benefitsKey = `${request}.CATEGORIES.BENEFITS_PENSIONERS`;

export const REQUEST_CATEGORY = {
  category: {
    P: `${pensionKey}.TITLE`,
    B: `${benefitsKey}.TITLE`,
    O: `${requestCategories}.OTHERS`,
  }
};
