import {
  ANTICIPATED_PENSION_TYPE, DISABILITY_PENSION_TYPE, ELDERLY_PENSION_TYPE, INHERITANCE_FORM_TYPE,
  MORTUARY_FORM_TYPE, SURVIVOR_PENSION
} from '@constants/forms.constant';

const detailPageKey = 'PARTIAL_FORMS_DETAIL';
const partialFormModalDataRequestKey = 'MODAL_DATA.PARTIAL_FORMS';
const requestContentPageKey = `${detailPageKey}.REQUEST_CONTENT`;
const informationPageKey = `${requestContentPageKey}.INFORMATION`;
const deceasedPageKey = `${requestContentPageKey}.DECEASED_AFFILIATE`;
const statusPageKey = `${requestContentPageKey}.HISTORY_STATUS`;
const attachmentPageKey = `${requestContentPageKey}.ATTACHMENT`;
const rulesPageKey = `${requestContentPageKey}.ATTACHMENT.RULES`;
const commentsPageKey = `${requestContentPageKey}.COMMENTS`;



export const PARTIAL_FORMS_DETAIL_CONTENT = {
  header: {
    breadcrumb: [
      `${detailPageKey}.HEADER.BREADCRUMB.REQUESTS`,
      `${detailPageKey}.HEADER.BREADCRUMB.SEARCH`,
      `${detailPageKey}.HEADER.BREADCRUMB.DETAIL`
    ],
    title: `${detailPageKey}.HEADER.TITLE`,
    description: `${detailPageKey}.HEADER.DESCRIPTION`,
  },
  requestContent: {
    information: {
      title: `${informationPageKey}.TITLE`,
      request: {
        type: `${informationPageKey}.REQUEST.TYPE`,
        folio: `${informationPageKey}.REQUEST.FOLIO`,
        date: `${informationPageKey}.REQUEST.DATE`,
        origin: `${informationPageKey}.REQUEST.ORIGIN`,
      }
    },
    deceasedAffiliate: {
      title: `${deceasedPageKey}.TITLE`,
      name: `${deceasedPageKey}.NAME`,
      rut: `${deceasedPageKey}.RUT`
    },
    historyStatus: {
      title: `${statusPageKey}.TITLE`,
      disclaimer: `${statusPageKey}.DISCLAIMER`,
    },
    attachment: {
      title: `${attachmentPageKey}.TITLE`,
      emptyBox: `${attachmentPageKey}.EMPTY_BOX`,
      rules: {
        name: `${rulesPageKey}.NAME`,
        format: `${rulesPageKey}.FORMAT`,
        size: `${rulesPageKey}.SIZE`
      },
      button: `${attachmentPageKey}.BUTTON`,
    },
    button: `${requestContentPageKey}.BUTTON`,
    buttonBack: `${requestContentPageKey}.BUTTONBACK`
    },
    comments: {
      title: `${commentsPageKey}.TITLE`,
      sort: `${commentsPageKey}.SORT`,
      button: {
        save: `${commentsPageKey}.BUTTON.SAVE`,
        cancel: `${commentsPageKey}.BUTTON.CANCEL`,
      },
      empty_comments: `${commentsPageKey}.EMPTY_COMMENTS`,
      more: `${commentsPageKey}.MORE`,
      less: `${commentsPageKey}.LESS`,
      document_request: `${commentsPageKey}.DOCUMENT_REQUEST`
    },
    modals: {
      confirmation_delete_file: {
        title: `${requestContentPageKey}.DELETE_FILE.TITLE`,
        subtitle: `${requestContentPageKey}.DELETE_FILE.SUBTITLE`,
        confirmButton: `${requestContentPageKey}.DELETE_FILE.CONFIRM_BUTTON`,
        dismissButton: `${requestContentPageKey}.DELETE_FILE.DISSMIS_BUTTON`
      },
      modal_alert_generic_error : {
        title: `${requestContentPageKey}.MODAL_ALERT_GENERIC_ERROR.TITLE`,
        subtitle: `${requestContentPageKey}.MODAL_ALERT_GENERIC_ERROR.SUBTITLE`,
      }
    }

};

export const PARTIAL_FORMS_DEFAULT_MODAL_DATA = {
  error: {
    title: `${partialFormModalDataRequestKey}.ERRORS.TITLE`,
    message: `${partialFormModalDataRequestKey}.ERRORS.MESSAGE`,
  },
  buttons: {
    goTo: `${partialFormModalDataRequestKey}.BUTTONS.GO_TO`,
    ok: `${partialFormModalDataRequestKey}.BUTTONS.OK`,
  }
};

export const REQUEST_CATEGORY_FORM = {
  category: {
    P: [
      {
        id: ELDERLY_PENSION_TYPE,
        description: `${detailPageKey}.ELDERLY_PENSION_FORM.TITLE`,
      },
      {
        id: ANTICIPATED_PENSION_TYPE,
        description: `${detailPageKey}.ANTICIPATED_PENSION_FORM.TITLE`,
      },
      {
        id: DISABILITY_PENSION_TYPE,
        description: `${detailPageKey}.DISABILITY_PENSION_FORM.TITLE`,
      },
    ],
  }
};

export const REQUEST_WITH_DECEASED = [MORTUARY_FORM_TYPE, INHERITANCE_FORM_TYPE, SURVIVOR_PENSION];
