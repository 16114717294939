import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import { FILE_EXTENSIONS, FILE_MAX_SIZE } from '@constants/forms.constant';
import {
  PAPERWORK_FOLLOW_UP_PAGE_CONTENT
} from '@constants/pages-content/paperwork-follow-up.constant';
import {
  PARTIAL_FORMS_DETAIL_CONTENT
} from '@constants/pages-content/partial-form-detail.constants';
import { DEFAULT_FILE_UPLOADING_STATUS, STATUS_ICONS } from '@constants/request-details.constant';
import { DocumentFile } from '@interfaces/form-files';
import { FormRequestStatusDetail } from '@interfaces/partial-form.interface';
import {
  DeletePaperworkFilesRequest, UploadPaperworkFilesRequest
} from '@interfaces/requests.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { FormsService } from '@services/forms/forms.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-card-attached-documents',
  templateUrl: './card-attached-documents.component.html',
  styleUrls: ['./card-attached-documents.component.scss']
})

export class CardAttachedDocumentsComponent implements OnInit {
  @Input() public requestData: FormRequestStatusDetail;
  @Input() public disabled: boolean;
  @ViewChild('fileUpload', { static: true }) public fileUpload: ElementRef;
  public pageContent = PARTIAL_FORMS_DETAIL_CONTENT;
  public modalContent = PARTIAL_FORMS_DETAIL_CONTENT.modals;
  public attachedFiles: Array<DocumentFile> = [];
  private keyFiles = 'clientFiles';
  private fileUploadingStatus = { ...DEFAULT_FILE_UPLOADING_STATUS };

  public get existAttachedFiles(): boolean { return this.attachedFiles.length > 0; }

  constructor(
    public font: FontService,
    private utils: Utils,
    private errorUtils: ErrorUtils,
    private paperworkService: FormsService,
    private loadingService: LoadingService,
    private modalService: ModalService,
  ) { }

  public ngOnInit(): void {
    this.mapRequestFiles(this.requestData.attached);
  }

  public getFileSize(fileSize: number): string {
    return `${Math.round(fileSize / 1000)}KB`;
  }

  private mapRequestFiles(response: any): void {
    response.forEach(fileResponse => {
      if (!fileResponse) { return; }
      const file = this.utils.base64ToFile(fileResponse);
      const docFile = {
        file,
        key: fileResponse.key,
        personRut: fileResponse.personRut,
        isEditable: false
      } as DocumentFile;
      this.attachedFiles.push(docFile);
    });
  }

  public async attachFile(event): Promise<void> {
    const reader = new FileReader();
    if (!event.target.files || !event.target.files.length) { return; }
    let [file] = event.target.files;
    if (!this.isValidateFile(file)) { return; }
    const validFileName = this.getFileName(file.name);
    if (validFileName !== file.name) { file = new File([file], validFileName, { type: file.type }); }
    reader.readAsDataURL(file);
    reader.onload = () => { this.uploadFile(file); };
  }

  public downloadFile(file: any): void {
    const url = URL.createObjectURL(file);
    this.utils.downloadFile(url, file.name);
  }

  public deleteFileModal(index: number): void {
    const { title, subtitle, confirmButton, dismissButton } = this.modalContent.confirmation_delete_file;
    const data = {
      title,
      description: subtitle,
      firstBtnText: confirmButton,
      secondBtnText: dismissButton,
      showSecondaryButton: true,
      primaryCallback: () => this.removeFile(index),
      secondaryCallback: () => { }
    };
    this.modalService.openModal(ModalGenericComponent, data);
  }

  public getStatusClass(field: string): string {
    return `${this.fileUploadingStatus[field]}`;
  }

  public getStatusIcon(field: string): string {
    const status = this.fileUploadingStatus[field];
    return STATUS_ICONS[status];
  }

  private uploadFile(file: File): void {
    this.loadingService.showLoading();
    const fileToUpload = {
      rut: this.requestData.contactRut,
      formType: this.requestData.type,
      id: this.requestData.id,
      key: this.keyFiles,
      personRut: this.requestData.contactRut,
      files: file
    } as UploadPaperworkFilesRequest;
    const formData = this.utils.createFormData(fileToUpload);
    this.paperworkService.uploadPaperworkFile(formData)
      .then(() => {
        this.attachedFiles.push({
          file,
          key: this.keyFiles,
          personRut: this.requestData.contactRut,
          isEditable: true,
        });
      })
      .catch((error) => {
        this.fileUploadingStatus = { ...DEFAULT_FILE_UPLOADING_STATUS };
        this.handleError(error);
      })
      .finally(() => {
        this.fileUpload.nativeElement.value = '';
        this.loadingService.hideLoading();
      });
  }

  private isValidateFile(file: File): boolean {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!this.checkValidaName(file.name)) { return this.handleErrorFileCondition('name'); }
    this.fileUploadingStatus['name'] = 'success';
    if (!FILE_EXTENSIONS.includes(fileExtension)) { return this.handleErrorFileCondition('format'); }
    this.fileUploadingStatus['format'] = 'success';
    if (this.checkOversizeFiles(file.size)) { return this.handleErrorFileCondition('size'); }
    this.fileUploadingStatus['size'] = 'success';
    return true;
  }

  private getFileName(name: string, iteration = 1): string {
    this.attachedFiles.forEach(file => {
      if (file.file.name.split('.')[0] === name.split('.')[0]) {
        name = this.getFileName(`${name.split('.')[0]} (${++iteration}).${name.split('.').pop()}`);
        return;
      }
    });
    return name;
  }

  private checkValidaName(completeName: string): boolean {
    const onlyName = completeName.split('.').pop();
    return this.utils.onlyLettersAndNumbers(onlyName);
  }

  private checkOversizeFiles(newFileSize: number): boolean {
    let totalSize = newFileSize;
    this.attachedFiles.forEach(file => { totalSize = totalSize + file.file.size; });
    return FILE_MAX_SIZE < totalSize;
  }

  private handleErrorFileCondition(error: string): boolean {
    this.fileUploadingStatus[error] = 'error';
    return false;
  }

  private removeFile(index: number): void {
    this.loadingService.showLoading();
    const fileToDelete = {
      rut: this.requestData.contactRut,
      formType: this.requestData.type,
      id: this.requestData.id,
      fileName: this.attachedFiles[index].file.name,
      key: this.attachedFiles[index].key,
      personRut: this.attachedFiles[index].personRut
    } as DeletePaperworkFilesRequest;
    this.paperworkService.deletePaperworkFile(fileToDelete)
      .then(() => this.attachedFiles.splice(index, 1))
      .catch((error) => this.handleError(error))
      .finally(() => this.loadingService.hideLoading());
  }

  private handleError(error): void {
    const { title, defaultDescription, firstBtnText } = PAPERWORK_FOLLOW_UP_PAGE_CONTENT.modalError;
    const description = this.errorUtils.handleServiceError(error) || defaultDescription;
    const primaryCallback = () => { };
    const data = { title, description, firstBtnText, primaryCallback };
    this.modalService.openModal(ModalGenericComponent, { data });
  }
}

