<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <div class="call-center-container">
    <ng-container *ngIf="showForm">
      <div class="icon i-vitalia-cellphone"></div>
      <ng-container>
        <ng-container >
          <div class="text-container">
            <p class="title" [ngClass]="font?.size">{{ click2CallContent?.title | translate }}</p>
            <p class="subtitle" [ngClass]="font?.size">{{ click2CallContent?.description | translate }}</p>
          </div>
          <ion-segment value="{{ callType }}" mode="ios" (ionChange)="getCallType($event)">
            <ion-segment-button value="phoneCall" layout="icon-start" [disabled]="false">
              <ion-label class="ion-text-wrap">Quiero que me llamen</ion-label>
              <ion-icon name="call"></ion-icon>
            </ion-segment-button>
            <ion-segment-button value="videoCall" layout="icon-start" [disabled]="false">
              <ion-label>Videollamada</ion-label>
              <ion-icon name="videocam"></ion-icon>
            </ion-segment-button>
          </ion-segment>

          <div class="form-container" *ngIf="isVideoCall">
            <div>
              <app-muffler [enlargedHeight]="'147px'" [reducedHeight]="'100px'" [heightAction]="'reduce'">
                <div class="international-call-message ">
                  {{ click2CallContent?.form.videoCallMessage | translate }}
                </div>
              </app-muffler>
            </div>
          </div>

          <div class="form-container" *ngIf="!isVideoCall" [ngClass]="{'international-call': isInternationalPhoneCall}">
            <form [formGroup]="click2callForm">
              <div class="row-container" *ngIf="isNationalPhoneCall">

                <mat-form-field [ngClass]="[font?.size, 'name']" floatLabel="always">
                  <mat-label><b>{{ click2CallContent?.form.name | translate }}(*)</b></mat-label>
                  <input type="text" matInput formControlName="name" placeholder="Juan Perez">
                  <span matSuffix class="i-validator"></span>
                  <mat-error [ngClass]="font?.size" *ngIf="requiredError('name')">
                    {{errorsContent.errors.required | translate}}
                  </mat-error>
                  <mat-error [ngClass]="font?.size" *ngIf="patternError('name')">
                    {{errorsContent.errors.patternLetters | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="row-container" *ngIf="isNationalPhoneCall">
                <mat-form-field [ngClass]="[font?.size, 'rut']" floatLabel="always">
                  <mat-label><b>{{ click2CallContent?.form.rut | translate }}(*)</b></mat-label>
                  <input type="text" matInput formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut">
                  <span matSuffix class="i-validator"></span>
                  <mat-error [ngClass]="font?.size" *ngIf="requiredError('rut')">
                    {{errorsContent.errors.required | translate}}
                  </mat-error>
                  <mat-error [ngClass]="font?.size" *ngIf="!requiredError('rut') && rut.hasError('validRut')">
                    {{errorsContent.errors.invalidRut | translate}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="row-container" *ngIf="isInternationalPhoneCall">
                <app-muffler *ngIf="isInternationalPhoneCall" [enlargedHeight]="'147px'" [reducedHeight]="'100px'" [heightAction]="'reduce'">
                  <div class="national-call-message ">
                    {{ click2CallContent?.form.phoneCallInternationalMessage | translate }}
                  </div>
                </app-muffler>
              </div>

              <div class="row-container two-column">
                <mat-form-field [ngClass]="[font?.size, 'country-codes']" floatLabel="always">
                  <mat-label><b>{{ click2CallContent?.form.countryCode | translate }}(*)</b></mat-label>

                  <mat-select formControlName="countryCode">
                    <mat-option [ngClass]="font?.size" *ngFor="let country of countries" [value]="country.dialCode">
                      {{country.name}} {{country.dialCode}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="number-clean field" *ngIf="isNationalPhoneCall" [ngClass]="[font?.size, 'phone']" floatLabel="always">
                  <mat-label><b>{{ click2CallContent?.form.phone | translate }}(*)</b></mat-label>
                  <input matInput type="number" name="phone" formControlName="phone" placeholder="988887777" [min]="phoneNumberMin" [max]="phoneNumberMax" [maxlength]="phoneNumberLength" digitsOnly>
                  <span matSuffix class="i-validator"></span>
                  <mat-error [ngClass]="font?.size" *ngIf="requiredError('phone')">
                    {{errorsContent.errors.required | translate}}
                  </mat-error>
                  <mat-error [ngClass]="font?.size" *ngIf="patternError('phone')">
                    {{errorsContent.errors.patternNumbers | translate}}
                  </mat-error>
                  <mat-error [ngClass]="font?.size" *ngIf="cellphoneError('phone')">
                    {{errorsContent.errors.invalidPhone | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
            </form>
          </div>

          <ion-button *ngIf="!isVideoCall && !isInternationalPhoneCall" class="button back primary" [disabled]="click2callForm.invalid" [ngClass]="font?.size" (click)="submitNationalCall()">{{ pageContent?.buttons.send | translate }}</ion-button>
          <ion-button *ngIf="isInternationalPhoneCall" class="button back primary" [ngClass]="font?.size" (click)="submitInternationalPhoneCall()">{{ pageContent?.buttons.send | translate }}</ion-button>
          <ion-button *ngIf="isVideoCall" class="button back primary" [ngClass]="font?.size" (click)="submitVideoCall()">{{ pageContent?.buttons.continue | translate }}</ion-button>
        </ng-container>
        <ng-container *ngIf="errorService">
            <p class="error-message">{{ click2CallContent?.errors.failService | translate}}</p>
        </ng-container>

        <div class="customer-schedule">
            <p class="info-schedule">{{ click2CallContent?.schedule | translate}}</p>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!showForm">
      <div class="icon i-vitalia-ok"></div>
      <div class="text-container">
        <p class="title" [ngClass]="font?.size">{{ click2CallContent?.success.title | translate }}</p>
        <p class="subtitle wrap-text" [ngClass]="font?.size">{{ click2CallContent?.success.message | translate }}</p>
        <p class="phone-number">{{ phoneNumberToCall }}</p>
      </div>
        <ion-button class="button back primary" [ngClass]="font?.size" (click)="modalClose()">{{ pageContent?.buttons.close | translate }}</ion-button>
    </ng-container>

  </div>
</div>
