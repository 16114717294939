import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RequestFamilyAllowanceForm } from '@interfaces/family-allowance-form.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public formValues;
  @Output() public formData: EventEmitter<RequestFamilyAllowanceForm> = new EventEmitter();
  public pensionTypesOptions: Array<Array<string>>;
  public requestGoalOptions: Array<Array<string>>;
  public form: UntypedFormGroup;

  public get pensionTypeValid(): boolean { return this.form.value.pensionType; }
  public get infoMessagePensioner(): boolean {
    return ['normal', 'anticipated', 'disability'].includes(this.form.value.pensionType);
  }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.createForm();
    this.pensionTypesOptions = Object.entries(this.pageContent.fields.pensionType.options);
    this.requestGoalOptions = Object.entries(this.pageContent.fields.requestGoal.options);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.formValues) {
      this.loadData();
    }
  }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return Boolean(control) && control.value === code;
  }

  public sendForm(): void {
    if (this.form.invalid) { return; }
    this.formData.emit(this.form.value);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      pensionType: ['', Validators.required],
      requestGoal: ['', Validators.required],
    });
    if (this.formValues) {
      this.loadData();
    }
  }

  private loadData(): void {
    this.form.patchValue({
      pensionType: this.formValues.pensionType,
      requestGoal: this.formValues.requestGoal
    });
  }
}
