<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="options-container">
    <div class="mobile-only">
      <div class="card border-shadow">
        <div class="image-container">
          <img class="information-image" src="{{ imageInformation[0].url }}" alt="{{ imageInformation[0].alt }}">
        </div>
        <div class="content">
          <div class="text-content">
            <h2 class="title-content" [ngClass]="font?.size"> {{ content?.newSite.title | translate}} </h2>
            <div class="description" [ngClass]="font?.size">
              {{ content?.newSite.description | translate }}
              <ul class="description-list">
                <li>Sitio web de <strong>fácil uso</strong></li>
                <li>Encuentra la información que buscas con <strong>rapidez</strong></li>
                <li>Sitio web adaptable a <strong>todos los navegadores</strong></li>
              </ul>
            </div>
            <ion-button class="button primary back" [ngClass]="font?.size" (click)="openUrl(newUrl)">{{ content?.newSite.link | translate}}</ion-button>
            <div class="description center-text" [ngClass]="font?.size">{{ content?.actualSite.description | translate}} </div>
            <ion-button class="button secondary back" [ngClass]="font?.size" (click)="openUrl(actualUrl)">{{ content?.actualSite.link | translate}}</ion-button>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-only">
      <div class="card border-shadow">
        <div class="image-container">
          <img class="information-image" src="{{ imageInformation[0].url }}" alt="{{ imageInformation[0].alt }}">
        </div>
        <div class="content">
          <div class="text-content">
            <h2 class="title-content" [ngClass]="font?.size">{{ content?.newSite.title | translate}}</h2>
            <div class="description" [ngClass]="font?.size">
              {{ content?.newSite.description | translate }}
              <ul class="description-list">
                <li>Sitio web de <strong>fácil uso</strong></li>
                <li>Encuentra la información que buscas con <strong>rapidez</strong></li>
                <li>Sitio web adaptable a <strong>todos los navegadores</strong></li>
              </ul>
            </div>
          </div>
          <ion-button class="button primary back" [ngClass]="font?.size" (click)="openUrl(newUrl)">{{ content?.newSite.link | translate}}</ion-button>
        </div>
      </div>
      <div class="card border-solid">
        <div class="image-container">
          <img class="information-image" src="{{ imageInformation[1].url }}" alt="{{ imageInformation[1].alt }}">
        </div>
        <div class="content">
          <div class="text-content">
            <h4 class="title-content normal" [ngClass]="font?.size">{{ content?.actualSite.title | translate}}</h4>
            <div class="description" [ngClass]="font?.size">{{ content?.actualSite.description | translate}}</div>
          </div>
          <ion-button class="button secondary back" [ngClass]="font?.size" (click)="openUrl(actualUrl)">{{ content?.actualSite.link | translate}}</ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
