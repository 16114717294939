<ng-container *ngIf="componentReady">
    <div class="partial-content">
        <ion-button class="btn small secondary label medium save" [disabled]="!isValidForm" (click)="sendForm(true)">
            {{ pageContent?.buttons.saveContinueLater | translate }}
            <div class="icon-result save"></div>
        </ion-button>
    </div>
    <div class="form-container">
        <div class="form-section">
            <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.form.deceasedAffiliateSection.title | translate }}</h3>
            <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.form.deceasedAffiliateSection.description | translate }}</p>
            <app-person-backgrounds [pageContent]="formContent" [controlsName]="resumeDeceasedAffiliateControls" (formChanged)="validateForm($event, 'deceasedAffiliate')">
            </app-person-backgrounds>
        </div>
        <div class="form-section">
            <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.form.applicantSection.title | translate }}</h3>
            <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.form.applicantSection.description | translate }}</p>
            <app-person-backgrounds [pageContent]="formContent" [controlsName]="resumeApplicantControls" [validateRut]="true" [fieldsValidators]="deceasedFieldsValidators" (formChanged)="validateForm($event, 'applicant')">
            </app-person-backgrounds>
        </div>
        <div class="button-container">
            <ion-button class="button primary form-button" type="submit" [ngClass]="font?.size" (click)="sendForm()" [disabled]="!isValidForm">
                {{ pageContent?.buttons?.next | translate }}
                <div class="icon-result next"></div>
            </ion-button>
        </div>
    </div>
</ng-container>