<div class="chart-investor-profile">
  <div *ngIf="!isChartRightClass" class="fix-chart-space">&nbsp;</div>
  <div class="button-actions">
    <div class="left" [ngClass]="directionLeftClass()" (click)="changeDirection('left')"></div>
    <div class="right" [ngClass]="directionRightClass()" (click)="changeDirection('right')"></div>
  </div>
  <div class="chart-container" [class.right]="isChartRightClass">
    <app-x-chartist *ngIf="chartData" class="chart" [data]="chartData" [options]="chartParams?.chartOptions"
      [type]="chartParams?.chartType"></app-x-chartist>
  </div>
</div>