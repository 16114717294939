<div class="form">
  <app-person-backgrounds
    [pageContent]="pageContent?.applicant"
    [controlsName]="applicantControls"
    (formChanged)="setApplicantForm($event)">
  </app-person-backgrounds>

  <form class="form-container" [formGroup]="form">
    <div class="form-section">
      <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.declarationSection.title | translate }}</h3>
      <div class="information-container">
        <app-disclaimer-box
          [description]= "pageContent?.declarationSection.disclaimer"
          [typeDisclaimer]="'info'">
        </app-disclaimer-box>
      </div>
      <app-attach-files *ngIf="requiredFiles"
        [attachFiles]="requiredFiles"
        [preloadedFiles]="null"
        (fileEmitter)="setFiles($event)">
      </app-attach-files>
    </div>
    <div class="fields-group">
      <mat-radio-group class="option-container custom-border" formControlName="survivor">
        <p class="option-label" [ngClass]="font?.size">{{ pageContent?.survivor.label | translate }}</p>
        <mat-radio-button class="option" value="true" [ngClass]="font?.size">{{ pageContent?.survivor.yes | translate }}</mat-radio-button>
        <mat-radio-button class="option" value="false" [ngClass]="font?.size">{{ pageContent?.survivor.no | translate }}</mat-radio-button>
        <app-info-text-popup [definitions]="pageContent?.survivor.definitions" iconName="i-tooltip-blue">
          <div class="i-tooltip-blue"></div>
        </app-info-text-popup>
      </mat-radio-group>
    </div>
  </form>

  <app-person-backgrounds [hidden]="!isSurvivor"
    [pageContent]="pageContent?.deceased"
    [controlsName]="deceasedControls"
    [fieldsValidators]="deceasedUserFieldsValidators"
    (formChanged)="setDeceasedForm($event)">
  </app-person-backgrounds>

  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="!isValid" (click)="confirm()">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
  </div>
</div>
