import { Component, Input } from '@angular/core';
import { APPLE_STORE, PLAY_STORE } from '@constants/stores.constant';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-more-information',
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.scss'],
})
export class MoreInformationComponent {
  @Input() public disclaimer: string;
  @Input() public buttonText: string;
  @Input() public buttonUrl: string;
  @Input() public loading = false;

  constructor(
    private navService: NavigationService,
    public font: FontService,
  ) { }

  public goTo() {
    if (!this.buttonUrl) { return; }
    if (this.buttonUrl === 'download_app') {
      return this.downloadApp();
    }
    this.navService.goTo(this.buttonUrl);
  }

  public downloadApp() {
    const apple = !!navigator.platform && /iPad|iPhone|iPod|Mac/.test(navigator.platform);
    const url = apple ? APPLE_STORE : PLAY_STORE;
    return this.navService.windowOpen(url);
  }
}
