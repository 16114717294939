<form class="form-container" [formGroup]="form" autocomplete="off" *ngIf="form">
  <h3 class="title-secondary spacing" *ngIf="pageContent?.title" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h3>
  <p class="subtitle-secondary" *ngIf="pageContent?.description" [ngClass]="font?.size">{{ pageContent?.description | translate }}</p>
  <div class="form-section">
    <div class="fields-group inline">
      <div class="check-group big-field">
        <p class="text-label" [ngClass]="font?.size">{{ pageContent?.fields.pensionType | translate | titlecase }}</p>
        <mat-radio-group class="options custom-border" formControlName="pensionType">
          <div class="option" [class.selected]="isChecked('pensionType', pensionType[0])" *ngFor="let pensionType of pensionTypesOptions">
            <div class="left-radio-button">
              <mat-radio-button [value]="pensionType[0]"></mat-radio-button>
            </div>
            <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pensionType[1].title | translate | titlecase }}</div>
            <app-info-text-popup *ngIf="pensionType[1].definitions" class="tooltip-icon" [definitions]="pensionType[1].definitions" iconName="i-tooltip-blue">
              <div class="i-tooltip-blue"></div>
            </app-info-text-popup>
          </div>
        </mat-radio-group>
      </div>
      <div class="check-group big-field">
        <p class="text-label" [ngClass]="font?.size">{{ pageContent?.fields.pensionModality | translate | titlecase }}</p>
        <mat-radio-group class="options custom-border" formControlName="pensionModality">
          <div class="option" [class.selected]="isChecked('pensionModality', pensionModality[0])" *ngFor="let pensionModality of pensionModalityOptions">
            <div class="left-radio-button">
              <mat-radio-button [value]="pensionModality[0]"></mat-radio-button>
            </div>
            <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pensionModality[1].title | translate | titlecase }}</div>
            <app-info-text-popup *ngIf="pensionModality[1].definitions" class="tooltip-icon" [definitions]="pensionModality[1].definitions" iconName="i-tooltip-blue">
              <div class="i-tooltip-blue"></div>
            </app-info-text-popup>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
</form>
