import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { environment } from '@environment';
import { VitaliaService } from '@services/vitalia/vitalia.service';


@Injectable({
  providedIn: 'root'
})
export class ChatbotRenderer {
  private renderer: Renderer2;
  private chatBotScriptElement: HTMLScriptElement;
  private chatbotIsAlive: Subject<boolean>;

  constructor(
    private router: Router,
    private vitaliaService: VitaliaService,
    /* tslint:disable-next-line */
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.chatbotIsAlive = new Subject<boolean>();
    this.setSubscriptions();
    this.chatbotIsAlive.next(false);
  }

  private setSubscriptions(): void {
    combineLatest([
      this.chatbotIsAlive,
      this.router.events,
    ]).subscribe(([isChatbotAlive, routerEvent]) => {
      if (isChatbotAlive && routerEvent instanceof NavigationStart) {
        this.vitaliaService.showVitaliaIcon(true);
        this.dismissChatbot();
      }
    });
  }

  public startInternationalPhoneCallChatbot(): void {
    this.initChatbot(environment.botmakerChatbotInternationalCall);
    this.vitaliaService.showVitaliaIcon(false);
  }

  public startVideoCallChatbot(): void {
    this.initChatbot(environment.botmakerChatbotVideoCall);
    this.vitaliaService.showVitaliaIcon(false);
  }

  public dismissChatbot(): void {
    this._document.querySelectorAll('iframe').forEach((iframe) => {
      const iframeName = iframe.attributes.getNamedItem('name') ?
        iframe.attributes.getNamedItem('name').value : '';
      if (iframeName === 'Botmaker') { iframe.parentElement.remove(); }
    });
    this.chatbotIsAlive.next(false);
  }

  private initChatbot(url: string): void {
    this.dismissChatbot();
    this.chatBotScriptElement = this.renderer.createElement('script');
    this.chatBotScriptElement.src = url;
    this.renderer.appendChild(this._document.body, this.chatBotScriptElement);
    this.chatbotIsAlive.next(true);
  }

}
