<div class="modal modal-container" *ngIf="loadData">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <form class="form-container" [formGroup]="form" (submit)="addStudiesCertificate()">
    <h1 class="title primary" [ngClass]="font?.size">{{ pageContent?.modalTitle | translate }}</h1>
    <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.formTitle | translate }}</h2>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.form.year | translate }}</mat-label>
        <mat-select formControlName="year" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let year of years" [value]="year">
            {{ year | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.form.period | translate }}</mat-label>
        <mat-select formControlName="period" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let period of periods" [value]="period.name">
            {{ period.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-section second">
      <div class="information-container">
        <app-disclaimer-box
          [description]= "pageContent?.disclaimer"
          [typeDisclaimer]="'alert'">
        </app-disclaimer-box>
      </div>
      <app-attach-files *ngIf="requestFiles"
        [attachFiles]="requiredFiles"
        [preloadedFiles]="studiesCertificate ? filesLoaded : null"
        (fileEmitter)="setFiles($event)">
      </app-attach-files>
    </div>

    <div class="button-container">
      <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="this.form.invalid">
        {{ buttonText | translate }}
      </ion-button>
    </div>
  </form>
</div>
