import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ACCOUNT_NUMBER_MAX_LENGTH } from '@constants/forms.constant';
import { DefaultResponseItem, PaymentMethods } from '@interfaces/general.interface';
import { PaymentMethodForm } from '@interfaces/payment-methods.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { ErrorUtils } from '@utils/error.utils';

@Component({
  selector: 'app-certify-studies-payment-method',
  templateUrl: './certify-studies-payment-method.component.html',
  styleUrls: ['./certify-studies-payment-method.component.scss'],
})
export class CertifyStudiesPaymentMethodComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public userRut;
  @Output() public goBack = new EventEmitter();
  @Output() public formData = new EventEmitter<any>();
  public maxLength = ACCOUNT_NUMBER_MAX_LENGTH;
  public form: UntypedFormGroup;
  public paymentData: PaymentMethods;
  public paymentMethods: Array<DefaultResponseItem>;
  public loadedPaymentMethods = false;
  public isTransfer: boolean;

  public getControl(control: string): AbstractControl { return this.form.get(control); }
  public get changePaymentMethod(): boolean {
    const changeValue = this.getControl('change').value;
    return changeValue && !JSON.parse(changeValue);
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private parametersService: ParametersService,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
    public font: FontService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.createForm();
    await this.loadData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.userRut) { this.userRut = changes.userRut.currentValue; }
  }

  public sendForm(): void {
    if (this.form.invalid) { return; }
    const { paymentMethodId } = this.form.value;
    let { accountNumber, bankId } = this.form.value;
    if (this.isTransfer) {
      accountNumber = Number(accountNumber);
      bankId = Number(bankId);
    } else {
      bankId = accountNumber = null;
    }
    const paymentMethod = {
      accountNumber,
      bankId,
      paymentMethodId: Number(paymentMethodId)
    } as PaymentMethodForm;

    this.formData.emit(paymentMethod);
  }

  public validateForm(form: UntypedFormGroup): void {
    this.isTransfer = !Boolean(form.get('otherAccountTypeId').value);
    this.updatePaymentMethodValidators(true);
    this.form.patchValue({
      accountNumber: form.get('accountNumber').value,
      bankId: form.get('bankId').value,
      paymentMethodId: this.isTransfer ? form.get('paymentMethodId').value : form.get('otherAccountTypeId').value,
    });
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      change: ['', [Validators.required]],
      accountNumber: [''],
      bankId: [''],
      paymentMethodId: [''],
    });
    // tslint:disable-next-line: deprecation
    this.getControl('change').valueChanges.subscribe(() => {
      this.updatePaymentMethodValidators(this.changePaymentMethod);
    });
  }

  private updatePaymentMethodValidators(add: boolean): void {
    let accountNumberValidators: Array<ValidatorFn> = null;
    let bankIdValidators: Array<ValidatorFn> = null;
    let paymentMethodIdValidators: Array<ValidatorFn> = null;
    if (add) {
      if (this.isTransfer) {
        accountNumberValidators = [Validators.required, Validators.maxLength(this.maxLength)];
        bankIdValidators = [Validators.required];
        paymentMethodIdValidators = [Validators.required];
      } else {
        paymentMethodIdValidators = [Validators.required];
      }
    } else {
      this.resetPaymentMethodValues();
    }
    this.updateControlValidators(this.getControl('accountNumber'), accountNumberValidators);
    this.updateControlValidators(this.getControl('bankId'), bankIdValidators);
    this.updateControlValidators(this.getControl('paymentMethodId'), paymentMethodIdValidators);
  }

  private resetPaymentMethodValues(): void {
    this.getControl('accountNumber').setValue('');
    this.getControl('bankId').setValue('');
    this.getControl('paymentMethodId').setValue('');
  }

  private updateControlValidators(control: AbstractControl, validator: Array<ValidatorFn>): void {
    control.setValidators(validator);
    control.updateValueAndValidity();
  }

  private async loadData(): Promise<void> {
    this.loadingService.showLoading();
    await Promise.all([
      this.parametersService.getPaymentMethodsAndBanks().toPromise(),
      this.parametersService.getPaymentMethods('pensionary-forms').toPromise(),
    ])
      .then(([paymentMethodsAndBanks, paymentMethods]: [PaymentMethods, Array<DefaultResponseItem>]) => {
        this.paymentData = paymentMethodsAndBanks;
        this.paymentMethods = paymentMethods;
        this.loadedPaymentMethods = true;
      })
      .catch((error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) }))
      .finally(() => this.loadingService.hideLoading());
  }
}
