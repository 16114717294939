import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { VIRTUAL_ACCOUNTS_PREFIXS } from '@constants/bank-accounts.constant';
import { Utils } from '@utils/utils';



export function virtualAccountValidator(accountNumberField: string, bankCodeField: string) {
  return (formGroup: UntypedFormGroup) => {
    const accountNumberControl = formGroup.controls[accountNumberField];
    const bankCode = formGroup.controls[bankCodeField].value;
    if (!Utils.isVirtualAccount(String(accountNumberControl.value), String(bankCode))) {
      return null; }
    accountNumberControl.setErrors({ virtualAccount: true });
    return null;
  };
}

export function VirtualAccountValidator(control: AbstractControl) {
    const isVirtualAccount = VIRTUAL_ACCOUNTS_PREFIXS.some(prefix => control.value.startsWith(prefix));
    return isVirtualAccount ? {forbiddenAccount: true } : null;
}
