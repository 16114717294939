import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_INFORMATION_OF_INTEREST_LINKS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              informacion_relevante: [
                {
                  titulo: 'Estatutos Sociales',
                  items: [
                    {
                      titulo: 'Estatutos AFP Planvital',
                      url: 'informacion-de-interes/estatutos-sociales/Estatutos-AFP-Planvital-S-A-(Abril-2020).pdf'
                    }
                  ]
                },
                {
                  año: '2020',
                  titulo: 'Junta de accionistas 2020',
                  items: [
                    {
                      titulo: 'Acta Trigésimo Octava Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Abril/30042020-ACTA-38%C2%BA-Jta-Ordinaria-Accionistas-firmada-por-todos.pdf'
                    },
                    {
                      titulo: 'Acta Vigésimo Sexta Junta Extraordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Abril/30042020-ACTA-26%C2%BA-Jta-Extraordinaria-Accionistas-firmada-por-todos.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Abril/30042020-AVISO-CITACION-14042020-EL-LIBERO.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Abril/15042020-PODER-(2020).pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Abril/30042020-ANTECEDENTES-ADICIONALES-MATERIAS-JUNTA-2020.pdf'
                    },
                    {
                      titulo: 'Candidatos a Director.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Abril/30042020-Candidatos%20a%20Director%20JOA%20y%20JEA%202020_DJ_CV%20(2).pdf'
                    },
                    {
                      titulo: 'Procedimiento para participación a distancia a Juntas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2020/Marzo/18032020-Procedimiento-Participacio%CC%81n-a-Distancia-JOA-JEA-2020-PLANVITAL%20VF.pdf'
                    }
                  ]
                },
                {
                  año: '2019',
                  titulo: 'Junta de accionistas 2019',
                  items: [
                    {
                      titulo: 'Acta Trigésimo Séptima Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2019/Abril/30042019-Acta-Trigesimo-Septima-JOA.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2019/Abril/15042019-CITACION.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2019/Abril/15042019-PODER(2019).pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2019/Abril/30042019-Antecedentes-adicionales-2019.pdf'
                    },
                    {
                      titulo: 'Candidatos a Director.',
                      url: 'informacion-de-interes/junta-de-accionistas-2019/Abril/30042019-Candidatos-2019.pdf'
                    }
                  ]
                },
                {
                  año: '2018',
                  titulo: 'Junta de accionistas 2018',
                  items: [
                    {
                      titulo: 'Acta Trigésimo Sexta Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2018/Abril/30402018-Trigesimo-Sexta-Junta-Ordinaria-de-Accionistas.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2018/Abril/15042018-AVISO-CITACION.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2018/Abril/15042018-PODER-(2018).pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2018/Abril/30042018-ANTECEDENTES-ADICIONALES-MATERIAS-JUNTA-2018.pdf'
                    },
                    {
                      titulo: 'Candidatos a Director.',
                      url: 'informacion-de-interes/junta-de-accionistas-2018/Abril/30042018-Candidatos-Director-JOA-2018.pdf'
                    }
                  ]
                },
                {
                  año: '2017',
                  titulo: 'Junta de accionistas 2017',
                  items: [
                    {
                      titulo: 'Acta Vigésimo Quinta Junta Extraordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Mayo/31052017-Vige%CC%81sima-Quinta-Junta-Extraordinaria-de-Accionistas-31-05-2017.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Mayo/15052017-AVISO-CITACION-12-05-2017.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Mayo/31052017-PODER.pdf'
                    },
                    {
                      titulo: 'Candidatos a Director.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Mayo/31052017-CANDIDATOS-A-DIRECTOR-MAYO-2017.pdf'
                    },
                    {
                      titulo: 'Acta Trigésimo Quinta Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Abril/27042017-TrigesimaQuinta-Junta-Ordinaria-Accionistas-2017.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Abril/15042017-AVISO-CITACION-2017.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Abril/15042017-PODER-2017.pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Abril/27042017-ANTECEDENTES-JUNTAS-2017.pdf'
                    },
                    {
                      titulo: 'Candidatos a Director.',
                      url: 'informacion-de-interes/junta-de-accionistas-2017/Abril/27042017-CANDIDATOS-A-DIRECTOR-ABRIL.pdf'
                    }
                  ]
                },
                {
                  año: '2016',
                  titulo: 'Junta de accionistas 2016',
                  items: [
                    {
                      titulo: 'Acta Trigésima Cuarta Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2016/Abril/28042016-Trigesima-Junta-Ordinaria-Accionista-2016.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2016/Abril/15042016-Citacion-2016.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2016/Abril/28042016-Poder-2016.pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2016/Abril/28042016-Antecedentes-Adicionales-Materias-Juntas-2016.pdf'
                    }
                  ]
                },
                {
                  año: '2015',
                  titulo: 'Junta de accionistas 2015',
                  items: [
                    {
                      titulo: 'Acta Trigésimo Tercera Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2015/Abril/30042015-Acta-33-JOA-2015.pdf'
                    },
                    {
                      titulo: 'Acta Vigésimo Cuarta Junta Extraordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2015/Abril/30042015-Acta-24-JEA-2015.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2015/Abril/15042015-Citacion-2015.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2015/Abril/15042015-Poder-2015.pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2015/Abril/14042015-Antecedentes-Adicionales-Materias-Juntas-2015.pdf'
                    }
                  ]
                },
                {
                  año: '2014',
                  titulo: 'Junta de accionistas 2014',
                  items: [
                    {
                      titulo: 'Acta Trigésimo Segunda Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2014/Abril/30042014-32-junta-Ordinaria-2014.pdf'
                    },
                    {
                      titulo: 'Acta Vigésimo Tercera Junta Extraordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2014/Abril/30042014-23-Junta-Extraordinaria-2014.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2014/Abril/14042014-Citacion-2014.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2014/Abril/14042014-Poder-2014.pdf'
                    },
                    {
                      titulo: 'Antecedentes.',
                      url: 'informacion-de-interes/junta-de-accionistas-2014/Abril/11042014-Antecedentes-Adicionales-Materias-Juntas-2014.pdf'
                    }
                  ]
                },
                {
                  año: '2013',
                  titulo: 'Junta de accionistas 2013',
                  items: [
                    {
                      titulo: 'Acta Trigésimo Primera Junta Ordinaria de Accionistas.',
                      url: 'informacion-de-interes/junta-de-accionistas-2013/Abril/30042013-Junta-Ordinaria-abril-2013.pdf'
                    },
                    {
                      titulo: 'Citación.',
                      url: 'informacion-de-interes/junta-de-accionistas-2013/Abril/15042013-Citacion.pdf'
                    },
                    {
                      titulo: 'Poder.',
                      url: 'informacion-de-interes/junta-de-accionistas-2013/Abril/30042013-Poder-Junta.pdf'
                    },
                    {
                      titulo: 'Propuesta de Auditores Externos.',
                      url: 'informacion-de-interes/junta-de-accionistas-2013/Abril/15042013-Propuesta-Auditores-Externos-2013.pdf'
                    },
                    {
                      titulo: 'Candidatos a Director.',
                      url: 'informacion-de-interes/junta-de-accionistas-2013/Abril/30042013-CANDIDATOS-A-DIRECTOR.pdf'
                    }
                  ]
                },
                {
                  titulo: 'Repartos de Capital y/o Utilidades',
                  items: [
                    {
                      fecha: '2020',
                      titulo: 'Dividendo definitivo 2020',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2020/30042020-AVISO-Reparto-Dividendo-01-06-2020(vfinal)-El-libero.pdf'
                    },
                    {
                      fecha: '2019',
                      titulo: 'Dividendo definitivo 2019',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2019/30042019-AVISO-Reparto-Dividendo-31-05-2019-(vfinal)-EL-LIBERO.pdf'
                    },
                    {
                      fecha: '2018',
                      titulo: 'Dividendo definitivo 2018',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2018/30042019-Dividendo-definitivo-2018.pdf'
                    },
                    {
                      fecha: '2017',
                      titulo: 'Dividendo definitivo 2017',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2017/30042018-Dividendo-Definitivo-2017.pdf'
                    },
                    {
                      fecha: '2016',
                      titulo: 'Dividendo definitivo 2016',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2017/27042017-Aviso-Reparto-Dividendo.pdf'
                    },
                    {
                      fecha: '2014',
                      titulo: 'Dividendo definitivo 2014',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2014/30042015-Dividendo-Definitivo-2014.pdf'
                    },
                    {
                      fecha: '2013',
                      titulo: 'Dividendo definitivo 2013',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2013/30042014-Dividendo-Definitivo-2013.pdf'
                    },
                    {
                      fecha: '2012',
                      titulo: 'Dividendo definitivo eventual 2012',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2012/31072013-Dividendo-Definitivo-Eventual-2012.pdf'
                    },
                    {
                      fecha: '2012',
                      titulo: 'Dividendo definitivo adicional 2012',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2012/13052013-Definitivo-Adicional-2012.pdf'
                    },
                    {
                      fecha: '2012',
                      titulo: 'Dividendo provisorio 2012',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2012/04122012-Provisorio-2012.pdf'
                    },
                    {
                      fecha: '2011',
                      titulo: 'Dividendo definitivo adicional 2011',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2011/11052012-Definitivo-Adicional-2011.pdf'
                    },
                    {
                      fecha: '2011',
                      titulo: 'Dividendo provisorio 2011',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2011/05122011-Provisorio-2011.pdf'
                    },
                    {
                      fecha: '2010',
                      titulo: 'Dividendo definitivo 2010',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2010/04042011-Definitivo-2010.pdf'
                    },
                    {
                      fecha: '2010',
                      titulo: 'Devolución de capital 2010',
                      url: 'informacion-de-interes/repartos-de-capital-utilidades/2010/29092010-Devolucion-de-capital-2010.pdf'
                    }
                  ]
                },
                {
                  titulo: 'Otros',
                  items: [
                    {
                      titulo: 'Información de Enersis y Endesa',
                      url: 'informacion-de-interes/otros/informacion-enersis-endesa.pdf'
                    },
                    {
                      titulo: 'Caso sociedad Cascada SQM',
                      url: 'informacion-de-interes/otros/caso-cascada.pdf'
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
