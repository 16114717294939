import { Component, Input } from '@angular/core';
import { FundHelpPopoverComponent } from '@components/fund-help-popover/fund-help-popover.component';
import { FundInfo } from '@interfaces/multi-funds.interface';
import { FontService } from '@providers/font/font.service';
import { PopoverService } from '@providers/popover/popover.service';

@Component({
  selector: 'app-fund-infographic',
  templateUrl: './fund-infographic.component.html',
  styleUrls: ['./fund-infographic.component.scss'],
})
export class FundInfographicComponent {
  @Input() public fund: FundInfo;

  constructor(
    public font: FontService,
    private popoverService: PopoverService,
  ) { }

  public backgroundClass(suffix: string) {
    const { name } = this.fund;
    return name ? `fund-${name.toLowerCase()}-${suffix}` : '';
  }

  public openHelp(event) {
    this.popoverService.presentPopover(FundHelpPopoverComponent, { fund: this.fund }, event);
  }
}
