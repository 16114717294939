import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { Element } from '@interfaces/general.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { GO_BACK } from '@constants/pages-content/general.constant';

@Component({
  selector: 'app-modal-filter-chips',
  templateUrl: './modal-filter-chips.component.html',
  styleUrls: ['./modal-filter-chips.component.scss'],
})
export class ModalFilterChipsComponent extends ModalComponent {
  public title: string;
  public filters: Array<Element>;
  public backButton = GO_BACK;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.title = this.params.get('title');
    this.filters = this.params.get('filters');
  }

  public goToSection(id: string) {
    this.closeModal(id);
  }

}
