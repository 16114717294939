import { formatCurrency } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ChartQuotaValuesComponent
} from '@components/chart-quota-values/chart-quota-values.component';
import {
  ModalSummaryQuotaValuesComponent
} from '@components/modal-summary-quota-values/modal-summary-quota-values.component';
import { MONTHS_ES } from '@constants/months.constant';
import {
  BREAKPOINT_INFORMATION, CHART_COMPARISON_PERIOD, EMPTY_VALUE, EMPTY_VALUE_FUNDS, FUNDS_NAMES
} from '@constants/quota-values.constant';
import { ChartQuotaValuesParams } from '@interfaces/chart-quota-values.interface';
import { QuotaValuesBannerContent } from '@interfaces/quota-values-banner.interface';
import {
  ChartDataQuotaValues, ModalDataQuotaValues, QuotaValue, QuotaValueFund
} from '@interfaces/quota-values.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { PopoverService } from '@providers/popover/popover.service';
import { ScreenSizeService } from '@providers/screen-size/screen-size.service';
import {
  FinancialIndicatorsService
} from '@services/financial-indicators/financial-indicators.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-quota-values',
  templateUrl: './quota-values.component.html',
  styleUrls: ['./quota-values.component.scss'],
})
export class QuotaValuesComponent {
  @Input() public fundLabel: string;
  @Input() public chartTitle: string;
  @Input() public simplified = false;
  @Input() public content: QuotaValuesBannerContent;
  @Input() public loading = false;

  public quotaValues: Array<QuotaValue>;
  public funds = FUNDS_NAMES;
  public delta: QuotaValueFund = EMPTY_VALUE_FUNDS;
  public modalDataQuotaValues: ModalDataQuotaValues = { fundA: [], fundB: [], fundC: [], fundD: [], fundE: [] };
  public chartDataQuotaValues: ChartDataQuotaValues = { fundA: [], fundB: [], fundC: [], fundD: [], fundE: [], labels: [] };
  public date = new Date();

  constructor(
    public font: FontService,
    private financialIndicatorsService: FinancialIndicatorsService,
    private popoverService: PopoverService,
    private modalService: ModalService,
    private screenSize: ScreenSizeService,
    private utils: Utils,
  ) {
    this.getNormativeQuotaValues();
    this.createChartData();
  }

  public translatedCurrentDate(language = 'es'): string {
    return Utils.getTranslatedDate(language, new Date(this.currentQuotaValue.date));
  }

  async getNormativeQuotaValues() {
    const date = await this.getMaterializationDate();
    this.financialIndicatorsService.getNormativeQuotaValues(date)
      .subscribe(
        (response) => {
          this.quotaValues = response.quotaValues.length ? response.quotaValues : EMPTY_VALUE;
          this.calculateDelta(this.previousQuotaValue, this.currentQuotaValue);
        },
      );
  }

  async getMaterializationDate(): Promise<string> {
    const materializationDate = await this.financialIndicatorsService.materializationDate().toPromise();
    return materializationDate;
  }


  private createChartData() {
    const startDate = new Date(new Date().setDate(this.date.getDate() - CHART_COMPARISON_PERIOD));
    this.financialIndicatorsService.getQuotaValues(startDate, this.date)
      .subscribe(
        (response) => {
          this.chartDataQuotaValues = { fundA: [], fundB: [], fundC: [], fundD: [], fundE: [], labels: [] };
          this.modalDataQuotaValues = { fundA: [], fundB: [], fundC: [], fundD: [], fundE: [] };
          response.quotaValues.forEach((quota) => {
            const date = new Date(quota.date);
            this.funds.forEach((fund) => this.chartDataQuotaValues[`fund${fund}`].push(quota[`fund${fund}`]));
            this.chartDataQuotaValues.labels.push(date.toISOString());

            if (this.utils.isLastDayOfMonth(date)) {
              const dateFormatted = `${MONTHS_ES[date.getMonth()]} ${date.getFullYear()}`;
              this.funds.forEach((fund) => {
                const value = { date: dateFormatted, value: quota[`fund${fund}`] };
                this.modalDataQuotaValues[`fund${fund}`].push(value);
              });
            }
          });
        },
      );
  }

  public get currentQuotaValue(): QuotaValue {
    return this.quotaValues ? this.quotaValues[this.quotaValues.length - 1] : { ...EMPTY_VALUE_FUNDS, date: new Date().toISOString() };
  }

  public get previousQuotaValue(): QuotaValue {
    if (!this.quotaValues || this.quotaValues.length === 1) { return { ...EMPTY_VALUE_FUNDS, date: new Date().toISOString() }; }
    const quotaValues = [...this.quotaValues];
    return quotaValues.reverse().find((quota) =>
      quota.fundA !== this.currentQuotaValue.fundA ||
      quota.fundB !== this.currentQuotaValue.fundB ||
      quota.fundC !== this.currentQuotaValue.fundC ||
      quota.fundD !== this.currentQuotaValue.fundD ||
      quota.fundE !== this.currentQuotaValue.fundE
    );
  }

  private calculateDelta(previousQuota: QuotaValue, currentQuota: QuotaValue) {
    this.funds.forEach((fund) => this.delta[`fund${fund}`] = currentQuota[`fund${fund}`] - previousQuota[`fund${fund}`]);
  }

  public showDetail(fund: string, event) {
    const isEmpty = this.modalDataQuotaValues[`fund${fund}`].length === 0 || this.chartDataQuotaValues[`fund${fund}`].length === 0;
    if (this.simplified || isEmpty) { return; }
    this.screenSize.width > BREAKPOINT_INFORMATION ? this.openChart(fund, event) : this.openModal(fund);
  }

  private openChart(fund: string, event) {
    const series = [this.chartDataQuotaValues[`fund${fund}`]];
    const { labels } = this.chartDataQuotaValues;
    const params: ChartQuotaValuesParams = {
      chartData: { series, labels },
      title: this.chartTitle,
      type: 'chart-popover',
      showTooltip: false,
      height: 300,
      ylabelInterpolationFnc: (value) => formatCurrency(value, 'es_CL', '$', 'CLP', '1.0-0'),
      xlabelInterpolationFnc: (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate()} ${Utils.getMonthTranslate(date.getMonth().toString())}`;
      },
    };
    return this.popoverService.presentPopover(ChartQuotaValuesComponent, params, event, 'auto', 'popover-valuesquote');
  }

  private openModal(fund: string) {
    const params = { values: this.modalDataQuotaValues[`fund${fund}`], title: this.chartTitle };
    this.modalService.openModal(ModalSummaryQuotaValuesComponent, params);
  }
}
