import { Component } from '@angular/core';
import { PopoverComponent } from '@components/popover/popover.component';
import { NavParams, PopoverController } from '@ionic/angular';
import { FundInfo } from '@interfaces/multi-funds.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-fund-help-popover',
  templateUrl: './fund-help-popover.component.html',
  styleUrls: ['./fund-help-popover.component.scss'],
})
export class FundHelpPopoverComponent extends PopoverComponent {
  public fund: FundInfo;
  constructor(
    public font: FontService,
    private params: NavParams,
    protected popoverCtrl: PopoverController,
  ) {
    super(popoverCtrl);
    this.fund = this.params.get('fund');
  }
}
