import { environment } from '@environment';
import { Banner } from '@interfaces/banner.interface';
import { AlertBanner, Article, GeneralInfo, Link } from '@interfaces/components.interface';
import { Infographic } from '@interfaces/infographic.interface';
import { Mockup } from '@interfaces/mockup.interface';
import { Product } from '@interfaces/product.interface';

// tslint:disable:max-line-length
export const GET_BANNERS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [{
        id: 5,
        imagen: {
          filename_disk: `1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
        },
        imagen_mobile: {
          filename_disk: '1850f3b1-1b06-4db4-938b-93abf66b9937.jpg',
        },
        icono: 'save',
        url: 'url',
        traducciones: [{
          id: 1,
          identifier: null,
          languages_code: 'es',
          banner: 5,
          alt_imagen: 'alt',
          primera_linea: '<p>Si tu plan es <strong>ahorrar</strong></p>',
          segunda_linea: '<p><strong>Te podemos ayudar</strong></p>',
          boton: 'Descubre cómo',
          title_image: '',
        }]
      }],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const BANNERS_MOCKUP: Array<Banner> = [{
  id: 5,
  image: {
    url: `${environment.cmsApiUrl}assets/1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
    alt: 'alt',
    title: '',
  },
  image_mobile: {
    url: `${environment.cmsApiUrl}assets/1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
    alt: 'alt',
    title: '',
  },
  icon: 'save',
  url: 'url',
  firstLine: '<p>Si tu plan es <strong>ahorrar</strong></p>',
  secondLine: '<p><strong>Te podemos ayudar</strong></p>',
  button: 'Descubre cómo'
}];

export const BANNER: Banner = {
  id: 5,
  image: {
    url: `${environment.cmsApiUrl}assets/1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
    alt: 'alt',
    title: '',
  },
  image_mobile: {
    url: `${environment.cmsApiUrl}assets/1850f3b1-1b06-4db4-938b-93abf66b9937.jpg`,
    alt: 'alt',
    title: '',
  },
  icon: 'save',
  url: 'url',
  firstLine: '<p>Si tu plan es <strong>ahorrar</strong></p>',
  secondLine: '<p><strong>Te podemos ayudar</strong></p>',
  button: 'Descubre cómo'
};

export const GET_BANNERS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 5,
          imagen: null,
          imagen_mobile: null,
          icono: 'save',
          url: 'url',
          traducciones: []
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const BANNERS_NULL_MOCKUP: Array<Banner> = [
  {
    id: 5,
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'save',
    url: 'url',
    firstLine: null,
    secondLine: null,
    button: null
  }
];

export const GET_PAYMENT_CENTERS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          imagen: {
            data: {
              full_url: 'https://storage.googleapis.com/pv-directus-dev/sitio-publico/originals/52693ba8-f94e-4baf-8bc2-6836771807be.jpg',
              url: 'https://storage.googleapis.com/pv-directus-dev/sitio-publico/originals/52693ba8-f94e-4baf-8bc2-6836771807be.jpg',
              embed: null
            }
          },
          url: 'https://servipag.cl/',
          traducciones: [
            {
              id: 1,
              alt_imagen: 'alt',
              boton: 'Ir a Servipag',
              idioma: 'es',
              centro_pago: 1,
              titulo: 'Centros de Pago de Pensiones Servipag'
            }
          ]
        },
        {
          id: 2,
          imagen: {
            data: {
              full_url: 'https://storage.googleapis.com/pv-directus-dev/sitio-publico/originals/d1a1e2d2-32ac-4404-bbc3-fe88233dc797.jpg',
              url: 'https://storage.googleapis.com/pv-directus-dev/sitio-publico/originals/d1a1e2d2-32ac-4404-bbc3-fe88233dc797.jpg',
              embed: null
            }
          },
          url: 'https://www.bancoestado.cl/',
          traducciones: [
            {
              id: 2,
              alt_imagen: 'alt',
              boton: 'Ir a Banco Estado',
              idioma: 'es',
              centro_pago: 2,
              titulo: 'Centros de Pago de Pensiones Banco Estado y depósitos bancarios'
            }
          ]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_CALENDARS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          fechas: {
            january: [17, 20],
            february: [18, 19],
            march: [19, 20],
            april: [17, 20],
            may: [19, 20],
            june: [18, 19],
            july: [17, 20],
            august: [18, 19],
            september: [15, 16],
            october: [16, 19],
            november: [18, 19],
            december: [17, 18]
          },
          titulo: 'servipagCalendar'
        },
        {
          id: 2,
          fechas: {
            january: [17, 20],
            february: [18, 19],
            march: [19, 20],
            april: [17, 20],
            may: [19, 20],
            june: [18, 19],
            july: [17, 20],
            august: [18, 19],
            september: [15, 16],
            october: [16, 19],
            november: [18, 19],
            december: [17, 18]
          },
          titulo: 'bankCalendar'
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_ARTICLES_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          sort: null,
          identificador: 'APV',
          url: 'afiliado/ahorros/previsional-voluntario',
          imagen: {
            filename_disk: 'e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg',
          },
          traducciones: [{
            languages_code: 'es',
            titulo: 'Ahorro Previsional Voluntario APV',
            alt_imagen: 'alt',
            title_image: ''
          }]
        },
        {
          id: 2,
          sort: null,
          identificador: 'APS',
          url: 'pensionado/beneficios/pension-solidaria',
          imagen: {
            filename_disk: 'e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg',
          },
          traducciones: [{
            languages_code: 'es',
            titulo: 'Beneficios Aporte Solidario APS',
            alt_imagen: 'alt',
            title_image: ''
          }]
        },
        {
          id: 3,
          sort: null,
          identificador: 'Cuenta 2',
          url: 'afiliado/ahorros/cuenta-2',
          imagen: {
            filename_disk: 'e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg',
          },
          traducciones: [{
            languages_code: 'es',
            titulo: 'Ahorra con tu Cuenta 2',
            alt_imagen: 'alt',
            title_image: ''
          }]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ARTICLES_MOCKUP: Array<Article> = [
  {
    id: 1,
    identifier: 'APV',
    image: {
      url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
      alt: 'alt',
      title: ''
    },
    title: 'Ahorro Previsional Voluntario APV',
    url: 'afiliado/ahorros/previsional-voluntario',
  },
  {
    id: 2,
    identifier: 'APS',
    image: {
      url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
      alt: 'alt',
      title: ''
    },
    title: 'Beneficios Aporte Solidario APS',
    url: 'pensionado/beneficios/pension-solidaria',
  },
  {
    id: 3,
    identifier: 'Cuenta 2',
    image: {
      url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
      alt: 'alt',
      title: ''
    },
    title: 'Ahorra con tu Cuenta 2',
    url: 'afiliado/ahorros/cuenta-2',
  }
];

export const GET_ARTICLES_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          url: null,
          identificador: null,
          imagen: null,
          traducciones: [],
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ARTICLES_NULL_MOCKUP: Array<Article> = [
  {
    id: 1,
    identifier: null,
    image: {
      url: null,
      alt: null,
      title: null
    },
    title: null,
    url: null,
  }
];

export const GUIA_VITAL_NULL_MOCKUP: Array<Article> = [
  {
    id: 1,
    identifier: null,
    image: {
      url: null,
      alt: null,
      title: ''
    },
    title: null,
    url: null,
  }
];

export const GET_LINKS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          url: 'url',
          traducciones: [
            {
              languages_code: 'es',
              titulo: 'Mapa del Sitio'
            }
          ]
        },
        {
          id: 2,
          url: 'pens',
          traducciones: [
            {
              languages_code: 'es',
              titulo: 'Asociación AFP Chile'
            }
          ]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const LINKS_MOCKUP: Array<Link> = [
  {
    id: 1,
    title: 'Mapa del Sitio',
    url: 'url',
    externo: false
  },
  {
    id: 2,
    title: 'Asociación AFP Chile',
    url: 'pens',
    externo: false
  }
];

export const GET_LINKS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          url: 'url',
          traducciones: []
        },
        {
          id: 2,
          url: 'pens',
          traducciones: []
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const LINKS_NULL_MOCKUP: Array<Link> = [
  {
    id: 1,
    title: null,
    url: 'url',
    externo: false
  },
  {
    id: 2,
    title: null,
    url: 'pens',
    externo: false
  }
];

export const GET_INFOGRAPHICS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          icono: 'lupa',
          url_modal: null,
          imagen_modal: null,
          traducciones: [
            {
              id: 1,
              titulo: 'title',
              descripcion: null,
              languages_code: 'es',
              infografia: 1,
              mostrar_mas: null,
              titulo_modal: null,
              descripcion_modal: null,
              boton_modal: null,
              texto_inferior_modal: null,
              alt_imagen_modal: null,
            }
          ]
        },
        {
          id: 2,
          icono: 'client',
          url_modal: null,
          imagen_modal: null,
          traducciones: [
            {
              id: 2,
              titulo: 'title',
              descripcion: null,
              languages_code: 'es',
              infografia: 2,
              mostrar_mas: null,
              titulo_modal: null,
              descripcion_modal: null,
              boton_modal: null,
              texto_inferior_modal: null,
              alt_imagen_modal: null,
            }
          ]
        },
        {
          id: 3,
          icono: 'support',
          url_modal: null,
          imagen_modal: null,
          traducciones: [
            {
              id: 3,
              titulo: 'title',
              descripcion: null,
              languages_code: 'es',
              infografia: 3,
              mostrar_mas: null,
              titulo_modal: null,
              descripcion_modal: null,
              boton_modal: null,
              texto_inferior_modal: null,
              alt_imagen_modal: null,
            }
          ]
        },
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const INFOGRAPHICS_MOCKUP: Array<Infographic> = [
  {
    id: 1,
    icon: 'lupa',
    title: 'title',
    description: null,
    showMoreButton: null,
    modal: {
      title: null,
      description: null,
      image: {
        url: null,
        alt: null,
        title: null
      },
      disclaimer: null,
      disclaimerButton: null,
      disclaimerUrl: null,
    },
  },
  {
    id: 2,
    icon: 'client',
    title: 'title',
    description: null,
    showMoreButton: null,
    modal: {
      title: null,
      description: null,
      image: {
        url: null,
        alt: null,
        title: null
      },
      disclaimer: null,
      disclaimerButton: null,
      disclaimerUrl: null,
    },
  },
  {
    id: 3,
    icon: 'support',
    title: 'title',
    description: null,
    showMoreButton: null,
    modal: {
      title: null,
      description: null,
      image: {
        url: null,
        alt: null,
        title: null
      },
      disclaimer: null,
      disclaimerButton: null,
      disclaimerUrl: null,
    },
  }
];

export const GET_INFOGRAPHICS_EXTENDED_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          icono: 'lupa',
          url_modal: 'url',
          imagen_modal: {
            data: {
              full_url: `${environment.cmsApiUrl}e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
              url: '/uploads/sitio-publico/originals/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg',
              embed: null
            }
          },
          traducciones: [
            {
              id: 1,
              titulo: '<p>La mejor <strong>comisi&oacute;n anual</strong></p>',
              descripcion: '<p><strong>Sobre saldo deAPV&nbsp;</strong>en porcentaje Marzo 2020</p>',
              idioma: 'es',
              infografia: 1,
              mostrar_mas: 'Más información',
              titulo_modal: '',
              descripcion_modal: '',
              boton_modal: '',
              texto_inferior_modal: '',
              alt_imagen_modal: 'alt'
            }
          ]
        },
        {
          id: 2,
          icono: 'atencion',
          url_modal: 'url',
          imagen_modal: {
            data: {
              full_url: `${environment.cmsApiUrl}e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
              url: '/uploads/sitio-publico/originals/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg',
              embed: null
            }
          },
          traducciones: [
            {
              id: 2,
              titulo: '<p>Menos&nbsp;<strong>tiempo de espera</strong></p>',
              descripcion: '<p>Somos de las mejores AFP en tiempos de respuesta por nuestros medios de contacto.</p>',
              idioma: 'es',
              infografia: 2,
              mostrar_mas: 'Más información',
              titulo_modal: '',
              descripcion_modal: '',
              boton_modal: '',
              texto_inferior_modal: '',
              alt_imagen_modal: 'alt'
            }
          ]
        },
        {
          id: 3,
          icono: 'cercano',
          url_modal: 'url',
          imagen_modal: {
            data: {
              full_url: `${environment.cmsApiUrl}e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
              url: '/uploads/sitio-publico/originals/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg',
              embed: null
            }
          },
          traducciones: [
            {
              id: 3,
              titulo: '<p><strong>Atenci&oacute;n cercana</strong> en todo Chile</p>',
              descripcion: '<p>Solucionamos todos tus tr&aacute;mites previsionales, de forma r&aacute;pida y segura en nuestras sucursales.</p>',
              idioma: 'es',
              infografia: 3,
              mostrar_mas: 'Más información',
              titulo_modal: '',
              descripcion_modal: '',
              boton_modal: '',
              texto_inferior_modal: '',
              alt_imagen_modal: 'alt'
            }
          ]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const INFOGRAPHICS_EXTENDED_MOCKUP: Array<Infographic> = [
  {
    id: 1,
    icon: 'lupa',
    title: '<p>La mejor <strong>comisi&oacute;n anual</strong></p>',
    description: '<p><strong>Sobre saldo deAPV&nbsp;</strong>en porcentaje Marzo 2020</p>',
    showMoreButton: 'Más información',
    modal: {
      title: '',
      description: '',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      disclaimer: '',
      disclaimerButton: '',
      disclaimerUrl: 'url',
    }
  },
  {
    id: 2,
    icon: 'atencion',
    title: '<p>Menos&nbsp;<strong>tiempo de espera</strong></p>',
    description: '<p>Somos de las mejores AFP en tiempos de respuesta por nuestros medios de contacto.</p>',
    showMoreButton: 'Más información',
    modal: {
      title: '',
      description: '',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      disclaimer: '',
      disclaimerButton: '',
      disclaimerUrl: 'url',
    }
  },
  {
    id: 3,
    icon: 'cercano',
    title: '<p><strong>Atenci&oacute;n cercana</strong> en todo Chile</p>',
    description: '<p>Solucionamos todos tus tr&aacute;mites previsionales, de forma r&aacute;pida y segura en nuestras sucursales.</p>',
    showMoreButton: 'Más información',
    modal: {
      title: '',
      description: '',
      image: {
        url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
        alt: 'alt',
        title: ''
      },
      disclaimer: '',
      disclaimerButton: '',
      disclaimerUrl: 'url',
    }
  }
];

export const GET_INFOGRAPHICS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          icono: 'lupa',
          url_modal: 'url',
          imagen_modal: null,
          traducciones: []
        },
        {
          id: 2,
          icono: 'atencion',
          url_modal: 'url',
          imagen_modal: null,
          traducciones: []
        },
        {
          id: 3,
          icono: 'cercano',
          url_modal: 'url',
          imagen_modal: null,
          traducciones: []
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const INFOGRAPHICS_NULL_MOCKUP: Array<Infographic> = [
  {
    id: 1,
    icon: 'lupa',
    title: null,
    description: null,
    showMoreButton: null,
    modal: {
      title: null,
      description: null,
      image: {
        url: null,
        alt: null,
        title: null
      },
      disclaimer: null,
      disclaimerButton: null,
      disclaimerUrl: 'url',
    }
  },
  {
    id: 2,
    icon: 'atencion',
    title: null,
    description: null,
    showMoreButton: null,
    modal: {
      title: null,
      description: null,
      image: {
        url: null,
        alt: null,
        title: null
      },
      disclaimer: null,
      disclaimerButton: null,
      disclaimerUrl: 'url',
    }
  },
  {
    id: 3,
    icon: 'cercano',
    title: null,
    description: null,
    showMoreButton: null,
    modal: {
      title: null,
      description: null,
      image: {
        url: null,
        alt: null,
        title: null
      },
      disclaimer: null,
      disclaimerButton: null,
      disclaimerUrl: 'url',
    }
  }
];

export const GET_PRODUCTS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          icono: 'icono',
          url_producto: null,
          identificador: 'Ahorro obligatorio',
          traducciones: [
            {
              id: 1,
              status: 'draft',
              owner: 1,
              created_on: '2020-04-22 20:13:49',
              producto: 1,
              languages_code: 'es',
              titulo: 'Ahorro obligatorio',
              descripcion: '<ul>\n<li>Ahorras para tu <strong>futura pensi&oacute;n</strong>.</li>\n<li>\n<p>Seguros de <strong>Cesant&iacute;a, Invalidez y Sobrevivencia.</strong></p>\n</li>\n<li>\n<p><strong>Tus ahorros est&aacute;n seguro</strong>s, son inembargables e independientes.</p>\n</li>\n</ul>',
              texto_boton: 'Más beneficios'
            }
          ]
        },
        {
          id: 2,
          icono: 'apv',
          url_producto: null,
          identificador: 'APV',
          traducciones: [
            {
              id: 2,
              status: 'draft',
              owner: 1,
              created_on: '2020-04-23 21:36:57',
              producto: 2,
              languages_code: 'es',
              titulo: 'Ahorro Previsional Voluntario APV',
              descripcion: '<ul>\n<li>\n<p>Permite alcanzar tu <strong>pensi&oacute;n deseada.</strong></p>\n</li>\n<li>\n<p>Puedes <strong>retirar el ahorro</strong> si lo necesitas.</p>\n</li>\n<li>\n<p>Puedes diversificar tu ahorro en <strong>hasta 2 multifondos.</strong></p>\n</li>\n</ul>',
              texto_boton: 'Más beneficios'
            }
          ]
        },
        {
          id: 3,
          icono: 'cuenta2',
          url_producto: null,
          identificador: 'Cuenta 2',
          traducciones: [
            {
              id: 3,
              status: 'draft',
              owner: 1,
              created_on: '2020-04-23 21:38:03',
              producto: 3,
              languages_code: 'es',
              titulo: 'Cuenta de Ahorro Voluntario Cuenta 2',
              descripcion: '<ul>\n<li>\n<p>Costo de administraci&oacute;n de<strong> 0,95% anual</strong> de tu ahorro.</p>\n</li>\n<li>\n<p>Puedes realizar <strong>24 giros al a&ntilde;o</strong>, sin costo.</p>\n</li>\n<li>\n<p>No existe <strong>monto m&iacute;nimo</strong> de ahorro.</p>\n</li>\n</ul>',
              texto_boton: 'Más beneficios'
            }
          ]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const PRODUCTS_MOCKUP: Array<Product> = [
  {
    id: 1,
    identifier: 'Ahorro obligatorio',
    icon: 'icono',
    productUrl: null,
    title: 'Ahorro obligatorio',
    description: '<ul>\n<li>Ahorras para tu <strong>futura pensi&oacute;n</strong>.</li>\n<li>\n<p>Seguros de <strong>Cesant&iacute;a, Invalidez y Sobrevivencia.</strong></p>\n</li>\n<li>\n<p><strong>Tus ahorros est&aacute;n seguro</strong>s, son inembargables e independientes.</p>\n</li>\n</ul>',
    textButton: 'Más beneficios'
  },
  {
    id: 2,
    identifier: 'APV',
    icon: 'apv',
    productUrl: null,
    title: 'Ahorro Previsional Voluntario APV',
    description: '<ul>\n<li>\n<p>Permite alcanzar tu <strong>pensi&oacute;n deseada.</strong></p>\n</li>\n<li>\n<p>Puedes <strong>retirar el ahorro</strong> si lo necesitas.</p>\n</li>\n<li>\n<p>Puedes diversificar tu ahorro en <strong>hasta 2 multifondos.</strong></p>\n</li>\n</ul>',
    textButton: 'Más beneficios'
  },
  {
    id: 3,
    identifier: 'Cuenta 2',
    icon: 'cuenta2',
    productUrl: null,
    title: 'Cuenta de Ahorro Voluntario Cuenta 2',
    description: '<ul>\n<li>\n<p>Costo de administraci&oacute;n de<strong> 0,95% anual</strong> de tu ahorro.</p>\n</li>\n<li>\n<p>Puedes realizar <strong>24 giros al a&ntilde;o</strong>, sin costo.</p>\n</li>\n<li>\n<p>No existe <strong>monto m&iacute;nimo</strong> de ahorro.</p>\n</li>\n</ul>',
    textButton: 'Más beneficios'
  }
];

export const GET_PRODUCTS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          icono: 'icono',
          url_producto: null,
          identificador: 'Ahorro obligatorio',
          traducciones: []
        },
        {
          id: 2,
          icono: 'apv',
          url_producto: null,
          identificador: 'APV',
          traducciones: []
        },
        {
          id: 3,
          icono: 'cuenta2',
          url_producto: null,
          identificador: 'Cuenta 2',
          traducciones: []
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const PRODUCTS_NULL_MOCKUP: Array<Product> = [
  {
    id: 1,
    identifier: 'Ahorro obligatorio',
    icon: 'icono',
    productUrl: null,
    title: null,
    description: null,
    textButton: null,
  },
  {
    id: 2,
    identifier: 'APV',
    icon: 'apv',
    productUrl: null,
    title: null,
    description: null,
    textButton: null,
  },
  {
    id: 3,
    identifier: 'Cuenta 2',
    icon: 'cuenta2',
    productUrl: null,
    title: null,
    description: null,
    textButton: null,
  }
];

export const GET_ALERT_BANNER_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          activo: true,
          traducciones: [
            {
              id: 1,
              titulo: 'Sucursales cerradas por contingencia Covid-19',
              mensaje: 'San Carlos, Temuco, Illapel y Osorno. En la Región Metropolitana; Maipú, Tenderini, Providencia y Teatinos.',
              idioma: 'es',
              alerta_cinta: 1
            }
          ]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ALERT_BANNER_MOCKUP: AlertBanner = {
  title: 'Sucursales cerradas por contingencia Covid-19',
  message: 'San Carlos, Temuco, Illapel y Osorno. En la Región Metropolitana; Maipú, Tenderini, Providencia y Teatinos.',
  active: true,
};

export const GET_ALERT_BANNER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          activo: true,
          traducciones: []
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const ALERT_BANNER_NULL_MOCKUP: AlertBanner = {
  title: null,
  message: null,
  active: true,
};

export const GET_GENERAL_INFO_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        contact_center_habilitado: true,
        traducciones: [
          {
            id: 1,
            horarios_call_center: '<p>S&aacute;bado 18 de julio, 9:00 a 14:00 hrs</p>',
            languages_code: 'es',
            informacion_general: 1
          }
        ],
        flotante_url: 'url',
        mostrar_flotante: true,
        modal_login: false,
        flotante: [
          {
            id: 1,
            languages_code: 'es',
            informacion_general: 1,
            titulo: 'Conoce tu saldo actual',
            bajada: 'Puedes consultar tu saldo actual solo ingresando tu rut y clave de acceso.',
            boton: 'Consultar saldo'
          }
        ],
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_AFFILIATE_FAQS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        secciones_preguntas: [
          {
            secciones_preguntas_id: 1
          }
        ],
        traducciones: [
          {
            titulo: 'Preguntas frecuentes para afiliados',
            languages_code: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_PENSIONER_FAQS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        secciones_preguntas: [
          {
            secciones_preguntas_id: 2
          }
        ],
        traducciones: [
          {
            titulo: 'Preguntas frecuentes para pensionados',
            idioma: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_EMPLOYER_FAQS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        secciones_preguntas: [
          {
            secciones_preguntas_id: 3
          }
        ],
        traducciones: [
          {
            titulo: 'Preguntas frecuentes para empleadores',
            idioma: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_CORPORATE_GOVERNANCE_FAQS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        secciones_preguntas: [
          {
            secciones_preguntas_id: 27
          }
        ],
        traducciones: [
          {
            titulo: 'Preguntas frecuentes sección gobierno corporativo',
            idioma: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GET_FAQS_SECTIONS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: [
        {
          id: 1,
          traducciones: [
            {
              id: 1,
              nombre: 'Afiliado',
              preguntas: {
                '¿Por qué debo afiliarme a una AFP?': '<p>Porque es obligatorio para los trabajadores dependientes que inician labores.</p><p>Debes tener un sistema que te proteja en caso de vejez, invalidez o muerte y esto se obtiene a través de la afiliación al Sistema de Pensiones del DL 3.500, que está representado por las AFP.</p>',
                'Empecé a trabajar por primera vez antes del 31 de julio de 2018, ¿dónde debo afiliarme?': '<p>Debes hacerlo en AFP PlanVital.</p>',
                'Empecé a trabajar por primera vez a partir del 1 de agosto de 2018, ¿en cuál AFP debo afiliarme?': '<p>Debes hacerlo en AFP Modelo.</p>'
              },
              languages_code: 'es',
              seccion_pregunta: 1
            }
          ]
        },
        {
          id: 2,
          traducciones: [
            {
              id: 2,
              nombre: 'Pensiones',
              preguntas: {
                'Si me pensiono en este Sistema, ¿debo renunciar a mi trabajo?': '<p>No, salvo que estés afecto a alguna ley especial, cuyo estatuto exija el cese de funciones.</p>',
                '¿Puedo dar poder para que otra persona cobre mi pensión?': '<p>Si, ante notario.</p><p>El formato de este poder lo puedes solicitar en cualquiera de nuestras agencias desde Arica a Punta Arenas.</p>'
              },
              languages_code: 'es',
              seccion_pregunta: 2
            }
          ]
        },
        {
          id: 3,
          traducciones: [
            {
              id: 3,
              nombre: 'Subsidio a trabajadores jóvenes',
              preguntas: {
                '¿Qué es el Subsidio a Trabajadores Jóvenes?': '<p>Es una contribución monetaria de parte del Estado para Empleadores y trabajadores cuyo propósito es fomentar la contratación de jóvenes, el empleo formal y el incremento de sus recursos previsionales.</p><ul><li>El dirigido al empleador se denomina <strong>Subsidio a la Contratación</strong>.</li><li>El dirigido al trabajador se denomina <strong>Subsidio a la Cotización</strong>.</li></ul>',
                '¿A qué tipo de trabajadores está dirigido?': '<p>A los que:</p><ul><li>Tienen entre 18 y 35 años de edad (se entiende que un trabajador tiene 35 años de edad hasta el día anterior a aquel en que cumple 36 años).</li><li>Perciben una remuneración mensual igual o inferior a 1,5 veces el Ingreso Mínimo Mensual.</li><li>Tienen menos de 24 cotizaciones mensuales <strong>como dependientes</strong>.</li></ul>'
              },
              languages_code: 'es',
              seccion_pregunta: 3
            }
          ]
        }
      ],
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GENERAL_INFO_MOCKUP: GeneralInfo = {
  callCenterAvailable: true,
  callCenterHours: '<p>S&aacute;bado 18 de julio, 9:00 a 14:00 hrs</p>',
  loginModalActive: false,
  floatingButton: {
    show: true,
    title: 'Conoce tu saldo actual',
    description: 'Puedes consultar tu saldo actual solo ingresando tu rut y clave de acceso.',
    button: {
      name: 'Consultar saldo',
      url: 'url',
    },
  },
};

export const GET_GENERAL_INFO_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [],
        flotante_url: 'url',
        mostrar_flotante: true,
        modal_login: false,
        flotante: [],
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const GENERAL_INFO_NULL_MOCKUP: GeneralInfo = {
  callCenterAvailable: false,
  callCenterHours: null,
  loginModalActive: false,
  floatingButton: {
    show: true,
    title: null,
    description: null,
    button: {
      name: null,
      url: 'url',
    },
  },
};
