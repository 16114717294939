import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NAMES_MAX_LENGTH } from '@constants/forms.constant';
import {
  STUDIES_CERTIFICATE_PERIODS, STUDIES_CERTIFICATE_YEARS
} from '@constants/modal-studies-certificate.constant';
import {
  PERSONAL_DECLARATION_REQUIRED_FILES
} from '@constants/pages-content/certify-studies.constants';
import { AttachFiles } from '@interfaces/attach-files.interface';
import {
  CertifyStudiesDeclarationParams, RequiredFiles, StudiesDeclaration
} from '@interfaces/certify-studies.interface';
import { PartialFormFileParams, PartialFormsFilesResponse } from '@interfaces/forms.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { attachFileCount } from '@validators/attach-file.validator';

@Component({
  selector: 'app-certify-studies-declaration-file',
  templateUrl: './certify-studies-declaration-file.component.html',
  styleUrls: ['./certify-studies-declaration-file.component.scss'],
})
export class CertifyStudiesDeclarationFileComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: StudiesDeclaration;
  @Input() public formFiles: Array<PartialFormsFilesResponse>;
  @Input() public partialFilesAttached: any;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public saveModal: EventEmitter<any> = new EventEmitter();
  @Output() public goBack = new EventEmitter();
  public form: UntypedFormGroup;
  public requiredFiles: AttachFiles;
  public partialFiles: AttachFiles;
  public fileParamsData: any = [];
  public namesMaxLength = NAMES_MAX_LENGTH;
  public periods = STUDIES_CERTIFICATE_PERIODS;
  public years = STUDIES_CERTIFICATE_YEARS;

  public get requestFiles(): boolean { return Boolean(this.requiredFiles) && this.requiredFiles.files.length > 0; }
  public get attachedFiles(): UntypedFormArray { return this.form.get('attachedFiles') as UntypedFormArray; }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.createForm();
    this.loadFiles();
  }

  private createForm() {
    const values = this.getFormValues();
    this.form = this.formBuilder.group({
      career: [values.career, Validators.required],
      establishment: [values.establishment, Validators.required],
      year: [values.year, Validators.required],
      period: [values.period, Validators.required],
      declaration: [values.declaration, Validators.requiredTrue],
      attachedFiles: this.formBuilder.array(this.formFiles, attachFileCount(2))
    });
  }

  private getFormValues() {
    if (!this.formValues) { return {}; }
    const { career, establishment, year, period, declaration } = this.formValues;
    return {
      career,
      establishment,
      year,
      period,
      declaration
    };
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public confirm(showModal = false): void {
    if (this.form.invalid) { return; }

    const { career, establishment, year, period, declaration } = this.form.value;
    const translatedYear = this.translate.instant(year);
    const data = {
      studiesDeclaration: {
        career,
        establishment,
        year: translatedYear,
        period,
        declaration
      } as StudiesDeclaration,
      files: {
        personalDocument: this.form.get(['attachedFiles', 0]).value,
        alternativeCertificate: this.form.get(['attachedFiles', 1]).value
      } as RequiredFiles,
      fileParams: {
        actions: [... this.fileParamsData['actionList']],
        personRut: [],
        keys: [... this.fileParamsData['keyList']],
      } as PartialFormFileParams
    } as CertifyStudiesDeclarationParams;

    this.saveModal.emit(showModal);
    this.formData.emit(data);
  }

  public setFiles(data: any): void {
    if (!data) { return; }
    this.attachedFiles.clear();
    this.fileParamsData = [];
    data.fileList.forEach((file: File) => this.attachedFiles.push(new UntypedFormControl(file)));
    this.fileParamsData = data;
  }

  private loadFiles(): void {
    this.requiredFiles = {
      files: Object.entries(PERSONAL_DECLARATION_REQUIRED_FILES).map(entry => entry[1])
    };
  }
}
