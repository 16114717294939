import { Component, Input } from '@angular/core';
import { DisclaimerType } from '@constants/disclaimer.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-disclaimer-box',
  templateUrl: './disclaimer-box.component.html',
  styleUrls: ['./disclaimer-box.component.scss'],
})
export class DisclaimerBoxComponent {
  @Input() public description;
  @Input() public typeDisclaimer: DisclaimerType = 'info';
  @Input() public showBackground = true;
  @Input() public showIcon = true;

  constructor(
    public font: FontService
  ) { }
}
