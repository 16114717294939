import { Injectable } from '@angular/core';
import { environment } from '@environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  private basePageView = '/public';

  constructor() { }

  public registerPageView(page: string) {
    gtag('config', environment.googleAnalyticsId, { page_path: `${this.basePageView}${page}` });
  }
}
