import { Component } from '@angular/core';
import { PopoverComponent } from '@components/popover/popover.component';
import { INVESTOR_RESULT_POPOVER } from '@constants/pages-content/investor-profile.constant';
import { PopoverController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-investor-result-popover',
  templateUrl: './investor-result-popover.component.html',
  styleUrls: ['./investor-result-popover.component.scss'],
})
export class InvestorResultPopoverComponent extends PopoverComponent {
  public info = INVESTOR_RESULT_POPOVER;
  constructor(
    public font: FontService,
    protected popoverCtrl: PopoverController,
  ) {
    super(popoverCtrl);
  }
}
