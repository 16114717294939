import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { AFFILIATE_TRANSFER_OPTIONS } from '@constants/modal-transfer-pension-option.constant';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-modal-transfer-pension-option',
  templateUrl: './modal-transfer-pension-option.component.html',
  styleUrls: ['./modal-transfer-pension-option.component.scss'],
})

export class ModalTransferPensionOptionComponent extends ModalComponent {
  public largeButton = true;
  public affiliateTransferOptions = AFFILIATE_TRANSFER_OPTIONS;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    protected navService: NavigationService,
  ) {
    super(params, modalCtrl);
  }

  public async goTo(url?: string): Promise<void> {
    await this.closeModal();
    if (!url) { return; }
    this.navService.goTo(url);
  }
}
