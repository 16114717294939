import { Component } from '@angular/core';
import { PopoverComponent } from '@components/popover/popover.component';
import { NavParams, PopoverController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-popover-information',
  templateUrl: './popover-information.component.html',
  styleUrls: ['./popover-information.component.scss'],
})
export class PopoverInformationComponent extends PopoverComponent {
  public information: string;

  constructor(
    public font: FontService,
    private params: NavParams,
    protected popoverCtrl: PopoverController,
  ) {
    super(popoverCtrl);
    this.information = this.params.get('information');
  }

}
