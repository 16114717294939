<div class="icon-card" [ngClass]="cardClass">
  <div class="icon" [ngClass]="iconClass"></div>
  <div class="text-container">
    <h2 *ngIf="!loading" class="title light" [ngClass]="font?.size" [innerHTML]="card?.title | translate"></h2>
    <p *ngIf="loading">
      <ion-skeleton-text class="skeleton-line small title" animated></ion-skeleton-text>
    </p>
    <p *ngIf="!loading" [ngClass]="[descriptionClass(), font?.size]" [innerHTML]="card?.description | translate"></p>
    <app-skeleton *ngIf="loading" [linesNumber]="4" [paragraphNumber]="2"></app-skeleton>
  </div>
  <ion-button class="button" [disabled]="loading" [ngClass]="[buttonClass(),font?.size]" (click)="moreInformation()">
    <p [class.hidden]="loading" [class.i-more]="!isDefaultCard">{{ card?.textButton | translate }}</p>
  </ion-button>
</div>