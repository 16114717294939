import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NAMES_MAX_LENGTH } from '@constants/forms.constant';
import {
  PERSONAL_DOCUMENT_REQUIRED_FILE
} from '@constants/pages-content/state-guarantee-form.constants';
import { PersonBackgroundsDefaultType } from '@constants/person-backgrounds.constant';
import { AttachFiles } from '@interfaces/attach-files.interface';
import { FieldsValidators } from '@interfaces/person-backgrounds.interface';
import {
  BackgroundParams, BasicPerson,
} from '@interfaces/state-guarantee-form.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-state-guarantee-background',
  templateUrl: './state-guarantee-background.component.html',
  styleUrls: ['./state-guarantee-background.component.scss']
})
export class StateGuaranteeBackgroundComponent implements OnInit {
  @Input() public pageContent;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  public namesMaxLength = NAMES_MAX_LENGTH;
  public requiredFiles: AttachFiles;
  public form: UntypedFormGroup;
  public applicantForm: UntypedFormGroup;
  public deceasedForm: UntypedFormGroup;
  public attachedFile: any;

  public applicantControls: Array<PersonBackgroundsDefaultType> = [
    'names', 'lastName', 'secondSurname', 'rut', 'gender',
    'maritalStatus', 'nationality', 'profession',
    'address', 'cellphone', 'email', 'region', 'commune'
  ];
  public deceasedControls: Array<PersonBackgroundsDefaultType> = [
    'names', 'lastName', 'secondSurname', 'rut'
  ];
  public deceasedUserFieldsValidators = { rut: { value: '' } } as FieldsValidators;

  public get isSurvivor(): boolean { return this.form.get('survivor').value === 'true'; }
  public get isApplicantFormValid() { return this.applicantForm && this.applicantForm.valid; }
  public get isDeceasedFormValid() { return !this.isSurvivor || (this.deceasedForm && this.deceasedForm.valid); }
  public get isValid(): boolean { return this.form.valid && this.isApplicantFormValid && this.isDeceasedFormValid && this.attachedFile; }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private utils: Utils
  ) { }

  public ngOnInit(): void {
    this.createForm();
  }

  public confirm(): void {
    if (!this.isValid) { return; }
    this.formData.emit(this.parseForms());
  }

  public setApplicantForm(form: UntypedFormGroup): void {
    if (!form.valid) { return; }
    this.applicantForm = form;
    this.deceasedUserFieldsValidators = { rut: { value: form.get('rut').value } };
  }

  public setDeceasedForm(form: UntypedFormGroup): void {
    if (form.valid) { this.deceasedForm = form; }
  }

  public setFiles(data: any): void {
    this.attachedFile = data.fileList.length ? data.fileList[0] : null;
  }

  private parseForms(): BackgroundParams {
    const { names: name, lastName, secondSurname, rut, gender, maritalStatus: maritalStatusId,
      nationality, profession, address, cellphone, email, commune: communeId, region: regionId } = this.applicantForm.value;
    const clientBackgrounds = {
      name, lastName: this.utils.getFullSurname(lastName, secondSurname),
      rut: this.utils.rutClean(rut),
      gender, maritalStatusId,
      nationality, profession,
      address, communeId, regionId,
      cellphone, email
    };

    const params = { clientBackgrounds, attachmentFile: this.attachedFile } as BackgroundParams;

    if (this.isSurvivor) {
      const deceasedValues = this.deceasedForm.value;
      params.deceasedBackgrounds = {
        rut: this.utils.rutClean(deceasedValues.rut),
        name: this.utils.getFullName(deceasedValues.names, deceasedValues.lastName, deceasedValues.secondSurname)
      } as BasicPerson;
    }

    return params;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      survivor: ['', Validators.required]
    });
    this.requiredFiles = {
      files: Object.values(PERSONAL_DOCUMENT_REQUIRED_FILE)
    };
  }
}
