<div class="form-container">

  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.pensions.title | translate }}</h3>
  <form class="form-section" [formGroup]="form">
    <p class="subtitle dynamic-text secondary" [ngClass]="font?.size">{{ pageContent?.pensions.description | translate }}</p>
    <div class="selection-container">
      <mat-checkbox *ngFor="let controlKey of controlKeys" [ngClass]="font?.size" [formControlName]="controlKey" color="primary">
        {{ pageContent.pensions[controlKey] | translate }}
      </mat-checkbox>
    </div>
  </form>

  <div class="fields-group radio-button-list">
    <app-radio-button-list-form
      [pageContent]="declarations"
      (formChanged)="setBackgrounds($event)">
    </app-radio-button-list-form>
  </div>

  <div class="form-section" *ngIf="isSurvivorPension">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.beneficiaries.title | translate }}</h3>
    <app-form-array-list
      [pageContent]="pageContent?.beneficiaries"
      [iconClass]="'pension'"
      [itemProperties]="beneficiaryProperties"
      [modalType]="modalType"
      [modalData]="modalData"
      (edited)="setBeneficiaries($event)">
    </app-form-array-list>
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.beneficiaries.disclaimer"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
  </div>

  <div class="button-container">
    <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="!isValid" (click)="confirm()">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</div>
