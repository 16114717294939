import { AfterViewChecked, ChangeDetectorRef, Component, Input } from '@angular/core';

import { IChartistData } from 'chartist';

import { ChartInvestorProfileQuotaParams } from '@interfaces/investor-profile.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-chart-investor-profile',
  templateUrl: './chart-investor-profile.component.html',
  styleUrls: ['./chart-investor-profile.component.scss'],
})
export class ChartInvestorProfileComponent implements AfterViewChecked {
  @Input() public chartParams: ChartInvestorProfileQuotaParams;
  @Input() public selectedFundId: number;
  public chartData: IChartistData;
  public directionMobile = 'left';

  constructor(
    public font: FontService,
    private cd: ChangeDetectorRef,
  ) { }

  ngAfterViewChecked() {
    this.setChartData(this.selectedFundId);
  }

  private setChartData(index: number) {
    const { chartData } = this.chartParams;
    const data = { ...chartData };
    data.series = [(chartData.series as Array<Array<number>>)[index]];
    this.chartData = data;
    this.cd.detectChanges();
  }

  public changeDirection(direction: string) {
    this.directionMobile = direction;
  }

  public directionLeftClass() {
    return this.directionMobile === 'left' ? 'i-back-gray' : 'i-back-black';
  }

  public directionRightClass() {
    return this.directionMobile === 'right' ? 'i-back-gray' : 'i-back-black';
  }

  public get isChartRightClass(): boolean {
    return this.directionMobile === 'right';
  }
}
