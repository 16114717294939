<div class="form-container">
  <div class="partial-content">

    <ion-button class="btn small secondary label medium save" [disabled]="!isFormValid" (click)="sendForm()">
      {{ pageContent?.buttons.saveContinueLater | translate }}
      <div class="icon-result save"></div>
    </ion-button>

  </div>
  <app-person-backgrounds [pageContent]="pageContent?.affiliateSection" [controlsName]="affiliatePersonControls"
    (formChanged)="validateForm($event, 'affiliate')">
  </app-person-backgrounds>

  <app-pension-backgrounds [pageContent]="pageContent?.pensionSection" [controlsName]="pensionControlsName"
    [data]="pensionsControlData" (formChanged)="setPensionParams($event)">
  </app-pension-backgrounds>

  <app-person-backgrounds [pageContent]="pageContent?.adviserSection" [controlsName]="adviserPersonControls"
    [fieldsValidators]="adviserUserFieldsValidators" (formChanged)="validateForm($event, 'adviser')">
  </app-person-backgrounds>

  <div class="information-container" *ngIf="showBeneficiariesSection">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.beneficiariesSection.title | translate }}
    </h3>
    <app-form-array-list [pageContent]="pageContent?.beneficiariesSection.buttons" [iconClass]="'pension'"
      [itemProperties]="chargingFeesProperties" [modalType]="modalType" [modalData]="modalData" [items]="beneficiaries"
      (edited)="setBeneficiaries($event)">
    </app-form-array-list>

    <app-disclaimer-box [description]="pageContent?.beneficiariesSection.disclaimer" [typeDisclaimer]="'info'">
    </app-disclaimer-box>
  </div>

  <div class="button-container">
    <ion-button class="button primary form-button" [ngClass]="font?.size" (click)="sendForm()"
      [disabled]="!isFormValid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
  </div>
</div>