<div class="modal">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="title" [ngClass]="font?.size" [innerHTML]="title"></div>
  <div class="table-container">
    <div class="item header-table">
      <p class="item-value" [ngClass]="font?.size">%</p>
    </div>
    <div class="item title-table">
      <p class="item-name" [ngClass]="font?.size">{{ data?.title?.name }}</p>
      <p class="item-value" [ngClass]="font?.size">{{ data?.title?.value | number:'1.0-2' }}%</p>
    </div>
    <div class="section" *ngFor="let section of data?.section">
      <div class="item title-section">
        <p class="item-name" [ngClass]="font?.size">{{ section?.type.name }}</p>
        <p class="item-value" [ngClass]="font?.size">{{ section?.type.value | number:'1.0-2' }}%</p>
      </div>
      <div class="item detail-section" *ngFor="let detail of section?.detail">
        <p class="item-name" [ngClass]="font?.size">{{ detail?.name }}</p>
        <p class="item-short-name" [ngClass]="font?.size">{{ detail?.shortName }}</p>
        <p class="item-value" [ngClass]="font?.size">{{ detail?.value | number:'1.0-2' }}</p>
      </div>
    </div>
  </div>
  <div class="centered-container">
    <ion-button class="button primary back" [ngClass]="font?.size" (click)="closeModal()">Volver</ion-button>
  </div>
</div>
