<div class="modal alert-modal-container" [class.informative]="informative">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="text-container">
    <div class="title primary" [ngClass]="font?.size" [innerHTML]="title | translate"></div>
    <p class="dynamic-text subtitle secondary" [ngClass]="font?.size" [innerHTML]="description | translate"></p>
  </div>
  <ion-button class="button primary back" [ngClass]="font?.size" (click)="closeModal()">Volver</ion-button>
</div>
