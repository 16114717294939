import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { MINIMUM_AGE, UPWARD_RELATIONSHIPS } from '@constants/pages-content/family-burden.constants';

export function familyBurdenValidator(birthdateInput: string, relationshipId: string, calculateAge: Function): ValidatorFn {
  return (formGroup: UntypedFormGroup) => {
    const birthdateControl = formGroup.controls[birthdateInput];
    const relationshipControl = formGroup.controls[relationshipId];

    const age = calculateAge(birthdateControl.value);
    const invalidApplicant = age < MINIMUM_AGE && UPWARD_RELATIONSHIPS.includes(relationshipControl.value);

    relationshipControl.setErrors(null);
    if (invalidApplicant) { relationshipControl.setErrors({ invalidApplicant: true }); }

    return null;
  };
}
