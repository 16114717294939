import { VoluntarySavings } from '@interfaces/savings.interface';

import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.SAVE_WITH_US.VOLUNTARY_SAVINGS';

export const VOLUNTARY_SAVINGS_CONTENT: VoluntarySavings = {
  header: {
    image: null,
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  button: `${pageKey}.BUTTON`,
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    benefits: null,
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/AFP_Planvital_apv_cotizaciones_voluntarias.jpg',
          alt: 'Cotizaciones voluntarias'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.VOLUNTARY_RATE.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_apv_abre_un_apv.jpg',
          alt: 'Abre un APV'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.APV.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.APV.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.APV.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.APV.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: true,
        redirectButton: {
          redirectURL: 'apv',
          textButton: `${pageKey}.CARDS.CHARACTERISTICS.APV.BUTTON_TEXT`,
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_apv_depositos_colectivos.jpg',
          alt: 'Depósitos colectivos'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: {
          title: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.TITLE`,
          description: `${pageKey}.CARDS.CHARACTERISTICS.COLLECTIVE_APV.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: '',
          disclaimerButton: '',
          disclaimerUrl: '',
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  questionsSection: {
    title: `${pageKey}.QUESTIONS.TITLE`,
    body: null,
    disclaimer: `${pageKey}.QUESTIONS.DISCLAIMER`,
    disclaimerButton: `${pageKey}.QUESTIONS.BUTTON`,
    disclaimerUrl: 'url',
  },
  commissionDisclaimer: {
    title: `${pageKey}.COMMISSION_DISCLAIMER.TITLE`,
    description: `${pageKey}.COMMISSION_DISCLAIMER.DESCRIPTION`
  },

};
