import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Infographic } from '@interfaces/infographic.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-infographic',
  templateUrl: './infographic.component.html',
  styleUrls: ['./infographic.component.scss'],
})
export class InfographicComponent {
  @Input() public infographic: Infographic;
  @Input() public type: 'simple' | 'extended';
  @Input() public product: 'primary' | 'secondary' | 'tertiary';
  @Input() public loading = false;
  @Output() public clickMoreInformation = new EventEmitter();

  constructor(
    public font: FontService,
  ) { }

  public emitClickButton() {
    this.clickMoreInformation.emit();
  }

  public get typeClass() {
    return this.type || '';
  }

  public get iconClass() {
    return `i-${this.infographic ? this.infographic.icon : ''} ${this.product || ''}`;
  }
}
