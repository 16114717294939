<div class="modal modal-information-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="information-content modal-frame">
    <div class="title" [ngClass]="font?.size" [innerHTML]="information?.title | translate"></div>
    <div class="description dynamic-text tertiary" [ngClass]="font?.size"></div>
    <b class="centered-container sub-title">{{modalInformation+'.MODAL_INFORMATION.IMPORTANT_INFO' | translate}}</b>
    <br>
    <div class="gray-alert">
      <div class="icon-alert">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        <p>{{modalInformation+'.MODAL_INFORMATION.MUST_REQUEST' |
          translate}}</p>
      </div>
    </div>
    <div>
      <p class="sub-title">
        {{modalInformation+'.MODAL_INFORMATION.STEP_ONE' |
        translate}}
      </p>
      <div class="interest-button secondary centered-container" style="padding-top: 0;"
        (click)="downloadMandate('notarial')">
        <ion-button class="button secondary">
          {{modalInformation+'.MODAL_INFORMATION.NOTARIAL_MANDATE' |
          translate}}
        </ion-button>
      </div>
    </div>
    <div class="pt-5">
      <div class="interest-button secondary centered-container" style="padding-top: 0;"
        (click)="downloadMandate('hospitalizado')">
        <ion-button class="button secondary">
          {{modalInformation+'.MODAL_INFORMATION.HOSPITALIZED_MANDATE' |
          translate}}
        </ion-button>
      </div>
    </div>
    <div class="pt-5">
      <div class="interest-button secondary centered-container" style="padding-top: 0;"
        (click)="downloadMandate('privado')">
        <ion-button class="button secondary">
          {{modalInformation+'.MODAL_INFORMATION.MANDATE_PRISONER' |
          translate}}
        </ion-button>
      </div>
    </div>
    <div class="pt-5">
      <p class="sub-title mt-25">{{modalInformation+'.MODAL_INFORMATION.STEP_TWO' |
        translate}}</p>
    </div>
    <div class="pt-5">
      <p class="align" [innerHTML]="modalInformation+'.MODAL_INFORMATION.ONCE_COMPLETED' | translate"></p>
    </div>

    <div class="pt-5">
    </div>
    <div class="centered-container">
      <ion-button class="button primary back" [ngClass]="font?.size" (click)="goToMandate()">{{modalInformation +
        '.MODAL_INFORMATION.BUTTON_ACTION' | translate}}</ion-button>
    </div>
    <div class="centered-container pt-5 mobile" (click)="closeModal()">
      <p class="sub-title mt-25">Volver</p>
    </div>
  </div>