import { ERROR_INPUT_TEXT } from '@constants/person-backgrounds.constant';
import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { Voucher } from '@interfaces/voucher.interface';

const pageKey = 'AFFILIATE.FAMILY_ALLOWANCE_FORM';

const requestFamilyAllowanceKey = `${pageKey}.STEPS.REQUEST`;
const clientCheckKey = `${pageKey}.STEPS.CLIENT_CHECK`;
const pensionTypeKey = `${requestFamilyAllowanceKey}.FIELDS.PENSION_TYPE`;
const informationKey = `${requestFamilyAllowanceKey}.FIELDS.INFORMATION_PENSIONER`;
const requestGoalKey = `${requestFamilyAllowanceKey}.FIELDS.REQUEST_GOAL`;

const affiliateBackgroundKey = `${pageKey}.STEPS.AFFILIATE_BACKGROUND`;
const affiliateBackgroundFieldsKey = `${affiliateBackgroundKey}.FORM.FIELDS`;
const maritalStatusKey = `${affiliateBackgroundFieldsKey}.MARITAL_STATUS.OPTIONS`;

const attachmentsKey = `${pageKey}.STEPS.ATTACHMENTS`;
const toastKey = `${pageKey}.TOAST`;

const voucherKey = `${pageKey}.STEPS.VOUCHER.SUCCESS`;
const failedSubmissionKey = `${pageKey}.STEPS.VOUCHER.FAILED`;

const savedModal = 'MODALS.SAVED';

export const IDENTIFICATION_FILE = {
  order: 1,
  buttonLabel: `${pageKey}.FILES.IDENTIFICATION_FILE`,
  fileIndex: 'idFile',
  required: true
};

export const MARITAL_STATUS_DESCRIPTION = {
  A: `${maritalStatusKey}.A`,
  S: `${maritalStatusKey}.S`,
  C: `${maritalStatusKey}.C`,
  V: `${maritalStatusKey}.V`,
  D: `${maritalStatusKey}.D`,
  P: `${maritalStatusKey}.P`,
};

const BASE_AFFILIATE_BACKGROUNDS_FORM = {
  fields: {
    names: `${affiliateBackgroundFieldsKey}.NAMES`,
    lastName: `${affiliateBackgroundFieldsKey}.LAST_NAME`,
    secondSurname: `${affiliateBackgroundFieldsKey}.SECOND_SUR_NAME`,
    gender: {
      label: `${affiliateBackgroundFieldsKey}.GENDER.LABEL`,
      female: `${affiliateBackgroundFieldsKey}.GENDER.FEMALE`,
      male: `${affiliateBackgroundFieldsKey}.GENDER.MALE`,
    },
    rut: `${affiliateBackgroundFieldsKey}.RUT`,
    maritalStatus: `${affiliateBackgroundFieldsKey}.MARITAL_STATUS.LABEL`,
    cellphone: `${affiliateBackgroundFieldsKey}.TELEPHONE`,
    email: `${affiliateBackgroundFieldsKey}.EMAIL`,
    address: `${affiliateBackgroundFieldsKey}.HOME_ADDRESS`,
  },
};

export const FAMILY_ALLOWANCE_FORM_CONTENT = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  introduction: {
    title: `${pageKey}.INTRODUCTION.TITLE`,
    description: `${pageKey}.INTRODUCTION.DESCRIPTION`,
    buttonAction: `${pageKey}.INTRODUCTION.BUTTON_ACTION`,
  },
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  steps: {
    clientCheck: {
      label: `${clientCheckKey}.LABEL`
    },
    request: {
      label: `${requestFamilyAllowanceKey}.LABEL`,
      title: `${requestFamilyAllowanceKey}.TITLE`,
      buttons: {
        next: `${requestFamilyAllowanceKey}.BUTTONS.NEXT`,
      },
      fields: {
        pensionType: {
          title: `${pensionTypeKey}.TITLE`,
          options: {
            normal: `${pensionTypeKey}.OPTIONS.OLD_AGE`,
            anticipated: `${pensionTypeKey}.OPTIONS.EARLY_OLD_AGE`,
            disability: `${pensionTypeKey}.OPTIONS.INVALIDITY`,
            survival: `${pensionTypeKey}.OPTIONS.SURVIVAL`,
          }
        },
        informationPensioner: {
          isPensioner: `${informationKey}.IS_PENSIONER`,
          isNotPensioner: `${informationKey}.NOT_PENSIONER`,
        },
        requestGoal: {
          title: `${requestGoalKey}.TITLE`,
          options: {
            newBeneficiary: `${requestGoalKey}.OPTIONS.NEW_BENEFICIARY`,
            increaseBurden: `${requestGoalKey}.OPTIONS.INCREASE_BURDEN`,
            burdenDecrease: `${requestGoalKey}.OPTIONS.DECREASE_BURDEN`,
            burdenRenewal: `${requestGoalKey}.OPTIONS.BURDEN_RENEWAL`,
          }
        },
      }
    },
    affiliateBackground: {
      label: `${affiliateBackgroundKey}.LABEL`,
      applicantBackground: {
        ...BASE_AFFILIATE_BACKGROUNDS_FORM,
        title: `${affiliateBackgroundKey}.APPLICANT_BACKGROUND.TITLE`,
        description: `${affiliateBackgroundKey}.APPLICANT_BACKGROUND.DESCRIPTION`,
        errors: {
          ...ERROR_INPUT_TEXT.errors,
          mustMatchRut: `${affiliateBackgroundKey}.ERRORS.MUST_MATCH_RUT`,
        }
      },
      buttons: {
        next: `${affiliateBackgroundKey}.BUTTONS.NEXT`,
        back: `${affiliateBackgroundKey}.BUTTONS.BACK`,
        saveContinueLater: `${affiliateBackgroundKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
      affiliateBackground: {
        ...BASE_AFFILIATE_BACKGROUNDS_FORM,
        title: `${affiliateBackgroundKey}.TITLE`,
      }
    },
    attachments: {
      label: `${attachmentsKey}.LABEL`,
      title: `${attachmentsKey}.TITLE`,
      buttonFamilyBurden: {
        add: `${attachmentsKey}.BUTTON_FAMILY_BURDEN`
      },
      buttons: {
        next: `${attachmentsKey}.BUTTONS.NEXT`,
        back: `${attachmentsKey}.BUTTONS.BACK`,
        saveContinueLater: `${affiliateBackgroundKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
      titleSecondary: `${attachmentsKey}.TITLE_SECONDARY`,
      disclaimerSecondary: `${attachmentsKey}.DISCLAIMER_SECONDARY`,
      personalConsent: `${attachmentsKey}.PERSONAL_CONSENT`,
      deleteBurdenModal: {
        title: `${attachmentsKey}.DELETE_MODAL.TITLE`,
        description: `${attachmentsKey}.DELETE_MODAL.DESCRIPTION`,
        firstBtnText: `${attachmentsKey}.DELETE_MODAL.DELETE`,
        secondBtnText: `${attachmentsKey}.DELETE_MODAL.CANCEL`,
      }
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
    },
  },
};

export const DISCLAIMER_MESSAGE = {
  new: {
    title: `${attachmentsKey}.DISCLAIMER.REQUEST_OPTIONS.NEW_BENEFICIARY`,
    type: 'newBeneficiary'
  },
  increase: {
    title: `${attachmentsKey}.DISCLAIMER.REQUEST_OPTIONS.INCREASE_BURDEN`,
    type: 'increaseBurden'
  },
  decrease: {
    title: `${attachmentsKey}.DISCLAIMER.REQUEST_OPTIONS.DECREASE_BURDEN`,
    type: 'burdenDecrease'
  },
  renewal: {
    title: `${attachmentsKey}.DISCLAIMER.REQUEST_OPTIONS.BURDEN_RENEWAL`,
    type: 'burdenRenewal'
  }
};

export const FAMILY_ALLOWANCE_FORM_VOUCHER: Voucher = {
  title: `${voucherKey}.TITLE`,
  description: `${voucherKey}.DESCRIPTION`,
  homeButton: `${voucherKey}.BUTTON`,
};

export const FAMILY_ALLOWANCE_FORM_FAILED_SUBMISSION = {
  title: `${failedSubmissionKey}.TITLE`,
  description: `${failedSubmissionKey}.DESCRIPTION`,
  firstBtnText: `${failedSubmissionKey}.BUTTON`,
  iconName: 'i-error',
};

export const FAMILY_ALLOWANCE_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const ARRAY_FAMILY_BURDEN_PROPERTIES: Array<FormArrayItemProperties> = [
  { name: 'fullName', translated: false, style: ['bold'] },
  { name: 'dirtyRut', translated: true },
  { name: 'relationshipId', translated: true },
  { name: 'birthdate', translated: true },
];
