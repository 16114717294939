import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AFP_ACCOUNTS } from '@constants/forms.constant';
import { Backgrounds, TransferValues } from '@interfaces/survivor-pension-form.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-transfer-pension-form',
  templateUrl: './transfer-pension-form.component.html',
  styleUrls: ['./transfer-pension-form.component.scss'],
})
export class TransferPensionFormComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: TransferValues;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public back: EventEmitter<any> = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();

  public accounts = AFP_ACCOUNTS;
  public form: UntypedFormGroup;

  public getControl(key: string): AbstractControl { if (this.form) { return this.form.get(key); } }
  public get accountsArray(): UntypedFormArray { return this.getControl('accounts') as UntypedFormArray; }
  public get option(): AbstractControl { return this.getControl('option'); }
  public get isOptionSelected(): boolean { return Boolean(this.option.value); }
  public get isValidAccounts(): boolean { return !this.isOptionSelected ? true : this.selectedAccounts.length > 0; }
  public isChecked(type: string): boolean { return this.accountsArray.value.includes(type); }
  public get validForm(): boolean { return !this.form.invalid && !!this.getControl('declaration').value && this.isValidAccounts; }
  private get selectedAccounts(): Array<string> { return this.isOptionSelected ? this.accountsArray.value : []; }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit() {
    const formValues = this.getFormValues();
    this.form = this.formBuilder.group({
      option: [formValues.option, [Validators.required]],
      declaration: [formValues.declaration, [Validators.required]],
      accounts: this.formBuilder.array(formValues.accounts)
    });
  }

  public sendForm(showModal = false): void {
    if (this.form.invalid) { return; }

    const clientOption = this.option.value ? { id: 'transferBalances' } as Backgrounds : null;

    const formValues = {
      accounts: this.selectedAccounts,
      clientOption
    } as TransferValues;
    this.savedModal.emit(showModal);
    this.formData.emit(formValues);
  }

  public updateAccounts(name: string, checked: boolean = true): void {
    if (checked) { return this.accountsArray.push(this.formBuilder.control(name)); }
    this.removeAccount(name);
  }

  public previousStep(): void {
    this.back.emit();
  }

  private removeAccount(type: string): void {
    const arrayIndex = this.accountsArray.controls.findIndex(control => control.value === type);
    this.accountsArray.removeAt(arrayIndex);
  }

  private getFormValues(): any {
    if (!this.formValues) { return { accounts: [] }; }
    return {
      option: Boolean(this.formValues.clientOption),
      declaration: true,
      accounts: this.formValues.accounts,
    };
  }

}
