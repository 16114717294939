<div class="partial-content">
  <ion-button class="btn small secondary label medium save" [disabled]="form.invalid" (click)="confirm(true)">
      {{ pageContent?.buttons.saveContinueLater | translate }}
      <div class="icon-result save"></div>
  </ion-button>
</div>
<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="confirm()">
  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.declarationSection.title | translate }}</h3>
    <div class="fields-group inline">
      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ pageContent?.declarationSection.career | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="career" [maxLength]="namesMaxLength" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">{{ pageContent?.declarationSection.required | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ pageContent?.declarationSection.establishment | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="establishment" [maxLength]="namesMaxLength" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">{{ pageContent?.declarationSection.required | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.declarationSection.year | translate }}</mat-label>
        <mat-select formControlName="year" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let year of years" [value]="year | translate">
            {{ year | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.declarationSection.period | translate }}</mat-label>
        <mat-select formControlName="period" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let period of periods" [value]="period.code">
            {{ period.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="form-section second">
    <mat-checkbox formControlName="declaration" color="primary" [ngClass]="font?.size">
      <div [innerHTML]="pageContent?.declarationSection.declaration | translate"></div>
    </mat-checkbox>
  </div>
  <div class="form-section second">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.declarationSection.secondTitle | translate }}</h3>
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.declarationSection.disclaimer"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
    <app-attach-files-partialsave *ngIf="requestFiles"
      [attachFiles]="requiredFiles"
      [preloadedFiles]="partialFilesAttached"
      (fileEmitter)="setFiles($event)">
    </app-attach-files-partialsave>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
