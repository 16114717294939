import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { PERSON_BACKGROUNDS_DEFAULT_CONTROLS } from './person-backgrounds.constant';

export const DEFAULT_CONTROLS: Array<PersonBackgroundsField> = [
  { key: 'names', value: '', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.names },
  { key: 'lastName', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.lastName },
  { key: 'secondSurname', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.secondSurname },
  { key: 'rut', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.rut },
  { key: 'relationship', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.relationship },
  { key: 'gender', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.gender },
  { key: 'cellphone', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.cellphone },
  { key: 'email', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.email },
];
