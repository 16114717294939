import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DISCLAIMERS } from '@pages-content/state-guarantee-form.constants';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-state-guarantee-conditions',
  templateUrl: './state-guarantee-conditions.component.html',
  styleUrls: ['./state-guarantee-conditions.component.scss'],
})
export class StateGuaranteeConditionsComponent implements OnInit {
  @Input() public pageContent;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public back: EventEmitter<any> = new EventEmitter();
  public disclaimers = DISCLAIMERS;
  public form: UntypedFormGroup;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      declaration: ['', [Validators.requiredTrue]],
    });
  }

  public confirm(): void {
    if (this.form.invalid) { return; }
    this.formData.emit();
  }

  public previousStep(): void {
    this.back.emit();
  }
}
