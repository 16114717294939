import { Component, Input } from '@angular/core';
import { ModalAlertComponent } from '@components/modal-alert/modal-alert.component';
import { IconCard, IconCardType } from '@interfaces/icon-card.interface';
import { NavController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';

@Component({
  selector: 'app-icon-card',
  templateUrl: './icon-card.component.html',
  styleUrls: ['./icon-card.component.scss'],
})
export class IconCardComponent {
  @Input() public card: IconCard;
  @Input() public type: IconCardType = 'default';
  @Input() public loading = false;
  @Input() public isButtonsPrimary: boolean;
  public get isDefaultCard() { return this.type === 'default'; }

  constructor(
    private navCtrl: NavController,
    private modalService: ModalService,
    public font: FontService,
  ) { }

  public get iconClass() {
    return this.card ? `i-${this.card.icon}` : '';
  }

  public get cardClass() {
    if (!this.card) { return ''; }
    const { style } = this.card;
    return `${this.type} ${style}`;
  }

  public descriptionClass() {
    if (!this.card) { return ''; }
    const { style } = this.card;
    const styleClass = style !== 'default' ? 'light' : '';
    const defaultClass = this.isDefaultCard ? 'dynamic-text tertiary' : 'light';
    return `${styleClass} ${defaultClass}`;
  }

  public buttonClass(): string {
    if (!this.card) { return ''; }
    return this.isButtonsPrimary ? 'primary' : 'secondary';
  }

  public moreInformation() {
    return this.card.url ? this.goToUrl() : this.openModalCard();
  }

  private goToUrl() {
    if (!this.card.url) { return; }
    this.navCtrl.navigateForward(this.card.url);
  }

  private openModalCard() {
    if (!this.card.modal) { return; }
    const { title, description } = this.card.modal;
    this.modalService.openModal(ModalAlertComponent, { title, description });
  }

}
