import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { TransferSurvivorPensionRadioValues } from '@interfaces/transfer-survivor-pension-beneficiaries.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-transfer-survivor-pension-beneficiaries',
  templateUrl: './modal-transfer-survivor-pension-beneficiaries.component.html',
  styleUrls: ['./modal-transfer-survivor-pension-beneficiaries.component.scss'],
})
export class ModalTransferSurvivorPensionBeneficiariesComponent extends ModalComponent {
  public title: string;
  public subTitle: string;
  public disclaimer: string;
  public selectedValue: TransferSurvivorPensionRadioValues;
  public firstBtnText: string;
  public secondBtnText: string;
  public primaryCallback: Function;
  public secondaryCallback: Function;
  public showSecondaryButton = false;
  public iconName: string[];
  public largeButton = false;
  public textInfo = 'AFFILIATE.TRANSFER_SURVIVOR_PENSION_BENEFICIARIES_FORM.DOCUMENTS';

  public get selectedOptions() {
    return {
      MANDATARY_GROUP_BENEFICIARY: TransferSurvivorPensionRadioValues.MANDATARY_GROUP_BENEFICIARY,
      TUTOR: TransferSurvivorPensionRadioValues.TUTOR,
      TUTOR_MENTAL_DISABILITY: TransferSurvivorPensionRadioValues.TUTOR_MENTAL_DISABILITY,
      UNIQUE_BENEFICIARY: TransferSurvivorPensionRadioValues.UNIQUE_BENEFICIARY,
    };
  }

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    const data = this.params.get('data');
    if (data) {
      Object.keys(data).forEach((key) => this[key] = data[key]);
      if (data.secondaryCallback) { this.showSecondaryButton = true; }
    }
  }

  public async primaryAction(): Promise<void> {
    await this.closeModal();
    this.primaryCallback();
  }

  public async secondaryAction(): Promise<void> {
    await this.closeModal();
    this.secondaryCallback();
  }


}
