import { merge } from 'rxjs';

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@components/modal/modal.component';
import { GENDERS, PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, VALIDATORS } from '@constants/forms.constant';
import { FAMILY_BURDEN_CONTENT, RELATIONSHIPS, REQUIRED_FILES } from '@constants/pages-content/family-burden.constants';
import { AttachFile, AttachFiles } from '@interfaces/attach-files.interface';
import { FamilyBurden } from '@interfaces/family-allowance-form.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { attachFileCount } from '@validators/attach-file.validator';
import { familyBurdenValidator } from '@validators/family-burden.validator';

@Component({
  selector: 'app-modal-family-burden',
  templateUrl: './modal-family-burden.component.html',
  styleUrls: ['./modal-family-burden.component.scss'],
})
export class ModalFamilyBurdenComponent extends ModalComponent implements OnInit {
  @Input() pensionType: string;
  public pageContent = FAMILY_BURDEN_CONTENT;
  public familyBurden: FamilyBurden;
  public form: UntypedFormGroup;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public genders = GENDERS;
  public relationships = Object.entries(RELATIONSHIPS);
  public includeAttachmentFile = false;
  public loadData = false;
  public maxDate = new Date();
  public requiredFiles: AttachFiles;
  public keyFiles = [];
  private actionFiles;

  public get rut(): AbstractControl { return this.form.controls.rut; }
  public get isDisabled(): AbstractControl { return this.form.get('isDisabled'); }
  public get birthdateInput(): AbstractControl { return this.form.get('birthdateInput'); }
  public get relationshipId(): AbstractControl { return this.form.get('relationshipId'); }
  public get isSon(): boolean { return this.form.get('relationshipId').value === 'Hijo(a)'; }
  public get requestFiles(): boolean { return Boolean(this.requiredFiles) && this.requiredFiles.files.length > 0; }
  public get buttonText(): string {
    return this.familyBurden ? this.pageContent.buttons.updateBeneficiary : this.pageContent.buttons.addBeneficiary;
  }
  public get isValidForm(): boolean { return this.form.valid; }
  public get attachedFiles(): UntypedFormArray { return this.form.get('attachedFiles') as UntypedFormArray; }
  public get filesLoaded(): any {
    const filesObject = {};
    this.keyFiles.forEach((value, index) => {
      filesObject[value] = this.familyBurden.attachedFiles[index];
    });
    return filesObject;
  }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
    private utils: Utils,
    private cd: ChangeDetectorRef,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.familyBurden = this.params.get('item');
    if (this.familyBurden) { this.preloadFiles(); }

    this.loadData = true;
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public getControl(control: string): AbstractControl {
    return this.form.controls[control];
  }

  public setDate(dateName: string): void {
    this.formUtils.setDateSelected(this.form, dateName);
  }

  public async addFamilyBurden(): Promise<void> {
    if (!this.isValidForm) { return; }
    const {
      name,
      lastName,
      secondLastName,
      rut,
      birthdate,
      gender,
      startDate,
      isDisabled,
      relationshipId,
      attachedFiles,
    } = this.form.value;
    const familyBurden: FamilyBurden = {
      fullName: this.utils.getFullName(name, lastName, secondLastName),
      dirtyRut: rut,
      rut: this.utils.rutClean(rut),
      name, lastName, secondLastName,
      birthdate, startDate,
      gender, isDisabled,
      relationshipId,
      attachedFiles,
      keyFiles: this.keyFiles,
      fileActions: this.actionFiles
    };
    await this.closeModal(familyBurden);
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  public setFiles(data: any): void {
    const formArray = this.attachedFiles;
    this.clearAttachmentFiles();
    data.fileList.forEach((file: File) => formArray.push(new UntypedFormControl(file)));
    data.keyList.forEach((key: string) => this.keyFiles.push(key));
    this.actionFiles = data.actionList;
  }

  private createForm(): void {
    const birthdate = this.getFamilyBurdenAttribute('birthdate');
    const startDate = this.getFamilyBurdenAttribute('startDate');

    this.form = this.formBuilder.group({
      name: [this.getFamilyBurdenAttribute('name'), VALIDATORS.NAME],
      lastName: [this.getFamilyBurdenAttribute('lastName'), VALIDATORS.NAME],
      secondLastName: [this.getFamilyBurdenAttribute('secondLastName'), VALIDATORS.OPTIONAL_NAME],
      rut: [this.getFamilyBurdenAttribute('dirtyRut'), VALIDATORS.RUT],
      birthdate: [birthdate, Validators.required],
      birthdateInput: [birthdate ? new Date(birthdate) : '', Validators.required],
      startDate: [this.getFamilyBurdenAttribute('startDate'), Validators.required],
      startDateInput: [startDate ? new Date(startDate) : '', Validators.required],
      gender: [this.getFamilyBurdenAttribute('gender'), Validators.required],
      isDisabled: [this.getFamilyBurdenAttribute('isDisabled'), Validators.required],
      relationshipId: [this.getFamilyBurdenAttribute('relationshipId'), Validators.required],
      attachedFiles: this.formBuilder.array(this.getFamilyBurdenAttachmentFiles())
    }, { validator: familyBurdenValidator('birthdateInput', 'relationshipId', this.utils.calculateAge) });
    this.switchFilesRequested();
    this.form.controls['relationshipId'].setValue('Cónyuge');
  }

  private setAttachedFilesValidator(minFiles?: number): void {
    if (minFiles) {
      this.attachedFiles.setValidators(attachFileCount(minFiles));
    } else {
      this.attachedFiles.clearValidators();
    }
    this.attachedFiles.updateValueAndValidity();
  }

  private switchFilesRequested(): void {
    // tslint:disable-next-line: deprecation
    merge(
      this.birthdateInput.valueChanges,
      this.relationshipId.valueChanges,
      this.isDisabled.valueChanges
      // tslint:disable-next-line: deprecation
    ).subscribe(() => {
      const relation = this.relationshipId.value;
      const age = this.utils.calculateAge(this.birthdateInput.value);
      const isDisabled = this.isDisabled.value;

      this.requiredFiles = this.getRequiredFiles(relation, age, isDisabled);

      if (this.requiredFiles.files && this.requiredFiles.files.length > 0) {
        this.setAttachedFilesValidator(this.requiredFiles.files.length);
      } else {
        this.clearAttachmentFiles();
        this.setAttachedFilesValidator();
      }
      this.cd.detectChanges();
    });
  }

  private getFamilyBurdenAttribute(attribute: string): string {
    if (!this.familyBurden) { return ''; }
    return this.familyBurden[attribute];
  }

  private getFamilyBurdenAttachmentFiles(): [] {
    if (!this.familyBurden) { return []; }
    return this.familyBurden['attachedFiles'];
  }

  private clearAttachmentFiles(): void {
    this.attachedFiles.clear();
    this.keyFiles = [];
  }

  private getRequiredFiles(relationship: string, age: number, invalid: boolean): AttachFiles {
    const files = this.getFilesArray(relationship, age, invalid);
    files.forEach((file, index) => file.order = index);
    return { files };
  }

  private preloadFiles(): void {
    if (!this.familyBurden.keyFiles || this.familyBurden.keyFiles.length <= 0) {
      return;
    }
    this.keyFiles = this.familyBurden.keyFiles;
    this.requiredFiles = {
      files: Object.entries(REQUIRED_FILES)
        .filter(entry => this.keyFiles.includes(entry[0])).map(entry => entry[1])
    };
  }

  private getFilesArray(relationship: string, age: number, invalid: boolean): Array<AttachFile> {
    const files = invalid ? [REQUIRED_FILES.compin] : [];
    if (!relationship) { return files; }

    switch (relationship) {
      case 'Hijo(a)':
        if (age < 18) { return files; }
        return [
          ...files,
          REQUIRED_FILES.studies,
          REQUIRED_FILES.declaration,
        ];
      case 'Abuelo(a)':
      case 'Padre':
        return [
          ...files,
          REQUIRED_FILES.declaration
        ];
      case 'Cónyuge':
        return [
          ...files,
          REQUIRED_FILES.declaration,
          REQUIRED_FILES.marriage,
        ];
      case 'Madre Viuda':
        return [
          ...files,
          REQUIRED_FILES.declaration,
          REQUIRED_FILES.marriage,
          REQUIRED_FILES.death,
        ];
      case 'Nieto(a)':
      case 'Bisnieto(a)':
        return [
          ...files,
          REQUIRED_FILES.tribunal,
          REQUIRED_FILES.orphan,
        ];
      default: return files;
    }
  }
}
