const pageKey = 'AFFILIATE.FAMILY_BURDEN_MODAL';

export const RELATIONSHIPS = {
  C: 'Cónyuge',
  H: 'Hijo(a)',
  N: 'Nieto(a)',
  BN: 'Bisnieto(a)',
  P: 'Padre',
  M: 'Madre',
  MV: 'Madre Viuda',
  A: 'Abuelo(a)',
};

export const UPWARD_RELATIONSHIPS = [
  'Padre',
  'Madre',
  'Abuelo(a)',
];

export const MINIMUM_AGE = 65;

export const REQUIRED_FILES = {
  compin: {
    order: 1,
    buttonLabel: `${pageKey}.FILES.COMPIN`,
    fileIndex: 'compin',
    required: true
  },
  tribunal: {
    order: 2,
    buttonLabel: `${pageKey}.FILES.TRIBUNAL`,
    fileIndex: 'tribunal',
    required: true
  },
  declaration: {
    order: 3,
    buttonLabel: `${pageKey}.FILES.DECLARATION`,
    fileIndex: 'declaration',
    required: true
  },
  studies: {
    order: 4,
    buttonLabel: `${pageKey}.FILES.STUDIES`,
    fileIndex: 'studies',
    required: true
  },
  marriage: {
    order: 5,
    buttonLabel: `${pageKey}.FILES.MARRIAGE`,
    fileIndex: 'marriage',
    required: true
  },
  death: {
    order: 6,
    buttonLabel: `${pageKey}.FILES.DEATH`,
    fileIndex: 'death',
    required: true
  },
  orphan: {
    order: 7,
    buttonLabel: `${pageKey}.FILES.ORPHAN`,
    fileIndex: 'orphan',
    required: true
  }
};

export const FAMILY_BURDEN_CONTENT = {
  title: `${pageKey}.TITLE_ADD`,
  personalData: {
    title: `${pageKey}.PERSONAL_DATA.TITLE`,
    form: {
      rut: `${pageKey}.PERSONAL_DATA.FORM.RUT`,
      name: `${pageKey}.PERSONAL_DATA.FORM.NAME`,
      lastName: `${pageKey}.PERSONAL_DATA.FORM.LAST_NAME`,
      secondSurname: `${pageKey}.PERSONAL_DATA.FORM.SECOND_SURNAME`,
      birthdate: `${pageKey}.PERSONAL_DATA.FORM.BIRTHDATE`,
      gender: {
        label: `${pageKey}.PERSONAL_DATA.FORM.GENDER.LABEL`,
        male: `${pageKey}.PERSONAL_DATA.FORM.GENDER.MALE`,
        female: `${pageKey}.PERSONAL_DATA.FORM.GENDER.FEMALE`,
      },
      relationship: `${pageKey}.PERSONAL_DATA.FORM.RELATIONSHIP`,
      isDisabled: `${pageKey}.PERSONAL_DATA.FORM.IS_DISABLED`,
      benefitStart: `${pageKey}.PERSONAL_DATA.FORM.BENEFIT_START`,
    }
  },
  form: {
    positiveAnswer: `${pageKey}.FORM.POSITIVE_ANSWER`,
    negativeAnswer: `${pageKey}.FORM.NEGATIVE_ANSWER`,
  },
  errors: {
    patternLetters: `${pageKey}.ERRORS.PATTERN_LETTERS`,
    required: `${pageKey}.ERRORS.REQUIRED`,
    invalidRut: `${pageKey}.ERRORS.INVALID_RUT`,
    invalidCellphone: `${pageKey}.ERRORS.INVALID_CELLPHONE`,
    invalidEmail: `${pageKey}.ERRORS.INVALID_EMAIL`,
    invalidDomain: `${pageKey}.ERRORS.INVALID_DOMAIN`,
    mustMatch: `${pageKey}.ERRORS.MUST_MATCH`,
    invalidApplicant: `${pageKey}.ERRORS.INVALID_APPLICANT`,
  },
  buttons: {
    addBeneficiary: `${pageKey}.BUTTONS.ADD`,
    updateBeneficiary: `${pageKey}.BUTTONS.MODIFY`
  },
  disclaimer: {
    header: `${pageKey}.DISCLAIMER.HEADER`,
    disability: `${pageKey}.DISCLAIMER.DISABILITY`,
    study: `${pageKey}.DISCLAIMER.STUDY`,
  }
};
