<div class="info-banner-container" [ngClass]="infoBannerClass">
  <p
    *ngIf="banner?.firstLine && !showWithH1Tag"
    class="first-line"
    [ngClass]="[styleClass, font?.size]"
    [class.hidden]="loading"
    [innerHTML]="banner?.firstLine | translate">
  </p>
  <h1
    *ngIf="banner?.firstLine && showWithH1Tag"
    class="first-line"
    [ngClass]="[styleClass, font?.size]"
    [class.hidden]="loading"
    [innerHTML]="banner?.firstLine | translate">
  </h1>
  <h2
    *ngIf="banner?.secondLine"
    class="second-line"
    [ngClass]="[styleClass, font?.size]"
    [class.hidden]="loading"
    [innerHTML]="banner?.secondLine | translate">
  </h2>
  <ion-button *ngIf="banner?.url" class="button" [disabled]="loading" [ngClass]="[font?.size, style === 'contrast' ? 'primary' : 'secondary']" (click)="goToUrl()">
    <span [class.hidden]="loading">{{ banner?.button | translate }}</span>
  </ion-button>
</div>
