import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {

  constructor(
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) { }

  public async closeModal(data?: any) {
    await this.modalCtrl.dismiss(data);
  }

}
