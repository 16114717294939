import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_CORPORATE_GOVERNANCE_LINKS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              certificado: {
                titulo: 'Certificado Modelo Prevención de Delitos',
                items: [
                  {
                    titulo: 'Certificado Modelo de Prevención de Delitos AFP Planvital 2020-2022.',
                    url: 'Gobierno-Corporativo/Certificado-Modelo-Prevencion-Delitos/Certificado-Modelo-Prevencion-Delitos.pdf'
                  }
                ]
              },
              gobierno_corporativo: [
                {
                  titulo: 'Prácticas de Gobierno Corporativo.',
                  items: [
                    {
                      titulo: 'Prácticas de Gobierno Corporativo.',
                      url: 'Gobierno-Corporativo/Gobierno-Corporativo/Autoevaluacion%20Gobierno%20Corporativo%20A%C2%A7o%202019%20(NCG%20385)V1.pdf'
                    },
                    {
                      titulo: 'Política de Sostenibilidad y Gobierno Corporativo.',
                      url: 'Gobierno-Corporativo/Gobierno-Corporativo/politica-de-sost-y-gobierno-corporativo-mayo-2020.pdf'
                    }
                  ]
                },
                {
                  titulo: 'Personas relacionadas | Gestión de Conflictos de Interés',
                  items: [
                    {
                      titulo: 'Manual de Gestión de Conflictos de Interés.',
                      url: 'Gobierno-Corporativo/Personas-Relacionadas/Manual-Gestion-Conflicto-de-Interes-julio2019.pdf'
                    },
                    {
                      titulo: 'Activos de Baja Liquidez.',
                      url: 'Gobierno-Corporativo/Personas-Relacionadas/Activos-Baja-Liquidez-junio-2020.pdf'
                    }
                  ]
                },
                {
                  titulo: 'Formularios',
                  items: [
                    {
                      titulo: 'Formulario de Transacciones de Valores.',
                      url: 'Gobierno-Corporativo/Formularios/Formulario-Transacci%C2%A2n-Valores.pdf'
                    },
                    {
                      titulo: 'Formulario de Ingreso de Persona Relacionada.',
                      url: 'Gobierno-Corporativo/Formularios/Formulario-de-Identificacion-Persona-Relacionada.pdf'
                    },
                    {
                      titulo: 'Formulario de Identificación Cónyuge de Persona Relacionada.',
                      url: 'Gobierno-Corporativo/Formularios/Formulario-de-Identificacion-Conyuge-de-Persona-Relacionada.pdf'
                    }
                  ]
                },
                {
                  titulo: 'Gerencia de Cumplimiento',
                  items: [
                    {
                      titulo: 'Código de Conducta.',
                      url: 'Gobierno-Corporativo/Gerencia-Cumplimiento/Codigo-conducta-2019.pdf'
                    },
                    {
                      titulo: 'Modelo de Prevención de Delitos Ley N° 20.393.',
                      url: 'Gobierno-Corporativo/Gerencia-Cumplimiento/Modelo-de-Prevenci%C2%A2n-Delitos-Ley-20393-y-21.121.pdf'
                    },
                    {
                      titulo: 'Sistema de Prevención de Lavado de Activos y Financiamiento del Terrorismo.',
                      url: 'Gobierno-Corporativo/Gerencia-Cumplimiento/Manual-Sistema-Prevenci%C2%A2n-Lavado-de-Activos-y-Finciamiento-Terrorismo-26-01-2017.pdf'
                    },
                    {
                      titulo: 'Sistema de Prevención del Cohecho y otros delitos bases contemplados en la Ley 20.393.',
                      url: 'Gobierno-Corporativo/Gerencia-Cumplimiento/Sistema-Prevencion-de-Cohecho-y-otros-delitos.pdf'
                    },
                    {
                      titulo: 'Manual Manejo de información de interés para el Mercado.',
                      url: 'Gobierno-Corporativo/Gerencia-Cumplimiento/Manual-de-Manejo-de-Informacion-de-Interes-para-el-Mercado.pdf'
                    },
                    {
                      titulo: 'Politicas de Divulgación de la Información y Transparencia.',
                      url: 'Gobierno-Corporativo/Gerencia-Cumplimiento/Politica-Divulgacion-Transparencia-2018.pdf'
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
