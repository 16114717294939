import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalBeneficiaryComponent } from '@components/modal-beneficiary/modal-beneficiary.component';
import { BENEFICIARY_PROPERTIES } from '@constants/forms.constant';
import { Beneficiary } from '@interfaces/beneficiary.interface';
import { DefaultResponseItem } from '@interfaces/general.interface';
import { BackgroundOptions, RequestParams } from '@interfaces/state-guarantee-form.interface';
import { BACKGROUND_KEYS, INVERTED_DECLARATIONS, StateGuaranteeBackgroundOptionKeys } from '@pages-content/state-guarantee-form.constants';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { ErrorUtils } from '@utils/error.utils';

@Component({
  selector: 'app-state-guarantee-declarations',
  templateUrl: './state-guarantee-declarations.component.html',
  styleUrls: ['../state-guarantee.page.scss']
})
export class StateGuaranteeDeclarationsComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public isSurvivorPension = false;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public back: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup;
  public beneficiaryProperties = BENEFICIARY_PROPERTIES;
  public modalType = ModalBeneficiaryComponent;
  public modalData: any;
  public declarations: any;

  private relationships: Array<DefaultResponseItem>;
  private beneficiaries: Array<Beneficiary> = [];
  private backgrounds: UntypedFormArray;
  private backgroundKeys: StateGuaranteeBackgroundOptionKeys[];

  public get controlKeys(): Array<string> { return Object.keys(this.form.controls); }
  public get backgroundsValid(): boolean {
    return this.backgrounds && this.backgrounds.controls.every(({ value }) => value.accepted !== null);
  }
  public get isValid(): boolean { return Object.values(this.form.controls).some(ctrl => ctrl.value) && this.backgroundsValid; }

  constructor(
    public font: FontService,
    private errorUtils: ErrorUtils,
    private formBuilder: UntypedFormBuilder,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private parametersService: ParametersService,
  ) { }

  public ngOnInit(): void {
    this.createForm();
    this.loadRelationships();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.declarations = _.cloneDeep(this.pageContent.backgrounds);
    this.backgroundKeys = _.cloneDeep(BACKGROUND_KEYS);
    if (changes.isSurvivorPension.firstChange) { return; }
    if (!this.isSurvivorPension) {
      this.declarations.options.pop();
      this.backgroundKeys.pop();
    }
  }

  public setBackgrounds(backgrounds: UntypedFormArray): void {
    this.backgrounds = backgrounds;
  }

  public setBeneficiaries(beneficiaries: Array<Beneficiary>): void {
    this.beneficiaries = beneficiaries;
  }

  public confirm(): void {
    if (!this.isValid) { return; }
    const data: RequestParams = {
      toFinance: this.parsePensionOptions(),
      backgroundOptions: this.parseBackgrounds(),
      beneficiaries: this.beneficiaries
    };

    this.formData.emit(data);
  }

  public previousStep(): void {
    this.back.emit();
  }

  private parsePensionOptions(): Array<string> {
    const options: Array<string> = [];
    const formValue = this.form.value;

    if (formValue.myPension) { options.push('0'); }
    if (formValue.offspringsPension) { options.push('1'); }
    if (formValue.representedPension) { options.push('2'); }

    return options;
  }

  private parseBackgrounds(): Array<BackgroundOptions> {
    const backgrounds: Array<BackgroundOptions> = [];
    const arrayValues = this.backgrounds.value;
    this.backgroundKeys.forEach((id, index) => {
      if (INVERTED_DECLARATIONS.includes(id) ? arrayValues[index].accepted : !arrayValues[index].accepted) { return; }

      const background: BackgroundOptions = { id };
      const description = arrayValues[index].detail;

      if (description) { background.description = description; }

      backgrounds.push(background);
    });

    return backgrounds;
  }

  private loadRelationships(): void {
    this.loadingService.showLoading();

    this.parametersService.getRelationships()
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (relationships) => {
            this.relationships = relationships;
            this.modalData = { includeAttachmentFile: false, relationships: this.relationships };
        },
        (error) => this.handleError(error)
      );
  }

  private handleError(error): void {
    this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      myPension: [false, Validators.required],
      offspringsPension: [false, Validators.required],
      representedPension: [false, Validators.required]
    });
  }
}
