import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AUVIOUS_SCRIPT } from '@constants/auvious.constant';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public displayVideocall(): void {
    const videocall = this.document.querySelector('app-auvious-widget');
    this.renderer.setStyle(videocall, 'display', 'initial');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videocall.launch('video');
  }

  public endVideocall(): void {
    const videocall = this.document.querySelector('app-auvious-widget');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videocall.terminate(false);
    this.renderer.setStyle(videocall, 'display', 'none');
  }

  public auviousScript() {
    const script = this.renderer.createElement('script');
    script.innerHTML = AUVIOUS_SCRIPT;
    this.renderer.appendChild(document.head, script);
  }


}
