import { Component, OnInit } from '@angular/core';
import { ProfitabilityTable } from '@interfaces/multi-funds.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-multi-funds-profitability',
  templateUrl: './multi-funds-profitability.component.html',
  styleUrls: ['./multi-funds-profitability.component.scss'],
})
export class MultiFundsProfitabilityComponent implements OnInit {
  public data: ProfitabilityTable;
  public properties: Array<string>;
  constructor(
    public font: FontService,
  ) {}

  ngOnInit() {
    this.properties = Object.keys(this.data.header);
  }
}
