import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_HEALTHCARE_INSTITUTIONS_MOCK: Mockup = {
  success: [{
    response: {
      data: {
        instituciones_salud: {
          contenido: [
            'Fonasa',
            'Banmedica S.A.',
            'Chuquicamata LTDA.',
            'Colmena Golden Cross S.A.',
            'Consalud S.A.',
            'Cruz Blanca S.A.',
            'Cruz Del Norte LTDA.',
            'FerroSalud S.A.',
            'Fundacion LTDA.',
            'Fusat S.A.',
            'Normedica S.A.',
            'Nueva MasVida S.A.',
            'Rio Blanco LTDA.',
            'San Lorenzo LTDA.',
            'Vida Tres S.A.',
          ]
        }
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};
