import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PREFIX } from '@constants/employer.constant';
import { PrimaryOption } from '@interfaces/header.interface';
import { NavController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { RouterService } from '@providers/router/router.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
})
export class TabNavigationComponent {
  @Input() public navigation = true;
  @Input() public tabs: Array<PrimaryOption>;
  @Input() public activeTab: string;
  @Output() private tabSelected = new EventEmitter<string>();
  private prefix = PREFIX;

  constructor(
    public font: FontService,
    public routerService: RouterService,
    private navCtrl: NavController,
    private translateService: TranslateService
  ) { }

  public tabClicked(event: any, url: string) {
    event.preventDefault();
    this.activeTab = url;
    this.navigation ? this.goTo(url) : this.tabSelected.emit(url);
  }

  public goTo(url: string) {
    if (!url) { return; }
    this.navCtrl.navigateForward(url);
  }

  public get currentUrl(): string {
    return this.routerService ? this.routerService.currentUrl : '';
  }

  public get currentTab(): string {
    return this.navigation ? this.currentUrl : this.activeTab;
  }

  public tabName(tabName: string): string {
    const tabNameSplit = tabName.split(' ');
    if (tabNameSplit[0] === this.prefix) {
     return this.translateService.instant(this.prefix) + ' ' + this.translateService.instant(tabNameSplit[1]);
    }
    return this.translateService.instant(tabName.toString());
  }

}
