import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from '@providers/google-analytics/google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  public currentUrl: string;
  public currentSection: string;
  public lastSection: string;
  public lastValidModule: string;
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {
    this.subscribeUrl();
  }

  private subscribeUrl(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.googleAnalyticsService.registerPageView(event.urlAfterRedirects);
        this.currentUrl = event.urlAfterRedirects.slice(1);
        this.updateSections();
      });
  }

  private updateSections(): void {
    if (!this.currentSection && !this.lastSection) {
      this.currentSection = this.getSection(this.currentUrl);
      this.lastSection = this.getSection(this.currentUrl);
    } else if (this.currentSection !== this.getSection(this.currentUrl)) {
      this.lastSection = this.currentSection;
      this.currentSection = this.getSection(this.currentUrl);
    }
  }

  public getSection(url: string): string {
    return url.split('/')[0];
  }

  public get selectedModule(): string { return this.currentSection; }
  public get lastSelectedModule(): string { return this.lastSection; }

  public isValidModule(module: string): boolean {
      return module === 'afiliado' || module === 'pensionado' || module === 'empleador';
  }

  public get defaultModule() {
    const isFirstModule = this.selectedModule === this.lastSelectedModule;
    isFirstModule ? this.setFirstValidModule() : this.setValidModule();
    return this.lastValidModule;
  }

  private setFirstValidModule(): void {
    const affiliateModule = 'afiliado';
    this.lastValidModule = this.isValidModule(this.selectedModule) ? this.selectedModule : affiliateModule;
  }

  private setValidModule(): void {
    if (this.isValidModule(this.selectedModule)) {
      this.lastValidModule = this.selectedModule;
    } else if (this.isValidModule(this.lastSelectedModule) ) {
      this.lastValidModule = this.lastSelectedModule;
    }
  }
}
