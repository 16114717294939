<div class="quota-values-section">
  <div class="quota-values-container general-container">
    <app-quota-values
      [content]="fundsContainer"
      [fundLabel]="fundsContainer?.fundName"
      [chartTitle]="fundsContainer?.chartTitle"
      class="quota-values"
      [loading]="loading">
    </app-quota-values>
    <app-more-information
      class="detail"
      [disclaimer]="fundsContainer?.disclaimer"
      [buttonText]="fundsContainer?.disclaimerButton"
      [buttonUrl]="financialInformation.quotaValue.url"
      [loading]="loading">
    </app-more-information>
  </div>
</div>
