import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArrayIcon, MAX_ARRAY_LIST } from '@constants/modal-data.constant';
import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';

@Component({
  selector: 'app-form-array-list',
  templateUrl: './form-array-list.component.html',
  styleUrls: ['./form-array-list.component.scss'],
})
export class FormArrayListComponent<T> {
  @Input() public pageContent: any;
  @Input() public iconClass: FormArrayIcon = 'pension';
  @Input() public itemProperties: Array<FormArrayItemProperties>;
  @Input() public modalType: ComponentType<any>;
  @Input() public modalData?: any = {};
  @Input() public maxFiles?: number;
  @Input() public items: Array<T> = [];
  @Output() public edited: EventEmitter<Array<T>> = new EventEmitter();
  @Output() public removed: EventEmitter<Array<T>> = new EventEmitter();
  @Output() public editedElement: EventEmitter<T> = new EventEmitter();

  public get isMaxProperties(): boolean { return this.itemProperties.length === MAX_ARRAY_LIST; }
  public get itemsCount(): number { return this.items.length; }

  constructor(
    public font: FontService,
    public translate: TranslateService,
    private service: ModalService
  ) { }

  public getItemProperty(item: T, property: FormArrayItemProperties): string {
    const name = typeof property.name === 'string' ? item[property.name] : property.name(item);
    return property.translated ? name : this.translate.instant(name);
  }

  public async addItem(): Promise<void> {
    const callback = (itemFromModal: T) => {
      if (!itemFromModal || this.items.find(item => item === itemFromModal)) { return; }
      this.items.push(itemFromModal);
      this.edited.emit(this.items);
      this.editedElement.emit(itemFromModal);
    };
    return await this.openModal(callback);
  }

  public async editItem(selectedItem: T): Promise<void> {
    const item = this.items.find(obj => obj === selectedItem);
    const index = this.items.indexOf(item);

    if (!item) { return; }

    const callback = (updatedItem: T) => {
      if (!updatedItem) { return; }
      this.items[index] = updatedItem;
      this.edited.emit(this.items);
      this.editedElement.emit({ ...updatedItem, edited: true });
    };
    return await this.openModal(callback, item);
  }

  public removeItem(selectedItem: T): void {
    const item = this.items.find(obj => obj === selectedItem);
    const index = this.items.indexOf(item);
    if (index < 0) { return; }

    this.items.splice(index, 1);
    this.edited.emit(this.items);
    this.removed.emit(this.items);
  }

  private async openModal(callback, item?: T): Promise<void> {
    const props = { ...this.modalData };
    if (item) { props['item'] = item; }

    return await this.service.openModal(
      this.modalType,
      props,
      'modal-default full-modal',
      callback
    );
  }
}
