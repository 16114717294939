import {
  Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { Modal } from '@interfaces/info-card.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

import {
  MultiFundsAssignationComponent
} from './multi-funds-assignation/multi-funds-assignation.component';
import {
  MultiFundsProfitabilityComponent
} from './multi-funds-profitability/multi-funds-profitability.component';
import {
  MultiFundsRestrictionComponent
} from './multi-funds-restrictions/multi-funds-restrictions.component';

@Component({
  selector: 'app-modal-primary',
  templateUrl: './modal-primary.component.html',
  styleUrls: ['./modal-primary.component.scss'],
})
export class ModalPrimaryComponent<Type> extends ModalComponent implements OnInit {
  public modalInfo: Modal<Type>;
  public componentRef: any;
  public dataComponent: any;
  @ViewChild('informationComponent', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;

  constructor(
    public font: FontService,
    private resolver: ComponentFactoryResolver,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.modalInfo = this.params.get('data');
    this.dataComponent = this.params.get('component');
  }

  public ngOnInit(): void {
    this.createComponent();
  }

  public createComponent(): void {
    this.entry.clear();
    this.setDataComponent();
    const factory = this.resolver.resolveComponentFactory(this.dataComponent);
    this.componentRef = this.entry.createComponent(factory);
    this.componentRef.instance.data = this.modalInfo.data;
  }

  private setDataComponent(): void {
    switch (this.modalInfo.type) {
      case 'restrictions':
        this.dataComponent = MultiFundsRestrictionComponent;
        break;
      case 'assignation':
        this.dataComponent = MultiFundsAssignationComponent;
        break;
      case 'profitability':
        this.dataComponent = MultiFundsProfitabilityComponent;
        break;
    }
  }
}
