export const assessmentPath = 'asesoria';
export const pensionerAssessmentPapework = 'pensionado/asesoria/centro-tramites';
export const affiliateAssessmentPapework = 'afiliado/asesoria/centro-tramites';

export const pensionerPaths = {
  assessmentPath: {
    paperworkCenterPath: 'centro-tramites',
    familyAllowancePath: 'solicitud-asignacion-familiar',
    certifyStudiesPath: 'acreditar-estudios',
    mortuaryQuotaPath: 'solicitud-cuota-mortuoria',
    inheritanceRequestPath: 'solicitud-herencia',
    chargingFeesPath: 'cobro-honorarios',
    stateGuaranteePath: 'garantia-estatal',
    followUpPath: 'consulta-solicitudes',
    requestDetailPath: 'detalle-solicitud',
    studiesAccreditationPath: 'acreditar-estudios',
  }
};

export const affiliatePaths = {
  assessmentPath: {
    futurePensionerPath: 'futuro-pensionado',
    multiFundsPath: 'multifondos',
    financialInformationPath: 'informacion-financiera',
    simulatorsPath: 'simuladores',
    certificatesPath: 'certificados',
    paperworkCenterPath: 'centro-tramites',
    survivorPensionPath: 'pension-sobrevivencia',
    followUpPath: 'consulta-solicitudes',
    requestDetailPath: 'detalle-solicitud',
    employerDiscount: 'descuento-empleador',
    transferFundsFromIndividualAccount: 'transferencia-fondos-cuenta-cesantia',
    additionalPensionPath: 'pension-adicional',
    depositIps: 'deposit-ips',
    regulatoryDocuments: 'mandatos',
    transferSurvivorPensionBeneficiaries: 'transferencia-pension-sobrevivencia',
  }
};

export const applicationsAffiliateStatePath = `afiliado/asesoria/${affiliatePaths.assessmentPath.followUpPath}`;
export const applicationsPensionerStatePath = `pensionado/asesoria/${pensionerPaths.assessmentPath.followUpPath}`;
export const applicationsAffiliateDetailRequest = `afiliado/asesoria/${affiliatePaths.assessmentPath.requestDetailPath}`;
export const applicationsPensionerDetailRequest = `pensionado/asesoria/${pensionerPaths.assessmentPath.requestDetailPath}`;
