import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { InvestmentFund } from '@interfaces/financial-information.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-investment-table-fund',
  templateUrl: './modal-investment-table-fund.component.html',
  styleUrls: ['./modal-investment-table-fund.component.scss'],
})
export class ModalInvestmentTableFundComponent extends ModalComponent {
  public title: string;
  public data: InvestmentFund;
  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.title = this.params.get('title');
    this.data = this.params.get('data');
  }

}
