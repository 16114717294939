import * as moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  HttpClientInterceptor
} from '@interceptors/http-client-interceptor/http-client-interceptor';
import {
  EconomicIndicators, LastTaxYears, UfValue, UtmValue
} from '@interfaces/financial-indicators.interface';
import { QuotaValues, QuotaValuesFile } from '@interfaces/quota-values.interface';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';

import * as mocks from './mocks/financial-indicators.mock';

@Injectable({
  providedIn: 'root'
})
export class FinancialIndicatorsService {
  private baseUrl = `${environment.proxyApiUrl}financial-indicators`;

  constructor(
    private http: HttpClientInterceptor,
    private utils: Utils,
    private errorUtils: ErrorUtils) { }

  public getEconomicIndicators(date = new Date(Date.now())): Observable<EconomicIndicators> {
    const data = { date: this.utils.getFormattedDate(date) };
    const url = `${this.baseUrl}/v2`;
    return this.http.get(url, mocks.FINANCIAL_INDICATORS_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getLastTaxYears(): Observable<LastTaxYears> {
    const url = `${this.baseUrl}/last-tax-years`;
    return this.http.get(url, mocks.LAST_TAX_YEARS_MOCKUP)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getQuotaValues(startDate: Date, endDate: Date): Observable<QuotaValues> {
    const url = `${this.baseUrl}/quota-values`;
    const data = {
      startDate: this.utils.getFormattedDate(startDate),
      endDate: this.utils.getFormattedDate(endDate),
    };
    return this.http.get(url, mocks.QUOTA_VALUES_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getQuotaValuesFile(startDate: Date, endDate: Date): Observable<QuotaValuesFile> {
    const url = `${this.baseUrl}/quota-values/download`;
    const data = {
      startDate: this.utils.getFormattedDate(startDate),
      endDate: this.utils.getFormattedDate(endDate),
    };
    return this.http.get(url, mocks.QUOTA_VALUES_FILE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getNormativeQuotaValues(date: string): Observable<QuotaValues> {
    const url = `${this.baseUrl}/normative-quota-values`;
    const dateN = moment(date, 'DD/MM/YYYY').utcOffset('-03:00');
    const formattedDate = dateN.format('YYYY-MM-DD');
    const data = {
      date: formattedDate,
    };
    return this.http.get(url, mocks.QUOTA_VALUES_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public materializationDate(): Observable<string> {
    const date = moment().add(0, 'days').format('YYYY-MM-DD');
    const url = `${environment.clientsUrl}financial-indicators/businessdays?date=${date}`;
    return this.http.get(`${url}`, mocks.MATERIALIZATION_DATE_MOCK, { site: 'public', step: '-2' })
      .pipe(map(response => response.date));

  }

  public getUf(date = new Date(Date.now())): Observable<UfValue> {
    const url = `${this.baseUrl}/uf`;
    const data = { date: this.utils.getFormattedDate(date) };
    return this.http.get(url, mocks.UF_VALUE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public getUtm(date = new Date(Date.now())): Observable<UtmValue> {
    const url = `${this.baseUrl}/utm`;
    const data = { date: this.utils.getFormattedDate(date) };
    return this.http.get(url, mocks.UTM_VALUE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
