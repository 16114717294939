import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PensionPaymentPage } from './pension-payment.page';

const routes: Routes = [
  {
    path: '',
    component: PensionPaymentPage,
    children: [
      { path: '', redirectTo: 'calendario', pathMatch: 'full' },
      {
        path: 'calendario',
        loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule),
      },
      {
        path: 'inscribir-cuenta',
        loadChildren: () => import('./register-account/register-account.module').then( m => m.RegisterAccountPageModule)
      },
      {
        path: 'tipos-pension',
        loadChildren: () => import('./pension-types/pension-types.module').then( m => m.PensionTypePageModule)
      },
      {
        path: 'modalidad-pension',
        loadChildren: () => import('./pension-modes/pension-modes.module').then( m => m.PensionModesPageModule)
      },
      {
        path: 'ofertas-pension',
        loadChildren: () => import('./pension-offers/pension-offers.module').then( m => m.PensionOffersPagePageModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PensionPaymentPageRoutingModule {}
