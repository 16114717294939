import { Component, Input } from '@angular/core';
import { UrlSection } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-download-section',
  templateUrl: './download-section.component.html',
  styleUrls: ['./download-section.component.scss'],
})
export class DownloadSectionComponent {
  @Input() list: Array<UrlSection> = [];
  @Input() public loading = false;

  constructor(
    public font: FontService,
    private utils: Utils,
  ) {}

  public filterList(): Array<UrlSection> {
    if (!this.list) { return []; }
    return this.list.filter(({ title }) =>  !!title );
  }

  public downloadFile(downloadUrl: string, title: string) {
    this.utils.downloadFile(downloadUrl, title);
  }
}
