<div class="slider-container" [ngClass]="typeClass">
  <app-slider-navigation color="gray" class="navigation general-container" (direction)="navigate($event)"
    [ngClass]="typeClass"></app-slider-navigation>
  <swiper-container #swiperRef class="slides-infographic secondary-bullets infographics-slides" pager="true"
    [config]="slideOpts">
    <swiper-slide class="infographic" *ngFor="let infographic of infographics">
      <app-infographic [infographic]="infographic" [type]="type" [loading]="loading" [product]="product"
        (clickMoreInformation)="openModalInformation(infographic)"></app-infographic>
    </swiper-slide>
  </swiper-container>
</div>