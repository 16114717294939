<mat-accordion>
  <mat-expansion-panel class="item" *ngFor="let content of list">
    <mat-expansion-panel-header class="header">
      <mat-panel-title *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="content.title | translate"></mat-panel-title>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line" animated></ion-skeleton-text>
    </mat-expansion-panel-header>
    <div *ngFor="let section of content.downloadSectionList">
      <div *ngIf="section?.title" class="dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="section.title | translate"></div>
      <app-download-section [list]="section.urlSectionList" [loading]="loading"></app-download-section>
    </div>
  </mat-expansion-panel>
</mat-accordion>
