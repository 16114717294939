import { SOCIAL_URL } from './static-link.constant';

export const SOCIAL_MEDIA_CHANNELS = [
  {
    name: 'facebook',
    url: SOCIAL_URL.facebook,
    title: 'AFPPlanVital',
  },
  {
    name: 'instagram',
    url: SOCIAL_URL.instagram,
    title: '@planvitalafp',
  },
  {
    name: 'linkedin',
    url: SOCIAL_URL.linkedin,
    title: 'AFP PlanVital',
  },
  {
    name: 'xtwitter',
    url: SOCIAL_URL.twitter,
    title: '@planvitalafp',
  },
  {
    name: 'youtube',
    url: SOCIAL_URL.youtube,
    title: 'AFP PlanVital'
  },
];
