import { DefaultResponseItem } from '@interfaces/general.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const BANKS_MOCKUP: Mockup = {
  success: [{
    response: [
      {
        id: '16',
        description: 'BANCO BCI'
      },
      {
        id: '28',
        description: 'BANCO BICE'
      },
      {
        id: '55',
        description: 'BANCO CONSORCIO'
      },
      {
        id: '1',
        description: 'BANCO DE CHILE'
      },
      {
        id: '51',
        description: 'BANCO FALABELLA'
      },
      {
        id: '9',
        description: 'BANCO INTERNACIONAL'
      },
      {
        id: '39',
        description: 'BANCO ITAU'
      },
      {
        id: '57',
        description: 'BANCO PARIS'
      },
      {
        id: '56',
        description: 'BANCO PENTA'
      },
      {
        id: '53',
        description: 'BANCO RIPLEY'
      },
      {
        id: '509',
        description: 'BANCO SANTANDER'
      },
      {
        id: '49',
        description: 'BANCO SECURITY'
      },
      {
        id: '504',
        description: 'BBVA'
      },
      {
        id: '27',
        description: 'CORPBANCA'
      },
      {
        id: '52',
        description: 'DEUTSCHE BANK (CHILE)'
      },
      {
        id: '12',
        description: 'ESTADO'
      },
      {
        id: '14',
        description: 'SCOTIABANK CHILE'
      }
    ] as Array<DefaultResponseItem>
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
