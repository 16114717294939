import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { CmsUtils } from '@utils/cms.utils';

@Component({
  selector: 'app-modal-app-stores',
  templateUrl: './modal-app-stores.component.html',
  styleUrls: ['./modal-app-stores.component.scss'],
})
export class ModalAppStoresComponent extends ModalComponent implements OnInit {

  public lang: string;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private cmsUtils: CmsUtils,
  ) {
    super(params, modalCtrl);
  }

  ngOnInit() {
    this.lang = this.cmsUtils.getLanguage();
  }

}
