<div *ngIf="!foundAccountSaved">
    <div class="sub-title-mandatory">
        <p id="tableDesc" class="sub-title-mandatory">{{accountTextInfo +
            ".TITLE" | translate}}</p>
        <p class="sub-title-mandatory red-info" style="margin-top: 20px; margin-bottom: 20px;">{{accountTextInfo +
            ".DESCRIPTION" | translate}}</p>
    </div>
    <form class="table-container" [formGroup]="accountForm">
        <table aria-describedby="tableDesc" class="founds-table-din" style="table-layout: fixed">
            <colgroup>
                <col style="width: 130px" />
                <col style="width: 130px" />
                <col style="width: 130px" />
                <col style="width: 130px" />
                <col style="width: 130px" />
                <col style="width: 130px" />
            </colgroup>
            <thead>
                <tr>
                    <th colspan="2">{{accountTextInfo + ".PRODCUT_TYPES.HEADER" | translate}}</th>
                    <th colspan="2">{{accountTextInfo + ".FOUND_ONE" | translate}}</th>
                    <th colspan="2">{{accountTextInfo + ".FOUND_TWO" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr formGroupName="mandatoryContribution">
                    <td class="founds-table-data" colspan="2">
                        <p style="margin-bottom: 5px; font-weight: 700;">{{accountTextInfo + ".PRODCUT_TYPES.PRODUCT" |
                            translate}}
                        </p>
                        <p>
                            {{accountTextInfo + ".PRODCUT_TYPES.MANDATORY_CONTRIBUTION" | translate}}
                        </p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr formGroupName="voluntarySavings">
                    <td class="founds-table-data" colspan="2">
                        <p style="margin-bottom: 5px; font-weight: 700;">{{accountTextInfo + ".PRODCUT_TYPES.PRODUCT" |
                            translate}}</p>
                        <p>
                            {{accountTextInfo + ".PRODCUT_TYPES.VOLUNTARY_SAVINGS" |
                            translate}}
                        </p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr formGroupName="agreedDeposit">
                    <td class="founds-table-data" colspan="2">
                        <p style="margin-bottom: 5px; font-weight: 700;">{{accountTextInfo + ".PRODCUT_TYPES.PRODUCT" |
                            translate}}</p>
                        <p>
                            {{accountTextInfo + ".PRODCUT_TYPES.AGREED_DEPOSIT" |
                            translate}}
                        </p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr formGroupName="voluntaryContribution">
                    <td class="founds-table-data" colspan="2">
                        <p style="margin-bottom: 5px; font-weight: 700;">{{accountTextInfo + ".PRODCUT_TYPES.PRODUCT" |
                            translate}}</p>
                        <p>
                            {{accountTextInfo + ".PRODCUT_TYPES.VOLUNTARY_CONTRIBUTION" |
                            translate}}
                        </p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr formGroupName="voluntaryAffiliate">
                    <td class="founds-table-data" colspan="2">
                        <p style="margin-bottom: 5px; font-weight: 700;">{{accountTextInfo + ".PRODCUT_TYPES.PRODUCT" |
                            translate}}</p>
                        <p>
                            {{accountTextInfo + ".PRODCUT_TYPES.VOLUNTARY_AFFILIATE" |
                            translate}}
                        </p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="firstDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondOrigin">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <mat-form-field [ngClass]="font?.size" floatLabel='never'>
                            <mat-label class="input-placeholder">Selecciona</mat-label>
                            <mat-select matNativeControl required formControlName="secondDestiny">
                                <mat-option [value]="foundOptions.C">C</mat-option>
                                <mat-option [value]="foundOptions.D">D</mat-option>
                                <mat-option [value]="foundOptions.E">E</mat-option>
                                <mat-option [value]="foundOptions.NE">No aplica</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
    <div>
        <div class="text-info" style="max-width: 750px; margin-top: 40px;">
            <span [innerHtml]="accountTextInfo + '.INFO' | translate"></span>
        </div>
        <section class="note-disclaimer-red" style="margin-bottom: 40px; margin-top: 40px; max-width: 700px;">
            <p>
                <ion-icon class="icon" name="alert-circle"></ion-icon>
                <span [innerHtml]="accountTextInfo + '.DISCLAIMER' | translate"></span>
            </p>
        </section>
    </div>
    <section class="buttons-footer">
        <ion-button class="button secondary button-back" (click)="goBackForm()">{{textInfo+'.GO_BACK' |
            translate}}</ion-button>
        <ion-button class="button primary" type="submit" [disabled]="!validateAccountsForm()"
            (click)="saveAccountFounds()">{{textInfo+'.CONTINUE' | translate}}</ion-button>
    </section>
</div>

<div *ngIf="foundAccountSaved">
    <p id="accountTextInfo" class="sub-title-mandatory">{{ accountTextInfo + ".RESUME.TITLE" | translate}}</p>
    <p class="sub-title-mandatory red-info" style="padding-top: 10px; max-width: 1000px;">{{ accountTextInfo +
        ".RESUME.DESCRIPTION" | translate}}</p>
    <div class="table-container">
        <table aria-describedby="accountTextInfo" class="founds-table" style="table-layout: auto;">
            <colgroup>
                <col style="width: 100px" />
                <col style="width: 190px" />
                <col style="width: 190px" />
                <col style="width: 190px" />
                <col style="width: 190px" />
                <col style="width: 190px" />
            </colgroup>
            <thead>
                <tr>
                    <th colspan="2">{{accountTextInfo + ".PRODCUT_TYPES.HEADER" | translate}}</th>
                    <th colspan="2">{{accountTextInfo + ".FOUND_ONE" | translate}}</th>
                    <th colspan="2">{{accountTextInfo + ".FOUND_TWO" | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="founds-table-data" colspan="2">{{accountTextInfo +
                        ".PRODCUT_TYPES.MANDATORY_CONTRIBUTION" |
                        translate}}</td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.mandatoryContribution.firstOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.mandatoryContribution.firstDestiny}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.mandatoryContribution.secondOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.mandatoryContribution.secondDestiny}}</p>
                    </td>
                </tr>
                <tr>
                    <td class="founds-table-data" colspan="2">
                        {{accountTextInfo + ".PRODCUT_TYPES.VOLUNTARY_SAVINGS" |
                        translate}}
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntarySavings.firstOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntarySavings.firstDestiny}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntarySavings.secondOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntarySavings.secondDestiny}}</p>
                    </td>
                </tr>
                <tr>
                    <td class="founds-table-data" colspan="2">
                        {{accountTextInfo + ".PRODCUT_TYPES.AGREED_DEPOSIT" |
                        translate}}
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.agreedDeposit.firstOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.agreedDeposit.firstDestiny}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.agreedDeposit.secondOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.agreedDeposit.secondDestiny}}</p>
                    </td>
                </tr>
                <tr>
                    <td class="founds-table-data" colspan="2">
                        {{accountTextInfo + ".PRODCUT_TYPES.VOLUNTARY_CONTRIBUTION" |
                        translate}}
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryContribution.firstOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryContribution.firstDestiny}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryContribution.secondOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryContribution.secondDestiny}}</p>
                    </td>
                </tr>
                <tr>
                    <td class="founds-table-data" colspan="2">
                        {{accountTextInfo + ".PRODCUT_TYPES.VOLUNTARY_AFFILIATE" |
                        translate}} </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryAffiliate.firstOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryAffiliate.firstDestiny}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".ORIGIN_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryAffiliate.secondOrigin}}</p>
                    </td>
                    <td class="founds-table-data">
                        <p>{{accountTextInfo + ".DESTINY_FOUND" | translate}}</p>
                        <p class="found-data">{{accountFormValues.voluntaryAffiliate.secondDestiny}}</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <section class="note-disclaimer-red">
            <p>
                <ion-icon class="icon" name="alert-circle"></ion-icon>
                {{ accountTextInfo + ".RESUME.DISCLAIMER" | translate}}
            </p>
        </section>
    </div>
    <section class="buttons-footer">
        <ion-button class="button secondary button-back" (click)="goBackSaveAccountFounds()">{{textInfo+'.GO_BACK' |
            translate}}</ion-button>
        <ion-button class="button primary" type="submit" (click)="sendForm()">{{textInfo+'.CONTINUE' |
            translate}}</ion-button>
    </section>
</div>