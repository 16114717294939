<div class="alert-section">
  <div class="general-container alert-container">
    <div class="left-container">
      <div class="icon i-warning"></div>
      <div class="message-container disclaimer" [ngClass]="font?.size">
        <div *ngIf="!loading" class="alert-title">{{ data?.title }}</div>
        <div *ngIf="!loading" class="alert-message" [ngClass]="bodyStyle">{{ shortenedBodyMessage }}
          <span *ngIf="showMoreText" class="more-info-alert" [ngClass]="font?.size" (click)="setBodyStyle()">
            <strong>Ver más</strong>
          </span>
          <div *ngIf="data?.modal && bodyStyle === 'large-body' || !showMoreText && data?.modal"
            class="more-info-alert inside" [ngClass]="font?.size" (click)="openInformationModal()">
            <p [innerHTML]=" buttonText | translate"></p>
          </div>
        </div>
        <ion-skeleton-text *ngIf="loading" class="skeleton-line small" animated></ion-skeleton-text>
        <ion-skeleton-text *ngIf="loading" class="skeleton-line" animated></ion-skeleton-text>
      </div>
      <div *ngIf="data?.modal && largeBody" class="more-info-alert outside" [ngClass]="font?.size"
        (click)="openInformationModal()">
        <p [innerHTML]="buttonText | translate"></p>
      </div>
      <ion-icon class="icon close-alert" name="close" (click)="hideBanner()"></ion-icon>
    </div>
  </div>
</div>