<div class="card-container" [class.disabled-component]="disabled">
  <div class="header-card">
    <div class="title" [ngClass]="font?.size">{{ pageContent?.comments.title | translate }}</div>
    <div class="title sort-section" (click)="changeSort()" [ngClass]="font?.size">
      {{ pageContent?.comments.sort | translate }} <div class="icon" [class.i-down-arrow-red]="downSort"
        [class.i-up-arrow-red]="!downSort"></div>
    </div>
  </div>
  <div class="column-container spaced">
    <app-comment *ngIf="externalStarredComment" [comment]="externalStarredComment" [starred]="true"></app-comment>
    <div class="title sort-section" (click)="changeSort()" [ngClass]="font?.size">
      {{ pageContent?.comments.sort | translate }} <div class="icon" [class.i-down-arrow-red]="downSort"
        [class.i-up-arrow-red]="!downSort"></div>
    </div>
    <form *ngIf="form" class="form" [formGroup]="form" (submit)="addComment()">
      <div class="row-container spaced border-bottom">
        <app-avatar [initials]="contactName" size="medium" [bordered]="true" [colorSeed]="contactName"></app-avatar>
        <div class="column-container flex-grow">
          <mat-form-field class="field mat-form-field-should-float" floatLabel="always">
            <mat-label class="label" [ngClass]="font?.size">{{ contactName }}</mat-label>
            <textarea [maxlength]="255" class="static-area" matInput [rows]="isCommenting ? 3 : 1"
              placeholder="Escribe un comentario..." formControlName="comment" [ngClass]="font?.size"></textarea>
          </mat-form-field>
          <div class="row-container spaced actions" [@slideInOut]="showButtons">
            <div class="buttons-container">
              <button class="btn small primary" type="submit" [ngClass]="font?.size">{{
                pageContent?.comments.button.save | translate }}</button>
              <button class="btn small secondary" (click)="discardComment()" [ngClass]="font?.size">{{
                pageContent?.comments.button.cancel | translate }} </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="empty" *ngIf="!comments?.length" [ngClass]="font?.size">{{ pageContent?.comments.empty_comments |
      translate }}</div>
    <div class="comments-container" *ngIf="comments?.length">
      <div class="comment" *ngFor="let comment of comments">
        <app-comment [comment]="comment"></app-comment>
      </div>
    </div>
  </div>
</div>