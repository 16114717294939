import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoText } from '@interfaces/info-text.interface';
import { ModalInfoText } from '@interfaces/modal.interface';
import { MODALS_CONTENT } from '@pages-content/modals.constants';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-definitions',
  templateUrl: './modal-definitions.component.html',
  styleUrls: ['./modal-definitions.component.scss'],
})
export class ModalDefinitionsComponent {
  public pageContent = MODALS_CONTENT.definitionModal;
  public definitions: Array<InfoText>;
  public showSecondaryButton = false;
  public iconName: string;
  public closeBtn = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalInfoText,
    public font: FontService,
    private dialogRef: MatDialogRef<ModalDefinitionsComponent>,
  ) {
    if (data.definitions) { this.definitions = data.definitions; }
    if (data.iconName) { this.iconName = data.iconName; }
  }

  public dialogClose(): void {
    this.dialogRef.close();
  }
}
