import { Component, Input } from '@angular/core';

import { ACCESSIBILITY_CONTENT } from '@constants/accessibility.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.component.html',
  styleUrls: ['./accessibility.component.scss'],
})
export class AccessibilityComponent {
  @Input() public isRedBackground = false;
  public pageContent = ACCESSIBILITY_CONTENT;

  constructor(
    public font: FontService,
  ) { }

  public changeFontSize(change: 'bigger' | 'smaller'): void {
    this.font.setSize(change);
  }

  public toggleContrast(): void {
    const bodyElement = document.querySelector('body');
    bodyElement.classList.toggle('high-contrast');
  }
}
