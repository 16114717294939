import { Mockup } from '@interfaces/mockup.interface';

export const GET_PAPERWORK_MOCK: Mockup = {
  success: [{
    response: {
      data: {
        paperwork: [
          {
            rut: '12345678-5',
            folio: '1234',
            email: 'ola@khe.ase',
            phone: '56912341234'
          },
          {
            rut: '12345678-5',
            folio: '12345',
            email: 'ola@khe.ase',
            phone: '56912341234'
          },
          {
            rut: '12345678-5',
            folio: '1234',
            email: 'ola1@khe.ase',
            phone: '56912341234'
          },
          {
            rut: '12345678-5',
            folio: '1234',
            email: 'ola1@khe.ase',
            phone: '56943214321'
          },
          {
            rut: '11111111-1',
            folio: '12345',
            email: 'ola1@khe.ase',
            phone: '56912341234'
          },
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
