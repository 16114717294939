import { environment } from '@environment';
import { Mockup } from '@interfaces/mockup.interface';
import { MandatorySavings } from '@interfaces/savings.interface';

// tslint:disable:max-line-length
export const GET_MANDATORY_SAVINGS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: {
          filename_disk: `97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
        },
        imagen_banner_mobile: {
          filename_disk: `97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
        },
        traducciones: [
          {
            consejos: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
            languages_code: 'es',
          }
        ],
        carrusel: [
          {
            infografias_id: 1
          },
          {
            infografias_id: 2
          },
          {
            infografias_id: 3
          }
        ],
        alt_image: '',
        title_image: ''
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

const pageKey = 'AFFILIATE.SAVE_WITH_US.MANDATORY_SAVINGS';

export const MANDATORY_SAVINGS_MOCKUP: MandatorySavings = {
  header: {
    image: {
      url: `${environment.cmsApiUrl}assets/97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
      alt: '',
      title: '',
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/97466f76-6ac0-4587-82cb-0d0a4441285c.png`,
      alt: '',
      title: '',
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    button: `${pageKey}.CAROUSEL.BUTTON`,
    benefits: [
      {
        id: 1,
        icon: 'lupa',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 2,
        icon: 'client',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      },
      {
        id: 3,
        icon: 'support',
        title: 'title',
        description: null,
        showMoreButton: null,
        modal: {
          title: null,
          description: null,
          image: {
            url: null,
            alt: null,
            title: null,
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
      }
    ],
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/01card-af-dependiente.webp',
          alt: 'Afiliado dependiente'
        },
        title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.MODAL_DESCRIPTION`,
          image: {
            url: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.IMAGE`,
            alt: 'Dependiente'
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/02card-af-independiente.webp',
          alt: 'Afiliado independiente'
        },
        title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/03card-af-voluntario.webp',
          alt: 'Voluntario'
        },
        title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  adviceSection: {
    title: `${pageKey}.ADVICE.TITLE`,
    body: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
    disclaimer: `${pageKey}.ADVICE.DISCLAIMER`,
    disclaimerButton: `${pageKey}.ADVICE.BUTTON`,
    disclaimerUrl: 'download_app',
  }
};

export const GET_MANDATORY_SAVINGS_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        imagen_banner: null,
        imagen_banner_mobile: null,
        traducciones: [
          {
            consejos: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
            languages_code: 'es'
          }
        ],
        carrusel: [],
        alt_image: null,
        title_image: null
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const MANDATORY_SAVINGS_NULL_MOCKUP: MandatorySavings = {
  header: {
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    button: `${pageKey}.CAROUSEL.BUTTON`,
    benefits: [],
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/01card-af-dependiente.webp',
          alt: 'Afiliado dependiente'
        },
        title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.MODAL_DESCRIPTION`,
          image: {
            url: `${pageKey}.CARDS.AFFILIATES.DEPENDANT.IMAGE`,
            alt: 'Dependiente'
          },
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/02card-af-independiente.webp',
          alt: 'Afiliado independiente'
        },
        title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.INDEPENDENT.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/03card-af-voluntario.webp',
          alt: 'Voluntario'
        },
        title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
        description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.DESCRIPTION`,
        showMoreText: 'GENERAL.MORE_INFO',
        modal: {
          title: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.TITLE`,
          description: `${pageKey}.CARDS.AFFILIATES.VOLUNTARY.MODAL_DESCRIPTION`,
          image: null,
          disclaimer: null,
          disclaimerButton: null,
          disclaimerUrl: null,
        },
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  adviceSection: {
    title: `${pageKey}.ADVICE.TITLE`,
    body: '<p><strong>Tu Pensi&oacute;n de Vejez depende principalmente del capital que tengas en tu cuenta cuando te pensiones.</strong></p>\n<p>Si te invalidas o falleces, el sistema te asegura a ti y a tus beneficiarios, una pensi&oacute;n que depende del promedio de tus rentas imponibles de los 10 a&ntilde;os anteriores al siniestro, solo si te encuentras cubierto por el Seguro de Invalidez y Sobrevivencia.</p>\n<p>Por lo anterior, es fundamental que te mantengas cotizando. Si pierdes el trabajo, trata de cotizar como afiliado voluntario mientras encuentras otro.</p>',
    disclaimer: `${pageKey}.ADVICE.DISCLAIMER`,
    disclaimerButton: `${pageKey}.ADVICE.BUTTON`,
    disclaimerUrl: 'download_app',
  }
};
