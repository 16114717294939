import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { PdfFile } from '@interfaces/general.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-modal-file-download',
  templateUrl: './modal-file-download.component.html',
  styleUrls: ['./modal-file-download.component.scss'],
})
export class ModalFileDownloadComponent extends ModalComponent {
  public title: string;
  public description: string;
  public informative: boolean;
  public pdfFile: PdfFile;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    public utils: Utils,
  ) {
    super(params, modalCtrl);
    this.title = this.params.get('title');
    this.description = this.params.get('description');
    this.pdfFile = this.params.get('pdfFile');
    this.informative = this.params.get('informative');
  }

  public downloadFile() {
    const { downloadUrl, downloadFileName } = this.pdfFile;
    this.utils.downloadFile(downloadUrl, downloadFileName);
  }

}
