import { Component, Input } from '@angular/core';

import { DownloadContent } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-accordion-download-list',
  templateUrl: './accordion-download-list.component.html',
  styleUrls: ['./accordion-download-list.component.scss'],
})
export class AccordionDownloadListComponent {
  @Input() list: Array<DownloadContent> = [];
  @Input() public loading = false;

  constructor(
    public font: FontService,
  ) { }

}
