const pageKey = 'AFFILIATE.BENEFICIARY_MODAL';

export const BENEFICIARY_MODAL_CONTENT = {
  title: `${pageKey}.TITLE`,
  personalData: {
    title: `${pageKey}.PERSONAL_DATA.TITLE`,
    form: {
      rut: `${pageKey}.PERSONAL_DATA.FORM.RUT`,
      name: `${pageKey}.PERSONAL_DATA.FORM.NAME`,
      lastName: `${pageKey}.PERSONAL_DATA.FORM.LAST_NAME`,
      secondSurname: `${pageKey}.PERSONAL_DATA.FORM.SECOND_SURNAME`,
      birthdate: `${pageKey}.PERSONAL_DATA.FORM.BIRTHDATE`,
      gender: {
        label: `${pageKey}.PERSONAL_DATA.FORM.GENDER.LABEL`,
        male: `${pageKey}.PERSONAL_DATA.FORM.GENDER.MALE`,
        female: `${pageKey}.PERSONAL_DATA.FORM.GENDER.FEMALE`,
      },
      relationship: `${pageKey}.PERSONAL_DATA.FORM.RELATIONSHIP`,
      isDisabled: `${pageKey}.PERSONAL_DATA.FORM.IS_DISABLED`,
      benefitStart: `${pageKey}.PERSONAL_DATA.FORM.BENEFIT_START`,
    }
  },
  contactData: {
    title: `${pageKey}.CONTACT_DATA.TITLE`,
    form: {
      email: `${pageKey}.CONTACT_DATA.FORM.EMAIL`,
      cellphone: `${pageKey}.CONTACT_DATA.FORM.CELLPHONE`,
      reEmail: `${pageKey}.CONTACT_DATA.FORM.RE_EMAIL`
    }
  },
  form: {
    positiveAnswer: `${pageKey}.FORM.POSITIVE_ANSWER`,
    negativeAnswer: `${pageKey}.FORM.NEGATIVE_ANSWER`,
  },
  errors: {
    required: `${pageKey}.ERRORS.REQUIRED`,
    invalidRut: `${pageKey}.ERRORS.INVALID_RUT`,
    invalidCellphone: `${pageKey}.ERRORS.INVALID_CELLPHONE`,
    invalidEmail: `${pageKey}.ERRORS.INVALID_EMAIL`,
    invalidDomain: `${pageKey}.ERRORS.INVALID_DOMAIN`,
    mustMatch: `${pageKey}.ERRORS.MUST_MATCH`,
    patternLetters: `${pageKey}.ERRORS.PATTERN_LETTERS`,
  },
  buttons: {
    addBeneficiary: `${pageKey}.BUTTONS.ADD`,
    updateBeneficiary: `${pageKey}.BUTTONS.MODIFY`
  },
  tutorData: {
    title: `${pageKey}.TUTOR_DATA.TITLE`,
    isMandataryTutor: `${pageKey}.TUTOR_DATA.RESPONSABLE_TUTOR`,
  }
};

