import { Mockup } from '@interfaces/mockup.interface';


export const GET_SHOW_IMAGES_MOCK: Mockup = {
    success: [{
        response: {
            data: [{
                'cuenta2': true,
                'apv': true,
                'multifondos': true,
                'simuladores': true
            }]
        }
    }],
    failures: []
};
