import { Component, OnInit } from '@angular/core';
import { MAIN_FINANCIAL_INFORMATION } from '@constants/financial-information.constant';
import { FundsContainer } from '@interfaces/home.interface';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-quota-values-banner',
  templateUrl: './quota-values-banner.component.html',
  styleUrls: ['./quota-values-banner.component.scss'],
})
export class QuotaValuesBannerComponent implements OnInit {
  public loading = false;
  public fundsContainer: FundsContainer;
  public financialInformation = MAIN_FINANCIAL_INFORMATION;

  constructor(
    private cmsService: CmsService,
    private languageProvider: LanguageProvider,
  ) { }

  public ngOnInit(): void {
    this.loadData();
    this.languageSubscription();
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    await this.cmsService.loadHome().toPromise()
      .then((homeData) => {
        this.fundsContainer = homeData.fundsContainer;
      })
      .finally(() => this.loading = false);
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() => this.loadData());
  }
}
