import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';

import { CorporationRoutingModule } from './corporation-routing.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    CorporationRoutingModule,
    RouterModule,
    ComponentsModule,
  ],
})
export class CorporationModule { }
