import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  private popover;

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  public async presentPopover(component, componentProps, event: Event, size?: any, cssClass = 'popover', callback?: (value?) => void) {
    if (this.popover) { return; }
    this.popover = await this.popoverCtrl.create({
      component,
      event,
      componentProps,
      mode: 'ios',
      cssClass,
      translucent: false,
      side: 'bottom',
      alignment: 'center',
      size,
    });
    this.popover.onDidDismiss()
      .then((response) => {
        if (!response.data && !callback) { return; }
        callback(response.data);
      })
      .finally(() => this.popover = null);
    return await this.popover.present();
  }
}
