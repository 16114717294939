export const HOME_PATH = 'publico_home/1';
export const HOME_FIELDS = `id,banners.banners_id,login.*,acciones_principales.*,icono_accion_izquierda,icono_accion_derecha,
contenedor_articulos.*,articulos.articulos_id,mas_articulos.articulos_id,url_boton_articulos,valores_cuota.*,url_boton_valores_cuota,productos.productos_id,
contenedor_productos.*,contenedor_tuguiavital.*,tuguiavital.tu_guia_vital_id`;

export const EMPLOYER_HOME_PATH = 'home_empleador/1';

export const EMPLOYER_HOME_FIELDS = `id,banners.banners_id,contenedor_articulos.*,articulos.articulos_id`;

export const ALERT_BANNER_FIELDS = 'activo,modal,imagen_modal.filename_disk,traducciones.*';

export const BANNER_FIELDS = 'id,icono,imagen.filename_disk,imagen_mobile.filename_disk,url,traducciones.*';

export const ARTICLE_FIELDS = `
id,sort,identificador,imagen.filename_disk,traducciones.alt_imagen,traducciones.titulo,
traducciones.title_image,traducciones.languages_code,url`;

export const LINKS_FIELDS = 'id,url,traducciones.*';

export const INFOGRAPHICS_FIELDS = 'id,icono,imagen_modal.filename_disk,url_modal,traducciones.*';

export const PRODUCTS_FIELDS = 'id,identificador,icono,url_producto,traducciones.*';

export const HEADERS_FIELDS = 'id,icono,imagen.filename_disk,imagen_mobile.filename_disk,traducciones.*';

export const GENERAL_INFO_FIELDS = 'traducciones.*,flotante_url,flotante.*,mostrar_flotante,modal_login,contact_center_habilitado';

export const PAYMENT_CENTER_FIELDS = 'id,imagen.filename_disk,url,traducciones.*';

export const CALENDARS_FIELDS = 'id,titulo,fechas';

export const FAQS_FIELDS = `
id,
traducciones.titulo,
traducciones.languages_code,
secciones_preguntas.secciones_preguntas_id`;

export const FAQS_SECTIONS_FIELDS = 'id,traducciones.*';

export const WHY_TRANSFER_PATH = 'cambiate/1';

export const WHY_TRANSFER_FIELDS = `
id,
imagen_header.filename_disk,
imagen_header_mobile.filename_disk,
icono_header,
link_traspaso.url,
link_traspaso.identificador,
link_seccion_articulos.url,
link_seccion_articulos.identificador,
articulos.articulos_id,
carrusel.infografias_id,
traducciones.*`;

export const TRANSFER_STATE_PATH = 'estado_de_transferencia/1';
export const TRANSFER_STATE_FIELDS = 'id,header.*,articulos.articulos_id,traducciones.*';

export const MANDATORY_SAVINGS_PATH = 'ahorro_obligatorio/1';
export const MANDATORY_SAVINGS_FIELDS = `traducciones.*,imagen_banner.filename_disk,imagen_banner_mobile.filename_disk,
carrusel.infografias_id,
title_image, alt_image`;

export const VOLUNTARY_SAVINGS_PATH = 'apv/1';
export const VOLUNTARY_SAVINGS_FIELDS = `imagen_banner.filename_disk,carrusel.infografias_id,apv_preguntas_traducciones.*,
title_image, alt_image, imagen_banner_mobile.filename_disk`;

export const SECONDARY_ACCOUNT_PATH = 'cuenta_2/1';
export const SECONDARY_ACCOUNT_FIELDS = `traducciones.*,imagen_banner.filename_disk,imagen_banner_mobile.filename_disk,
carrusel.infografias_id,alt_image,title_image`;

export const FINANCIAL_INFORMATION_PATH = 'informacion_financiera/1';
export const FINANCIAL_INFORMATION_FIELDS = 'header.*,traducciones.*';

export const INVESTMENT_PORTFOLIO_PATH = 'cartera_inversiones/';
export const INVESTMENT_PORTFOLIO_FIELDS = `
traducciones.*,traducciones_grafico_cartera,
graficos.cartera_inversiones_graficos_id.titulo_fondo,
graficos.cartera_inversiones_graficos_id.alt_zona,
graficos.cartera_inversiones_graficos_id.alt_cartera,
graficos.cartera_inversiones_graficos_id.grafico_zona.filename_disk,
graficos.cartera_inversiones_graficos_id.grafico_cartera.filename_disk`;

export const MULTI_FUNDS_PATH = 'multifondos/';
export const MULTI_FUNDS_FIELDS = `header.imagen.filename_disk,header.imagen_mobile.filename_disk,
header.*,traducciones.rentabilidad,traducciones.languages_code`;

export const FUTURE_PENSIONER_PATH = 'futuro_pensionado/';
export const FUTURE_PENSIONER_FIELDS = 'banners.*';

export const QUOTA_VALUES_PATH = 'franja_valores_cuota/1';
export const QUOTA_VALUES_FIELDS = 'traducciones.valores_cuota,traducciones.fondo,traducciones.languages_code';

export const FUND_WITHDRAWAL_PATH = 'retiro_de_fondos/';
export const FUND_WITHDRAWAL_FIELDS = 'contenido';

export const FINANCIAL_STATEMENTS = 'estados_financieros/';
export const FINANCIAL_STATEMENTS_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const ECONOMIC_INDICATORS_PATH = 'indicadores_economicos/';
export const ECONOMIC_INDICATORS_FIELDS = `
reporte.filename_download,
reporte.id,
traducciones.titulo,
traducciones.languages_code,
traducciones.texto_boton`;

export const ESSENTIAL_FACTS = 'hechos_esenciales/';
export const ESSENTIAL_FACTS_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const INFORMATION_OF_INTEREST = 'informacion_de_interes/';
export const INFORMATION_OF_INTEREST_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const CORPORATE_GOVERNANCE = 'gobierno_corporativo/';
export const CORPORATE_GOVERNANCE_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const ANNUAL_MEMORY = 'memoria_anual/';
export const ANNUAL_MEMORY_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const AFP_POLICIES = 'politicas_afp/';
export const AFP_POLICIES_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const ADMINISTRATION_PORTRAITS = 'administracion_empleados/';
export const ADMINISTRATION_PORTRAITS_FIELDS = 'traducciones.contenido,traducciones.languages_code';

export const HEALTHCARE_INSTITUTIONS = 'entidades_previsionales/';
export const HEALTHCARE_INSTITUTIONS_FIELDS = 'instituciones_salud';

export const PAYMENT_INSTITUTIONS = 'instituciones_cobro/';
export const PAYMENT_INSTITUTIONS_FIELDS = 'instituciones';

export const RELATIONSHIPS = 'parentesco/';
export const MORTUARY_QUOTA_RELATIONSHIPS_FIELDS = 'traducciones.*';

export const GENERAL_PARAMETERS = 'parametros_generales';
export const GENERAL_PARAMETERS_FIELDS = 'id,name,value';

export const SUPERINTENDENCE_PATH = 'franja_superintendencia/1';
export const SUPERINTENDENCE_FIELDS = `
link_superintendencia.identificador,
link_superintendencia.url,
traducciones.languages_code,
traducciones.texto,
traducciones.texto_boton`;

export const FOOTER_PATH = 'footer/1';
export const FOOTER_FIELDS = `
links_columna_1.links_id,
links_columna_2.links_id,
links_columna_3.links_id,
links_columna_4.links_id,
titulos.titulo_1,
titulos.titulo_2,
titulos.titulo_3,
titulos.titulo_4,
titulos.languages_code`;

export const FAMILY_ALLOWANCE_PATH = 'asignacion_familiar/';
export const FAMILY_ALLOWANCE_FIELDS = `
id,
banner,
topes_mensuales.*,
asignacion_familiar_seccion.*`;

export const SHOW_IMAGES_PATH = 'mostrar_imagenes/';
export const SHOW_IMAGES_FIELDS = 'apv,cuenta2,multifondos,simuladores';

export const CURRENT_COMMISSIONS_PATH = 'comisiones_vigentes/';
export const CURRENT_COMMISSIONS_FIELDS = `
comision_afp_dependiente,
comision_afp_independientes,
seguro_invalidez_independientes,
total_independiente,
comision_afp_independientes_mujer,
seguro_invalidez_independientes_mujer,
total_independiente_mujer,
comision_afp_pensionados,
traspaso_entre_fondos,
retiros_programados_renta_temp,
admin_cav,
aportes_indemnizacion,
admin_apv_afiliado,
admin_apv_no_afiliado,
transf_apv,
transf_apv_colectivo,
transf_cav,
fecha_actualizacion`;

export const CURRENT_COMMISSIONS_FIELDS_ARRAY = [
  'comision_afp_dependiente',
  'comision_afp_independientes',
  'seguro_invalidez_independientes',
  'total_independiente',
  'comision_afp_independientes_mujer',
  'seguro_invalidez_independientes_mujer',
  'total_independiente_mujer',
  'comision_afp_pensionados',
  'traspaso_entre_fondos',
  'retiros_programados_renta_temp',
  'admin_cav',
  'aportes_indemnizacion',
  'admin_apv_afiliado',
  'admin_apv_no_afiliado',
  'transf_apv',
  'transf_apv_colectivo',
  'transf_cav',
  'fecha_actualizacion',
];

export const CMS_REQUESTS = {
  filter: {
    published: {
      status: {
        _in: ['published']
      }
    }
  },
};

export const CMS_SORT = 'sort';
