import { Component, Input } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-rut-validation',
  templateUrl: './modal-rut-validation.component.html',
  styleUrls: ['./modal-rut-validation.component.scss']
})
export class ModalRutValidationComponent  extends ModalComponent {
  @Input() public pageContent;
  constructor(
    public font: FontService,
    protected modalCtrl: ModalController,
    protected params: NavParams,
    ) {
    super(params, modalCtrl);
  }

}
