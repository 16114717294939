const pageKey = 'STUDIES_CERTIFICATE';
const formKey = `${pageKey}.FORM`;

export const STUDIES_CERTIFICATE_REQUIRED_FILES = {
  certificate: {
    order: 1,
    buttonLabel: `${pageKey}.TITLE`,
    fileIndex: 'certificate',
    required: true
  }
};

export const STUDIES_CERTIFICATE_CONTENT = {
  modalTitle: `${pageKey}.MODAL_TITLE`,
  formTitle: `${pageKey}.FORM_TITLE`,
  disclaimer: `${pageKey}.DISCLAIMER`,
  form: {
    year: `${formKey}.YEAR`,
    period: `${formKey}.PERIOD`,
  },
  buttons: {
    updateCertificate: `${pageKey}.BUTTONS.UPDATE`,
    addCertificate: `${pageKey}.BUTTONS.ADD`,
  },
  errors: {
    required: `${pageKey}.ERRORS.REQUIRED`,
  }
};

export const STUDIES_CERTIFICATE_PERIODS = [
  { code: 'first', name: `${formKey}.PERIODS.FIRST`, },
  { code: 'second', name: `${formKey}.PERIODS.SECOND`, },
  { code: 'annual', name: `${formKey}.PERIODS.ANNUAL`, },
];

export const STUDIES_CERTIFICATE_YEARS = [
  `${formKey}.YEARS.1`,
  `${formKey}.YEARS.2`,
  `${formKey}.YEARS.3`,
  `${formKey}.YEARS.4`,
  `${formKey}.YEARS.5`,
  `${formKey}.YEARS.6`,
  `${formKey}.YEARS.7`,
];
