import * as Chartist from 'chartist';
import { finalize, take } from 'rxjs/operators';

import { formatCurrency } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ChartQuotaValuesComponent
} from '@components/chart-quota-values/chart-quota-values.component';
import { ONE_YEAR_IN_MILLISECONDS } from '@constants/general.constant';
import { ChartQuotaValuesParams } from '@interfaces/chart-quota-values.interface';
import { QuotaValueSearcherBox } from '@interfaces/financial-information.interface';
import { QuotaValue } from '@interfaces/quota-values.interface';
import { QUOTA_VALUE_INFORMATION } from '@pages-content/quota-value-information.constant';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import {
  FinancialIndicatorsService
} from '@services/financial-indicators/financial-indicators.service';
import { ChartistUtils } from '@utils/chartist.utils';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { EndDateValidator, ValidateDate } from '@validators/date.validator';

@Component({
  selector: 'app-quota-value-searcher',
  templateUrl: './quota-value-searcher.component.html',
  styleUrls: ['./quota-value-searcher.component.scss'],
})
export class QuotaValueSearcherComponent {
  @Input() data: QuotaValueSearcherBox;
  @Input() fundName: string;
  public maxDate = new Date();
  public requestForm = new UntypedFormGroup({
    startDate: new UntypedFormControl('', Validators.required),
    endDate: new UntypedFormControl('', Validators.required),
    startDateInput: new UntypedFormControl('', ValidateDate),
    endDateInput: new UntypedFormControl('', ValidateDate),
  });

  public get startDate(): string { return this.requestForm.value['startDate']; }
  public get endDate(): string | Date { return this.requestForm.value['endDate'] || this.maxDate; }
  public get overThreeYears(): boolean {
    const { endDateInput, startDateInput } = this.requestForm.getRawValue();
    if (!endDateInput || !startDateInput) { return false; }
    const diffInMilliseconds = endDateInput.getTime() - startDateInput.getTime();
    return diffInMilliseconds / ONE_YEAR_IN_MILLISECONDS > 3;
  }

  constructor(
    public font: FontService,
    private chartUtils: ChartistUtils,
    private financialIndicatorsService: FinancialIndicatorsService,
    private modalService: ModalService,
    private formUtils: FormUtils,
    private loadingService: LoadingService,
    private utils: Utils,
  ) {
    this.requestForm.setValidators(EndDateValidator('startDate', 'endDate'));
  }

  public dateSelected(formControlName: string): void {
    this.formUtils.setDateSelected(this.requestForm, formControlName);
  }

  public getQuotaValue(): void {
    if (this.overThreeYears) { return; }
    const { startDate, endDate } = this.requestForm.getRawValue();
    this.loadingService.showLoading();
    this.financialIndicatorsService.getQuotaValues(startDate, endDate)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => {
          const params: ChartQuotaValuesParams = {
            chartData: this.getChartData(response.quotaValues),
            type: 'chart-modal',
            title: QUOTA_VALUE_INFORMATION.quotaValueSearcherBox.modalTitle,
            fundName: this.fundName,
            showTooltip: true,
            ylabelInterpolationFnc: (value) => formatCurrency(value, 'es_CL', '$', 'CLP', '1.0-0'),
          };
          this.modalService.openModal(ChartQuotaValuesComponent, params);
        }
      );
  }

  public async exportExcel(): Promise<void> {
    const { startDate, endDate } = this.requestForm.getRawValue();
    this.loadingService.showLoading();
    try {
      const response = await this.financialIndicatorsService.getQuotaValuesFile(startDate, endDate).pipe(take(1)).toPromise();
      this.utils.downloadBase64File(response.file, 'Fondos.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } finally {
      this.loadingService.hideLoading();
    }
  }

  private getChartData(quotas: Array<QuotaValue>): Chartist.IChartistData {
    const series = this.chartUtils.getSeriesQuotaValueFunds(quotas);
    const labels = this.chartUtils.getLabelsQuotaValues(quotas);
    return { series, labels };
  }
}
