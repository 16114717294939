import { take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  HttpClientInterceptor
} from '@interceptors/http-client-interceptor/http-client-interceptor';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private http: HttpClientInterceptor,
  ) { }

  public sendNoEmailNotification(message: string): Promise<void> {
    const url = `${environment.affiliateApplicationsUrl}partial-forms/no-mail-notification`;
    return this.http.post(url, { message }, null).pipe(take(1)).toPromise() as Promise<void>;
  }
}
