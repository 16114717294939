import { Injectable } from '@angular/core';
import { ShutdownTransactions } from '@interfaces/firebase.interface';

@Injectable()
export abstract class FirebaseService {
  public abstract initializeFirebase(): void;
  public abstract initializeRemoteConfig(): void;
  public abstract initializeDefaultConfig(): void;
  public abstract isInitialized(): boolean;
  public abstract getRemoteConfigShutdownValues(): Promise<ShutdownTransactions>;
}
