import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VALIDATORS } from '@constants/forms.constant';
import {
  CALLING_PERSON_FORM_KEY, CERTIFY_STUDIES_CALLING_PERSON_BACKGROUNDS,
  CERTIFY_STUDIES_DEEASED_PERSON_BACKGROUNDS, CERTIFY_STUDIES_FORM_TYPE,
  CERTIFY_STUDIES_MARITAL_STATUS_DESCRIPTION, DECEASED_PERSON_FORM_KEY
} from '@constants/pages-content/certify-studies.constants';
import { PersonBackgroundParams } from '@interfaces/certify-studies.interface';
import { FieldsValidators, PersonBackgroundFieldsOptions } from '@interfaces/person-backgrounds.interface';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';

@Component({
  selector: 'app-certify-studies-backgrounds',
  templateUrl: './certify-studies-backgrounds.component.html',
  styleUrls: ['./certify-studies-backgrounds.component.scss'],
})
export class CertifyStudiesBackgroundsComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: PersonBackgroundParams;
  @Output() public formData: EventEmitter<PersonBackgroundParams> = new EventEmitter();
  public form: UntypedFormGroup;
  public callingPersonFormKey: CERTIFY_STUDIES_FORM_TYPE = CALLING_PERSON_FORM_KEY;
  public deceasedPersonFormKey: CERTIFY_STUDIES_FORM_TYPE = DECEASED_PERSON_FORM_KEY;
  public deceasedPersonControls = CERTIFY_STUDIES_DEEASED_PERSON_BACKGROUNDS;
  public callingPersonControls = CERTIFY_STUDIES_CALLING_PERSON_BACKGROUNDS;
  public maritalStatusDescriptions: Array<Array<string>>;
  public resumeAffiliateControls: Array<PersonBackgroundFieldsOptions>;
  public resumeDeceasedAffiliateControls: Array<PersonBackgroundFieldsOptions>;

  public callingUserFieldsValidators = {
    birthdate: {
      maxRange: 24
    }
  } as FieldsValidators;
  public deceasedUserFieldsValidators = {
    rut: {
      value: ''
    }
  } as FieldsValidators;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private utils: Utils,
    private formUtils: FormUtils
  ) { }

  public ngOnInit(): void {
    this.maritalStatusDescriptions = Object.entries(CERTIFY_STUDIES_MARITAL_STATUS_DESCRIPTION);
    this.createForm();
  }

  private createForm() {
    const values = this.getFormValues();
    this.setControlsValues();
    this.form = this.formBuilder.group({
      deceasedUserNames: [values.deceasedUserNames, VALIDATORS.NAME],
      deceasedUserlastName: [values.deceasedUserlastName, VALIDATORS.NAME],
      deceasedUserRut: [values.deceasedUserRut, VALIDATORS.RUT],
      names: [values.name, VALIDATORS.NAME],
      lastName: [values.lastName, VALIDATORS.NAME],
      secondSurname: [values.secondSurname, VALIDATORS.OPTIONAL_NAME],
      rut: [values.rut, VALIDATORS.RUT],
      email: [values.email, VALIDATORS.EMAIL],
      cellphone: [values.cellphone, VALIDATORS.PHONE],
      maritalStatus: [values.maritalStatus, Validators.required],
      gender: [values.gender, Validators.required],
      birthdate: [values.birthdate, Validators.required],
      healthcare: [values.healthcare, Validators.required],
      profession: [values.profession, Validators.required],
      address: [values.address, Validators.required],
      communeId: [values.communeId, Validators.required],
      region: [values.region, Validators.required],
    }, {
      validator: [mustMatch('rut', 'deceasedUserRut', false)]
    });
  }

  private setControlsValues(): void {
    this.setResumeBackgroundsPersonControls(this.formValues ? this.formValues : null);
    this.setResumeDeceasedPersonControls(this.formValues ? this.formValues : null);
  }

  private setResumeBackgroundsPersonControls(value: PersonBackgroundParams): void {
    this.callingPersonControls = this.formUtils.getDefaultControlsValues(
      value, CERTIFY_STUDIES_CALLING_PERSON_BACKGROUNDS);
  }

  private setResumeDeceasedPersonControls(value: PersonBackgroundParams): void {
    this.deceasedPersonControls = [
      { key: 'names', ...value ? { value: value.deceasedUserNames } : {} },
      { key: 'lastName', ...value ? { value: value.deceasedUserlastName } : {} },
      { key: 'rut', ...value ? { value: value.deceasedUserRut } : {} },
    ];
  }


  private getFormValues(): any {
    if (!this.formValues) { return {}; }
    const {
      address, birthdate, cellphone, region, communeId, deceasedUserNames, deceasedUserRut,
      deceasedUserlastName, email, gender, healthcare, lastName, maritalStatus, names,
      profession, rut, secondSurname
    } = this.formValues;

    return {
      names,
      lastName,
      secondSurname,
      address,
      rut,
      birthdate,
      cellphone,
      region,
      communeId,
      email,
      gender,
      healthcare,
      maritalStatus,
      profession,
      deceasedUserNames,
      deceasedUserRut,
      deceasedUserlastName
    };
  }

  public validateForm(form: UntypedFormGroup, keyForm: CERTIFY_STUDIES_FORM_TYPE): void {
    if (keyForm === this.callingPersonFormKey) {
      this.validateCallingPersonFormGroup(form);
    } else if (keyForm === this.deceasedPersonFormKey) {
      this.validateDeceasedPersonFormGroup(form);
    }
  }

  public sendForm(): void {
    if (this.form.invalid) { return; }
    this.formData.emit(this.form.value);
  }

  private validateCallingPersonFormGroup(form: UntypedFormGroup): void {
    const { names, lastName, secondSurname, rut, email, cellphone, maritalStatus,
      gender, birthdate, healthcare, profession, address, commune, region
    } = form.value;

    this.form.patchValue({
      names, lastName, secondSurname,
      rut: this.utils.rutClean(rut),
      email, cellphone, maritalStatus,
      gender, birthdate, healthcare, profession, address, communeId: commune, region
    });
    this.deceasedUserFieldsValidators = {
      ...this.deceasedUserFieldsValidators,
      rut: {
        value: rut
      }
    };
  }

  private validateDeceasedPersonFormGroup(form: UntypedFormGroup): void {
    const { names, lastName, rut } = form.value;
    this.form.patchValue({
      deceasedUserNames: names,
      deceasedUserlastName: lastName,
      deceasedUserRut: this.utils.rutClean(rut),
    });
  }

}
