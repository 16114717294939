<div class="searcher-container box">
  <form class="form" [formGroup]="requestForm">
    <p class="title tertiary" [ngClass]="font?.size">{{ data?.title | translate }}</p>
    <ion-item class="question-box">
      <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ data?.startDate | translate }}</ion-label>
      <div class="icon-input">
        <ion-input formControlName="startDate" readonly></ion-input>
        <input class="data-input"
               (dateChange)="dateSelected('startDate')"
               matInput [matDatepicker]="startDatepicker"
               formControlName="startDateInput"
               [max]="endDate">
        <mat-datepicker-toggle [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #startDatepicker></mat-datepicker>
      </div>
    </ion-item>
    <ion-item class="question-box">
      <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ data?.endDate | translate }}</ion-label>
      <div class="icon-input">
        <ion-input formControlName="endDate" readonly></ion-input>
        <input class="data-input"
               (dateChange)="dateSelected('endDate')"
               matInput [matDatepicker]="endDatepicker"
               formControlName="endDateInput"
               [min]="startDate"
               [max]="maxDate">
        <mat-datepicker-toggle [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #endDatepicker></mat-datepicker>
      </div>
    </ion-item>
    <div class="centered-container">
      <ion-button *ngIf="!overThreeYears" class="button primary" [ngClass]="font?.size" (click)="getQuotaValue()" [disabled]="requestForm.invalid">{{ data?.button | translate }}</ion-button>
      <ion-button class="button primary unset-padding" [ngClass]="font?.size" (click)="exportExcel()" [disabled]="requestForm.invalid">{{ data?.excelButton | translate }}</ion-button>
    </div>
  </form>
</div>
