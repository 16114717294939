<div class="box">
  <p class="title tertiary" [ngClass]="font?.size">{{ data?.title | translate }}</p>
  <div class="form-container">
    <form class="form" [formGroup]="requestForm">
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ data?.rut | translate }}</ion-label>
        <ion-input formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut"></ion-input>
      </ion-item>
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ data?.certificateId | translate }}
          <ion-icon class="icon" name="alert-circle"></ion-icon>
        </ion-label>
        <ion-input formControlName="certificateId" placeholder="11112222333344445"></ion-input>
      </ion-item>
      <div class="disclaimer" [ngClass]="font?.size" [innerHtml]="recaptchaDisclaimer | translate"></div>
      <div class="centered-container">
        <ion-button class="button primary" [ngClass]="font?.size" (click)="validateCertificate()" [disabled]="requestForm.invalid">{{ data?.button | translate }}</ion-button>
      </div>
    </form>
    <div class="image-container">
      <img class="img" src="{{ data?.image.url }}" alt="{{ data?.image.alt }}">
    </div>
  </div>
</div>
