import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { filesActionType } from '@constants/forms.constant';
import { REQUIRED_FILES } from '@constants/pages-content/mortuary-quota-request.constants';
import { AttachFiles } from '@interfaces/attach-files.interface';
import { DefaultResponseItem, PaymentMethods } from '@interfaces/general.interface';
import {
  AttachmentPaymentParams, AttachmentsMortuaryFee
} from '@interfaces/mortuary-quota-form.interface';
import { PaymentMethodForm } from '@interfaces/payment-methods.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-attachments-payment',
  templateUrl: './attachments-payment.component.html',
  styleUrls: ['./attachments-payment.component.scss'],
})
export class AttachmentsPaymentComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public userRut;
  @Input() public formValues: AttachmentPaymentParams;
  @Output() public back = new EventEmitter();
  @Output() public formData: EventEmitter<any> = new EventEmitter();

  public requiredFiles: AttachFiles = { files: REQUIRED_FILES };
  public paymentData: PaymentMethods;
  public paymentMethods: Array<DefaultResponseItem>;
  public loadedPaymentMethods = false;
  public isTransfer: boolean;

  private paymentMethod: PaymentMethodForm;
  private attachedFiles: Array<any> = [];
  private fileKeys: Array<string> = [];
  private fileActionIndicators: Array<filesActionType> = [];

  public get requiredFilesCount(): number { return this.requiredFiles.files.length; }
  public get hasRequiredFiles(): boolean {
    return this.attachedFiles.length >= this.requiredFilesCount  && this.fileKeys.length >= this.requiredFilesCount;
  }
  public get isValid() { return Boolean(this.paymentMethod) && this.isPaymentMethodValid && this.hasRequiredFiles; }
  public get isPaymentMethodValid(): boolean {
    return this.isTransfer ? Boolean(this.paymentMethod.paymentMethodId && this.paymentMethod.bankId && this.paymentMethod.accountNumber) :
      Boolean(this.paymentMethod.paymentMethodId);
  }
  public get filesLoaded(): any {
    if (!this.formValues || !this.formValues.attachments) { return null; }
    const filesObject = {
      deathCertificate: this.formValues.attachments.deathCertificate,
      bill: this.formValues.attachments.bill
    };
    return filesObject;
  }
  public get paymentMethodData(): PaymentMethodForm {
    if (!this.formValues || !this.formValues.paymentMethod) { return null; }
    return this.formValues.paymentMethod;
  }

  constructor(
    public font: FontService,
    private modalService: ModalService,
    private parametersService: ParametersService,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
    private utils: Utils,
  ) { }

  public async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.userRut) { this.userRut = changes.userRut.currentValue; }
  }

  public previousStep(): void {
    this.back.emit();
  }

  public confirm(): void {
    if (!this.isValid) { return; }

    const attachmentsMortuaryFee = {
      deathCertificate: this.attachedFiles[0],
      bill: this.attachedFiles[1],
      actions: this.fileActionIndicators,
      fileKeys: this.fileKeys
    } as AttachmentsMortuaryFee;

    const attachmentPayment = {
      paymentMethod: this.paymentMethod,
      attachments: attachmentsMortuaryFee
    } as AttachmentPaymentParams;

    this.formData.emit(attachmentPayment);
  }

  public setPaymentMethods(paymentMethodGroup: UntypedFormGroup): void {
    const { accountNumber, bankId, paymentMethodId, otherAccountTypeId } = paymentMethodGroup.value;
    this.isTransfer = !Boolean(otherAccountTypeId);
    this.paymentMethod = {
      accountNumber: accountNumber ? accountNumber : null,
      bankId: bankId ? this.utils.cleanLeftZeros(bankId) : null,
      paymentMethodId: this.isTransfer ? Number(paymentMethodId) : Number(otherAccountTypeId)
    };
  }

  public setFiles(data: any): void {
    if (!data) { return; }
    this.attachedFiles = data.fileList;
    this.fileKeys = data.keyList;
    this.fileActionIndicators = data.actionList;
  }

  private async loadData(): Promise<void> {
    this.loadingService.showLoading();
    await Promise.all([
      this.parametersService.getPaymentMethodsAndBanks().toPromise(),
      this.parametersService.getPaymentMethods('pensionary-forms').toPromise(),
    ]).then(([paymentMethodsAndBanks, paymentMethods]: [PaymentMethods, Array<DefaultResponseItem>
        ]) => {
          this.paymentData = paymentMethodsAndBanks;
          this.paymentMethods = paymentMethods;
          this.loadedPaymentMethods = true;
      }).catch((error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) }))
      .finally(() => this.loadingService.hideLoading());
  }
}
