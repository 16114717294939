import { curveCardinal } from 'd3-shape';

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { FUNDS_NAMES } from '@constants/quota-values.constant';
import { ChartQuotaValuesParams } from '@interfaces/chart-quota-values.interface';
import { Element } from '@interfaces/general.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-chart-quota-values',
  templateUrl: './chart-quota-values.component.html',
  styleUrls: ['./chart-quota-values.component.scss'],
})
export class ChartQuotaValuesComponent extends ModalComponent implements OnInit, OnDestroy {
  @Input() public chartParams: ChartQuotaValuesParams;
  @Input() public height = 250;
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;
  public yAxisTickFormattingFn = this.yAxisFormat.bind(this);
  public xAxisTickFormattingFn = this.xAxisFormat.bind(this);
  public funds: Array<string> = FUNDS_NAMES;
  public fundsChips: Array<Element>;
  public series: Array<Array<number>>;
  public selectedFundId = 0;
  public multi: Array<any> = [];
  public view: Array<number> = [];
  public showTooltip = false;
  public colorScheme = {
    domain: ['#C21B17', '#AA1B17', '#9f2842']
  };
  public resizeOberver: ResizeObserver;
  public curve = curveCardinal.tension(0.25);

  public get isModal(): boolean { return this.chartParams.type === 'chart-modal'; }
  public get isPopover(): boolean { return this.chartParams.type === 'chart-popover'; }

  public get chartClass(): string {
    const { type } = this.chartParams;
    return `${ type || ''} ${this.isModal ? 'modal' : ''}`;
  }

  public get chartWidth(): number {
    const el = this.chartContainer.nativeElement;
    const offsetWidth = el.offsetWidth;
    const leftPadding = parseInt(window.getComputedStyle(el).paddingLeft, 10);
    const rightPadding = parseInt(window.getComputedStyle(el).paddingRight, 10);
    return offsetWidth - leftPadding - rightPadding;
  }

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    if (this.params.data) {
      this.chartParams = this.params.data as ChartQuotaValuesParams;
      this.height = this.chartParams.height;
    }
  }

  public ngOnInit(): void {
    this.setDataSeries();
    this.generateChips();
    this.setChartData(this.selectedFundId);
    this.resizeChart();
    this.setResizeObserver();
  }

  public ngOnDestroy(): void {
    this.resizeOberver.unobserve(this.chartContainer.nativeElement);
  }

  public changeFund(id: number): void {
    this.selectedFundId = id;
    this.setChartData(this.selectedFundId);
  }

  public resizeChart(): void {
    this.view = [this.chartWidth, this.height];
  }

  public yAxisFormat(value): string {
    return this.chartParams && this.chartParams.ylabelInterpolationFnc ? this.chartParams.ylabelInterpolationFnc(value) : `${value}`;
  }

  public xAxisFormat(value): string {
    return this.chartParams && this.chartParams.xlabelInterpolationFnc ? this.chartParams.xlabelInterpolationFnc(value) : `${value}`;
  }

  private setResizeObserver(): void {
    this.resizeOberver = new ResizeObserver(() => { this.resizeChart(); });
    this.resizeOberver.observe(this.chartContainer.nativeElement);
  }

  private generateChips(): void {
    if (this.isPopover) { return; }
    this.fundsChips = this.funds.map((fund, index) => ({ id: index, name: `${this.chartParams.fundName} ${fund}` }));
  }

  private setChartData(fundIndex: number): void {
    const { chartData, showTooltip } = this.chartParams;
    const data = { ...chartData };
    data.series = [(chartData.series as Array<Array<number>>)[fundIndex]];
    this.showTooltip = showTooltip;
    this.multi = data.series.map((serie) => {
      const values = { name: '', series: [] };
      serie.forEach((value, index) => { values.series.push({ name: data.labels[index], value }); });
      return values;
    });
  }

  private setDataSeries(): void {
    const { chartData } = this.chartParams;
    this.series = chartData.series as Array<Array<number>>;
  }
}
