<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="sendForm()">
  <app-person-backgrounds
    [pageContent]="pageContent?.form.callingUser"
    [controlsName]="callingPersonControls"
    [fieldsValidators]="callingUserFieldsValidators"
    (formChanged)="validateForm($event, callingPersonFormKey)">
  </app-person-backgrounds>
  <app-person-backgrounds
    [pageContent]="pageContent?.form.deceasedUser"
    [controlsName]="deceasedPersonControls"
    [fieldsValidators]="deceasedUserFieldsValidators"
    (formChanged)="validateForm($event, deceasedPersonFormKey)">
  </app-person-backgrounds>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
  </div>
</form>
