import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TRANSLATE_YEAR_KEY_TO_DOCTYPE_KEY, TRANSLATE_PERIOD_KEY_TO_DOCTYPE_KEY } from '@constants/array-list-partialsave.constant';
import { filesActionType } from '@constants/forms.constant';
import { FormArrayIcon, MAX_ARRAY_LIST } from '@constants/modal-data.constant';
import { AttachFileReloadIndicator } from '@interfaces/attach-files.interface';
import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';

@Component({
  selector: 'app-form-array-list-partialsave',
  templateUrl: './form-array-list-partialsave.component.html',
  styleUrls: ['./form-array-list-partialsave.component.scss'],
})
export class FormArrayListPartialSaveComponent {
  @Input() public pageContent: any;
  @Input() public iconClass: FormArrayIcon = 'pension';
  @Input() public itemProperties: Array<FormArrayItemProperties>;
  @Input() public modalType: ComponentType<any>;
  @Input() public modalData?: any = {};
  @Input() public maxFiles?: number;
  @Input() public items: any = [];
  @Input() public keysIndicator?: any;
  @Output() public edited: EventEmitter<any> = new EventEmitter();
  public fileKeysActionIndicator: Array<AttachFileReloadIndicator> = [];

  public get isMaxProperties(): boolean { return this.itemProperties.length === MAX_ARRAY_LIST; }
  public get itemsCount(): number { return this.items.length; }

  constructor(
    public font: FontService,
    public translate: TranslateService,
    private service: ModalService
  ) { }

  public getItemProperty(item: [], property: FormArrayItemProperties): string {
    const name = typeof property.name === 'string' ? item[property.name] : property.name(item);
    return property.translated ? name : this.translate.instant(name);
  }

  public async addItem(): Promise<void> {
    const callback = (itemFromModal: any) => {
      if (!itemFromModal || this.items.find(item => item === itemFromModal)) { return; }
      this.items.push(itemFromModal);
      const fileIndex = this.getFileIndex(this.items[0]);
      this.fileKeysActionIndicator.push({fileIndex, action: filesActionType.insert});
      this.edited.emit({ items: this.items, fileIndicator: this.fileKeysActionIndicator });
    };
    return await this.openModal(callback);
  }

  private getFileIndex(item: any): string {
    return `${TRANSLATE_YEAR_KEY_TO_DOCTYPE_KEY[item.year]}-${TRANSLATE_PERIOD_KEY_TO_DOCTYPE_KEY[item.period]}`;
  }


  public async editItem(selectedItem: any): Promise<void> {
    const item = this.items.find(obj => obj === selectedItem);
    const index = this.items.indexOf(item);

    if (!item) { return; }

    const callback = (updatedItem: any) => {
      if (!updatedItem) { return; }
      const fileIndex = this.getFileIndex(updatedItem);
      this.items[index] = updatedItem;
      this.fileKeysActionIndicator[0].fileIndex = fileIndex;
      this.edited.emit({ items: this.items, fileIndicator: this.fileKeysActionIndicator });
    };
    return await this.openModal(callback, item);
  }

  public removeItem(selectedItem: any): void {
    const item = this.items.find(obj => obj === selectedItem);
    const index = this.items.indexOf(item);
    if (index < 0) { return; }

    this.fileKeysActionIndicator.splice(0, 1);
    this.items.splice(index, 1);
    this.edited.emit({ items: this.items, fileIndicator: this.fileKeysActionIndicator });
  }

  private async openModal(callback, item?: any): Promise<void> {
    const props = { ...this.modalData };
    if (item) { props['item'] = item; }

    return await this.service.openModal(
      this.modalType,
      props,
      'modal-default full-modal',
      callback
    );
  }
}
