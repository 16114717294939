import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalStopScreenshareComponent } from '@components/modal-stop-screenshare/modal-stop-screenshare.component';
import { ModalComponent } from '@components/modal/modal.component';
import { PHONE_NUMBER_LENGTH } from '@constants/forms.constant';
import { CALL_CENTER } from '@constants/pages-content/call-center.constant';
import { SCREENSHARE_CODE_MAX_LENGTH } from '@constants/pages-content/contact.constant';
import { CONTACT_CENTER } from '@constants/plan-vital.constant';
import { Click2CallData, Click2CallRequest } from '@interfaces/genesys.interface';
import { NavParams, ModalController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { ComponentsService } from '@services/cms/components.service';
import { GenesysService } from '@services/genesys/genesys.service';
import { ValidateRut } from '@validators/rut.validator';

@Component({
  selector: 'app-modal-contact-center',
  templateUrl: './modal-contact-center.component.html',
  styleUrls: ['./modal-contact-center.component.scss'],
})
export class ModalContactCenterComponent extends ModalComponent {
  public cobrowseConfig;
  public pageContent = CALL_CENTER;
  public click2CallContent = this.pageContent.click2Call;
  public cobrowsingContent = this.pageContent.cobrowsing;
  public contactCenterNumber = CONTACT_CENTER.NUMBER;
  public screenshareCodeMaxLength = SCREENSHARE_CODE_MAX_LENGTH;
  public isSessionActive = this.genesysService.isSessionActive.value;
  public isWithinContactCenterHours = true;
  public isContactCenterAvailable = true;
  public callCenterHours: string;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public click2callForm: UntypedFormGroup;
  public screenshareForm: UntypedFormGroup;
  public isClick2CallSelected = false;
  public isCobrowsingSelected = false;
  public successfulClick2Call = false;
  public isClick2CallActive = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private genesysService: GenesysService,
    public router: Router,
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private componentsService: ComponentsService,
    public dialog: MatDialog,
  ) {
    super(params, modalCtrl);
    this.getCallCenterHours();
    this.checkContactCenterAvailable();
    this.genesysService.isSessionActive.subscribe(response => {
      this.isSessionActive = response;
    });
  }

  public get noOptionSelected(): boolean { return !this.isClick2CallSelected && !this.isCobrowsingSelected; }
  public get isShareScreenButtonDisabled(): boolean { return this.screenshareForm.invalid || this.isSessionActive; }
  public get shareScreenButtonText(): string {
    return this.isSessionActive ? this.cobrowsingContent.disabledScreenshareButton : this.cobrowsingContent.screenshareButton;
  }

  public get enableContactCenterButtons(): boolean { return this.isWithinContactCenterHours && this.isContactCenterAvailable; }
  public get isScreenshareAvailable(): boolean { return this.genesysService.isScreenshareAvailable; }

  public get name(): UntypedFormControl { return this.click2callForm.get('name') as UntypedFormControl; }
  public get rut(): UntypedFormControl { return this.click2callForm.get('rut') as UntypedFormControl; }
  public get phone(): UntypedFormControl { return this.click2callForm.get('phone') as UntypedFormControl; }
  public get reason(): UntypedFormControl { return this.click2callForm.get('reason') as UntypedFormControl; }

  public get securityCode(): UntypedFormControl { return this.screenshareForm.get('securityCode') as UntypedFormControl; }

  public submitClick2CallForm(): void {
    if (this.click2callForm.invalid) { return; }
    const callbackRequest = this.getClick2CallRequest(this.click2callForm.value);
    this.genesysService.sendClick2CallRequest(callbackRequest).subscribe(() => {
      this.successfulClick2Call = true;
    });
  }

  public selectClick2Call(): void {
    this.setClick2CallForm();
    this.isClick2CallSelected = true;
  }

  public selectCobrowsing(): void {
    this.setScreenshareForm();
    this.isCobrowsingSelected = true;
  }

  public async startScreenshare(): Promise<void> {
    if (this.screenshareForm.invalid) { return; }
    try {
      await this.genesysService.initializeScreenshare(this.securityCode.value);
      this.dialog.open(ModalStopScreenshareComponent, {
        hasBackdrop: false,
        position: { bottom: '20px' },
        panelClass: 'stop-screenshare-modal'
      });
      this.closeModal();
    } catch (err) {
      this.securityCode.setErrors({ invalid: 'invalid' });
    }
  }

  public goBack(): void {
    this.isClick2CallSelected = false;
    this.isCobrowsingSelected = false;
  }

  private getClick2CallRequest(callbackData: Click2CallData): Click2CallRequest {
    const { name, rut, phone, reason } = callbackData;
    return {
      callbackNumbers: [phone],
      callbackUserName: name,
      rut,
      reason,
      url: this.router.url,
      isFromPublic: true,
    };
  }

  private setClick2CallForm(): void {
    this.click2callForm = this.formBuilder.group({
      name: ['', Validators.required],
      rut: ['', [ValidateRut, Validators.required]],
      phone: ['', [
        Validators.required,
        Validators.minLength(PHONE_NUMBER_LENGTH),
        Validators.maxLength(PHONE_NUMBER_LENGTH),
      ]],
      reason: [''],
    });
  }

  private setScreenshareForm(): void {
    this.screenshareForm = this.formBuilder.group({
      securityCode: ['', [Validators.required]],
    });
  }

  private getCallCenterHours(): void {
    this.componentsService.getGeneralInfo()
      .subscribe((response) => {
        this.callCenterHours = response.callCenterHours;
        this.isContactCenterAvailable = response.callCenterAvailable;
      });
  }

  private checkContactCenterAvailable(): void {
    this.genesysService.isContactCenterAvailable()
      .subscribe((response) => this.isWithinContactCenterHours = response);
  }
}
