import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'pensionado/pago-pension', pathMatch: 'full' },
  { path: 'pensionado', redirectTo: 'pensionado/pago-pension', pathMatch: 'full' },
  { path: 'inicio', redirectTo: 'pago-pension', pathMatch: 'full' },
  { path: 'pago-pension', loadChildren: () => import('./pension-payment/pension-payment.module').then(m => m.PensionPaymentPageModule) },
  { path: 'beneficios', loadChildren: () => import('./benefits/benefits.module').then(m => m.BenefitsPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PensionerRoutingModule { }
