<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <div class="icon-result" [ngClass]="{ 'i-check': isValid, 'i-error': !isValid }"></div>
  <p class="title" [ngClass]="font?.size">{{ resolution?.title }}</p>
  <p class="subtitle" [ngClass]="font?.size" *ngIf="!isValid" [innerHTML]="resolution?.subtitle"></p>
  <div class="button-container" *ngIf="isValid">
    <ion-button class="button valid" *ngIf="isValid" [ngClass]="font?.size" (click)="downloadCertificate()">{{ content?.download }}</ion-button>
    <ion-button class="button"  *ngIf="false" [ngClass]="font?.size" (click)="closeModal()">{{ content?.goBack }}</ion-button>
  </div>
</div>
