const pageKey = 'CALL_CENTER';

export const BUTTONS_TITLE: { [key: string]: string; } = {
  contactCenter: `${pageKey}.CONTACT_CENTER`,
  branchOffices: `${pageKey}.BRANCH_OFFICES`,
  callCenter: `${pageKey}.CALL_CENTER`,
  contact: `${pageKey}.CONTACT`,
  faqs: `${pageKey}.FAQS`,
  top: `${pageKey}.TOP`,
  webchat: `${pageKey}.WEBCHAT`,
  facebook: 'Facebook',
  twitter: 'Twitter',
  instagram: 'Instagram',
  linkedin: 'Linkedin',
  youtube: 'Youtube',
  whatsapp: 'WhatsApp',
  email: 'Email',
};
