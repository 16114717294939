import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@components/modal/modal.component';
import { ConfirmationModalData } from '@interfaces/modal.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent {
  public title: string;
  public description: string;
  public disclaimer: string;
  public informative: boolean;
  public backBtn: string;
  public confirmBtn: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData,
    public dialogRef: MatDialogRef<ModalComponent>,
    public font: FontService,
  ) {
    this.title = data.title;
    this.description = data.description;
    this.disclaimer = data.disclaimer;
    this.informative = data.informative;
    this.backBtn = data.backBtn;
    this.confirmBtn = data.confirmBtn;
  }

  public closeModal(changeRoute: boolean): void {
    this.dialogRef.close(changeRoute);
  }
}
