<div class="modal">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="title-container">
    <div class="title" [ngClass]="font?.size" [innerHTML]="modalInfo?.title | translate"></div>
    <div class="subtitle" [ngClass]="font?.size" [innerHTML]="modalInfo?.description | translate"></div>
  </div>
  <ng-template #informationComponent></ng-template>
  <div class="centered-container">
    <ion-button class="button primary back" [ngClass]="font?.size" (click)="closeModal()">Volver</ion-button>
  </div>
</div>
