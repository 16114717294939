<div class="container" *ngIf="formRequestStatusDetail" [class.is-mobile]="isMobile"
  [class.hide-right]="!requestDetails.hasAttachment">

  <div class="left-column" [class.is-mobile]="isMobile">
    <button [ngClass]="font?.size" class="btn large secondary with-icon" (click)="goBack()"><em
        class="icon i-back-form"></em>
      {{ pageContent?.requestContent.buttonBack | translate }}</button>
    <app-card [title]="pageContent.requestContent.information.title">
      <div class="information-container">
        <div class="fields-group">
          <div class="request-details">
            <p class="header" [ngClass]="font?.size">{{ pageContent?.requestContent.information.request.type | translate
              | uppercase }}</p>
            <p class="detail" [ngClass]="font?.size">{{ requestDetails.type | titlecase | translate }}</p>
          </div>
          <div class="request-details">
            <p class="header" [ngClass]="font?.size">{{ pageContent?.requestContent.information.request.date | translate
              | uppercase }}</p>
            <p class="detail" [ngClass]="font?.size">{{ requestDetails.date }}</p>
          </div>
          <div class="request-details">
            <p class="header" [ngClass]="font?.size">{{ pageContent?.requestContent.information.request.origin |
              translate | uppercase }}</p>
            <p class="detail" [ngClass]="font?.size">{{ channel }}</p>
          </div>
        </div>
        <div *ngIf="showDeceasedInfo" class="fields-group">
          <h3 class="title-secondary" [ngClass]="font?.size">{{ pageContent?.requestContent.deceasedAffiliate.title |
            translate }}</h3>
          <div class="request-details">
            <p class="header" [ngClass]="font?.size">{{ pageContent?.requestContent.deceasedAffiliate.name |
              translate | uppercase }}</p>
            <p class="detail" [ngClass]="font?.size">{{ requestDetails.deceased.name }}</p>
          </div>
          <div class="request-details">
            <p class="header" [ngClass]="font?.size">{{ pageContent?.requestContent.deceasedAffiliate.rut |
              translate | uppercase }}</p>
            <p class="detail" [ngClass]="font?.size">{{ requestDetails.deceased.rut }}</p>
          </div>
        </div>
      </div>
    </app-card>
    <app-card [title]="pageContent.requestContent.historyStatus.title" [hidden]="false">
      <div class="status-container">
        <div class="status-list">
          <ul class="status-bullet">
            <li *ngFor="let statusDefault of historyStatusDefault"
              [ngClass]="[statusDefault.history, statusDefault.visible]">
              <div class="label-container">
                <p class="status" [ngClass]="statusDefault.history">
                  {{ getStatusLabel(statusDefault.status) | translate | uppercase }}
                </p>
                <app-info-text-popup *ngIf="statusDefault.displayDefinition" [definitions]="statusDefault.definitions"
                  iconName="i-tooltip-blue">
                  <div class="i-tooltip-blue"></div>
                </app-info-text-popup>
              </div>
              <ul class="sub-status-bullet" *ngIf="statusDefault.displayChildStatus">
                <li *ngFor="let childStatus of statusDefault.childStatus" class="only-color">
                  <p class="status sub-status" [ngClass]="childStatus.history">
                    {{ childStatus.status | translate | uppercase }}
                  </p>
                  <app-info-text-popup *ngIf="childStatus.displayDefinition" [definitions]="childStatus.definitions"
                    iconName="i-tooltip-blue">
                    <div class="i-tooltip-blue"></div>
                  </app-info-text-popup>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="isPartial || noShowMessagePartial">
          <div class="information-container-box">
            <app-disclaimer-box *ngIf="!noShowMessagePartial"
              [description]="pageContent?.requestContent.historyStatus.disclaimer | translate"
              [typeDisclaimer]="'alert'">
            </app-disclaimer-box>
          </div>
          <div class="button-container">
            <button class="btn small secondary" [ngClass]="font?.size" (click)="goToForm()">
              {{ pageContent?.requestContent.button | translate }}</button>
          </div>
        </ng-container>
      </div>
    </app-card>
  </div>
  <div class="right-column" [class.is-mobile]="isMobile">
    <app-card-attached-documents *ngIf="formRequestStatusDetail" [requestData]="formRequestStatusDetail"
      [disabled]="!isPendingDocuments || noDocumentToShow">
    </app-card-attached-documents>
    <app-card-comments *ngIf="formRequestStatusDetail?.id" [disabled]="!isPendingDocuments || noDocumentToShow"
      [id]="formRequestStatusDetail?.id" [contactName]="formRequestStatusDetail?.contactName"></app-card-comments>
  </div>
</div>