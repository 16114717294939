import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@components/modal/modal.component';
import {
  GENDERS, PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN,
  VALIDATORS,
  filesActionType
} from '@constants/forms.constant';
import { BENEFICIARY_MODAL_CONTENT } from '@constants/pages-content/beneficiary.constants';
import { Beneficiary } from '@interfaces/beneficiary.interface';
import { DefaultResponseItem } from '@interfaces/general.interface';
import { ModalController, NavParams } from '@ionic/angular';
import {
  EmailDomainValidator
} from '@providers/email-domain-validator/email-domain-validator.service';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';
import { ValidateRut } from '@validators/rut.validator';

@Component({
  selector: 'app-modal-beneficiary',
  templateUrl: './modal-beneficiary.component.html',
  styleUrls: ['./modal-beneficiary.component.scss'],
})
export class ModalBeneficiaryComponent extends ModalComponent implements OnInit {
  public pageContent = BENEFICIARY_MODAL_CONTENT;
  public beneficiary: Beneficiary;
  public form: UntypedFormGroup;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public genders = GENDERS;
  public relationships: Array<DefaultResponseItem> = [];
  public showContactInfo = true;
  public showTutorInfo = false;
  public includeAttachmentFile = false;
  public loadData = false;
  public maxDate = new Date();
  public fileLabel = 'Adjuntar Cédula';
  private actionFile = filesActionType.none;

  public get mobileWidth(): boolean { return window.innerWidth < 500; }
  public get rut(): AbstractControl { return this.form.controls.rut; }
  public get tutorRut(): AbstractControl { return this.form.controls.tutorRut; }
  public get fileName(): string { return this.getControl('fileName').value; }
  public get buttonText(): string {
    return this.beneficiary
      ? this.pageContent.buttons.updateBeneficiary
      : this.pageContent.buttons.addBeneficiary;
  }

  constructor(
    public font: FontService,
    private emailDomainValidator: EmailDomainValidator,
    private formBuilder: UntypedFormBuilder,
    private util: Utils,
    private formUtils: FormUtils,
    private changeDetector: ChangeDetectorRef,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.beneficiary = this.params.get('item');
    this.relationships = this.params.get('relationships');
    this.showContactInfo = !this.params.get('removeContactInfo');
    this.includeAttachmentFile = this.params.get('includeAttachmentFile');
    this.showTutorInfo = this.params.get('showTutorInfo');
    this.fileLabel = this.params.get('fileLabel') || this.fileLabel;
    this.loadData = true;
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public async validateEmailDomain(email: AbstractControl) {
    const validDomain = await this.emailDomainValidator.validateEmailDomain(email.value);
    if (!validDomain) { return email.setErrors({ ...email.errors, invalidDomain: true }); }
  }

  public getControl(control: string): AbstractControl {
    return this.form.controls[control];
  }

  public setDate(): void {
    this.formUtils.setDateSelected(this.form, 'birthdate');
  }

  public addBeneficiary(): void {
    if (this.form.invalid) { return; }

    const { name, lastName, secondSurname } = this.form.value;
    const fullName = this.util.getFullName(name, lastName, secondSurname);


    const beneficiary: Beneficiary = {
      ...this.form.value,
      cellphone: String(this.showContactInfo ? this.getControl('cellphone').value : ''),
      fullName,
      action: this.actionFile,
    };
    this.closeModal(beneficiary);
  }

  private createForm(): void {
    let group = {
      rut: [this.getBeneficiaryAttribute('rut'), [Validators.required, ValidateRut]],
      name: [this.getBeneficiaryAttribute('name'), VALIDATORS.NAME],
      lastName: [this.getBeneficiaryAttribute('lastName'), VALIDATORS.NAME],
      secondSurname: [this.getBeneficiaryAttribute('secondSurname'), VALIDATORS.OPTIONAL_NAME],
      birthdate: [this.getBeneficiaryAttribute('birthdate'), Validators.required],
      birthdateInput: [this.getBeneficiaryAttribute('birthdate')],
      gender: [this.getBeneficiaryAttribute('gender'), Validators.required],
      isDisabled: [this.getBeneficiaryAttribute('isDisabled'), Validators.required],
      relationshipId: [this.getBeneficiaryAttribute('relationshipId'), Validators.required],
    };
    if (this.showContactInfo) {
      group = Object.assign(group, {
        cellphone: [
          this.getBeneficiaryAttribute('cellphone'), [
            Validators.required,
            Validators.minLength(this.phoneNumberLength),
            Validators.maxLength(this.phoneNumberLength),
            Validators.min(this.phoneNumberMin),
            Validators.max(this.phoneNumberMax),
          ],
        ],
        email: [this.getBeneficiaryAttribute('email'), [Validators.required, Validators.email]],
        reEmail: [this.getBeneficiaryAttribute('email'), [Validators.required, Validators.email]],
      });
    }
    if (this.includeAttachmentFile) {
      group = Object.assign(group, {
        file: [this.getBeneficiaryAttribute('file'), Validators.required],
        fileName: [this.getBeneficiaryAttribute('fileName'), Validators.required]
      });
    }
    if (this.showTutorInfo) {
      group = Object.assign(group, {
        tutorRut: [this.getBeneficiaryAttribute('tutorRut'), [Validators.required, ValidateRut]],
        tutorName: [this.getBeneficiaryAttribute('tutorName'), VALIDATORS.NAME],
        tutorLastName: [this.getBeneficiaryAttribute('tutorLastName'), VALIDATORS.NAME],
        tutorSecondSurname: [this.getBeneficiaryAttribute('tutorSecondSurname'), VALIDATORS.OPTIONAL_NAME],
        tutorCellphone: [
          this.getBeneficiaryAttribute('tutorCellphone'), [
            Validators.required,
            Validators.minLength(this.phoneNumberLength),
            Validators.maxLength(this.phoneNumberLength),
            Validators.min(this.phoneNumberMin),
            Validators.max(this.phoneNumberMax),
          ],
        ],
        tutorEmail: [this.getBeneficiaryAttribute('tutorEmail'), [Validators.required, Validators.email]],
        isMandataryTutor: [this.getBeneficiaryAttribute('isMandataryTutor'), Validators.required],
      });
    }
    this.form = this.formBuilder.group(group, this.showContactInfo ? {
      validator: [mustMatch('email', 'reEmail')]
    } : null);
  }

  public onFileChange(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const isUpdate = this.fileName && this.fileName !== '';
        this.form.patchValue({ file, fileName: file.name });
        this.actionFile = isUpdate ? filesActionType.update : filesActionType.insert;
        this.changeDetector.markForCheck();
      };
    } else {
      this.form.patchValue({ file: null, fileName: null, });
    }
  }

  private getBeneficiaryAttribute(attribute: string): string {
    if (!this.beneficiary) { return ''; }
    return this.beneficiary[attribute];
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }
}
