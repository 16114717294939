import { Component } from '@angular/core';
import { PopoverComponent } from '@components/popover/popover.component';
import { Item } from '@interfaces/general.interface';
import { NavParams, PopoverController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-popover-options',
  templateUrl: './popover-options.component.html',
  styleUrls: ['./popover-options.component.scss'],
})
export class PopoverOptionsComponent extends PopoverComponent {
  public options: Array<Item>;

  constructor(
    public font: FontService,
    private params: NavParams,
    protected popoverCtrl: PopoverController,
  ) {
    super(popoverCtrl);
    this.options = this.params.get('options');
  }

  public changeOption(option: Item) {
    this.dismissPopover(option);
  }

}
