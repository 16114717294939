import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ModalBeneficiaryComponent
} from '@components/modal-beneficiary/modal-beneficiary.component';
import { ACCOUNT_NUMBER_MAX_LENGTH, filesActionType } from '@constants/forms.constant';
import {
  ARRAY_DECEASED_AFFILIATE_PROPERTIES, SURVIVOR_ACCRUAL_OPTIONS
} from '@constants/pages-content/survivor-pension-form.constants';
import { Beneficiary } from '@interfaces/beneficiary.interface';
import { FormFiles } from '@interfaces/form-files';
import { PartialFormFileParams } from '@interfaces/forms.interface';
import { DefaultResponseItem, PaymentMethods, SelectOption } from '@interfaces/general.interface';
import { PaymentMethodForm } from '@interfaces/payment-methods.interface';
import { Backgrounds, DeceasedPayment } from '@interfaces/survivor-pension-form.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';
import { virtualAccountValidator } from '@validators/virtual-account.validator';

@Component({
  selector: 'app-deceased-affiliate-payment',
  templateUrl: './deceased-affiliate-payment.component.html',
  styleUrls: ['./deceased-affiliate-payment.component.scss'],
})
export class DeceasedAffiliatePaymentComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public userRut;
  @Input() public formValues: DeceasedPayment;
  @Output() public goBack = new EventEmitter();
  @Output() public setPaymentForm = new EventEmitter<any>();
  public form: UntypedFormGroup;
  public maxLength = ACCOUNT_NUMBER_MAX_LENGTH;
  public beneficiaries: Array<Beneficiary> = [];
  public accrualOptions: Array<SelectOption> = SURVIVOR_ACCRUAL_OPTIONS;
  public deceasedAffiliateProperties = ARRAY_DECEASED_AFFILIATE_PROPERTIES;
  public paymentData: PaymentMethods;
  public paymentMethods: Array<DefaultResponseItem>;
  public loadedData = false;
  public modalType = ModalBeneficiaryComponent;
  public paymentMethodValues: PaymentMethodForm;

  private relationships: Array<DefaultResponseItem>;

  public getControl(key: string): AbstractControl { return this.form.get(key); }
  public get loadedPage(): boolean { return this.form && this.loadedData; }
  public get modalData(): any {
    return {
      relationships: this.relationships,
      removeContactInfo: false,
      includeAttachmentFile: true,
    };
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private parametersService: ParametersService,
    private errorUtils: ErrorUtils,
    private loadingService: LoadingService,
    private utils: Utils,
    public font: FontService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.createForm();
    await this.loadData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.userRut) { this.userRut = changes.userRut.currentValue; }
  }

  public confirm(): void {
    if (this.form.invalid) { return; }

    const { accountNumber, bankId, paymentMethodId, beneficiaries, preliminary, accrual } = this.form.value;
    const clientOptions: Backgrounds[] = [];
    const files: Array<string> = [];
    const fileParams: PartialFormFileParams = {
      keys: [],
      personRut: [],
      actions: [],
    };

    if (Number(preliminary)) { clientOptions.push({ id: 'preliminary' } as Backgrounds); }
    clientOptions.push({ id: accrual } as Backgrounds);

    if (beneficiaries) {
      beneficiaries.forEach((beneficiary: Beneficiary) => {
        const { file, rut, action } = beneficiary;
        const cleanedRut = this.utils.rutClean(rut);
        fileParams.keys.push(`personalDocument-${cleanedRut}`);
        fileParams.personRut.push(cleanedRut);
        fileParams.actions.push(action === undefined ? filesActionType.none : action);
        files.push(file);
      });
    }

    const paymentMethod = {
      accountNumber,
      bankId,
      paymentMethodId: Number(paymentMethodId)
    } as PaymentMethodForm;

    const formData = {
      paymentMethod,
      beneficiaries,
      clientOptions,
      files: new FormFiles(files),
      fileParams
    } as DeceasedPayment;

    this.setPaymentForm.emit(formData);
  }

  public validateForm(form: UntypedFormGroup, formKey: 'paymentMethod'): void {
    if (formKey !== 'paymentMethod') { return ; }
    const { accountNumber, bankId, paymentMethodId } = form.value;
    this.form.patchValue({
      accountNumber,
      bankId,
      paymentMethodId,
    });
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }


  public setBeneficiaries(beneficiaries: Array<Beneficiary>): void {
    this.beneficiaries = beneficiaries;
    this.updateBeneficiaries();
  }

  private async loadData(): Promise<void> {
    this.loadingService.showLoading();
    await Promise.all([
      this.parametersService.getRelationships().toPromise(),
      this.parametersService.getPaymentMethodsAndBanks().toPromise(),
      this.parametersService.getPaymentMethods('pensionary-forms').toPromise(),
    ])
      .then(([relationships, paymentMethodsAndBanks, paymentMethods]: [
          Array<DefaultResponseItem>, PaymentMethods, Array<DefaultResponseItem>
        ]) => {
          this.relationships = relationships;
          this.paymentData = paymentMethodsAndBanks;
          this.paymentMethods = paymentMethods;
          this.loadedData = true;
      })
      .catch((error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) }))
      .finally(() => this.loadingService.hideLoading());
  }

  private createForm(): void {
    const formValues = this.getFormValues();
    this.form = this.formBuilder.group({
      accountNumber: [formValues.accountNumber, [Validators.required, Validators.maxLength(this.maxLength)]],
      bankId: [formValues.bankId, [Validators.required]],
      paymentMethodId: [formValues.paymentMethodId, Validators.required],
      beneficiaries: [formValues.beneficiaries, Validators.required],
      preliminary: [formValues.preliminary, Validators.required],
      accrual: [formValues.accrual, Validators.required],
    }, { validator: virtualAccountValidator('accountNumber', 'bankId')} );
  }

  private getFormValues(): any {
    if (!this.formValues) { return {}; }
    const files = this.formValues.files;
    const fileParams = this.formValues.fileParams;
    const { accountNumber, bankId, paymentMethodId } = this.formValues.paymentMethod;
    const clientOptions = this.formValues.clientOptions;
    const beneficiaries = this.formValues.beneficiaries;

    this.formValues.beneficiaries.forEach((beneficiary) => {
      const index = fileParams.personRut.findIndex((rut) => this.utils.rutFormat(rut) === beneficiary.rut);
      beneficiary.file = files.data[index];
      beneficiary.fileName = (beneficiary.file as any).name;
    });
    this.paymentMethodValues = { accountNumber, bankId, paymentMethodId };
    this.beneficiaries = beneficiaries;
    const accrual = clientOptions.find((clientOption) => ['normalAccrual', 'scheduledWithdrawal', 'annuity'].includes(clientOption.id));
    return {
      beneficiaries,
      preliminary: clientOptions.some((clientOption) => clientOption.id === 'preliminary') ? '1' : '0',
      accrual: accrual ? accrual.id : null,
      accountNumber,
      bankId: bankId ? bankId.padStart(3, '0') : null,
      paymentMethodId,
    };
  }

  private updateBeneficiaries(): void {
    this.getControl('beneficiaries').setValue(this.beneficiaries);
  }

}
