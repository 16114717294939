<div class="navigation-tab-container">
  <div class="navigation-container general-container">
    <div class="tab-button-container" *ngFor="let tab of tabs">
      <a [href]="tab?.url"
        [ngClass]="font?.size"
        (click)="tabClicked($event, tab?.url)"
        class="tab button primary transparent"
        [class.selected]="currentTab === tab?.url">
        {{ tabName(tab?.name) }}
        </a>
      </div>
  </div>
</div>
