<div class="form-container" *ngIf="loadedPaymentMethods">
  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.attachment.title | translate }}</h3>
    <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.attachment.description | translate }}</p>
    <app-attach-files
      [attachFiles]="requiredFiles"
      [preloadedFiles]="filesLoaded"
      (fileEmitter)="setFiles($event)">
    </app-attach-files>
  </div>
  <div class="form-section">
    <app-payment-method-form
      [pageContent]="pageContent?.paymentMethods"
      [paymentData]="paymentData"
      [paymentMethods]="paymentMethods"
      [allowOtherMethods]="true"
      [userRut]="userRut"
      [formValues]="paymentMethodData"
      (confirmForm)="setPaymentMethods($event)">
    </app-payment-method-form>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" [disabled]="!isValid" (click)="confirm()">
      {{ pageContent?.buttons?.next | translate }}
      <div class="icon-result next"></div>
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      <div class="icon-result back"></div>
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</div>
