<div class="articles-secondary">
  <div class="articles-section general-container">
    <p *ngIf="!loading" class="title" [ngClass]="font?.size" [innerHTML]="articlesSection?.title"></p>
    <ion-skeleton-text *ngIf="loading" class="skeleton-line" animated></ion-skeleton-text>
    <div class="articles-container">
      <app-card-article class="article" *ngFor="let article of articlesSection?.articles" [article]="article"
        [type]="'secondary-banner'" [loading]="loading">
      </app-card-article>
    </div>
    <app-more-information *ngIf="false" class="more-information" [disclaimer]="articlesSection?.disclaimer"
      [buttonText]="articlesSection?.disclaimerButton">
    </app-more-information>
  </div>
</div>
