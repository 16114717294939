import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import {
  InvestorProfileAttentionRequest,
  InvestorProfileResultRequest,
  InvestorProfileResultResponse,
  UpdateInvestorProfileResultRequest } from '@interfaces/investor-profile.interface';
import { ErrorUtils } from '@utils/error.utils';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  SAVE_SIMULATION_RESULT_MOCK,
  SIMULATION_RESULT_ATTENTION_MOCK,
  UPDATE_SIMULATION_RESULT_MOCK } from './mocks/investor-profile.mock';

@Injectable({
  providedIn: 'root'
})
export class InvestorProfileService {
  private baseUrl = `${environment.investorProfileUrl}investor-profile`;

  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
  ) { }

  public saveSimulationResult(data: InvestorProfileResultRequest): Observable<InvestorProfileResultResponse> {
    const url = `${this.baseUrl}/simulation-result`;
    return this.http.post(url, data, SAVE_SIMULATION_RESULT_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public updateSimulationResult(data: UpdateInvestorProfileResultRequest): Observable<void> {
    const url = `${this.baseUrl}/simulation-result`;
    return this.http.patch(url, data, UPDATE_SIMULATION_RESULT_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }

  public sendAttentionCode(data: InvestorProfileAttentionRequest): Observable<void> {
    const url = `${this.baseUrl}/attention-code`;
    return this.http.post(url, data, SIMULATION_RESULT_ATTENTION_MOCK)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
