export const AUVIOUS_SCRIPT = `
var gc_moduloAuvious1 = document.createElement("script");
    gc_moduloAuvious1.setAttribute("type", "module");
    gc_moduloAuvious1.setAttribute("src", "https://genesys.auvious.com/widget/dist/auvious/auvious.esm.js");
    document.head.appendChild(gc_moduloAuvious1);
    
    var gc_moduloAuvious2 = document.createElement("script");
    gc_moduloAuvious2.setAttribute("nomodule", "");
    gc_moduloAuvious2.setAttribute("src", "https://genesys.auvious.com/widget/dist/auvious/auvious.js");
    document.head.appendChild(gc_moduloAuvious2);

    // Custom inputs for widget
    const inputOptions = [
      { type: 'text', name: 'firstName', required: true, label: 'Nombre' },
      { type: 'text', name: 'rut', required: true, label: 'Rut' },
    ];
    
    
    var gc_parametrosAuvious = {
      "auvious-environment": "genesys.auvious.com",
      "chat-mode":"genesys-web-messaging",
      "application-id": "f45d275b-7b58-450a-ab31-e9aa2f5a30bd", /* Identificador de la instalación de Auvious */
      "pc-environment": "mypurecloud.com", /* Región de la organización de Genesys Cloud */
      "pc-organization-id": "44899fbb-b13c-4b1e-863d-6c5b6c05b41e", /* Identificador de la organización de Genesys Cloud */
      "pc-deployment-id": "b9938e11-a7aa-4618-ac81-ef9138b36b6d", /* Identificador del Deployment-ID Web-Messaging */
      //"pc-deployment-id": "7c5033e1-aecf-41bc-bce5-94f32be69a67", /* Identificador del widget de chat de terceros */
      //"pc-queue": "Cola Auvious Videollamada", /* Cola para recibir la videollamada*/
      //"pc-callback-queue": "Cola Auvious Videollamada", /* Cola para programar la videollamada */
      "kiosk-mode": false, /* true para pantalla completa */
      "active-widgets": "video", /* "chat", "video", "callback", "audio" y/o "cobrowse" */
      "wait-for-greeting-seconds": -1, /* segundos para mostrar una burbuja de bienvenida */
      "registration-type": "custom", /* "name" o "nameEmailSubject" */ // on custom for inputs
      "customer-display-name-broadcast":true,
      "test-device-permissions": true,
      //"dark-mode": false, /* true para modo dark */
      "color-primary": "#C21B17", /* color del widget */
      "color-accent": "#C21B17", /* color de elementos seleccionados y botones */
      "color-warn": "#C21B17", /* color de elementos destacados */
      "locale": "es-US" /* Idioma del widget */
    };
    
    function gc_mostrarAuvious() {
      var gc_widgetAuvious = document.createElement("app-auvious-widget");
      //gc_widgetAuvious.setTranslationFile("https://www.e-contact.cl/auvious/es.json", "es"); /* archivo de traducción personalizado */
      Object.keys(gc_parametrosAuvious).forEach(gc_claveAuvious => {
        if (!!gc_parametrosAuvious[gc_claveAuvious]) {
          const gc_valorClave = gc_parametrosAuvious[gc_claveAuvious];
          gc_widgetAuvious.setAttribute(gc_claveAuvious, gc_valorClave)
        }
      });
      document.body.appendChild(gc_widgetAuvious);
      gc_widgetAuvious.setCustomFields(inputOptions);
      gc_widgetAuvious.setCustomerMetadata({ 'origin' : 'publicSite'});
      gc_widgetAuvious.style.display = 'none';

      // // Mostrar widget al conectarse a la sala
      gc_widgetAuvious.addEventListener('callStarting', function (event) {
        // gc_widgetAuvious.style.display = 'initial';
        const url = window.location.href.split('/');
        if (url[3] != 'ingreso') {
          // gc_widgetAuvious.terminate(false);
          gc_widgetAuvious.style.display = 'initial';
        }
      })
      // // Mostrar widget al conectarse a la sala
      // // Mostrar widget al conectarse a la sala
      gc_widgetAuvious.addEventListener('callEnded', function (event) {
        gc_widgetAuvious.style.display = 'none';
      })

      gc_widgetAuvious.addEventListener('conversationEnded', function () {
        gc_widgetAuvious.style.display = 'none';
      })
    };
    
    
    function gc_abrirAuvious() {
      var gc_widgetAuvious = document.querySelector("app-auvious-widget");
      gc_widgetAuvious.setAttribute("customer-display-name", "Cliente"); /* nombre de cliente para inicio automático */
      gc_widgetAuvious.addEventListener("ready", function () {

        gc_widgetAuvious.launch("video")
      });
    };
    
    window.customElements.whenDefined("app-auvious-widget")
    .then(function () {
      gc_mostrarAuvious();
      // gc_abrirAuvious() /* función para iniciar automáticamente la videollamada */
    })
`;
