<div class="step-container">
  <div class="title secondary" [ngClass]="font?.size">{{ content?.title | translate }}</div>
  <div class="dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="content?.description | translate"></div>

  <form class="form-container" [formGroup]="form" autocomplete="off">
    <div class="fields-group">

      <mat-form-field [ngClass]="font.size">
        <mat-label>{{ labels.bankCode | translate }}</mat-label>
        <mat-select formControlName="bankCode">
          <mat-option *ngFor="let bank of paymentMethods.banks" [value]="bank.code">{{ bank.description | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [ngClass]="font.size">
        <mat-label>{{ labels.accountType | translate }}</mat-label>
        <mat-select formControlName="accountType">
          <ng-container *ngFor="let type of paymentMethods.accountTypes">
            <mat-option *ngIf="showAccountTypeOption(type.id)" [value]="type.id">{{ type.description | titlecase }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input-account" [ngClass]="font.size" [class.error]="accountNumber?.hasError('virtualAccount')">
        <mat-label>{{ labels.accountNumber | translate }}</mat-label>
        <input [ngClass]="font.size" matInput type="text" formControlName="accountNumber" [maxLength]="accountNumberMaxLength"
        [pattern]="accountNumberPattern" required>
        <span matSuffix class="i-validator"></span>
        <mat-error *ngIf="requiredError('accountNumber')">{{ errors.required | translate }}</mat-error>
        <mat-error *ngIf="patternError('accountNumber')">{{ errors.patternAccount | translate }}</mat-error>
        <mat-error *ngIf="accountNumber?.hasError('virtualAccount')">{{ errors.patternVirtualAccount | translate }}</mat-error>
        <mat-error *ngIf="isRutAccountNumber()">{{ errors.patternRutAccount | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font.size" class="input-account">
        <mat-label>{{ labels.repeatAccountNumber | translate }}</mat-label>
        <input matInput type="text" formControlName="repeatAccountNumber" [maxLength]="accountNumberMaxLength"
        [pattern]="accountNumberPattern" required blockPaste>
        <span matSuffix class="i-validator"></span>
        <mat-error *ngIf="requiredError('repeatAccountNumber')">{{ errors.required | translate }}</mat-error>
        <mat-error *ngIf="patternError('repeatAccountNumber')">{{ errors.patternAccount | translate }}</mat-error>
        <mat-error *ngIf="mustMatchError('repeatAccountNumber')">{{ errors.patternMatch | translate }}</mat-error>
      </mat-form-field>

    </div>

    <div class="i-alert disclaimer" [ngClass]="font?.size">{{ content?.accountDisclaimer | translate }}</div>
    <div class="i-alert disclaimer" [ngClass]="font?.size" [innerHTML]="content?.disclaimer | translate"></div>

    <div class="fields-group">
      <mat-checkbox [ngClass]="font?.size" formControlName="authorize" color="primary" required>
        {{ content?.authorize | translate }}
      </mat-checkbox>
    </div>

    <div class="fields-group">
      <mat-checkbox [ngClass]="font?.size" formControlName="personalConsent" color="primary" required>
        {{ content?.personalConsent | translate }}
      </mat-checkbox>
    </div>

    <ion-button class="button primary form-button" (click)="goNextStep()" [disabled]="form.invalid" >{{ buttons.send | translate }}</ion-button>
    <ion-button class="button secondary form-button" (click)="goPreviousStep()">{{ buttons.previous | translate }}</ion-button>
  </form>
</div>
