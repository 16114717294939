<div class="fund-infographic">
  <div class="info-container">
    <div class="fund-name-container" [ngClass]="backgroundClass('background')">
      <p class="fund-name" [ngClass]="font?.size">{{ fund?.name}}</p>
    </div>
    <div class="fund-info">
      <p class="fund-risk" [ngClass]="[font?.size, backgroundClass('risk')]">{{ fund?.risk | translate }}</p>
      <p class="fund-risk-description" [ngClass]="font?.size">{{ fund?.description | translate }}</p>
    </div>
  </div>
  <div class="help-container" [ngClass]="font?.size" (click)="openHelp($event)">
    <p class="help-text">Límites porcentuales</p>
    <ion-icon class="help-icon" name="alert-circle"></ion-icon>
  </div>
</div>
