export const CURRENT_USER_STORAGE_KEY = 'currentUser';

export const DEFAULT_FILE_UPLOADING_STATUS = {
  name: 'normal',
  format: 'normal',
  size: 'normal',
};

export const STATUS_ICONS = {
  normal: 'i-circle',
  success: 'i-circle-check',
  error: 'i-circle-close',
};
