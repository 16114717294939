import { Component } from '@angular/core';
import { HeaderComponent } from '@components/header/header.component';
import { ModalLoginComponent } from '@components/modal-login/modal-login.component';
import {
  PopoverHeaderMenuComponent
} from '@components/popover-header-menu/popover-header-menu.component';
import { HeaderOption } from '@interfaces/header.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { ModalService } from '@providers/modal/modal.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { PopoverService } from '@providers/popover/popover.service';
import { RouterService } from '@providers/router/router.service';
import { ComponentsService } from '@services/cms/components.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
})
export class HeaderDesktopComponent extends HeaderComponent {
  constructor(
    public font: FontService,
    protected componentsService: ComponentsService,
    protected navService: NavigationService,
    protected modalService: ModalService,
    protected routerService: RouterService,
    protected popoverService: PopoverService,
    public language: LanguageProvider,
    public util: Utils,
  ) {
    super(font, componentsService, navService, modalService, routerService, popoverService, language, util);
  }

  public openOptions(item: HeaderOption, event: Event) {
    const { options, url, actualUrl, isModal } = item;
    if (isModal) {
      if (!this.showModalLogin) { return this.navService.goTo(url); }
      return this.modalService.openModal(
        ModalLoginComponent,
        { newUrl: url, actualUrl },
        'modal-default modal-login'
      );
    }
    if (url) { return this.navService.goTo(url); }
    if (!options || options.length === 0) { return; }
    this.popoverService.presentPopover(PopoverHeaderMenuComponent, { options }, event as Event, 'auto', 'popover-header');
  }

  public goToWithPreventDefault(event: any, url: string) {
    event.preventDefault();
    this.navService.goTo(url);
  }


}
