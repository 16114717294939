import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PopoverOptionsComponent } from '@components/popover-options/popover-options.component';
import { HEADER_OPTIONS, LANGUAGES } from '@constants/header.constant';
import { LinkModal } from '@interfaces/components.interface';
import { Item } from '@interfaces/general.interface';
import { HeaderOption } from '@interfaces/header.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { ModalService } from '@providers/modal/modal.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { PopoverService } from '@providers/popover/popover.service';
import { RouterService } from '@providers/router/router.service';
import { ComponentsService } from '@services/cms/components.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public showAlert = false;
  @Input() public showModalLogin = false;
  public showInformation: boolean;
  public alertInformation: LinkModal;
  public languageSelected = LANGUAGES.find((language) => language.id === this.util.language);
  public options: Array<HeaderOption> = HEADER_OPTIONS;
  public searchForm = new UntypedFormGroup({
    words: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
  });

  public get homeUrl(): string { return `${this.defaultModule}/inicio`; }
  public get defaultModule(): string { return this.routerService.defaultModule; }

  constructor(
    public font: FontService,
    protected componentsService: ComponentsService,
    protected navService: NavigationService,
    protected modalService: ModalService,
    protected routerService: RouterService,
    protected popoverService: PopoverService,
    public languageProvider: LanguageProvider,
    public util: Utils,
  ) {
    this.loadAlertInformation();
    this.languageSubscription();
  }

  public goTo(url: string): void {
    this.navService.goTo(url);
  }

  public setLanguage(language: Item): void {
    if (this.languageSelected === language) { return; }
    this.languageProvider.setLanguage(language);
    this.languageSelected = language;
  }

  public openLanguageOptions(event): void {
    const params = {
      options: LANGUAGES,
    };
    this.popoverService.presentPopover(PopoverOptionsComponent, params, event, 'popover', 'auto', (value) => this.setLanguage(value));
  }

  public get primaryOptions(): Array<HeaderOption> {
    const primaryOption = this.options.find((option) => this.routerService.getSection(option.url) === this.defaultModule);
    return primaryOption ? primaryOption.options : [];
  }

  public goToSearch(): void {
    if (this.searchForm.invalid) { return; }
    const { value } = this.searchForm.get('words');
    this.searchForm.reset();
    this.navService.goTo(`buscar/${value}`);
  }

  public eventHandler(key: string): void {
    if (key !== 'Enter') { return; }
    this.goToSearch();
  }

  public closeInformation(): void {
    this.showInformation = false;
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() => {
      this.loadAlertInformation();
    });
  }

  private loadAlertInformation(): void {
    this.componentsService.getGeneralInfo()
      .subscribe((response) => {
        this.alertInformation = response.floatingButton;
        this.showInformation = this.alertInformation.show;
        this.showModalLogin = response.loginModalActive;
      });
  }
}
