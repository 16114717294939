import { Component, Input } from '@angular/core';
import { PENSION_PAYMENT_PAGES } from '@constants/pension-payment.constant';
import { MainActions } from '@interfaces/home.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-main-actions',
  templateUrl: './main-actions.component.html',
  styleUrls: ['./main-actions.component.scss'],
})
export class MainActionsComponent {
  @Input() public mainActions: MainActions;
  @Input() public loading = false;
  public registerAccountUrl = PENSION_PAYMENT_PAGES.registerAccount.url;
  public calendarPageUrl = PENSION_PAYMENT_PAGES.calendar.url;
  public get redirect() {
    return 'afiliado/asesoria/certificados/obtener';
  }

  constructor(
    public font: FontService,
    private navService: NavigationService,
  ) { }

  public openPage(event: any, url: string) {
    event.preventDefault();
    this.navService.goTo(url);
  }
}
