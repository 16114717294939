<div class="form-container">
  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.backupAttachment.title | translate }}</h3>
    <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.backupAttachment.description | translate }}</p>
    <div class="information-container">
      <app-disclaimer-box
        [description]= "pageContent?.backupAttachment.disclaimer"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>
    <app-attach-files
      [attachFiles]="requiredFiles"
      [preloadedFiles]="filesLoaded"
      (fileEmitter)="setFiles($event)">
    </app-attach-files>
  </div>
  <div class="form-section">
    <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.notarialAttachment.title | translate }}</h3>
    <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.notarialAttachment.description | translate }}</p>
    <div class="array-documents">
      <app-form-array-list
        [pageContent]="pageContent?.notarialAttachment.buttonPowerAttorney"
        [iconClass]="'person-locked'"
        [itemProperties]="documentProperties"
        [modalType]="modalType"
        [modalData]="modalData"
        [maxFiles]="maxPowerAttorney"
        [items]="powerAttorneyItems"
        (edited)="setDocument($event)">
      </app-form-array-list>
    </div>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" [disabled]="!isValid" (click)="confirm()">
      {{ pageContent?.buttons?.next | translate }}
      <div class="icon-result next"></div>
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      <div class="icon-result back"></div>
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</div>
