<div class="partial-content">
  <ion-button class="btn small secondary label medium save" [disabled]="!validForm" (click)="sendForm(true)">
    {{ pageContent?.buttons.saveContinueLater | translate }}
    <div class="icon-result save"></div>
  </ion-button>
</div>
<form class="form-container" [formGroup]="form" *ngIf="form" (ngSubmit)="sendForm()">
  <div class="form-section">
    <div class="title secondary" [ngClass]="font?.size">{{ pageContent?.title | translate }}</div>
    <p class="subtitle dynamic-text secondary" [ngClass]="font?.size">{{ pageContent?.question | translate | titlecase  }}</p>
    <mat-radio-group class="options" formControlName="option">
      <div class="option">
        <div class="left-radio-button">
          <mat-radio-button [value]="true"></mat-radio-button>
        </div>
        <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pageContent?.options.yes | translate }}</div>
      </div>
      <div class="option">
        <div class="left-radio-button">
          <mat-radio-button [value]="false"></mat-radio-button>
        </div>
        <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pageContent?.options.no | translate }}</div>
      </div>
    </mat-radio-group>

    <ng-container *ngIf="option.value">
      <p class="title secondary" [ngClass]="font?.size">{{ pageContent?.selectAccount | translate | titlecase }}</p>  
      <div class="accounts-container">
        <mat-checkbox *ngFor="let account of accounts" [ngClass]="font?.size" color="primary"
          [checked]="isChecked(account.type)"
          (change)="updateAccounts(account.type, $event.checked)">
          <div class="account-container">
            <p [innerHTML]="account.description"></p>
            <app-info-text-popup class="tooltip-icon" *ngIf="account.definitions" [definitions]="account.definitions" iconName="i-tooltip-blue">
              <div class="i-tooltip-blue"></div>
            </app-info-text-popup>
          </div>
        </mat-checkbox>
      </div>
    </ng-container>

    <div class="title secondary" [ngClass]="font?.size">{{ pageContent?.disclaimerTitle | translate }}</div>
    <p class="disclaimer saving" [ngClass]="font?.size">{{ pageContent?.disclaimerList.saving | translate }}</p>
    <p class="disclaimer padlock" [ngClass]="font?.size">{{ pageContent?.disclaimerList.padlock | translate }}</p>
    <p class="disclaimer profitability" [ngClass]="font?.size">{{ pageContent?.disclaimerList.profitability | translate }}</p>
    <p class="disclaimer wait-time" [ngClass]="font?.size">{{ pageContent?.disclaimerList.waitTime | translate }}</p>
    <mat-checkbox formControlName="declaration" color="primary" [ngClass]="font?.size">
      {{ pageContent?.declaration | translate }}
    </mat-checkbox>
  </div>
  
  <div class="button-container">
    <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="!validForm">
      {{ pageContent?.buttons.submit | translate }}
      <div class="icon-result next"></div>
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      <div class="icon-result back"></div>
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
