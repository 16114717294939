import { SecondaryAccount } from '@interfaces/savings.interface';

import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.SAVE_WITH_US.SECONDARY_ACCOUNT';

export const SECONDARY_ACCOUNT_CONTENT: SecondaryAccount = {
  header: {
    image: null,
    icon: 'lock',
    firstLine: `${pageKey}.HEADER.TITLE`,
    secondLine: `${pageKey}.HEADER.SUBTITLE`,
  },
  button: `${pageKey}.BUTTON`,
  carousel: {
    title: `${pageKey}.CAROUSEL.TITLE`,
    subtitle: `${pageKey}.CAROUSEL.SUBTITLE`,
    benefits: []
  },
  savingCardsSection: {
    title: `${pageKey}.CARDS.TITLE`,
    subtitle: `${pageKey}.CARDS.SUBTITLE`,
    cards: [
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_producto.jpg',
          alt: 'Cuenta de ahorro, producto'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.UNIVERSAL.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_proque_ahorrar.jpg',
          alt: 'Cuenta de ahorro, ahorrar'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.MINIMUM_AGE.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
      {
        image: {
          url: 'assets/images/AFP_Planvital_cuenta_2_cuenta_de_ahorro_tipos_depositos.jpg',
          alt: 'Cuenta de ahorro, tipos de depósitos'
        },
        title: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.TITLE`,
        description: `${pageKey}.CARDS.CHARACTERISTICS.DEPOSITS.DESCRIPTION`,
        showMoreText: MORE_INFO,
        modal: null,
        isRedirect: false,
        redirectButton: {
          redirectURL: '',
          textButton: '',
        }
      },
    ]
  },
  depositsSection: {
    title: `${pageKey}.DEPOSITS.TITLE`,
    body: `${pageKey}.DEPOSITS.BODY`,
  },
  withdrawalsSection:  {
    title: `${pageKey}.WITHDRAWALS.TITLE`,
    subtitle: `${pageKey}.WITHDRAWALS.SUBTITLE`,
    body: null,
  },
  restrictionsSection: {
    title: `${pageKey}.RESTRICTIONS.TITLE`,
    body: null,
  },
  commissionDisclaimer: {
    title: `${pageKey}.COMMISSION_DISCLAIMER.TITLE`,
    description: `${pageKey}.COMMISSION_DISCLAIMER.DESCRIPTION`
  }
};
