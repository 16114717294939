<div class="box">
  <h3 class="title" [ngClass]="font?.size">{{ boxData?.title | translate}}</h3>
  <form [formGroup]="registerForm">
    <div class="input-container primary">
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.name | translate}}</ion-label>
        <ion-input formControlName="name"></ion-input>
      </ion-item>
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.rut | translate}}</ion-label>
        <ion-input formControlName="rut"></ion-input>
      </ion-item>
    </div>
    <div class="input-container primary">
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.phoneNumber | translate}}</ion-label>
        <ion-input type="tel" formControlName="phoneNumber" [maxLength]="maxLength" [minLength]="minLength"></ion-input>
      </ion-item>
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.email | translate }}</ion-label>
        <ion-input type="mail" formControlName="email"></ion-input>
      </ion-item>
    </div>
    <div class="input-container secondary">
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.bankName | translate }}</ion-label>
        <ion-input formControlName="bankName"></ion-input>
      </ion-item>
      <ion-item class="question-box">
        <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.accountType | translate }}</ion-label>
        <ion-input formControlName="accountType"></ion-input>
      </ion-item>
      <div class="question-box">
        <ion-item>
          <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData?.accountNumber | translate }}</ion-label>
          <ion-input formControlName="accountNumber"></ion-input>
          <ion-label class="label" position="stacked" *ngIf="">{{ boxData?.accountNumber | translate }}</ion-label>
        </ion-item>
        <div class="error-label">
          <mat-error *ngIf="registerForm.controls['accountNumber'].hasError('forbiddenAccount')">
            {{ errors.forbiddenAccount | translate }}
          </mat-error>
        </div>
      </div>
    </div>
    <div class="disclaimer" [ngClass]="font?.size" [innerHtml]="recaptchaContent | translate"></div>
    <div class="centered-container">
      <ion-button class="button primary" [ngClass]="font?.size" (click)="sendForm()" [disabled]="registerForm.invalid" [innerHtml]="boxData?.registerButton | translate"></ion-button>
    </div>
  </form>
</div>
