<div class="modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <div class="modal-icon i-disclaimer-info"></div>

  <div class="title secondary" [ngClass]="font?.size">{{ title | translate }}</div>

  <p class="description" [ngClass]="font?.size">{{ description | translate }}</p>

  <div class="id-imgs">
    <div class="new-id"></div>
    <div class="old-id"></div>
  </div>
</div>
