import { PensionModes } from '@interfaces/future-pensioner.interface';
import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.ASSESSMENT.FUTURE_PENSIONER.MODES';

export const PENSION_MODES_CONTENT: PensionModes = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  modeCards: [
    {
      icon: 'calendar-clock-red',
      style: 'primary',
      title: `${pageKey}.CARDS.PROGRAMMED_PAY.TITLE`,
      description: `${pageKey}.CARDS.PROGRAMMED_PAY.DESCRIPTION`,
      textButton: MORE_INFO,
      modal: {
        title: `${pageKey}.CARDS.PROGRAMMED_PAY.TITLE`,
        description: `${pageKey}.CARDS.PROGRAMMED_PAY.MODAL_DESCRIPTION`,
      },
    },
    {
      icon: 'calendar-bell',
      style: 'default',
      title: `${pageKey}.CARDS.IMMEDIATE.TITLE`,
      description: `${pageKey}.CARDS.IMMEDIATE.DESCRIPTION`,
      textButton: MORE_INFO,
      modal: {
        title: `${pageKey}.CARDS.IMMEDIATE.TITLE`,
        description: `${pageKey}.CARDS.IMMEDIATE.MODAL_DESCRIPTION`,
      },
    },
    {
      icon: 'calendar-check',
      style: 'default',
      title: `${pageKey}.CARDS.TEMPORARY_IMMEDIATE.TITLE`,
      description: `${pageKey}.CARDS.TEMPORARY_IMMEDIATE.DESCRIPTION`,
      textButton: MORE_INFO,
      modal: {
        title: `${pageKey}.CARDS.TEMPORARY_IMMEDIATE.TITLE`,
        description: `${pageKey}.CARDS.TEMPORARY_IMMEDIATE.MODAL_DESCRIPTION`,
      },
    },
    {
      icon: 'calendar-star',
      style: 'default',
      title: `${pageKey}.CARDS.PROGRAMMED_IMMEDIATE.TITLE`,
      description: `${pageKey}.CARDS.PROGRAMMED_IMMEDIATE.DESCRIPTION`,
      textButton: MORE_INFO,
      modal: {
        title: `${pageKey}.CARDS.PROGRAMMED_IMMEDIATE.TITLE`,
        description: `${pageKey}.CARDS.PROGRAMMED_IMMEDIATE.MODAL_DESCRIPTION`,
      },
    },
  ]
};
