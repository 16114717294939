const modalsKey = 'MODALS';

export const MODALS_CONTENT = {
  definitionModal: {
    title: `${modalsKey}.DEFINITIONS.TITLE`
  }
};

export const SUBJECT_PROCEDURE = 'Asistencia trámite';
export type CallType = 'phoneCall' | 'videoCall';
