import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import * as cmsConstants from '@constants/cms.constant';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import { AdministrationWorkers } from '@interfaces/administration.interface';
import { CorporateGovernanceDownloadSections } from '@interfaces/corporation.interface';
import { EmployerHome, FamilyAllowance } from '@interfaces/employer.interface';
import { FaqsContent, FaqsItem } from '@interfaces/faqs.interface';
import { EconomicIndicatorsInfo, FinancialInformation, InvestmentPortfolioInfo } from '@interfaces/financial-information.interface';
import { FooterColumn } from '@interfaces/footer.interface';
import { FundsWithdrawal } from '@interfaces/fund-withdrawal';
import { FuturePensioner } from '@interfaces/future-pensioner.interface';
import { DefaultResponseItem, DownloadContent, DownloadContentItems, DownloadSection, DownloadSectionContent, Section, UrlSection, UrlSectionContent } from '@interfaces/general.interface';
import { Home } from '@interfaces/home.interface';
import { MultiFunds } from '@interfaces/multi-funds.interface';
import { CalendarCms } from '@interfaces/pension-payment.interface';
import { QuotaValuesBanner } from '@interfaces/quota-values-banner.interface';
import { MandatorySavings, SecondaryAccount, VoluntarySavings } from '@interfaces/savings.interface';
import { SuperintendenceBanner } from '@interfaces/superintendence-banner.interface';
import { TransferState } from '@interfaces/transfer-state.interface';
import { WhyTransfer } from '@interfaces/why-transfer.interface';
import { CmsUtils } from '@utils/cms.utils';
import { ErrorUtils } from '@utils/error.utils';

import { ComponentsService } from './components.service';
import { GET_ADMINISTRATION_WORKERS } from './mocks/administration-workers.mock';
import { GET_AFP_POLICIES_LINKS } from './mocks/afp-policies.mock';
import { GET_ANNUAL_MEMORY_LINKS } from './mocks/annual-memory.mock';
import { GET_CORPORATE_GOVERNANCE_LINKS } from './mocks/corporate-governance.mock';
import { GET_CURRENT_COMMISSIONS_MOCKUP } from './mocks/current-commissions-values.mock';
import { GET_ESSENTIAL_FACTS_LINKS } from './mocks/essential-facts-link.mock';
import { GET_FAMILY_ALLOWANCE_MOCKUP } from './mocks/family-allowance.mock';
import { GET_FINANCIAL_INFORMATION_MOCKUP, GET_INVESTMENT_PORTFOLIO_MOCKUP } from './mocks/financial-information.mock';
import { GET_FINANCIAL_STATEMENTS_LINKS } from './mocks/financial-statements-link.mock';
import { GET_FOOTER_MOCKUP } from './mocks/footer.mock';
import { GET_FUNDS_WITHDRAWAL_MOCKUP } from './mocks/funds-withdrawal.mock';
import { GET_FUTURE_PENSIONER_MOCKUP } from './mocks/future-pensioner.mock';
import { GET_HEALTHCARE_INSTITUTIONS_MOCK } from './mocks/healthcare-institutions.mock';
import { GET_EMPLOYER_HOME_MOCKUP, GET_HOME_MOCKUP } from './mocks/home.mock';
import { GET_INFORMATION_OF_INTEREST_LINKS } from './mocks/information-of-interest.mock';
import { GET_MANDATORY_SAVINGS_MOCKUP } from './mocks/mandatory-savings.mock';
import { GET_MORTUARY_QUOTA_RELATIONSHIPS_MOCK } from './mocks/mortuary-quota-relationships.mock';
import { GET_MULTI_FUNDS_MOCKUP } from './mocks/multi-funds.mock';
import { GET_PAYMENT_INSTITUTIONS_MOCK } from './mocks/payment-institutions.mock';
import { GET_QUOTA_VALUES_BANNER_MOCKUP } from './mocks/quota-values-banner.mock';
import { GET_SECONDARY_ACCOUNT_MOCKUP } from './mocks/secondary-account.mock';
import { GET_SUPERINTENDECE_BANNER_MOCKUP } from './mocks/superintendence-banner.mock';
import { GET_TRANSFER_STATE_MOCKUP } from './mocks/transfer-state.mock';
import { GET_VOLUNTARY_SAVINGS_MOCKUP } from './mocks/voluntary-savings.mock';
import { GET_WHY_TRANSFER_MOCKUP } from './mocks/why-transfer.mock';
import { GeneralParams } from '@interfaces/general-params.interface';
import { GET_GENERAL_PARAMETERS_MOCK } from './mocks/general-parameters.mock';
import { ShowImages } from '@interfaces/show-images.interface';
import { GET_SHOW_IMAGES_MOCK } from './mocks/show-images.mock';
import { Banner } from '@interfaces/banner.interface';
import { CurrentCommissionsContent } from '@interfaces/current-commissions.interface';
import { FOOTER_ANNUAL_MEMORY_HEADER_ID, FOOTER_CORPORATE_GOVERNANCE_HEADER_ID, FOOTER_POLICIES_HEADER_ID } from '@constants/banners.constant';
import { GET_FINANCIAL_INDICATORS_CONTENT_MOCKUP } from '@services/financial-indicators/mocks/financial-indicators.mock';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  constructor(
    private componentsService: ComponentsService,
    private cmsUtils: CmsUtils,
    private errorUtils: ErrorUtils,
    private http: HttpClientInterceptor,
  ) {
  }

  public loadHome(): Observable<Home> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.HOME_PATH}?fields=${cmsConstants.HOME_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const homeRequest = this.http.get(url, GET_HOME_MOCKUP);
    const articlesRequest = this.componentsService.getArticles(lang);
    const bannersRequest = this.componentsService.getBanners(lang);
    const productsRequest = this.componentsService.getProducts(lang);
    const guiaVitalRequest = this.componentsService.getGuiaVitalItems(lang);
    return forkJoin([homeRequest, articlesRequest, bannersRequest, productsRequest, guiaVitalRequest])
      .pipe(
        map(response => this.cmsUtils.mapHome(response[0].data, response[1], response[2], response[3], response[4], lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadEmployerHome(): Observable<EmployerHome> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.EMPLOYER_HOME_PATH}?fields=${cmsConstants.EMPLOYER_HOME_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}&sort=${cmsConstants.CMS_SORT}`;
    const homeRequest = this.http.get(url, GET_EMPLOYER_HOME_MOCKUP);
    const articlesRequest = this.componentsService.getArticles(lang);
    const bannersRequest = this.componentsService.getBanners(lang);
    return forkJoin([homeRequest, articlesRequest, bannersRequest])
      .pipe(
        map(([homeResponse, articlesResponse, bannersResponse]) => {
          return this.cmsUtils.mapEmployerHome(homeResponse.data, articlesResponse, bannersResponse, lang);
        }),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadWhyTransfer(): Observable<WhyTransfer> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.WHY_TRANSFER_PATH}?fields=${cmsConstants.WHY_TRANSFER_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const transferRequest = this.http.get(url, GET_WHY_TRANSFER_MOCKUP);
    const articlesRequest = this.componentsService.getArticles(lang);
    const infographicsRequest = this.componentsService.getInfographics(lang);
    return forkJoin([transferRequest, articlesRequest, infographicsRequest])
      .pipe(
        map(response => this.cmsUtils.mapWhyTransfer(response[0].data, response[1], response[2], lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadTransferState(): Observable<TransferState> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.TRANSFER_STATE_PATH}?fields=${cmsConstants.TRANSFER_STATE_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const transferRequest = this.http.get(url, GET_TRANSFER_STATE_MOCKUP);
    const articlesRequest = this.componentsService.getArticles(lang);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([transferRequest, articlesRequest, headersRequest])
      .pipe(
        map(response => this.cmsUtils.mapTransferState(response[0].data, response[1], response[2], lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFooter(): Observable<Array<FooterColumn>> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FOOTER_PATH}?fields=${cmsConstants.FOOTER_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const footerRequest = this.http.get(url, GET_FOOTER_MOCKUP);
    const linksRequest = this.componentsService.getLinks(lang);
    return forkJoin([footerRequest, linksRequest])
      .pipe(
        map(response => this.cmsUtils.mapFooter(response[0].data, response[1], lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadSuperintendenceBanner(): Observable<SuperintendenceBanner> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.SUPERINTENDENCE_PATH}?fields=${cmsConstants.SUPERINTENDENCE_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_SUPERINTENDECE_BANNER_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapSuperintendenceBanner(response.data, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadQuotaValuesBanner(): Observable<QuotaValuesBanner> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.QUOTA_VALUES_PATH}?fields=${cmsConstants.QUOTA_VALUES_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_QUOTA_VALUES_BANNER_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapQuotaValuesBanner(response.data, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadMandatorySavings(): Observable<MandatorySavings> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.MANDATORY_SAVINGS_PATH}?fields=${cmsConstants.MANDATORY_SAVINGS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const mandatorySavingsRequest = this.http.get(url, GET_MANDATORY_SAVINGS_MOCKUP);
    const infographicsRequest = this.componentsService.getInfographics(lang);
    return forkJoin([mandatorySavingsRequest, infographicsRequest])
      .pipe(
        map(response => this.cmsUtils.mapMandatorySavings(response[0].data, response[1])),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadVoluntarySavings(): Observable<VoluntarySavings> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.VOLUNTARY_SAVINGS_PATH}?fields=${cmsConstants.VOLUNTARY_SAVINGS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const voluntarySavingsRequest = this.http.get(url, GET_VOLUNTARY_SAVINGS_MOCKUP);
    const infographicsRequest = this.componentsService.getInfographics(lang);
    return forkJoin([voluntarySavingsRequest, infographicsRequest])
      .pipe(
        map(response => this.cmsUtils.mapVoluntarySavings(response[0].data, response[1])),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadSecondaryAccount(): Observable<SecondaryAccount> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.SECONDARY_ACCOUNT_PATH}?fields=${cmsConstants.SECONDARY_ACCOUNT_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const secondaryAccountRequest = this.http.get(url, GET_SECONDARY_ACCOUNT_MOCKUP);
    const infographicsRequest = this.componentsService.getInfographics(lang);
    return forkJoin([secondaryAccountRequest, infographicsRequest])
      .pipe(
        map(response => this.cmsUtils.mapSecondaryAccount(response[0].data, response[1])),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFinancialInformation(): Observable<FinancialInformation> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FINANCIAL_INFORMATION_PATH}?fields=${cmsConstants.FINANCIAL_INFORMATION_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const financialInformationRequest = this.http.get(url, GET_FINANCIAL_INFORMATION_MOCKUP);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([financialInformationRequest, headersRequest])
      .pipe(
        map(response => this.cmsUtils.mapFinancialInformation(response[0].data, response[1])),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadInvestmentPortfolio(): Observable<InvestmentPortfolioInfo> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.INVESTMENT_PORTFOLIO_PATH}?fields=${cmsConstants.INVESTMENT_PORTFOLIO_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const investmentPortfolioRequest = this.http.get(url, GET_INVESTMENT_PORTFOLIO_MOCKUP);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([investmentPortfolioRequest, headersRequest])
      .pipe(
        map(response => this.cmsUtils.mapInvestmentPortfolio(response[0].data)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadEconomicsIndicators(): Observable<EconomicIndicatorsInfo> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.ECONOMIC_INDICATORS_PATH}?fields=${cmsConstants.ECONOMIC_INDICATORS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_FINANCIAL_INDICATORS_CONTENT_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapEconomicIndicators(response.data)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadMultiFunds(): Observable<MultiFunds> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.MULTI_FUNDS_PATH}?fields=${cmsConstants.MULTI_FUNDS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const multiFundsRequest = this.http.get(url, GET_MULTI_FUNDS_MOCKUP);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([multiFundsRequest, headersRequest])
      .pipe(
        map(([multiFunds, headers]) => this.cmsUtils.mapMultiFunds(multiFunds.data, headers, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFuturePensioner(): Observable<FuturePensioner> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FUTURE_PENSIONER_PATH}?fields=${cmsConstants.FUTURE_PENSIONER_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const futurePensionerRequest = this.http.get(url, GET_FUTURE_PENSIONER_MOCKUP);
    const bannersRequest = this.componentsService.getBanners(lang);
    const headersRequest = this.componentsService.getHeaders();
    return forkJoin([futurePensionerRequest, bannersRequest, headersRequest])
      .pipe(
        map(response => this.cmsUtils.mapFuturePensioner(response[0].data, response[1], response[2])),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFundsWithdrawal(): Observable<FundsWithdrawal> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FUND_WITHDRAWAL_PATH}?fields=${cmsConstants.FUND_WITHDRAWAL_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_FUNDS_WITHDRAWAL_MOCKUP)
      .pipe(
        map(response => this.cmsUtils.mapFundsWithdrawal(response.data, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadCalendar(): Observable<CalendarCms> {
    const lang = this.cmsUtils.getLanguage();
    const paymentCentersRequest = this.componentsService.getPaymentCenters(lang);
    const calendarsRequest = this.componentsService.getCalendars();
    return forkJoin([paymentCentersRequest, calendarsRequest])
      .pipe(
        map(([paymentCenters, calendar]) => this.cmsUtils.mapCalendarCms(paymentCenters, calendar)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFamilyAllowance(): Observable<FamilyAllowance> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FAMILY_ALLOWANCE_PATH}?fields=${cmsConstants.FAMILY_ALLOWANCE_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const familyAllowanceRequest = this.http.get(url, GET_FAMILY_ALLOWANCE_MOCKUP);
    const bannersRequest = this.componentsService.getBanners(lang);
    return forkJoin([familyAllowanceRequest, bannersRequest])
      .pipe(
        map(([familyAllowanceResponse, bannersResponse]) => {
          return this.cmsUtils.mapFamilyAllowance(familyAllowanceResponse.data, bannersResponse, lang);
        }),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadCurrentCommissionsValues(): Observable<CurrentCommissionsContent> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.CURRENT_COMMISSIONS_PATH}?fields=${cmsConstants.CURRENT_COMMISSIONS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const currentComissionsRequest = this.http.get(url, GET_CURRENT_COMMISSIONS_MOCKUP);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([currentComissionsRequest, headersRequest])
      .pipe(
        map(([currentComissionsResponse, headersRequest]) => this.cmsUtils.mapCurrentCommissionsValues(currentComissionsResponse.data, headersRequest)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFaqs(faqsType: 'afiliado' | 'pensionado' | 'empleador'): Observable<FaqsContent> {
    let getFaqsRequest: Observable<FaqsItem>;
    const lang = this.cmsUtils.getLanguage();
    if (faqsType === 'afiliado') {
      getFaqsRequest = this.componentsService.getAffiliateFaqs();
    } else if (faqsType === 'pensionado') {
      getFaqsRequest = this.componentsService.getPensionerFaqs();
    } else {
      getFaqsRequest = this.componentsService.getEmployerFaqs();
    }
    const getFaqsSectionsRequest = this.componentsService.getFaqsSections(lang);
    return forkJoin([getFaqsRequest, getFaqsSectionsRequest])
      .pipe(
        map(([getFaqsResponse, getFaqsSectionsResponse]) => {
          return this.cmsUtils.mapFaqs(getFaqsResponse, getFaqsSectionsResponse, lang);
        }),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadCorporateGovernanceFaqs(): Observable<Array<Section>> {
    const lang = this.cmsUtils.getLanguage();
    const getCorporateGovernanceRequest = this.componentsService.getCorporateGovernanceFaqs();
    const getFaqsSectionsRequest = this.componentsService.getFaqsSections(lang);
    return forkJoin([getCorporateGovernanceRequest, getFaqsSectionsRequest])
      .pipe(
        map(([getFaqsResponse, getFaqsSectionsResponse]) => {
          return this.cmsUtils.mapFaqs(getFaqsResponse, getFaqsSectionsResponse, lang).sections[0].faqs;
        }),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadFinancialStatementsValues(): Observable<DownloadContentItems> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.FINANCIAL_STATEMENTS}?fields=${cmsConstants.FINANCIAL_STATEMENTS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const financialStatementsRequest = this.http.get(url, GET_FINANCIAL_STATEMENTS_LINKS);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([financialStatementsRequest, headersRequest])
      .pipe(
        map(([financialStatementResponse, headersResponse]) => 
          this.cmsUtils.mapFinancialStatements(financialStatementResponse.data, headersResponse, lang
        )),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadEssentialFactsValues(): Observable<DownloadContentItems> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.ESSENTIAL_FACTS}?fields=${cmsConstants.ESSENTIAL_FACTS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const essentialFactsRequest = this.http.get(url, GET_ESSENTIAL_FACTS_LINKS);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([essentialFactsRequest, headersRequest])
      .pipe(
        map(([essentialFactsResponse, headersResponse]) =>
          this.cmsUtils.mapEssentialFacts(essentialFactsResponse.data, headersResponse, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadInformationOfInterestValues(): Observable<DownloadContentItems> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.INFORMATION_OF_INTEREST}?fields=${cmsConstants.INFORMATION_OF_INTEREST_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const informationOfInteresRequest = this.http.get(url, GET_INFORMATION_OF_INTEREST_LINKS);
    const headersRequest = this.componentsService.getHeaders(lang);
    return forkJoin([informationOfInteresRequest, headersRequest])
      .pipe(
        map(([informationOfInterestResponse, headersResponse]) =>
          this.cmsUtils.mapInformationOfInterest(informationOfInterestResponse.data, headersResponse, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadCorporateGovernanceDownloadSection(): Observable<CorporateGovernanceDownloadSections> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.CORPORATE_GOVERNANCE}?fields=${cmsConstants.CORPORATE_GOVERNANCE_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_CORPORATE_GOVERNANCE_LINKS)
      .pipe(
        map(response => this.cmsUtils.mapCorporateGovernance(response.data, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadCorporateGovernanceValues(): Observable<[CorporateGovernanceDownloadSections, Section[], Banner]> {
    const downloadSectionRequest = this.loadCorporateGovernanceDownloadSection();
    const faqsRequest = this.loadCorporateGovernanceFaqs();
    const headerRequest = this.getHeaderImages(FOOTER_CORPORATE_GOVERNANCE_HEADER_ID);
    return forkJoin([downloadSectionRequest, faqsRequest, headerRequest]);
  }

  public loadAnnualMemoryValues(): Observable<UrlSectionContent> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.ANNUAL_MEMORY}?fields=${cmsConstants.ANNUAL_MEMORY_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const annualMemoryRequest = this.http.get(url, GET_ANNUAL_MEMORY_LINKS);
    const headerRequest = this.getHeaderImages(FOOTER_ANNUAL_MEMORY_HEADER_ID);
    return forkJoin([annualMemoryRequest, headerRequest])
      .pipe(
        map(([annualMemoryResponse, headerResponse]) =>
          this.cmsUtils.mapAnnualMemoryValues(annualMemoryResponse.data, headerResponse, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadAfpPoliciesValues(): Observable<DownloadSectionContent> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.AFP_POLICIES}?fields=${cmsConstants.AFP_POLICIES_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const policiesRequest = this.http.get(url, GET_AFP_POLICIES_LINKS);
    const headerRequest = this.getHeaderImages(FOOTER_POLICIES_HEADER_ID);
    return forkJoin([policiesRequest, headerRequest])
      .pipe(
        map(([policiesResponse, headerResponse]) => this.cmsUtils.mapAfpPoliciesValues(policiesResponse.data, headerResponse, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadAdministrationWorkers(): Observable<AdministrationWorkers> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.ADMINISTRATION_PORTRAITS}?fields=${cmsConstants.AFP_POLICIES_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    const administrationWorkers = this.http.get(url, GET_ADMINISTRATION_WORKERS);
    const headers = this.componentsService.getHeaders(lang);
    return forkJoin([administrationWorkers, headers])
      .pipe(
        map(([administrationWorkersResponse, headersResponse] : [any, Array<Banner>]) =>
          this.cmsUtils.mapAdministrationWorkers(administrationWorkersResponse.data, headersResponse, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadHealthcareInstitutions(): Observable<Array<string>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.HEALTHCARE_INSTITUTIONS}?fields=${cmsConstants.HEALTHCARE_INSTITUTIONS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_HEALTHCARE_INSTITUTIONS_MOCK)
      .pipe(
        map(response => response.data.instituciones_salud.contenido),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadPaymentInstitutions(): Observable<Array<string>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.PAYMENT_INSTITUTIONS}?fields=${cmsConstants.PAYMENT_INSTITUTIONS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_PAYMENT_INSTITUTIONS_MOCK)
      .pipe(
        map(response => response.data.instituciones.contenido),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public loadMortuaryQuotaRelationships(): Observable<Array<DefaultResponseItem>> {
    const lang = this.cmsUtils.getLanguage();
    // tslint:disable-next-line:max-line-length
    const url = `${environment.cmsApiUrl}items/${cmsConstants.RELATIONSHIPS}?fields=${cmsConstants.MORTUARY_QUOTA_RELATIONSHIPS_FIELDS}&filter=${JSON.stringify(cmsConstants.CMS_REQUESTS.filter.published)}`;
    return this.http.get(url, GET_MORTUARY_QUOTA_RELATIONSHIPS_MOCK)
      .pipe(
        map(response => this.cmsUtils.mapMortuaryQuotaRelationships(response.data, lang)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getGeneralParams(): Observable<GeneralParams> {
    const url = `${environment.cmsApiUrl}/items/${cmsConstants.GENERAL_PARAMETERS}`;
    const data = {
      fields: cmsConstants.GENERAL_PARAMETERS_FIELDS,
      status: 'published',
    };

    return this.http.get(url, GET_GENERAL_PARAMETERS_MOCK, data)
      .pipe(
        map(response => response.data),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getShowImages(): Observable<ShowImages> {
    const url = `${environment.cmsApiUrl}items/${cmsConstants.SHOW_IMAGES_PATH}`;
    const data = {
      fields: cmsConstants.SHOW_IMAGES_FIELDS,
      status: 'published',
    };

    return this.http.get(url, GET_SHOW_IMAGES_MOCK, data)
      .pipe(
        map(response => response),
        catchError(error => this.errorUtils.generalCatchError(error))
      );
  }

  public getHeaderImages(headerId: number): Observable<Banner> {
    const lang = this.cmsUtils.getLanguage();
    const getHeaders = this.componentsService.getHeaders(lang);
    return forkJoin([getHeaders])
      .pipe(
        map(response => this.cmsUtils.mapHeaderImages(response[0], headerId)),
        catchError(error => this.errorUtils.generalCatchError(error))
      );    
  }

}
