<div>
  <div class="form-container">
    <form class="form" [formGroup]="form" autocomplete="off">

      <div class="options-container">
        <mat-radio-group name="options" formControlName="typeSearchOption">
          <p class="option-label" [ngClass]="font?.size">{{ pageContent?.form?.search | translate }}</p>
          <mat-radio-button class="option" [ngClass]="font?.size" *ngFor="let option of typeSearchOptions" name="options" [value]="option.id">
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>

        <div class="options-container search-by">
          <mat-form-field [ngClass]="font?.size" floatLabel="always">
            <mat-label class="option-label" [ngClass]="font?.size">{{ pageContent?.form?.rut | translate }}</mat-label>
            <input matInput type="text" formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut" rutInput required>
            <span matSuffix class="i-validator"></span>
            <mat-error *ngIf="rut?.hasError('required')" [ngClass]="font?.size">{{ pageContent?.errors?.required | translate }}</mat-error>
            <mat-error *ngIf="!rut?.hasError('required') && rut?.hasError('validRut')" [ngClass]="font?.size">{{ pageContent?.errors?.invalidRut | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="phoneOptionSelected" class="number-clean" [ngClass]="font?.size" floatLabel="always">
              <mat-label class="option-label" [ngClass]="font?.size">{{ pageContent?.form?.options?.phone | translate }}</mat-label>
              <input matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax" formControlName="phone" placeholder="987654321" 
                [max]="phoneNumberMax" [maxLength]="phoneNumberLength" [minLength]="phoneNumberLength" required digitsOnly>
              <span matSuffix class="i-validator"></span>
              <mat-error *ngIf="phone?.hasError('required')" [ngClass]="font?.size">{{ pageContent?.errors?.required | translate }}</mat-error>
              <mat-error *ngIf="phone?.hasError('min') || phone?.hasError('max')" [ngClass]="font?.size"> {{ pageContent?.errors?.invalidCellPhone | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="emailOptionSelected" [ngClass]="font?.size" floatLabel="always">
            <mat-label class="option-label" [ngClass]="font?.size">{{ pageContent?.form?.options?.email | translate }}</mat-label>
            <input matInput type="email" formControlName="email" required>
            <span matSuffix class="i-validator"></span>
            <mat-error *ngIf="email?.hasError('required')" [ngClass]="font?.size">{{ pageContent?.errors?.required | translate }}</mat-error>
            <mat-error *ngIf="email?.hasError('email') || email?.hasError('pattern')" [ngClass]="font?.size">{{ pageContent?.errors?.invalidEmail | translate }}</mat-error>
          </mat-form-field>
          
          <div class="button-container">
            <button class="submit-button" [ngClass]="font?.size" type="submit" (click)="getParamSearch()">
              {{ pageContent?.buttons?.search | translate }}
            </button>
          </div>
       </div>
      </div>
    </form>
  </div>
</div>
