<div class="chart-container" [ngClass]="chartClass" #chartContainer>
  <ion-button *ngIf="isModal" class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="title" [ngClass]="font?.size" [innerHTML]="chartParams?.title | translate"></div>
  <ngx-charts-line-chart class="chart"
    [scheme]="colorScheme"
    [xAxis]="true"
    [yAxis]="true"
    [yAxisTickFormatting]="yAxisTickFormattingFn"
    [xAxisTickFormatting]="xAxisTickFormattingFn"
    [results]="multi"
    [animations]="false"
    [autoScale]="true"
    [view]="view"
    [curve]="curve"
    [tooltipDisabled]="!showTooltip"
    [roundDomains]="true"
  >
    <ng-template #tooltipTemplate let-model="model">
      <div class="area-tooltip-container">
        <div>{{ yAxisFormat(model.value) }}</div>
      </div>
    </ng-template>
    <ng-template #seriesTooltipTemplate let-model="model">
      <div class="area-tooltip-container">
        <div *ngFor="let tooltipItem of model" class="tooltip-item">
          {{ yAxisFormat(tooltipItem.value) }}
        </div>
      </div>
    </ng-template>
  </ngx-charts-line-chart>
  <div class="funds-chips">
    <app-filter-chips *ngIf="!isPopover" class="chips" [elements]="fundsChips" [selectedElementId]="selectedFundId" chipType="concentrated" highlightSelected="true" (selectedId)="changeFund($event)"></app-filter-chips>
  </div>
</div>
