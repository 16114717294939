import { Mockup } from '@interfaces/mockup.interface';
import { WhyTransfer } from '@interfaces/why-transfer.interface';
import { environment } from '@environment';

// tslint:disable:max-line-length
export const GET_WHY_TRANSFER_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        link_traspaso: {
          url: 'url',
          identificador: 'Mapa del sitio'
        },
        icono_header: 'handshake',
        imagen_header: {
          filename_disk: `45312432-3ff7-4555-b1ee-a814a44e4cd6.png`,
        },
        imagen_header_mobile: {
          filename_disk: `45312432-3ff7-4555-b1ee-a814a44e4cd6.png`,
        },
        link_seccion_articulos: {
          url: 'url',
          identificador: 'Mapa del sitio'
        },
        articulos: [
          {
            articulos_id: 1
          },
          {
            articulos_id: 2
          },
          {
            articulos_id: 3
          }
        ],
        carrusel: [
          {
            infografias_id: 1
          },
          {
            infografias_id: 2
          },
          {
            infografias_id: 3
          }
        ],
        traducciones: [
          {
            id: 1,
            traspaso: '<h1>Ahora asegura el &eacute;xito de <strong>tu traspaso</strong></h1>\n<p>A continuaci&oacute;n te indicamos la informaci&oacute;n que tienes que tener presente al suscribirte a AFP PlanVital.</p>\n<p>Una vez ingresado tu <strong>Rut</strong> y<strong> Clave de Seguridad</strong>, en l&iacute;nea tu actual AFP te informar&aacute; si presentas alguna situaci&oacute;n particular que no permita terminar con el proceso de cambio.</p>\n<p>Algunas consideraciones antes de hacer tu traspaso</p>\n<ul>\n<li>Si realizaste un cambio de Fondos en tu AFP actual hace <strong>menos de</strong><br /><strong>7 d&iacute;as h&aacute;biles.</strong></li>\n<li>Recuerda que los<strong> cambios de Fondos tienen un plazo de materializaci&oacute;n</strong> de 4 a 7 d&iacute;as h&aacute;biles.</li>\n<li>Solicitud Pensi&oacute;n <strong>en tr&aacute;mite</strong>.</li>\n<li>Si tienes un tr&aacute;mite de pensi&oacute;n en proceso,<strong> una vez que recibas el primer pago</strong>, puedes solicitar el traspaso de AFP.</li>\n</ul>',
            promover_aplicacion_movil: '<h1>Ahorra tiempo y haz tus tr&aacute;mites de manera f&aacute;cil y r&aacute;pida</h1>\n<p>En ella podr&aacutes:</p>\n<ul>\n<li>Descargar tus certificados</li>\n<li>Revisar el saldo de tus cuentas</li>\n<li>Abrir productos voluntarios</li>\n<li>Cambiarte o distribuir de fondo tus ahorros</li>\n</ul>',
            titulo_tiendas_aplicacion_movil: '<h1>Descarga nuestra APP en las siguientes plataformas</h1>',
            boton_traspaso: 'Ir al traspaso online',
            cambiate: 1,
            ir_a_traspaso: '<p><strong>Ya est&aacute;s listo!</strong> ahora puedes continuar con tu traspaso a AFP PlanVital.</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>',
            titulo_articulos: '<p>Descubre m&aacute;s razones para<strong> cambiarte a AFP PlanVital</strong></p>',
            disclaimer_articulos: '<p>Si necesitas m&aacute;s informaci&oacute;n, <strong>cont&aacute;ctanos en nuestros medios de atenci&oacute;n.</strong></p>\n<p>&nbsp;</p>',
            boton_articulos: 'Ir al centro de atención',
            header: '<p>&iquest;Por qu&eacute; cambiarme a <strong>AFP PlanVital</strong>?</p>',
            texto_traspaso_header: '<p>Si ya lo tienes claro, comienza tu traspaso online, <strong>m&aacute;s r&aacute;pido y simple!</strong></p>\n<p>&nbsp;</p>',
            titulo_carrusel: '<p>Inf&oacute;rmate y decide, <strong>s&eacute; parte de AFP PlanVital</strong></p>\n<p>&nbsp;</p>',
            languages_code: 'es',
            alt_imagen_header: 'alt',
            alt_imagen_traspaso: 'alt',
            title_image: 'title',
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const WHY_TRANSFER_MOCKUP: WhyTransfer = {
  id: 1,
  header: {
    image: {
      url: `${environment.cmsApiUrl}assets/45312432-3ff7-4555-b1ee-a814a44e4cd6.png`,
      alt: 'alt',
      title: 'title',
    },
    image_mobile: {
      url: `${environment.cmsApiUrl}assets/45312432-3ff7-4555-b1ee-a814a44e4cd6.png`,
      alt: 'alt',
      title: 'title',
    },
    icon: 'handshake',
    firstLine: '<p>&iquest;Por qu&eacute; cambiarme a <strong>AFP PlanVital</strong>?</p>',
  },
  mainAction: {
    disclaimer: '<p>Si ya lo tienes claro, comienza tu traspaso online, <strong>m&aacute;s r&aacute;pido y simple!</strong></p>\n<p>&nbsp;</p>',
    disclaimerButton: 'Ir al traspaso online',
    disclaimerUrl: 'url'
  },
  carouselTitle: '<p>Inf&oacute;rmate y decide, <strong>s&eacute; parte de AFP PlanVital</strong></p>\n<p>&nbsp;</p>',
  carousel: [
    {
      id: 1,
      icon: 'lupa',
      title: '<p>La mejor <strong>comisi&oacute;n anual</strong></p>',
      description: '<p><strong>Sobre saldo deAPV&nbsp;</strong>en porcentaje Marzo 2020</p>',
      showMoreButton: 'Más información',
      modal: {
        title: '',
        description: '',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        disclaimer: '',
        disclaimerButton: '',
        disclaimerUrl: 'url',
      }
    },
    {
      id: 2,
      icon: 'atencion',
      title: '<p>Menos&nbsp;<strong>tiempo de espera</strong></p>',
      description: '<p>Somos de las mejores AFP en tiempos de respuesta por nuestros medios de contacto.</p>',
      showMoreButton: 'Más información',
      modal: {
        title: '',
        description: '',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        disclaimer: '',
        disclaimerButton: '',
        disclaimerUrl: 'url',
      }
    },
    {
      id: 3,
      icon: 'cercano',
      title: '<p><strong>Atenci&oacute;n cercana</strong> en todo Chile</p>',
      description: '<p>Solucionamos todos tus tr&aacute;mites previsionales, de forma r&aacute;pida y segura en nuestras sucursales.</p>',
      showMoreButton: 'Más información',
      modal: {
        title: '',
        description: '',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        disclaimer: '',
        disclaimerButton: '',
        disclaimerUrl: 'url',
      }
    }
  ],
  instructions: {
    body: '<h1>Ahora asegura el &eacute;xito de <strong>tu traspaso</strong></h1>\n<p>A continuaci&oacute;n te indicamos la informaci&oacute;n que tienes que tener presente al suscribirte a AFP PlanVital.</p>\n<p>Una vez ingresado tu <strong>Rut</strong> y<strong> Clave de Seguridad</strong>, en l&iacute;nea tu actual AFP te informar&aacute; si presentas alguna situaci&oacute;n particular que no permita terminar con el proceso de cambio.</p>\n<p>Algunas consideraciones antes de hacer tu traspaso</p>\n<ul>\n<li>Si realizaste un cambio de Fondos en tu AFP actual hace <strong>menos de</strong><br /><strong>7 d&iacute;as h&aacute;biles.</strong></li>\n<li>Recuerda que los<strong> cambios de Fondos tienen un plazo de materializaci&oacute;n</strong> de 4 a 7 d&iacute;as h&aacute;biles.</li>\n<li>Solicitud Pensi&oacute;n <strong>en tr&aacute;mite</strong>.</li>\n<li>Si tienes un tr&aacute;mite de pensi&oacute;n en proceso,<strong> una vez que recibas el primer pago</strong>, puedes solicitar el traspaso de AFP.</li>\n</ul>',
    disclaimer: '<p><strong>Ya est&aacute;s listo!</strong> ahora puedes continuar con tu traspaso a AFP PlanVital.</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>',
    disclaimerButton: 'Ir al traspaso online',
    disclaimerUrl: 'url'
  },
  promoteApp: {
    body: '<h1>Ahorra tiempo y haz tus tr&aacute;mites de manera f&aacute;cil y r&aacute;pida</h1>\n<p>En ella podr&aacutes:</p>\n<ul>\n<li>Descargar tus certificados</li>\n<li>Revisar el saldo de tus cuentas</li>\n<li>Abrir productos voluntarios</li>\n<li>Cambiarte o distribuir de fondo tus ahorros</li>\n</ul>',
    titleStores: '<h1>Descarga nuestra APP en las siguientes plataformas</h1>',
  },
  articlesSection: {
    title: '<p>Descubre m&aacute;s razones para<strong> cambiarte a AFP PlanVital</strong></p>',
    articles: [
      {
        id: 1,
        identifier: 'APV',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        title: 'Ahorro Previsional Voluntario APV',
        url: 'afiliado/ahorros/previsional-voluntario',
      },
      {
        id: 2,
        identifier: 'APS',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        title: 'Beneficios Aporte Solidario APS',
        url: 'pensionado/beneficios/pension-solidaria',
      },
      {
        id: 3,
        identifier: 'Cuenta 2',
        image: {
          url: `${environment.cmsApiUrl}assets/e7c8bfb0-defb-4d2e-82cc-d9067ee56cdc.jpg`,
          alt: 'alt',
          title: '',
        },
        title: 'Ahorra con tu Cuenta 2',
        url: 'afiliado/ahorros/cuenta-2',
      }
    ],
    disclaimer: '<p>Si necesitas m&aacute;s informaci&oacute;n, <strong>cont&aacute;ctanos en nuestros medios de atenci&oacute;n.</strong></p>\n<p>&nbsp;</p>',
    disclaimerButton: 'Ir al centro de atención',
    disclaimerUrl: 'url'
  }
};

export const GET_WHY_TRANSFER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        id: 1,
        link_traspaso: {
          url: 'url',
          identificador: 'Mapa del sitio'
        },
        icono_header: 'handshake',
        imagen_header: null,
        imagen_header_mobile: null,
        link_seccion_articulos: {
          url: 'url',
          identificador: 'Mapa del sitio'
        },
        articulos: [],
        carrusel: [],
        traducciones: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const WHY_TRANSFER_NULL_MOCKUP: WhyTransfer = {
  id: 1,
  header: {
    image: {
      url: null,
      alt: null,
      title: null,
    },
    image_mobile: {
      url: null,
      alt: null,
      title: null,
    },
    icon: 'handshake',
    firstLine: null,
  },
  mainAction: {
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: 'url'
  },
  carouselTitle: null,
  carousel: [],
  promoteApp: {
    body: null,
    titleStores: null
  },
  instructions: {
    body: null,
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: 'url'
  },
  articlesSection: {
    title: null,
    articles: [],
    disclaimer: null,
    disclaimerButton: null,
    disclaimerUrl: 'url'
  }
};
