
<div class="partial-content">
  <ion-button class="btn small secondary label medium save" [disabled]="!isValidForm" (click)="sendForm(true)">
    {{ pageContent?.buttons.saveContinueLater | translate }}
    <div class="icon-result save"></div>
  </ion-button>
</div>
<form class="form-container" [formGroup]="form" *ngIf="form">
  <div class="form-section">
    <h3 class="title secondary spacing" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h3>
    <div class="fields-group">
      <mat-radio-group class="option-container" formControlName="reason">
        <h4 class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.form.question | translate }}</h4>
        <div class="options" *ngFor="let reason of reasonOptions">
          <mat-radio-button [value]="reason[0]" class="option" [ngClass]="font?.size"> 
            <p class="label">{{ reason[1] | translate }}</p>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div> 
  
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [ngClass]="font?.size" (click)="sendForm()" [disabled]="!isValidForm">
      {{ pageContent?.buttons?.next | translate }}
      <div class="icon-result next"></div>
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      <div class="icon-result back"></div>
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>
