export class Error {
  public code: number;
  public title: string;
  public description: string;

  constructor(error: any) {
    if (!error.error) { return; }

    error = error.error;
    const { code, message, messageDescription } = error;

    this.code = code;
    this.title = message;
    this.description = messageDescription;
  }
}
