import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_AFP_POLICIES_LINKS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              politicas_afp: [
                {
                  titulo: 'Políticas de Inversión',
                  items: [
                    {
                      titulo: 'Políticas de Inversión y de Solución de Conflictos de Interés.',
                      url: 'Politicas-AFP/Politicas-Inversion/Politica-de-inversion-y-de-solucion-de-conflictos-de-interes.pdf'
                    },
                    {
                      titulo: 'Límites de Riesgo Financiero aprobado por el Directorio de AFP PlanVital.',
                      url: 'Politicas-AFP/Politicas-Inversion/Limites-de-Riesgo-Financiero-Definidos-por-Directorio.pdf'
                    }
                  ]
                },
                {
                  titulo: 'Políticas del Sitio Web',
                  items: [
                    {
                      titulo: 'Política de Seguridad | Privacidad y Capacidad del Sitio Web.',
                      url: 'Politicas-AFP/Politicas-Sitio-Web/Pol%C2%B0tica%20de%20Seguridad%20Privacidad%20y%20Capacidad%20del%20Sitio%20Web.pdf'
                    }
                  ]
                }
              ]
            }
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
