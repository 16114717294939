import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NAMES_MAX_LENGTH, RUT_MAX_LENGTH, VALIDATORS } from '@constants/forms.constant';
import {
  GENERAL_BACKGROUND_FIELDS_CONTENT
} from '@constants/person-backgrounds.constant';
import { PersonBackgroundFieldsOptions } from '@interfaces/person-backgrounds.interface';
import { DeceasedAffiliateBackgrounds } from '@interfaces/survivor-pension-form.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-deceased-affiliate-background',
  templateUrl: './deceased-affiliate-background.component.html',
  styleUrls: ['./deceased-affiliate-background.component.scss'],
})
export class DeceasedAffiliateBackgroundComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: DeceasedAffiliateBackgrounds;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  public namesMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public maxDate = new Date();
  public form: UntypedFormGroup;
  public formContent = GENERAL_BACKGROUND_FIELDS_CONTENT;
  public validStep: boolean;
  public resumeAffiliateControls: Array<PersonBackgroundFieldsOptions>;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private utils: Utils,
  ) { }

  public ngOnInit(): void {
    this.createForm();
  }

  public sendForm(): void {
    if (this.form.invalid) { return; }

    const {
      name, lastName, secondSurname, rut, maritalStatusId, gender, deceasedDate
    } = this.form.value;

    const deceasedAffiliateBackgrounds = {
      name,
      lastName,
      secondSurname,
      rut: this.utils.rutClean(rut),
      maritalStatusId,
      gender,
      deceasedDate,
    } as DeceasedAffiliateBackgrounds;
    this.formData.emit(deceasedAffiliateBackgrounds);
  }

  public validateForm(form: UntypedFormGroup): void {
    const { names, lastName, secondSurname, rut, maritalStatus, gender, deceasedDate } = form.value;
    this.form.patchValue({
      name: names,
      lastName,
      secondSurname,
      rut,
      maritalStatusId: maritalStatus,
      gender,
      deceasedDate,
    });
  }

  private createForm(): void {
    const group = {
      name: ['', VALIDATORS.NAME],
      lastName: ['', VALIDATORS.NAME],
      secondSurname: ['', VALIDATORS.OPTIONAL_NAME],
      rut: ['', VALIDATORS.RUT],
      maritalStatusId: ['', Validators.required],
      gender: ['', Validators.required],
      deceasedDate: ['', Validators.required]
    };
    this.form = this.formBuilder.group(group);
    this.setFormValues();
    this.validStep = true;
  }

  private setFormValues(): void {
    this.form.patchValue({
      ...this.formValues
    });
    this.resumeAffiliateControls = [
      { key: 'names', ...this.formValues ? { value: this.formValues.name } : {} },
      { key: 'lastName', ...this.formValues ? { value: this.formValues.lastName } : {} },
      { key: 'secondSurname', ...this.formValues ? { value: this.formValues.secondSurname } : {} },
      { key: 'rut', ...this.formValues ? { value: this.formValues.rut } : {} },
      { key: 'maritalStatus', ...this.formValues ? { value: this.formValues.maritalStatusId } : {} },
      { key: 'gender', ...this.formValues ? { value: this.formValues.gender } : {} },
      { key: 'deceasedDate', ...this.formValues ? { value: this.formValues.deceasedDate } : {} },
    ];
  }
}
