import { Mockup } from '@interfaces/mockup.interface';

export const GET_CURRENT_COMMISSIONS_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        comision_afp_dependiente: '1,16%',
        comision_afp_independientes: '1,16%',
        seguro_invalidez_independientes: '1,99%',
        total_independiente: '2,69%',
        comision_afp_pensionados: '1,16%',
        traspaso_entre_fondos: '0%',
        retiros_programados_renta_temp: '0%',
        admin_cav: '0,16%',
        aportes_indemnizacion: '0%',
        admin_apv_afiliado: '0,16%',
        admin_apv_no_afiliado: '0,64%',
        transf_apv: '$1.437',
        transf_apv_colectivo: 'No aplica',
        transf_cav: '$1.100'
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};
