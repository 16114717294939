import { Mockup } from '@interfaces/mockup.interface';

export const RELATIONSHIPS_MOCKUP: Mockup = {
  success: [{
    response: [
      {
        id: '1',
        description: 'CONYUGE'
      },
      {
        id: '2',
        description: 'CONYUGE CON HIJOS CON DERECHO A PENSION'
      },
      {
        id: '3',
        description: 'MADRE DE HIJOS DE AFILIACION NO MATRIMON'
      },
      {
        id: '4',
        description: 'MADRE C/HIJOS AFILIACION NO MATR. PENSIO'
      },
      {
        id: '5',
        description: 'PADRE'
      },
      {
        id: '6',
        description: 'PADRE INVALIDO'
      },
      {
        id: '7',
        description: 'MADRE'
      },
      {
        id: '8',
        description: 'MADRE VIUDA'
      },
      {
        id: '9',
        description: 'HIJO'
      },
      {
        id: '10',
        description: 'HIJO INVALIDO'
      },
      {
        id: '11',
        description: 'CONYUGE INVALIDO S/HIJOS CON DER. PEN.'
      },
      {
        id: '12',
        description: 'CONYUGE INVALIDO C/HIJOS CON DER. PEN.'
      },
      {
        id: '13',
        description: 'CONVIVIENTE CIVIL'
      }
    ]
  }],
  failures: [{
    response: {
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
      code: 50001,
    },
  }],
};
