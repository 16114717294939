import { Component } from '@angular/core';
import { RestrictionsTable } from '@interfaces/multi-funds.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-multi-funds-restrictions',
  templateUrl: './multi-funds-restrictions.component.html',
  styleUrls: ['./multi-funds-restrictions.component.scss'],
})
export class MultiFundsRestrictionComponent {
  public data: RestrictionsTable;
  constructor(
    public font: FontService,
  ) {}
}
