import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ModalController } from '@ionic/angular';

import { ModalAlertComponent } from '@components/modal-alert/modal-alert.component';
import { Section } from '@interfaces/general.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modal;

  constructor(
    public dialog: MatDialog,
    private modalCtrl: ModalController,
  ) { }

  public async openModal(component, componentProps?, cssClass = 'modal-default', callback?: (value?) => void) {
    if (this.modal) { return; }
    this.modal = await this.modalCtrl.create({ component, componentProps, cssClass });
    this.modal.onDidDismiss()
      .then((response) => {
        this.modal = null;
        if (!response.data && !callback) { return; }
        callback(response.data);
      })
      .catch(() => this.modal = null);
    return await this.modal.present();
  }

  public openModalCustom(component, modalData: any): void {
    this.openDialogCustom(component, modalData);
  }

  public openModalCustomWithClass(component, modalData: any, panelClass?: string): void {
    this.openDialogCustomWithClass(component, modalData, panelClass);
  }

  public closeModals(): void {
    this.dialog.closeAll();
    this.modal = null;
  }

  public openAlert(params: Section) {
    this.openModal(ModalAlertComponent, { ...params, informative: true });
  }

  private openDialogCustom(component, data: any): void {
    if (Boolean(this.modal)) { this.closeModals(); }

    this.modal = this.dialog.open(component, { data, autoFocus: false, disableClose: true, panelClass: 'dialog-custom' });
    this.modal.updatePosition({ right: `0` });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }

  private openDialogCustomWithClass(component, data: any, panelClass?: string): void {
    if (Boolean(this.modal)) { this.closeModals(); }

    this.modal = this.dialog.open(component, { data, panelClass, autoFocus: false, disableClose: true });
    this.modal.afterClosed().subscribe(() => this.modal = undefined);
  }
}
