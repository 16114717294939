import { Component, Input } from '@angular/core';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() public title: string;
  @Input() public hidden = true;

  constructor(
    public font: FontService
  ) { }

  public hide(): void {
    this.hidden = !this.hidden;
  }
}
