import { Injectable } from '@angular/core';

type FontType = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

@Injectable({
  providedIn: 'root'
})
export class FontService {
  private currentIndex = 2;
  private sizes: Array<FontType> = ['xsmall', 'small', 'medium', 'large', 'xlarge'];
  public get size() { return this.sizes[this.currentIndex]; }

  constructor() { }

  public setSize(changeType: 'bigger' | 'smaller'): void {
    const nextIndex = changeType === 'bigger' ? this.currentIndex + 1 : this.currentIndex - 1;
    this.currentIndex = nextIndex >= this.sizes.length || nextIndex < 0 ? this.currentIndex : nextIndex;
  }
}
