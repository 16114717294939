import { Validators } from '@angular/forms';
import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { Item } from '@interfaces/general.interface';
import { ValidateRut } from '@validators/rut.validator';

import {
  ADDITIONAL_PENSION_APPLICATION_TYPE,
  ANTICIPATED_PENSION_REDIRECT_TYPE, CAI_WITHDRAWAL_REDIRECT_TYPE,
  CALCULATION_AND_BOND_RECOGNITION_REDIRECT_TYPE,
  DEPOSIT_IPS_TYPE,
  DISABILITY_PENSION_REDIRECT_TYPE,
  DISABILITY_REEVALUATION_REDIRECT_TYPE, ELDERLY_PENSION_REDIRECT_TYPE,
  FOREIGN_WITHDRAWAL_REDIRECT_TYPE,
  LIFE_ANNUITY_APPLICATION_REDIRECT_TYPE,
  LIFE_ANNUITY_DECISION_REDIRECT_TYPE,
  OFFER_REQUEST_REDIRECT_TYPE,
  OVERPAYMENT_REFUND_REDIRECT_TYPE,
  REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_REDIRECT_TYPE,
  REQUEST_SURPLUS_FUNDS_SIMULATOR_REDIRECT_TYPE,
  SELECT_MODALITY_BOND_RECOGNITION_REDIRECT_TYPE,
  SURPLUS_CALCULATION_REDIRECT_TYPE, SURPLUS_PAYMENT_REDIRECT_TYPE, TRANSFER_ACCOUNTS_REDIRECT_TYPE,
  TRANSFER_CAV_REDIRECT_TYPE
} from './redirect.constant';
import {
  ADDRESS_PATTERN, ALPHABETIC_PATTERN, EMAIL_PATTERN, PHONE_PATTERN
} from './regex.constant';
import { affiliatePaths, pensionerPaths } from './routes.constant';

export const ACCOUNT_NUMBER_MAX_LENGTH = 14;
export const NAMES_MAX_LENGTH = 30;
export const EMPLOYER_NAME_MAX_LENGTH = 120;
export const RUT_MAX_LENGTH = 12;
export const SERIAL_NUMBER_MAX_LENGTH = 10;
export const PHONE_NUMBER_LENGTH = 9;
export const PHONE_NUMBER_MIN = 100000000;
export const PHONE_NUMBER_MAX = 999999999;

/************** BANK CODES PAYMENT METHOD STEP FORM **************/
export const PREFIX_VIRTUAL_ACCOUNT_BCI = '7770';
export const PREFIX_VIRTUAL_ACCOUNT_SANTANDER = '710';
export const PREFIX_VIRTUAL_ACCOUNT_RIPLEY = '404';

export const BCI_CODE = '16';
export const RIPLEY_CODE = '53';
export const SANTANDER_CODE = '37';

export const VIRTUAL_ACCOUNTS_BANKS = {
  [BCI_CODE]: PREFIX_VIRTUAL_ACCOUNT_BCI,
  [RIPLEY_CODE]: PREFIX_VIRTUAL_ACCOUNT_RIPLEY,
  [SANTANDER_CODE]: PREFIX_VIRTUAL_ACCOUNT_SANTANDER,
};

export const COMPENSATION_BOX_CODE = '729';
export const STATE_BANK_CODE = '012';
export const COOPEUCH_CODE = '672';

export const VISTA_ACCOUNT_ID = 2;
export const RUT_ACCOUNT_ID = 4;

/************** AFP ACCOUNTS STEP FORM **************/
export const MANDATORY_ACCOUNT_TYPE = 'CCO';
export const APV_ACCOUNT_TYPE = 'CCV';
export const CAV_ACCOUNT_TYPE = 'CAV';
export const VOLUNTARY_AFFILIATE_ACCOUNT_TYPE = 'CAF';
export const AGREED_DEPOSIT_ACCOUNT_TYPE = 'CDC';
export const AFP_ACCOUNTS = [
  { type: MANDATORY_ACCOUNT_TYPE, description: 'Cuenta Obligatoria' },
  {
    type: APV_ACCOUNT_TYPE, description: '<strong>Cuenta Cotización Voluntaria</strong>',
    definitions: [{ title: `FORM_DEFINITIONS.23.TITLE`, definition: `FORM_DEFINITIONS.23.TEXT` }]
  },
  {
    type: CAV_ACCOUNT_TYPE, description: '<strong>Cuenta de Afiliado Voluntario</strong>',
    definitions: [{ title: `FORM_DEFINITIONS.24.TITLE`, definition: `FORM_DEFINITIONS.24.TEXT` }]
  },
  { type: VOLUNTARY_AFFILIATE_ACCOUNT_TYPE, description: 'Cuenta 2' },
  {
    type: AGREED_DEPOSIT_ACCOUNT_TYPE, description: '<strong>Depósitos Convenidos</strong>',
    definitions: [{ title: `FORM_DEFINITIONS.22.TITLE`, definition: `FORM_DEFINITIONS.22.TEXT` }]
  },
];

/************** CAI FORM **************/
export const AUTHORITY_ACT_ID = 1;
export const SUSPENSION_PACT_ID = 2;
export const CHILD_CARE_ID = 3;
export const REVOCATION_ID = 4;

/************** BENEFICIARY FORM **************/
export const GENDERS = [
  {
    id: 'F',
    description: 'Femenino'
  },
  {
    id: 'M',
    description: 'Masculino'
  },
];


/************** VALIDATORS **************/
export const VALIDATORS = {
  NAME: [
    Validators.required,
    Validators.maxLength(NAMES_MAX_LENGTH),
    Validators.pattern(ALPHABETIC_PATTERN)
  ],
  OPTIONAL_NAME: [
    Validators.maxLength(NAMES_MAX_LENGTH),
    Validators.pattern(ALPHABETIC_PATTERN)
  ],
  EMPLOYER_NAME: [
    Validators.required,
    Validators.maxLength(EMPLOYER_NAME_MAX_LENGTH),
    Validators.pattern(ALPHABETIC_PATTERN),
  ],
  EMAIL: [
    Validators.required,
    Validators.email,
    Validators.pattern(EMAIL_PATTERN),
  ],
  PHONE: [
    Validators.required,
    Validators.minLength(PHONE_NUMBER_LENGTH),
    Validators.maxLength(PHONE_NUMBER_LENGTH),
    Validators.min(PHONE_NUMBER_MIN),
    Validators.max(PHONE_NUMBER_MAX),
    Validators.pattern(PHONE_PATTERN),
  ],
  RUT: [
    Validators.required,
    ValidateRut,
    Validators.maxLength(RUT_MAX_LENGTH),
  ],
  ADDRESS: [
    Validators.required,
    Validators.pattern(ADDRESS_PATTERN),
  ]
};

export const FILE_VALID_MIME_TYPES = ['application/pdf', 'application/vnd.ms-excel',
  'image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/bmp', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessing'];

export const FILE_EXTENSIONS = ['pdf', 'jpg', 'png', 'docx', 'doc', 'bmp'];

export const FILE_MAX_SIZE = 3145728;

export const ATTACH_VALIDATORS_CONTENT = {
  attachValidators: {
    attachFormat: `ATTACH_VALIDATORS.ATTACH_FORMAT`,
    attachExample: `ATTACH_VALIDATORS.ATTACH_EXAMPLE`,
    attachMaxSize: `ATTACH_VALIDATORS.ATTACH_MAX_SIZE`,
    attachDifferentFiles: `ATTACH_VALIDATORS.DIFFERENT_FILES`,
    optionalTitle: `ATTACH_VALIDATORS.OPTIONAL_TITLE`,
    optionalTooltip: `ATTACH_VALIDATORS.OPTIONAL_TOOLTIP`,
  }
};

export const BENEFICIARY_PROPERTIES: Array<FormArrayItemProperties> = [
  { name: 'fullName', translated: true, style: ['bold'] },
  { name: 'birthdate', translated: false },
  { name: 'email', translated: true },
  { name: 'cellphone', translated: true },
];

export const TYPE_SEARCH_OPTIONS: Array<Item> = [
  { id: '2', name: 'Celular' },
  { id: '3', name: 'Correo electrónico' },
];

export const MIN_FOLIO_LENGTH = 1;
export const MAX_FOLIO_LENGTH = 17;

const failedSubmissionKey = 'PARTIAL_FORMS_GENERIC_ERROR';

export const REQUEST_FAILED_SUBMISSION = {
  title: `${failedSubmissionKey}.TITLE`,
  description: `${failedSubmissionKey}.DESCRIPTION`,
  firstBtnText: `${failedSubmissionKey}.BUTTON`,
  iconName: 'i-error',
};

export const DISABILITY_PENSION_TYPE = 'pension-invalidez';
export const ELDERLY_PENSION_TYPE = 'pension-vejez-normal';
export const ANTICIPATED_PENSION_TYPE = 'pension-anticipada';
export const MORTUARY_FORM_TYPE = 'cuota-mortuoria';
export const INHERITANCE_FORM_TYPE = 'herencia';
export const SURVIVOR_PENSION = 'pension-sobrevivencia';
export const OFFER_REQUEST_FORM_FILE_TYPE = 'pension-solicitud-oferta';
export const WITHDRAWAL_TECHNICAL_FUNDS_FORM_FILE_TYPE = 'retiro-fondos-tecnicos-extranjeros';
export const TRANSFER_CAV_FORM_FILE_TYPE = 'transferencia-cav';
export const TRANSFER_PENSION_ACCOUNTS_FORM_FILE_TYPE = 'transferencia-cuentas-pension';
export const FREELY_DISPOSABLE_SURPLUS_CALCULATION_FORM_FILE_TYPE = 'solicitud-calculo-excedente-libre-disposicion';
export const FREELY_DISPOSABLE_SURPLUS_PAYMENT_FORM_FILE_TYPE = 'solicitud-pago-excedente-libre-disposicion';
export const DISABILITY_REEVALUATION_FORM_FILE_TYPE = 'reevaluacion-invalidez';
export const DISABILITY_REEVALUATION_FORM_FILE_TYPE_2 = 'disabilityReevaluation';
export const OVERPAYMENT_REFUND_FORM_FILE_TYPE = 'devolucion-pago-exceso';
export const FAMILY_ALLOWANCE_FORM_FILE_TYPE = 'asignacion-familiar';
export const STATE_GUARANTEE_FORM_FILE_TYPE = 'garantia-estatal';
export const STUDIES_ACCREDITATION_FORM_FILE_TYPE = 'acreditacion-estudios';
export const CHARGING_FEES_FORM_FILE_TYPE = 'cobro-honorarios';
export const CAI_WITHDRAWAL_FORM_FILE_TYPE = 'retiro-cai';
export const EMPLOYER_DISCOUNT_VOLUNTARY_MEMBER_FORM_FILE_TYPE = 'descuento-empleador-para-afiliado-voluntario';
export const ADDITIONAL_PENSION_APPLICATION_FORM_FILE_TYPE = 'pension-adicional';
export const DEPOSIT_IPS_FORM_FILE_TYPE = 'retiros-depositos-convenidos-ips';
export const REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_FORM_TYPE = 'transferencia-fondos-cuenta-cesantia';
export const REQUEST_SURPLUS_FUNDS_SIMULATOR_FORM_FILE_TYPE = 'simulador-fondos-excedente';
export const MANDATES_FORM_FILE_TYPE = 'mandatos';
export const LIFE_ANNUTY_APPLICATION_FORM_FILE_TYPE = 'solicitud-renta-vitalicia';
export const LIFE_ANNUTY_DECISION_FORM_FILE_TYPE = 'decision-renta-vitalicia';
export const CALCUATION_AND_BOND_RECOGNITION_FORM_FILE_TYPE = 'calculo-y-bono-de-reconocimiento';
export const SELECT_MODALITY_BOND_RECOGNITION_FORM_FILE_TYPE = 'seleccion-modalidad-de-bono-de-reconocimiento';



export const PRIVATE_SITE_FORMS_URL = {
  [DISABILITY_PENSION_TYPE]: DISABILITY_PENSION_REDIRECT_TYPE,
  [ELDERLY_PENSION_TYPE]: ELDERLY_PENSION_REDIRECT_TYPE,
  [ANTICIPATED_PENSION_TYPE]: ANTICIPATED_PENSION_REDIRECT_TYPE,
  [OFFER_REQUEST_FORM_FILE_TYPE]: OFFER_REQUEST_REDIRECT_TYPE,
  [WITHDRAWAL_TECHNICAL_FUNDS_FORM_FILE_TYPE]: FOREIGN_WITHDRAWAL_REDIRECT_TYPE,
  [TRANSFER_CAV_FORM_FILE_TYPE]: TRANSFER_CAV_REDIRECT_TYPE,
  [TRANSFER_PENSION_ACCOUNTS_FORM_FILE_TYPE]: TRANSFER_ACCOUNTS_REDIRECT_TYPE,
  [FREELY_DISPOSABLE_SURPLUS_CALCULATION_FORM_FILE_TYPE]: SURPLUS_CALCULATION_REDIRECT_TYPE,
  [FREELY_DISPOSABLE_SURPLUS_PAYMENT_FORM_FILE_TYPE]: SURPLUS_PAYMENT_REDIRECT_TYPE,
  [DISABILITY_REEVALUATION_FORM_FILE_TYPE]: DISABILITY_REEVALUATION_REDIRECT_TYPE,
  [OVERPAYMENT_REFUND_FORM_FILE_TYPE]: OVERPAYMENT_REFUND_REDIRECT_TYPE,
  [CAI_WITHDRAWAL_FORM_FILE_TYPE]: CAI_WITHDRAWAL_REDIRECT_TYPE,
  [REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_FORM_TYPE]: REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_REDIRECT_TYPE,
  [REQUEST_SURPLUS_FUNDS_SIMULATOR_FORM_FILE_TYPE]: REQUEST_SURPLUS_FUNDS_SIMULATOR_REDIRECT_TYPE,
  [ADDITIONAL_PENSION_APPLICATION_FORM_FILE_TYPE]: ADDITIONAL_PENSION_APPLICATION_TYPE,
  [DEPOSIT_IPS_FORM_FILE_TYPE]: DEPOSIT_IPS_TYPE,
  [LIFE_ANNUTY_APPLICATION_FORM_FILE_TYPE]: LIFE_ANNUITY_APPLICATION_REDIRECT_TYPE,
  [LIFE_ANNUTY_DECISION_FORM_FILE_TYPE]: LIFE_ANNUITY_DECISION_REDIRECT_TYPE,
  [CALCUATION_AND_BOND_RECOGNITION_FORM_FILE_TYPE]: CALCULATION_AND_BOND_RECOGNITION_REDIRECT_TYPE,
  [SELECT_MODALITY_BOND_RECOGNITION_FORM_FILE_TYPE]: SELECT_MODALITY_BOND_RECOGNITION_REDIRECT_TYPE,
};

export const HIDE_COMMENTS_FORMS = [

];

export const PENSIONER_BASE_PATH = 'pensionado/asesoria/';
export const AFFILIATE_BASE_PATH = 'afiliado/asesoria/';

export const BASE_PATHS = {
  [MORTUARY_FORM_TYPE]: PENSIONER_BASE_PATH,
  [INHERITANCE_FORM_TYPE]: PENSIONER_BASE_PATH,
  [SURVIVOR_PENSION]: AFFILIATE_BASE_PATH,
  [MANDATES_FORM_FILE_TYPE]: AFFILIATE_BASE_PATH,
};

export const PENSION_FORM_TYPE = {
  mortuaryQuota: MORTUARY_FORM_TYPE,
  inheritanceRequest: INHERITANCE_FORM_TYPE,
  survivalPension: SURVIVOR_PENSION,
  disabilityPension: DISABILITY_PENSION_TYPE,
  anticipatedElderhoodPension: ANTICIPATED_PENSION_TYPE,
  elderhoodPension: ELDERLY_PENSION_TYPE,
  surplusPayment: FREELY_DISPOSABLE_SURPLUS_PAYMENT_FORM_FILE_TYPE,
  surplusCalculation: FREELY_DISPOSABLE_SURPLUS_CALCULATION_FORM_FILE_TYPE,
  disabilityReevaluation: DISABILITY_REEVALUATION_FORM_FILE_TYPE,
  caiForm: CAI_WITHDRAWAL_FORM_FILE_TYPE,
  foreignerTechnicalWithdrawal: WITHDRAWAL_TECHNICAL_FUNDS_FORM_FILE_TYPE,
  overpaymentRefund: OVERPAYMENT_REFUND_FORM_FILE_TYPE,
  familyAllowanceFormFileType: FAMILY_ALLOWANCE_FORM_FILE_TYPE,
  chargingFeesFormFileType: CHARGING_FEES_FORM_FILE_TYPE,
  studiesAccreditation: STUDIES_ACCREDITATION_FORM_FILE_TYPE,
  employerDiscountForVoluntaryAffiliateFormType: EMPLOYER_DISCOUNT_VOLUNTARY_MEMBER_FORM_FILE_TYPE,
  requestForTransferFundsFromIndividualAccountFormFileType: REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_FORM_TYPE,
  requestSurplusFundsSimulatorFormFileType: REQUEST_SURPLUS_FUNDS_SIMULATOR_FORM_FILE_TYPE,
  additionalPensionApplicationFormFileType: ADDITIONAL_PENSION_APPLICATION_FORM_FILE_TYPE,
  depositIpsFormFileType: DEPOSIT_IPS_FORM_FILE_TYPE,
  mandatesFormFileType: MANDATES_FORM_FILE_TYPE,
  lifeAnnuityApplicationFormFileType: LIFE_ANNUTY_APPLICATION_FORM_FILE_TYPE,
  lifeAnnuityDecisionFormFileType: LIFE_ANNUTY_DECISION_FORM_FILE_TYPE,
  calculationAndBondRecognitionFormFileType: CALCUATION_AND_BOND_RECOGNITION_FORM_FILE_TYPE,
  selectModalityBondRecognitionFormFileType: SELECT_MODALITY_BOND_RECOGNITION_FORM_FILE_TYPE,

};

export const FORM_TYPES = {
  [pensionerPaths.assessmentPath.paperworkCenterPath]: '',
  [pensionerPaths.assessmentPath.familyAllowancePath]: FAMILY_ALLOWANCE_FORM_FILE_TYPE,
  [pensionerPaths.assessmentPath.certifyStudiesPath]: '',
  [pensionerPaths.assessmentPath.mortuaryQuotaPath]: MORTUARY_FORM_TYPE,
  [pensionerPaths.assessmentPath.inheritanceRequestPath]: INHERITANCE_FORM_TYPE,
  [pensionerPaths.assessmentPath.chargingFeesPath]: CHARGING_FEES_FORM_FILE_TYPE,
  [pensionerPaths.assessmentPath.stateGuaranteePath]: '',
  [pensionerPaths.assessmentPath.studiesAccreditationPath]: STUDIES_ACCREDITATION_FORM_FILE_TYPE,
  [affiliatePaths.assessmentPath.futurePensionerPath]: '',
  [affiliatePaths.assessmentPath.multiFundsPath]: '',
  [affiliatePaths.assessmentPath.financialInformationPath]: '',
  [affiliatePaths.assessmentPath.simulatorsPath]: '',
  [affiliatePaths.assessmentPath.certificatesPath]: '',
  [affiliatePaths.assessmentPath.paperworkCenterPath]: '',
  [affiliatePaths.assessmentPath.survivorPensionPath]: SURVIVOR_PENSION,
  [affiliatePaths.assessmentPath.employerDiscount]: EMPLOYER_DISCOUNT_VOLUNTARY_MEMBER_FORM_FILE_TYPE,
  [affiliatePaths.assessmentPath.regulatoryDocuments]: MANDATES_FORM_FILE_TYPE,
};

export const ORIGIN_FORMS_KEY = 'W';

export const PARTIAL_FORMS_WEB_CHANNEL = 'W';
export const PARTIAL_FORMS_BRANCH_OFFICE_CHANNEL = 'S';

export const CHANNEL_LABELS = {
  [PARTIAL_FORMS_BRANCH_OFFICE_CHANNEL]: 'Sucursal',
  [PARTIAL_FORMS_WEB_CHANNEL]: 'Sitio web'
};

export enum filesActionType {
  insert,
  update,
  delete,
  none,
}

export const FORM_TYPES_ONLY_PRIVATE_SITE = [
  REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_FORM_TYPE,
  REQUEST_SURPLUS_FUNDS_SIMULATOR_FORM_FILE_TYPE,
  ADDITIONAL_PENSION_APPLICATION_FORM_FILE_TYPE,
  DEPOSIT_IPS_FORM_FILE_TYPE,
  LIFE_ANNUTY_APPLICATION_FORM_FILE_TYPE,
  LIFE_ANNUTY_DECISION_FORM_FILE_TYPE,
];
