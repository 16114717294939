import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { deceasedDateControlKey, PERSON_BACKGROUNDS_DEFAULT_CONTROLS } from './person-backgrounds.constant';

export const INHERITANCE_APPLICANT_CONTROLS: Array<PersonBackgroundsField> = [
  { key: 'names', value: '', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.names },
  { key: 'lastName', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.lastName },
  { key: 'rut', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.rut },
  { key: 'cellphone', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.cellphone },
  { key: 'email', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.email },
];

export const INHERITANCE_DECEASED_CONTROLS: Array<PersonBackgroundsField> = [
  { key: 'names', value: '', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.names },
  { key: 'lastName', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.lastName },
  { key: 'secondSurname', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.secondSurname },
  { key: 'rut', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.rut },
  { key: 'maritalStatus', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.maritalStatus },
  { key: 'gender', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.gender },
  { key: 'deceasedDate', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS[deceasedDateControlKey] },
];
