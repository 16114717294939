import { LoginModal, Image } from '@interfaces/components.interface';
import { LOGIN_MODAL_KEY } from './titles.constant';

export const MODAL_PRIVATE_SITE: LoginModal = {
    newSite: {
      title: `${LOGIN_MODAL_KEY}.NEW_SITE.TITLE`,
      description: `${LOGIN_MODAL_KEY}.NEW_SITE.DESCRIPTION`,
      link: `${LOGIN_MODAL_KEY}.NEW_SITE.LINK_TITLE`
    },
    actualSite: {
      title: `${LOGIN_MODAL_KEY}.ACTUAL_SITE.TITLE`,
      description: `${LOGIN_MODAL_KEY}.ACTUAL_SITE.DESCRIPTION`,
      link: `${LOGIN_MODAL_KEY}.ACTUAL_SITE.LINK_TITLE`
    }
};

export const IMAGES_INFORMATION: Image[] = [
  {
    url: 'assets/images/modal-login1.jpg',
    alt: 'Cliente navegando en la nueva sucursal virtual'
  },
  {
    url: 'assets/images/modal-login2.jpg',
    alt: 'Cliente pensionado en la actual sucursal virtual'
  }
];
