<div class="table-container">
  <div class="row header">
    <p class="column" [ngClass]="font?.size">{{ data?.header.type }}</p>
    <p class="column" [ngClass]="font?.size">{{ data?.header.age }}</p>
    <p class="column" [ngClass]="font?.size">{{ data?.header.funds }}</p>
  </div>
  <div class="items" *ngFor="let item of data?.items">
    <div class="row">
      <p class="column type" [ngClass]="font?.size">{{ item.type }}</p>
      <p class="column" [ngClass]="font?.size" [innerHtml]="item.age"></p>
      <p class="column" [ngClass]="font?.size">{{ item.funds }}</p>
    </div>
  </div>
</div>
