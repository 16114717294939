import { Component, Input } from '@angular/core';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import { FontService } from '@providers/font/font.service';
import { RegisterForm } from '@interfaces/register-account.interface';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ValidateRut } from '@validators/rut.validator';
import { ContactService } from '@services/contact/contact.service';
import { RegisterBankAccountParams } from '@interfaces/register-bank-account.interface';
import { ModalService } from '@providers/modal/modal.service';
import { REGISTER_ACCOUNT_MODAL, REGISTER_ACCOUNT_CONTENT } from '@constants/pages-content/register-account.constant';
import { ErrorUtils } from '@utils/error.utils';
import { ModalBankRegisterResultComponent } from '@components/modal-bank-register-result/modal-bank-register-result.component';
import { LoadingService } from '@providers/loading/loading.service';
import { finalize } from 'rxjs/operators';
import { RECAPTCHA_DISCLAIMER } from '@constants/pages-content/general.constant';
import { VirtualAccountValidator } from '@validators/virtual-account.validator';

@Component({
  selector: 'app-register-form-box',
  templateUrl: './register-form-box.component.html',
  styleUrls: ['./register-form-box.component.scss'],
})
export class RegisterFormBoxComponent {
  @Input() public boxData: RegisterForm;
  public minLength = 9;
  public maxLength = 9;
  public recaptchaContent = RECAPTCHA_DISCLAIMER;
  public registerForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    rut: new UntypedFormControl('', ValidateRut),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(this.maxLength),
    Validators.minLength(this.minLength), Validators.pattern(PHONE_PATTERN)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
    bankName: new UntypedFormControl('', Validators.required),
    accountType: new UntypedFormControl('', Validators.required),
    accountNumber: new UntypedFormControl('', [Validators.required, VirtualAccountValidator])
  });
  public errors = REGISTER_ACCOUNT_CONTENT.errors;

  constructor(
    private errorUtils: ErrorUtils,
    public font: FontService,
    private modalService: ModalService,
    private contactService: ContactService,
    private loadingService: LoadingService,
  ) { }

  public sendForm() {
    if (this.registerForm.invalid) { return; }
    const params: RegisterBankAccountParams = {
      name: this.registerForm.controls['name'].value,
      rut: this.registerForm.controls['rut'].value,
      phoneNumber: this.registerForm.controls['phoneNumber'].value,
      email: this.registerForm.controls['email'].value,
      bankName: this.registerForm.controls['bankName'].value,
      accountType: this.registerForm.controls['accountType'].value,
      accountNumber: this.registerForm.controls['accountNumber'].value,
    };
    this.loadingService.showLoading();
    this.contactService.registerBankAccount(params)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => {
          if (response) { this.cleanForm(); }
          this.modalService.openModal(ModalBankRegisterResultComponent, { data: response });
        },
        (error) => this.modalService.openAlert(
          {
            title: REGISTER_ACCOUNT_MODAL.failure.title,
            description: this.errorUtils.handleServiceError(error),
          }
        ),
      );
  }

  private cleanForm() {
    this.registerForm.reset({
      name: '',
      rut: '',
      phoneNumber: '',
      email: '',
      bankName: '',
      accountType: '',
      accountNumber: ''
    });
    this.registerForm.enable();
  }
}
