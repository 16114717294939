<ng-container *ngIf="validatedComponent">
    <div class="partial-content">
        <ion-button class="btn small secondary label medium save" [disabled]="!validForm" (click)="sendForm(true)">
            {{ pageContent?.buttons.saveContinueLater | translate }}
            <div class="icon-result save"></div>
        </ion-button>
    </div>
    <div class="form-container">
        <div class="form-section">
            <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.form.funeralServices.title | translate }}</h3>
            <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.form.funeralServices.description | translate }}</p>
            <app-person-backgrounds *ngIf="trimedRelationships" [pageContent]="funeralServicesBackground" [controlsName]="resumeAffiliateControls" [fieldsValidators]="fieldsValidators" [relationships]="trimedRelationships" (formChanged)="setFuneralServiceBackgroundForm($event)">
            </app-person-backgrounds>
        </div>
        <div class="form-section">
            <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.form.quotaPayment.title | translate }}</h3>
            <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.form.quotaPayment.description | translate }}</p>
            <app-person-backgrounds *ngIf="relationships" [pageContent]="funeralServicesBackground" [controlsName]="quotaPaymentControls" [fieldsValidators]="fieldsValidators" [relationships]="relationships" (formChanged)="setQuotaPaymentBackgroundForm($event)">
            </app-person-backgrounds>
        </div>
        <div class="button-container">
            <ion-button class="button primary form-button" type="submit" [ngClass]="font?.size" (click)="sendForm()" [disabled]="!validForm">
                {{ pageContent?.buttons?.next | translate }}
                <div class="icon-result next"></div>
            </ion-button>
            <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
                <div class="icon-result back"></div>
                {{ pageContent?.buttons?.back | translate }}
            </ion-button>
        </div>
    </div>
</ng-container>