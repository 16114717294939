import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'informacion', redirectTo: 'informacion/retiro-fondos', pathMatch: 'full' },
  {
    path: 'informacion',
    children: [
      {
        path: 'retiro-fondos',
        loadChildren: () => import('./funds-withdrawal/funds-withdrawal.module').then(m => m.FundsWithdrawalPageModule)
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InformationPageRoutingModule { }
