<div class="fund-quota-value" [class.simplified]="simplified">
  <div class="fund-detail">
    <p *ngIf="!loading" class="fund" [ngClass]="font?.size">{{ fundLabel }}</p>
    <p *ngIf="loading" class="fund">
      <ion-skeleton-text class="skeleton-line" [class.xsmall]="simplified" animated></ion-skeleton-text>
    </p>
    <p class="fund-name" [ngClass]="font?.size">{{ fund }}</p>
  </div>
  <p class="fund-value" [ngClass]="[font?.size, deltaClass]">{{ fundValue | currency: 'CLP':'$': '1.0-2' }}</p>
  <div class="variation" [ngClass]="deltaIcon"></div>
</div>
