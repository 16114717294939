import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@components/modal/modal.component';
import { filesActionType } from '@constants/forms.constant';
import {
  MODAL_POWER_ATTORNEY_CONTENT, POWER_ATTORNEY_REQUIRED_FILES
} from '@constants/pages-content/inheritance-request.constants';
import { AttachFiles } from '@interfaces/attach-files.interface';
import { PowerAttorneyDocument } from '@interfaces/inheritance-request.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';

@Component({
  selector: 'app-modal-power-attorney',
  templateUrl: './modal-power-attorney.component.html',
  styleUrls: ['./modal-power-attorney.component.scss'],
})
export class ModalPowerAttorneyComponent extends ModalComponent implements OnInit {
  public pageContent = MODAL_POWER_ATTORNEY_CONTENT ;
  public form: UntypedFormGroup;
  public loadData = false;
  public maxDate = new Date();
  public requiredFiles: AttachFiles;
  public powerAttorney: PowerAttorneyDocument;
  public fileKeys: Array<string> = [];

  public get attachedFile(): AbstractControl { return this.form.get('attachedFile'); }
  public get fileName(): AbstractControl { return this.form.get('fileName'); }
  public get action(): AbstractControl { return this.form.get('action'); }
  public get fileKey(): AbstractControl { return this.form.get('fileKey'); }
  public get requestFiles(): boolean { return Boolean(this.requiredFiles) && this.requiredFiles.files.length > 0; }
  public get buttonText(): string {
    return this.powerAttorney ? this.pageContent.buttons.updateDocuments : this.pageContent.buttons.addDocuments;
  }
  public get filesLoaded(): any {
    const filesObject = {};
    filesObject['powerAttorney'] = this.powerAttorney.attachedFile;
    return filesObject;
  }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.powerAttorney = this.params.get('item');
    this.loadData = true;
  }

  public ngOnInit(): void {
    this.createForm();
    this.loadFiles();
  }

  public async addPowerAttorney(): Promise<void> {
    if (this.form.invalid) { return; }

    const powerAttorneyDocument = this.form.value as PowerAttorneyDocument;
    await this.closeModal(powerAttorneyDocument);
  }

  public setFiles(data: any): void {
    const file = data.fileList[0];
    const action = data.actionList[0];
    const key = data.keyList[0];
    this.attachedFile.setValue(file);
    this.fileName.setValue(file ? file['name'] : null);
    this.action.setValue(action);
    if (file && !this.fileKey.value) { this.fileKey.setValue(key); }
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      typeDocument: [POWER_ATTORNEY_REQUIRED_FILES.notarialPower.buttonLabel, Validators.required],
      fileName: [this.getDocumentsAttribute('fileName'), Validators.required],
      attachedFile: [this.getDocumentsAttribute('attachedFile'), Validators.required],
      fileKey: [this.getDocumentsAttribute('fileKey')],
      action: [filesActionType.none],
    });
  }

  private getDocumentsAttribute(attribute: string): string {
    if (!this.powerAttorney) { return ''; }
    return this.powerAttorney[attribute];
  }

  private loadFiles(): void {
    this.requiredFiles = {
      files: Object.entries(POWER_ATTORNEY_REQUIRED_FILES).map(entry => entry[1])
    };
  }
}
