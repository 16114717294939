import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { IChartistData } from 'chartist';

import { InvestorResultPopoverComponent } from '@components/investor-result-popover/investor-result-popover.component';
import { LOGIN_OPTION } from '@constants/header.constant';
import * as CONTENT from '@constants/pages-content/investor-profile.constant';
import { QUOTA_VALUE_INFORMATION } from '@constants/pages-content/quota-value-information.constant';
import { EMAIL_PATTERN } from '@constants/regex.constant';
import { PUBLIC_SITE_ORIGIN } from '@constants/utils.constants';
import { environment } from '@environment';
import { QuotaValueInformation } from '@interfaces/financial-information.interface';
import {
  ChartInvestorProfileQuotaParams,
  InvestorProfileBox,
  InvestorProfileResult,
  InvestorProfileResultRequest,
  UpdateInvestorProfileResultRequest
} from '@interfaces/investor-profile.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { PopoverService } from '@providers/popover/popover.service';
import { InvestorProfileService } from '@services/investor-profile/investor-profile.service';
import { ChartistUtils } from '@utils/chartist.utils';
import { ValidateRut } from '@validators/rut.validator';
import { ValidateWhiteSpace } from '@validators/white-space.validator';

@Component({
  selector: 'app-investor-profile-box',
  templateUrl: './investor-profile-box.component.html',
  styleUrls: ['./investor-profile-box.component.scss'],
})
export class InvestorProfileBoxComponent implements OnInit {
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  @Input() public boxData: InvestorProfileBox;
  @Output() public changeStartQuiz = new EventEmitter();

  private controlNames = CONTENT.CONTROL_NAMES;
  public questions = CONTENT.INVESTOR_PROFILE_QUESTIONS;
  public textContent = CONTENT.TEXT_CONTENT;
  public utilsContent = CONTENT.INVESTOR_PROFILE_UTILS;
  public profile: InvestorProfileResult;
  public chartParams: ChartInvestorProfileQuotaParams;
  public information: QuotaValueInformation = QUOTA_VALUE_INFORMATION;
  public selectedFundId: number;
  public loadingSimulationResultId = false;
  public simulationResultId: number;
  public form = new UntypedFormGroup({
    personalInformation: new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, ValidateWhiteSpace]),
      rut: new UntypedFormControl('', [Validators.required, ValidateRut]),
      email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
      phone: new UntypedFormControl(''),
    }),
    answers: new UntypedFormGroup({
      first: new UntypedFormControl('', Validators.required),
      second: new UntypedFormControl('', Validators.required),
      third: new UntypedFormControl('', Validators.required),
      fourth: new UntypedFormControl('', Validators.required),
      fifth: new UntypedFormControl('', Validators.required),
      sixth: new UntypedFormControl('', Validators.required),
    })
  });

  public get stepIndex() { return this.stepper ? this.stepper.selectedIndex : 0; }
  public get answersGroup() { return this.form.get('answers') as UntypedFormGroup; }
  public get rut() { return this.personalInformationGroup.get('rut') as UntypedFormControl; }
  public get name() { return this.personalInformationGroup.get('name') as UntypedFormControl; }
  public get email() { return this.personalInformationGroup.get('email') as UntypedFormControl; }
  public get phone() { return this.personalInformationGroup.get('phone') as UntypedFormControl; }
  public get personalInformationGroup() { return this.form.get('personalInformation'); }

  constructor(
    public font: FontService,
    private chartUtils: ChartistUtils,
    private navService: NavigationService,
    private investorProfileService: InvestorProfileService,
    private loadingService: LoadingService,
    private popoverService: PopoverService,
  ) { }

  ngOnInit() {
    this.chartParams = {
      chartData: this.getChartData,
      chartType: CONTENT.CHART_TYPE,
      chartOptions: CONTENT.CHART_OPTIONS,
    };
    this.selectedFundId = 0;
  }

  public get fundBackgroundClass() {
    if (!this.profile) { return ''; }
    const { fund } = this.profile;
    return fund ? `fund-${fund.toLowerCase()}-background` : '';
  }

  public get fundColorClass() {
    if (!this.profile) { return ''; }
    const { fund } = this.profile;
    return fund ? `fund-${fund.toLowerCase()}-risk` : '';
  }

  public nextStep() {
    return this.stepper.next();
  }

  public backStep() {
    return this.stepper.previous();
  }

  public answerControlName(index: number): string {
    return this.controlNames[index + 1];
  }

  public stepControl(index: number) {
    return this.answersGroup.get(`${index}`);
  }

  public invalidStepForm(index: number) {
    return this.answersGroup.get(this.answerControlName(index)).invalid;
  }

  public updateSimulation(option?: string) {
    this.loadingService.showLoading();
    const data: UpdateInvestorProfileResultRequest = {
      id: this.simulationResultId,
      goToTransfer: option === 'transfer',
      goToPrivateSite: option !== 'transfer',
    };

    return this.investorProfileService.updateSimulationResult(data).toPromise()
      .then(() => {
        this.loadingService.hideLoading();
      })
      .catch(() => this.simulationResultId = null)
      .finally(() => this.openUrl(option));
  }

  public openUrl(option?: string) {
    const privateSiteUrl = `${LOGIN_OPTION.url}ingreso?redirect=change`;
    const url = option === 'transfer' ? environment.transferUrl : privateSiteUrl;
    this.navService.goTo(url);
  }

  public startQuiz() {
    this.changeStartQuiz.emit(true);
    this.investorProfileService.sendAttentionCode({
      rut: this.rut.value,
      step: 0
    }).subscribe();
    this.nextStep();
  }

  public resetForm() {
    this.changeStartQuiz.emit(false);
    this.stepper.reset();
    this.form.reset();
    this.profile = null;
    this.simulationResultId = null;
  }

  public calculateInvestorProfile() {
    try {
      this.loadingSimulationResultId = true;
      this.nextStep();
      const answersFormValues = this.answersGroup.getRawValue();
      const values = Object.values(answersFormValues);
      const total = values.reduce((accumulate: number, currentValue: number) => (accumulate + currentValue), 0) as number;
      this.processInvestorProfile(total);
      this.investorProfileService.sendAttentionCode({
        rut: this.rut.value,
        step: 1
      }).subscribe();
    } catch (error) {
      this.investorProfileService.sendAttentionCode({
        rut: this.rut.value,
        step: -1
      }).subscribe();
      throw new Error(error);
    }
  }

  private processInvestorProfile(score: number) {
    const results = [...CONTENT.INVESTOR_PROFILE_RESULT];
    results.reverse();
    results.forEach((profile) => {
      if (score <= profile.score) {
        this.profile = profile;
      }
    });
    this.selectedFundId = this.profile.fund.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);
    this.saveSimulation();
  }

  private saveSimulation() {
    const data: InvestorProfileResultRequest = {
      name: this.name.value,
      rut: this.rut.value,
      email: this.email.value,
      phone: this.phone.value,
      simulatedFundResult: this.profile.fund.toUpperCase(),
      origin: PUBLIC_SITE_ORIGIN,
    };
    this.investorProfileService.saveSimulationResult(data).toPromise()
      .then((res) => {
        this.simulationResultId = +res.id;
      })
      .catch(() => this.simulationResultId = null)
      .finally(() => this.loadingSimulationResultId = false);
  }

  private get getChartData(): IChartistData {
    const values = this.information.profitabilityDataFunds;
    const labels = values.map((value) => value.year);
    const series = this.chartUtils.getSeriesQuotaValueFunds(values);
    return { series, labels };
  }

  public openInfo(event) {
    this.popoverService.presentPopover(InvestorResultPopoverComponent, {}, event, 'cover');
  }
}
