<div class="modal modal-container" *ngIf="loadData">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <form class="form-container" [formGroup]="form" (submit)="addFamilyBurden()">
    <h1 class="title primary" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h1>
    <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.personalData.title | translate }}</h2>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label" [innerHTML]="pageContent?.personalData.form.name | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="name" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('name')" [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('name')">{{ pageContent?.errors.patternLetters | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label" [innerHTML]="pageContent?.personalData.form.lastName | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="lastName" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('lastName')" [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('lastName')">{{ pageContent?.errors.patternLetters | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label" [innerHTML]="pageContent?.personalData.form.secondSurname | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="secondLastName">
        <mat-error [ngClass]="font?.size">{{ pageContent?.errors.patternLetters | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label" [innerHTML]="pageContent?.personalData.form.rut | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut" rutInput required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('rut')">{{ pageContent?.errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="!requiredError('rut') && rut.hasError('validRut')">{{ pageContent?.errors.invalidRut | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field class="calendar-input" [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.personalData.form.birthdate | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="birthdateInput" (dateChange)="setDate('birthdate')" matInput [matDatepicker]="datepicker" [max]="maxDate">
        <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #datepicker></mat-datepicker>
      </mat-form-field>

      <mat-radio-group class="option-container" formControlName="gender">
        <h4 class="label bold" [ngClass]="font?.size">{{ pageContent?.personalData.form.gender.label | translate }}</h4>
        <div class="options">
          <mat-radio-button value="F" class="option" [class.selected]="isChecked('gender', 'F')" [ngClass]="font?.size"> {{ pageContent?.personalData.form.gender.female | translate }}</mat-radio-button>
          <mat-radio-button value="M" class="option" [class.selected]="isChecked('gender', 'M')" [ngClass]="font?.size"> {{ pageContent?.personalData.form.gender.male | translate }}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="fields-group">
      <mat-form-field class="calendar-input" [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.personalData.form.benefitStart | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="startDateInput" (dateChange)="setDate('startDate')" matInput [matDatepicker]="startDatepicker" [max]="maxDate" required>
        <mat-datepicker-toggle [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #startDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label class="label">{{ pageContent?.personalData.form.relationship | translate }}</mat-label>
        <mat-select formControlName="relationshipId" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let relationship of relationships" [value]="relationship[1]">
            {{ relationship[1] | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('relationshipId')">{{ pageContent?.errors.required | translate }}</mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="relationshipId.hasError('invalidApplicant')">{{ pageContent?.errors.invalidApplicant | translate }}</mat-error>
      </mat-form-field>

      <mat-radio-group class="option-container custom-border" formControlName="isDisabled">
        <h4 class="label bold" [ngClass]="font?.size">{{ pageContent?.personalData.form.isDisabled | translate }}</h4>
        <div class="options">
          <mat-radio-button [value]="true" class="option" [class.selected]="getControl('isDisabled').value" [ngClass]="font?.size"> {{ pageContent?.form.positiveAnswer | translate }} </mat-radio-button>
          <mat-radio-button [value]="false" class="option" [class.selected]="getControl('isDisabled').value === false" [ngClass]="font?.size"> {{ pageContent?.form.negativeAnswer | translate }} </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    
    <div class="information-container info">
      <span class="icon info"></span>
      <div class="information-text">
        <p class="text-label" [ngClass]="font?.size">{{ pageContent?.disclaimer.header | translate }}</p>
        <p class="text-label" *ngIf="getControl('isDisabled').value" [ngClass]="font?.size">{{ pageContent?.disclaimer.disability | translate }}</p>
        <p class="text-label" *ngIf="isSon" [ngClass]="font?.size">{{ pageContent?.disclaimer.study | translate }}</p>
      </div>
    </div>

    <app-attach-files *ngIf="requestFiles"
      [attachFiles]="requiredFiles"
      [preloadedFiles]="familyBurden ? filesLoaded : null"
      (fileEmitter)="setFiles($event)">
    </app-attach-files>

    <div class="button-container">
      <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit"  [disabled]="!isValidForm">
        {{ buttonText | translate }}
      </ion-button>
    </div>
  </form>
</div>
