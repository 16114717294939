import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MODAL_GET_CERTIFICATE_TEXT_CONTENT,
         PERIOD_CODE_OPTIONS,
         QUOTATIONS_PERIOD_CODE_OPTIONS } from '@constants/pages-content/modal-get-certificate.constants';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-drop-down-period',
  templateUrl: './drop-down-period.component.html',
  styleUrls: ['./drop-down-period.component.scss'],
})
export class DropDownPeriodComponent implements OnInit {
  public textContent = MODAL_GET_CERTIFICATE_TEXT_CONTENT;
  public periodCodeOptions;
  public fields: string[];
  public periodCode = new UntypedFormControl('', Validators.required);
  @Input() type: string;
  @Input() fieldsInput: string[];
  @Output() selectedPeriodCode = new EventEmitter<string>();

  constructor(
    public font: FontService,
    ) { }
  ngOnInit(): void {
    if (this.type === 'quotations') {
      this.periodCodeOptions = QUOTATIONS_PERIOD_CODE_OPTIONS ;
    } else {
      this.periodCodeOptions = PERIOD_CODE_OPTIONS;
    }
  }
  onSelectPeriodCode(): void {
    this.selectedPeriodCode.emit(this.periodCode.value);
  }

}
