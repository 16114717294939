<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <div class="icon-result" [ngClass]="iconName"></div>
  <p class="title" [ngClass]="font?.size" [innerHTML]="title | translate"></p>
  <p class="subtitle" [ngClass]="font?.size" [innerHTML]="description | translate"></p>
  <div class="button-container">
    <ion-button class="button primary" [class.large]="largeButton" [ngClass]="font?.size" [innerHTML]="firstBtnText | translate" (click)="primaryAction()"></ion-button>
    <ion-button class="button secondary" [class.large]="largeButton" *ngIf="showSecondaryButton" [ngClass]="font?.size" [innerHTML]="secondBtnText | translate" (click)="secondaryAction()"></ion-button>
  </div>
</div>
