<div class="steps">
  <div class="step-container" *ngFor="let step of steps; let index = index;">
    <div class="step-description">
      <div class="number-container">
        <p class="number" [ngClass]="font?.size">{{ index + 1 }}</p>
      </div>
      <div class="label-container">
        <p class="label" [ngClass]="font?.size" [innerHTML]="step?.label | translate"></p>
      </div>
    </div>
    <div class="step-text-container" (click)="openDescription(step?.description, $event)">
      <p class="title-step" [ngClass]="font?.size">{{ step?.title | translate }}</p>
      <ion-button class="button transparent" [ngClass]="font?.size">
        <span class="text">{{ step?.moreInformation | translate }}</span>
        <ion-icon class="icon alert" name="alert-circle"></ion-icon>
        <ion-icon class="icon chevron" name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </div>
  </div>
</div>
