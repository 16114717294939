import {
  INHERITANCE_FORM_TYPE, MORTUARY_FORM_TYPE, SURVIVOR_PENSION
} from '@constants/forms.constant';
import { Mockup } from '@interfaces/mockup.interface';

import {
  INHERITANCE_REQUEST_FORM_MOCK, MORTUARY_QUOTA_PARTIAL_FORM_MOCK,
  SURVIVOR_PENSION_PARTIAL_FORM_MOCK
} from './partial-forms.mock';

export const EMPTY_MOCK: Mockup = {
  success: [{
    response: {}
  }],
  failures: [{
    response: {}
  }]
};

export const FORM_DATA_MOCK = {
  [MORTUARY_FORM_TYPE]: MORTUARY_QUOTA_PARTIAL_FORM_MOCK,
  [SURVIVOR_PENSION]: SURVIVOR_PENSION_PARTIAL_FORM_MOCK,
  [INHERITANCE_FORM_TYPE]: INHERITANCE_REQUEST_FORM_MOCK,
};

export const PARTIAL_SAVE_MOCK: Mockup = {
  success: [{
    response: {
      isFirstSave: true,
      folio: 5
    }
  }],
  failures: [{
    response: {}
  }]
};
