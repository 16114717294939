import { Mockup } from '@interfaces/mockup.interface';
import { BehaviorSubject, of } from 'rxjs';

// tslint:disable: max-line-length
export const CONTACT_CENTER_AVAILABLE_MOCK: Mockup = {
  success: [{
    response: true,
  }],
  failures: [
    {
      response: {
        statusCode: 500,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        message: 'Error',
      },
    },
  ],
};

export const CLICK_2_CALL_MOCK: Mockup = {
  success: [
    {
      response: {
        conversation: {
            id: '6520198d-8893-4c77-a4dc-4f076b278d62',
            selfUri: '/api/v2/conversations/6520198d-8893-4c77-a4dc-4f076b278d62',
        },
        callbackIdentifiers: [
            {
                type: 'EXTERNAL',
                id: '7ab34f75-c540-4f1d-9a08-faabfe3be24a',
            },
            {
                type: 'ACD',
                id: '0a82343c-e4ec-4525-bd2f-7578b17194c3',
            },
        ]
      }
    },
  ],
  failures: [
    {
      response: {
        statusCode: 500,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        message: 'Error',
      },
    },
  ],
};

export const CODE_AUTHORIZATION_MOCK: Mockup = {
  success: [
    {
      response: {
        addCommunicationCode: '109715',
        conversation: {
          id: 'b09abea8-ca1b-4810-b0f6-f5423894a42c',
          participants: [],
          selfUri: '/api/v2/conversations/b09abea8-ca1b-4810-b0f6-f5423894a42c',
        },
        features: {
          hawkClientScreenShare: true,
        },
        jwt: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJiMTM5MzU2LTAxMzAtNGJiZS04ZmRjLTMxMGIxN2MzODE0NSIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImppZCI6ImFjZC1iMDlhYmVhOC1jYTFiLTQ4MTAtYjBmNi1mNTQyMzg5NGE0MmMtMTA5NzE1QGNvbmZlcmVuY2UuYWZwcGxhbnZpdGFsMS5vcmdzcGFuLmNvbSJ9LCJleHAiOjE2Mzk3Mzg5NzcsImlhdCI6MTYzOTY3MzUyOSwiaXNzIjoidXJuOnB1cmVjbG91ZDpjb252ZXJzYXRpb24iLCJvcmciOiI0NDg5OWZiYi1iMTNjLTRiMWUtODYzZC02YzViNmMwNWI0MWUifQ.vBQSFaZ8oKyDOL_qq35bmmpvJ3bruuU6EpDOv_uNbUsKFKGDG5K4YMEiuWb0xM_U8LoKq25xOQxbUNHos_bfIl_x_0sS7yQX0YEH8jv2MBg0WjSrQ4RxfO7v9Vy081F-0sSf3JzLL9yq02FMWQR5o-ywICEP_jcjoO1xL9jOlVibvb5Y0zAnYFPIoMIhAQSVYJgRQebVN3BeltfgNAEnathrOXHG4BFHY7qJW4tEqGAE_1guUH7UJBAeaViFTAtKRB7ysbJHZ8ruz1gkn0y3tTOJ-NYHe0Hecn25iX0ZpWnctvEKiPUI-Au8xIGI34NdwLPH-RdAN_42nqUmVlZHtg',
        sourceCommunicationId: '858c9619-693c-4a21-b12e-bd4553a03ef8',
      }
    },
  ],
  failures: [
    {
      response: {
        code: 'not.found',
        contextId: '47a149c3-c92b-410b-86a4-79647163e4a3',
        details: [],
        errors: [],
        message: 'Not Found',
        messageParams: {
          message: 'Code not found',
        },
        messageWithParams: 'Not Found',
        status: 404,
      },
    },
  ],
};

export const GenesysServiceMock = {
  sendClick2CallRequest: () => { },
  // tslint:disable-next-line: deprecation
  isContactCenterAvailable: () => of({ }),
  isSessionActive: new BehaviorSubject<boolean>(false).asObservable(),
  initializeScreenshare: () => Promise.resolve(),
  stopScreenshare: () => Promise.resolve(),
};
