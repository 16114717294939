<div class="modal modal-container" *ngIf="loadData">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <form class="form-container" [formGroup]="form" (submit)="addBeneficiary()">
    <h1 class="title primary" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h1>
    <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.personalData.title | translate }}</h2>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.name | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="name" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('name').hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('name')">{{ pageContent.errors.patternLetters |
          translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.lastName | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="lastName" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('lastName').hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('lastName')">{{ pageContent.errors.patternLetters |
          translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.secondSurname | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="secondSurname">
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('secondSurname')">{{ pageContent.errors.patternLetters |
          translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.rut | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="12.345.678-9"
          [formatRut]="rut" rutInput required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="rut.hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="!rut.hasError('required') && rut.hasError('validRut')"
          [innerHTML]="pageContent?.errors.invalidRut | translate"></mat-error>
      </mat-form-field>

      <mat-form-field class="calendar-input" [ngClass]="font?.size">
        <mat-label>{{ pageContent?.personalData.form.birthdate | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="birthdateInput" (dateChange)="setDate()" matInput
          [matDatepicker]="datepicker" [max]="maxDate" required>
        <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker [touchUi]="mobileWidth" class="icon" #datepicker></mat-datepicker>
      </mat-form-field>

      <mat-radio-group class="option-container" formControlName="gender">
        <h4 class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.personalData.form.gender.label | translate
          }}</h4>
        <div class="options">
          <mat-radio-button value="F" class="option" [class.selected]="isChecked('gender', 'F')" [ngClass]="font?.size">
            {{ pageContent?.personalData.form.gender.female | translate }}</mat-radio-button>
          <mat-radio-button value="M" class="option" [class.selected]="isChecked('gender', 'M')" [ngClass]="font?.size">
            {{ pageContent?.personalData.form.gender.male | translate }}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size" class="double-width">
        <mat-label>{{ pageContent?.personalData.form.relationship | translate }}</mat-label>
        <mat-select formControlName="relationshipId" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let relationship of relationships" [value]="relationship.id">
            {{ relationship.description | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('relationshipId').hasError('required')">{{
          pageContent?.errors.required | translate }}</mat-error>
      </mat-form-field>

      <mat-radio-group class="option-container custom-border" formControlName="isDisabled">
        <h4 class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.personalData.form.isDisabled | translate
          }}</h4>
        <div class="options">
          <mat-radio-button [value]="true" class="option" [class.selected]="getControl('isDisabled').value"
            [ngClass]="font?.size"> {{ pageContent?.form.positiveAnswer | translate }} </mat-radio-button>
          <mat-radio-button [value]="false" class="option" [class.selected]="getControl('isDisabled').value === false"
            [ngClass]="font?.size"> {{ pageContent?.form.negativeAnswer | translate }} </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <ng-container *ngIf="showContactInfo">
      <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.contactData.title | translate }}</h2>

      <div class="fields-group">
        <mat-form-field class="field" [ngClass]="font?.size">
          <mat-label [innerHTML]="pageContent?.contactData.form.email | translate"></mat-label>
          <input [ngClass]="font?.size" matInput minlength="5" maxlength="50" type="text" placeholder="ejemplo@mail.com"
            formControlName="email" (blur)="validateEmailDomain(getControl('email'))" required>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('email')?.hasError('required')">
            {{ pageContent?.errors.required | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('email')?.hasError('email')">
            {{ pageContent?.errors.invalidEmail | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('email').hasError('invalidDomain')">
            {{ pageContent?.errors.invalidDomain | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="field" [ngClass]="font?.size">
          <mat-label>{{ pageContent?.contactData.form.reEmail | translate }}</mat-label>
          <input [ngClass]="font?.size" matInput minlength="5" maxlength="50" type="text" placeholder="ejemplo@mail.com"
            formControlName="reEmail" blockPaste required>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('reEmail')?.hasError('required')">
            {{ pageContent?.errors.required | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('reEmail')?.hasError('email')">
            {{ pageContent?.errors.invalidEmail | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('reEmail')?.hasError('mustMatch')">
            {{ pageContent?.errors.mustMatch | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="number-clean field" [ngClass]="font?.size">
          <mat-label [innerHTML]="pageContent?.contactData.form.cellphone | translate"></mat-label>
          <input [ngClass]="font?.size" matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax"
            placeholder="223456789" formControlName="cellphone" [minlength]="phoneNumberLength"
            [maxlength]="phoneNumberLength" required digitsOnly>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('cellphone')?.hasError('required')">
            {{ pageContent?.errors.required | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size"
            *ngIf="getControl('cellphone')?.hasError('min') || getControl('cellphone')?.hasError('max')">
            {{ pageContent?.errors.invalidCellphone | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="showTutorInfo">
      <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.tutorData.title | translate }}</h2>
      <div class="fields-group">
        <div class="fields-group">
          <mat-form-field [ngClass]="font?.size">
            <mat-label [innerHTML]="pageContent?.personalData.form.name | translate"></mat-label>
            <input [ngClass]="font?.size" matInput type="text" formControlName="tutorName" required>
            <span matSuffix class="i-validator"></span>
            <mat-error [ngClass]="font?.size" *ngIf="getControl('tutorName').hasError('required')"
              [innerHTML]="pageContent?.errors.required | translate"></mat-error>
            <mat-error [ngClass]="font?.size" *ngIf="patternError('tutorName')">{{ pageContent.errors.patternLetters |
              translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field [ngClass]="font?.size">
            <mat-label [innerHTML]="pageContent?.personalData.form.lastName | translate"></mat-label>
            <input [ngClass]="font?.size" matInput type="text" formControlName="tutorLastName" required>
            <span matSuffix class="i-validator"></span>
            <mat-error [ngClass]="font?.size" *ngIf="getControl('tutorLastName').hasError('required')"
              [innerHTML]="pageContent?.errors.required | translate"></mat-error>
            <mat-error [ngClass]="font?.size" *ngIf="patternError('tutorLastName')">{{ pageContent.errors.patternLetters
              | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field [ngClass]="font?.size">
            <mat-label [innerHTML]="pageContent?.personalData.form.secondSurname | translate"></mat-label>
            <input [ngClass]="font?.size" matInput type="text" formControlName="tutorSecondSurname">
            <span matSuffix class="i-validator"></span>
            <mat-error [ngClass]="font?.size" *ngIf="patternError('tutorSecondSurname')">{{
              pageContent.errors.patternLetters | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="fields-group">

        <mat-form-field [ngClass]="font?.size">
          <mat-label [innerHTML]="pageContent?.personalData.form.rut | translate"></mat-label>
          <input [ngClass]="font?.size" matInput type="text" formControlName="tutorRut" placeholder="12.345.678-9"
            [formatRut]="tutorRut" rutInput required>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="tutorRut.hasError('required')"
            [innerHTML]="pageContent?.errors.required | translate"></mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="!tutorRut.hasError('required') && tutorRut.hasError('validRut')"
            [innerHTML]="pageContent?.errors.invalidRut | translate"></mat-error>
        </mat-form-field>

        <mat-form-field class="number-clean field" [ngClass]="font?.size">
          <mat-label [innerHTML]="pageContent?.contactData.form.cellphone | translate"></mat-label>
          <input [ngClass]="font?.size" matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax"
            placeholder="223456789" formControlName="tutorCellphone" [minlength]="phoneNumberLength"
            [maxlength]="phoneNumberLength" required digitsOnly>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('tutorCellphone')?.hasError('required')">
            {{ pageContent?.errors.required | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size"
            *ngIf="getControl('tutorCellphone')?.hasError('min') || getControl('tutorCellphone')?.hasError('max')">
            {{ pageContent?.errors.invalidCellphone | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="field" [ngClass]="font?.size">
          <mat-label [innerHTML]="pageContent?.contactData.form.email | translate"></mat-label>
          <input [ngClass]="font?.size" matInput minlength="5" maxlength="50" type="text" placeholder="ejemplo@mail.com"
            formControlName="tutorEmail" (blur)="validateEmailDomain(getControl('tutorEmail'))" required>
          <span matSuffix class="i-validator"></span>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('tutorEmail')?.hasError('required')">
            {{ pageContent?.errors.required | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('tutorEmail')?.hasError('tutorEmail')">
            {{ pageContent?.errors.invalidEmail | translate }}
          </mat-error>
          <mat-error [ngClass]="font?.size" *ngIf="getControl('tutorEmail').hasError('invalidDomain')">
            {{ pageContent?.errors.invalidDomain | translate }}
          </mat-error>
        </mat-form-field>
        <h4 class="subtitle-secondary" [ngClass]="font?.size" style="margin-bottom: 4px;">
          {{pageContent?.tutorData.isMandataryTutor |
          translate
          }}</h4>
        <mat-radio-group class="option-container custom-border" formControlName="isMandataryTutor">
          <div class="options">
            <mat-radio-button [value]="true" class="option" [class.selected]="getControl('isMandataryTutor').value"
              [ngClass]="font?.size"> {{ pageContent?.form.positiveAnswer | translate }} </mat-radio-button>
            <mat-radio-button [value]="false" class="option"
              [class.selected]="getControl('isMandataryTutor').value === false" [ngClass]="font?.size"> {{
              pageContent?.form.negativeAnswer | translate }}</mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </ng-container>
    <div class="file-box" *ngIf="includeAttachmentFile">
      <p class="file-name">{{ fileName }}</p>
      <div class="file-button">
        <input class="file-input" id="file" type="file" accept="application/pdf" (change)="onFileChange($event)" />
        <label class="file-label button" [ngClass]="font?.size" for="file">{{fileLabel | translate}}</label>
      </div>
    </div>
    <div class="button-container">
      <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="form.invalid">
        {{ buttonText | translate }}
      </ion-button>
    </div>
  </form>
</div>