import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { SERIAL_NUMBER_DESCRIPTION, SERIAL_NUMBER_TITLE } from '@constants/pages-content/serial-number-validation.constant';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-serial-number-modal',
  templateUrl: './serial-number-modal.component.html',
  styleUrls: ['./serial-number-modal.component.scss']
})
export class SerialNumberModalComponent extends ModalComponent {
  public title: string = SERIAL_NUMBER_TITLE;
  public description: string = SERIAL_NUMBER_DESCRIPTION;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
  }

}
