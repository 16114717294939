export const SHUTDOWN_TRANSACTIONS_DEFAULT = {
    disabledCertificates: false,
    disabledPaperworkCenterAffiliate: false,
    disabledPaperworkCenterPensioned: false,
    disabledPaperworkFollowUpAffiliate: false,
    disabledPaperworkFollowUpPensioned: false,
    disabledPensionTypePensioned: false,
    disabledSurvivorPensionPensioned: false,
};

export const FIREBASE_REMOTE_CONFIG = {
    minimumFetchIntervalMillis: 3600000,
    fetchTimeoutMillis: 60000
};

export const REMOTE_CONFIG_TIMES = {
    DEFAULT: 43200000,
    MINUTE: 60000,
    ZERO: 0,
};
