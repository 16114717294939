<div class="modal-definition-container">
  <div class="modal-definition-title">
    <div *ngIf="iconName" class="{{ iconName }} modal-definition-icon"></div>
    <h1 class="title secondary" [ngClass]="font?.size" [innerHTML]="pageContent?.title | translate"></h1>
    <div class="modal-definition-close" *ngIf="closeBtn">
      <div class="i-cross-gray" (click)="dialogClose()"></div>
    </div>
  </div>
  <div class="definition-container" *ngFor="let definition of definitions">
    <h1 class="title secondary" [ngClass]="font?.size" [innerHTML]="definition.title | translate"></h1>
    <p class="description" [ngClass]="font?.size" [innerHTML]="definition.definition | translate"></p>
  </div>
</div>
