<div class="main-actions">
  <div class="main-actions-container general-container">
    <div class="action i-document">
      <p *ngIf="!loading" class="text-action" [ngClass]="font?.size" [innerHTML]="mainActions?.leftAction"></p>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line small text-action" animated></ion-skeleton-text>
      <a href="afiliado/asesoria/certificados/obtener" class="button secondary" [ngClass]="font?.size"
         [title]="mainActions?.leftActionButton" (click)="openPage($event, redirect)">
        <ion-icon class="icon" name="chevron-forward-outline"></ion-icon>
        <span [class.hidden]="loading">{{ mainActions?.leftActionButton }}</span>
      </a>
    </div>
    <div class="action i-calendar">
      <p *ngIf="!loading" class="text-action" [ngClass]="font?.size" [innerHTML]="mainActions?.rightAction"></p>
      <ion-skeleton-text *ngIf="loading" class="skeleton-line small text-action" animated></ion-skeleton-text>
      <a href="pensionado/pago-pension/calendario" class="button secondary" [ngClass]="font?.size" (click)="openPage($event, calendarPageUrl)"
         [title]="mainActions?.rightActionButton">
        <ion-icon class="icon" name="chevron-forward-outline"></ion-icon>
        <span [class.hidden]="loading">{{ mainActions?.rightActionButton }}</span>
      </a>
    </div>
  </div>
</div>