import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
})
export class PopoverComponent {

  constructor(
    protected popoverCtrl: PopoverController,
  ) { }

  public dismissPopover(data?): void {
    this.popoverCtrl.dismiss(data);
  }
}
