import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() public initials = '';
  @Input() public size = 'small';
  @Input() public bordered = false;
  @Input() public color = '#000000';
  @Input() public colorSeed = '';

  constructor(
    public utils: Utils,
  ) { }

  private trimInitials(currentValue: string): void {
    this.initials = currentValue.slice(0, 2);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const initialsChanges = changes && changes.initials && changes.initials.currentValue;
    const seedChanges = changes && changes.colorSeed && changes.colorSeed.currentValue;
    if (initialsChanges) { this.trimInitials(initialsChanges); }
    if (seedChanges) { this.color = this.utils.stringToHslPastelColor(seedChanges); }
  }

}
