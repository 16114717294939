<div class="modal alert-modal-container" [class.informative]="informative">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="text-container">
    <div class="title primary" [ngClass]="font?.size" [innerHTML]="title | translate"></div>
    <p class="dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="description | translate"></p>
  </div>
  <ion-button *ngIf="pdfFile?.downloadUrl" class="button primary download" [ngClass]="font?.size" (click)="downloadFile()">{{ pdfFile?.downloadButtonText | translate}}</ion-button>
</div>
