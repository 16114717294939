import { Component, Input, OnChanges } from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';

import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-calendar-card',
  templateUrl: './calendar-card.component.html',
  styleUrls: ['./calendar-card.component.scss'],
})
export class CalendarCardComponent implements OnChanges {
  @Input() dateClass: (date: Date) => MatCalendarCellCssClasses;
  @Input() startDate: Date;
  public monthName: string;
  constructor(
    public font: FontService,
    private utils: Utils,
  ) { }

  public ngOnChanges() {
    this.setCalendarTitle();
  }

  private async setCalendarTitle() {
    const monthIndex = this.startDate.getMonth();
    this.monthName = await this.utils.getTranslatedMonthName(monthIndex);
  }

}
