import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TRANSFER_ALERT_TITLE } from '@constants/transfer.constant';
import { TransferStatusBox } from '@interfaces/transfer-state.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { TransferService } from '@services/transfer/transfer.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';
import { ValidateDate } from '@validators/date.validator';
import { ValidateRut } from '@validators/rut.validator';
import { LoadingService } from '@providers/loading/loading.service';
import { finalize } from 'rxjs/operators';
import { RECAPTCHA_DISCLAIMER } from '@constants/pages-content/general.constant';

@Component({
  selector: 'app-transfer-state-box',
  templateUrl: './transfer-state-box.component.html',
  styleUrls: ['./transfer-state-box.component.scss'],
})
export class TransferStateBoxComponent {
  @Input() public boxData: TransferStatusBox;
  @Input() public loading = false;
  public recaptchaContent = RECAPTCHA_DISCLAIMER;
  public requestForm = new UntypedFormGroup({
    rut: new UntypedFormControl('', ValidateRut),
    date: new UntypedFormControl('', ValidateDate),
    dateInput: new UntypedFormControl('', Validators.required),
  });

  constructor(
    private transferService: TransferService,
    private errorUtils: ErrorUtils,
    private utils: Utils,
    private modalService: ModalService,
    private loadingService: LoadingService,
    public font: FontService,
  ) { }

  public get rut() { return this.requestForm.get('rut') as UntypedFormControl; }

  public getTransferState() {
    if (this.requestForm.invalid) { return; }
    const { rut, date } = this.requestForm.value;
    const rutClean = this.utils.rutClean(rut);
    this.loadingService.showLoading();
    this.transferService.getTransferState(rutClean, date)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => this.modalService.openAlert({ title: TRANSFER_ALERT_TITLE, description: response.state }),
        (error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) })
      );
  }

  public dateSelected() {
    const formattedDate = this.utils.getFormattedDate(this.requestForm.controls['date'].value);
    this.requestForm.controls['dateInput'].setValue(formattedDate);
  }
}
