import { Component, Input } from '@angular/core';
import { ModalContactCenterV2Component } from '../modal-contact-center-v2/modal-contact-center-v2.component';
import { ModalService } from '@providers/modal/modal.service';


@Component({
  selector: 'app-assistance-in-hours',
  templateUrl: './assistance-in-hours.component.html',
  styleUrls: ['./assistance-in-hours.component.scss'],
})
export class AssistanceInHoursComponent {
  @Input() public procedureType;
  constructor(
    private modalService: ModalService
  ) { }

  public openModal(): void {
    const component = ModalContactCenterV2Component;
    const procedureTypeData = {
      procedureType: this.procedureType
    };
    this.modalService.openModal(component, procedureTypeData, 'modal-default', null);
  }

}
