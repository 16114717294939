import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'quienes-somos/nuestra-compania', pathMatch: 'full' },
  { path: 'quienes-somos', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'informacion-relevante',
    loadChildren: () => import('./relevant-information/relevant-information.module').then(m => m.RelevantInformationModule) },
  { path: 'politicas-y-estatutos',
    loadChildren: () => import('./policies-and-statutes/policies-and-statutes.module').then(m => m.PoliciesAndStatutesPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CorporationRoutingModule { }
