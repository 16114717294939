import { Mockup } from '@interfaces/mockup.interface';
import { QuotaValuesBanner } from '@interfaces/quota-values-banner.interface';

import localeEsCl from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEsCl);

export const GET_QUOTA_VALUES_BANNER_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            valores_cuota: 'Valores Cuota',
            fondo: 'Fondo',
            languages_code: 'es'
          }
        ]
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const QUOTA_VALUES_BANNER_MOCKUP: QuotaValuesBanner = {
  quotaValue: 'Valores Cuota',
  fund: 'Fondo',
};

export const GET_QUOTA_VALUES_BANNER_NULL_MOCKUP: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: []
      },
      public: true
    }
  }],
  failures: [{
    response: {}
  }]
};

export const QUOTA_VALUES_BANNER_NULL_MOCKUP: QuotaValuesBanner = {
  quotaValue: null,
  fund: null,
};
