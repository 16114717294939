<div class="title-container">
  <h3 class="title medium">
    <span class="contact-title">
      ¿Necesitas ayuda?
    </span>
  </h3>
  <p class="sub" [ngClass]="font?.size">
    Contáctanos a través de nuestros canales
  </p>
</div>

<div class="row-container" *ngIf="!isMobile">
  <div class="icon-container" (click)="goToSocialNetwork('whatsapp')">
    <div class="icon i-new-whatsapp"></div>
    <span class="channel-info">WhatsApp</span>
    <span class="sub-channel-info">+56 2 2264 5200</span>
  </div>
  <div class="icon-container" (click)="openModal('app-stores')">
    <div class="icon i-new-app"></div>
    <span class="channel-info">App AFP PlanVital</span>
  </div>
  <div class="icon-container" (click)="openModal('call-center')">
    <div class="icon i-new-contact"></div>
    <span class="channel-info">Contact Center</span>
    <span class="sub-channel-info"> 800 072 072</span>
  </div>
  <div class="icon-container no-pointer">
    <div class="icon i-new-contact-international"></div>
    <span class="channel-info">Desde el extranjero</span>
    <span class="sub-channel-info">+56 2 2264 5200</span>
  </div>
  <div class="icon-container" (click)="goToUrl(branchesPage)">
    <div class="icon i-new-sucursales"></div>
    <span class="channel-info">Sucursales</span>
  </div>
  <div class="icon-container" (click)="goToEmail()">
    <div class="icon i-new-contact-form"></div>
    <span class="channel-info">Correo electrónico</span>
    <span class="sub-channel-info">infovital@planvital.cl</span>
  </div>
  <div class="icon-container" (click)="openModal('contact')">
    <div class="icon i-new-infovital"></div>
    <span class="channel-info">Formulario de contacto</span>
  </div>
</div>

<div class="mobile-container" *ngIf="isMobile">
  <div class="column-container">
    <div class="column">
      <div class="icon-container" (click)="goToSocialNetwork('whatsapp')">
        <div class="icon i-new-whatsapp"></div>
        <span class="channel-info">WhatsApp</span>
        <span class="sub-channel-info">+56 2 2264 5200</span>
      </div>
      <div class="icon-container" (click)="openModal('call-center')">
        <div class="icon i-new-contact"></div>
        <span class="channel-info">Contact Center</span>
        <span class="sub-channel-info">800 072 072</span>
      </div>
      <div class="icon-container" (click)="openModal('call-center')">
        <div class="icon i-new-contact-international"></div>
        <span class="channel-info">Desde el extranjero</span>
        <span class="sub-channel-info">+56 2 2264 5200</span>
      </div>
    </div>

    <div class="column">
      <div class="icon-container" (click)="openModal('app-stores')">
        <div class="icon i-new-app"></div>
        <span class="channel-info">App PlanVital</span>
      </div>
      <div class="icon-container" (click)="goToUrl('sucursales')">
        <div class="icon i-new-sucursales"></div>
        <span class="channel-info">Sucursales</span>
      </div>
      <div class="icon-container" (click)="goToEmail()">
        <div class="icon i-new-contact-form"></div>
        <span class="channel-info">Correo electrónico</span>
        <span class="sub-channel-info">infovital@planvital.cl</span>
      </div>
    </div>

  </div>

  <div class="row-container">
    <div class="icon-container" (click)="openModal('contact')">
      <div class="icon i-new-infovital"></div>
      <span class="channel-info">Formulario de contacto</span>
    </div>
  </div>

</div>


<div class="title-container">
  <p class="sub middle" [ngClass]="font?.size">
    Visítanos en nuestras redes sociales
  </p>
</div>

<div *ngIf="!isMobile">
  <div class="row-container social-channels">
    <div class="icon-container sub-line-{{i}}" *ngFor="let socialMediaChannel of socialMediaChannels; index as i"
      (click)="goToSocialNetwork(socialMediaChannel.url)">
      <div class="icon" [ngClass]="iconSocialNetwork(socialMediaChannel.name)"></div>
      <span class="channel-info">{{ socialMediaChannel.title }}</span>
    </div>
  </div>
</div>

<div *ngIf="isMobile">
  <div class="row-container social-channels top">
    <div class="icon-container sub-line-{{i}}" *ngFor="let firstRow of mobileFirstRow; index as i"
      (click)="goToSocialNetwork(firstRow.url)">
      <div class="icon" [ngClass]="iconSocialNetwork(firstRow.name)"></div>
      <span class="channel-info">{{ firstRow.title }}</span>
    </div>
  </div>

  <div class="row-container social-channels bottom">
    <div class="icon-container sub-line-{{i}}" *ngFor="let secondRow of mobileSecondRow; index as i"
      (click)="goToSocialNetwork(secondRow.url)">
      <div class="icon" [ngClass]="iconSocialNetwork(secondRow.name)"></div>
      <span class="channel-info">{{ secondRow.title }}</span>
    </div>
  </div>
</div>