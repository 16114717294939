import {
  GENERAL_PENSION_BACKGROUND_FIELDS_CONTENT, PensionBackgroundsDefaultType
} from '@constants/pension-backgrounds.constant';
import {
  ERROR_INPUT_TEXT, GENERAL_BACKGROUND_FIELDS_CONTENT,
  PERSON_BACKGROUNDS_DEFAULT_CONTROLS, PersonBackgroundsDefaultType, communeKey, regionKey, selectionDateKey
} from '@constants/person-backgrounds.constant';
import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';

const pageKey = 'PENSIONER.ASSESSMENT.CHARGING_FEES';
const clienteCheckKey = `${pageKey}.STEPS.CLIENT_CHECK`;
const generalButtonsKey = `${pageKey}.BUTTONS`;
const backgroundsKey = `${pageKey}.STEPS.BACKGROUNDS`;
const documentationKey = `${pageKey}.STEPS.DOCUMENTATION`;
const paymentKey = `${pageKey}.STEPS.PAYMENT`;
const voucherKey = `${pageKey}.STEPS.VOUCHER`;

const paymentMethodsKey = `${paymentKey}.PAYMENT_METHODS`;
const successSubmissionKey = `${voucherKey}.SUCCESS`;
const failedSubmissionKey = `${voucherKey}.FAILED`;

const savedModal = 'MODALS.SAVED';
const toastKey = `${pageKey}.TOAST`;


export const IDENTIFICATION_FILE_BENEFICIARY = {
  fileIndex: 'idFile',
  required: true
};

export const CHARGING_FEES_FORM_CONTENT = {
  title: `${pageKey}.TITLE`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },
  steps: {
    clientCheck: {
      label: `${clienteCheckKey}.LABEL`,
    },
    backgrounds: {
      label: `${backgroundsKey}.LABEL`,
      affiliateSection: {
        title: `${backgroundsKey}.AFFILIATE_SECTION.TITLE`,
        fields: {
          ...GENERAL_BACKGROUND_FIELDS_CONTENT.fields
        }
      },
      pensionSection: {
        fields: {
          ...GENERAL_PENSION_BACKGROUND_FIELDS_CONTENT.fields
        }
      },
      adviserSection: {
        title: `${backgroundsKey}.ADVISER_SECTION.TITLE`,
        fields: {
          ...GENERAL_BACKGROUND_FIELDS_CONTENT.fields
        },
        errors: {
          ...ERROR_INPUT_TEXT.errors,
          mustMatchRut: `${backgroundsKey}.ADVISER_SECTION.ERRORS.MUST_MATCH_RUT`,
        }
      },
      beneficiariesSection: {
        title: `${backgroundsKey}.BENEFICIARIES_SECTION.TITLE`,
        disclaimer: `${backgroundsKey}.BENEFICIARIES_SECTION.DISCLAIMER`,
        buttons: {
          add: `${backgroundsKey}.BENEFICIARIES_SECTION.BUTTONS.ADD`,
        }
      },
      buttons: {
        next: `${generalButtonsKey}.NEXT`,
        back: `${generalButtonsKey}.BACK`,
        saveContinueLater: `${backgroundsKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      }
    },
    documentation: {
      label: `${documentationKey}.LABEL`,
      declarationSection: {
        title: `${documentationKey}.DECLARATION_SECTION.TITLE`,
        declaration: `${documentationKey}.DECLARATION_SECTION.DECLARATION`,
      },
      filesSection: {
        title: `${documentationKey}.FILES_SECTION.TITLE`,
        disclaimer: `${documentationKey}.FILES_SECTION.DISCLAIMER`,
      },
      buttons: {
        next: `${generalButtonsKey}.NEXT`,
        back: `${generalButtonsKey}.BACK`,
        saveContinueLater: `${documentationKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      }
    },
    payment: {
      label: `${paymentKey}.LABEL`,
      paymentMethods: {
        disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
        rutDisclaimer: `${paymentMethodsKey}.RUT_DISCLAIMER`,
        accountDisclaimer: `${paymentMethodsKey}.VIRTUAL_ACCOUNT_DISCLAIMER`,
        formSection: {
          title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
          transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
          cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
          bank: {
            field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`,
          },
          account: {
            field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`,
          },
          other: {
            options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
          }
        },
        buttons: {
          next: `${paymentMethodsKey}.BUTTONS.NEXT`,
          back: `${generalButtonsKey}.BACK`,
          saveContinueLater: `${paymentKey}.BUTTONS.SAVE_CONTINUE_LATER`,
        },
        errors: {
          required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
          accountNumber: `${paymentMethodsKey}.ERRORS.ACCOUNT_NUMBER`,
          patternVirtualAccount: `${paymentMethodsKey}.ERRORS.VIRTUAL_ACCOUNT`,
        },
      },
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
      success: {
        title: `${successSubmissionKey}.TITLE`,
        description: `${successSubmissionKey}.DESCRIPTION`,
        homeButton: `${successSubmissionKey}.BUTTON`,
        download: `${successSubmissionKey}.DOWNLOAD_PDF`,
      },
      failed: {
        title: `${failedSubmissionKey}.TITLE`,
        description: `${failedSubmissionKey}.DESCRIPTION`,
        firstBtnText: `${failedSubmissionKey}.BUTTON`,
        secondBtnText: `${failedSubmissionKey}.SECOND_BUTTON`,
        iconName: 'i-error',
      }
    },
  }
};

export const CHARGING_FEES_AFFILIATE_PERSON_BACKGROUNDS = [
  'names',
  'rut',
  'selectionDate'
] as Array<PersonBackgroundsDefaultType>;

export const CHARGING_FEES_AFFILIATE_CONTROLS: Array<PersonBackgroundsField> = [
  { key: 'names', value: '', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.names },
  { key: 'rut', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.rut },
  { key: 'selectionDate', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS[selectionDateKey] },
];

export const CHARGING_FEES_ADVISER_PERSON_BACKGROUNDS = [
  'names',
  'rut',
  'commissionAmount',
  'address',
  'commune',
  'region',
  'cellphone',
  'email',
] as Array<PersonBackgroundsDefaultType>;

export const CHARGING_FEES_ADVISER_CONTROLS: Array<PersonBackgroundsField> = [
  { key: 'names', value: '', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.names },
  { key: 'rut', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.rut },
  { key: 'commissionAmount', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.commissionAmount },
  { key: 'address', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.address },
  { key: 'commune', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS[communeKey] },
  { key: 'region', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS[regionKey] },
  { key: 'cellphone', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.cellphone },
  { key: 'email', validators: PERSON_BACKGROUNDS_DEFAULT_CONTROLS.email },
];

export const CHARGING_FEES_PENSION_BACKGROUNDS = [
  'pensionType',
  'pensionModality',
] as Array<PensionBackgroundsDefaultType>;

export const ARRAY_CHARGING_FEES_PROPERTIES: Array<FormArrayItemProperties> = [
  { name: 'fullName', translated: true, style: ['bold'] },
  { name: 'rut', translated: true },
  { name: 'birthdate', translated: true },
];

export const CHARGING_FEES_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const DECLARATION_REQUIRED_FILES = {
  affiliatePersonalDocument: {
    order: 1,
    buttonLabel: `${documentationKey}.FILES_SECTION.AFFILIATE_PERSONAL_DOCUMENT`,
    fileIndex: 'affiliatePersonalDocument',
    required: true
  },
  advisorPersonalDocument: {
    order: 2,
    buttonLabel: `${documentationKey}.FILES_SECTION.ADVISER_PERSONAL_DOCUMENT`,
    fileIndex: 'advisorPersonalDocument',
    required: true
  },
  quotationDocument: {
    order: 3,
    buttonLabel: `${documentationKey}.FILES_SECTION.QUOTATION`,
    fileIndex: 'quotationDocument',
    required: true
  },
  contractDocument: {
    order: 4,
    buttonLabel: `${documentationKey}.FILES_SECTION.CONTRACT`,
    fileIndex: 'contractDocument',
    required: true
  }
};
