<div class="modal modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <div class="icon-result" [ngClass]="{ 'i-send-outline': isValid, 'i-error': !isValid }"></div>
  <p class="title" [ngClass]="font?.size" [innerHTML]="resolution?.title"></p>
  <p class="subtitle" [ngClass]="font?.size" *ngIf="isValid" [innerHTML]="resolution?.description"></p>
  <div class="button-container">
    <ion-button class="button" [ngClass]="[buttonClass, font?.size]" (click)="closeModal()">{{ resolution?.textButton }}</ion-button>
  </div>
</div>
