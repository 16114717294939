<div class="partial-content">
    <ion-button class="btn small secondary label medium save" [disabled]="form.invalid" (click)="sendForm(true)">
        {{ pageContent?.buttons.saveContinueLater | translate }}
        <div class="icon-result save"></div>
    </ion-button>

</div>
<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="sendForm()" *ngIf="form">
    <app-person-backgrounds [pageContent]="pageContent?.form.callingUser" [controlsName]="resumePersonControls" (formChanged)="validateForm($event, 'callingUser')">
    </app-person-backgrounds>
    <app-person-backgrounds [pageContent]="pageContent?.form.deceasedClientSection" [controlsName]="resumeDeceasedPersonControls" [validateRut]="true" [fieldsValidators]="deceasedFieldsValidators" (formChanged)="validateForm($event, 'deceasedUser')">
    </app-person-backgrounds>
    <app-person-backgrounds [pageContent]="pageContent?.form.backgroundsSection" [controlsName]="resumeBackgroundPersonControl" (formChanged)="validateForm($event, 'workSituation')">
    </app-person-backgrounds>
    <div class="form-section">
        <div class="fields-group radio-button-list">
            <app-radio-button-list-form [pageContent]="pageContent?.form.backgroundsSection.fields.backgrounds" [isFirstChild]="true" [backgroundsValues]="backgroundsValues" (formChanged)="validateForm($event, 'backgrounds')">
            </app-radio-button-list-form>
        </div>
    </div>
    <div class="button-container">
        <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
            {{ pageContent?.buttons?.next | translate }}
            <div class="icon-result next"></div>
        </ion-button>
    </div>
</form>