<div class="login">
  <p class="title tertiary light">{{ loginData?.title }}</p>
  <ion-item class="question-login">
    <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ loginData?.rut }}</ion-label>
    <ion-input placeholder="12345678-9"></ion-input>
  </ion-item>
  <ion-item class="question-login">
    <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ loginData?.password }}</ion-label>
    <ion-input type="password" placeholder="••••"></ion-input>
  </ion-item>
  <ion-button class="button primary" [ngClass]="font?.size">{{ loginData?.loginButton }}</ion-button>
  <p class="forgot-password" >{{ loginData?.forgotPasswordMessage }}</p>
</div>
