import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { REGISTER_ACCOUNT_MODAL } from '@constants/pages-content/register-account.constant';

@Component({
  selector: 'app-modal-bank-register-result',
  templateUrl: './modal-bank-register-result.component.html',
  styleUrls: ['./modal-bank-register-result.component.scss'],
})
export class ModalBankRegisterResultComponent extends ModalComponent {
  public validate: boolean;
  public content = REGISTER_ACCOUNT_MODAL;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.validate = this.params.get('data');
  }

  public get isValid() { return this.validate; }
  public get resolution() { return REGISTER_ACCOUNT_MODAL[this.isValid ? 'success' : 'failure']; }
  public get buttonClass() { return this.isValid ? 'success' : ''; }
}
