import { Mockup } from '@interfaces/mockup.interface';

export const GET_COMMENTS_DATA_MOCK: Mockup = {
  success: [{
    response: {
      totalCount: 31,
      page: 1,
      limit: 10,
      data:
        [
          {
            id: 1,
            createdAt: new Date('2021-03-02'),
            updatedAt: null,
            authorName: 'Cr7',
            message: 'Soy el mejor',
            starred: true,
            internal: true,
          },
          {
            id: 2,
            createdAt: new Date('2021-04-02'),
            updatedAt: null,
            authorName: 'Ronaldinho',
            message: 'Holinha muchachinhos',
            starred: false,
            internal: true,
          },
          {
            id: 3,
            createdAt: new Date('2021-05-02'),
            updatedAt: null,
            authorName: 'Messi',
            message: 'Che pibe',
            starred: false,
            internal: true,
          },
          {
            id: 4,
            createdAt: new Date('2021-05-02'),
            updatedAt: null,
            authorName: 'Mbappe',
            message: 'Le Blue',
            starred: true,
            internal: false,
          },
        ]
    },
  }],
  failures: [
    {
      response: {
        code: 50001,
        folioNumber: null,
        messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.',
        serviceErrorMessage: '',
        message: 'Error',
      },
    },
  ]
};
