<app-quota-values-banner *ngIf="!isAffiliateHome"></app-quota-values-banner>
<app-contact-channels></app-contact-channels>
<app-superintendence></app-superintendence>

<div class="footer-container">
  <div class="footer general-container">
    <div class="columns">
      <div class="section" *ngFor="let column of columns">
        <p *ngIf="!loading" class="column-title" [ngClass]="font?.size" [class.no-title]="!column?.title">{{ column?.title }}</p>
        <ion-skeleton-text *ngIf="loading" class="skeleton-line xsmall title" animated></ion-skeleton-text>
        <span *ngFor="let link of column.links">
          <p class="link">
            <a *ngIf="!loading && link.externo" [href]="link.url" [ngClass]="font?.size" (click)="goToUrl($event, link.url)"
              rel="nofollow" [title]="link.title"
            >{{ link.title }}</a>
            <a *ngIf="!loading && !link.externo" [href]="link.url" [ngClass]="font?.size" (click)="goToUrl($event, link.url)"
              [title]="link.title"
            >{{ link.title }}</a>
          </p>
          <ion-skeleton-text *ngIf="loading" class="skeleton-line xsmall" animated></ion-skeleton-text>
        </span>
      </div>
    </div>
    <div class="download-app-container">
      <h4 class="column-title" [ngClass]="font?.size" [innerHTML]="content?.DOWNLOAD_APP | translate"></h4>
      <div class="store-icons-container" >
        <div *ngFor="let link of content?.LINKS_APP">
          <a href="{{link.url}}" title="{{link.title}}" rel="noopener noreferrer" target=_blank><div class="{{link.icon}}-{{ lang }}"></div></a>
        </div>
      </div>
    </div>
    <div class="certificates">
      <p class="disclaimer" [ngClass]="font?.size" [innerHTML]="content?.CERTIFICATE_DISCLAIMER | translate"></p>
      <div class="certificate-container">
          <div class="logo-container">
            <div *ngFor="let certificate of content?.CERTIFICATES_FIRST;">
              <div class="logo-item" >
                <img class="logo" src="{{ certificate?.url }}"  alt="{{ certificate?.alt }}"/>
             </div>
            </div>
          </div>
            <div class="logo-container">
                <div *ngFor="let certificate of content?.CERTIFICATES_SECOND;">
                    <div class="logo-item" >
                        <img class="logo" src="{{ certificate?.url }}"  alt="{{ certificate?.alt }}"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
