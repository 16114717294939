import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { FormsService } from '@services/forms/forms.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-modal-info-downloadable-formats',
  templateUrl: './modal-info-downloadable-formats.component.html',
  styleUrls: ['./modal-info-downloadable-formats.component.scss']
})
export class ModalInfoDownloadableFormatsComponent extends ModalComponent {
  public modal: any;
  public information: any;
  public url: string;
  public downloadable: Array<any>;
  constructor(
    public font: FontService,
    protected modalCtrl: ModalController,
    private utils: Utils,
    protected params: NavParams,
    private navService: NavigationService,
    private formService: FormsService,
  ) {
    super(params, modalCtrl);
  }

  public async goTo(): Promise<void> {
    if (!this.url) {
      return;
    }

    await this.closeModal();
    this.navService.goTo(this.url);
  }

  public getForm(type: string, nameFile: string) {
    this.formService.getFormsEmployerDiscount({ type })
      .subscribe(
        (response: any) => {
          this.utils.downloadBase64File(response.base64File, nameFile);
        });
  }
}
