import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ModalPowerAttorneyComponent
} from '@components/modal-power-attorney/modal-power-attorney.component';
import { filesActionType } from '@constants/forms.constant';
import {
  ARRAY_POWER_ATTORNEY, INHERITANCE_REQUEST_REQUIRED_FILES, MAX_POWER_ATTORNEY_FILES,
  MIN_POWER_ATTORNEY_FILES
} from '@constants/pages-content/inheritance-request.constants';
import { AttachFiles } from '@interfaces/attach-files.interface';
import {
  AttachmentsRequiredParams, PowerAttorneyDocument
} from '@interfaces/inheritance-request.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-inheritance-request-attachments',
  templateUrl: './inheritance-request-attachments.component.html',
  styleUrls: ['./inheritance-request-attachments.component.scss'],
})
export class InheritanceRequestAttachmentsComponent implements  OnInit {
  @Input() public pageContent;
  @Input() public formValues: AttachmentsRequiredParams;
  @Output() public back = new EventEmitter();
  @Output() public formData: EventEmitter<any> = new EventEmitter();

  public requiredFiles: AttachFiles = { files: INHERITANCE_REQUEST_REQUIRED_FILES };
  public attachedFiles: Array<any> = [];
  public fileKeys: Array<string> = [];
  public fileReloadIndicators: Array<filesActionType> = [];
  public powerAttorneyItems: Array<PowerAttorneyDocument> = [];
  public documentProperties = ARRAY_POWER_ATTORNEY;
  public modalType = ModalPowerAttorneyComponent;
  public modalData: any;
  public maxPowerAttorney = MAX_POWER_ATTORNEY_FILES;

  public get requiredFilesCount(): number { return this.requiredFiles.files.length; }
  public get hasRequiredFiles(): boolean {
    return this.attachedFiles.length >= this.requiredFilesCount - 1 && this.fileKeys.length >= this.requiredFilesCount - 1;
  }
  public get powerAttorneyCount(): number { return this.powerAttorneyItems.length; }
  public get requiredPowerAttorney(): boolean { return this.powerAttorneyCount >= MIN_POWER_ATTORNEY_FILES; }
  public get isValid(): boolean { return this.hasRequiredFiles && this.requiredPowerAttorney; }

  constructor(
    public font: FontService,
  ) { }

  public ngOnInit(): void {
    this.setPowerAttorneyItems();
  }

  public previousStep(): void {
    this.back.emit();
  }

  public confirm(): void {
    if (!this.isValid) { return; }
    const requiredAttachments = {
      kinshipCertificate: this.attachedFiles[0],
      idFile: this.attachedFiles[1],
      deathCertificate: this.attachedFiles[2],
      keyFiles: this.fileKeys,
      fileReloadIndicators: this.fileReloadIndicators,
      powerAttorney: this.powerAttorneyItems
    } as AttachmentsRequiredParams;
    this.formData.emit(requiredAttachments);
  }

  public setFiles(data: any): void {
    if (!data) { return; }
    this.attachedFiles = data.fileList;
    this.fileKeys = data.keyList;
    this.fileReloadIndicators = data.actionList;
  }

  public get filesLoaded(): any {
    if (!this.formValues || !this.formValues.powerAttorney
          || !this.formValues.idFile || !this.formValues.kinshipCertificate) { return null; }
    const filesObject = {
      deathCertificate: this.formValues.deathCertificate,
      idFile: this.formValues.idFile,
      powerAttorney: this.formValues.powerAttorney,
      kinshipCertificate: this.formValues.kinshipCertificate,
    };
    return filesObject;
  }

  public setPowerAttorneyItems(): void {
    if (!this.formValues || !this.formValues.powerAttorney) { return ; }
    this.powerAttorneyItems = this.formValues.powerAttorney.map((attorneyObject) => {
      return {
        typeDocument: 'Poder notarial',
        name: '',
        fileName: attorneyObject.attachedFile.name,
        attachedFile: attorneyObject.attachedFile,
        fileKey: attorneyObject.fileKey,
        action: filesActionType.none,
      };
    });
    this.setDocument(this.powerAttorneyItems);
  }

  public setDocument(document: Array<PowerAttorneyDocument>): void {
    this.powerAttorneyItems = document;
  }
}
