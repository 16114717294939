import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SavingsRoutingModule } from './savings-routing.module';

@NgModule({
  imports: [
    RouterModule,
    SavingsRoutingModule,
  ],
})
export class SavingsModule { }
