<div class="header-desktop-container">
  <div class="logo i-pv-white-logo" (click)="goTo(homeUrl)"></div>
  <div class="options-secondary">
    <div class="options-secondary-container general-container">
      <a [href]="item.url" class="option transparent" [ngClass]="font?.size" [class.selected]="item?.url.includes(defaultModule)" *ngFor="let item of options" (click)="goToWithPreventDefault($event, item.url)">{{ item?.name | translate }}</a>
      <ion-button class="option select i-glove button transparent" [ngClass]="font?.size" (click)="openLanguageOptions($event)">
        {{ languageSelected.name }}
        <ion-icon class="icon" name="chevron-down-outline"></ion-icon>
      </ion-button>
      <app-accessibility></app-accessibility>
      <div class="option">
        <form [formGroup]="searchForm" class="search">
          <ion-input class="input" formControlName="words" placeholder="Buscar..." [ngClass]="font?.size" (keypress)="eventHandler($event.key)"></ion-input>
          <ion-button class="button transparent" (click)="goToSearch()">
            <div class="icon i-search"></div>
          </ion-button>
        </form>
      </div>
    </div>
  </div>
  <div class="options-primary general-container">
    <ion-button [ngClass]="['option button transparent', font?.size]" *ngFor="let item of primaryOptions" (click)="openOptions(item, $event)">{{ item?.name | translate }}</ion-button>
  </div>
</div>
