import { Mockup } from '@interfaces/mockup.interface';

export const GET_MORTUARY_QUOTA_RELATIONSHIPS_MOCK: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            id: 1,
            idioma: 'es',
            cuota_mortuoria: {
              contenido: [
                {
                  id: '1',
                  description: 'CONYUGE'
                },
                {
                  id: '2',
                  description: 'CONYUGE CON HIJOS CON DERECHO A PENSION'
                },
                {
                  id: '3',
                  description: 'MADRE DE HIJOS DE AFILIACION NO MATRIMON'
                },
                {
                  id: '4',
                  description: 'MADRE C/HIJOS AFILIACION NO MATR. PEN.'
                },
                {
                  id: '5',
                  description: 'PADRE'
                },
                {
                  id: '6',
                  description: 'PADRE INVALIDO'
                },
                {
                  id: '7',
                  description: 'MADRE'
                },
                {
                  id: '8',
                  description: 'MADRE VIUDA'
                },
                {
                  id: '9',
                  description: 'HIJO'
                },
                {
                  id: '10',
                  description: 'HIJO INVALIDO'
                },
                {
                  id: '11',
                  description: 'CONYUGE INVALIDO S/HIJOS CON DER. PEN.'
                },
                {
                  id: '12',
                  description: 'CONYUGE INVALIDO C/HIJOS CON DER. PEN.'
                },
                {
                  id: '13',
                  description: 'CONVIVIENTE CIVIL'
                },
                {
                  id: '14',
                  description: 'FUNERARIA'
                }
              ]
            },
            parentesco: 1
          },
          {
            id: 2,
            idioma: 'ht',
            cuota_mortuoria: {
              contenido: [
                {
                  id: '1',
                  description: 'CONYUGE CREOLE'
                },
                {
                  id: '2',
                  description: 'CONYUGE CON HIJOS CREOLE CON DERECHO A PENSION'
                },
                {
                  id: '3',
                  description: 'MADRE DE HIJOS DE AFILIACION NO MATRIMON'
                },
                {
                  id: '4',
                  description: 'MADRE C/HIJOS AFILIACION NO MATR. PEN.'
                },
                {
                  id: '5',
                  description: 'PADRE'
                },
                {
                  id: '6',
                  description: 'PADRE INVALIDO'
                },
                {
                  id: '7',
                  description: 'MADRE'
                },
                {
                  id: '8',
                  description: 'MADRE VIUDA'
                },
                {
                  id: '9',
                  description: 'HIJO'
                },
                {
                  id: '10',
                  description: 'HIJO INVALIDO'
                },
                {
                  id: '11',
                  description: 'CONYUGE INVALIDO S/HIJOS CON DER. PEN.'
                },
                {
                  id: '12',
                  description: 'CONYUGE INVALIDO C/HIJOS CON DER. PEN.'
                },
                {
                  id: '13',
                  description: 'CONVIVIENTE CIVIL'
                },
                {
                  id: '14',
                  description: 'FUNERARIA'
                }
              ]
            },
            parentesco: 1
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
