export const TRANSLATE_PERIOD_KEY_TO_DOCTYPE_KEY = {
    'STUDIES_CERTIFICATE.FORM.PERIODS.FIRST': 'firstSemester',
    'STUDIES_CERTIFICATE.FORM.PERIODS.SECOND': 'secondSemester',
    'STUDIES_CERTIFICATE.FORM.PERIODS.ANNUAL': 'annual' ,
};

export const TRANSLATE_YEAR_KEY_TO_DOCTYPE_KEY = {
    'STUDIES_CERTIFICATE.FORM.YEARS.1': 'firstYear',
    'STUDIES_CERTIFICATE.FORM.YEARS.2': 'secondYear',
    'STUDIES_CERTIFICATE.FORM.YEARS.3': 'thirdYear',
    'STUDIES_CERTIFICATE.FORM.YEARS.4': 'fourthYear',
    'STUDIES_CERTIFICATE.FORM.YEARS.5': 'fifthYear',
    'STUDIES_CERTIFICATE.FORM.YEARS.6': 'sixYear',
    'STUDIES_CERTIFICATE.FORM.YEARS.7': 'other'
};
