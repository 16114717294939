<div class="modal confirm-modal-container" [class.informative]="informative">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal(false)">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="icon-result confirm"></div>
  <div class="text-container">
    <div class="title primary" [ngClass]="font?.size" [innerHTML]="title | translate"></div>
    <p class="dynamic-text subtitle secondary" [ngClass]="font?.size" [innerHTML]="description | translate"></p>
  </div>
  <div class="buttons-container">
    <ion-button class="button primary form-button medium md button-solid" [ngClass]="font?.size" (click)="closeModal(false)">{{ backBtn | translate }}</ion-button>
    <ion-button class="button secondary form-button medium md button-solid" [ngClass]="font?.size" (click)="closeModal(true)">{{ confirmBtn | translate }}</ion-button>
  </div>
</div>
