import { VALIDATORS } from '@constants/forms.constant';
import { ERROR_INPUT_TEXT } from '@constants/person-backgrounds.constant';
import { PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { Voucher } from '@interfaces/voucher.interface';

const pageKey = 'PENSIONER.MORTUARY_QUOTA_REQUEST';
const requestMortuoryQuotaKey = `${pageKey}.STEPS`;
const toastKey = `${pageKey}.TOAST`;

const clientCheckKey = `${requestMortuoryQuotaKey}.CLIENT_CHECK`;
const deceasedBackgroundKey = `${requestMortuoryQuotaKey}.DECEASED_AFFILIATE_BACKGROUND`;
const servicesQuotaKey = `${requestMortuoryQuotaKey}.SERVICES_QUOTA`;
const attachmentsAndPaymentKey = `${requestMortuoryQuotaKey}.ATTACHMENT_PAYMENT`;
const paymentMethodsKey = `${attachmentsAndPaymentKey}.PAYMENT_METHODS`;

const voucherKey = `${pageKey}.STEPS.VOUCHER.SUCCESS`;
const failedSubmissionKey = `${pageKey}.STEPS.VOUCHER.FAILED`;

const savedModal = 'MODALS.SAVED';

export const REQUIRED_FILES = [
  {
    order: 1,
    buttonLabel: `${pageKey}.FILES.BILL`,
    fileIndex: 'bill',
    required: true
  },
  {
    order: 2,
    buttonLabel: `${pageKey}.FILES.DEATH_CERTIFICATE`,
    fileIndex: 'deathCertificate',
    required: true
  },
];

export const FUNERAL_SERVICES_FIELD = {
  fields: {
    names: `${servicesQuotaKey}.FORM.FIELDS.NAMES`,
    lastName: `${servicesQuotaKey}.FORM.FIELDS.LAST_NAME`,
    secondSurname: `${servicesQuotaKey}.FORM.FIELDS.SECOND_SURNAME`,
    rut: `${servicesQuotaKey}.FORM.FIELDS.RUT`,
    cellphone: `${servicesQuotaKey}.FORM.FIELDS.CELLPHONE`,
    email: `${servicesQuotaKey}.FORM.FIELDS.EMAIL`,
    relationship: `${servicesQuotaKey}.FORM.FIELDS.RELATIONSHIP.LABEL`,
    gender: {
      label: `${servicesQuotaKey}.FORM.FIELDS.GENDER.LABEL`,
      female: `${servicesQuotaKey}.FORM.FIELDS.GENDER.FEMALE`,
      male: `${servicesQuotaKey}.FORM.FIELDS.GENDER.MALE`,
    }
  },
  errors: {
    ...ERROR_INPUT_TEXT.errors,
    mustMatchRut: `${servicesQuotaKey}.ERRORS.MUST_MATCH_RUT`,
  }
};

export const MORTUARY_QUOTA_REQUEST_CONTENT = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  introduction: {
    title: `${pageKey}.INTRODUCTION.TITLE`,
    description: `${pageKey}.INTRODUCTION.DESCRIPTION`,
    buttonAction: `${pageKey}.INTRODUCTION.BUTTON_ACTION`,
  },
  folio: `${pageKey}.FOLIO`,
  requestProcess: `${pageKey}.REQUEST_PROCESS`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`,
  },
  steps: {
    clientCheck: {
      label: `${clientCheckKey}.LABEL`
    },
    deceasedBackground: {
      label: `${deceasedBackgroundKey}.LABEL`,
      title: `${deceasedBackgroundKey}.TITLE`,
      description: `${deceasedBackgroundKey}.DESCRIPTION`,
      buttons: {
        next: `${deceasedBackgroundKey}.BUTTONS.NEXT`,
      },
    },
    servicesQuota: {
      label: `${servicesQuotaKey}.LABEL`,
      buttons: {
        next: `${servicesQuotaKey}.BUTTONS.NEXT`,
        back: `${servicesQuotaKey}.BUTTONS.BACK`,
        saveContinueLater: `${servicesQuotaKey}.BUTTONS.SAVE_CONTINUE_LATER`,
      },
      form: {
        funeralServices: {
          title: `${servicesQuotaKey}.FORM.FUNERAL_SERVICES.TITLE`,
          description: `${servicesQuotaKey}.FORM.FUNERAL_SERVICES.DESCRIPTION`,
        },
        quotaPayment: {
          title: `${servicesQuotaKey}.FORM.QUOTA_PAYMENT.TITLE`,
          description: `${servicesQuotaKey}.FORM.QUOTA_PAYMENT.DESCRIPTION`,
        }
      }
    },
    attachmentPayment: {
      label: `${attachmentsAndPaymentKey}.LABEL`,
      attachment: {
        title: `${attachmentsAndPaymentKey}.ATTACHMENT.TITLE`,
      },
      paymentMethods: {
        disclaimer: `${paymentMethodsKey}.DISCLAIMER`,
        rutDisclaimer: `${paymentMethodsKey}.RUT_DISCLAIMER`,
        accountDisclaimer: `${paymentMethodsKey}.VIRTUAL_ACCOUNT_DISCLAIMER`,
        formSection: {
          title: `${paymentMethodsKey}.FORM_SECTION.TITLE`,
          transfer: `${paymentMethodsKey}.FORM_SECTION.TRANSFER`,
          cash: `${paymentMethodsKey}.FORM_SECTION.CASH`,
          bank: {
            field: `${paymentMethodsKey}.FORM_SECTION.BANK.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.BANK.OPTIONS`,
          },
          account: {
            field: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.FIELD`,
            options: `${paymentMethodsKey}.FORM_SECTION.ACCOUNT.OPTIONS`,
          },
          other: {
            options: `${paymentMethodsKey}.FORM_SECTION.OTHER.OPTIONS`
          }
        },
        errors: {
          ...ERROR_INPUT_TEXT.errors,
          required: `${paymentMethodsKey}.ERRORS.REQUIRED`,
          accountNumber: `${paymentMethodsKey}.ERRORS.ACCOUNT_NUMBER`,
          patternVirtualAccount: `${paymentMethodsKey}.ERRORS.VIRTUAL_ACCOUNT`,
        },
      },
      buttons: {
        next: `${attachmentsAndPaymentKey}.BUTTONS.NEXT`,
        back: `${attachmentsAndPaymentKey}.BUTTONS.BACK`,
      },
    },
    voucher: {
      label: `${voucherKey}.LABEL`,
    },
  },
};



export const MORTUARY_QUOTA_REQUEST_VOUCHER: Voucher = {
  title: `${voucherKey}.TITLE`,
  description: `${voucherKey}.DESCRIPTION`,
  homeButton: `${voucherKey}.BUTTON`,
  download: `${voucherKey}.DOWNLOAD_PDF`,
};

export const MORTUARY_QUOTA_REQUEST_FAILED_SUBMISSION = {
  title: `${failedSubmissionKey}.TITLE`,
  description: `${failedSubmissionKey}.DESCRIPTION`,
  firstBtnText: `${failedSubmissionKey}.BUTTON`,
  iconName: 'i-error',
};

export const MORTUARY_QUOTA_PARTIAL_REQUEST_SAVED = {
  title: `${savedModal}.TITLE`,
  description: `${savedModal}.DESCRIPTION`,
  firstBtnText: `${savedModal}.BUTTON`,
  iconName: 'i-saved',
};

export const ERRORS_INPUT = {
  errors: {
    required: `${pageKey}.ERRORS_INPUT.REQUIRED`,
    patternLetters: `${pageKey}.ERRORS_INPUT.PATTERN_LETTERS`,
    patternNumbers: `${pageKey}.ERRORS_INPUT.PATTERN_NUMBERS`,
    patternAddress: `${pageKey}.ERRORS_INPUT.PATTERN_ADDRESS`,
    patternAccount: `${pageKey}.ERRORS_INPUT.PATTERN_ACCOUNT`,
    patternRutAccount: `${pageKey}.ERRORS_INPUT.PATTERN_RUT_ACCOUNT`,
    patternVirtualAccount: `${pageKey}.ERRORS_INPUT.PATTERN_VIRTUAL_ACCOUNT`,
    patternMatch: `${pageKey}.ERRORS_INPUT.PATTERN_MATCH`,
    invalidRut: `${pageKey}.ERRORS_INPUT.INVALID_RUT`,
    mustMatchRut: `${pageKey}.ERRORS_INPUT.MUST_MATCH_RUT`,
    invalidPhone: `${pageKey}.ERRORS_INPUT.INVALID_PHONE`,
    invalidEmail: `${pageKey}.ERRORS_INPUT.INVALID_EMAIL`,
    invalidEmailDomain: `${pageKey}.ERRORS_INPUT.INVALID_EMAIL_DOMAIN`,
  },
};

const MORTUARY_QUOTA_BASE_PERSON_BACKGROUNDS = [
  { key: 'names' },
  { key: 'secondSurname' },
  { key: 'rut' },
  { key: 'relationship' },
  { key: 'cellphone' },
  { key: 'email' },
] as Array<PersonBackgroundsField>;

export const RESUME_AFFILIATE_PERSON_BACKGROUNDS = [
  ...MORTUARY_QUOTA_BASE_PERSON_BACKGROUNDS,
  { key: 'lastName' },
  { key: 'gender' },
];

export const QUOTA_PAYMENT_PERSON_BACKGROUNDS = [
  ...MORTUARY_QUOTA_BASE_PERSON_BACKGROUNDS,
  { key: 'lastName', validators: VALIDATORS.OPTIONAL_NAME },
  { key: 'gender', validators: [] },
];

export const FUNERARY_ID = '14';
