import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DECLARATION_REQUIRED_FILES } from '@constants/pages-content/charging-fees.constants';
import { PENSION_MODALITY } from '@constants/pension-backgrounds.constant';
import { AttachFiles } from '@interfaces/attach-files.interface';
import { ChargingFeesDocumentation } from '@interfaces/charging-fees.interface';
import { FontService } from '@providers/font/font.service';
import { attachFileCount } from '@validators/attach-file.validator';

@Component({
  selector: 'app-charging-fees-documentation',
  templateUrl: './charging-fees-documentation.component.html',
  styleUrls: ['../charging-fees.page.scss'],
})
export class ChargingFeesDocumentationComponent implements OnInit {
  @Input() public pageContent;
  @Input() public pensionModality: string;
  @Input() public formValues;
  @Output() public formData: EventEmitter<ChargingFeesDocumentation> = new EventEmitter();
  @Output() public goBack = new EventEmitter();
  public form: UntypedFormGroup;
  public requiredFiles: AttachFiles;
  public cleanFiles;

  public get requestFiles(): boolean { return Boolean(this.requiredFiles) && this.requiredFiles.files.length > 0; }
  public get attachedFiles(): UntypedFormArray { return this.form.get('attachedFiles') as UntypedFormArray; }
  public get selectedPensionModality(): string { return this.pensionModality ? PENSION_MODALITY[this.pensionModality].title : ''; }

  private attachmentsDetails;
  private dataLoaded = false;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      declaration: [null, Validators.requiredTrue],
      attachedFiles: this.formBuilder.array([], attachFileCount(4))
    });
    this.loadFiles();

    const preloadData = (Object.values(this.formValues.attachedFiles));
    const haveData = preloadData.every(e => e !== null);

    if (haveData) {

      this.cleanFiles = this.formValues.attachedFiles;
      this.form.controls['declaration'].patchValue(true);
      this.dataLoaded = true;
    }
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public confirm(): void {
    if (this.form.invalid) { return; }
    const data = {
      attachedFiles: this.attachedFiles.value,
      details: this.attachmentsDetails,
      isLoaded: this.dataLoaded
    } as ChargingFeesDocumentation;
    this.formData.emit(data);
  }

  public setFiles(data: any): void {
    this.attachedFiles.clear();
    data.fileList.forEach((file: File) => this.attachedFiles.push(new UntypedFormControl(file)));
    this.attachmentsDetails = data;
  }

  private loadFiles(): void {
    this.requiredFiles = {
      files: Object.entries(DECLARATION_REQUIRED_FILES).map(entry => entry[1])
    };
  }
}
