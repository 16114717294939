<div class="content-container general-container">
  <div class="content-section">
    <app-search-paperwork-by-field
      (formData)="searchPaperworkByParams($event)"
      [pageContent]= "pageContent?.validateUser">
    </app-search-paperwork-by-field>
  </div>
  <div class="content-section">
    <app-alert type="info" [message]="pageContent?.alert | translate"></app-alert>
  </div>
  <div class="content-section">
    <app-paginated-table *ngIf="detailRoute"
      defaultSort="date"
      [inputData]="paperworkData"
      [columnsData]="columnsData"
      [pageContent]="pageContent?.simplyLanguage"
      [detailRoute]="detailRoute">
    </app-paginated-table>
  </div>
</div>
