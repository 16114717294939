<div class="title-container">
  <div>
    <h1 class="title" [ngClass]="font?.size">{{ pensionTypes?.title | translate }}</h1>
    <p class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="pensionTypes?.description | translate"></p>
  </div>
  <ion-button (click)="goPaperworkFollowUp()" class="button primary main">{{ pensionTypes?.goTo | translate }}</ion-button>
</div>
<div class="info-card-container">
  <app-info-card
    class="info-card primary"
    alignment="center"
    [card]="card"
    [page]="cardPage"
    *ngFor="let card of pensionTypes?.moreInfoCards">
  </app-info-card>
</div>
<app-disclaimer-commision></app-disclaimer-commision>
