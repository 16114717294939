import { PrimaryOption } from '@interfaces/header.interface';

const sectionKey = 'EMPLOYER.HOME';
const tabs = 'TABS';

export const EMPLOYER_ROLE: PrimaryOption = {
  name: `${sectionKey}.${tabs}.EMPLOYER`,
  url: 'employer',
};

export const ROLES_TABS: Array<PrimaryOption> = [
  EMPLOYER_ROLE,
  {
    name: `${sectionKey}.${tabs}.EMPLOYEE`,
    url: 'employee',
  },
];

export const TYPE_TABS: Array<PrimaryOption> = [
  EMPLOYER_ROLE,
  {
    name: `${sectionKey}.${tabs}.PEOPLE`,
    url: 'person',
  },
];

export const PREFIX = `${sectionKey}.PREFIX`;
