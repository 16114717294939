import { Component, EventEmitter, Output } from '@angular/core';
import { CAI_FORM as caiForm } from '@pages-content/cai.constant';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-reason-step',
  templateUrl: './reason-step.component.html',
  styleUrls: ['./reason-step.component.scss'],
})
export class ReasonStepComponent {
  @Output() public reasonSelected = new EventEmitter<number>();
  @Output() private previous = new EventEmitter();
  public content = caiForm.reasonStep;
  public buttons = caiForm.buttons;

  constructor(
    public font: FontService,
  ) { }

  public setReason(id: number) {
    this.reasonSelected.emit(id);
  }

  public goBack() {
    this.previous.emit();
  }

}
