import { PensionOffers } from '@interfaces/future-pensioner.interface';
import { MORE_INFO } from './general.constant';

const pageKey = 'AFFILIATE.ASSESSMENT.FUTURE_PENSIONER.OFFER';

export const PENSION_OFFERS_CONTENT: PensionOffers = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  processTimeline: {
    title: `${pageKey}.TIMELINE.TITLE`,
    steps: [
      {
        title: `${pageKey}.TIMELINE.STEPS.1.TITLE`,
        moreInformation: MORE_INFO,
        label: `${pageKey}.TIMELINE.STEPS.1.LABEL`,
        description: `${pageKey}.TIMELINE.STEPS.1.DESCRIPTION`,
      },
      {
        title: `${pageKey}.TIMELINE.STEPS.2.TITLE`,
        moreInformation: MORE_INFO,
        label: `${pageKey}.TIMELINE.STEPS.2.LABEL`,
        description: `${pageKey}.TIMELINE.STEPS.2.DESCRIPTION`,
      },
      {
        title: `${pageKey}.TIMELINE.STEPS.3.TITLE`,
        moreInformation: MORE_INFO,
        label: `${pageKey}.TIMELINE.STEPS.3.LABEL`,
        description: `${pageKey}.TIMELINE.STEPS.3.DESCRIPTION`,
      },
      {
        title: `${pageKey}.TIMELINE.STEPS.4.TITLE`,
        moreInformation: MORE_INFO,
        description: `${pageKey}.TIMELINE.STEPS.4.DESCRIPTION`,
      },
      {
        title: `${pageKey}.TIMELINE.STEPS.5.TITLE`,
        moreInformation: MORE_INFO,
        description: `${pageKey}.TIMELINE.STEPS.5.DESCRIPTION`,
      },
    ],
  },
  disclaimers: {
    title: `${pageKey}.DISCLAIMERS.TITLE`,
    sections: [
      {
        title: `${pageKey}.DISCLAIMERS.SECTIONS.ACCEPTANCE.TITLE`,
        description: `${pageKey}.DISCLAIMERS.SECTIONS.ACCEPTANCE.DESCRIPTION`,
      },
      {
        title: `${pageKey}.DISCLAIMERS.SECTIONS.AUCTION.TITLE`,
        description: `${pageKey}.DISCLAIMERS.SECTIONS.AUCTION.DESCRIPTION`,
      },
      {
        title: `${pageKey}.DISCLAIMERS.SECTIONS.EXTERNAL_OFFER.TITLE`,
        description: `${pageKey}.DISCLAIMERS.SECTIONS.EXTERNAL_OFFER.DESCRIPTION`,
      },
      {
        title: `${pageKey}.DISCLAIMERS.SECTIONS.OFFER_REJECTION.TITLE`,
        description: `${pageKey}.DISCLAIMERS.SECTIONS.OFFER_REJECTION.DESCRIPTION`,
      },
    ],
  }
};
