import { distinctUntilChanged } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  EMPLOYER_NAME_MAX_LENGTH, PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN,
  RUT_MAX_LENGTH
} from '@constants/forms.constant';
import { ButtonsCaiForm, ErrorsCaiForm, LabelsCaiForm } from '@interfaces/cai.interface';
import { Region, Section, SelectOption } from '@interfaces/general.interface';
import { CAI_FORM as caiForm } from '@pages-content/cai.constant';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';

@Component({
  selector: 'app-employer-info-step',
  templateUrl: './employer-info-step.component.html',
  styleUrls: ['./employer-info-step.component.scss'],
})
export class EmployerInfoStepComponent implements OnInit {
  @Input() public form: UntypedFormGroup;
  @Input() public regions: Array<Region> = [];
  @Input() public hasReasonDate = false;
  @Input() public hasStartWorkDate = false;
  @Output() public next = new EventEmitter();
  @Output() public previous = new EventEmitter();

  public content: Section = caiForm.employerInfoStep;
  public labels: LabelsCaiForm = caiForm.labels;
  public errors: ErrorsCaiForm = caiForm.errors;
  public buttons: ButtonsCaiForm = caiForm.buttons;
  public namesMaxLength = EMPLOYER_NAME_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public maxDate = new Date();

  public communes: Array<SelectOption> = [];

  public get rut() { return this.form.get('employerRut'); }
  public get region() { return this.form.get('employerRegion'); }
  public get commune() { return this.form.get('employerCommune'); }
  public get phone() { return this.form.get('employerPhone'); }
  public get email() { return this.form.get('employerEmail'); }
  public get startReasonDateInput() { return this.form.get('startReasonDateInput'); }
  public get endReasonDateInput() { return this.form.get('endReasonDateInput'); }

  public get maxReasonDate() { return this.endReasonDateInput.value || this.maxDate; }

  constructor(
    public font: FontService,
    private formUtils: FormUtils,
  ) { }

  ngOnInit() {
    this.regionSubscription();
  }

  private regionSubscription(): void {
    this.commune.disable();
    this.region.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        () => { this.communes = this.formUtils.getCommunes(this.commune, this.region, this.regions); }
      );
  }

  public getEmailInputErrorMessage(): string {
    const requiredEmail = this.requiredError('employerEmail');
    const invalidEmail = this.patternError('employerEmail') || this.email.hasError('employerEmail');
    return requiredEmail ? this.errors.required : invalidEmail ? this.errors.invalidEmail : this.errors.invalidEmailDomain;
  }

  public async validateEmailDomain(): Promise<void> {
    return await this.formUtils.validateEmailDomain(this.email);
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }

  public dateSelected(formControlName: string): void {
    this.formUtils.setDateSelected(this.form, formControlName);
  }

  public goPreviousStep(): void {
    this.previous.emit();
  }

  public goNextStep(): void {
    this.next.emit();
  }

}
