<div class="table-container">
  <div class="row header" [ngClass]="font?.size">
    <p *ngFor="let property of properties" class="column" [ngClass]="font?.size" [innerHtml]="data?.header[property] | translate"></p>
  </div>
  <div class="items" *ngFor="let item of data?.items">
    <div class="row">
      <p *ngFor="let property of properties" class="column" [ngClass]="font?.size">{{ item[property] }}</p>
    </div>
  </div>
</div>

<div class="table-mobile-container">
  <div *ngFor="let item of data?.items">
    <div *ngFor="let property of properties; let index = index;">
      <div class="row header" *ngIf="index === 0">
        <p class="column" [ngClass]="font?.size"> {{ data?.header[property] | translate }} {{ item[property] }}</p>
        <p class="column" [ngClass]="font?.size"></p>
      </div>
      <div class="row" *ngIf="index !== 0">
        <p class="column" [ngClass]="font?.size" [innerHtml]="data?.header[property] | translate"></p>
        <p class="column" [ngClass]="font?.size">{{ item[property] }}</p>
      </div>
    </div>
  </div>
</div>
