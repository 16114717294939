import { Mockup } from '@interfaces/mockup.interface';

export const SAVE_SIMULATION_RESULT_MOCK: Mockup = {
  success: [{
    response: {
      id: '1234567891234'
    }
  }],
  failures: [{
    response: {
      statusCode: 400,
    }
  }],
};

export const UPDATE_SIMULATION_RESULT_MOCK: Mockup = {
  success: [{
    response: {}
  }],
  failures: [{
    response: {
      statusCode: 400,
    }
  }],
};

export const SIMULATION_RESULT_ATTENTION_MOCK: Mockup = {
  success: [{
    response: {}
  }],
  failures: [{
    response: {
      statusCode: 400,
    }
  }],
};
