<div class="summary-modal-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="title" [ngClass]="font?.size" [innerHTML]="title"></div>
  <div class="quota-values">
    <div class="quota" *ngFor="let quota of monthlyQuotas">
      <p class="date" [ngClass]="font?.size">{{ quota.date }}</p>
      <p class="value" [ngClass]="font?.size">{{ quota.value | currency: 'es':'$':'1.2-2' }}</p>
    </div>
  </div>
  <ion-button class="button primary back" [ngClass]="font?.size" (click)="closeModal()">Volver</ion-button>
</div>
