<div class="investor-profile-box"
  [ngClass]="stepper.selectedIndex > questions.length ? 'result' : stepper.selectedIndex > 0 ? 'quiz': ''">
  <div class="box">
    <div class="stepper-back-container">
      <div class="button desktop" [ngClass]="font?.size" (click)="backStep()"
        *ngIf="stepper.selectedIndex < questions.length + 1 && stepper.selectedIndex > 1">
        <div class="i-back-gray"></div>
        <span>{{ utilsContent.backButtonText | translate }} {{ stepper.selectedIndex - 1 }}</span>
      </div>
      <div class="button mobile" [ngClass]="font?.size" (click)="backStep()"
        *ngIf="stepper.selectedIndex < questions.length + 1 && stepper.selectedIndex > 0">
        <div class="i-back-dark"></div>
      </div>
    </div>
    <mat-horizontal-stepper class="stepper" linear #stepper>
      <mat-step [stepControl]="personalInformationGroup">
        <div class="title-container">
          <div class="i-dollar"></div>
          <h2 class="title" [ngClass]="font?.size" [innerHTML]="boxData.title | translate"></h2>
          <ng-container>
            <div class="subtitle" [ngClass]="font?.size" [innerHTML]="boxData.description | translate"></div>
          </ng-container>
        </div>
        <form class="simulator-form personal-form" [formGroup]="personalInformationGroup">
          <div class="item-form">
            <ion-item class="question-box" [ngClass]="font?.size">
              <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData.fullName | translate
                }}</ion-label>
              <ion-input formControlName="name" placeholder="Juan Perez" minlength="3" required></ion-input>
            </ion-item>
          </div>
          <div class="item-form">
            <ion-item class="question-box" [ngClass]="font?.size">
              <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData.rut | translate
                }}</ion-label>
              <ion-input formControlName="rut" type="text" maxLength="12" [formatRut]="rut" placeholder="17657984-0"
                required></ion-input>
            </ion-item>
          </div>
          <div class="item-form">
            <ion-item class="question-box" [ngClass]="font?.size">
              <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData.email | translate
                }}</ion-label>
              <ion-input formControlName="email" placeholder="Juanperez@gmail.com" required></ion-input>
            </ion-item>
          </div>
          <div class="item-form">
            <ion-item class="question-box" [ngClass]="font?.size">
              <ion-label class="label" [ngClass]="font?.size" position="stacked">{{ boxData.phone | translate
                }}</ion-label>
              <ion-input formControlName="phone" placeholder="9 88898 6000"></ion-input>
            </ion-item>
          </div>
        </form>
        <div class="actions-container right">
          <div class="button-container">
            <ion-button class="button primary" [ngClass]="font?.size" [disabled]="personalInformationGroup.invalid"
              (click)="startQuiz();">
              {{ utilsContent.startButtonText | translate }}
            </ion-button>
          </div>
        </div>
      </mat-step>

      <mat-step [stepControl]="stepControl(index)" *ngFor="let question of questions; let index=index; let last=last">
        <div class="quiz-container">
          <div class="i-dollar"></div>
          <form [formGroup]="answersGroup">
            <p class="question subtitle dynamic-text secondary" [ngClass]="font?.size">{{ question.description |
              translate }}</p>
            <mat-radio-group class="alternatives" formControlName="{{ answerControlName(index) }}">
              <mat-radio-button class="answer" [value]="answer.value" *ngFor="let answer of questions[index].options"
                required>
                <div class="subtitle dynamic-text secondary" [ngClass]="font?.size"> {{ answer.description | translate
                  }}</div>
              </mat-radio-button>
            </mat-radio-group>
          </form>
          <div class="actions-container">
            <div class="quiz-step-container">
              <div class="quiz-step" [ngClass]="font?.size"><strong [ngClass]="font?.size">{{ index + 1 }}</strong> de
                {{ questions.length }}</div>
            </div>
            <div class="button-container">
              <ion-button class="button action primary next-step" *ngIf="!last" [ngClass]="font?.size"
                (click)="nextStep()" [disabled]="invalidStepForm(index)">
                {{ utilsContent.nextButtonText | translate }}
              </ion-button>
              <ion-button class="button action primary" *ngIf="last" [ngClass]="font?.size"
                (click)="calculateInvestorProfile()" [disabled]="invalidStepForm(index)">
                {{ utilsContent.processButtonText | translate }}
              </ion-button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step>
        <div class="investor-profile">
          <div class="recommended-fund">
            <div class="recommended-fund-box">
              <div class="name-container">
                <div class="fullname" [ngClass]="font?.size">{{ name.value }}</div>
                <div class="description" [ngClass]="font?.size" [innerHTML]="profile?.description | translate"></div>
                <div class="level-container">
                  <div class="fund-char" [ngClass]="[fundBackgroundClass, font?.size]">
                    <span>{{ profile?.fund.toUpperCase() }}</span>
                  </div>
                  <div class="fund-defails">
                    <div class="fund-title" [ngClass]="font?.size">{{ profile?.risk | translate }}</div>
                    <div class="fund-description" [ngClass]="font?.size">{{ profile?.riskDescription | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="recommended-fund-box">
              <div class="profile-container">
                <div class="title" [ngClass]="font?.size">{{ utilsContent.detailsProfile | translate }}</div>
                <div class="description" [ngClass]="font?.size" [innerHTML]="profile?.detailsDescription | translate">
                </div>
              </div>
            </div>
          </div>
          <div class="investor-profile-content">
            <p class="investor-info" [ngClass]="font?.size" [innerHTML]="profile?.chartTitle | translate"></p>
            <p class="investor-title" [ngClass]="font?.size">
              {{ utilsContent.profitability | translate }} <span [ngClass]="fundColorClass">{{ profile?.profitability |
                translate }}</span><span class="icon info" (click)="openInfo($event)"></span>
            </p>
            <app-chart-investor-profile class="investor-chart" *ngIf="chartParams" [chartParams]="chartParams"
              [selectedFundId]="selectedFundId"></app-chart-investor-profile>
            <p class="investor-disclaimer" [ngClass]="font?.size" [innerHTML]="utilsContent.disclaimer | translate"></p>

            <div [ngClass]="stepper.selectedIndex > 0 ? 'investor-actions' : ''">
              <div class="investor-action">
                <p class="label" [ngClass]="font?.size">{{ utilsContent.changeFundButtonText | translate }}</p>
                <div class="button-container">
                  <ion-button class="button action primary" [ngClass]="font?.size" (click)="updateSimulation()"
                    [disabled]="loadingSimulationResultId" [innerHTML]="textContent.transferButtonText | translate">
                  </ion-button>
                </div>
              </div>
              <div class="investor-action">
                <p class="label" [ngClass]="font?.size">{{ utilsContent.newClientButtonText | translate }}</p>
                <div class="button-container">
                  <ion-button class="button action secondary" [ngClass]="font?.size"
                    (click)="updateSimulation('transfer')" [disabled]="loadingSimulationResultId"
                    [innerHTML]="textContent.loginButtonText | translate">
                  </ion-button>
                </div>
              </div>
            </div>

            <div class="investor-back-container">
              <div class="investor-back-button" [ngClass]="font?.size" (click)="resetForm()">
                <em class="investor-back-icon"></em>{{ utilsContent.againButtonText | translate }}
              </div>
            </div>
          </div>

        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>