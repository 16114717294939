import { Component, Input } from '@angular/core';
import { Voucher } from '@interfaces/voucher.interface';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { FilesUtil } from '@utils/files.utils';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent {
  @Input() public voucher: Voucher;
  @Input() public customRouteButton: string;
  @Input() public homeRoute: string;
  @Input() public showDescription: boolean;
  @Input() public file: string;

  public NAME_DEFAULT_FILE = 'formulario.pdf';

  constructor(
    private filesUtil: FilesUtil,
    public font: FontService,
    private navService: NavigationService,
  ) { }

  public goTo(url: string): void {
    this.navService.goTo(url);
  }

  public downloadPdf(): void {
    this.filesUtil.downloadBase64File(this.file, this.NAME_DEFAULT_FILE);
  }
}
