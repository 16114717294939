import { environment } from '@environment';
import { Item } from '@interfaces/general.interface';
import { HeaderOption } from '@interfaces/header.interface';
import { pensionerAssessmentPapework } from './routes.constant';
import { SAVINGS_TABS } from './savings.constant';
import * as routes from './titles.constant';

export const LANGUAGES: Array<Item> = [
  { id: 'es', name: 'Español' },
  { id: 'ht', name: 'Kreyòl' },
];

export const LOGIN_OPTION: HeaderOption = {
  name: routes.LOGIN_TITLES.name,
  url: environment.privateSiteUrl,
  actualUrl: environment.loginUrl,
  isModal: true,
};

export const HOME_URL: { [key: string]: string } = {
  affiliate: 'afiliado/inicio',
  pensioner: 'pensionado/inicio',
  employer: 'empleador/inicio',
};

export const AFFILIATE_PRIMARY_OPTIONS: Array<HeaderOption> = [
  {
    name: routes.TRANSFER_TITLES.name,
    options: [
      {
        name: routes.TRANSFER_TITLES.content.wantTransfer,
        url: 'https://traspaso.planvital.cl/',
      },
      {
        name: routes.TRANSFER_TITLES.content.shortWhy,
        url: 'afiliado/traspaso/por-que-cambiarme',
      },
      {
        name: routes.TRANSFER_TITLES.content.state,
        url: 'afiliado/traspaso/estado',
      }
    ]
  },
  {
    name: routes.SAVINGS_TITLES.name,
    options: SAVINGS_TABS,
  },
  {
    name: routes.ASSESSMENT_TITLES.name,
    options: [
      {
        name: routes.ASSESSMENT_TITLES.content.financial,
        url: 'afiliado/asesoria/informacion-financiera',
      },
      {
        name: routes.ASSESSMENT_TITLES.content.multifunds,
        url: 'afiliado/asesoria/multifondos',
      },
      {
        name: routes.ASSESSMENT_TITLES.content.futurePensioner,
        url: 'afiliado/asesoria/futuro-pensionado',
      },
      {
        name: routes.ASSESSMENT_TITLES.content.simulators,
        url: 'afiliado/asesoria/simuladores',
      },
      {
        name: routes.ASSESSMENT_TITLES.content.certificates,
        url: 'afiliado/asesoria/certificados',
      },
      {
        name: routes.ASSESSMENT_TITLES.content.paperwork,
        url: 'afiliado/asesoria/centro-tramites',
      },
      {
        name: routes.ASSESSMENT_TITLES.content.vitalGuide,
        url: 'https://tuguiavital.com/',
      },
    ],
  },
  LOGIN_OPTION,
];

export const PENSIONER_PRIMARY_OPTIONS: Array<HeaderOption> = [
  {
    name: routes.PENSIONER_PAYMENT_TITLES.name,
    url: 'pensionado/pago-pension',
  },
  {
    name: routes.BENEFITS_TITLES.name,
    url: 'pensionado/beneficios',
  },
  {
    name: routes.PENSIONER_ASSESSMENT_TITLES.name,
    options: [
      {
        name: routes.PENSIONER_ASSESSMENT_TITLES.content.paperwork,
        url: pensionerAssessmentPapework,
      },
      {
        name: routes.ASSESSMENT_TITLES.content.vitalGuide,
        url: 'https://tuguiavital.com/',
      },
    ],
  },
  LOGIN_OPTION,
];

export const EMPLOYER_PRIMARY_OPTIONS: Array<HeaderOption> = [
  {
    name: routes.EMPLOYER_SERVICES_TITLES.name,
    options: [
      {
        name: routes.EMPLOYER_SERVICES_TITLES.content.passwordCertificates,
        url: 'empleador/servicios/clave',
      },
      {
        name: routes.EMPLOYER_SERVICES_TITLES.content.agreedDeposits,
        url: 'empleador/servicios/depositos-convenidos',
      },
      {
        name: routes.EMPLOYER_SERVICES_TITLES.content.validateCertificate,
        url: 'afiliado/asesoria/certificados/validar',
      },
      {
        name: routes.EMPLOYER_SERVICES_TITLES.content.paymentOptions,
        url: 'empleador/servicios/modalidades-pago',
      },
      {
        name: routes.EMPLOYER_SERVICES_TITLES.content.excessPayment,
        url: 'empleador/servicios/pago-exceso',
      },
    ],
  },
  {
    name: routes.EMPLOYER_PAYMENT_TITLES.name,
    options: [
      {
        name: routes.EMPLOYER_PAYMENT_TITLES.content.paymentSheets,
        url: 'empleador/pago-cotizaciones/planillas',
      },
      {
        name: routes.EMPLOYER_PAYMENT_TITLES.content.currentFees,
        url: 'empleador/pago-cotizaciones/comisiones',
      },
    ],
  },
  {
    name: routes.EMPLOYER_INFO_TITLES.name,
    options: [
      {
        name: routes.EMPLOYER_INFO_TITLES.content.familyAssignment,
        url: 'empleador/informacion/asignacion-familiar',
      },
      {
        name: routes.EMPLOYER_INFO_TITLES.content.employeeTypes,
        url: 'empleador/informacion/tipos-trabajadores',
      },
      {
        name: routes.EMPLOYER_INFO_TITLES.content.youngEmployeeSubsidy,
        url: 'empleador/informacion/subsidio-trabajador-joven',
      },
      // {
      //   name: routes.EMPLOYER_INFO_TITLES.content.contributionsPaymentNov,
      //   url: 'empleador/informacion/pago-cotizaciones-noviembre',
      // },
    ],
  },
  LOGIN_OPTION,
];

export const HEADER_OPTIONS: Array<HeaderOption> = [
  {
    name: routes.AFFILIATE_MODULE.affiliate.name,
    url: HOME_URL.affiliate,
    options: AFFILIATE_PRIMARY_OPTIONS,
  },
  {
    name: routes.PENSIONER_MODULE.pensioner.name,
    url: HOME_URL.pensioner,
    options: PENSIONER_PRIMARY_OPTIONS,
  },
  {
    name: routes.EMPLOYER_MODULE.employer.name,
    url: HOME_URL.employer,
    options: EMPLOYER_PRIMARY_OPTIONS,
  },
];
