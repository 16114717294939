<ion-button class="accessibility-button select i-accessibility button transparent" [ngClass]="font?.size" [class.invert-colors]="isRedBackground" 
  [ngClass]="font?.size" (click)="$event.stopPropagation()" [matMenuTriggerFor]="beforeAccessibilityMenu">
  <p class="accessibility-title">{{ pageContent?.title | translate }}</p>
  <span class="i-arrow-down-gray"></span>
</ion-button>

<mat-menu class="accessibility-menu" #beforeAccessibilityMenu="matMenu" xPosition="before">
  <div class="accesibility-options">
    <div class="option" [ngClass]="font?.size" (click)="changeFontSize('bigger'); $event.stopPropagation()">
      <span class="icon i-a-plus"></span>
    </div>
    <div class="option" [ngClass]="font?.size" (click)="changeFontSize('smaller'); $event.stopPropagation()">
      <span class="icon i-a-minus"></span>
    </div>
    <div class="option" [ngClass]="font?.size" (click)="toggleContrast(); $event.stopPropagation()">
      <span class="icon i-high-contrast"></span>
    </div>
  </div>
</mat-menu>
