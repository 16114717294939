<form class="form" [formGroup]="form" *ngIf="validForm" (ngSubmit)="sendForm()">
  <div class="form-section">
    <h1 class="title-secondary size-medium" [ngClass]="font?.size">{{ pageContent?.formSection.title | translate }}</h1>

    <mat-radio-group class="options payment-options custom-border" (change)="paymentTypeChanged($event)"
      *ngIf="allowOtherMethods">
      <div class="payment-option" [class.selected]="isTransferPayment">
        <div class="left-radio-button">
          <mat-radio-button class="radio-button" [value]="'transfer'" [checked]="isTransferPayment"></mat-radio-button>
        </div>
        <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pageContent?.formSection.transfer |
          translate }}</div>
      </div>
      <div class="payment-option" [class.selected]="isCashPayment">
        <div class="left-radio-button">
          <mat-radio-button class="radio-button" [value]="'cash'" [checked]="isCashPayment"></mat-radio-button>
        </div>
        <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pageContent?.formSection.cash | translate
          }}</div>
      </div>
    </mat-radio-group>

    <div class="input-section" *ngIf="!allowOtherMethods || isTransferPayment">
      <div class="input-group">
        <div class="input-field">
          <mat-form-field class="input-account" [ngClass]="font?.size">
            <mat-label>{{ pageContent?.formSection.bank.field | translate }}</mat-label>
            <mat-select [ngClass]="font?.size" formControlName="bankId" [value]="bankId.value">
              <mat-option [ngClass]="font?.size" *ngFor="let bank of banks" [value]="bank.code">{{ bank.description
                }}</mat-option>
            </mat-select>
            <mat-error [ngClass]="font?.size">{{ pageContent?.errors.required | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="check-group">
          <p class="text-label" [ngClass]="font?.size">{{ pageContent?.formSection.bank.options | translate }}</p>
          <mat-radio-group class="options custom-border" formControlName="paymentMethodId">
            <div class="option" [class.selected]="isChecked('paymentMethodId', accountType.id)"
              *ngFor="let accountType of accountTypes">
              <div class="left-radio-button">
                <mat-radio-button class="radio-button" [value]="accountType.id"
                  [checked]="accountType.id === paymentMethodId.value"></mat-radio-button>
              </div>
              <div class="right-radio-button text-label" [ngClass]="font?.size">{{ accountType.description }}</div>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <p *ngIf="showRutAccountDisclaimer" class="disclaimer information" [ngClass]="font?.size">{{
        pageContent?.rutDisclaimer | translate }}</p>

      <div class="input-group">
        <div class="input-field">
          <mat-form-field class="number-clean input-account" [ngClass]="font?.size"
            [class.error]="accountNumber?.hasError('virtualAccount')">
            <mat-label>{{ pageContent?.formSection.account.field | translate }}</mat-label>
            <input [ngClass]="font?.size" matInput type="number" formControlName="accountNumber">
            <span matSuffix class="i-validator"></span>
            <mat-error [ngClass]="font?.size" *ngIf="accountNumber?.hasError('required')">{{
              pageContent?.errors.required | translate }}</mat-error>
            <mat-error [ngClass]="font?.size" *ngIf="accountNumber?.hasError('virtualAccount')">{{
              pageContent?.errors.patternVirtualAccount | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="check-group" *ngIf="getControl('headline')">
          <p class="text-label" [ngClass]="font?.size">{{ pageContent?.formSection.account.options | translate }}</p>
          <mat-radio-group class="options custom-border" formControlName="headline">
            <div class="option" [class.selected]="isChecked('headline', headline.id)"
              *ngFor="let headline of headlines">
              <div class="left-radio-button">
                <mat-radio-button [value]="headline.id"></mat-radio-button>
              </div>
              <div class="right-radio-button text-label" [ngClass]="font?.size">{{ headline.description | translate }}
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>

  <div class="input-group" *ngIf="allowOtherMethods && isCashPayment">
    <div class="check-group">
      <p class="text-label" [ngClass]="font?.size">{{ pageContent?.formSection.other.options | translate }}</p>
      <mat-radio-group class="options custom-border" formControlName="otherAccountTypeId">
        <div class="option" [class.selected]="isChecked('otherAccountTypeId', method.id)"
          *ngFor="let method of otherAccountTypes">
          <div class="left-radio-button">
            <mat-radio-button class="radio-button" [value]="method.id"></mat-radio-button>
          </div>
          <div class="right-radio-button text-label" [ngClass]="font?.size">{{ method.description }}</div>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <p class="disclaimer account-information" [ngClass]="font?.size">{{ pageContent?.accountDisclaimer | translate }}</p>

  <div class="information-container">
    <app-disclaimer-box [description]="pageContent?.disclaimer | translate" [typeDisclaimer]="'info'">
    </app-disclaimer-box>
  </div>

  <div class="button-container" *ngIf="showButtons">
    <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>
  </div>
</form>