import { RegisterAccount } from '@interfaces/register-account.interface';
import { RUT, EMAIL, PHONE_NUMBER } from './general.constant';

const pageKey = 'PENSIONER.PENSION_PAYMENT.REGISTER';

export const REGISTER_ACCOUNT_CONTENT: RegisterAccount = {
  title: `${pageKey}.TITLE`,
  description: `${pageKey}.DESCRIPTION`,
  form: {
    title: `${pageKey}.FORM.TITLE`,
    name: `${pageKey}.FORM.NAME`,
    rut: RUT,
    bankName: `${pageKey}.FORM.BANK_NAME`,
    phoneNumber: PHONE_NUMBER,
    email: EMAIL,
    accountType: `${pageKey}.FORM.ACCOUNT_TYPE`,
    accountNumber: `${pageKey}.FORM.ACCOUNT_NUMBER`,
    registerButton: `${pageKey}.FORM.REGISTER_BUTTON`,
  },
  errors: {
    forbiddenAccount: `${pageKey}.ERROR.VIRTUAL_ACCOUNT_ERROR`,
  },
  calendarSection: {
    title: `${pageKey}.CALENDAR_SECTION.TITLE`,
    description: `${pageKey}.CALENDAR_SECTION.DESCRIPTION`,
    calendarButton: `${pageKey}.CALENDAR_SECTION.CALENDAR_BUTTON`,
    primaryDisclaimer: `${pageKey}.CALENDAR_SECTION.PRIMARY_DISCLAIMER`,
    secondaryDisclaimer: `${pageKey}.CALENDAR_SECTION.SECONDARY_DISCLAIMER`,
    dateClass: null,
  }
};

export const REGISTER_ACCOUNT_MODAL = {
  success: {
    title: 'Tu cuenta fue enviada exitosamente',
    description: 'Nos pondremos en contacto para enviarte los datos de la inscripción de cuenta.',
    textButton: 'Inscribir otra cuenta',
  },
  failure: {
    title: 'Tu cuenta no pudo ser enviada',
    description: 'Ha ocurrido un error con el envío de tus datos.',
    textButton: 'Volver',
  },
};
