import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, TYPE_SEARCH_OPTIONS
} from '@constants/forms.constant';
import { EMAIL_PATTERN, NUMERIC_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import { Item } from '@interfaces/general.interface';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { ValidateRut } from '@validators/rut.validator';

@Component({
  selector: 'app-search-paperwork-by-field',
  templateUrl: './search-paperwork-by-field.component.html',
  styleUrls: ['./search-paperwork-by-field.component.scss'],
})
export class SearchPaperworkByFieldComponent implements OnInit {
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Input() public pageContent: any;
  public form: UntypedFormGroup;
  public typeSearchOptions: Array<Item> = TYPE_SEARCH_OPTIONS.filter(item => item.id !== '1');
  public onlyNumbersPattern = NUMERIC_PATTERN;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;

  public get rut(): AbstractControl { return this.form.get('rut'); }
  public get phone(): AbstractControl { return this.form.get('phone'); }
  public get email(): AbstractControl { return this.form.get('email'); }
  public get typeSearchOption(): AbstractControl { return this.form.get('typeSearchOption'); }
  public get phoneOptionSelected(): boolean { return this.typeSearchOption.value === '2'; }
  public get emailOptionSelected(): boolean { return this.typeSearchOption.value === '3'; }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
  ) { }

  public ngOnInit(): void {
    this.createForm();
    this.typeSearchOptionSubscription();
  }

  private createForm(): void {
    const phoneValidators = [
      Validators.minLength(this.phoneNumberLength),
      Validators.maxLength(this.phoneNumberLength),
      Validators.min(this.phoneNumberMin),
      Validators.max(this.phoneNumberMax),
      Validators.pattern(PHONE_PATTERN),
    ];

    this.form = this.formBuilder.group({
      hasPreload: [null, Validators.required],
      typeSearchOption: ['2', Validators.required],
      rut: [null, [Validators.required, ValidateRut]],
      phone: [null, phoneValidators],
      email: [null, [Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    });
  }

  public getParamSearch(): void {
    const { rut, email, phone } = this.form.value;
    this.formData.emit({ rut, email, phone });
  }

  private typeSearchOptionSubscription(): void {
    this.typeSearchOption.valueChanges
      .subscribe(() => this.changeTypeSearchOption());
  }

  private changeTypeSearchOption(): void {
    this.formUtils.disableFields(this.form, ['phone', 'email']);

    if (this.phoneOptionSelected) {
      this.formUtils.enableFields(this.form, ['phone']);
    } else if (this.emailOptionSelected) {
      this.formUtils.enableFields(this.form, ['email']);
    }
  }
}
