import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[confirmExit]'
})
export class ConfirmExitDirective {

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    const dialogText = 'Es posible que los cambios no se guarden.';
    event.returnValue = dialogText as any;     // Gecko, Trident, Chrome 34+
    return dialogText;                         // Gecko, WebKit, Chrome <34
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: Event) { }
}
