import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  NAMES_MAX_LENGTH, PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, RUT_MAX_LENGTH,
  VALIDATORS
} from '@constants/forms.constant';
import {
  FamilyAllowanceBackgroundParams, PersonBackgroundParams
} from '@interfaces/family-allowance-form.interface';
import {
  FieldsValidators,
  PersonBackgroundFieldsOptions,
} from '@interfaces/person-backgrounds.interface';
import { MARITAL_STATUS_DESCRIPTION } from '@pages-content/family-allowance-form.constants';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';

@Component({
  selector: 'app-family-allowance-background',
  templateUrl: './family-allowance-background.component.html',
  styleUrls: ['./family-allowance-background.component.scss']
})
export class FamilyAllowanceBackgroundComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public isAffiliate: boolean;
  @Input() public requestGoal: string;
  @Input() public formValues;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public goBack = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();
  public namesMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public form: UntypedFormGroup;
  public formApplicant: UntypedFormGroup;
  public maritalStatusOptions: Array<Array<string>>;
  public personControls: Array<PersonBackgroundFieldsOptions> = [
    'names', 'lastName', 'secondSurname', 'gender', 'rut', 'maritalStatus', 'cellphone', 'email', 'address'
  ];
  public applicantPersonControls: Array<PersonBackgroundFieldsOptions> = [
    'names', 'lastName', 'secondSurname', 'gender', 'rut', 'maritalStatus', 'cellphone', 'email', 'address'
  ];
  public applicantFieldsValidators = {
    rut: {
      value: ''
    }
  } as FieldsValidators;

  public get rut(): AbstractControl { return this.getControl('rut'); }
  public get applicantRut(): AbstractControl { return this.getControl('applicantRut'); }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
    private utils: Utils,
  ) { }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
  }

  public ngOnInit(): void {
    this.maritalStatusOptions = Object.entries(MARITAL_STATUS_DESCRIPTION);
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }

  public mustMatchError(formControlName: string): boolean {
    return this.formUtils.mustMatchError(this.form, formControlName);
  }

  public sendForm(showModal = false): void {
    if (this.form.invalid) { return; }
    const formValues = this.prepareDataSend();

    this.savedModal.emit(showModal);
    this.formData.emit(formValues);
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public validateForm(form: UntypedFormGroup, formKey: 'affiliate' | 'applicant'): void {
    const {
      names, lastName, secondSurname, gender, rut, maritalStatus, cellphone, email, address
    } = form.value;

    if (formKey === 'affiliate') {
      this.form.patchValue({
        names,
        lastNames: this.utils.getFullSurname(lastName ? lastName : '', secondSurname ? secondSurname : ''),
        gender,
        rut,
        maritalStatusId: maritalStatus,
        phoneNumber: cellphone,
        email,
        address
      });
      this.applicantFieldsValidators = {
        rut: {
          value: rut
        }
      };
    } else if (formKey === 'applicant') {
      this.form.patchValue({
        applicantNames: names,
        applicantLastNames: this.utils.getFullSurname(lastName ? lastName : '', secondSurname ? secondSurname : ''),
        applicantGender: gender,
        applicantRut: rut,
        applicantMaritalStatusId: maritalStatus,
        applicantPhoneNumber: cellphone,
        applicantEmail: email,
        applicantAddress: address
      });
    }
  }

  private prepareDataSend(): FamilyAllowanceBackgroundParams {
    const {
      names,
      lastNames,
      gender,
      rut,
      maritalStatusId,
      phoneNumber,
      email,
      address,
      applicantNames,
      applicantLastNames,
      applicantGender,
      applicantRut,
      applicantMaritalStatusId,
      applicantPhoneNumber,
      applicantEmail,
      applicantAddress,
    } = this.form.value;

    const affiliate = {
      name: names,
      lastName: lastNames,
      gender,
      rut: this.utils.rutClean(rut),
      maritalStatusId,
      cellphone: phoneNumber,
      email,
      address
    } as PersonBackgroundParams;

    const formValues = {
      affiliate
    } as FamilyAllowanceBackgroundParams;

    if (!this.isAffiliate) {
      formValues.applicant = {
        name: applicantNames,
        lastName: applicantLastNames,
        gender: applicantGender,
        rut: this.utils.rutClean(applicantRut),
        maritalStatusId: applicantMaritalStatusId,
        cellphone: applicantPhoneNumber,
        email: applicantEmail,
        address: applicantAddress
      } as PersonBackgroundParams;
    }
    return formValues;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      names: ['', VALIDATORS.NAME],
      lastNames: ['', VALIDATORS.NAME],
      gender: ['', Validators.required],
      rut: ['', VALIDATORS.RUT],
      maritalStatusId: ['', Validators.required],
      phoneNumber: ['', VALIDATORS.PHONE],
      email: ['', VALIDATORS.EMAIL],
      address: ['', VALIDATORS.ADDRESS],
    });

    if (!this.isAffiliate) {
      this.form.addControl('applicantNames', new UntypedFormControl('', VALIDATORS.NAME));
      this.form.addControl('applicantLastNames', new UntypedFormControl('', VALIDATORS.NAME));
      this.form.addControl('applicantGender', new UntypedFormControl('', Validators.required));
      this.form.addControl('applicantRut', new UntypedFormControl('', VALIDATORS.RUT));
      this.form.addControl('applicantMaritalStatusId', new UntypedFormControl('', Validators.required));
      this.form.addControl('applicantPhoneNumber', new UntypedFormControl('', VALIDATORS.PHONE));
      this.form.addControl('applicantEmail', new UntypedFormControl('', VALIDATORS.EMAIL));
      this.form.addControl('applicantAddress', new UntypedFormControl('', VALIDATORS.ADDRESS));
      this.form.setValidators([mustMatch('rut', 'applicantRut', false)]);
    }
    this.setFormValues();
  }

  private setFormValues(): void {
    this.personControls = [
      { key: 'names', ...this.formValues ? { value: this.formValues.affiliate.name } : {} },
      { key: 'lastName', ...this.formValues ? { value: this.formValues.affiliate.lastName.split(' ')[0] } : {} },
      { key: 'secondSurname', ...this.formValues ? { value: this.formValues.affiliate.lastName.split(' ')[1] } : {} },
      { key: 'rut', ...this.formValues ? { value: this.formValues.affiliate.rut } : {} },
      { key: 'gender', ...this.formValues ? { value: this.formValues.affiliate.gender } : {} },
      { key: 'maritalStatus', ...this.formValues ? { value: this.formValues.affiliate.maritalStatusId } : {} },
      { key: 'cellphone', ...this.formValues ? { value: this.formValues.affiliate.cellphone } : {} },
      { key: 'email', ...this.formValues ? { value: this.formValues.affiliate.email } : {} },
      { key: 'address', ...this.formValues ? { value: this.formValues.affiliate.address } : {} },
    ];
    if (this.formValues && this.isAffiliate) {
      this.form.patchValue({
        names: this.formValues.affiliate.name,
        lastNames: this.utils.getFullSurname(this.formValues.affiliate.lastName, this.formValues.affiliate.secondSurname),
        gender: this.formValues.affiliate.gender,
        rut: this.formValues.affiliate.rut,
        maritalStatusId: this.formValues.affiliate.maritalStatusId,
        phoneNumber: this.formValues.affiliate.cellphone,
        email: this.formValues.affiliate.email,
        address: this.formValues.affiliate.address,
      });
    } else if (this.formValues && !this.isAffiliate) {
      this.form.patchValue({
        applicantNames: this.formValues.applicant.name,
        applicantLastNames: this.formValues.applicant.lastName,
        applicantGender: this.formValues.applicant.gender,
        applicantRut: this.formValues.applicant.rut,
        applicantMaritalStatusId: this.formValues.applicant.maritalStatusId,
        applicantPhoneNumber: this.formValues.applicant.cellphone,
        applicantEmail: this.formValues.applicant.cellphone,
        applicantAddress: this.formValues.applicant.address
      });
      this.applicantPersonControls = [
        { key: 'names', ...this.formValues.applicant ? { value: this.formValues.applicant.name } : {} },
        { key: 'lastName', ...this.formValues.applicant ? { value: this.formValues.applicant.lastName.split(' ')[0] } : {} },
        { key: 'secondSurname', ...this.formValues.applicant ? { value: this.formValues.applicant.lastName.split(' ')[1] } : {} },
        { key: 'rut', ...this.formValues.applicant ? { value: this.formValues.applicant.rut } : {} },
        { key: 'gender', ...this.formValues.applicant ? { value: this.formValues.applicant.gender } : {} },
        { key: 'maritalStatus', ...this.formValues.applicant ? { value: this.formValues.applicant.maritalStatusId } : {} },
        { key: 'cellphone', ...this.formValues.applicant ? { value: this.formValues.applicant.cellphone } : {} },
        { key: 'email', ...this.formValues.applicant ? { value: this.formValues.applicant.email } : {} },
        { key: 'address', ...this.formValues.applicant ? { value: this.formValues.applicant.address } : {} },
      ];
    } else {
      this.form.patchValue({
        names: null,
        lastNames: null,
        gender: null,
        rut: null,
        maritalStatusId: null,
        phoneNumber: null,
        email: null,
        address: null,
      });
    }
  }
}
