
<form class="form-container" [formGroup]="form" autocomplete="off" (submit)="sendForm()">
  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h3>
  <div class="form-section">
    <div class="fields-group">
      <div class="check-group">
        <p class="text-label" [ngClass]="font?.size">{{ pageContent?.fields.pensionType.title | translate | titlecase }}</p>
        <mat-radio-group class="options custom-border" formControlName="pensionType">
          <div class="option" [class.selected]="isChecked('pensionType', pensionType[0])" *ngFor="let pensionType of pensionTypesOptions">
            <div class="left-radio-button">
              <mat-radio-button [value]="pensionType[0]"></mat-radio-button>
            </div>
            <div class="right-radio-button text-label" [ngClass]="font?.size">{{ pensionType[1] | translate | titlecase }}</div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="information-container" *ngIf="pensionTypeValid && infoMessagePensioner">
      <app-disclaimer-box
        [description]= "pageContent?.fields.informationPensioner.isPensioner"
        [typeDisclaimer]="'info'">
      </app-disclaimer-box>
    </div>

    <div class="fields-group">
      <div class="check-group">
        <p class="text-label" [ngClass]="font?.size">{{ pageContent?.fields.requestGoal.title | translate | titlecase}}</p>
        <mat-radio-group class="options custom-border" formControlName="requestGoal">
          <div class="option" [class.selected]="isChecked('requestGoal', requestGoal[0])"  *ngFor="let requestGoal of requestGoalOptions">
            <div class="left-radio-button">
              <mat-radio-button [value]="requestGoal[0]"></mat-radio-button>
            </div>
            <div class="right-radio-button text-label" [ngClass]="font?.size">{{ requestGoal[1] | translate | titlecase }}</div>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="button-container">
    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>
  </div>
</form>
      
