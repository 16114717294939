import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { GO_BACK } from '@constants/pages-content/general.constant';
import { MODAL_PRIVATE_SITE, IMAGES_INFORMATION } from '@constants/modal-login.constant';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent extends ModalComponent {

  public content = MODAL_PRIVATE_SITE;
  public backButton = GO_BACK;
  public imageInformation = IMAGES_INFORMATION;
  public newUrl: string;
  public actualUrl: string;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private navService: NavigationService,
  ) {
    super(params, modalCtrl);
    this.newUrl = this.params.get('newUrl');
    this.actualUrl = this.params.get('actualUrl');
  }

  public openUrl(url: string) {
    this.closeModal();
    return this.navService.goTo(url);
  }

}
