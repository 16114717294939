import { distinctUntilChanged } from 'rxjs/operators';

import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  NAMES_MAX_LENGTH, PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, RUT_MAX_LENGTH
} from '@constants/forms.constant';
import {
  ERROR_INPUT_TEXT,
  MARITAL_STATUS_DESCRIPTION, MIN_DATE, PERSON_BACKGROUNDS_DEFAULT_CONTROLS,
  PersonBackgroundsDefaultType,
  WORK_SITUATION,
  birthdateControlKey, deceasedDateControlKey,
  healthcareControlKey,
  regionKey, relationshipControlKey, rutControlKey, selectionDateKey
} from '@constants/person-backgrounds.constant';
import { DefaultResponseItem, Region, SelectOption } from '@interfaces/general.interface';
import {
  FieldsValidators, PersonBackgroundFieldsOptions, PersonBackgroundsField
} from '@interfaces/person-backgrounds.interface';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading/loading.service';
import { ModalService } from '@providers/modal/modal.service';
import { CmsService } from '@services/cms/cms.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { ErrorUtils } from '@utils/error.utils';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { mustMatch } from '@validators/must-match.validator';

@Component({
  selector: 'app-person-backgrounds',
  templateUrl: './person-backgrounds.component.html',
  styleUrls: ['./person-backgrounds.component.scss'],
})
export class PersonBackgroundsComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public controlsName: Array<PersonBackgroundFieldsOptions> = [];
  @Input() public fieldsValidators: FieldsValidators;
  @Input() public relationships: Array<DefaultResponseItem>;
  @Input() public disableMinDate = false;
  @Output() public formChanged: EventEmitter<any> = new EventEmitter();
  public form: UntypedFormGroup;
  public namesMaxLength = NAMES_MAX_LENGTH;
  public nameMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public healthcareInstitutions: Array<string>;
  public errorsContent = ERROR_INPUT_TEXT;
  public maritalStatusSelector = Object.entries(MARITAL_STATUS_DESCRIPTION);
  public workOptions = Object.entries(WORK_SITUATION);
  public regions: Array<Region> = [];
  public communes: Array<SelectOption> = [];
  public minBirthdate: Date;
  public maxBirthdate: Date;
  public minDeceasedDate: Date;
  public maxDeceasedDate: Date;
  public minSelectionDate: Date;
  public maxSelectionDate: Date;
  private controlProperties: Array<PersonBackgroundsField>;
  private controls = PERSON_BACKGROUNDS_DEFAULT_CONTROLS;

  public get rut(): AbstractControl { return this.getControl('rut'); }
  public get region(): AbstractControl { return this.getControl('region'); }
  public get commune(): AbstractControl { return this.getControl('commune'); }
  public get primaryRutControl(): AbstractControl { return this.getControl('primaryRut'); }
  public get errors(): any {
    return this.pageContent && this.pageContent.errors ? this.pageContent : this.errorsContent;
  }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
    private formUtils: FormUtils,
    private cmsService: CmsService,
    private loadingService: LoadingService,
    private modalService: ModalService,
    private errorUtils: ErrorUtils,
    private parametersService: ParametersService,
    private utils: Utils,
  ) { }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public async ngOnInit(): Promise<void> {
    this.controlProperties = this.controlsName.map(name => typeof name === 'string' ? { key: name } as PersonBackgroundsField : name);
    this.setDefaultDateValues();
    this.createForm();
    await this.loadData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.fieldsValidators) {
      this.primaryRutControl.setValue(changes.fieldsValidators.currentValue.rut.value);
    }
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.form, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.form, formControlName);
  }

  public cellphoneError(formControlName: string): boolean {
    return this.formUtils.cellphoneError(this.form, formControlName);
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  public dateSelected(control: string): void {
    this.formUtils.setDateSelected(this.form, control);
  }

  public isRequired(keyControl: string): boolean {
    const controlProperty = this.controlProperties.find((property) => property.key === keyControl);
    const defaultValidator = this.form.get(keyControl).validator({} as AbstractControl);
    return Boolean(controlProperty.validators && controlProperty.validators.required) ||
      Boolean(defaultValidator && defaultValidator.required);
  }

  private createForm(): void {
    const group = {};
    let formValidators = null;
    this.controlProperties.forEach((controlName) => {
      const validators = controlName.validators ? controlName.validators : this.controls[controlName.key];
      group[controlName.key] = [controlName.value, validators];
    });
    if (this.showControl(deceasedDateControlKey)) {
      let defaultValue = this.getDefaultControlValue(deceasedDateControlKey);
      if (defaultValue) { defaultValue = this.utils.addTimezoneOffset(defaultValue); }
      group[`${deceasedDateControlKey}Input`] = [defaultValue, Validators.required];
      this.setDefaultDateRange();
    }
    if (this.showControl(birthdateControlKey)) {
      let defaultValue = this.getDefaultControlValue(birthdateControlKey);
      if (defaultValue) { defaultValue = this.utils.addTimezoneOffset(defaultValue); }
      group[`${birthdateControlKey}Input`] = [defaultValue, Validators.required];
      this.setDefaultDateRange();
    }
    if (this.showControl(selectionDateKey)) {
      let defaultValue = this.getDefaultControlValue(selectionDateKey);
      if (defaultValue) { defaultValue = this.utils.addTimezoneOffset(defaultValue); }
      group[`${selectionDateKey}Input`] = [defaultValue, Validators.required];
      this.setDefaultDateRange();
    }
    if (this.fieldsValidators && this.fieldsValidators.rut) {
      group['primaryRut'] = [''];
      formValidators = [mustMatch('primaryRut', rutControlKey, false)];
    }

    this.form = this.formBuilder.group(group,
      formValidators ? { validator: formValidators } : null
    );

    this.evaluateFormAndSubscribe();
  }

  private evaluateFormAndSubscribe(): void {
    this.evaluateValidators();
    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      if (control.value) { control.markAsTouched(); }
    });

    this.form.valueChanges.subscribe(() => {
      this.formChanged.emit(this.form);
    });
  }

  private evaluateValidators(): void {
    if (!this.fieldsValidators) { return; }
    if (this.fieldsValidators.rut) {
      this.primaryRutControl.setValue(this.fieldsValidators.rut.value);
    }
  }

  private async loadData(): Promise<void> {
    const calls = {};
    const regionRequired = this.showControl(regionKey);
    if (regionRequired) { calls['regions'] = this.parametersService.getRegions().toPromise(); }
    if (this.showControl(healthcareControlKey)) {
      calls['healthcareInstitutions'] = this.cmsService.loadHealthcareInstitutions().toPromise();
    }
    if (this.showControl(relationshipControlKey) && !this.relationships) {
      calls['relationships'] = this.parametersService.getRelationships().toPromise();
    }

    const promises = Object.values(calls);
    if (!promises.length) { return; }
    const params = Object.keys(calls);

    this.loadingService.showLoading();
    await Promise.all(promises)
      .then((responses) => {
        responses.forEach((response, index) => this[params[index]] = response);
        if (regionRequired) { this.regionSubscription(); }
        this.setIfCommune();
      })
      .catch((error?) => {
        if (!error) { return; }
        this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) });
      })
      .finally(() => this.loadingService.hideLoading());
  }

  private setIfCommune(): void {
    if (this.getDefaultControlValue('commune') !== '') {
      this.commune.enable();
      this.communes = this.formUtils.getCommunes(this.commune, this.region, this.regions);
      this.form.controls['commune'].setValue(this.getDefaultControlValue('commune'));
    }
  }

  private regionSubscription(): void {
    this.commune.disable();
    this.region.valueChanges
      .pipe(distinctUntilChanged((current, previous) => JSON.stringify(current) === JSON.stringify(previous)))
      .subscribe(
        () => { this.communes = this.formUtils.getCommunes(this.commune, this.region, this.regions); }
      );
  }

  private setDefaultDateRange(): void {
    if (this.fieldsValidators) {
      if (this.fieldsValidators.birthdate) {
        const dateRange = this.getDateRange(this.fieldsValidators.birthdate.minRange, this.fieldsValidators.birthdate.maxRange);
        this.minBirthdate = dateRange.minDate;
        this.maxBirthdate = dateRange.maxDate;
      }
      if (this.fieldsValidators.deceasedDate) {
        const dateRange = this.getDateRange(this.fieldsValidators.deceasedDate.minRange, this.fieldsValidators.deceasedDate.maxRange);
        this.minDeceasedDate = dateRange.minDate;
        this.maxDeceasedDate = dateRange.maxDate;
      }
      if (this.fieldsValidators.selectionDate) {
        const dateRange = this.getDateRange(this.fieldsValidators.selectionDate.minRange, this.fieldsValidators.selectionDate.maxRange);
        this.minSelectionDate = dateRange.minDate;
        this.maxSelectionDate = dateRange.maxDate;
      }
    } else { this.setDefaultDateValues(); }
  }

  private setDefaultDateValues(): void {
    this.minBirthdate = this.minDeceasedDate = this.minSelectionDate = !this.disableMinDate && new Date(MIN_DATE);
    this.maxBirthdate = this.maxDeceasedDate = this.maxSelectionDate = new Date();
  }

  private getDateRange(min: number, max: number): any {
    let minDate = !this.disableMinDate && new Date(MIN_DATE);
    let maxDate = new Date();
    if (min) {
      maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() - min);
    }
    if (max) {
      minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - max);
    }
    return { minDate, maxDate };
  }

  private showControl(keyControl: PersonBackgroundsDefaultType): boolean {
    return this.controlProperties.map((control) => control.key).includes(keyControl);
  }

  private getDefaultControlValue(keyControl: PersonBackgroundsDefaultType): any {
    return this.controlProperties.find((control) => control.key === keyControl).value;
  }
}
