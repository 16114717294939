import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';

type ElementSizeState = 'enlarge' | 'reduce';

export const MUFFLER_ENLARGER_HEIGHT_DEFAULT = '100px';

@Component({
  selector: 'app-muffler',
  templateUrl: './muffler.component.html',
  styleUrls: ['./muffler.component.scss'],
  animations: [
    trigger('smoothHeightResizeAnimation', [
      state('enlarge', style({ height: '{{enlargedHeight}}' }),
        { params: { enlargedHeight: MUFFLER_ENLARGER_HEIGHT_DEFAULT } }),
      state('reduce', style({ height: '{{reducedHeight}}' }),
        { params: { reducedHeight: 0 } }),
      transition('reduce => enlarge', animate('500ms ease-in-out')),
      transition('enlarge => reduce', animate('500ms ease-in-out')),
    ]),
  ],
})
export class MufflerComponent implements OnInit, AfterViewInit {
  @Input() enlargedHeight: string;
  @Input() reducedHeight: string;
  @Input() heightAction: ElementSizeState;
  public initHeight: string;
  private _heightState: ElementSizeState;
  private _heightStateSubject = new Subject<ElementSizeState>();

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  public get heightState(): string { return this._heightState; }

  ngOnInit() {
    this._heightStateSubject.subscribe((heightState: ElementSizeState) => {
      this._heightState = heightState;
    });
    this._heightStateSubject.next(this.heightAction === 'reduce' ? 'enlarge' : 'reduce');
  }

  ngAfterViewInit() {
    this._heightStateSubject.next(this.heightAction);
    this.changeDetectorRef.detectChanges();
  }

  public action(nextAction: ElementSizeState) {
    this._heightStateSubject.next(nextAction);
  }

}
