<div class="title-container">
  <h1 class="title" [ngClass]="font?.size">{{ pensionOffers?.title | translate }}</h1>
  <p class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="pensionOffers?.description | translate"></p>
</div>

<div class="process-timeline-container">
  <h2 class="title title-offers" [ngClass]="font?.size">{{ pensionOffers?.processTimeline.title | translate }}</h2>
  <div class="timeline-container">
    <app-steps [steps]="pensionOffers?.processTimeline.steps"></app-steps>
  </div>
</div>

<div class="offers-disclaimers-container">
  <h2 class="title title-offers" [ngClass]="font?.size">{{ pensionOffers?.disclaimers.title | translate }}</h2>
  <div class="disclaimers-container">
    <div class="disclaimer" *ngFor="let section of pensionOffers?.disclaimers.sections">
      <h2 class="title tertiary" [ngClass]="font?.size">{{ section?.title | translate }}</h2>
      <p class="dynamic-text tertiary" [ngClass]="font?.size" [innerHTML]="section?.description | translate"></p>
    </div>
  </div>
</div>

<app-disclaimer-commision></app-disclaimer-commision>
