import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import {
  AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DEFAULT_STATE_BANK_CODE, DEFAULT_VISTA_ACCOUNT_CODE, OTHER_PAYMENT_METHODS_IDS,
  OTHER_PAYMENT_METHODS_IDS_EMPLOYER, STATEMENT_PENSIONARY_DECLARATIONS_HEADLINES
} from '@constants/payment-methods.constant';
import {
  DefaultObjectResponse, DefaultResponse, PaymentMethodForm, PaymentMethods
} from '@interfaces/payment-methods.interface';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';
import { virtualAccountValidator } from '@validators/virtual-account.validator';

@Component({
  selector: 'app-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss'],
})
export class PaymentMethodFormComponent implements OnInit, OnChanges {
  @Input() public pageContent;
  @Input() public paymentData: PaymentMethods;
  @Input() public paymentMethods: Array<DefaultResponse>;
  @Input() public delegateRut: string;
  @Input() public userRut: string;
  @Input() public preloadAccountNumber: boolean;
  @Input() public allowOtherMethods: boolean;
  @Input() public showButtons: boolean;
  @Input() public removeValeVista: boolean;
  @Input() public isEmployer: boolean;
  @Input() public formValues: PaymentMethodForm;
  @Output() public confirmForm: EventEmitter<any> = new EventEmitter();
  @Output() public back: EventEmitter<any> = new EventEmitter();

  public headlines = STATEMENT_PENSIONARY_DECLARATIONS_HEADLINES;
  private vistaAccount = DEFAULT_VISTA_ACCOUNT_CODE;
  private stateBank = DEFAULT_STATE_BANK_CODE;

  public banks: Array<DefaultObjectResponse>;
  public accountTypes: Array<DefaultResponse>;
  public otherAccountTypes: Array<DefaultResponse>;
  public form: UntypedFormGroup;
  public validForm: boolean;
  private paymentType = '';
  private requiredValidator: Array<ValidatorFn> = [Validators.required];
  private otherPaymentMethodsAvailable: Array<string>;

  public get bankId(): AbstractControl { return this.getControl('bankId'); }
  public get paymentMethodId(): AbstractControl { return this.getControl('paymentMethodId'); }
  public get otherAccountTypeId(): AbstractControl { return this.getControl('otherAccountTypeId'); }
  public get accountNumber(): AbstractControl { return this.getControl('accountNumber'); }
  public get headline(): AbstractControl { return this.getControl('headline'); }
  public get isTransferPayment(): boolean { return this.isPaymentMethod('transfer'); }
  public get isCashPayment(): boolean { return this.isPaymentMethod('cash'); }
  public get showRutAccountDisclaimer(): boolean {
    return this.bankId.value === this.stateBank && this.paymentMethodId.value === this.vistaAccount;
  }
  private get isDelegate(): boolean { return Boolean(this.delegateRut); }
  private get isUserLoaded(): boolean {
    return Boolean(this.userRut) || Boolean(this.delegateRut);
  }

  constructor(
    public util: Utils,
    public font: FontService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit(): void {
    if (!this.paymentData) { return; }

    if (!this.allowOtherMethods) { this.paymentType = 'transfer'; }
    this.otherPaymentMethodsAvailable = this.isEmployer ? OTHER_PAYMENT_METHODS_IDS_EMPLOYER : OTHER_PAYMENT_METHODS_IDS;

    this.loadPaymentData();
    this.createForm();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.form && (changes.delegateRut || changes.userRut)) {
      if (changes.delegateRut) {
        this.addHeadlineControl();
      }
      this.evaluateFormControlValuesAndSubscribes();
      this.autoFillAccount(this.bankId.value, this.paymentMethodId.value);
    }
  }

  public sendForm(): void {
    if (this.form.invalid) { return; }

    let { bankId, paymentMethodId, accountNumber } = this.form.getRawValue();
    const { otherAccountTypeId } = this.form.getRawValue();

    if (!this.isTransferPayment) {
      bankId = accountNumber = '';
      paymentMethodId = otherAccountTypeId;
    }

    const data = {
      bankId: +bankId,
      accountNumber,
      paymentMethodId,
    };

    this.confirmForm.emit(data);
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  public paymentTypeChanged(event: any): void {
    this.resetValues();
    this.paymentType = event.value;
    this.updateRequiredValidator();
  }

  public previousStep(): void {
    this.back.emit();
  }

  public getControl(key: string): AbstractControl {
    return this.form && this.form.get(key);
  }

  private resetValues(): void {
    this.getControl('accountNumber').setValue('');
    this.getControl('bankId').setValue('');
    this.getControl('paymentMethodId').setValue('');
    this.getControl('otherAccountTypeId').setValue('');
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      bankId: ['', [Validators.required]],
      paymentMethodId: ['', [Validators.required]],
      accountNumber: ['', [Validators.required]],
      otherAccountTypeId: [''],
    }, { validator: virtualAccountValidator('accountNumber', 'bankId') });

    this.evaluateFormControlValuesAndSubscribes();
    this.validForm = true;

    if (!this.showButtons) {
      this.form.valueChanges.subscribe(() => {
        if (this.accountNumber.enabled) { this.confirmForm.emit(this.form); }
      });
    }
  }

  private evaluateFormControlValuesAndSubscribes(): void {
    if (this.preloadAccountNumber) {
      const defaults = this.getFormDefaults();
      this.form.patchValue({
        bankId: defaults.bankId,
        paymentMethodId: defaults.paymentMethodId,
        accountNumber: defaults.accountNumber,
      });
    }
    if (this.isUserLoaded) {
      this.accountNumber.disable();
      if (this.delegateRut) { this.addHeadlineControl(); }
      this.getControl('bankId').valueChanges.subscribe((val) => {
        this.autoFillAccount(val, this.paymentMethodId.value);
      });
      this.getControl('paymentMethodId').valueChanges.subscribe((val) => {
        this.autoFillAccount(this.bankId.value, val);
      });
    }
    if (!this.formValues) { return; }
    this.bankIdTransform();
    this.setPaymentMethodSelected();
    this.form.patchValue(this.formValues);
    this.confirmForm.emit(this.form);
  }

  private bankIdTransform(): void {
    this.formValues = {
      ...this.formValues,
      bankId: this.formValues.bankId ? this.formValues.bankId.padStart(3, '0') : ''
    };
  }

  private isPaymentMethod(type: string): boolean {
    return this.paymentType === type;
  }

  private setPaymentMethodSelected(): void {
    if (this.otherAccountTypes.some((accountType) => accountType.id === String(this.formValues.paymentMethodId))) {
      this.paymentTypeChanged({ value: 'cash' });
      this.formValues.otherAccountTypeId = this.formValues.paymentMethodId;
      this.formValues.paymentMethodId = null;
    } else {
      this.paymentTypeChanged({ value: 'transfer' });
    }
  }

  private getFormDefaults(): any {
    const paymentMethodId = this.vistaAccount;
    const bankId = this.stateBank;
    const accountNumber = this.getRutAccount(this.isDelegate ? this.delegateRut : this.userRut);
    return { paymentMethodId, bankId, accountNumber };
  }

  private loadPaymentData(): void {
    this.banks = this.paymentData.banks;
    this.accountTypes = this.paymentMethods;
    this.filterPaymentMethods();
  }

  private addHeadlineControl(): void {
    if (this.headline) { return; }

    this.form.addControl('headline', new UntypedFormControl('1', Validators.required));
    this.headline.valueChanges.subscribe(() => {
      this.autoFillAccount(this.bankId.value, this.paymentMethodId.value);
    });
  }

  private getRutAccount(rut: string): string {
    return rut ? rut.slice(0, -1) : null;
  }

  private filterPaymentMethods(): void {
    this.otherAccountTypes = this.accountTypes.filter((accountType) => this.otherPaymentMethodsAvailable.includes(accountType.id));
    if (this.removeValeVista) {
      this.otherAccountTypes = this.otherAccountTypes.filter((type) => type.id !== OTHER_PAYMENT_METHODS_IDS_EMPLOYER[0]);
    }
    this.accountTypes = this.accountTypes.filter((accountType) => !OTHER_PAYMENT_METHODS_IDS.includes(accountType.id));
  }

  private autoFillAccount(bankId, paymentMethodId): void {
    if (this.accountNumber.disabled) { this.accountNumber.enable(); }
    if (!this.isUserLoaded || bankId !== this.stateBank || paymentMethodId !== this.vistaAccount) { return; }

    const account = this.getRutAccount(this.isDelegate ? this.delegateRut : this.userRut);
    this.accountNumber.setValue(account);
    this.accountNumber.disable();
  }

  private updateRequiredValidator(): void {
    const validator = this.isTransferPayment ? this.requiredValidator : [];

    const transferPaymentControls = [this.bankId, this.accountNumber, this.paymentMethodId];
    if (this.headline) { transferPaymentControls.push(this.headline); }
    transferPaymentControls.forEach((control) =>
      this.updateValidators(control, validator)
    );
    this.updateValidators(this.otherAccountTypeId, this.isTransferPayment ? [] : this.requiredValidator);
  }

  private updateValidators(control: AbstractControl, validator: Array<ValidatorFn>): void {
    control.setValidators(validator);
    control.updateValueAndValidity();
  }
}
