export const SUPERINTENDENCE_FORM_URL = 'https://es.research.net/r/spensiones';

export const SOCIAL_URL = {
  facebook: 'https://www.facebook.com/AFPPlanVital/?fref=ts',
  twitter: 'https://twitter.com/planvitalafp',
  instagram: 'https://www.instagram.com/planvitalafp/?hl=es-la',
  linkedin: 'https://www.linkedin.com/company/afp-planvital',
  youtube: 'https://www.youtube.com/channel/UCZOAjIwHUEJeBXaFXz5c-dA',
  whatsapp: 'https://api.whatsapp.com/send?phone=56222645200&text=Hola',
  email: 'mailto:infovital@planvital.cl'
};
