import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ModalFamilyBurdenComponent
} from '@components/modal-family-burden/modal-family-burden.component';
import {
  ARRAY_FAMILY_BURDEN_PROPERTIES,
  DISCLAIMER_MESSAGE, IDENTIFICATION_FILE
} from '@constants/pages-content/family-allowance-form.constants';
import { AttachFiles } from '@interfaces/attach-files.interface';
import {
  FamilyAllowanceStatementParams, FamilyBurden
} from '@interfaces/family-allowance-form.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-family-allowance-statement',
  templateUrl: './family-allowance-statement.component.html',
  styleUrls: ['./family-allowance-statement.component.scss'],
})
export class FamilyAllowanceStatementComponent implements OnInit {
  @Input() public pageContent;
  @Input() public isAffiliate: boolean;
  @Input() public requestGoal: string;
  @Output() public goBack = new EventEmitter();
  @Output() public formData: EventEmitter<FamilyAllowanceStatementParams> = new EventEmitter();
  public form: UntypedFormGroup;
  public requiredFiles: AttachFiles;
  public familyBurdens: Array<FamilyBurden> = [];
  public burdenProperties = ARRAY_FAMILY_BURDEN_PROPERTIES;
  public modalType = ModalFamilyBurdenComponent;
  public modalData: any;

  public get disclaimerMessage(): string {
    if (!this.requestGoal) { return ''; }
    return Object.values(DISCLAIMER_MESSAGE).find(message => this.requestGoal === message.type).title;
  }

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public ngOnInit(): void {
    this.createForm();
    this.requiredFiles = this.generateFiles();
  }

  public generateFiles(): AttachFiles {
    return { files: [IDENTIFICATION_FILE] };
  }

  public confirm(): void {
    if (this.form.invalid) { return; }
    const {
      familyBurdens,
      attachedFile
    } = this.form.value;
    this.formData.emit({
      familyBurdens,
      attachedFile
    } as FamilyAllowanceStatementParams);
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public setFamilyBurdens(familyBurdens: Array<FamilyBurden>): void {
    this.familyBurdens = familyBurdens;
    this.updateFamilyBurdens();
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  public setFiles(data: { keyList, fileList; }): void {
    this.getControl('attachedFile').setValue(data.fileList.length ? data.fileList[0] : '');
  }

  public updateFamilyBurdens(): void {
    this.getControl('familyBurdens').setValue(this.familyBurdens);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      familyBurdens: ['', Validators.required],
      declaration: [false, Validators.requiredTrue],
      attachedFile: ['', Validators.required]
    });
  }
}
