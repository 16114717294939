import { Image } from '@interfaces/components.interface';

import { InfoText } from './info-text.interface';
import { Banner } from './banner.interface';

export interface DefaultResponseItem {
  id: string;
  description: string;
}

export interface Item {
  id: string;
  name: string;
  description?: string;
}

export interface Section {
  mainTitle?: string;
  title: string;
  description: string;
  goTo?: string;
}

export interface Faq extends Section {
  image?: Image;
}

export interface UrlSection {
  title: string;
  date?: string;
  url?: string;
}

export interface UrlSectionContent {
  header: Banner;
  urlSection: Array<UrlSection>
}

export interface Element {
  id: string | number;
  name: string;
}

export interface Reason extends Element {
  description: string;
  icon: string;
}

export interface Step extends Section {
  label?: string;
  moreInformation: string;
}

export interface PdfFile {
  downloadButtonText: string;
  downloadFileName: string;
  downloadUrl: string;
  info?: string;
}

export interface DownloadSection {
  title?: string;
  urlSectionList: Array<UrlSection>;
}

export interface DownloadSectionContent {
  downloadSection: Array<DownloadSection>;
  header: Banner;
}

export interface DownloadContent {
  title: string;
  year?: string;
  downloadSectionList: Array<DownloadSection>;
}

export interface DownloadContentItems {
  downloadContent: Array<DownloadContent>;
  header: Banner;
}

export interface SelectOption {
  code: string;
  description: string;
  definitions?: Array<InfoText>;
  id?: number;
}

export interface Region extends SelectOption {
  communes: Array<Commune>;
}

export interface Commune extends SelectOption {
  city: string;
  regionCode: string;
}

export interface PaymentMethods {
  banks: Array<SelectOption>;
  accountTypes: Array<SelectOption>;
}

export interface InformationSection {
  title: string;
  faqs: Array<Faq>;
}

export interface MoreInformation {
  title: string;
  button: string;
  url: string;
}

export interface RedirectSectionPaet {
  title: string;
  subtitle: string;
  button: string;
  url: string;
}

export interface InterestButtonsSection {
  leftButton: ButtonsSection;
  rightButton: ButtonsSection;
}

export interface ButtonsSection {
  display: boolean;
  title: string;
  subtitle: string;
  containerClass: string;
  textButton: string;
  urlButton: string;
}

export interface InformationModal extends Section {
  button: string;
}
