import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { InformationModal } from '@interfaces/modal.interface';
import { GO_BACK } from '@constants/pages-content/general.constant';

@Component({
  selector: 'app-modal-information',
  templateUrl: './modal-information.component.html',
  styleUrls: ['./modal-information.component.scss'],
})
export class ModalInformationComponent extends ModalComponent {
  public information: InformationModal;
  public backButton = GO_BACK;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.information = this.params.get('information');
  }
}
