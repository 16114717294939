import { Component, OnInit } from '@angular/core';
import { FuturePensioner } from '@interfaces/future-pensioner.interface';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-disclaimer-commision',
  templateUrl: './disclaimer-commision.component.html',
  styleUrls: ['./disclaimer-commision.component.scss'],
})
export class DisclaimerCommisionComponent implements OnInit {
  public futurePensioner: FuturePensioner;

  constructor(
    protected cmsService: CmsService,
    public font: FontService,
    public languageProvider: LanguageProvider,
  ) { }

  public ngOnInit(): void {
    this.getFuturePensioner();
    this.languageSubscription();
  }

  private getFuturePensioner(): void {
    this.cmsService.loadFuturePensioner().toPromise()
      .then((response) => this.futurePensioner = response);
  }

  private languageSubscription(): void {
    this.languageProvider.language.subscribe(() =>  this.getFuturePensioner());
  }
}
