<ion-content>
  <app-banner [banner]="multiFunds?.header"></app-banner>
  <app-breadcrumb class="breadcrumb" [pathNames]="breadcrumb"></app-breadcrumb>

  <div class="multi-funds-container general-container">
    <h2 class="title" [ngClass]="font?.size">{{ multiFunds?.title | translate }}</h2>
    <p class="subtitle dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="multiFunds?.description | translate"></p>
    <div class="fund-infographic-container">
      <app-fund-infographic [fund]="fund" *ngFor="let fund of multiFunds?.funds"></app-fund-infographic>
    </div>
    <div class="help-section">
      <div class="help-container">
        <h6 class="title tertiary" [ngClass]="font?.size">{{ multiFunds?.helpSection.risky.title | translate }}</h6>
        <p class="subtitle" [ngClass]="font?.size" [innerHtml]="multiFunds?.helpSection.risky.description | translate"></p>
      </div>
      <div class="help-container">
        <h6 class="title tertiary" [ngClass]="font?.size">{{ multiFunds?.helpSection.safe.title | translate }}</h6>
        <p class="subtitle" [ngClass]="font?.size" [innerHtml]="multiFunds?.helpSection.safe.description | translate"></p>
      </div>
    </div>
    <p class="fund-change-disclaimer dynamic-text secondary" [ngClass]="font?.size" [innerHTML]="multiFunds?.fundChangeDisclaimer | translate"></p>
    <div class="info-card-container">
      <app-info-card
        class="info-card tertiary"
        *ngFor="let card of infoCards"
        [page]="cardPage"
        alignment="center"
        type="information"
        [card]="card"
        [showImages]="showImages">
      </app-info-card>
    </div>
  </div>

  <app-footer></app-footer>
</ion-content>