<div class="information-container">
  <app-disclaimer-box *ngIf="itemsCount === maxFiles"
    [description]= "pageContent?.disclaimer"
    [typeDisclaimer]="'alert'">
  </app-disclaimer-box>
</div>

<div class="items-container" [ngClass]="font?.size">
  <div class="block items" *ngFor="let item of items">
    <p class="icon" [ngClass]="iconClass"></p>
    <div class="items-info" [class.max-properties]="isMaxProperties">
      <p class="info" *ngFor="let property of itemProperties" [ngClass]="property.style?.join(' ')">
        {{ getItemProperty(item, property) }}
      </p>
    </div>
    <p class="icon attachments-edit" (click)="editItem(item)"></p>
    <p class="icon attachments-remove" (click)="removeItem(item)"></p>
  </div>
  <div class="block add-container" [class.max-files]="itemsCount === maxFiles">
    <p class="icon attachments-add"></p>
    <div class="button-container" (click)="addItem()">
      <p class="button-text">{{ pageContent?.add | translate }}</p>
    </div>
  </div>
</div>
