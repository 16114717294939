import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ROUTES_WITH_RECAPTCHA_VALIDATION } from '@constants/recaptcha.constant';
import { environment } from '@environment';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const matches = ROUTES_WITH_RECAPTCHA_VALIDATION.filter(
      (route) => this.compareStringUsingWildcard(route.route, request.url) && route.method === request.method
    );
    if (matches.length === 1) {
      return from(this.getRecaptchaToken(matches[0].action))
        .pipe(
          switchMap((recaptchaToken: string) => {
            const requestClone = request.clone({
              headers: request.headers.append('recaptcha-token', recaptchaToken)
            });
            return next.handle(requestClone);
          })
        );
    }
    return next.handle(request);
  }

  private getRecaptchaToken(actionKey: string): Promise<string> {
    return new Promise((res) => {
      (window as any).grecaptcha.enterprise.ready(() => {
        (window as any).grecaptcha.enterprise.execute(environment.recaptchaSiteKey, { action: actionKey })
          .then((token) => res(token));
      });
    });
  }

  private compareStringUsingWildcard(
    template: string,
    stringToCompare: string
  ): boolean {
    return new RegExp('^' + template.replace(/\*/g, '.*') + '$').test(
      stringToCompare
    );
  }
}
