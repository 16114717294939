<div class="card-container">
  <div class="title" [class.mobile-card]="hidden" (click)="hide()" [ngClass]="font?.size">
    {{ title | translate }}
    <em [ngClass]="hidden ? 'i-sort-desc' : 'i-sort-asc'"></em>
  </div>
  <div class="panel-accordion" [class.display]="!hidden">
    <ng-content>
    </ng-content>
  </div>
</div>
