import { Mockup } from '@interfaces/mockup.interface';

export const SURVIVOR_PENSION_PARTIAL_FORM_MOCK: Mockup = {
  success: [
    {
      response: {
        folio: 5,
        currentStep: 2,
        request: {
          rut: '123456785',
          name: 'Nombre Apellido',
          lastName: 'apellido',
          email: 'solicitante@gmail.com',
          cellphone: '942729012',
          clientBackgrounds: {
            clientSituation: 'A',
            deceasedDate: '2021-07-05',
            gender: 'M',
            lastName: 'Apellido Fallecido',
            maritalStatusId: 'S',
            name: 'Nombre Fallecido',
            rut: '19',
            secondSurname: null,
          },
          paymentMethod: {
            accountNumber: null,
            bankId: null,
            paymentMethodId: null,
          },
          transferAccounts: [],
          beneficiaries: [],
        },
        attached: [
          {
            fileName: 'test',
            contentType: 'test',
            key: 'test',
            personRut: '19',
            base64File: 'test',
          }
        ],
      },
    },
  ],
  failures: [{
    response: {}
  }]
};

export const INHERITANCE_REQUEST_FORM_MOCK: Mockup = {
  success: [
    {
      response: {
        attached: [],
        currentStep: 1,
        folio: 5,
        request: {
          applicantBackgrounds: {
            name: 'Nombre',
            lastName: 'Apellido',
            rut: '123456785',
            email: 'aplicante@gmail.com',
            cellphone: '987654567'
          },
          reasonParams: '',
          clientOptions: [
            { id: '0', description: 'CONSORCIO S.A ADMINISTRADORA GENERAL DE FONDOS' },
          ],
          deceasedClientBackgrounds: {
            secondSurname: 'test',
            deceasedDate: '2021-07-07',
            name: 'test',
            lastName: 'test',
            rut: '187998867',
            gender: 'M',
            maritalStatusId: 'A',
            clientSituation: null,
          },
          inheritanceRequest: '',
          email: 'solicitante@gmail.com',
          name: 'Nombre Apellido',
          rut: '123456785',
        },
      },
    },
  ],
  failures: [{
    response: {}
  }]
};

export const MORTUARY_QUOTA_PARTIAL_FORM_MOCK: Mockup = {
  success: [
    {
      response: {
        folio: 5,
        currentStep: 2,
        request: {
          paymentMethod: {
            paymentMethodId: '3',
            bankId: '028',
            accountNumber: '123123',
          },
          funeralServicesClientBackgrounds: {
            name: 'test',
            rut: '19',
            secondSurname: 'test',
            cellphone: '123123123',
            email: 'test@test.cl',
            relationshipId: '1',
            lastName: 'test',
            gender: 'M',
          },
          mortuaryFeeClientBackgrounds: {
            name: 'test',
            rut: '19',
            secondSurname: 'tes',
            cellphone: '123123123',
            email: 'test@test.cl',
            relationshipId: '5',
            lastName: 'test',
            gender: 'M',
          },
          deceasedClientBackgrounds: {
            secondSurname: 'test',
            deceasedDate: '2021-07-07',
            name: 'test',
            lastName: 'test',
            rut: '187998867',
            gender: 'M',
            maritalStatusId: 'A',
            clientSituation: null,
          },
        },
        attached: [
          {
            fileName: 'dummy2.pdf',
            contentType: 'application/pdf',
            key: 'bill',
            personRut: '19',
            base64File: '',
          },
          {
            fileName: 'dummy.pdf',
            contentType: 'application/pdf',
            key: 'deathCertificate',
            personRut: '19',
            base64File: '',
          },
        ],
      },
    },
  ],
  failures: [{
    response: {}
  }]
};

export const CERTIFY_STUDIES_PARTIAL_FORM_MOCK: Mockup = {
  success: [{
    response: {
      id: '1',
      currentStep: 4,
      request: {
        affiliate: {
          name: 'Test',
          lastName: 'Test',
          secondSurname: 'Test',
          rut: '27'
        },
        applicant: {
          name: 'Test',
          lastName: 'Test',
          secondSurname: 'Test',
          rut: '19',
          address: 'Test',
          region: 2,
          communeId: '2201',
          email: 'test@test.cl',
          cellphone: '999999999',
          maritalStatusId: 'S',
          healthcare: 'Test',
          profession: 'Test',
          gender: 'F',
          birthdate: '2002-01-01'
        },
        applicantDeclaration: {
          unmarried: true,
          certificate: true,
        },
        studiesDeclaration: {
          career: null,
          establishment: null,
          year: null,
          period: null,
          declaration: null
        },
        selectPaymentMethod: {
          paymentMethoiId: null,
          bankId: null,
          accountNumber: null
        }
      },
      attached: [
        {
          fileName: 'artista.jpg',
          contentType: 'image/jpeg',
          size: '1',
          key: 'personalDocument',
          personRut: '19',
          base64File: ''
        },
        {
          fileName: 'artista.jpg',
          contentType: 'image/jpeg',
          size: '1',
          key: 'thirdYear-secondSemester',
          personRut: '19',
          base64File: ''
        }
      ]
    }
  },
  {
    response: {
      id: '1',
      currentStep: 4,
      request: {
        affiliate: {
          name: 'Test',
          lastName: 'Test',
          secondSurname: 'Test',
          rut: '27'
        },
        applicant: {
          name: 'Test',
          lastName: 'Test',
          secondSurname: 'Test',
          rut: '19',
          address: 'Test',
          region: 2,
          communeId: '2201',
          email: 'test@test.cl',
          cellphone: '999999999',
          maritalStatusId: 'S',
          healthcare: 'Test',
          profession: 'Test',
          gender: 'F',
          birthdate: '2002-01-01'
        },
        applicantDeclaration: {
          unmarried: true,
          certificate: false,
        },
        studiesDeclaration: {
          career: 'Test',
          establishment: 'Test',
          year: 'Test',
          period: 'Test',
          declaration: true
        },
        selectPaymentMethod: {
          paymentMethoiId: null,
          bankId: null,
          accountNumber: null
        }
      },
      attached: [
        {
          fileName: 'artista.jpg',
          contentType: 'image/jpeg',
          size: '1',
          key: 'personalDocument',
          personRut: '19',
          base64File: ''
        },
        {
          fileName: 'artista.jpg',
          contentType: 'image/jpeg',
          size: '1',
          key: 'alternativeCertificate',
          personRut: '19',
          base64File: ''
        }
      ]
    }
  }],
  failures: []
};

export const PARTIAL_FORM_MOCKUP: Mockup = {
  success: [{
    response: {
      type: 'pension-invalidez',
      id: 827,
      origin: 'web',
      status: 'P',
      date: '06-09-2021',
      hasAttachment: true,
      attached: [
        {
          fileName: 'dummy2.pdf',
          contentType: 'application/pdf',
          size: '30012',
          key: 'bill',
          personRut: '19',
          base64File: 'JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwog' +
            'IC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAv' +
            'TWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0K' +
            'Pj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAg' +
            'L1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+' +
            'PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9u' +
            'dAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2Jq' +
            'Cgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJU' +
            'CjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVu' +
            'ZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4g' +
            'CjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAw' +
            'MDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9v' +
            'dCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G',
        },
        {
          fileName: 'dummy1.pdf',
          contentType: 'application/pdf',
          size: '30012',
          key: 'bill',
          personRut: '19',
          base64File: 'JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwog' +
            'IC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAv' +
            'TWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0K' +
            'Pj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAg' +
            'L1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+' +
            'PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9u' +
            'dAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2Jq' +
            'Cgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJU' +
            'CjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVu' +
            'ZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4g' +
            'CjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAw' +
            'MDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9v' +
            'dCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G',
        },
        {
          fileName: 'dummy3.pdf',
          contentType: 'application/pdf',
          size: '30012',
          key: 'bill',
          personRut: '19',
          base64File: 'JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwog' +
            'IC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAv' +
            'TWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0K' +
            'Pj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAg' +
            'L1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+' +
            'PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9u' +
            'dAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2Jq' +
            'Cgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJU' +
            'CjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVu' +
            'ZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4g' +
            'CjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAw' +
            'MDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9v' +
            'dCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G',
        },
      ]
    }
  }],
  failures: [{
    response: {
      statusCode: 500,
      message: 'Error',
      messageDescription: 'El servicio ha respondido con un error. Por favor, inténtalo de nuevo más tarde.'
    }
  }],
};
