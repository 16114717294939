import { Component, OnInit } from '@angular/core';
import { CARD_TITLE_TO_DISCARD } from '@constants/pages-content/multi-funds.constant';
import { ASSESSMENT_TITLES } from '@constants/titles.constant';
import { Card, CardPage } from '@interfaces/info-card.interface';
import { MultiFunds } from '@interfaces/multi-funds.interface';
import { ShowImages } from '@interfaces/show-images.interface';
import { AssessmentPage } from '@modules/affiliate/assessment/assessment.page';
import { FontService } from '@providers/font/font.service';
import { LanguageProvider } from '@providers/language/language.service';
import { CmsService } from '@services/cms/cms.service';

@Component({
  selector: 'app-multi-funds',
  templateUrl: './multi-funds.page.html',
  styleUrls: ['./multi-funds.page.scss'],
})
export class MultiFundsPage extends AssessmentPage implements OnInit {
  public pageTitle = ASSESSMENT_TITLES.content.multifunds;
  public cardPage: CardPage = 'multi-fund';
  public multiFunds: MultiFunds;
  public infoCards;
  public loading = false;
  public showImages: boolean;

  constructor(
    private cmsService: CmsService,
    public font: FontService,
    public languageProvider: LanguageProvider,
  ) {
    super();
    this.breadcrumb.push(this.pageTitle);
  }

  public async ngOnInit() {
    await this.getMultifunds();
    this.languageSubscription();
  }

  private async getMultifunds() {
    this.loading = true;
    await Promise.all([
      this.cmsService.loadMultiFunds().toPromise(),
      this.cmsService.getShowImages().toPromise(),
    ]).then(([loadMultiFunds, getShowImages]: [MultiFunds, ShowImages]) => {
      this.multiFunds = loadMultiFunds;
      this.showImages = getShowImages.data[0].multifondos;
      this.processInfoCards(loadMultiFunds.moreInfoCards);
    })
    .finally(() => this.loading = false);
  }

  private languageSubscription() {
    this.languageProvider.language.subscribe(() => {
      this.getMultifunds();
    });
  }

  private processInfoCards(moreInfoCards: Card<any>[]) {
    this.infoCards = moreInfoCards.filter(infoCard => {
      return this.languageProvider.translate.instant(infoCard.title) !== CARD_TITLE_TO_DISCARD;
    });
  }

}
