import { Mockup } from '@interfaces/mockup.interface';

// tslint:disable:max-line-length
export const GET_ADMINISTRATION_WORKERS: Mockup = {
  success: [{
    response: {
      data: {
        traducciones: [
          {
            idioma: 'es',
            contenido: {
              directorio: [
                {
                  nombre: 'Alfredo Orelli',
                  cargo: 'Presidente Directorio',
                  url: 'img-directorio/alfredo-orelli.png'
                },
                {
                  nombre: 'Sara Bendel Manriquez',
                  cargo: 'Director',
                  url: 'img-directorio/Sara-Bendel-Manriquez.png'
                },
                {
                  nombre: 'Andrea Rabusin',
                  cargo: 'Director',
                  url: 'img-directorio/andrea-rabusin.png'
                },
                {
                  nombre: 'Javier Marin Estevez',
                  cargo: 'Director',
                  url: 'img-directorio/Javier-Marin-Estevez.jpg'
                },
                {
                  nombre: 'Mario García Arias',
                  cargo: 'Director independiente',
                  url: 'img-directorio/Mario_Garcia_Arias.jpg'
                },
                {
                  nombre: 'Oscar Spoerer Varela',
                  cargo: 'Vicepresidente',
                  url: 'img-directorio/oscar-spoerer.png'
                },
                {
                  nombre: 'Cristián Pizarro Goicochea',
                  cargo: 'Director',
                  url: 'img-directorio/cristian-pizarro.png'
                },
                {
                  nombre: 'Lorenzo Ioan',
                  cargo: 'Director',
                  url: 'img-directorio/lorenzo_ioan.jpg'
                },
                {
                  nombre: 'Cristián Zárate Pizarro',
                  cargo: 'Director suplente',
                  url: ''
                }
              ],
              ejecutivos: [
                {
                  nombre: 'Andrea Battini',
                  cargo: 'Chief Executive Officer',
                  url: 'img-directorio/andrea_battini.jpg'
                },
                {
                  nombre: 'José Joaquín Prat',
                  cargo: 'General Manager',
                  url: 'img-directorio/jose_joaquin_prat.jpg'
                },
                {
                  nombre: 'María Isabel Jaramillo',
                  cargo: 'Chief Customer Officer',
                  url: 'img-directorio/maria_isabel_jaramillo.jpg'
                },
                {
                  nombre: 'Marcela Espinosa',
                  cargo: 'Chief Financial Officer',
                  url: 'img-directorio/marcela_espinosa.jpg'
                },
                {
                  nombre: 'Sophie Levalois',
                  cargo: 'Chief Sales Officer',
                  url: 'img-directorio/sophie_levalois.jpg'
                },
                {
                  nombre: 'Raúl Avello',
                  cargo: 'Chief Operating Officer',
                  url: 'img-directorio/raul_avello.jpg'
                },
                {
                  nombre: 'Juan Antonio García',
                  cargo: 'Gerente de Personas y Organización',
                  url: 'img-directorio/juan_antonio_garcia.jpg'
                },
                {
                  nombre: 'Cristián Ascencio',
                  cargo: 'Chief Information and Technology Officer',
                  url: 'img-directorio/cristian_ascencio.jpg'
                },
                {
                  nombre: 'Guillermo Matte',
                  cargo: 'General Counsel',
                  url: 'img-directorio/guillermo_matte.jpg'
                },
                {
                  nombre: 'Ana María Abarca',
                  cargo: 'Chief Risk Officer',
                  url: 'img-directorio/ana_maria_abarca.jpg'
                },
                {
                  nombre: 'Juan Esteban Pérez',
                  cargo: 'Chief of Internal Audit',
                  url: 'img-directorio/juan_esteban_perez.jpg'
                },
                {
                  nombre: 'Ariel Canelo',
                  cargo: 'Gerente de Cumplimiento',
                  url: 'img-directorio/ariel_canelo.jpg'
                },
                {
                  nombre: '',
                  cargo: 'Chief Investments Officer'
                }
              ]
            }
          }
        ]
      }
    }
  }],
  failures: [{
    response: {}
  }]
};
