import { Injectable } from '@angular/core';
import { Utils } from '@utils/utils';

@Injectable({
  providedIn: 'root'
})
export class FilesUtil {

    constructor(
        private util: Utils
    ) { }

    public downloadBase64File(base64Data: string, name: string, type = 'application/pdf'): void {
        const blob = this.util.base64toBlob(base64Data, type);
        const blobUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.style.display = 'none';
        anchor.href = blobUrl;
        anchor.download = name;
        anchor.click();
    }

}
