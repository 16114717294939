import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TransferRoutingModule } from './transfer-routing.module';

@NgModule({
  imports: [
    RouterModule,
    TransferRoutingModule,
  ],
})
export class TransferModule { }
