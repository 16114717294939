import { PrimaryOption } from '@interfaces/header.interface';

import { PENSIONER_PAYMENT_TITLES } from './titles.constant';

export const PENSION_PAYMENT_PAGES: { [key: string]: PrimaryOption } = {
  registerAccount: {
    name: PENSIONER_PAYMENT_TITLES.content.register,
    url: 'pensionado/pago-pension/inscribir-cuenta',
  },
  calendar: {
    name: PENSIONER_PAYMENT_TITLES.content.calendar,
    url: 'pensionado/pago-pension/calendario',
  },
  pensionTypes: {
    name: PENSIONER_PAYMENT_TITLES.content.pensionTypes,
    url: 'pensionado/pago-pension/tipos-pension',
  },
  pensionModes: {
    name: PENSIONER_PAYMENT_TITLES.content.pensionModes,
    url: 'pensionado/pago-pension/modalidad-pension',
  },
  pensionOffers: {
    name: PENSIONER_PAYMENT_TITLES.content.pensionOffers,
    url: 'pensionado/pago-pension/ofertas-pension',
  },
};

export const PENSION_PAYMENT_TABS: Array<PrimaryOption> = [
  {
    name: PENSION_PAYMENT_PAGES.calendar.name,
    url: PENSION_PAYMENT_PAGES.calendar.url,
  },
  {
    name: PENSION_PAYMENT_PAGES.pensionTypes.name,
    url: PENSION_PAYMENT_PAGES.pensionTypes.url,
  },
  {
    name: PENSION_PAYMENT_PAGES.pensionModes.name,
    url: PENSION_PAYMENT_PAGES.pensionModes.url,
  },
  {
    name: PENSION_PAYMENT_PAGES.pensionOffers.name,
    url: PENSION_PAYMENT_PAGES.pensionOffers.url,
  },
];
