

export interface CausantBackgrounds {
    name?: string;
    lastName?: string;
    secondSurname?: string;
    rut?: string;
    birthdate?: string;
    gender?: string;
    address?: string;
    region?: string;
    commune?: string;
    cellphone?: string;
    email?: string;
}

export interface ApplicantBackgrounds {
    name?: string;
    lastName?: string;
    secondSurname?: string;
    rut?: string;
    birthdate?: string;
    gender?: string;
    address?: string;
    region?: string;
    commune?: string;
    cellphone?: string;
    email?: string;
}

export interface MandataryBackgrounds {
    name?: string;
    lastName?: string;
    secondSurname?: string;
    rut?: string;
    birthdate?: string;
    gender?: string;
    address?: string;
    region?: string;
    commune?: string;
    cellphone?: string;
    email?: string;
}

export interface AccountFounds {
    mandatoryContribution: Founds;
    voluntarySavings: Founds;
    agreedDeposit: Founds;
    voluntaryContribution: Founds;
    voluntaryAffiliate: Founds;
}


export interface Founds {
    firstOrigin: FoundOptions;
    firstDestiny: FoundOptions;
    secondOrigin: FoundOptions;
    secondDestiny: FoundOptions;
}




export enum FoundOptions {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    NE = 'No aplica',
}


export enum TransferSurvivorPensionRadioValues {
    UNIQUE_BENEFICIARY = 'unique-beneficiary',
    MANDATARY_GROUP_BENEFICIARY = 'mandatary-group-beneficiary',
    TUTOR = 'tutor',
    TUTOR_MENTAL_DISABILITY = 'tutor-mental-disability',
}

