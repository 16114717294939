import { Section } from '@interfaces/general.interface';
import { PHONE_NUMBER, EMAIL, RUT, YES, NO, SEND } from './general.constant';

const contactKey = 'CONTACT';
export const CONTACT_MODAL_TEXT: Section = {
  title: `${contactKey}.TITLE`,
  description: `${contactKey}.DESCRIPTION`,
};

export const CONTACT_OPTION_LABELS = {
  upload: 'GENERAL.UPLOAD',
  update: 'GENERAL.UPDATE',
  yes: YES,
  no: NO,
  send: SEND,
};

const formKey = 'FORM_FIELDS';
export const NAMES_FORM_FIELDS = {
  rut: RUT,
  fatherLastName: `${formKey}.FATHER_NAME`,
  motherLastName: `${formKey}.MOTHER_NAME`,
  name: `${formKey}.NAME`,
  phoneNumber: PHONE_NUMBER,
  email: EMAIL,
  reasonCode: `${formKey}.REASON`,
  description: `${formKey}.COMMENTS`,
  file: `${formKey}.FILE`,
};

export const CONTACT_RESULT_MODAL = {
  success: {
    title: `${contactKey}.SUCCESS.TITLE`,
    description: `${contactKey}.SUCCESS.DESCRIPTION`,
  },
  error: {
    title: `${contactKey}.ERROR.TITLE`,
    description: `${contactKey}.ERROR.DESCRIPTION`,
  },
};

export const GENESYS_ORGANIZATION_GUID = '44899fbb-b13c-4b1e-863d-6c5b6c05b41e';
export const MYPURECLOUD_ENVIRONMENT = 'mypurecloud.com';
export const MYPURECLOUD_API_URL = 'https://api.mypurecloud.com/api/v2';
export const SCREENSHARE_CODE_MAX_LENGTH = 6;
