import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalComponent } from '@components/modal/modal.component';
import { PHONE_NUMBER_LENGTH, PHONE_NUMBER_MAX, PHONE_NUMBER_MIN, VALIDATORS } from '@constants/forms.constant';
import { CALL_CENTER_V2, ERROR_INPUT_TEXT } from '@constants/pages-content/call-center-v2.constant';
import { Click2CallData, Click2CallRequest } from '@interfaces/genesys.interface';
import { NavParams, ModalController } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { GenesysService } from '@services/genesys/genesys.service';
import { FormUtils } from '@utils/form.utils';
import { CountryDialCodesService } from '../../services/country-dial-codes/country-dial-codes.service';
import { CallType, SUBJECT_PROCEDURE } from '@constants/pages-content/modals.constants';
import { ChatbotRenderer } from '@providers/chatbot/chatbot.renderer';
import { Subscription } from 'rxjs';
import { CountryIso } from '@interfaces/country-dial-codes.interface';



@Component({
  selector: 'app-modal-contact-center-v2',
  templateUrl: './modal-contact-center-v2.component.html',
  styleUrls: ['./modal-contact-center-v2.component.scss'],
})

export class ModalContactCenterV2Component extends ModalComponent implements OnInit, OnDestroy {
  @Input() public procedureType;
  public cobrowseConfig;
  public pageContent = CALL_CENTER_V2;
  public click2CallContent = this.pageContent.click2Call;
  public isSessionActive = this.genesysService.isSessionActive.value;
  public phoneNumberLength = PHONE_NUMBER_LENGTH;
  public phoneNumberMax = PHONE_NUMBER_MAX;
  public phoneNumberMin = PHONE_NUMBER_MIN;
  public click2callForm: UntypedFormGroup;
  public countries: Array<CountryIso>;
  public errorsContent = ERROR_INPUT_TEXT;
  public showForm = true;
  public phoneNumberToCall: string;
  public errorService = false;
  private genesysServiceSubscription: Subscription;

  public callType: CallType = 'phoneCall';

  public get isNationalPhoneCall(): boolean {
    return this.callType === 'phoneCall' && this.countryCode.value === '+56';
  }

  public get isVideoCall(): boolean {
    return this.callType === 'videoCall';
  }

  public get isInternationalPhoneCall(): boolean {
    return this.callType === 'phoneCall' && this.countryCode.value !== '+56';
  }


  constructor(
    private formBuilder: UntypedFormBuilder,
    private genesysService: GenesysService,
    public router: Router,
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    public dialog: MatDialog,
    private countryDialCodes: CountryDialCodesService,
    private formUtils: FormUtils,
    private chatbotRenderer: ChatbotRenderer,
  ) {
    super(params, modalCtrl);
    this.setClick2CallForm();
    this.genesysService.isSessionActive.subscribe(response => {
      this.isSessionActive = response;
    });
  }

  async ngOnInit(): Promise<void> {
    this.countries = await this.countryDialCodes.getCountries();
    this.countryCode.setValue('+56');
  }

  ngOnDestroy(): void {
    if (this.genesysServiceSubscription instanceof Subscription) {
      this.genesysServiceSubscription.unsubscribe();
    }
  }

  public get name(): UntypedFormControl { return this.click2callForm.get('name') as UntypedFormControl; }
  public get rut(): UntypedFormControl { return this.click2callForm.get('rut') as UntypedFormControl; }
  public get phone(): UntypedFormControl { return this.click2callForm.get('phone') as UntypedFormControl; }
  public get reason(): UntypedFormControl { return this.click2callForm.get('reason') as UntypedFormControl; }
  public get countryCode(): UntypedFormControl { return this.click2callForm.get('countryCode') as UntypedFormControl; }

  public submitNationalCall(): void {
    if (this.click2callForm.invalid) { return; }
    this.phoneNumberToCall = `${this.countryCode.value} ${this.phone.value}`;
    this.reason.setValue(`${SUBJECT_PROCEDURE} - ${this.procedureType}`);
    const callbackRequest = this.getClick2CallRequest(this.click2callForm.value);
    this.genesysService.sendClick2CallRequest(callbackRequest).subscribe(() => {
      this.showForm = false;
      this.errorService = false;
    }, () => {
      this.errorService = true;
    });
  }
  public submitVideoCall(): void {
    this.chatbotRenderer.startVideoCallChatbot();
  }

  public submitInternationalPhoneCall(): void {
    this.chatbotRenderer.startInternationalPhoneCallChatbot();
  }

  private getClick2CallRequest(callbackData: Click2CallData): Click2CallRequest {
    const { name, rut, phone, countryCode, reason } = callbackData;
    return {
      callbackNumbers: [this.countryCode.value + phone],
      callbackUserName: name,
      rut,
      countryCode,
      reason,
      url: this.router.url,
      isFromPublic: true,
    };
  }

  private setClick2CallForm(): void {
    this.click2callForm = this.formBuilder.group({
      name: ['', VALIDATORS.NAME],
      rut: ['', VALIDATORS.RUT],
      countryCode: ['', Validators.required],
      phone: ['', VALIDATORS.PHONE],
      reason: [''],
    });
  }

  public requiredError(formControlName: string): boolean {
    return this.formUtils.requiredError(this.click2callForm, formControlName);
  }

  public patternError(formControlName: string): boolean {
    return this.formUtils.patternError(this.click2callForm, formControlName);
  }

  public cellphoneError(formControlName: string): boolean {
    return this.formUtils.cellphoneError(this.click2callForm, formControlName);
  }

  public async modalClose(): Promise<void> {
    await this.modalCtrl.dismiss();
  }


  public getCallType(event: any): void {
    this.chatbotRenderer.dismissChatbot();
    this.callType = event.detail.value;
  }


}
