import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalValidateCertificateComponent } from '@components/modal-validate-certificate/modal-validate-certificate.component';
import { NUMBER_PATTERN } from '@constants/regex.constant';
import { ValidateBox } from '@interfaces/certificates.interface';
import { ValidateCertificateParams } from '@interfaces/validate-certificate.interface';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { CertificatesService } from '@services/certificates/certificates.service';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';
import { ValidateRut } from '@validators/rut.validator';
import { LoadingService } from '@providers/loading/loading.service';
import { finalize } from 'rxjs/operators';
import { RECAPTCHA_DISCLAIMER } from '@constants/pages-content/general.constant';

@Component({
  selector: 'app-validate-certificate-box',
  templateUrl: './validate-certificate-box.component.html',
  styleUrls: ['./validate-certificate-box.component.scss'],
})
export class ValidateCertificateBoxComponent {
  @Input() public data: ValidateBox;
  public recaptchaDisclaimer = RECAPTCHA_DISCLAIMER;
  public requestForm = new UntypedFormGroup({
    rut: new UntypedFormControl('', ValidateRut),
    certificateId: new UntypedFormControl('', [Validators.required, Validators.pattern(NUMBER_PATTERN)]),
  });

  constructor(
    public font: FontService,
    private errorUtils: ErrorUtils,
    private utils: Utils,
    private modalService: ModalService,
    private certificatesService: CertificatesService,
    private loadingService: LoadingService,
  ) { }

  public get rut() { return this.requestForm.get('rut') as UntypedFormControl; }

  public validateCertificate() {
    if (this.requestForm.invalid) { return; }
    const { rut, certificateId } = this.requestForm.getRawValue();
    const data: ValidateCertificateParams = { certificateId, rut: this.utils.rutClean(rut) };
    this.loadingService.showLoading();
    this.certificatesService.validateCertificate(data)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(
        (response) => this.modalService.openModal(ModalValidateCertificateComponent, { data: response, certificateId }),
        (error) => this.modalService.openAlert({ title: 'Error', description: this.errorUtils.handleServiceError(error) })
    );
  }
}
