import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Declarations } from '@interfaces/certify-studies.interface';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-certify-studies-declarations',
  templateUrl: './certify-studies-declarations.component.html',
  styleUrls: ['./certify-studies-declarations.component.scss'],
})
export class CertifyStudiesDeclarationsComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: Declarations;
  @Output() public formData: EventEmitter<Declarations> = new EventEmitter();
  @Output() public saveModal: EventEmitter<any> = new EventEmitter();
  @Output() public goBack = new EventEmitter();
  public form: UntypedFormGroup;

  constructor(
    public font: FontService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      unmarried: ['', Validators.requiredTrue],
      certificate: ['', Validators.required],
    });
    this.setFormValues();
  }

  public sendForm(showModal = false): void {
    if (this.form.invalid) {
      return;
    }
    const { unmarried, certificate } = this.form.value;
    const declarations = {
      unmarried,
      certificate: JSON.parse(certificate),
    } as Declarations;

    this.saveModal.emit(showModal);
    this.formData.emit(declarations);
  }

  public previousStep(): void {
    this.goBack.emit();
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  private setFormValues(): any {
    this.form.patchValue({
      ...this.formValues
    });
    if (this.formValues) {
      this.form.controls['certificate'].setValue(String(this.formValues.certificate).toString());
    }
  }
}
