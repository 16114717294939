import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NAMES_MAX_LENGTH, RUT_MAX_LENGTH } from '@constants/forms.constant';
import {
  INHERITANCE_APPLICANT_CONTROLS, INHERITANCE_DECEASED_CONTROLS
} from '@constants/inheritance-request-backgrounds.constants';
import { GENERAL_BACKGROUND_FIELDS_CONTENT } from '@constants/person-backgrounds.constant';
import {
  ApplicantBackgrounds, InheritanceRequestBackgrounds
} from '@interfaces/inheritance-request.interface';
import { FieldsValidators, PersonBackgroundsField } from '@interfaces/person-backgrounds.interface';
import { DeceasedAffiliateBackgrounds } from '@interfaces/survivor-pension-form.interface';
import { FontService } from '@providers/font/font.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-inheritance-request-backgrounds',
  templateUrl: './inheritance-request-backgrounds.component.html',
  styleUrls: ['../inheritance-request.page.scss'],
})
export class InheritanceRequestBackgroundsComponent implements OnInit {
  @Input() public pageContent;
  @Input() public formValues: InheritanceRequestBackgrounds;
  @Output() public formData: EventEmitter<any> = new EventEmitter();
  @Output() public savedModal: EventEmitter<any> = new EventEmitter();
  public componentReady;
  public namesMaxLength = NAMES_MAX_LENGTH;
  public rutMaxLength = RUT_MAX_LENGTH;
  public maxDate = new Date();
  public form: UntypedFormGroup;
  public formContent = GENERAL_BACKGROUND_FIELDS_CONTENT;
  public deceasedFieldsValidators = {
    rut: {
      value: ''
    }
  } as FieldsValidators;

  public resumeDeceasedAffiliateControls: Array<PersonBackgroundsField>;
  public resumeApplicantControls: Array<PersonBackgroundsField>;

  private deceasedBackgroundForm: UntypedFormGroup;
  private applicantBackgroundForm: UntypedFormGroup;

  public get rut(): AbstractControl { return this.getControl('rut'); }
  public get isValidForm(): boolean { return this.isValidDeceasedBackgroundForm &&  this.isValidApplicantBackgroundForm; }
  private get isValidDeceasedBackgroundForm(): boolean { return this.deceasedBackgroundForm && this.deceasedBackgroundForm.valid; }
  private get isValidApplicantBackgroundForm(): boolean { return this.applicantBackgroundForm && this.applicantBackgroundForm.valid; }

  constructor(
    public font: FontService,
    private utils: Utils,
    private formUtils: FormUtils,
  ) { }

  public ngOnInit(): void {
    this.componentReady = false;
    this.setFormValues();
  }

  public getControl(key: string): AbstractControl { return this.form.get(key); }

  public sendForm(showModal = false): void {
    if (!this.isValidForm) { return; }
    const backgrounds = {
      deceasedClientBackgrounds: this.parseDeceasedBackgrounds(),
      applicantBackgrounds: this.parseApplicantBackgrounds(),
    } as InheritanceRequestBackgrounds;

    this.savedModal.emit(showModal);
    this.formData.emit(backgrounds);
  }

  public validateForm(form: UntypedFormGroup, formKey: 'deceasedAffiliate' | 'applicant'): void {
    if (formKey === 'deceasedAffiliate') { this.validateDeceasedForm(form); }
    if (formKey === 'applicant') { this.validateApplicantForm(form); }
  }

  private validateDeceasedForm(form: UntypedFormGroup): void {
    this.deceasedFieldsValidators = {
      rut: {
        value: form.get('rut').value
      }
    };
    this.deceasedBackgroundForm.patchValue(form.value);
  }

  private validateApplicantForm(form: UntypedFormGroup): void {
    this.applicantBackgroundForm.patchValue(form.value);
  }

  private parseDeceasedBackgrounds(): DeceasedAffiliateBackgrounds {
    const {
      names: name, lastName, secondSurname, rut, maritalStatus: maritalStatusId, gender, deceasedDate
    } = this.deceasedBackgroundForm.value;

    return { name,
      lastName, secondSurname,
      rut: this.utils.rutClean(rut),
      maritalStatusId,
      gender,
      deceasedDate
    };
  }

  private parseApplicantBackgrounds(): ApplicantBackgrounds {
    const { names, lastName, rut, email, cellphone } = this.applicantBackgroundForm.value;
    return { name: names, lastName, rut: this.utils.rutClean(rut), email, cellphone };
  }

  private setFormValues(): void {
    const applicantBackground = this.formValues ? this.formValues.applicantBackgrounds : null;
    const deceasedClientBackgrounds = this.formValues ? this.formValues.deceasedClientBackgrounds : null;

    this.setApplicantBackgroundControls(applicantBackground);
    this.setDeceasedBackgroundControls(deceasedClientBackgrounds);
    this.componentReady = true;
  }

  private setDeceasedBackgroundControls(applicantBackground: DeceasedAffiliateBackgrounds): void {
    const callback = (formGroup: UntypedFormGroup) => this.deceasedBackgroundForm = formGroup;
    this.resumeDeceasedAffiliateControls =
      this.setPersonBackgroundsParams(applicantBackground, false, callback, INHERITANCE_DECEASED_CONTROLS);
  }

  private setApplicantBackgroundControls(applicantBackground: ApplicantBackgrounds): void {
    const callback = (formGroup: UntypedFormGroup) => this.applicantBackgroundForm = formGroup;
    this.resumeApplicantControls =
      this.setPersonBackgroundsParams(applicantBackground, false, callback, INHERITANCE_APPLICANT_CONTROLS);
  }

  private setPersonBackgroundsParams(
    values: any,
    defaultRelationship: boolean,
    callback: Function,
    defaultControls: any,
  ): Array<PersonBackgroundsField> {
    const controls = this.formUtils.getDefaultControlsValues(values, defaultControls, defaultRelationship);
    const group = {};
    controls.forEach((control) => {
      group[control.key] = new UntypedFormControl(control.value, control.validators);
    });
    callback(new UntypedFormGroup(group));
    return controls;
  }
}
