import { Validators } from '@angular/forms';

const primaryKey = 'GENERAL_BACKGROUND_FIELDS';
const backgroundFieldsKey = `${primaryKey}.FIELDS`;
const pensionTypeKey = `${primaryKey}.PENSION_TYPE.OPTIONS`;
const pensionModalityKey = `${primaryKey}.PENSION_MODALITY.OPTIONS`;

export type PensionBackgroundsDefaultType =
  'pensionType' |
  'pensionModality';

export const PENSION_BACKGROUNDS_DEFAULT_CONTROLS = {
  pensionType: ['', Validators.required],
  pensionModality: ['', Validators.required],
};

export const GENERAL_PENSION_BACKGROUND_FIELDS_CONTENT = {
  fields: {
    pensionType: `${backgroundFieldsKey}.PENSION_TYPE`,
    pensionModality: `${backgroundFieldsKey}.PENSION_MODALITY`,
  }
};

export const survivalPensionTypeKey = 'survival';
export const PENSION_TYPES = {
  normal: {
    title: `${pensionTypeKey}.OLD_AGE`,
    definitions: [{ title: `FORM_DEFINITIONS.32.TITLE`, definition: `FORM_DEFINITIONS.32.TEXT`}]
  },
  anticipated: {
    title: `${pensionTypeKey}.EARLY_OLD_AGE`,
    definitions: [{ title: `FORM_DEFINITIONS.33.TITLE`, definition: `FORM_DEFINITIONS.33.TEXT`}]
  },
  disability: {
    title: `${pensionTypeKey}.INVALIDITY`,
    definitions: [{ title: `FORM_DEFINITIONS.34.TITLE`, definition: `FORM_DEFINITIONS.34.TEXT`}]
  },
  [survivalPensionTypeKey]: {
    title: `${pensionTypeKey}.SURVIVAL`,
    definitions: [{ title: `FORM_DEFINITIONS.29.TITLE`, definition: `FORM_DEFINITIONS.29.TEXT`}]
  },
};

export const PENSION_MODALITY = {
  programmed: {
    title: `${pensionModalityKey}.PROGRAMMED`,
    definitions: [{ title: `FORM_DEFINITIONS.8.TITLE`, definition: `FORM_DEFINITIONS.8.TEXT`}]
  },
  immediateLifeAnnuity: {
    title: `${pensionModalityKey}.IMMEDIATE`,
    definitions: [{ title: `FORM_DEFINITIONS.30.TITLE`, definition: `FORM_DEFINITIONS.30.TEXT`}]
  },
  temporaryDeferredAnnuity: {
    title: `${pensionModalityKey}.TEMPORARY`,
    definitions: [{ title: `FORM_DEFINITIONS.35.TITLE`, definition: `FORM_DEFINITIONS.35.TEXT`}]
  },
  immediateLifeAnnuityProgrammedRetirement: {
    title: `${pensionModalityKey}.IMMEDIATE_PROGRAMMED`,
    definitions: [{ title: `FORM_DEFINITIONS.31.TITLE`, definition: `FORM_DEFINITIONS.31.TEXT`}]
  },
};
