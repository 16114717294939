import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { ModalController, NavParams } from '@ionic/angular';
import { CONTACT_RESULT_MODAL } from '@pages-content/contact.constant';
import { FontService } from '@providers/font/font.service';
import { Utils } from '@utils/utils';
import { Section } from '@interfaces/general.interface';

@Component({
  selector: 'app-modal-contact-result',
  templateUrl: './modal-contact-result.component.html',
  styleUrls: ['./modal-contact-result.component.scss'],
})
export class ModalContactResultComponent extends ModalComponent {
  public content = CONTACT_RESULT_MODAL;
  public folio: string;
  public successful: boolean;

  constructor(
    public font: FontService,
    private utils: Utils,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
    this.folio = this.params.get('folio');
    this.successful = this.params.get('successful');
  }

  public get data(): Section {
    const { success, error } = this.content;
    return this.successful ? success : error;
  }

  public get resultParams() {
    return { folio: this.folio, date: this.utils.getFormattedDate(new Date()) };
  }

}
