import { DefaultResponseItem } from '@interfaces/general.interface';
import { Mockup } from '@interfaces/mockup.interface';

export const PAYMENT_METHODS_MOCKUP: Mockup = {
  success: [{
    response: [
      {
        id: '10',
        description: 'Cuenta Corriente Electronica'
      },
      {
        id: '11',
        description: 'Cuenta Ahorro Electronica'
      },
      {
        id: '12',
        description: 'Cuenta Vista Electronica'
      },
      {
        id: '13',
        description: 'Cuenta Rut Electronica'
      },
      {
        id: '3',
        description: 'Cuenta Corriente'
      },
      {
        id: '4',
        description: 'Vale Vista'
      },
      {
        id: '40',
        description: 'Efectivo Banco Estado'
      },
      {
        id: '41',
        description: 'Efectivo Servipag'
      },
      {
        id: '5',
        description: 'Cuenta Ahorro'
      },
      {
        id: '8',
        description: 'Cuenta Vista/ Rut'
      }
    ] as Array<DefaultResponseItem>
  }],
  failures: [{
    response: {}
  }]
};
