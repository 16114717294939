<form class="form-container" [formGroup]="form" autocomplete="off" *ngIf="form" (submit)="sendForm()">
  <div class="partial-content">
    <ion-button class="btn small secondary label medium save" [disabled]="form.invalid" (click)="sendForm(true)">
        {{ pageContent?.buttons.saveContinueLater | translate }}
        <div class="icon-result save"></div>
    </ion-button>
  </div>
  <app-person-backgrounds
    [pageContent]="pageContent?.affiliateBackground"
    [controlsName]="personControls"
    (formChanged)="validateForm($event, 'affiliate')">
  </app-person-backgrounds>

  <app-person-backgrounds *ngIf="!isAffiliate"
    [pageContent]="pageContent?.applicantBackground"
    [controlsName]="applicantPersonControls"
    [fieldsValidators]="applicantFieldsValidators"
    (formChanged)="validateForm($event, 'applicant')">
  </app-person-backgrounds>
  
  <div class="button-container">

    <ion-button class="button primary form-button" type="submit" [disabled]="form.invalid">
      {{ pageContent?.buttons?.next | translate }}
    </ion-button>

    <ion-button class="button secondary form-button" [ngClass]="font?.size" (click)="previousStep()">
      {{ pageContent?.buttons?.back | translate }}
    </ion-button>

  </div>
</form>
