import { AUTHORITY_ACT_ID, CHILD_CARE_ID, REVOCATION_ID, SUSPENSION_PACT_ID } from '@constants/forms.constant';
import { CaiForm, CaiHome } from '@interfaces/cai.interface';
import { Voucher } from '@interfaces/voucher.interface';
import { CONTINUE, GO_BACK, MORE_INFO, SEND_REQUEST } from '@pages-content/general.constant';

// tslint:disable:max-line-length
const CAI_KEY = 'AFFILIATE.ASSESSMENT.PAPERWORK_CENTER.CAI';
const CAI_HOME_KEY = `${CAI_KEY}.HOME`;
const CAI_FORM_KEY = `${CAI_KEY}.FORM`;
const CAI_VOUCHER_KEY = `${CAI_KEY}.VOUCHER.SUCCESS`;
const CAI_FAILED_SUBMISSION_KEY = `${CAI_KEY}.VOUCHER.FAILED`;

export const CAI_HOME_URL = 'afiliado/asesoria/centro-tramites/cai/inicio';

export const CAI_HOME: CaiHome = {
  banner: {
    image: {
      url: 'assets/images/cai_home.jpg',
      alt: 'Ley Protección al Empleo',
    },
    firstLine: `${CAI_HOME_KEY}.BANNER.FIRST_LINE`,
    secondLine: `${CAI_HOME_KEY}.BANNER.SECOND_LINE`,
    button: `${CAI_HOME_KEY}.BANNER.BUTTON`,
    url: 'afiliado/asesoria/centro-tramites/cai/formulario/',
  },
  cards: [
    {
      image: {
        url: 'assets/images/cai_home1.jpg',
        alt: 'Protección al Empleo'
      },
      title: `${CAI_HOME_KEY}.CARDS.1.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        title: `${CAI_HOME_KEY}.CARDS.1.MODAL.TITLE`,
        description: `${CAI_HOME_KEY}.CARDS.1.MODAL.DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/cai_home2.jpg',
        alt: 'Cuenta de Ahorro o Indemnización'
      },
      title: `${CAI_HOME_KEY}.CARDS.2.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        title: `${CAI_HOME_KEY}.CARDS.2.MODAL.TITLE`,
        description: `${CAI_HOME_KEY}.CARDS.2.MODAL.DESCRIPTION`,
      },
    },
    {
      image: {
        url: 'assets/images/cai_home3.jpg',
        alt: 'Pago del Retiro'
      },
      title: `${CAI_HOME_KEY}.CARDS.3.TITLE`,
      showMoreText: MORE_INFO,
      modal: {
        title: `${CAI_HOME_KEY}.CARDS.3.MODAL.TITLE`,
        description: `${CAI_HOME_KEY}.CARDS.3.MODAL.DESCRIPTION`,
      },
    },
  ],
  information: {
    title: `${CAI_HOME_KEY}.INFORMATION.TITLE`,
    faqs: [
      {
        title: `${CAI_HOME_KEY}.INFORMATION.FAQS.1.TITLE`,
        description: `${CAI_HOME_KEY}.INFORMATION.FAQS.1.DESCRIPTION`,
      },
      {
        title: `${CAI_HOME_KEY}.INFORMATION.FAQS.2.TITLE`,
        description: `${CAI_HOME_KEY}.INFORMATION.FAQS.2.DESCRIPTION`,
      },
      {
        title: `${CAI_HOME_KEY}.INFORMATION.FAQS.3.TITLE`,
        description: `${CAI_HOME_KEY}.INFORMATION.FAQS.3.DESCRIPTION`,
      },
    ],
  },
  highlightSection: {
    title: `${CAI_HOME_KEY}.HIGHLIGHT_INFORMATION.TITLE`,
    description: `${CAI_HOME_KEY}.HIGHLIGHT_INFORMATION.DESCRIPTION`,
  },
  disclaimer: {
    title: `${CAI_HOME_KEY}.DISCLAIMER.TITLE`,
    description: `${CAI_HOME_KEY}.DISCLAIMER.DESCRIPTION`,
    button: `${CAI_HOME_KEY}.DISCLAIMER.BUTTON`,
    url: 'afiliado/asesoria/centro-tramites/cai/formulario/revocacion',
  },
  moreInformation: {
    title: `${CAI_HOME_KEY}.MORE_INFORMATION.TITLE`,
    button: `${CAI_HOME_KEY}.MORE_INFORMATION.BUTTON`,
    url: 'afiliado/asesoria/centro-tramites/cai/formulario/',
  }
};

export const CAI_FORM: CaiForm = {
  title: `${CAI_FORM_KEY}.TITLE`,
  steps: [
    `${CAI_FORM_KEY}.STEPS.1`,
    `${CAI_FORM_KEY}.STEPS.2`,
    `${CAI_FORM_KEY}.STEPS.3`,
    `${CAI_FORM_KEY}.STEPS.4`,
    `${CAI_FORM_KEY}.STEPS.5`,
  ],
  buttons: {
    previous: GO_BACK,
    next: CONTINUE,
    send: SEND_REQUEST,
  },
  labels: {
    names: `${CAI_FORM_KEY}.LABELS.NAMES`,
    lastNames: `${CAI_FORM_KEY}.LABELS.LASTNAMES`,
    rut: `${CAI_FORM_KEY}.LABELS.RUT`,
    serialNumber: `${CAI_FORM_KEY}.LABELS.SERIAL_NUMBER`,
    region: `${CAI_FORM_KEY}.LABELS.REGION`,
    commune: `${CAI_FORM_KEY}.LABELS.COMMUNE`,
    address: `${CAI_FORM_KEY}.LABELS.ADDRESS`,
    phone: `${CAI_FORM_KEY}.LABELS.PHONE`,
    email: `${CAI_FORM_KEY}.LABELS.EMAIL`,
    employerRut: `${CAI_FORM_KEY}.LABELS.EMPLOYER_RUT`,
    employerName: `${CAI_FORM_KEY}.LABELS.EMPLOYER_NAME`,
    employerRegion: `${CAI_FORM_KEY}.LABELS.REGION`,
    employerCommune: `${CAI_FORM_KEY}.LABELS.COMMUNE`,
    employerAddress: `${CAI_FORM_KEY}.LABELS.ADDRESS`,
    employerEmail: `${CAI_FORM_KEY}.LABELS.EMAIL`,
    employerPhone: `${CAI_FORM_KEY}.LABELS.PHONE`,
    startReasonDate: `${CAI_FORM_KEY}.LABELS.START_REASON_DATE`,
    endReasonDate: `${CAI_FORM_KEY}.LABELS.END_REASON_DATE`,
    startWorkDate: `${CAI_FORM_KEY}.LABELS.START_WORK_DATE`,
    bankCode: `${CAI_FORM_KEY}.LABELS.BANK_CODES`,
    accountType: `${CAI_FORM_KEY}.LABELS.ACCOUNT_TYPE`,
    accountNumber: `${CAI_FORM_KEY}.LABELS.ACCOUNT_NUMBER`,
    repeatAccountNumber: `${CAI_FORM_KEY}.LABELS.REPEAT_ACCOUNT_NUMBER`,
  },
  errors: {
    required: `${CAI_FORM_KEY}.ERRORS.REQUIRED`,
    patternLetters: `${CAI_FORM_KEY}.ERRORS.PATTERN_LETTERS`,
    patternNumbers: `${CAI_FORM_KEY}.ERRORS.PATTERN_NUMBERS`,
    patternAddress: `${CAI_FORM_KEY}.ERRORS.PATTERN_ADDRESS`,
    patternAccount: `${CAI_FORM_KEY}.ERRORS.PATTERN_ACCOUNT`,
    patternRutAccount: `${CAI_FORM_KEY}.ERRORS.PATTERN_RUT_ACCOUNT`,
    patternVirtualAccount: `${CAI_FORM_KEY}.ERRORS.PATTERN_VIRTUAL_ACCOUNT`,
    patternMatch: `${CAI_FORM_KEY}.ERRORS.PATTERN_MATCH`,
    invalidRut: `${CAI_FORM_KEY}.ERRORS.INVALID_RUT`,
    invalidPhone: `${CAI_FORM_KEY}.ERRORS.INVALID_PHONE`,
    invalidEmail: `${CAI_FORM_KEY}.ERRORS.INVALID_EMAIL`,
    invalidEmailDomain: `${CAI_FORM_KEY}.ERRORS.INVALID_EMAIL_DOMAIN`,
  },
  reasonStep: {
    title: `${CAI_FORM_KEY}.REASON_STEP.TITLE`,
    description: `${CAI_FORM_KEY}.REASON_STEP.DESCRIPTION`,
    disclaimer: `${CAI_FORM_KEY}.REASON_STEP.DISCLAIMER`,
    reasons: [
      {
        id: AUTHORITY_ACT_ID,
        name: `${CAI_FORM_KEY}.REASON_STEP.REASONS.1.NAME`,
        description: `${CAI_FORM_KEY}.REASON_STEP.REASONS.1.DESCRIPTION`,
        icon: 'i-authority',
      },
      {
        id: SUSPENSION_PACT_ID,
        name: `${CAI_FORM_KEY}.REASON_STEP.REASONS.2.NAME`,
        description: `${CAI_FORM_KEY}.REASON_STEP.REASONS.2.DESCRIPTION`,
        icon: 'i-pact',
      },
      {
        id: CHILD_CARE_ID,
        name: `${CAI_FORM_KEY}.REASON_STEP.REASONS.3.NAME`,
        description: `${CAI_FORM_KEY}.REASON_STEP.REASONS.3.DESCRIPTION`,
        icon: 'i-child',
      },
      {
        id: REVOCATION_ID,
        name: `${CAI_FORM_KEY}.REASON_STEP.REASONS.4.NAME`,
        description: `${CAI_FORM_KEY}.REASON_STEP.REASONS.4.DESCRIPTION`,
        icon: 'i-revocation',
      },
    ]
  },
  personalInfoStep: {
    title: `${CAI_FORM_KEY}.PERSONAL_INFO_STEP.TITLE`,
    description: `${CAI_FORM_KEY}.PERSONAL_INFO_STEP.DESCRIPTION`,
  },
  employerInfoStep: {
    title: `${CAI_FORM_KEY}.EMPLOYER_INFO_STEP.TITLE`,
    description: `${CAI_FORM_KEY}.EMPLOYER_INFO_STEP.DESCRIPTION`,
  },
  paymentMethodStep: {
    title: `${CAI_FORM_KEY}.PAYMENT_METHOD_STEP.TITLE`,
    description: `${CAI_FORM_KEY}.PAYMENT_METHOD_STEP.DESCRIPTION`,
    disclaimer: `${CAI_FORM_KEY}.PAYMENT_METHOD_STEP.DISCLAIMER`,
    accountDisclaimer: `${CAI_FORM_KEY}.PAYMENT_METHOD_STEP.VIRTUAL_ACCOUNT_DISCLAIMER`,
    authorize: `${CAI_FORM_KEY}.PAYMENT_METHOD_STEP.AUTHORIZE`,
    personalConsent: `${CAI_FORM_KEY}.PAYMENT_METHOD_STEP.PERSONAL_CONSENT`,
  }
};

export const CAI_VOUCHER: Voucher = {
  title: `${CAI_VOUCHER_KEY}.TITLE`,
  description: `${CAI_VOUCHER_KEY}.DESCRIPTION`,
  homeButton: `${CAI_VOUCHER_KEY}.BUTTON`,
};

export const CAI_FAILED_SUBMISSION = {
  title: `${CAI_FAILED_SUBMISSION_KEY}.TITLE`,
  description: `${CAI_FAILED_SUBMISSION_KEY}.DESCRIPTION`,
  firstBtnText: `${CAI_FAILED_SUBMISSION_KEY}.BUTTON`,
  iconName: 'i-error',
};
